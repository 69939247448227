import React from 'react';

import moment from 'moment';
import { MdEditNote, MdHelp, MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';

import Tag from '../../../../components/Generic/Tag';

import ModalDeleteContract from './ModalDeleteContract';

const OperaionTable = ({ data, loadList }) => {
  const status = (value) => {
    switch (value) {
      case 'AGUARDANDO_ASSINATURA':
        return 'yellow';
      case 'ASSINADO':
        return 'green';
      default:
        return 'gray';
    }
  };

  const textStatus = (value) => {
    switch (value) {
      case 'AGUARDANDO_ASSINATURA':
        return 'Aguardando assinatura';
      case 'ASSINADO':
        return 'Assinado';
      default:
        return 'Rascunho';
    }
  };

  const handleEditContract = () => {
    localStorage.setItem('edit', true);
  };

  const handlePreviewerContract = () => {
    localStorage.removeItem('edit');
  };

  return (
    <Table className="table-default" w="full">
      <Thead border="1px" borderBottom="1px">
        <Tr>
          <Th></Th>
          <Th textAlign="center">Status</Th>
          <Th>Filiais</Th>
          <Th>CNPJ</Th>
          <Th>Razão Social</Th>
          <Th textAlign="center">Versão</Th>
          <Th textAlign="center">Data de Cadastro</Th>
          <Th>Pendências</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody border="1px">
        {data.length > 0 ? (
          <>
            {data.map((entity, key) => {
              return (
                <Tr key={key}>
                  <Td w="60px">
                    <Tooltip label="Visualizar contrato">
                      <Link
                        onClick={handlePreviewerContract}
                        to={`/vendemmia/operation-specification/detail/${entity.identifier}/isPreviwer`}>
                        <MdOpenInNew size={20} cursor="pointer" color="#422C76" />
                      </Link>
                    </Tooltip>
                  </Td>
                  <Td width={'120px'}>
                    <Tag textAlign="center" width={'100%'} fontSize="17px" type={status(entity.status)}>
                      {textStatus(entity.status)}
                    </Tag>
                  </Td>
                  <Td textAlign="center">
                    <Flex align="center" gap="3px">
                      {entity?.registration?.vendemmiaOperationUnit?.length > 0 ? (
                        <>
                          {entity.registration.vendemmiaOperationUnit.length}
                          <Tooltip label={(() => {
                            let units = entity.registration.vendemmiaOperationUnit;
                            let result = [];
                            for (let i in units) {
                              result.push(units[i].label);
                            }
                            return result.join(', ');
                          })()}>
                            <Box>
                              <MdHelp />
                            </Box>
                          </Tooltip>
                        </>
                      ) : (
                        <>-</>
                      )}
                    </Flex>
                  </Td>
                  <Td>{entity.registration.companyOperationDocumentNumber}</Td>
                  <Td>
                    <Text w="500px" textColor="primary">
                      {entity.registration.companyName}
                    </Text>
                  </Td>
                  <Td textAlign="center">{entity.version}</Td>
                  <Td textAlign="center">{moment(entity.createdAt).format('DD/MM/YYYY HH:mm')}</Td>
                  <Td>
                    <Flex align="center" gap="3px">
                      {(entity.status === 'RASCUNHO' || entity.status === 'ASSINADO') && <Text>Sem pendências</Text>}

                      {entity.status === 'CONFIRMADO' && <Text>Aguardando envio para assinatura</Text>}

                      {entity.status === 'CADASTRADO' && (
                        <>
                          {entity?.responsibleUsers?.status?.qttPending}

                          {entity?.responsibleUsers?.status?.qttPending > 0 ? (
                            <Tooltip label={entity?.responsibleUsers?.status?.whoIsMissingApproval}>
                              <Box>
                                <MdHelp />
                              </Box>
                            </Tooltip>
                          ) : (
                            <Text>sem pendências</Text>
                          )}
                        </>
                      )}

                      {entity.status === 'AGUARDANDO_ASSINATURA' && (
                        <>
                          {entity?.responsibleUsers?.status?.qttPendingSign}

                          {entity?.responsibleUsers?.status?.qttPendingSign > 0 ? (
                            <Tooltip label={entity?.responsibleUsers?.status?.whoIsMissingSign}>
                              <Box>
                                <MdHelp color="#422C76" />
                              </Box>
                            </Tooltip>
                          ) : (
                            <Text>sem pendências</Text>
                          )}
                        </>
                      )}
                    </Flex>
                  </Td>
                  <Td>
                    <Flex gap="10px">
                      <Tooltip label="Editar contrato">
                        <Link onClick={handleEditContract} to={`/vendemmia/operation-specification/detail/${entity.identifier}`}>
                          <MdEditNote size={20} cursor="pointer" color="#422C76" />
                        </Link>
                      </Tooltip>

                      <ModalDeleteContract entity={entity} loadList={loadList} />
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </>
        ) : (
          <Tr>
            <Td colSpan={9}>
              <Flex justify="center" my="25px">
                Nenhum dado encontrado para os parâmetros filtrados.
              </Flex>
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};

export default OperaionTable;
