import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { Button, FormControl, FormLabel, Grid, Input, Select, Text } from '@chakra-ui/react';

import requests from '../../../../../services/requests';

export const CardGeneralInformation = ({ entity, setHasError, formErrorData, setFormErrorData, setStep, setFormCardData }) => {
  const [drivers, setDrivers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [data, setData] = useState({
    driver: entity?.userDriverIdentifier ?? '',
    driverName: entity?.userDriverName ?? '',
    vehicle: entity?.vehicle?.identifier ?? '',
    startsAt: entity?.startsAt ?? '',
    endsAt: entity?.endsAt ?? '',
  });

  const { register } = useForm();

  const listDrivers = () => {
    requests
      .listUsers(
        {
          filters: {
            selectedStatus: ['ATIVO'],
            role: ['ROLE_DRIVER'],
          },
        },
        0,
        99999
      )
      .then((list) => {
        let options = [];
        list.data.map((driver) => {
          return options.push({
            value: driver.identifier,
            label: driver.name,
          });
        });
        setDrivers(options);
      });
  };

  const listVehicles = () => {
    requests.listVehicles({}, 0, 99999).then((list) => {
      let options = [];
      list.data.map((vehicle) => {
        return options.push({
          value: vehicle.identifier,
          label: vehicle.licensePlate,
        });
      });
      setVehicles(options);
    });
  };

  const findDriverByValue = (value) => {
    const driverTemp = drivers.find((driver) => driver.value === value);
    return driverTemp;
  };

  const onSubmit = () => {
    if (!data.startsAt) {
      setFormErrorData(true);

      return;
    }

    setFormCardData(data);
    setStep(4);
  };

  useEffect(() => {
    listDrivers();
    listVehicles();
  }, []);

  useEffect(() => {
    setHasError(!data.startsAt);
  }, [data]);

  return (
    <>
      <Grid templateColumns="repeat(2, 1fr)" gap="15px" mx="2px">
        <FormControl>
          <FormLabel mb="9px">Motorista</FormLabel>
          <Select
            className="input-register"
            color="#0F0A1D66"
            placeholder="Selecione um motorista"
            value={data.driver}
            onChange={(event) =>
              setData({
                ...data,
                driver: event.target.value,
                driverName: event.target.value === '' ? event.target.value : findDriverByValue(event.target.value).label,
              })
            }
            name="driver">
            {drivers.map((driver) => (
              <option key={driver.value} value={driver.value}>
                {driver.label}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel mb="9px">Veículo</FormLabel>
          <Select
            className="input-register"
            color="#0F0A1D66"
            placeholder="Selecione um veículo"
            value={data.vehicle}
            onChange={(event) => setData({ ...data, vehicle: event.target.value })}
            name="vehicle">
            {vehicles.map((vehicle) => (
              <option key={vehicle.value} value={vehicle.value}>
                {vehicle.label}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel mb="9px">Data e horário inicial</FormLabel>
          <Input
            className="input-register"
            type="datetime-local"
            cursor="pointer"
            placeholder="Data e horário inicial"
            _placeholder={{ color: '#0F0A1D66' }}
            {...register('startsAt', { required: true })}
            value={data.startsAt}
            onChange={(event) => setData({ ...data, startsAt: event.target.value })}
            name="startsAt"
          />
          {formErrorData && !data.startsAt && <Text textStyle="error">Campo obrigatório.</Text>}
        </FormControl>

        <FormControl>
          <FormLabel mb="9px">Data e horário final</FormLabel>
          <Input
            className="input-register"
            type="datetime-local"
            cursor="pointer"
            placeholder="Data e horário final"
            _placeholder={{ color: '#0F0A1D66' }}
            value={data.endsAt}
            onChange={(event) => setData({ ...data, endsAt: event.target.value })}
            name="endsAt"
          />
        </FormControl>
      </Grid>

      <Button mt="20px" variant="primary" onClick={onSubmit}>
        <Text fontStyle="paragraph">Salvar</Text>
      </Button>
    </>
  );
};
export default CardGeneralInformation;
