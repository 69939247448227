import React from 'react';

import { Avatar, Flex, Text } from '@chakra-ui/react';

const ProcessChatMessage = ({ entity }) => {
  function TimeSince({ createdAt }) {
    // Converter a string de data e hora no formato dd/mm/yyyy hh:mm
    // para o formato yyyy-mm-ddThh:mm
    const [datePart, timePart] = createdAt.split(' ');
    const [day, month, year] = datePart.split('/');
    const formattedDateTime = `${year}-${month}-${day}T${timePart}`;

    // Criar um objeto Date para o momento atual e o createdAt formatado
    const now = new Date();
    const createdDate = new Date(formattedDateTime);

    // Diferença em milissegundos
    let diff = now - createdDate;

    // Converter a diferença em minutos
    let diffInMinutes = Math.floor(diff / (1000 * 60));
    if (diffInMinutes < 60) {
      // Se a diferença for menor que 60 minutos, retornar a diferença em minutos
      return <span>{diffInMinutes}m</span>;
    }

    // Converter a diferença em horas
    let diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
      // Se a diferença for menor que 24 horas, retornar a diferença em horas
      return <span>{diffInHours}h</span>;
    }

    // Se a diferença for maior que 24 horas, converter a diferença para dias
    let diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays <= 7) {
      return <span>{diffInDays}d</span>;
    }

    return <span>{createdAt}</span>;
  }

  function getPicture(picture) {
    let url = process.env.REACT_APP_BASE_URL;

    if (url === 'http://localhost:8000' || 'http://localhost:8001') {
      url = 'https://api.vendemmia.com.br';
    }

    const newImage = window.localStorage.getItem('temp-profile-picture');
    const tempPicture = newImage || picture;
    return (url + tempPicture)?.replace('//profile', '/profile');
  }

  return (
    <Flex className="down-anim">
      <Flex mt="15px" mr="20px" borderRadius="100%" border="#422C76 solid 1px" p="2px" h="fit-content">
        <Avatar w="40px" h="40px" src={getPicture(entity.userProfilePictureUri)} name={entity.userName} />
      </Flex>

      <Flex direction="column" bg="#F2F2F2" p="10px 15px" w="100%" borderRadius="5px">
        <Flex direction="column">
          <Flex alignItems="center" gap="14px" position="relative">
            <Text color="primary" fontSize="19px" fontWeight="700">
              {entity.userName}
            </Text>

            <Text bg="#A885F9" color="white" fontSize="12px" borderRadius="5px" p="4px 25px">
              {'Autor'}
            </Text>

            <Text bg="#E52359" color="white" fontSize="12px" borderRadius="5px" p="4px 25px">
              {entity.stageDescription}
            </Text>

            <Text right="0" position="absolute" color="#88868E" fontSize="14px">
              {TimeSince({ createdAt: entity.createdAt })}
            </Text>
          </Flex>

          <Text color="#0F0A1D99" fontWeight="normal" fontSize="14px">
            {entity.userRole == 'Operator' ? 'Time operacional Vendemmia' : 'Time Vendemmia'}
          </Text>
        </Flex>

        <Text fontSize="16px" fontWeight="600" lineHeight="2" mt="10px">
          {entity?.description}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ProcessChatMessage;
