import React, { useEffect, useRef, useState } from 'react';

import { MdAdd, MdOutlineFileDownload } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Button, Grid, GridItem, Text } from '@chakra-ui/react';

import FloatActionButton from '../../../components/Generic/FloatActionButton';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import generateExportFile from '../../../utils/generateExportFile';
import addParametersToURL from '../../../utils/url/addParametersToURL ';
import hasSpecificURLParameters from '../../../utils/url/hasSpecificURLParameter';
import roleOptions from '../user-management/Helpers/roleOptions';

import ReportControllers from './components/ReportControllers';
import ReportList from './components/ReportList';
import ReportParams from './components/ReportParams';
import ReportSidebar from './components/ReportSidebar';
import ReportSql from './components/ReportSql';

const USER_CACHE_KEY = 'report_user_cache_key';
const USER_CACHE_TIMESTAMP_KEY = 'report_user_timestamp_key';

const ReportGeneratorPage = () => {
  const floatActionButton = document.querySelector('.float-action-button button');
  const hasPermission = permissions.vendemmiaSQLReportManagement;
  const delayBeforeReload = 2000;

  const [isMobile, setIsMobile] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [isLoadingSidebarMenu, setIsLoadingSidebarMenu] = useState(true);
  const [isLoadingSingleReport, setIsLoadingSingleReport] = useState(true);
  const [isLoadingReportRun, setIsLoadingReportRun] = useState(true);

  const [isLoadingRun, setIsLoadingRun] = useState(false);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingUserPermission, setIsLoadingUserPermission] = useState(false);

  const [isOpenFloatButton, setIsOpenFloatButton] = useState(false);

  const [isNewReport, setIsNewReport] = useState(false);
  const [switchPageButton, setSwitchPageButton] = useState(false);

  const [currentFolder, setCurrentFolder] = useState();

  const [sidebarMenu, setSidebarMenu] = useState([]);
  const [singleReport, setSingleReport] = useState();
  const [reportRun, setReportRun] = useState([]);
  const [listDefautParams, setListDefautParams] = useState([]);

  const [disableParameters, setDisableParameters] = useState(true);

  const [unsavedChangesExist, setUnsavedChangesExist] = useState(false);
  const [allowedRoles, setAllowedRoles] = useState([]);
  const [allowedUsers, setAllowedUsers] = useState([]);
  const [labelsForRoles, setLabelsForRoles] = useState([]);
  const [labelsForUserRoles, setLabelsForUserRoles] = useState([]);

  const [responsibleUsersOptions, setResponsibleUsersOptions] = useState([]);
  const [isReportSelected, setIsReportSelected] = useState(false);

  const request = useRef(0);

  const [list, setList] = useState([]);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const load = (filters, key) => {
    setIsLoading(true);

    /* removeIDParameterIfTrueAndReload(); */
    setIsNewReport(hasSpecificURLParameters(['new-report']).exists);

    isNewRecordURL();

    loadSidebarMenu(filters);
    isUserListCached();

    loadListDefautParams();
    /* alertUnsavedChanges(); */
    hasSelectedReport();
  };

  const loadMore = (filters, key) => {
    setIsLoading(true);
    setIsLoadingMore(true);
    requests.listProcess(filters, metadata.current_page + 1).then((data) => {
      if (request.current && request.current > key) {
        return;
      }

      request.current = key;

      setList((prevList) => [...prevList, ...data.data]);
      setMetadata(data.meta);
      setIsLoading(false);
      setIsLoadingMore(false);
    });
  };

  //chamadas as APIs
  //puxar itens do menu
  const loadSidebarMenu = async (filters) => {
    setIsLoadingSidebarMenu(false);

    let res;

    try {
      if (filters !== null) {
        delete filters?.search;
      }

      res = await requests.getListOfReportsMenu({ filters: filters });
      setSidebarMenu(res);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }

    //se houver parametro na URL chama o relatário do id
    const hasIdentifier = hasSpecificURLParameters(['id']).exists;

    if (hasIdentifier) {
      const identifier = hasSpecificURLParameters(['id']).value;

      //definir em qual folder está o relatório selecionado
      localStorage.removeItem('report-folder');

      if (!currentFolder) {
        res.forEach((menu, index) => {
          menu.list.forEach((submenu) => {
            identifier === submenu.identifier && setCurrentFolder(index);
          });
        });
      }

      loadSingleReportOfMenu(identifier);
    }

    setIsLoadingSidebarMenu(true);
  };

  //puxar dados do relatório escolhido no menu
  const loadSingleReportOfMenu = async (identifier, filters) => {
    setIsLoadingSingleReport(false);

    //atualizar trocar de tela de criação
    setIsNewReport(false);
    addParametersToURL({ id: identifier });

    try {
      if (filters !== null) {
        delete filters?.search;
      }

      const res = await requests.getSingleReportOfMenu(identifier, { filters: filters });
      setSingleReport(res);

      //definir se os parametros estao ativos
      setDisableParameters(res.isParameterVisible);

      //definir as permissões ativas para perfil e usuário
      setActivePermissionsForProfile(res.allowedRoles);
      setActivePermissionsForUser(res.allowedUsers);

      /* if (!currentFolder) {
        res.forEach((item, index) => {
          identifier === submenu.identifier && setCurrentFolder(index);
        });
      }
 */
      //É necessario limpar o localStorage para nao vir com dados antigos e setar o atual
      localStorage.removeItem(`report-sql`);
      localStorage.setItem('report-sql', JSON.stringify(res));

      loadReportRun(res, true);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }
    setIsLoadingSingleReport(true);
  };

  const isUserListCached = () => {
    // Verifique se os dados estão no cache e se o cache ainda é válido
    const cacheTimestamp = localStorage.getItem(USER_CACHE_TIMESTAMP_KEY);
    const cacheData = localStorage.getItem(USER_CACHE_KEY);

    if (cacheTimestamp && cacheData) {
      const timestampCurrent = new Date().getTime();
      const durationCache = 60 * 60 * 1000; // Uma hora em milissegundos

      if (timestampCurrent - parseInt(cacheTimestamp) < durationCache) {
        // Cache válido, carregue os dados do cache
        const data = JSON.parse(cacheData);
        setResponsibleUsersOptions(data);

        return;
      }
    }

    // Cache não encontrado ou expirado, faça a solicitação à API e atualize o cache
    loadResponsibleUsersOptions();
  };

  //puxar lista dos usuários do sistema
  const loadResponsibleUsersOptions = async () => {
    setIsLoadingUserPermission(true);

    try {
      const res = await requests.listUsers({filters: {status: 'ATIVO'}}, 1, 500);

      const roleUsersOptions = res.data.map((item) => ({
        label: `${item.name} (${item.roleHumanReadable})`,
        value: item.identifier,
      }));

      //ordenação alfabetica
      const roleUsersOptionsFormatted = roleUsersOptions.sort((a, b) => a.label.localeCompare(b.label));
      setResponsibleUsersOptions(roleUsersOptionsFormatted);

      // Atualize o cache com os novos dados e o timestamp
      localStorage.setItem(USER_CACHE_KEY, JSON.stringify(roleUsersOptionsFormatted));
      localStorage.setItem(USER_CACHE_TIMESTAMP_KEY, new Date().getTime().toString());
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
      toast.error('Erro ao carregar lista de usuários responsáveis, recarregue a página e tente novamente');
    }

    setIsLoadingUserPermission(false);
  };

  //carregar lista de parametros padrões
  const loadListDefautParams = async () => {
    setIsLoadingSingleReport(false);

    try {
      const res = await requests.getListDefautParams();
      setListDefautParams(res);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }
    setIsLoadingSingleReport(true);
  };

  //executar relatório atual
  const loadReportRun = async (payload, isEdit) => {
    setIsLoadingReportRun(false);
    setIsLoadingRun(true);

    //remover ação de mudanças não salvas
    removeUnsaveChanges();

    let dataSql = JSON.parse(localStorage.getItem('report-sql')) || {};
    let dataParams = JSON.parse(localStorage.getItem('report-parameters')) || {};

    const { parameters, identifier } = payload;

    //remover o parametro identifier do payload
    if (payload?.parameters.length > 1) {
      parameters.forEach((item) => {
        delete item.identifier;
      });
    } else if (payload?.parameters.length === 1) {
      parameters[0] && delete payload?.parameters[0].identifier;
    }

    const name = dataSql?.name ?? payload.name;
    const folder = dataSql?.folder ?? payload.folder;
    const query = removeBackslashes(dataSql?.query ?? payload.query);
    const databaseName = dataSql?.databaseName ?? payload.databaseName;
    const isParameterVisible = disableParameters;

    //isEdit serve para verificar o primeiro carregamento na edição
    const parametersArray = isEdit ? parameters : dataParams.customParameters;

    try {
      const res = await requests.runReport(identifier, {
        name,
        folder,
        query,
        databaseName,
        parametersArray,
        isParameterVisible,
        allowedRoles,
        allowedUsers,
      });
      setReportRun(res);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }

    setIsLoadingRun(false);
    setIsLoadingReportRun(true);
  };

  //exportar relatório
  const handlerExportReport = async (payload, type) => {
    if (!payload) {
      toast.error('Não há dados na lista para exportar.');
      return;
    }

    setIsLoadingReportRun(false);

    const { name, folder, query, databaseName, identifier } = payload;
    const parametersArray = payload.parameters;

    //remover o parametro identifier do payload
    if (parametersArray.length > 1) {
      parametersArray.forEach((item) => {
        delete item.identifier;
      });
    } else {
      parametersArray[0] && delete payload?.parameters[0].identifier;
    }

    try {
      const response = await requests.exportReport(identifier, {
        name,
        folder,
        query,
        databaseName,
        parametersArray,
        isParameterVisible: true,
        allowedRoles,
        allowedUsers,
      });

      if (type === 'xlsx') {
        generateExportFile(response, name, 'xlsx');
      } else {
        generateExportFile(response, name, 'csv');
      }
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }

    setIsLoadingReportRun(true);
  };

  //remover relatório atual
  const handlerRemoveReport = async (identifier) => {
    try {
      await requests.deleteReport(identifier);
      toast.success('Relatório removido com sucesso.');

      //desabilitar todos os módulos
      enableAllLoaders();

      setTimeout(() => {
        addNewReport();
      }, delayBeforeReload);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }
  };

  //criar relatório atual
  const handlerCreateReport = async (type) => {
    setIsLoadingCreate(true);

    //localStorage
    const dataSql = JSON.parse(localStorage.getItem('report-sql'));
    const dataParams = JSON.parse(localStorage.getItem('report-parameters'));

    const { name, folder, query, databaseName } = dataSql;
    const parametersArray = dataParams.customParameters;

    //field validation
    const isNameValid = validateAndDisplayToast('Nome do relatório', name);
    const isFolderValid = validateAndDisplayToast('Nome da pasta', folder);
    const isQueryValid = validateAndDisplayToast('Query', query);
    const isDatabaseNameValid = validateAndDisplayToast('Base de Dados', databaseName);

    const isParamValid = validateParametersArray(parametersArray);

    if (!doesArrayContainFalse(isParamValid)) {
      if (isNameValid && isFolderValid && isQueryValid && isDatabaseNameValid) {
        try {
          const res = await requests.createReport({
            name: type === 'duplicate' ? `Cópia de ${name}` : name,
            folder,
            query,
            databaseName,
            parametersArray,
            isParameterVisible: disableParameters,
            allowedRoles,
            allowedUsers,
          });

          //desabilitar todos os módulos
          enableAllLoaders();

          toast.success(`Relatório ${type === 'duplicate' ? 'duplicado' : 'criado'} com sucesso.`);

          //faz o reload da página após a trigger e recarrega o relatório criado.
          const identifier = res.identifier;
          let url = window.location.origin + window.location.pathname + '?id=' + identifier;

          setTimeout(() => {
            window.location.href = url.toString();
          }, delayBeforeReload);
        } catch (error) {
          console.error('Erro ao obter os dados da API:', error);
        }
      }
    }

    setIsLoadingCreate(false);
  };

  //editar relatorio atual
  const handlerEditReport = async (data) => {
    setIsLoadingCreate(true);

    //remover ação de mudanças não salvas
    removeUnsaveChanges();

    //localStorage
    const dataSql = JSON.parse(localStorage.getItem('report-sql'));
    const dataParams = JSON.parse(localStorage.getItem('report-parameters'));

    const identifier = data.identifier;

    const name = dataSql.name ?? data.name;
    const folder = dataSql.folder ?? data.folder;
    const query = dataSql.query ?? data.query;
    const databaseName = dataSql.databaseName ?? data.databaseName;
    const parametersArray = dataParams.customParameters;

    //field validation
    const isNameValid = validateAndDisplayToast('Nome do relatório', name);
    const isFolderValid = validateAndDisplayToast('Nome da pasta', folder);
    const isQueryValid = validateAndDisplayToast('Query', query);
    const isDatabaseNameValid = validateAndDisplayToast('Base de Dados', databaseName);

    const isParamValid = validateParametersArray(parametersArray);

    if (!doesArrayContainFalse(isParamValid)) {
      if (isNameValid && isFolderValid && isQueryValid && isDatabaseNameValid) {
        try {
          await requests.editReport(identifier, {
            name,
            folder,
            query,
            databaseName,
            parametersArray,
            isParameterVisible: disableParameters,
            allowedRoles,
            allowedUsers,
          });

          toast.success('Relatório atualizado com sucesso.');

          //desabilitar todos os módulos
          enableAllLoaders();

          setTimeout(() => {
            window.location.reload();
          }, delayBeforeReload);
        } catch (error) {
          console.error('Erro ao obter os dados da API:', error);
        }
      }
    }

    setIsLoadingCreate(false);
  };
  //chamadas as APIs

  const setActivePermissionsForProfile = (data) => {
    const allowedRolesFormatted = data.map((item) => item.role);
    const labelsForRolesFormatted = getLabelsForRoles(allowedRolesFormatted);

    setAllowedRoles(allowedRolesFormatted);
    setLabelsForRoles(labelsForRolesFormatted);
  };

  const setActivePermissionsForUser = (data) => {
    const allowedRolesFormatted = data.map((item) => item.identifier);
    const labelsForRolesFormatted = data.map((item) => item.name);

    setAllowedUsers(allowedRolesFormatted);
    setLabelsForUserRoles(labelsForRolesFormatted);
  };

  const updateCurrentFolder = (value) => {
    setCurrentFolder(value);
  };

  const validateParametersArray = (parametersArray) => {
    const validate = [];

    let isValid;

    const isValidItem = (label, index) => {
      const fieldName = label + ' ' + '#' + (index + 1);
      const errorMessage = `O campo ${fieldName} não pode estar vazio.`;
      toast.error(errorMessage);
      isValid = false;
    };

    parametersArray.forEach((parameter, index) => {
      isValid = true;

      !parameter.label && isValidItem('Label', index);
      !parameter.name && isValidItem('Name', index);
      !parameter.value && isValidItem('Value', index);

      validate.push(isValid);
    });

    return validate;
  };

  const doesArrayContainFalse = (array) => {
    return array.some((item) => item === false);
  };

  const validateAndDisplayToast = (fieldName, fieldValue) => {
    if (!fieldValue) {
      toast.error(`O campo '${fieldName}' precisa ser preenchido.`);
      return false;
    }
    return true;
  };

  function removeBackslashes(inputString) {
    const cleanedString = inputString.replace(/\\/g, '');
    return cleanedString;
  }

  const isNewRecordURL = () => {
    let hasParameter = hasSpecificURLParameters(['id']).exists;

    if (!hasParameter) {
      addParametersToURL({ 'new-report': true });
      setIsNewReport(true);
      setcleanStorage(20);
    }
  };

  const setcleanStorage = (range) => {
    for (let index = 0; index < range; index++) {
      localStorage.removeItem(`report-label-${index}`);
      localStorage.removeItem(`report-name-${index}`);
      localStorage.removeItem(`report-value-${index}`);
    }
  };

  function removeIDParameterIfTrueAndReload() {
    const url = new URL(window.location.href);
    const params = url.searchParams;

    // Verifica se o parâmetro "id" está definido como "true" e o remove, se necessário
    if (params.has('id') && params.get('id') === 'true') {
      params.delete('id');
      // Recarrega a página
      window.location.href = url.toString();
    }
  }

  const addNewReport = () => {
    const urlWithoutId = window.location.pathname;
    window.history.replaceState(null, null, urlWithoutId);

    window.location.reload();
  };

  const closeFloatActionButton = () => {
    floatActionButton && floatActionButton.click();
  };

  const unsavedChanges = (boolean) => {
    setUnsavedChangesExist(boolean);
  };

  const enableAllLoaders = () => {
    setIsLoadingSidebarMenu(false);
    setIsLoadingSingleReport(false);
    setIsLoadingReportRun(false);
  };

  const alertUnsavedChanges = () => {
    window.removeEventListener('beforeunload', beforeUnloadHandler);

    if (unsavedChangesExist) {
      window.addEventListener('beforeunload', beforeUnloadHandler);
    }
  };

  const removeUnsaveChanges = () => {
    setUnsavedChangesExist(false);
    window.removeEventListener('beforeunload', beforeUnloadHandler);
  };

  function beforeUnloadHandler(e) {
    e.preventDefault();
    e.returnValue = 'Você tem alterações não salvas. Tem certeza de que deseja sair?';
  }

  function getLabelsForRoles(response) {
    const labels = [];

    // Itera sobre os valores da resposta da API
    for (const role of response) {
      // Procura o objeto correspondente em roleOptions pelo valor
      const roleOption = roleOptions.find((option) => option.value === role);

      if (roleOption) {
        // Se encontrar o objeto, adiciona o rótulo ao array de rótulos
        labels.push(roleOption.label);
      }
    }

    return labels;
  }

  const hasSelectedReport = () => {
    setIsReportSelected(hasSpecificURLParameters(['id']).exists);
  };

  //triggers
  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    /* alertUnsavedChanges(); */
  }, [unsavedChangesExist]);
  
  useEffect(() => {
    !isNewReport ? setSwitchPageButton(true) : setSwitchPageButton(false);
  }, [isNewReport]);

  const FloatActionButtonOptions = [
    /* {
      isLoading: '',
      rightIcon: <Image src={reportExcel} color="#FFFFFF" size={20} />,
      handler: () => handlerExportReport(singleReport, 'xlsx'),
      title: 'Exportar .xlsx',
      showInNewReport: true,
    }, */
    {
      isLoading: '',
      rightIcon: <MdAdd color="#FFFFFF" size={25} />,
      handler: () => {
        addNewReport();
        closeFloatActionButton();
      },
      title: 'Adicionar novo',
      showInNewReport: true,
    },
    /* {
      isLoading: '',
      rightIcon: <MdOutlineShare color="#FFFFFF" size={25} />,
      handler: () => downloadCsv('/vendemmia/report-generator/export-csv', updateLoaderDownloadCsv),
      title: 'Compartilhar',
      showInNewReport: true,
    }, */
    {
      isLoading: '',
      rightIcon: <MdOutlineFileDownload color="#FFFFFF" size={25} />,
      handler: () => {
        unsavedChanges && alert('Será exportado a ultima versão salva');
        handlerExportReport(singleReport, 'csv');
        closeFloatActionButton();
      },
      title: 'Exportar dados',
      showInNewReport: switchPageButton,
    },
  ];

  return (
    <Page
      screen="report-generator"
      title="Relatórios"
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      textFilterPlaceholder="Nome do relatório"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      loadMore={loadMore}
      isContentLoadingMore={isLoadingMore}
      /* filterOptions={filterOptions} */
      isRefreshLoading={isLoading || isLoadingMore}
      showPeriodFilter={false}
      showRefreshData={false}
      showFilters={false}
      allowFiltersOverride={false}>
      <Grid
        as="main"
        minH={'100vh'}
        gridTemplateColumns={{ base: '1fr', md: 'auto 1fr' }}
        /* templateColumns='repeat(5, 1fr)' */
        gap={4}
        m="10px">
        <GridItem>
          <ScreenLoader isLoading={!isLoadingSidebarMenu}>
            <ReportSidebar
              sidebarMenu={sidebarMenu}
              currentFolder={currentFolder}
              updateCurrentFolder={updateCurrentFolder}
              loadSingleReportOfMenu={loadSingleReportOfMenu}
              setIsNewReport={setIsNewReport}
              setSwitchPageButton={setSwitchPageButton}
              unsavedChangesExist={unsavedChangesExist}
              unsavedChanges={unsavedChanges}
            />
          </ScreenLoader>
        </GridItem>
        <GridItem>
          <Grid minH={'100vh'} gap={4} templateRows="repeat(13fr)" templateColumns="repeat(12, 1fr)">
            <GridItem rowSpan={6} colSpan={{ base: '12', xlr: '5' }}>
              <ScreenLoader isLoading={!isLoadingSingleReport}>
                <ReportSql
                  dataReport={singleReport}
                  sidebarMenu={sidebarMenu}
                  isNewReport={isNewReport}
                  setIsNewReport={setIsNewReport}
                  unsavedChangesExist={unsavedChangesExist}
                  unsavedChanges={unsavedChanges}
                />
              </ScreenLoader>
            </GridItem>
            <GridItem rowSpan={6} colSpan={{ base: '12', xlr: '7' }}>
              <ScreenLoader isLoading={!isLoadingSingleReport}>
                <ReportParams
                  //consts
                  dataReport={singleReport}
                  listDefautParams={listDefautParams}
                  disableParameters={disableParameters}
                  //sets
                  setDisableParameters={setDisableParameters}
                  //methods
                  unsavedChanges={unsavedChanges}
                  //styles
                  cols={3}
                  containerHeight={'100%'}
                  //conditionals
                  isMobile={isMobile}
                  shouldAddParameter={true}
                  activateUnsavedChanges={true}
                  activateDefaultParameters={true}
                  showDisableParametersButton={true}
                />
              </ScreenLoader>
            </GridItem>
            <GridItem rowSpan={1} colSpan={12}>
              <ReportControllers
                //consts
                dataReport={singleReport}
                isNewReport={isNewReport}
                allowedRoles={allowedRoles}
                allowedUsers={allowedUsers}
                labelsForRoles={labelsForRoles}
                labelsForUserRoles={labelsForUserRoles}
                responsibleUsersOptions={responsibleUsersOptions}
                //sets
                setAllowedRoles={setAllowedRoles}
                setLabelsForRoles={setLabelsForRoles}
                setAllowedUsers={setAllowedUsers}
                setLabelsForUserRoles={setLabelsForUserRoles}
                //methods
                loadReportRun={loadReportRun}
                handlerRemoveReport={handlerRemoveReport}
                handlerEditReport={handlerEditReport}
                handlerCreateReport={handlerCreateReport}
                removeUnsaveChanges={removeUnsaveChanges}
                unsavedChanges={unsavedChanges}
                //loads
                isLoadingRun={isLoadingRun}
                isLoadingCreate={isLoadingCreate}
                isLoadingDelete={isLoadingDelete}
                isLoadingUserPermission={isLoadingUserPermission}
                //styles
                containerHeight={'100%'}
                justifyContent={'space-between'}
              />
            </GridItem>
            <GridItem rowSpan={6} colSpan={12}>
              <ScreenLoader isLoading={!isLoadingReportRun}>
                <ReportList dataReport={reportRun} maxH={'355px'} paginateTextDisplay={false} isReportSelected={isReportSelected} />
              </ScreenLoader>
            </GridItem>
          </Grid>
        </GridItem>
        <FloatActionButton
          display="flex"
          flexDirection="column"
          options={[
            FloatActionButtonOptions.map((button, key) => {
              return (
                button.showInNewReport && (
                  <Button
                    key={key}
                    h="46px"
                    px="24px"
                    py="14px"
                    bgColor="green"
                    color="#FFFFFF"
                    borderRadius="27px"
                    _hover={{ bgColor: '#70D499' }}
                    isLoading={button.isLoading}
                    rightIcon={button.rightIcon}
                    onClick={button.handler}>
                    <Text>{button.title}</Text>
                  </Button>
                )
              );
            }),
          ]}
          isOpen={isOpenFloatButton}
          setIsOpen={setIsOpenFloatButton}
        />
      </Grid>
    </Page>
  );
};

export default ReportGeneratorPage;
