import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import {
  Card,
  Flex,
  Image,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  Tabs,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
} from '@chakra-ui/react';

import gestao from '../../../assets/images/gestao-ball.png';
import manual from '../../../assets/images/proprio-ball.png';
import api from '../../../assets/images/vendemmia-ball.png';
import LabelWithTextH from '../../../components/Generic/LabelWithTextH';
import Layout from '../../../components/Layout';
import Menubar from '../../../components/MenuBar/Menubar';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import useScreenPage from '../../../hooks/useScreenPage';
import requests from '../../../services/requests';

import AdditionalDataList from './components/AdditionalDataList';
import ChannelFlag from './components/ChannelFlag';
import DocumentList from './components/DocumentList';
import FavoriteProcess from './components/FavoriteProcess';
import ProcessDetailActivity from './components/ProcessDetailActivity';
import ProcessDetails from './components/ProcessDetails';
import ProductList from './components/ProductList';
import QuoteList from './components/QuoteList';
import SwitchMaps from './components/SwitchMaps';

const ProcessDetailPage = () => {
  let profileRole = localStorage.getItem('profile-role');

  //Detecção de chamadas as APIs
  const screenPage = useScreenPage()

  const { identifier } = useParams();
  const [process, setProcess] = useState({});
  const [updates, setUpdates] = useState([]);
  const [loadUpdates, setLoadUpdates] = useState(true);
  const [isDownloadingAllFilesloadUpdates, setIsDownloadingAllFiles] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showAdtionalFields, setShowAdtionalFields] = useState('no');
  const [points, setPoints] = useState([]);
  const [lines, setLines] = useState([]);

  const getProcess = () => {
    requests.showProcess(identifier, {
      screenPage,
    }).then((data) => {
      setProcess({ ...data, vesselMmsi: getVesselMmsi(data) });

      const coordinates = [];
      for (let i in data?.freights) {
        if (data?.freights[i]?.lastLatitude && data?.freights[i]?.lastLongitude) {
          coordinates.push({
            latitude: data?.freights[i]?.lastLatitude,
            longitude: data?.freights[i]?.lastLongitude,
          });
        }
      }
    });
  };

  const getIcon = () => {
    switch (process.originDescription) {
      case 'api':
        return api;
      case 'manual':
        return manual;
      case 'gestao':
        return gestao;
      default:
        return api;
    }
  };

  const getVesselMmsi = (process) => {
    let mmsi = [];

    if (process.vessel && process.vessel.mmsi && process.vessel.mmsi != '0') {
      mmsi.push(process.vessel);
    }
    if (process.vesselExtra1 && process.vesselExtra1.mmsi) {
      mmsi.push(process.vesselExtra1);
    }
    if (process.vesselExtra2 && process.vesselExtra2.mmsi) {
      mmsi.push(process.vesselExtra2);
    }

    return mmsi;
  };

  const getUpdates = () => {
    if (!process || !process.identifier) {
      setLoadUpdates(true);
      return;
    }

    setLoadUpdates(true);
    requests
      .getProcessUpdates(process.identifier, {
        screenPage,
      })
      .then((data) => {
        setUpdates(data);
        setLoadUpdates(false);
      })
      .catch(() => setLoadUpdates(false));
  };

  const onLoadAdtionalFieldsData = (fields) => {
    if (fields.length > 0) {
      for (const field of fields) {
        if (field.value) {
          setShowAdtionalFields('all'); //Se tiver campos e valores, mostrar pra todos
          return;
        }
      }
      setShowAdtionalFields('admin'); //Se tiver apenas campos, mostrar para "não-clientes"
      return;
    }
    setShowAdtionalFields('no'); //Se não tiver nada, não mostrar pra ninguém
    return;
  };

  const downloadAllFiles = useCallback(() => {
    setIsDownloadingAllFiles(true);

    let link = document.createElement('a');

    link.href = (requests.getApiUrl() + `/open/process/file/${process.identifier}/all/download`).replace('//open', '/open');
    link.target = '_blank';
    link.download = process.identifier + '.zip';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setIsDownloadingAllFiles(false);
  });

  const isShowAdtionalFields = () => {
    if (showAdtionalFields == 'all' || (profileRole !== 'ROLE_CLIENT' && showAdtionalFields == 'admin')) {
      return true;
    }

    return false;
  };

  const loadList = () => {
    requests.mapPositions(identifier, {
      screenPage,
    }).then((data) => {
      setPoints(data.points);
      setLines(data.lines);
    });
  };

  const load = () => {
    loadList();
  };

  useEffect(() => {
    getUpdates();
  }, [process]);

  useEffect(() => {
    getProcess();
  }, []);

  useEffect(() => {
    load();
  }, []);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Layout>
      <Menubar
        linkTree={[
          { link: '#', title: 'Importação' },
          { link: '/comex/process', title: 'Processos' },
        ]}
        title={process.code}
        placeholder="Processo, empresa ou ref"
        hideAllFilters={true}
      />
      <Flex flexDirection={{ base:'column', md:'row' }}>
        <Flex direction="column" gap="15px" m="10px" w={{ base:'initial', md:'60vw' }}>
          <Card overflow="hidden">
            <ScreenLoader isLoading={isLoading}>
              <Flex justify="flex-start" alignItems="center" p="15px" gap="20px" overflow="hidden" flexWrap={'wrap'}>
                <Image src={getIcon()} borderRadius="100%" w="30px" h="30px" />

                {!isLoading && process && (
                  <>
                    {process.channel && <ChannelFlag color={process.channel} />}

                    <Text textStyle="tableTitle" ml={{ base: '0', md: '-15px' }}>{process.code}</Text>

                    <Tooltip label={`Etapa atual: ${process.currentStageModality}`}>
                      <Image
                        w="30px"
                        h="30px"
                        src={require(`../../../assets/images/process-step-${Math.max(1, Math.floor(process.progressBar / 20 - 1))}${
                          process.originDescription ? '-mag' : ''
                        }.svg`)}
                        filter={'saturate(1)'}
                        opacity={'1'}
                        transition=".4s"
                        style={{ animationName: 'scale' }}
                      />
                    </Tooltip>
                    <Text textStyle="tableTitle" ml={{ base: '0', md: '-15px' }}>
                      {process.currentStageModality}
                    </Text>
                    <Text>
                      <FavoriteProcess process={process} />
                    </Text>
                    {process.deliveredAtFormatted ? (
                      <>
                        <LabelWithTextH title="Data de entrega" content={process.deliveredAtFormatted} />
                      </>
                    ) : process.billedAtFormatted ? (
                      <>
                        <LabelWithTextH title="Data de faturamento" content={process.billedAtFormatted} />
                      </>
                    ) : process.arrivedAtFormatted ? (
                      <>
                        <LabelWithTextH title="Data de chegada" content={process.arrivedAtFormatted} />
                      </>
                    ) : process.arrivalExpectedAtFormatted ? (
                      <>
                        <LabelWithTextH title="Data esperada de chegada" content={process.arrivalExpectedAtFormatted} />
                      </>
                    ) : process.boardedAtFormatted ? (
                      <>
                        <LabelWithTextH title="Data de embarque" content={process.boardedAtFormatted} />
                      </>
                    ) : process.boardExpectedAtFormatted ? (
                      <>
                        <LabelWithTextH title="Data esperada de embarque" content={process.boardExpectedAtFormatted} />
                      </>
                    ) : (
                      <LabelWithTextH title="Data de abertura" content={process.startedAtFormatted} />
                    )}

                    <LabelWithTextH title="Responsável" content={process.userResponsibleConexos} />
                  </>
                )}
              </Flex>
              <SwitchMaps process={process} h="500px" points={points} lines={lines} />
            </ScreenLoader>
          </Card>

          <Card>
            <Tabs mt="20px" position="relative" variant="unstyled">
              <TabList 
                flexDirection={{ base:'column', md:'row' }} 
                justifyContent="flex-start" 
                p={{ base:'0 15px', md:'0 0 0 20px' }} 
                overflow={'hidden'} 
                borderBottom="#70707036 solid 1px"
              >
                <Tab color="#BEBEBE" _selected={{ color: '#6C48C2' }}>
                  <Text fontSize="14px">Atividades</Text>
                </Tab>
                <Tab color="#BEBEBE" _selected={{ color: '#6C48C2' }}>
                  <Text fontSize="14px">Documentos</Text>
                </Tab>
                <Tab color="#BEBEBE" _selected={{ color: '#6C48C2' }}>
                  <Text fontSize="14px">Produtos</Text>
                </Tab>
                <Tab color="#BEBEBE" _selected={{ color: '#6C48C2' }}>
                  <Text fontSize="14px">Cotações</Text>
                </Tab>
                {isShowAdtionalFields() && (
                  <Tab color="#BEBEBE" _selected={{ color: '#6C48C2' }}>
                    <Text fontSize="14px">Informações complementares</Text>
                  </Tab>
                )}
              </TabList>

              <TabIndicator mt="-1.5px" height="2px" bg="#6C48C2" borderRadius="1px" />

              <TabPanels overflow={'hidden'} p={{ base: '15px', md: '0' }} ml={{ base: '0', md: '20px' }} mr={{ base: '0', md: '20px' }}>
                <TabPanel w={{ base: 'initial', md: 'calc(100% - 32px)' }} overflow={'scroll'}>
                  <ScreenLoader isLoading={isLoading}>
                    <ProcessDetailActivity updates={updates} process={process} onSendComment={() => getUpdates()} />
                  </ScreenLoader>
                </TabPanel>

                <TabPanel w={{ base: 'initial', md: 'calc(100% - 32px)' }} overflow={'scroll'}>
                  <ScreenLoader isLoading={isLoading}>
                    <DocumentList process={process} setIsLoadingData={setIsLoading} downloadAllFiles={downloadAllFiles} />
                  </ScreenLoader>
                </TabPanel>

                <TabPanel w={{ base: 'initial', md: 'calc(100% - 32px)' }} overflow={'scroll'}>
                  <ScreenLoader isLoading={isLoading}>
                    <ProductList process={process} setIsLoadingData={setIsLoading} />
                  </ScreenLoader>
                </TabPanel>

                <TabPanel w={{ base: 'initial', md: 'calc(100% - 32px)' }} overflow={'scroll'}>
                  <ScreenLoader isLoading={isLoading}>
                    <QuoteList process={process} setIsLoadingData={setIsLoading} />
                  </ScreenLoader>
                </TabPanel>

                <TabPanel w={{ base: 'initial', md: 'calc(100% - 32px)' }} overflow={'scroll'}>
                  <ScreenLoader isLoading={isLoading}>
                    <AdditionalDataList process={process} setIsLoadingData={setIsLoading} onLoadData={onLoadAdtionalFieldsData} />
                  </ScreenLoader>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Card>
        </Flex>

        <Card m="10px" p="10px" w={{ base:'initial', md:'calc(40vw - 60px)' }}>
          <Flex justify="space-between" alignItems="center" borderBottom="#70707036 solid 1px" pb="15px" mb="20px">
            <Text textStyle="tableTitle" color="#6C48C2" fontWeight="bold">
              Informações da empresa
            </Text>
          </Flex>

          <Flex direction="column" gap="5px" mb="25px">
            <ScreenLoader isLoading={isLoading}>
              <Table variant="simple" className="table-default" w="100%">
                <Tbody>
                  <Tr>
                    <Td>Empresa</Td>
                    <Td>{process?.client?.companyName}</Td>
                  </Tr>
                  <Tr>
                    <Td>CNPJ</Td>
                    <Td>{process?.client?.documentNumberFormatted}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </ScreenLoader>
          </Flex>

          <Flex justify="space-between" alignItems="center" borderBottom="#70707036 solid 1px" pb="15px" mb="20px">
            <Text textStyle="tableTitle" color="#6C48C2" fontWeight="bold">
              Linha do tempo
            </Text>
          </Flex>
          <Flex direction="column" gap="5px">
            <ScreenLoader isLoading={isLoading}>
              <ProcessDetails process={process} updates={updates} loadUpdates={loadUpdates} />
            </ScreenLoader>
          </Flex>
        </Card>
      </Flex>
    </Layout>
  );
};

export default ProcessDetailPage;
