import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Flex, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';

import MapRegisterFreight from '../../../../../components/Map/MapRegisterFreight';

import RegisterFreight from './RegisterFreight';

const ModalFreight = ({ entity, isOpen, onClose, title, subTitle, action, setAction, setOrigin, setDestination, tab }) => {
  const [geolocationList, setGeolocationList] = useState({});
  const [mapWidth, setMapWidth] = useState(100);
  const [mapHeight, setMapHeight] = useState(100);
  const mapContainerRef = useRef(null);

  useLayoutEffect(() => {
    if (mapContainerRef && mapContainerRef.current) {
      setMapWidth(mapContainerRef?.current?.clientWidth);
      setMapHeight(mapContainerRef?.current?.clientHeight);
    }
  }, [mapContainerRef]);

  useEffect(() => {
    function handleWindowResize() {
      setMapWidth(mapContainerRef?.current?.clientWidth);
      setMapHeight(mapContainerRef?.current?.clientHeight);
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />

      <ModalContent maxW="none" px="2px" py="2px" my="0" w="80vw" h="80vh">
        <ModalBody bgColor="#FAFBFF" p="0" display="flex" h="full">
          <RegisterFreight
            title={title}
            subTitle={subTitle}
            tab={tab}
            entity={entity}
            setGeolocationList={setGeolocationList}
            setDestination={setDestination}
            action={action}
            setAction={setAction}
            setOrigin={setOrigin}
            onCancelled={onClose}
          />

          <Flex ref={mapContainerRef} width="100%" overflow="hidden">
            <MapRegisterFreight w={`${mapWidth}%`} h={`${mapHeight}%`} points={geolocationList} />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalFreight;
