import React, { useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';

import { Box, Button, Flex, HStack, Input, PinInput, PinInputField, Text } from '@chakra-ui/react';

import 'react-toastify/dist/ReactToastify.css';
import bg1 from '../../assets/images/login/bgs/bg-1.jpg';
import bg2 from '../../assets/images/login/bgs/bg-2.jpg';
import Loading from '../../components/Loading/Loading';
import BackSlider from '../../components/Login/BackSlider/BackSlider';
import CardRound from '../../components/Login/CardRound/CardRound';
import requests from '../../services/requests';

const Reactivation = () => {
  const [email, setEmail] = useState(window.localStorage.getItem('profile-email'));
  const [step, setStep] = useState(1);
  const [pin, setPin] = useState(['', '', '', '', '', '']);
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [loading, setLoading] = useState(false);

  const renderButtonText = () => {
    switch (step) {
      case 1:
        return 'Enviar';
      case 2:
        return 'Salvar';
      case 3:
        return 'Fazer login';
      default:
        return 'Enviar';
    }
  };

  const handleChange = (value, index) => {
    setCode(value);
  };

  const handlePaste = (e) => {
    e.preventDefault();

    const pastedData = e.clipboardData.getData('text/plain');
    const pastedArray = pastedData.split('').slice(0, 6); // Limita a 6 caracteres se necessário

    setCode(pastedArray);
  };

  const bgs = [
    {
      urlBg: bg1,
      text: 'Vendemmia Logística Integrada',
      timeSecs: 10,
      topicId: 1,
      filter: 0,
    },
    {
      urlBg: bg1,
      text: 'Vendemmia Logística Integrada',
      timeSecs: 10,
      topicId: 2,
      filter: 0,
    },
    {
      urlBg: bg1,
      text: 'Vendemmia Logística Integrada',
      timeSecs: 10,
      topicId: 3,
      filter: 0,
    },
  ];

  return (
    <BackSlider slides={bgs}>
      <ToastContainer />
      <CardRound>
        <Flex flexDir="column" gap="20px">
          {step === 1 && (
            <>
              <Text textStyle="cardTitle">Reativação de conta expirada</Text>
              <Text textStyle="paragraph">Informe o e-mail cadastrado para receber instruções para a recuperação da sua conta</Text>
            </>
          )}

          {step === 2 && (
            <>
              <Text textStyle="cardTitle">Insira o código recebido</Text>
              <Text textStyle="paragraph">Se o seu e-mail estiver cadastrado, você irá receber um código para alterar sua senha</Text>
            </>
          )}

          {step === 1 && (
            <Box>
              <Input
                variant="flushed"
                placeholder="E-mail: "
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
              />
            </Box>
          )}

          {step === 2 && (
            <Flex flexDirection="column" gap="20px">
              <Box>
                <HStack>
                  <PinInput type="alphanumeric" isDisabled={false} onChange={handleChange} onPaste={handlePaste}>
                    {pin.map((value, index) => (
                      <PinInputField key={index} value={value} />
                    ))}
                  </PinInput>
                </HStack>
              </Box>
              <Box>
                <Input
                  id="newPassword"
                  type="password"
                  variant="flushed"
                  placeholder="Insira sua nova senha"
                  value={password}
                  onChange={(e) => setPassword(e.target.value.trim())}
                />
              </Box>
              <Box>
                <Input
                  id="confirmPassword"
                  className="focus:outline-none border-2 border-#EBEBEB py-1 px-2"
                  type="password"
                  variant="flushed"
                  placeholder="Confirme a senha"
                  onChange={(e) => setPasswordValidation(e.target.value.trim())}
                />
              </Box>
            </Flex>
          )}

          {step === 3 && (
            <p className="text-C6 text-sm text-center px-10">
              Seu usuário foi <Text as={'strong'}>reativado</Text> com sucesso e sua senha alterada.
            </p>
          )}

          <Flex flexDirection="row" justify="space-between" align="center">
            <Button
              variant="secundary"
              onClick={() => {
                if (step === 1) {
                  return (window.location.href = '/');
                } else {
                  setStep(step - 1);
                }
              }}>
              <Text textStyle="paragraph">Voltar</Text>
            </Button>

            <Button
              variant="primary"
              onClick={async () => {
                if (step === 1) {
                  setLoading(true);

                  try {
                    await requests.sendEmailUserReactivation({
                      username: email,
                    });

                    setLoading(false);
                    toast.success('Código enviado para email informado!');
                    setStep(2);
                  } catch (error) {
                    setLoading(false);
                    toast.error(error);
                    console.error(error);
                  }
                } else if (step === 2) {
                  if (password !== passwordValidation) {
                    toast.error('As senhas não são iguais!');
                  } else {
                    setLoading(true);

                    try {
                      await requests.sendNewPasswordUserReactivation({
                        code,
                        password,
                      });

                      toast.success('O usuário foi reativado com sucesso');
                      setLoading(false);
                      setStep(3);
                    } catch (error) {
                      setLoading(false);
                      toast.error(error);
                      console.error(error);
                    }
                  }
                } else if (step === 3) {
                  try {
                    const resLogin = await requests.checkLogin({
                      username: email,
                      password,
                    });

                    var token = resLogin.data.token;
                    localStorage.setItem('session-token', token);

                    requests.getProfile().then((profile) => {
                      localStorage.setItem('profile-role', profile.role);
                      localStorage.setItem('profile-identifier', profile.identifier);
                      localStorage.setItem('profile-name', profile.name);
                      localStorage.setItem('profile-email', profile.email);
                      localStorage.setItem('profile-cellphone', profile.cellphone);
                      localStorage.setItem('profile-phone', profile.phone);
                      localStorage.setItem('profile-role-human-readable', profile.roleHumanReadable);
                      localStorage.setItem('profile-picture', profile.picture);
                      localStorage.setItem('profile-permissions', JSON.stringify(profile.permissions));

                      let redirectUserTo = window.localStorage.getItem('redirectTo') ?? '/dashboard';
                      let redirectUserExpiresAt = window.localStorage.getItem('redirectToExpiresAt');

                      if (!redirectUserExpiresAt || new Date() > redirectUserExpiresAt) {
                        redirectUserTo = '/dashboard';
                      }

                      window.localStorage.removeItem('redirectTo');
                      window.localStorage.removeItem('redirectToExpiresAt');
                      window.location.href = redirectUserTo;

                      return;
                    });
                    setLoading(false);
                  } catch (error) {
                    console.error(error);
                    setLoading(false);
                  }
                }
              }}>
              <Text textStyle="paragraph">{renderButtonText()}</Text>
            </Button>
          </Flex>
        </Flex>
      </CardRound>
      <Loading status={loading} />
    </BackSlider>
  );
};

export default Reactivation;
