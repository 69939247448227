import React from 'react';

import { Flex, Table, TableContainer, Tbody, Thead, Tr } from '@chakra-ui/react';

import Paginate from '../../../../../components/Paginate/Paginate';

import TableListCte from './TableListCte';

import '../../../components/Tables/Travel/styles.css';

const TableCte = ({
  tab,
  list,
  setMetadata,
  metadata,
  load,
  setLoad,
  loadSecond,
  action,
  setAction,
  showDetails,
  loadData
}) => {
  return (
    <TableContainer>
      <Table maxW="100%" mt="10px">
        <Thead>
          <Tr></Tr>
        </Thead>

        <Tbody>
          {!loadSecond && (
            <>
              {list?.map((entity, key) => {
                return <TableListCte tab={tab} entity={entity} setLoad={setLoad} key={key} loadData={loadData} />;
              })}
            </>
          )}
        </Tbody>
      </Table>

      {load ? (
        <Flex justify="center" my="25px">
          <span>Carregando...</span>
        </Flex>
      ) : (
        <>
          {list?.length === 0 && (
            <Flex justify="center" my="25px">
              Nenhum dado encontrado para os parâmetros filtrados.
            </Flex>
          )}
        </>
      )}
      {metadata?.total_pages > 1 && (
        <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
      )}
    </TableContainer>
  );
};

export default TableCte;
