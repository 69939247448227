import React from 'react';

import { Flex, Text, Grid, Box } from '@chakra-ui/react';

import UpdateInfoTag from '../../../dashboard/components/UpdateInfoTag';

const CalendarWorkloadItem = ({ entity }) => {
  return (
    <Flex
      direction="column"
      p="10px"
      bg="#F5F5F5"
      w="100%"
      borderRadius="8px"
      transition={'.3s'}
      _hover={{ transform: 'scale(1.05)', boxShadow: '0px 2px 20px #00000044' }}>
      <Flex mt="10px" align="center" gap="10px">
        <Text textStyle="subtitle" textColor="#0F0A1D">
          <Grid
            gridTemplateColumns={{ base: '1fr', md: '1fr auto' }}
            justifyItems={'center'}
            alignItems={'center'}
            w="full"
            gap={{ base: '5px', md: '15px' }}
          >
            <Box>
              {entity.event}
            </Box>
            <Box textAlign="right">
              {entity.qtt}
            </Box>
          </Grid>
        </Text>
      </Flex>
    </Flex>
  );
};

export default CalendarWorkloadItem;
