import React, { useState } from 'react';

import { toast } from 'react-toastify';

import requests from '../../../../../services/requests';

import ModalFormTerm from './ModalFormTerm';

export const ModalNewTerm = ({ addPolicyToList }) => {
  const [load, setLoad] = useState(false);
  const [description, setDescription] = useState('');

  const handleContentChange = (event) => {
    setDescription(event.target.value);
  };

  const onSubmit = async () => {
    setLoad(true);

    try {
      await requests.addTermUse({ description });

      addPolicyToList();
      setLoad(false);
      setDescription('');

      toast.success('Criado com sucesso');
    } catch (error) {
      setLoad(false);

      toast.error('Erro ao criar, tente novamente!');
    }
  };

  return (
    <>
      <ModalFormTerm
        txtButton="Novo tópico"
        txtHeader="Cadastro dos Termos de Uso"
        textButtonConfirm="Salvar"
        onSubmit={onSubmit}
        description={description}
        handleContentChange={handleContentChange}
        load={load}
      />
    </>
  );
};

export default ModalNewTerm;
