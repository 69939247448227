import React from 'react';

import { Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react';

import ListCollapse from './ListCollapse';

const TableTimeLine = ({
  list,
  tripList,
  zoomIntoTrip,
  paginate,
  currentTripData = null,
  setCurrentTripData = null,
  currentTripIsTrackable = false,
}) => {
  return (
    <>
      <Table variant="striped" colorScheme="blackAlpha" size="sm" textAlign="left" textStyle="subtitle">
        <Thead>
          <Tr minH="50px">
            <Th></Th>
            <Th>Status</Th>
            <Th>Viagem</Th>
            <Th>Início</Th>
            <Th>Conclusão</Th>
            <Th>{`CT-e's`}</Th>
            <Th>Motorista</Th>
            <Th>Placas</Th>
            <Th>Empresa</Th>
            <Th>Arquivos</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {list?.length > 0 && (
            <>
              {list?.map((entity, key) => {
                return (
                  <ListCollapse
                    entity={entity}
                    entityMarker={
                      tripList.filter((value) => {
                        return value.identifier === entity.identifier;
                      })[0]
                    }
                    key={key}
                    zoomIntoTrip={zoomIntoTrip}
                    currentTripData={currentTripData}
                    setCurrentTripData={setCurrentTripData}
                    currentTripIsTrackable={currentTripIsTrackable}
                  />
                );
              })}
            </>
          )}
        </Tbody>
      </Table>
      {paginate}
    </>
  );
};

export default TableTimeLine;
