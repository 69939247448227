import { useState, useEffect } from 'react';

import { Button, Card } from '@chakra-ui/react';

import CommonList from '../../../components/CommonList/CommonList';
import Page from '../../../components/Page';
import permissions from '../../../services/permissions';

import taxList from './mock/taxList';

const TaxInquiryPage = () => {
  const hasPermission = permissions.vendemmiaTaxInquiry;
  const [isMobile, setIsMobile] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [list, setList] = useState(taxList.data);

  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const load = (filters, key) => {
    /* setList(taxList.data); */
  };

  const loadMore = (filters, key) => {
    setIsLoading(true);
    setIsLoadingMore(true);
    requests.listProcess(filters, metadata.current_page + 1).then((data) => {
      if (request.current && request.current > key) {
        return;
      }

      request.current = key;

      setList((prevList) => [...prevList, ...data.data]);
      setMetadata(data.meta);
      setIsLoading(false);
      setIsLoadingMore(false);
    });
  };

  useEffect(() => {
    console.log(list);
  }, [list]);

  return (
    <Page
      screen="tax-inquiry"
      title="Consulta tributária"
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      /* textFilterPlaceholder="Nome do default" */
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      loadMore={loadMore}
      isContentLoadingMore={isLoadingMore}
      /* filterOptions={filterOptions} */
      isRefreshLoading={isLoading || isLoadingMore}
      showPeriodFilter={false}
      showRefreshData={false}
      showFilters={false}
      allowFiltersOverride={false}>
      <CommonList
        title={'Consulta tributária'}
        categories={[
          'Consulta',
          'Filial Vendemia',
          'Cliente',
          'Modalidade importação',
          'Benefício fiscal',
          'Solicitação',
          'Validade',
          'Status',
          /* 'Ações', */
        ]}
        subCategories={[
          'Consulta',
          'Filial Vendemia',
          'Cliente',
          'Modalidade importação',
          'Benefício fiscal',
          'Solicitação',
          'Validade',
          'Status',
          /* 'Ações', */
        ]}
        //conditionals
        hiddenCategories={['createdAt']}
        hasCollapsed={true}
        collapsedContentType={'table'}
        /* collapsed={}
        collapsedIcon={} */
        list={list}
        subList={list}>
        {/* <Button>Detalhes</Button> */}
      </CommonList>
    </Page>
  );
};

export default TaxInquiryPage;
