import React, { useEffect, useState } from 'react';

import { MdAdd } from 'react-icons/md';

import { Button, Flex, Text, useDisclosure } from '@chakra-ui/react';

import permissions from '../../../../../services/permissions';
import CardTravel from '../../../components/Tables/Travel/CardTravel';
import TableTravel from '../../../components/Tables/Travel/TableTravel';

import ModalFreight from './ModalFreight';

const TravelList = ({
  tab,
  setTab,
  load,
  list,
  setAction,
  metadata,
  setMetadata,
  action,
  loadData
}) => {
  const [origin, setOrigin] = useState([0, 0]);
  const [destination, setDestination] = useState([0, 0]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const resolution = window.innerWidth;

  useEffect(() => {
    setOrigin([0, 0]);
    setDestination([0, 0]);
  }, [isOpen]);

  return (
    <>
      {resolution > 768 ? (
        <TableTravel
          tab={tab}
          setTab={setTab}
          list={list}
          load={load}
          metadata={metadata}
          setMetadata={setMetadata}
          action={action}
          setAction={setAction}
          showDetails={true}
          loadData={loadData}
        />
      ) : (
        <CardTravel
          list={list}
          load={load}
          metadata={metadata}
          setMetadata={setMetadata}
          action={action}
          setAction={setAction}
          showDetails={true}
        />
      )}

      {permissions.logisticTripCreate && (
        <Flex className="float-button-modal">
          <Button
            bgColor="green"
            color="white"
            borderRadius="50px"
            shadow="xl"
            h="50px"
            py="15px"
            px="30px"
            onClick={() => {
              onOpen();
            }}
            _hover={{ opacity: '0.5', transition: '0.9s' }}>
            <MdAdd />
            <Text textStyle="paragraph" ml="10px">
              Nova viagem
            </Text>
          </Button>

          <ModalFreight
            isOpen={isOpen}
            onClose={onClose}
            title="Cadastro de viagem"
            subTitle="Você pode adicionar os dados e salvar sua viagem como rascunho."
            action={action}
            setAction={setAction}
            setOrigin={setOrigin}
            setDestination={setDestination}
            tab={tab}
            entity={false}
          />
        </Flex>
      )}
    </>
  );
};

export default TravelList;
