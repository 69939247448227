import React, { useEffect, useState } from 'react';

import { MdDownloadForOffline } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Text } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import ExpandContainer from '../../../components/ExpandContainer/ExpandContainer';
import FloatActionButton from '../../../components/Generic/FloatActionButton';
import Layout from '../../../components/Layout';
import Menubar from '../../../components/MenuBar/Menubar';
import Paginate from '../../../components/Paginate/Paginate';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import useScreenPage from '../../../hooks/useScreenPage';
import api from '../../../services/api';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsMapping } from '../../../utils/filters/filterPresets';

import initialFields from './components/initialFields';
import MapTable from './components/MapTable';
import ModalConfigFilter from './components/ModalConfigFilter';

const MappingPage = () => {
  //Detecção de chamadas as APIs
  const screenPage = useScreenPage();

  const [isLoading, setIsLoading] = useState(true);
  const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);
  const [isDownloadingXlsx, setIsDownloadingXlsx] = useState(false);

  const [list, setList] = useState([]);

  const [filterOptions, setFilterOptions] = useState([]);
  const [filterFields, setFilterFields] = useState(initialFields);
  const [filterApplied, setFilterApplied] = useState({});
  const [appliedFields, setAppliedFields] = useState([]);

  const [action, setAction] = useState(1);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [isOpenFloatButton, setIsOpenFloatButton] = useState(false);

  let navigate = useNavigate();

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsMapping());
  };

  const isRefreshing = () => {
    return isLoading;
  };

  const refresh = () => {
    load();
  };

  const load = () => {
    loadList();
  };

  const loadList = () => {
    setIsLoading(true);

    if (filterApplied !== null) {
      delete filterApplied?.search;
    }

    requests
      .listMap(
        {
          ...filterApplied,
          fields: filterFields,
        },
        metadata.current_page > 0 ? metadata.current_page : 1
      )
      .then((data) => {
        setList(data.data);
        setMetadata(data.meta);
        refreshApliedFields(filterFields);
        setIsLoading(false);
      });
  };

  const listAdditionalFields = () => {
    setIsLoading(true);

    requests.listAditionalFields({ screenPage }).then((data) => {
      var aux = initialFields;

      aux.forEach((item, index) => {
        if (item.id === 'camposAdicionais') {
          data.data.map((adittionalField) => {
            aux[index].subFields.push({
              id: 'camposAdicionais',
              name: adittionalField.label,
              active: true,
            });
          });
        }
      });
      setIsLoading(false);
      refreshApliedFields(aux);
      setFilterFields(aux);
    });
  };

  function clone(obj) {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null === obj || 'object' != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (var i = 0, len = obj.length; i < len; i++) {
        copy[i] = clone(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  const refreshApliedFields = (fields) => {
    var copy = [];
    for (var attr in fields) {
      if (fields.hasOwnProperty(attr)) copy[attr] = clone(fields[attr]);
    }
    setAppliedFields(copy);
  };

  const downloadXlsx = () => {
    setIsDownloadingXlsx(true);

    var filters = filterApplied;
    filters.fields = appliedFields;

    api
      .get(`/map-filter/map/export-xlsx`, {
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('session-token'),
        },
        params: { filters: JSON.stringify(filters) },
      })
      .then((response) => {
        const fileType = response.headers['content-type'];
        const blob = new Blob([response.data], { fileType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'exportXLSX.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .finally(() => {
        setIsDownloadingXlsx(false);
      });
  };

  const downloadCsv = () => {
    setIsDownloadingCsv(true);
    var filters = filterApplied;
    filters.fields = appliedFields;

    api
      .get(`/map-filter/map/export-csv`, {
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('session-token'),
        },
        params: { filters: JSON.stringify(filters) },
      })
      .then((response) => {
        const fileType = response.headers['content-type'];
        const blob = new Blob([response.data], { fileType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'exportCSV.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .finally(() => {
        setIsDownloadingCsv(false);
      });
  };

  useEffect(() => {
    permissions.comexMapping || permissions.comexProcess ? '' : navigate('/');
  }, []);

  useEffect(() => {
    load();
    getFilterOptions();
  }, [action, filterApplied]);

  useEffect(() => {
    listAdditionalFields();
  }, []);

  return (
    <Layout>
      <Menubar
        title="Mapeamento"
        linkTree={[{ link: '#', title: 'Importação' }]}
        isRefreshLoading={isRefreshing()}
        filterOptions={filterOptions}
        triggerRefreshData={refresh}
        hideAllFilters={true}
        showRefreshData={true}
      />

      <Card m="10px">
        <ExpandContainer>
          <Box w="full">
            <ScreenLoader isLoading={isLoading}>
              <Box overflow="auto" h="calc(100vh - 220px)">
                <MapTable data={list} appliedFields={appliedFields} />
              </Box>
            </ScreenLoader>

            {metadata.total_pages > 1 && (
              <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
            )}
          </Box>
        </ExpandContainer>

        <ScreenLoader isLoading={isLoading}>
          <Box maxH="calc(100vh - 230px)" overflow="auto">
            <MapTable data={list} appliedFields={appliedFields} />
          </Box>
        </ScreenLoader>

        {metadata.total_pages > 1 && (
          <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
        )}
      </Card>

      <FloatActionButton
        options={[
          <>
            <Button
              h="46px"
              px="24px"
              py="14px"
              bgColor="green"
              color="#FFFFFF"
              borderRadius="27px"
              _hover={{ bgColor: '#70D499' }}
              isLoading={isDownloadingCsv}
              rightIcon={<MdDownloadForOffline color="#FFFFFF" size={20} />}
              onClick={() => downloadCsv()}>
              <Text>Exportar .csv</Text>
            </Button>
          </>,
          <>
            <Button
              h="46px"
              px="24px"
              py="14px"
              bgColor="green"
              color="#FFFFFF"
              borderRadius="27px"
              _hover={{ bgColor: '#70D499' }}
              isLoading={isDownloadingXlsx}
              rightIcon={<MdDownloadForOffline color="#FFFFFF" size={20} />}
              onClick={() => downloadXlsx()}>
              <Text>Exportar .xlsx</Text>
            </Button>
          </>,
          <ModalConfigFilter
            key={3}
            setFilterApplied={setFilterApplied}
            refreshApliedFields={refreshApliedFields}
            setFilterFields={setFilterFields}
            filterFields={filterFields}
            filterOptions={filterOptions}
          />,
        ]}
        isOpen={isOpenFloatButton}
        setIsOpen={setIsOpenFloatButton}
      />
    </Layout>
  );
};

export default MappingPage;
