import React, { useEffect, useState } from 'react';

import { MdAdd } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Button, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

import requests from '../../../../services/requests';

const QuoteList = ({ process, setIsLoadingData }) => {
  const [quotes, setQuotes] = useState([]);

  const getQuotes = () => {
    if (!process || !process.identifier) {
      return;
    }

    setIsLoadingData(true);
    requests
      .getProcessQuote(process.identifier)
      .then((data) => {
        setQuotes(data.data);
        setIsLoadingData(false);
      })
      .catch(() => {
        setIsLoadingData(false);
      });
  };

  useEffect(() => {
    getQuotes();
  }, [process]);

  return (
    <>
      <Flex justify="space-between" mb="10px">
        <Flex align="center" gap="2px" direction="column">
          <Text textStyle="tableTitle" fontWeight="800" color="primary" mb="10px">
            Cotações
          </Text>
        </Flex>

        <Link to="/vendemmia/quote/new">
          <Button leftIcon={<MdAdd />} variant="primary">
            <Text fontSize="14px" textColor="#FFFFFF">
              Nova cotação
            </Text>
          </Button>
        </Link>
      </Flex>
      <Table className="table-default">
        <Thead border="1px" borderBottom="1px">
          <Tr>
            <Th>Nome</Th>
            <Th>Tipo</Th>
            <Th>Carga disponível</Th>
            <Th>Origem</Th>
            <Th>Destino</Th>
            <Th>Valor invoice</Th>
          </Tr>
        </Thead>
        <Tbody border="1px">
          {quotes.length > 0 ? (
            quotes.map((quote, index) => (
              <Tr key={index} border="none" cursor="pointer" style={{ '--delay': `${index / 10}s` }}>
                <Td style={{ color: '#6C48C2' }}>
                  <Link to={`/vendemmia/quote/detail/${quote.identifier}`}>
                    {quote.name ?? '-'}
                  </Link>
                </Td>
                <Td>{quote?.transportModality ?? '-'}</Td>
                <Td>{quote?.availableDateFormatted ?? '-'}</Td>
                <Td>{quote?.originPortName ? quote?.originPortName + ' (' + quote?.originPortCountry + ')' : '-'}</Td>
                <Td>
                  {quote?.destinyPortName ? quote?.destinyPortName + ' (' + quote?.destinyPortCountry + ')' : '-'}
                </Td>
                <Td>{quote?.invoiceValueFormatted ?? '-'}</Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={6}>
                <Flex justify="center" my="25px">
                  Nenhum dado encontrado para os parâmetros filtrados.
                </Flex>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </>
  );
};

export default QuoteList;
