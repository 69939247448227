import React, { useEffect, useRef, useState } from 'react';

import { MdOutlineHelp } from 'react-icons/md';

import { Flex, Text } from '@chakra-ui/react';

import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsWarehouse } from '../../../utils/filters/filterPresets';
import CardNumberStatus from '../components/CardNumberStatus';

import ChartStockCoverage from './components/ChartStockCoverage';
import ChartStockTurnover from './components/ChartStockTurnover';

const KPIWmsPage = () => {
  const hasPermission = permissions.metricsKpiWarehouse;

  const [isMobile, setIsMobile] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const request = useRef(0);

  const [isLoadingStockCoverageChart, setIsLoadingStockCoverageChart] = useState(false);
  const [isLoadingStockTurnoverChart, setIsLoadingStockTurnoverChart] = useState(false);
  const [isLoadingCurrentStockValue, setIsLoadingCurrentStockValue] = useState(false);
  const [isLoadingLackOfProducts, setIsLoadingLackOfProducts] = useState(false);
  const [isLoadingStockCoveragePerDay, setIsLoadingStockCoveragePerDay] = useState(false);

  const [stockCoverageChartData, setStockCoverageChartData] = useState();
  const [stockCoveragePerDayData, setStockCoveragePerDayData] = useState();
  const [stockTurnoverChartData, setStockTurnoverChartData] = useState();
  const [currentStockValue, setCurrentStockValueData] = useState();
  const [lackOfProducts, setLackOfProducts] = useState();
  const [lackOfProductsList, setLackOfProductsList] = useState();
  const [filterOptions, setFilterOptions] = useState([]);

  const loadCharts = (filters, key) => {
    setIsLoading(true);

    loadCurrentStockValue(filters);
    loadLackOfProducts(filters);
    loadStockCoverage(filters);
    loadStockTurnover(filters);
    loadStockCoveragePerDay(filters);

    setIsLoading(false);
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsWarehouse());
  };
  useEffect(() => {
    getFilterOptions();
  }, []);

  const formatValue = (value) => {
    if (value === undefined) {
      return '';
    }
    return value.toLocaleString('pt-BR');
  };

  //Custo de estoque atual
  const loadCurrentStockValue = async (filters) => {
    const copyFilters = { ...filters };
    setIsLoadingCurrentStockValue(false);

    try {
      if (copyFilters !== null) {
        delete copyFilters?.search;
      }

      const res = await requests.getWarehouseKpiCurrentStockValue({ filters: copyFilters });

      setCurrentStockValueData(res.value);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }

    setIsLoadingCurrentStockValue(true);
  };

  //Itens com vendas sem estoque
  const loadLackOfProducts = async (filters) => {
    const copyFilters = { ...filters };
    setIsLoadingLackOfProducts(false);

    try {
      if (copyFilters !== null) {
        delete copyFilters?.search;
      }

      const res = await requests.getWarehouseKpiCurrentStockLackOfProduct({ filters: copyFilters });
      const resList = await requests.getWarehouseKpiCurrentStockLackOfProductList({ filters: copyFilters });
      setLackOfProducts(res.quantity);
      setLackOfProductsList(resList);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }

    setIsLoadingLackOfProducts(true);
  };

  const loadStockCoverage = async (filters) => {
    setIsLoadingStockCoverageChart(true);

    let itenPerPage = '/12';

    try {
      const res = await requests.getWarehouseKpiStockCoverage(filters, itenPerPage);

      setStockCoverageChartData(res.list);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }

    setIsLoadingStockCoverageChart(false);
  };

  const loadStockTurnover = async (filters) => {
    setIsLoadingStockTurnoverChart(true);

    let itenPerPage = '/15';

    try {
      const res = await requests.getWarehouseKpiStockTurnover(filters, itenPerPage);

      setStockTurnoverChartData(res);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }
    setIsLoadingStockTurnoverChart(false);
  };

  const loadStockCoveragePerDay = async (filters) => {
    setIsLoadingStockCoveragePerDay(true);

    let itenPerPage = '/31';

    try {
      const res = await requests.getWarehouseKpiStockCoveragePerDay(filters, itenPerPage);

      setStockCoveragePerDayData(res);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }
    setIsLoadingStockCoveragePerDay(false);
  };

  return (
    <Page
      screen="comex-warehouse"
      title="KPI de armazém"
      breadcrumbs={[{ link: '#', title: 'Métricas' }]}
      textFilterPlaceholder="Processo, empresa ou ref"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      // list={list}
      // metadata={metadata}
      loadCharts={loadCharts}
      isContentLoading={isLoading}
      // isContentLoadingMore={isLoadingMore}
      filterOptions={filterOptions}
      // allowFiltersOverride={true}
      isRefreshLoading={isLoading || isLoadingMore}>
      <Flex display={{ base: 'flex', md: 'grid' }} mx="20px" gap="20px" direction="column">
        <Flex gap={'10px'} mt={'10px'} flexDirection={{ base: 'column', md: 'row' }}>
          <ScreenLoader isLoading={!isLoadingCurrentStockValue} style={{ width: 'initial' }}>
            <CardNumberStatus
              text="Custo de estoque atual"
              bgColor="#6C48C2"
              fontSizeTitle={56}
              bgImage={true}
              textColor="#FFFFFFE8"
              value={currentStockValue}
              isMoney={true}
              minWidth="385px"
              isLoading={isLoadingCurrentStockValue}
              iconHelp={<MdOutlineHelp color="#FFFFFFE8" size={20} />}
              textHelp="Soma de todo o valor em estoque na data de hoje"
              formatValue={formatValue}
            />
          </ScreenLoader>
          <ScreenLoader isLoading={!isLoadingLackOfProducts} style={{ width: 'initial' }}>
            <CardNumberStatus
              text="Itens com vendas sem estoque"
              textDescription="itens sem estoque"
              bgColor="#FFFFFF"
              fontSizeTitle={56}
              textColor="primary"
              hasDrawer={{
                data: lackOfProductsList,
                header: ['Cód. Produto', 'Quantidade Vendida'],
                content: ['codProduto', 'qtt'],
                formatField: 3,
              }}
              value={lackOfProducts}
              minWidth="385px"
              isLoading={isLoadingLackOfProducts}
              iconHelp={<MdOutlineHelp color="#00000040" size={20} />}
              textHelp="Quantidade de produtos sem estoque e que tiveram saída nos últimos 90 dias"
              formatValue={formatValue}
            />
          </ScreenLoader>
        </Flex>
        <Flex bgColor="#FFFFFF" flexDirection={'column'} p={{ base: '20px 30px', md: '40px' }}>
          <Flex flexDirection={{ base: 'column', md: 'row' }} gap={{ base: '15px', md: '35px' }} mb={{ base: '15px', md: '0' }}>
            <Text mb={{ base: '0', md: '30px' }} fontSize={{ base: '22px', md: '31px' }} fontWeight={'bold'} color={'titleColor'}>
              Cobertura de estoque em dias
            </Text>
            {/* <CardDrawerButton 
              mt={'10px'}
              hasDrawerCard={{
                data: stockCoverageChartData,
                header: ["Cód. Produto", "Valor unitário", "Quantidade Vendida"],
                content: ["codProduto", "unityValue", "qttOutput"],
                formatField: 3
              }} 
            /> */}
          </Flex>
          <ScreenLoader isLoading={isLoadingStockCoveragePerDay}>
            <ChartStockCoverage data={stockCoveragePerDayData} />
          </ScreenLoader>
        </Flex>
        <Flex bgColor="#FFFFFF" flexDirection={'column'} p={{ base: '20px 30px', md: '40px' }}>
          <Text mb={{ base: '0', md: '30px' }} fontSize={{ base: '22px', md: '31px' }} fontWeight={'bold'} color={'titleColor'}>
            Giro de estoque
          </Text>
          <ScreenLoader isLoading={isLoadingStockTurnoverChart}>
            <ChartStockTurnover data={stockTurnoverChartData} />
          </ScreenLoader>
        </Flex>
      </Flex>
    </Page>
  );
};

export default KPIWmsPage;
