import React, { useState } from 'react';

import {
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import { Flex, Text, Tag } from '@chakra-ui/react';

import CalendarWorkloadItem from '../../pages/comex/calendar/components/CalendarWorkloadItem';

import CalendarMonthItem from './CalendarMonthItem';

const CalendarMonthColumn = ({ day, delay, modality = 'event', isMobile = false }) => {
  const screenWidth = window.innerWidth
  const [showMore, setShowMore] = useState(false);

  const isToday = () => {
    const today = new Date();
    const compareDate = new Date(day.date);

    return (
      today.getDate() === compareDate.getDate() &&
      today.getMonth() === compareDate.getMonth() &&
      today.getFullYear() === compareDate.getFullYear()
    );
  };

  return (
    <>
      {!isMobile ? (
        <Flex display={{ base: 'none', md: 'flex' }}
          direction="column"
          w="100%"
          alignItems="center"
          style={{ '--delay': `${delay / 20}s`, transformOrigin: 'left' }}
          className="up-anim"
        >
          <Text 
            p="15px" 
            fontSize="18px" 
            w="100%" 
            color={isToday() ? '#2f85be' : '#412B75'}
          >
            {day.formatted.slice(0,2)}
          </Text>

          <Flex 
            direction="column" 
            bg="white" 
            h="100%" 
            w="100%" 
            gap="10px" 
            p="0 26px 15px" 
            alignItems="center"
            minH={{ base: "initial", md: "237px" }}
          >
            {modality === 'event' ? <>
              {day.events?.length > 0 &&
              day.events.map((event, key) => (key < 3 || showMore) && <CalendarMonthItem entity={event} key={key} />)}
              {day.events?.length > 3 && (
                <Text
                  color="#6794DC"
                  textDecoration="underline"
                  cursor="pointer"
                  fontSize={'14px'}
                  onClick={() => setShowMore(!showMore)}
                >
                  {showMore ? 'Ver menos' : `+ ${(day.events.length - 3)} processos`}
                </Text>
              )}
            </> :<>
              {day.events.map((data, key) => {
                return <CalendarWorkloadItem entity={data} key={key} />;
              })}
            </>}
          </Flex>
        </Flex>
      ) : (
        <>
          <h2>
            <AccordionButton>
              <Flex 
                bg={isToday() ? '#2f85be' : '#6794DC'} 
                p="15px" 
                fontSize="14px" 
                w="100%" 
                color="white" 
                textAlign="center" 
                justifyContent="space-between" 
                alignItems="center"
              >

                <Tag>
                  {day.events.length}
                </Tag>
                <p>{isToday() ? `Hoje ${day.formatted}` : day.fullFormatted}</p>
                {' '} <AccordionIcon />
              </Flex>
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Flex direction="column" bg="white" h="100%" w="100%" gap="10px" p="15px 2px" alignItems="center">
              {modality === 'event' ? <>
                {day.events?.length > 0 &&
                  day.events.map((event, key) => (key < 3 || showMore) && <CalendarMonthItem entity={event} key={key} />)}
                {day.events?.length > 3 && (
                  <Text color="#6794DC" cursor="pointer" onClick={() => setShowMore(!showMore)}>
                    {showMore ? 'Ver menos' : `Ver todos (${day.events.length})`}
                  </Text>
                )}
                {day.events?.length <= 0 &&
                  <Text fontSize="14px">Não há registros para esta data.</Text>
                }
              </> :<>
                {day.events.map((data, key) => {
                  return <CalendarWorkloadItem entity={data} key={key} />;
                })}
              </>}
            </Flex>
          </AccordionPanel>
        </>
      )}
    </>
  );
};

export default CalendarMonthColumn;
