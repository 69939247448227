import React, { useEffect, useState } from 'react';

import jwt_decode from 'jwt-decode';
import { ToastContainer } from 'react-toastify';

import { Box } from '@chakra-ui/react';

import permissions from '../services/permissions';

import Footer from './Footer/Footer';
import Header from './Header/Header';
import ScreenLoader from './ScreenLoader/ScreenLoader';

const Layout = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  const redirectToLogin = () => {
    let pathname = window.location.pathname;

    window.localStorage.clear(); // Clears any login information

    if (pathname !== '/') {
      // Stores page to redirect user after login
      window.localStorage.setItem('redirectTo', pathname);

      // Stores max age to redirect user after login
      let expiresAt = new Date();
      expiresAt.setDate(expiresAt.getDate() + 1);
      window.localStorage.setItem('redirectToExpiresAt', expiresAt);
    }

    window.location.href = '/'; // Redirects to login page
  };

  useEffect(() => {
    if (location.pathname === '/dashboard' && !permissions.comex) {
      if (permissions.warehouse) {
        if (permissions.warehouseReceipt) {
          window.location.href = '/warehouse/receipt';
          return;
        }
        if (permissions.warehouseStock) {
          window.location.href = '/warehouse/stock';
          return;
        }
        if (permissions.warehousePickingAndPacking) {
          window.location.href = '/warehouse/picking-packing';
          return;
        }
        if (permissions.warehouseExpedition) {
          window.location.href = '/warehouse/expedition';
          return;
        }
      }

      if (permissions.logistic) {
        if (permissions.logisticRealtime) {
            window.location.href = '/logistic/trip/timeline';
            return;
        }
        if (permissions.logisticTripFullManagement) {
            window.location.href = '/logistic/trip';
            return;
        }
        if (permissions.logisticDriverManagement) {
            window.location.href = '/logistic/drivers';
            return;
        }
        if (permissions.logisticFleetManagement) {
            window.location.href = '/logistic/fleet';
            return;
        }
      }

      if (permissions.metrics) {
        if (permissions.metricsComex) {
            window.location.href = '/metrics/analytics';
            return;
        }
        if (permissions.metricsWarehouse) {
            window.location.href = '/metrics/warehouse';
            return;
        }
        if (permissions.metricsKpiComex) {
            window.location.href = '/metrics/kpi-comex';
            return;
        }
        if (permissions.metricsKpiWarehouse) {
            window.location.href = '/metrics/kpi-warehouse';
            return;
        }
      }

      if (permissions.vendemmia) {
        if (permissions.vendemmiaFreightQuote) {
            window.location.href =  '/vendemmia/quote';
            return;
        }
        if (permissions.vendemmiaOperationSpecification) {
            window.location.href =  '/vendemmia/operation-specification';
            return;
        }
        if (permissions.vendemmiaMIA) {
            window.location.href =  '/vendemmia/ai-assistant';
            return;
        }
        if (permissions.vendemmiaSQLReportManagement) {
            window.location.href =  '/vendemmia/report-generator';
            return;
        }
        if (permissions.vendemmiaSQLReportView) {
            window.location.href =  '/vendemmia/report-view';
            return;
        }
      }
    }

    setIsLoading(true);
    let token = window.localStorage.getItem('session-token');
    if (token && token.length > 0) {
      let decoded = jwt_decode(token);
      let dateToExpires = new Date(decoded.exp * 1000);
      if (Date.now() <= dateToExpires) {
        setIsLoading(false);
        return;
      }
    }

    redirectToLogin();
    setIsLoading(false);
  }, []);

  return (
    <ScreenLoader isLoading={isLoading}>
      <Header />

      <Box as="main" bgColor="background" minH="calc(100vh - 160px)" pt="10px" pb="5px">
        {children}
      </Box>

      <Footer />

      <ToastContainer />
    </ScreenLoader>
  );
};

export default Layout;
