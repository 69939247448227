import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { Text } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import HelpTooltip from '../../../components/HelpTooltip/HelpTooltip';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import useScreenPage from '../../../hooks/useScreenPage';
import requests from '../../../services/requests';
import CalendarSection from '../../comex/calendar/components/CalendarSection';

const DashboardWeeklySchedule = props => {

  const { 
    filterInfo, 
    searchString, 
    shouldRefresh, 
    setIsRefreshing,
  } = props

  //Detecção de chamadas as APIs
  const screenPage = useScreenPage()

  const [isLoading, setIsLoading] = useState(true);

  const [list, setList] = useState([]);

  const load = () => {
    loadList();
  };

  const loadList = () => {
    setIsLoading(true);

    requests
      .calendar('week', 'event', {
        filters: { clients: filterInfo.client },
        search: searchString,
        screenPage
      })
      .then((data) => {
        setList(data.weeks[0]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (filterInfo) {
      load();
    }
  }, [filterInfo, searchString]);

  useEffect(() => {
    if (shouldRefresh) {
      load();
    }
  }, [shouldRefresh]);

  useEffect(() => {
    setIsRefreshing(isLoading);
  }, [isLoading]);

  return (
    <Card
      header={
        <>
          <Text textStyle="cardTitle" display={{ base: 'none', md: 'flex' }}>
            Agenda da semana
            <HelpTooltip tooltip="Os filtros de data não afetam essa seção" />
          </Text>
          <Text textStyle="cardTitle" fontSize={{ base: '18px', md: '20px' }} display={{ base: 'flex', md: 'none' }}>
            Agenda da semana
          </Text>
          <Link to="/comex/calendar">
            <Text color="primary" fontSize={{ base: '14px', md: '16px' }}>
              Ver agenda completa {'>'}
            </Text>
          </Link>
        </>
      }>
      <ScreenLoader isLoading={isLoading} minH="500px">
        {list && <CalendarSection list={list ?? {}} />}
      </ScreenLoader>
    </Card>
  );
};

export default DashboardWeeklySchedule;
