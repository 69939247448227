import { toast } from 'react-toastify';

const generateExportFile = (response, fileName, extension, contentType) => {
  const toastId = toast.info('Aguarde enquanto preparamos a exportação...', {
    autoClose: false, // Mantém o toast na tela até que seja fechado manualmente
    closeOnClick: false, // Impede que o usuário feche o toast ao clicar nele
    draggable: false, // Impede que o usuário arraste o toast
    closeButton: false, // Não exibe o botão de fechar o toast
  });

  //xlsx ou csv

  let contentTypeValue;

  if (extension === 'xlsx') {
    contentTypeValue = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  } else {
    contentTypeValue = 'text/plain; charset=UTF-8'
  }

  if (response && fileName) {
    const date = new Date();

    let fileNameDate = date.getFullYear() + date.getMonth() + date.getDate();

    const blob = new Blob([response], { contentTypeValue });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}-${fileNameDate}.${extension ? extension : 'xlsx'}`);

    document.body.appendChild(link);

    link.click();
    link.remove();

    toast.dismiss(toastId); // Remove o toast exibido anteriormente
    toast.success('Exportação efetuado com sucesso!', {
      autoClose: 1500, // Tempo em milissegundos que o toast ficará na tela
    });
  } else {
    toast.dismiss(toastId); // Remove o toast exibido anteriormente
    toast.error('Problemas ao realizar exportação, tente novamente ou entre em contato com o Administrador');
  }
};

export default generateExportFile;
