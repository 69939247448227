import React, { useEffect, useRef, useState } from 'react';

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, Flex, Image, Menu, MenuButton, MenuList, Text, useDisclosure } from '@chakra-ui/react';

import { headerLinks } from './HeaderLinks';

export const NavbarContent = () => {
  const [activeIcon, setActiveIcon] = useState(null);
  const { onToggle, isOpen, onClose } = useDisclosure();
  const location = useLocation();
  const navigate = useNavigate();

  const isActualPageActive = (item) => {
    if (item.link && item.link.length > 0 && location.pathname === item.link) {
      return true;
    }

    return item.subMenu && item.subMenu.length > 0 && item.subMenu.some((element) => location.pathname.includes(element?.link));
  };

  const toggleIcon = (item) => {
    setActiveIcon(activeIcon === item ? null : item);
    onToggle();
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveIcon(null);
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <Flex gap={{ base: '14px', lg: '35px' }} align={{ base: 'flex-start', lg: 'center' }} direction={{ base: 'column', lg: 'row' }}>
      <Flex
        align="center"
        gap={{ base: '14px', lg: '35px' }}
        alignItems="center"
        justify={{ base: 'flex-start', lg: 'center' }}
        direction={{ base: 'column', lg: 'row' }}>
        {headerLinks.map((item, key) => (
          <React.Fragment key={key}>
            <Menu direction="column">
              <MenuButton
                ref={dropdownRef}
                w={{ base: '100%', lg: 'initial' }}
                textAlign={{ base: 'left', lg: 'initial' }}
                variant="link"
                fontWeight="bold"
                _hover={{ textDecoration: 'none' }}
                as={Button}
                rightIcon={
                  (item.subMenu && item.subMenu.length > 0) ?
                    (isOpen && activeIcon === item.title) ? (
                      <MdKeyboardArrowUp color={{ base: '#422C76', lg: '#FFFFFFB3' }} />
                    ) : (
                      <MdKeyboardArrowDown color={{ base: '#422C76', lg: '#FFFFFFB3' }} />
                    )
                  : (<></>)
                }
                onClick={() => {
                  if (item.link && item.link.length > 0) {
                    navigate(item.link);
                  } else {
                    toggleIcon(item.title);
                  }
                }}>
                <Box
                  textStyle="tableTitle"
                  color={{ base: '#422C76', lg: isActualPageActive(item) ? 'white' : '#ffffffb5' }}
                  fontWeight={isActualPageActive(item) ? '700' : '500'}
                  fontSize={{ base: '16px', lg: '16px', xl: '20px' }}>
                  {item.title}
                </Box>
              </MenuButton>

              {item.subMenu && item.subMenu.length > 0 &&
                <MenuList p="18px" w="327px" border="1px" borderRadius="7px" borderColor="#A885F982" shadow="lg" zIndex="999">
                  <Flex gap="14px" direction="column">
                    <Text fontSize="15px" color="primary" textStyle="paragraph">
                      {item?.subTitle}
                    </Text>

                    {item.subMenu.map((sub, index) => (
                      <Link to={sub.link} w="full" key={index} _hover={{ textDecoration: 'none' }}>
                        <Flex gap="20px" align="center">
                          <Image src={sub.icon} w="47px" h="41px" />
                          <Flex direction="column">
                            <Text
                              textStyle="tableTitle"
                              fontSize={{ base: '16px', lg: '16px', xl: '20px' }}
                              fontWeight="bold"
                              color="primary"
                              _hover={{ textDecoration: 'underline' }}>
                              {sub.title}
                            </Text>
                            <Text textStyle="paragraph" fontSize="12px" color="#422C76">
                              {sub.subTitle}
                            </Text>
                          </Flex>
                        </Flex>
                      </Link>
                    ))}
                  </Flex>
                </MenuList>
              }
            </Menu>
          </React.Fragment>
        ))}
      </Flex>
    </Flex>
  );
};
