import React from 'react';

import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text, Tooltip, useDisclosure } from '@chakra-ui/react';

const ModalViewFile = ({ label, ctaButton, type, expedition, enabled = true }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const flagClick = (e) => {
    e.stopPropagation(); // isso impede a propagação do evento que vai para a página de detalhes do processo
    enabled ? onOpen() : null;
  };

  return (
    <>
      <Tooltip label={label}>
        <Box onClick={flagClick.bind(this)} cursor="pointer">
          {ctaButton}
        </Box>
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />

        <ModalContent p="10px 0" borderRadius="9px" width="full" maxW="60vw" maxH="90vh" margin={'25px'} overflowY="auto">
          <Tooltip label="Fechar tabela">
            <ModalCloseButton alt="Fechar tabela" title="Fechar tabela" />
          </Tooltip>

          <ModalBody w="full" h="full" direction="column" overflowY="auto" mt="30px">
            {type === 'romaneio' && (
              <>
                {expedition?.fileInfo?.romaneio?.identifier ? (
                  <iframe
                    src={`${process.env.REACT_APP_API_URL}/open/pickingpack/file/${expedition?.fileInfo?.romaneio?.identifier}/view`}
                    height="650px"
                    width="100%"></iframe>
                ) : (
                  <Text color="primary" textAlign="center">
                    Aguarde a disponibilidade do arquivo de Romaneio de coleta
                  </Text>
                )}
              </>
            )}

            {type === 'canhoto' && (
              <>
                {expedition?.fileInfo?.canhoto?.identifier ? (
                  <iframe
                    src={`${process.env.REACT_APP_API_URL}/open/pickingpack/file/${expedition?.fileInfo?.canhoto?.identifier}/view`}
                    height="650px"
                    width="100%"></iframe>
                ) : (
                  <Text color="primary" textAlign="center">
                    Aguarde a disponibilidade do arquivo de Canhoto
                  </Text>
                )}
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalViewFile;
