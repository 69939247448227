import React from 'react';

import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

import SortableTableHeader from '../../components/SortableTableHeader';

import ReceiptList from './ReceiptList';

const ReceiptTable = ({ list, handleSort, sorting }) => {
  return (
    <Table w="full">
      <Thead>
        <Tr>
          <Th>
            <Box></Box>
          </Th>
          <Th>
            <Flex direction="row" align="center">
              <Text>Nota fiscal</Text>
            </Flex>
          </Th>
          <SortableTableHeader column="e.status" label="Status" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.idOrdemRecto" label="OR" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.nomeRemetente" label="Remetente" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.nomeDestinatario" label="Destinatário" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.idDi" label="DI" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.origem" label="Origem" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.ufArmazem" label="Armazém" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.dtInicio" label="Início de recebimento" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.dtFim" label="Término de recebimento" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.conferenceAt" label="Data de conferência" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.qtdVolume" label="Volumes" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.idProcesso" label="Processo" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.situacao" label="Situação" sorting={sorting} handleSort={handleSort} />
        </Tr>
      </Thead>

      <Tbody>
        {list.length > 0 ? (
          <>
            {list.map((entity, key) => (
              <ReceiptList key={entity.id} count={key} entity={entity} />
            ))}
          </>
        ) : (
          <Tr>
            <Td colSpan={8}>
              <Flex justify="center" my="25px">
                Nenhum dado encontrado para os parâmetros filtrados.
              </Flex>
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};

export default ReceiptTable;
