import { useState } from 'react';

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import { Button, Flex, Grid, GridItem, Heading, Link, List, ListItem, Text } from '@chakra-ui/react';

import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import getDayOfWeek from '../../../../utils/date/getDayOfWeek';

import CategoryColumn from './CategoryColumn';

const FinanceFlowTable = ({
  fluxCashTableDataValue,
  detailsByStatus,
  handleNext,
  handlePrevious,
  forceSetSearchPeriod,
  loadDetailsByStatus,
  isLoadingCategoryColumn,
}) => {
  const { dates } = fluxCashTableDataValue;

  const formaterData = (date) => {
    const parts = date.split('-');
    return `${parts[2]}/${parts[1]}/${parts[0]}`;
  };

  const categoryColumnView = [
    {
      category: detailsByStatus && detailsByStatus.foreignExchangeAdvance,
    },
    {
      category: detailsByStatus && detailsByStatus.exchangeBalance,
    },
    {
      category: detailsByStatus && detailsByStatus.paymentOfNationalizationTaxes,
    },
    {
      category: detailsByStatus && detailsByStatus.nationalizationExpenses,
    },
    {
      category: detailsByStatus && detailsByStatus.projectedRevenue,
    },
    {
      category: detailsByStatus && detailsByStatus.invoicedBills,
    },
  ];

  return (
    <>
      <Grid as="header">
        <GridItem display={'grid'} gridTemplateColumns={'331px 1fr'}>
          <Flex>
            <Heading color={'linkColor'} fontSize={'32px'} fontWeight={'600'} lineHeight={'32px'}>
              Fluxo de caixa
            </Heading>
          </Flex>
          <Flex minH={'54px'}>
            <Button
              bg={'linkColor'}
              borderRadius={'0'}
              p={'0'}
              color={'white'}
              h={'full'}
              onClick={handlePrevious}>
              <MdKeyboardArrowLeft size={30} />
            </Button>
            <Flex align={'center'} justify={'center'} w={'full'} border={'2px solid #6C48C2'}>
              <Text color={'linkColor'} fontSize={'20px'} fontWeight={'700'}>
                Periodo: {dates && formaterData(fluxCashTableDataValue.startsAt)} até
                {dates && ' ' + formaterData(fluxCashTableDataValue.endsAt)}
              </Text>
            </Flex>
            <Button
              bg={'linkColor'}
              borderRadius={'0'}
              p={'0'}
              color={'white'}
              h={'full'}
              onClick={handleNext}>
              <MdKeyboardArrowRight size={30} />
            </Button>
          </Flex>
        </GridItem>
        <GridItem>
          <List
            display={'grid'}
            gridTemplateColumns={`repeat(8, 1fr)`}
            lineHeight={'75px'}
            justifyItems={'center'}
            alignItems={'center'}
            border={'1px solid #70707036'}
            borderRadius={'12px'}>
            <ListItem minW={'330px'}>&nbsp;</ListItem>
            {dates &&
              dates.map((item, key) => {
                return (
                  <ListItem key={key} data-leftLine={true} display={'Flex'} textAlign={'center'}>
                    <Text as={'p'} lineHeight={'initial'} color={'primary'} fontSize={'20px'} fontWeight={'500'}>
                      <Text as={'span'} lineHeight={'20px'} fontSize={'15px'}>
                        {getDayOfWeek(item)}
                      </Text>
                      <br />
                      {formaterData(item)}
                    </Text>
                  </ListItem>
                );
              })}
          </List>
        </GridItem>
      </Grid>
      {categoryColumnView.map((item, key) => {
        return (
          <ScreenLoader key={key} isLoading={isLoadingCategoryColumn}>
            <CategoryColumn
              id={key}
              detailsByStatus={item.category}
              loadDetailsByStatus={loadDetailsByStatus}
              forceSetSearchPeriod={forceSetSearchPeriod}
              fluxCashTableDataValue={fluxCashTableDataValue}
            />
          </ScreenLoader>
        );
      })}

      {/* <CategoryColumn fluxCashTableDataValue={fluxCashTableDataValue} detailsByStatus={detailsByStatus.total} id={6} /> */}
    </>
  );
};

export default FinanceFlowTable;
