import React, { useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';

import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsDriver } from '../../../utils/filters/filterPresets';
import DriversCard from '../components/Cards/DriversCard';
import DriversTable from '../components/Tables/DriversTable';

import ModalDriver from './components/ModalDriver';

function DriverManagementPage() {
  const request = useRef(0);

  const [action, setAction] = useState(1);

  const hasPermission = permissions.logisticDriverManagement;
  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);

  const [isMobile, setIsMobile] = useState(false);

  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);

  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClose = () => {
    onClose();
  };

  const handleCloseRefresh = () => {
    onClose();
    refresh();
  };

  const handleOpen = () => {
    onOpen();
  };

  const getFilterOptions = async () => {
    setFilterOptions(filterOptionsDriver);
  };

  const exportListFABAction = (filters, key) => {
    return exportListAction(filters, key);
  };

  const exportListAction = (filters, key) => {
    const toastId = toast.info('Aguarde enquanto preparamos a exportação...', {
      autoClose: false, // Mantém o toast na tela até que seja fechado manualmente
      closeOnClick: false, // Impede que o usuário feche o toast ao clicar nele
      draggable: false, // Impede que o usuário arraste o toast
      closeButton: false, // Não exibe o botão de fechar o toast
    });

    requests
      .exportCsvUser({
        filters: {
          ...filters.filters,
          role: ['ROLE_DRIVER'],
        },
      })
      .then((response) => {
        const fileType = response.headers['content-type'];
        const blob = new Blob([response.data], { fileType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'export.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
        toast.dismiss(toastId); // Remove o toast exibido anteriormente
        toast.success('Exportação efetuado com sucesso!', {
          autoClose: 1500, // Tempo em milissegundos que o toast ficará na tela
        });
      })
      .catch(() => {
        toast.dismiss(toastId); // Remove o toast exibido anteriormente
        toast.error('Problemas ao realizar exportação, tente novamente ou entre em contato com o Administrador');
      });
  };

  const load = (filters, key, page) => {
    setIsLoading(true);
    requests
      .listUsers({
        filters: {
          ...filters.filters,
          search: filters?.search,
          role: ['ROLE_DRIVER'],
        },
      })
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }

        request.current = key;

        let options = [];
        data.data.map((driver) => {
          return options.push({
            identifier: driver.identifier,
            name: driver.name,
            email: driver.email,
            cellphone: driver.cellphone,
            status: driver.status,
            documentNumber: driver.documentNumber,
            type: driver.type,
            facial: driver?.enableFacialRecognition,
          });
        });

        setList(options);
        setMetadata(data.meta);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  const triggerRefresh = () => {
    let aux = forceLoadTrigger;
    setForceLoadTrigger(aux + 1);
  };

  return (
    <Page
      screen="driver"
      title="Gestão de motoristas"
      breadcrumbs={[{ link: '#', title: 'Transporte' }]}
      textFilterPlaceholder="Nome, e-mail, telefone do motorista"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading}
      showFilters={true}
      showPeriodFilter={false}
      allowEmptySearchPeriod={true}
      forceLoadTrigger={forceLoadTrigger}
      FAB={[
        {
          title: 'Cadastrar Motorista',
          text: 'Cadastro de Motorista',
          action: (
            <ModalDriver
              handleCloseRefresh={handleCloseRefresh}
              handleClose={handleClose}
              handleOpen={handleOpen}
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              key={2}
            />
          ),
          modality: 'custom',
        },
        {
          title: 'Exportar Motoristas',
          text: 'Exportar arquivo CSV com dados de Motoristas',
          action: exportListFABAction,
          modality: 'export-csv',
        },
      ]}>
      <Card
        m="10px"
        header={
          <Flex gap="2px" direction="column">
            <Text textStyle="cardTitle" fontWeight="bold" color="primary">
              Listagem de motoristas
            </Text>
            <Text textStyle="subtitle" color="#BEBEBE">
              Existe um total de {metadata?.total_count} motoristas
            </Text>
          </Flex>
        }>
        <Box p="15px">
          <ScreenLoader isLoading={isLoading}>
            {!isMobile ? (
              <DriversTable
                action={action}
                setAction={setAction}
                drivers={list}
                loadList={triggerRefresh}
                load={isLoading}
                setIsLoadingData={setIsLoading}
                metadata={metadata}
                setMetadata={setMetadata}
              />
            ) : (
              <DriversCard
                action={action}
                setAction={setAction}
                drivers={list}
                load={isLoading}
                metadata={metadata}
                setMetadata={setMetadata}
              />
            )}
          </ScreenLoader>
        </Box>
      </Card>
    </Page>
  );
}

export default DriverManagementPage;
