import React, { useEffect, useRef, useState } from 'react';

import { MdRemoveRedEye } from 'react-icons/md';
import { toast } from 'react-toastify';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import CurveStockChart from '../../../components/Charts/Warehouse/CurveStockChart';
import OrderProgressByStateChart from '../../../components/Charts/Warehouse/OrderProgressByStateChart';
import ShipmentAnalysisChart from '../../../components/Charts/Warehouse/ShipmentAnalysisChart';
import WerehouseSlaChart from '../../../components/Charts/Warehouse/WerehouseSlaChart';
import ExpandContainer from '../../../components/ExpandContainer/ExpandContainer';
import Page from '../../../components/Page';
import Paginate from '../../../components/Paginate/Paginate';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsWarehouse } from '../../../utils/filters/filterPresets';

import ExpeditionTable from './components/ExpeditionTable';

const ExpeditionPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const hasPermission = permissions.warehouseStock;

  const [isMobile, setIsMobile] = useState(false);

  const request = useRef(0);

  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [isLoadingChart, setIsLoadingChart] = useState(true);
  const requestExpeditionChart = useRef(0);
  const requestRemittanceChart = useRef(0);
  const [dataExpeditionChart, setDataExpeditionChart] = useState({});
  const [dataRemittanceChart, setDataRemittanceChart] = useState({});
  const [useOwnState, setUseOwnState] = useState(false);
  const [ownState, setOwnState] = useState(false);

  const [sorting, setSorting] = useState(null);
  const handleSort = (column, direction) => {
    setSorting({
      column: column,
      direction: direction,
    });
  };

  const load = (filters, key, page) => {
    setIsLoading(true);

    filters.type_storage = 'expedicao';

    requests
      .listPickingPack(filters, page)
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }
        request.current = key;

        setList(data.data);
        setMetadata(data.meta);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);
  };

  const loadCharts = (filters, key) => {
    setIsLoadingChart(true);

    delete filters.sort;
    delete filters.page;

    filters.type_storage = 'expedicao';

    requests
      .dashboardPickingPack(filters, 'expedicao')
      .then((data) => {
        if (requestExpeditionChart.current && requestExpeditionChart.current > key) {
          return;
        }
        requestExpeditionChart.current = key;

        setDataExpeditionChart(data.data);
      })
      .finally(() => {
        setIsLoadingChart(false);
      });

    requests
      .remittanceAnalysisExpedition(filters)
      .then((data) => {
        if (requestRemittanceChart.current && requestRemittanceChart.current > key) {
          return;
        }
        requestRemittanceChart.current = key;

        setDataRemittanceChart(data.data ?? { average: '0' });
      })
      .finally(() => {
        setIsLoadingChart(false);
      });
  };

  const exportListFABActionExpedition = (filters, key) => {
    return exportListFABAction(filters, key, 'expedition');
  };

  const exportListFABActionPickingPackingExpedicao = (filters, key) => {
    return exportListFABAction(filters, key, 'picking-packing-expedition');
  };

  const exportDocumentsFABActionRomaneio = (filters, key) => {
    return exportListFABAction(filters, key, 'romaneio');
  };

  const exportDocumentsFABActionCanhoto = (filters, key) => {
    return exportListFABAction(filters, key, 'canhoto');
  };

  const exportDocumentsFABActionRomaneioCanhoto = (filters, key) => {
    return exportListFABAction(filters, key, 'all');
  };

  const exportListFABAction = (filters, key, modality) => {
    const toastId = toast.info('Aguarde enquanto preparamos a exportação...', {
      autoClose: false, // Mantém o toast na tela até que seja fechado manualmente
      closeOnClick: false, // Impede que o usuário feche o toast ao clicar nele
      draggable: false, // Impede que o usuário arraste o toast
      closeButton: false, // Não exibe o botão de fechar o toast
    });

    filters.type_storage = modality;

    const date = new Date();

    let fileName = '-' + date.getFullYear() + date.getMonth() + date.getDate();
    if (modality === 'expedition') {
      fileName = 'Expedicao' + fileName + '.xlsx';
    } else if (modality === 'picking-packing') {
      fileName = 'PickingPacking' + fileName + '.xlsx';
    } else if (modality === 'picking-packing-expedition') {
      fileName = 'PickingPacking-e-Expedicao' + fileName + '.xlsx';
    } else if (modality === 'romaneio') {
      fileName = 'arquivos-romaneio' + fileName + '.zip';
    } else if (modality === 'canhoto') {
      fileName = 'arquivos-canhoto' + fileName + '.zip';
    } else if (modality === 'all') {
      fileName = 'arquivos-romaneio-e-canhoto' + fileName + '.zip';
    } else {
      return;
    }

    requests
      .exportListPickingExpedicao(filters, modality)
      .then((response) => {
        const link = document.createElement('a');

        link.setAttribute('download', fileName);
        link.href = window.URL.createObjectURL(
          new Blob([response.data], {
            fileType: response.headers['content-type'],
          })
        );

        document.body.appendChild(link);

        link.click();
        link.remove();

        toast.dismiss(toastId); // Remove o toast exibido anteriormente
        toast.success('Exportação efetuada com sucesso!', {
          autoClose: 1500, // Tempo em milissegundos que o toast ficará na tela
        });
      })
      .catch(() => {
        toast.dismiss(toastId); // Remove o toast exibido anteriormente
        toast.error('Problemas ao realizar exportação, tente novamente ou entre em contato com o Administrador');
      });
  };

  const uploadFABActionDocuments = (docs, config) => {
    setUseOwnState(true);

    const toastId = toast.info('Aguarde enquanto enviamos os arquivos para o servidor...', {
      autoClose: false, // Mantém o toast na tela até que seja fechado manualmente
      closeOnClick: false, // Impede que o usuário feche o toast ao clicar nele
      draggable: false, // Impede que o usuário arraste o toast
      closeButton: false, // Não exibe o botão de fechar o toast
    });
    setOwnState(true);
    requests
      .savePickingPackFile(docs, config)
      .then(() => {
        toast.dismiss(toastId); // Remove o toast exibido anteriormente
        toast.success('Upload efetuado com sucesso!', {
          autoClose: 1500, // Tempo em milissegundos que o toast ficará na tela
        });
        setOwnState(false);
      })
      .catch(() => {
        toast.dismiss(toastId); // Remove o toast exibido anteriormente
        toast.error('Problemas ao realizar o upload, tente novamente ou entre em contato com o Administrador');
        setOwnState(false);
      });
  };

  const [filterOptions, setFilterOptions] = useState([]);
  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsWarehouse());
  };
  useEffect(() => {
    getFilterOptions();
  }, []);

  const chartsView = [
    {
      title: 'Curva ABC',
      content: <CurveStockChart data={dataExpeditionChart} modality="EXPEDICAO" />,
      hasModal: false,
    },
    {
      title: 'Valor de nota em expedição por estado',
      content: <OrderProgressByStateChart data={dataRemittanceChart} />,
      hasModal: false,
    },
    {
      title: 'Análise de remessas',
      content: <ShipmentAnalysisChart data={dataRemittanceChart} />,
      hasModal: true,
      modal: <WerehouseSlaChart data={dataRemittanceChart} type="expedition" onOpen={onOpen} isOpen={isOpen} onClose={onClose} />,
    },
  ];

  return (
    <Page
      screen="warehouse"
      title="Expedição"
      breadcrumbs={[{ link: '#', title: 'Armazém' }]}
      textFilterPlaceholder="Buscar por NF ou destinatário"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      loadCharts={loadCharts}
      load={load}
      isContentLoading={isLoading}
      sorting={sorting}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading || isLoadingChart}
      useOwnState={useOwnState}
      ownState={ownState}
      setOwnState={setOwnState}
      FAB={[
        {
          title: 'Exportar Expedição',
          text: 'Exportar arquivo CSV com dados de Expedição',
          action: exportListFABActionExpedition,
          modality: 'export-csv',
        },
        {
          title: 'Exportar Picking & Packing + Expedição',
          text: 'Exportar arquivo CSV com dados de Picking & Packing com dados de Expedição',
          action: exportListFABActionPickingPackingExpedicao,
          modality: 'export-csv',
        },
        {
          title: 'Exportar documentos de Romaneio',
          text: 'Exportar documentos de Romaneio anexos à Expedição',
          action: exportDocumentsFABActionRomaneio,
          modality: 'export-files',
        },
        {
          title: 'Exportar documentos de Canhoto',
          text: 'Exportar documentos de Canhoto anexos à Expedição',
          action: exportDocumentsFABActionCanhoto,
          modality: 'export-files',
        },
        {
          title: 'Exportar documentos de Romaneio e Canhoto',
          text: 'Exportar documentos de Romaneio e Canhoto anexos à Expedição',
          action: exportDocumentsFABActionRomaneioCanhoto,
          modality: 'export-files',
        },
        {
          title: 'Upload de documentos',
          text: 'Upload de documentos para anexar à Expedição',
          action: uploadFABActionDocuments,
          modality: 'upload-files',
        },
      ]}>
      <Flex direction="column" gap="20px" m="10px">
        <Grid w="full" h={{ base: 'initial', md: '345px' }}>
          <Accordion display="grid" gap="10px" gridTemplateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}>
            {chartsView.map((item, key) => {
              return !item.hasModal ? (
                !isMobile ? (
                  /* desktop */
                  <Card key={key} title={item.title}>
                    <ScreenLoader isLoading={isLoadingChart}>{item.content}</ScreenLoader>
                  </Card>
                ) : (
                  /* mobile */
                  <AccordionItem key={key}>
                    <Card>
                      <AccordionButton display="flex" justifyContent="space-between" _hover="none">
                        <Text textStyle="cardTitle" fontSize="16px" p="8px 5px">
                          {item.title}
                        </Text>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        <ScreenLoader isLoading={isLoadingChart}>{item.content}</ScreenLoader>
                      </AccordionPanel>
                    </Card>
                  </AccordionItem>
                )
              ) : /* modal */
              !isMobile ? (
                /* desktop */
                <Card
                  key={key}
                  header={
                    <Flex justify="space-between" w="full" alignItems="center">
                      <Text textStyle="cardTitle">{item.title}</Text>

                      {!isLoadingChart && (
                        <Tooltip label={item.title}>
                          <IconButton h="30px" bgColor="#FFFFFF" icon={<MdRemoveRedEye color="#422C76" />} onClick={onOpen} />
                        </Tooltip>
                      )}
                    </Flex>
                  }>
                  <ScreenLoader isLoading={isLoadingChart}>
                    <Flex direction="row" justify="center" h="full">
                      {item.content}

                      {item?.modal}
                    </Flex>
                  </ScreenLoader>
                </Card>
              ) : (
                /* mobile */
                <AccordionItem key={key}>
                  <Card
                    borderBottom="0"
                    header={
                      <Flex justify="space-between" w="full" alignItems="center">
                        <Text textStyle="cardTitle">
                          <AccordionButton _hover="none" p="8px 5px">
                            <Text textStyle="cardTitle" fontSize="16px">
                              {item.title}
                            </Text>
                            <AccordionIcon />
                          </AccordionButton>
                        </Text>

                        {!isLoadingChart && (
                          <Tooltip label={item.title}>
                            <IconButton h="30px" bgColor="#FFFFFF" icon={<MdRemoveRedEye color="#422C76" />} onClick={onOpen} />
                          </Tooltip>
                        )}
                      </Flex>
                    }>
                    <ScreenLoader isLoading={isLoadingChart}>
                      <Flex direction="row" justify="center" h="full">
                        <AccordionPanel>{item.content}</AccordionPanel>

                        {item?.modal}
                      </Flex>
                    </ScreenLoader>
                  </Card>
                </AccordionItem>
              );
            })}
          </Accordion>
        </Grid>

        <Card id="tableId" className="up-anim">
          <ExpandContainer>
            <Box w="full">
              <Box overflow="auto" h="calc(100vh - 220px)">
                <ScreenLoader isLoading={isLoading}>
                  <ExpeditionTable list={list} handleSort={handleSort} sorting={sorting} />
                </ScreenLoader>
              </Box>

              {metadata.total_pages > 1 && <Paginate metadata={metadata} setMetadata={setMetadata} scroll="tableId" />}
            </Box>
          </ExpandContainer>

          <ScreenLoader isLoading={isLoading}>
            <Box overflowX="auto" m="1rem">
              <ExpeditionTable list={list} handleSort={handleSort} sorting={sorting} />
            </Box>
          </ScreenLoader>

          {metadata.total_pages > 1 && <Paginate metadata={metadata} setMetadata={setMetadata} scroll="tableId" />}
        </Card>
      </Flex>
    </Page>
  );
};

export default ExpeditionPage;
