import React, { useEffect, useState } from 'react';

import { Flex, Input, Text } from '@chakra-ui/react';
import '../../../styles/globals.css';

const EditPass = ({ setNewPwd }) => {
  const [newPass, setNewPass] = useState('');
  const [newPassConfirm, setNewPassConfirm] = useState('');

  useEffect(() => {
    if (newPass.length >= 6 && newPass === newPassConfirm) {
      setNewPwd(newPass);
    } else {
      setNewPwd('');
    }
  }, [newPass, newPassConfirm]);

  return (
    <Flex direction="column" gap="20px">
      <Text textStyle="tableTitle" fontWeight="800">
        Alteração de senha
      </Text>

      <Flex direction="column">
        <label htmlFor="password">Nova senha</label>

        <Input
          variant="primary"
          maxW="300px"
          type="password"
          name="password"
          id="password"
          value={newPass}
          onChange={(event) => setNewPass(event.target.value)}
        />

        {newPass.length < 6 ? <Text color="red">Insira no mínimo 6 caracteres</Text> : null}
      </Flex>

      <Flex direction="column">
        <label htmlFor="confirm-pass">Confirmar senha</label>

        <Input
          variant="primary"
          maxW="300px"
          type="password"
          name="confirm-pass"
          id="confirm-pass"
          value={newPassConfirm}
          onChange={(event) => setNewPassConfirm(event.target.value)}
        />

        {newPassConfirm.length < 6 ? <Text color="red">Insira no mínimo 6 caracteres</Text> : null}
        {newPassConfirm !== newPass && <Text color="red">As senhas não coferem!</Text>}
      </Flex>
    </Flex>
  );
};

export default EditPass;
