import React from 'react';

import { MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    List,
    ListItem,
    Grid,
    GridItem,
  } from '@chakra-ui/react'
import { Box, Flex, Image, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';

import plane from '../../../../assets/images/aviao.svg';
import vessel from '../../../../assets/images/navio.svg';
import Tag from '../../../../components/Generic/Tag';

const QuoteCard = ({ list }) => {
  function getStatusBackgroundFormatted(status) {
    let color = 'gray';
    if (status === 'ON_REVIEW_LEGAL') {
      color = 'yellow';
    } else if (status === 'ON_REVIEW_CLIENT' || status === 'ON_REVIEW_OPERATIONAL') {
      color = 'yellow'; // TODO: Verificar qual cor vai ser a oficial
    } else if (status === 'APPROVED') {
      color = 'green';
    } else {
      color = 'red';
    }

    return color;
  }

  return (
    <Flex flexDirection={'column'}>
      <List
      display={'grid'}
      gridTemplateColumns={'50px 50px 40px 1fr 80px'}
      gap="10px"
      justifyItems={'center'}
      p={'15px 0'}
      >
        <ListItem>{' '}</ListItem>
        <ListItem fontWeight={'bold'}>
          Status
        </ListItem>
        <ListItem fontWeight={'bold'}>
          Tipo
        </ListItem>
        <ListItem fontWeight={'bold'}>
          Referência
        </ListItem>
        <ListItem>{' '}</ListItem>
      </List>
      <Accordion>
      {list.length > 0 ? (
        <>
          {list.map((item, key) => {

          return (
            <AccordionItem key={key} _odd={{ bg: "#eee" }}>

                  <h2>
                  <AccordionButton
                    display={'grid'}
                    gridTemplateColumns={'50px 50px 40px 1fr 80px'}
                    justifyItems={'center'}
                    gap="10px"
                    p={'10px 0'}
                    _hover={'transparent'}
                  >
                      <Box as="span" flex='1' textAlign='left'>
                        <Tooltip label="Visualizar">
                          <Box align="center">
                            <Link to={`/vendemmia/quote/detail/${item.identifier}`}>
                              <MdOpenInNew size={20} cursor="pointer" color="#422C76" />
                            </Link>
                          </Box>
                        </Tooltip>
                      </Box>
                      <Tag 
                        textAlign="center" 
                        w={'20px'}
                        h={'20px'}
                        p={0}
                        borderRadius={'10px'}
                        type={getStatusBackgroundFormatted(item.status)}
                      >
                        <Tooltip label={item.statusReadable}>
                          {' '}
                        </Tooltip>
                      </Tag>
                      <Box>
                        {item.transportModalitySlug == 'air' && (
                          <Tooltip label="Aéreo">
                            <Image src={plane} alt="Cotação de frete aéreo" />
                          </Tooltip>
                        )}
                        {item.transportModalitySlug == 'sea' && (
                          <Tooltip label="Maritima">
                            <Image src={vessel} alt="Cotação de frete marítima" />
                          </Tooltip>
                        )}
                      </Box>
                      <Box>
                        <Link 
                          to={`/comex/process/detail/${item.processIdentifier}`}
                          style={{ color: '#6C48C2' }}
                        >
                          {item.reference.length <= 15 ? <>
                            {item.reference}
                            <br />
                          </> : <Flex gap="5px">
                            <Tooltip label={item.reference}>
                              <Box>{item.reference.substring(0, 10)}...</Box>
                            </Tooltip>
                          </Flex>}
                          <small>{`(${item.name})`}</small>
                        </Link>
                      </Box>
                      <AccordionIcon />
                  </AccordionButton>
                  </h2>
                  <AccordionPanel bg={'white'} pb={4}>
                    <Text>
                      <Grid 
                      templateRows='repeat(3, 1fr)'
                      templateColumns='repeat(2, 1fr)'
                      gap={'10px'}
                      >
                        <GridItem rowSpan={1} colSpan={1} fontSize={'14px'}>
                          <strong>Data:</strong> {item?.availableDateFormatted ?? '-'} <br />
                          (carga disponível)
                        </GridItem>
                        <GridItem rowSpan={1} colSpan={1} fontSize={'14px'}>
                          <strong>Origem:</strong> {item?.originPortName ? item?.originPortName + ' (' + item?.originPortCountry + ')' : '-'}
                        </GridItem>
                        <GridItem rowSpan={1} colSpan={1} fontSize={'14px'}>
                          <strong>Destino:</strong> {item?.destinyPortName ? item?.destinyPortName + ' (' + item?.destinyPortCountry + ')' : '-'}
                        </GridItem>
                        <GridItem rowSpan={1} colSpan={1} fontSize={'14px'}>
                          <strong>Valor invoice:</strong> {item?.invoiceValueFormatted ?? '-'}
                        </GridItem>
                        <GridItem rowSpan={1} colSpan={2} fontSize={'14px'}>
                          <strong>Solicitante:</strong> {item?.clientName ?? '-'}
                        </GridItem>
                      </Grid>
                    </Text>
                  </AccordionPanel>
            </AccordionItem>
          )

          })}
        </>
        ): (
          <Grid>
            <GridItem>
              <Text 
                justify="center" 
                p="25px"
                borderTop={'1px solid #70707036'}
                textAlign={'center'}
              >
                Nenhum dado encontrado para os parâmetros filtrados.
              </Text>
            </GridItem>
          </Grid>
        )}
      </Accordion>
    </Flex>
  );
};

export default QuoteCard;