import React from 'react';

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Collapse, Flex, Grid, GridItem, Icon, Image, Text, Tooltip, useDisclosure } from '@chakra-ui/react';

import document from '../../../../../assets/images/document.svg';
import { convertColorTrip, modalityIcon, modalityTitle } from '../../../../../utils/translate';
import DocumentModalView from '../../../CurrentTrips/components/Documents/DocumentModalView';

import CteDetails from './CteDetails';

const TavelListCollapseCard = ({ entity }) => {
  const { isOpen, onToggle } = useDisclosure();
  const getIcon = isOpen ? <MdKeyboardArrowUp color="#6C48C2" /> : <MdKeyboardArrowDown color="#6C48C2" />;

  const getColor = (modality) => {
    if (modality == 'FRETE_NAO_RASTREAVEL') {
      return '#f1c40f';
    } else if (modality == 'FRETE_RASTREAVEL') {
      return '#bababa';
    }

    return '#a885f9';
  };

  return (
    <>
      <Grid
        justifyContent={'space-between'}
        templateColumns={'16px 20px 50px 75px 70px 20px'}
        alignItems={'center'}
        p={'15px'}
        justifyItems={'center'}
        gap={'5px'}
        border={'1px solid #70707036'}
        borderRadius={'10px'}>
        <GridItem className="border" border="none" cursor="pointer" onClick={onToggle}>
          <Box>{getIcon}</Box>
        </GridItem>
        <GridItem border="none" title={modalityTitle(entity.modality)} onClick={onToggle}>
          <Box>
            <Box h="1.1rem">
              <Icon as={modalityIcon(entity?.modality)} color={getColor(entity?.modality)} w="1.1rem" h="1.1rem" />
            </Box>
          </Box>
        </GridItem>
        <GridItem border="none" textStyle="subtitle">
          <Box>
            <Flex
              align="center"
              w={'18px'}
              h={'18px'}
              gap="10px"
              borderRadius="15px"
              bgColor={convertColorTrip(entity.status)}
              color="white"></Flex>
          </Box>
        </GridItem>
        <GridItem cursor="pointer" border="none" textColor="linkColor" textStyle="subtitle" onClick={onToggle}>
          <Box>#{entity?.code}</Box>
        </GridItem>
        <GridItem cursor="pointer" border="none" textStyle="subtitle" bgColor={'transparent'}>
          <Box>
            <DocumentModalView identifier={entity.identifier} code={entity.code} files={entity.files} />
          </Box>
        </GridItem>
        <GridItem border="none" /*  w="50%"*/>
          <Flex direction="row" gap="20px" align="center" justify="flex-end">
            <Tooltip label="Detalhe da viagem">
              <Link to={`/logistic/trip/detail/${entity.identifier}`} state={entity}>
                <Image
                  w="18px"
                  src={document}
                  alt="Detalhes"
                  filter="saturate(0)"
                  cursor="pointer"
                  transition="0.2s"
                  _hover={{
                    filter: 'saturate(1)',
                  }}
                />
              </Link>
            </Tooltip>
          </Flex>
        </GridItem>
      </Grid>

      <Collapse style={{ marginTop: '-20px', background: '#F9F9F9', padding: '20px' }} in={isOpen} animateOpacity>
        <Grid templateRows={'1fr'} templateColumns={'repeat(4, 1fr)'} gap={'10px'}>
          <GridItem
            cursor="pointer"
            border="none"
            textStyle="subtitle"
            textColor="#0F0A1DB3"
            onClick={onToggle}
            rowSpan={1}
            colSpan={7}
            fontSize={'14px'}>
            <Text>
              <strong>Motorista: </strong>
              {entity?.userDriverName}
            </Text>
          </GridItem>
          <GridItem
            cursor="pointer"
            border="none"
            textStyle="subtitle"
            textColor="#0F0A1DB3"
            onClick={onToggle}
            rowSpan={1}
            colSpan={2}
            fontSize={'14px'}>
            <Text>
              <strong>Início: </strong>
              {entity?.startsEtdFormatted != null ? entity?.startsEtdFormatted : entity?.startsAtFormatted}
            </Text>
          </GridItem>
          <GridItem
            cursor="pointer"
            border="none"
            textStyle="subtitle"
            textColor="#0F0A1DB3"
            onClick={onToggle}
            rowSpan={1}
            colSpan={2}
            fontSize={'14px'}>
            <Text>
              <strong>Final: </strong>
              {entity?.endsEtdFormatted != null ? entity?.endsEtdFormatted : entity?.endsAtFormatted}
            </Text>
          </GridItem>
          <GridItem p="0" colSpan="8" border="none">
            <Text overflowX="auto">
              <CteDetails trip={entity} />
            </Text>
          </GridItem>
        </Grid>
      </Collapse>
    </>
  );
};

export default TavelListCollapseCard;
