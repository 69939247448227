import React from 'react';

import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

const ProcessContainersDrawer = ({ process, icon }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const flagClick = (e) => {
    e.stopPropagation(); // isso impede a propagação do evento que vai para a página de detalhes do processo
    onOpen();
  };

  return (
    <>
      {process && (
        <>
          <Tooltip label="Visualizar containers">
            <Box onClick={flagClick.bind(this)} ref={btnRef}>
              {icon}
            </Box>
          </Tooltip>

          <Drawer isOpen={isOpen} onClose={onClose} placement="right" finalFocusRef={btnRef}>
            <DrawerOverlay />

            <DrawerContent maxW={'100% !important'} w={{ base: "initial", md:"50% !important" }} px="30px" py="30px">
              <DrawerCloseButton />

              <Flex direction="column" gap="5px" mb="20px">
                <Flex align="center" textStyle="tableTitle" gap="5px">
                  <Text textColor="primary" fontWeight="bold">
                    Containers:
                  </Text>

                  <Text textColor="linkColor" fontWeight="medium" justify="flex-start">
                    {process?.containerList?.qtt ?? process?.containerQtt}
                  </Text>
                </Flex>

                <Flex align="flex-start" textStyle="subtitle" textColor="#BEBEBE" gap="5px">
                  <Text>Processo:</Text>
                  <Text>{process?.code || ''}</Text>
                </Flex>
              </Flex>

              <DrawerBody p="0">
                <Table className="table-default" w="full" p="0">
                  <Tbody>
                    {process?.containerList?.containers?.map((value, key) => (
                      <React.Fragment key={key}>
                        {typeof value === 'object' ? (
                          <Tr>
                            <Td>{value.code}</Td>
                            <Td>{value.modality}</Td>
                          </Tr>
                        ) : null}
                        {typeof value === 'string' ? (
                          <Tr key={key}>
                            <Td colSpan="2">{value}</Td>
                          </Tr>
                        ) : null}
                      </React.Fragment>
                    ))}
                  </Tbody>
                </Table>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      )}
    </>
  );
};

export default ProcessContainersDrawer;
