import React, { useEffect, useRef, useState } from 'react';

import { AiFillStar } from 'react-icons/ai';

import { Box, Tooltip } from '@chakra-ui/react';

import requests from '../../../../services/requests';

const FavoriteProcess = ({ process, onChange }) => {
  const [isActive, setIsActive] = useState(false);
  const toggleRef = useRef(null);

  const flagClick = (e) => {
    e.stopPropagation(); // isso impede a propagação do evento que vai para a página de detalhes do processo
    savePriority();
    onChange && onChange(!isActive);
    setIsActive(!isActive);
  };

  useEffect(() => {
    const toggleElement = toggleRef.current;
    if (toggleElement) {
      // Adiciona a classe .toggle-click
      toggleElement.classList.add('toggle-click');
      setTimeout(() => {
        // Remove a classe .toggle-click após 450ms
        toggleElement.classList.remove('toggle-click');
      }, 450);
    }
  }, [isActive]);

  const savePriority = () => {
    requests.saveProcessPriority(process.identifier, !isActive);
  };
  const checkIsPriority = async () => {
    requests.checkProcessPriority(process.identifier).then((data) => {
      setIsActive(data.isFavourite);
    });
  };
  useEffect(() => {
    checkIsPriority();
  }, []);

  return (
    <Tooltip
      label={
        <>
          Marcar como favorito?
          <br /> Obs. Os itens favoritados são exibidos no dashboard em destaque.
        </>
      }>
      <Box ref={toggleRef} position="relative">
        <AiFillStar
          color="#e52359"
          size={25}
          className={`star ${isActive ? 'active' : ''}`}
          onClick={flagClick.bind(this)}
        />
      </Box>
    </Tooltip>
  );
};

export default FavoriteProcess;
