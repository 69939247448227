import React, { useEffect, useState } from 'react';

import { Flex, Grid, Input, Text, Wrap } from '@chakra-ui/react';
import '../../../styles/globals.css';

import getProfile from '../../../services/profile';

const EditInfos = ({ setInfos }) => {
  const [editInfos, setEditInfos] = useState({
    name: getProfile.name !== 'undefined' ? getProfile.name : '',
    email: getProfile.email !== 'undefined' ? getProfile.email : '',
    phone: getProfile.phone !== 'undefined' ? getProfile.phone : '',
    cellphone: getProfile.cellphone !== 'undefined' ? getProfile.cellphone : '',
  });

  useEffect(() => {
    setInfos(editInfos);
  }, [editInfos]);

  return (
    <Flex direction="column">
      <Text textStyle="tableTitle" fontWeight="800">
        Informações de Perfil
      </Text>

      <Grid 
        marginTop="20px" 
        gap="10px" 
        maxW="600px"
        templateColumns="1fr 1fr"
      >
        <Flex direction="column">
          <label htmlFor="name">Nome completo</label>

          <Input
            variant="primary"
            type="text"
            value={editInfos.name}
            name="name"
            id="name"
            onChange={(event) => setEditInfos({ ...editInfos, name: event.target.value })}
          />
        </Flex>

        <Flex direction="column">
          <label htmlFor="cellphone">Celular (Opcional)</label>

          <Input
            variant="primary"
            type="text"
            value={editInfos.cellphone}
            name="cellphone"
            id="cellphone"
            onChange={(event) => setEditInfos({ ...editInfos, cellphone: event.target.value })}
          />
        </Flex>

        <Flex direction="column">
          <label htmlFor="email">E-mail</label>

          <Input
            variant="primary"
            type="email"
            value={editInfos.email}
            name="email"
            id="email"
            onChange={(event) => setEditInfos({ ...editInfos, email: event.target.value })}
          />
        </Flex>

        <Flex direction="column">
          <label htmlFor="phone">Telefone (Opcional)</label>

          <Input
            variant="primary"
            type="text"
            value={editInfos.phone}
            name="phone"
            id="phone"
            onChange={(event) => setEditInfos({ ...editInfos, phone: event.target.value })}
          />
        </Flex>
      </Grid>
    </Flex>
  );
};

export default EditInfos;
