import React, { useCallback, useEffect, useState } from 'react';

import { MdDelete, MdOutlineFileDownload, MdOutlineOpenInNew } from 'react-icons/md';
import { toast } from 'react-toastify';

import {
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr
} from '@chakra-ui/react';

import DefaultModal from '../../../../../components/Modal/Template/DefaultModal';
import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../../services/requests';

const DocumentModalView = ({
  isOpen,
  identifier,
  code,
  onClose,
}) => {
  const [files, setFiles] = useState([]);

  let profileRole = localStorage.getItem('profile-role');
  const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);
  const [isLoadingListData, setIsLoadingListData] = useState(false);

  const getName = (upload) => {
    const arrName = upload?.split('.');
    return arrName?.shift();
  };

  const handleDownload = (fileIdentifier, uploadName) => {
    const link = document.createElement('a');
    link.href = (process.env.REACT_APP_API_URL + `/open/freight/${identifier}/file/${fileIdentifier}/download`).replace('//open', '/open');
    link.target = '_blank';
    link.download = uploadName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDelete = useCallback((fileIdentifier) => {
    setIsLoadingDeleteData(true);

    requests
      .removeCTEFile(identifier, fileIdentifier)
      .then(() => {
        toast.success(`Arquivo removido com sucesso`);
        getEntity();
        onClose();
      })
      .finally(() => {
        setIsLoadingDeleteData(false);
      });
  });

  const handleView = (fileIdentifier) => {
    const linkDocument = (process.env.REACT_APP_API_URL + `/open/freight/${identifier}/file/${fileIdentifier}/view`).replace(
      '//open',
      '/open'
    );
    return <iframe width="100%" height="700em" src={linkDocument} allowFullScreen={true}></iframe>;
  };

  function getModalityReadable(modality = '') {
    let result = 'Outros';
    if (modality === 'CTE') {
      result = 'CT-e';
    } else if (modality === 'CTE_XML') {
      result = 'XML do CT-e';
    } else if (modality === 'CTE_PDF') {
      result = 'PDF do CT-e';
    } else if (modality === 'NF_XML') {
      result = 'XML da Nota fiscal';
    } else if (modality === 'NF_PDF') {
      result = 'PDF da Nota fiscal';
    } else if (modality === 'LOG') {
      result = 'Log da Viagem';
    } else if (modality === 'ENTREGA') {
      result = 'Comprovante de entrega';
    }

    return result;
  }

  const isShowButtonDelete = (identifier, modality) => {
    if (modality == 'LOG') {
      return;
    }
    return (
      <Tooltip label="Excluir arquivo">
        <IconButton
          bg="#EF8484"
          p="0"
          icon={<MdDelete size={20} color="#FFFFFF" />}
          transition="0.2s"
          _hover={{ bgColor: '#f6a4a4' }}
          onClick={(decision) => {
            if (decision) {
              handleDelete(identifier);
            }
          }}
        />
      </Tooltip>
    );
  };

  const loadDriverFreightFiles = () => {
    setIsLoadingListData(true);

    requests
      .showDriverFreightFiles(identifier)
      .then((response) => {
        setFiles(response.data);
        setIsLoadingListData(false);
      })
      .catch(() => {
        setIsLoadingListData(false);
        toast.error("Problemas ao listar arquivos da viagem, tente novamente ou entre em contato com o Administrador");
      });
  };

  useEffect(() => {
    if (identifier) {
      loadDriverFreightFiles();
    }
  }, [identifier]);

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent borderRadius="5px">
          <ModalHeader>#{code} - Arquivos</ModalHeader>
          <ModalCloseButton zIndex="100000" />
          <ModalBody p="25px" position="relative">
            <ScreenLoader isLoading={isLoadingListData}>
              <Table className="table-default" w="100%">
                <Thead>
                  <Tr>
                    <Th>Nome</Th>
                    <Th>Modalidade</Th>
                    <Th>Ações</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {files?.length > 0 &&
                    files?.map((file, key) => {
                      if (profileRole !== 'ROLE_SYSTEM_ADMIN' && file?.modality == 'LOG') {
                        return;
                      }

                      return (
                        <React.Fragment key={key}>
                          <Tr>
                            <Td>{file?.name ?? '-'}</Td>
                            <Td>{file?.modalityReadable ?? '-'}</Td>
                            <Td>
                              <Flex>
                                <DefaultModal
                                  label="Visualizar arquivo"
                                  width="70vw"
                                  ctaButton={
                                    <IconButton
                                      bg="#6b6b6b"
                                      p="0"
                                      mr="2"
                                      icon={<MdOutlineOpenInNew size={20} color="#FFFFFF" />}
                                      transition="0.2s"
                                      _hover={{ bgColor: '#b8b8b8' }}
                                    />
                                  }
                                  title={file?.modalityReadable}
                                  showButtons={false}>
                                  {handleView(file.identifier)}
                                </DefaultModal>
                                <Tooltip label="Baixar arquivo">
                                  <IconButton
                                    bg="#633dbe"
                                    p="0"
                                    icon={<MdOutlineFileDownload size={20} color="#FFFFFF" />}
                                    mr="2"
                                    transition="0.2s"
                                    _hover={{ bgColor: '#ab88ff' }}
                                    onClick={(decision) => {
                                      if (decision) {
                                        handleDownload(file.identifier, file.uploadName);
                                      }
                                    }}
                                  />
                                </Tooltip>
                                {isShowButtonDelete(file.identifier, file?.modality)}
                              </Flex>
                            </Td>
                          </Tr>
                        </React.Fragment>
                      );
                    })}
                </Tbody>
              </Table>
            </ScreenLoader>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DocumentModalView;
