import React from 'react';

import { MdOutlineReceiptLong } from 'react-icons/md';
import { RiFileListFill, RiLayoutGridFill } from 'react-icons/ri';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Flex, Link, Table, Tbody, Text, Th, Thead, Tr } from '@chakra-ui/react';

import Paginate from '../../../../../components/Paginate/Paginate';
import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';

import TavelListCollapse from './TavelListCollapse';

import './styles.css';

const TableTravel = ({ tab, setTab, list, load, setAction, metadata, setMetadata, action, loadData = () => {} }) => {
  let profileRole = localStorage.getItem('profile-role');

  return (
    <>
      <Flex direction="column" w="100%" px="15px ">
        <Flex
          h="70px"
          align="center"
          flexDirection={{ base: 'column', md: 'row' }}
          justify="space-between"
          w="100%"
          gap={{ base: '5', md: '0' }}>
          <Flex gap="2px" direction="column">
            <Text textStyle="cardTitle" fontWeight="bold" color="primary">
              Viagens em preparação
            </Text>

            {metadata && metadata.total_count > 0 && (
              <Text textStyle="subtitle" color="#BEBEBE" fontSize={{ base: '14px', md: '16px' }}>
                Existe um total de {metadata.total_count} viagens cadastradas. Mostrando {metadata.item_count} nesta página.
              </Text>
            )}
          </Flex>

          <Flex gap="15px" align="center">
            <Link as={RouterLink} to="/logistic/trip">
              <Button
                variant={tab === 'progress' ? 'primary' : 'secundary'}
                borderRadius="0px"
                borderColor="#6C48C2"
                w={{ base: '100%', md: 'initial' }}
                title="Viagens em lista"
                onClick={() => setTab('progress')}
                rightIcon={<RiFileListFill />}>
                Lista de Viagens
              </Button>
            </Link>

            {/* {profileRole === 'ROLE_SYSTEM_ADMIN' && ( */}
            <Link as={RouterLink} to="/logistic/trip#kanban">
              <Button
                variant={tab === 'kanban' ? 'primary' : 'secundary'}
                borderRadius="0px"
                borderColor="#6C48C2"
                title="Viagens em kanban"
                rightIcon={<RiLayoutGridFill />}
                onClick={() => setTab('kanban')}>
                Quadro de Viagens
              </Button>
            </Link>
            {/* )} */}

            <Link as={RouterLink} to="/logistic/trip#cte">
              <Button
                variant={tab === 'cte' ? 'primary' : 'secundary'}
                borderRadius="0px"
                borderColor="#6C48C2"
                w={{ base: '100%', md: 'initial' }}
                title={"CT-e's"}
                rightIcon={<MdOutlineReceiptLong />}
                onClick={() => setTab('cte')}>
                {"CT-e's"}
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Flex>
      <ScreenLoader isLoading={load}>
        <Box m="10px">
          <Table w="full">
            <Thead>
              <Tr className="border">
                <Th>
                  <Box></Box>
                </Th>
                <Th>
                  <Box></Box>
                </Th>
                <Th>
                  <Box>
                    <Text textTransform="none" textStyle="subtitle" textColor="#422C76" fontWeight="medium">
                      Número
                    </Text>
                  </Box>
                </Th>
                <Th>
                  <Box>
                    <Text textTransform="none" textStyle="subtitle" textColor="#422C76" fontWeight="medium">
                      {`CT-e's`}
                    </Text>
                  </Box>
                </Th>
                <Th>
                  <Box>
                    <Text textTransform="none" textStyle="subtitle" textColor="#422C76" fontWeight="medium">
                      Empresas vinculadas
                    </Text>
                  </Box>
                </Th>
                <Th>
                  <Box>
                    <Text textTransform="none" textStyle="subtitle" textColor="#422C76" fontWeight="medium">
                      Motorista
                    </Text>
                  </Box>
                </Th>
                <Th>
                  <Box>
                    <Text textTransform="none" textStyle="subtitle" textColor="#422C76" fontWeight="medium">
                      Início
                    </Text>
                  </Box>
                </Th>
                <Th>
                  <Box>
                    <Text textTransform="none" textStyle="subtitle" textColor="#422C76" fontWeight="medium">
                      Conclusão
                    </Text>
                  </Box>
                </Th>
                <Th>
                  <Box>
                    <Text textTransform="none" textStyle="subtitle" textColor="#422C76" fontWeight="medium">
                      Arquivos
                    </Text>
                  </Box>
                </Th>
                <Th>
                  <Box>
                    <Text textTransform="none" textStyle="subtitle" textColor="#422C76" fontWeight="medium">
                      Status
                    </Text>
                  </Box>
                </Th>
                <Th>
                  <Box></Box>
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {!load && (
                <>
                  {list?.map((entity, key) => {
                    return <TavelListCollapse entity={entity} key={key} loadData={loadData} />;
                  })}
                </>
              )}
            </Tbody>
          </Table>
        </Box>
      </ScreenLoader>

      {metadata?.total_pages > 1 && (
        <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
      )}
    </>
  );
};

export default TableTravel;
