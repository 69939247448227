import React from 'react';

import { ImLocation } from 'react-icons/im';

import { Box, Flex, Image, Text } from '@chakra-ui/react';

import origem from '../../../../../assets/svgs/trajeto_coleta.svg';
import entrega from '../../../../../assets/svgs/trajeto_entrega.svg';
import other from '../../../../../assets/svgs/trajeto_info.svg';
import lento from '../../../../../assets/svgs/trajeto_movimento_lento.svg';
import parada from '../../../../../assets/svgs/trajeto_parada.svg';
import emergencia from '../../../../../assets/svgs/trajeto_parada_emergencia.svg';
import noFace from '../../../../../assets/svgs/trajeto_problema_reconhecimento_facial.svg';
import face from '../../../../../assets/svgs/trajeto_reconhecimento_facial.svg';
import aceite from '../../../../../assets/svgs/viagem_aceita.svg';
import cadastro from '../../../../../assets/svgs/viagem_cadastrada.svg';
import enviado from '../../../../../assets/svgs/viagem_enviada.svg';

const TravelTimelineStep = ({ title, progress, infos, stepCount = 0, delay = 0, isBlock = false, setCenter, setZoom }) => {
  function getIcon(info) {
    switch (info.section) {
      case 'CREATED':
        return cadastro;
      case 'SENT_TO_DRIVER':
        return enviado;
      case 'ACCEPTED_BY_DRIVER':
        return aceite;
      case 'low':
        return lento;
      case 'stop':
        return parada;
      case 'emergency':
        return emergencia;
      case 'face':
        return face;
      case 'no-face':
        return noFace;
      default:
        if (info.type == 'Coleta') {
          return origem;
        } else if (info.type == 'Descarga/Entrega') {
          return entrega;
        }

        return other;
    }
  }

  function getLabelSection(section) {
    switch (section) {
      case 'emergency':
        return 'Emergência';
      case 'stop':
        return 'Parada';
      case 'low':
        return 'Movimento lento';
      case 'no-face':
        return 'Reconhecimento facial';
      default:
        return section;
    }
  }

  return (
    <Flex
      direction="row"
      w="100%"
      gap={'15px'}
      style={{
        transformOrigin: 'left center',
        animation: 'rlFade 0.5s ease',
        animationFillMode: 'both !important',
        animationDelay: 'var(--delay)',
      }}>
      <Flex alignItems="center" direction="column">
        <Image src={getIcon(infos)} boxSize="2rem" mt="0.5" mb="0.5" />
        <Box left="12px" top="0" width="2px" height="50px" background="#6C48C280" />
      </Flex>
      <Flex direction="column" pl="1rem" textOverflow="ellipsis" maxWidth="100px">
        <Text fontSize={14} color="#0F0A1D5C">
          {infos?.date ? infos?.date : 'Pendente'}
        </Text>
      </Flex>
      {/* <Flex 
        direction="row"
      >
        {infos?.section !== null && infos?.section !== 'face' && infos?.modality !== 'STATUS' && (
          <Text fontSize={14} color="#0F0A1D">
            {getLabelSection(infos?.section)}
          </Text>
        )}
      </Flex> */}
      <Text fontWeight="bold" fontSize={14} color="#0F0A1D" minWidth={'168px'} maxWidth={'168px'}>
        {infos?.type}
      </Text>
      {
        (typeof infos?.latitude) !== 'undefined' && infos?.latitude != '' && infos?.latitude != 0 && infos?.section !== 'CREATED' && (
          // <Flex direction="row" gap="10">
          // <Box w="50" justify="flex-end">
          <ImLocation
            title="Origem"
            color="#ea4335"
            size="30px"
            cursor="pointer"
            onClick={(decision) => {
              if (decision) {
                setCenter({
                  lat: infos.latitude,
                  lng: infos.longitude,
                });
                setZoom(18);
              }
            }}
          />
        )
        // </Box>
        // </Flex>
      }
    </Flex>
  );
};

export default TravelTimelineStep;
