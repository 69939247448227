import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

import requests from '../../../../services/requests';

const ProcessUpdatesDrawer = ({ process, notification }) => {
  const [updates, setUpdates] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  let navigate = useNavigate();

  const formatDescription = (description) => {
    const parser = new DOMParser();
    const decodedDescription = parser.parseFromString(description, 'text/html').body.textContent;
    return decodedDescription;
  };

  const flagClick = (e) => {
    e.stopPropagation(); // isso impede a propagação do evento que vai para a página de detalhes do processo
    (process.qttComment > 0 || process.qttRecentUpdates > 0) ? onOpen() : null;
  };

  const toRoute = (to) => {
    navigate(to);
  };

  const getUpdates = async () => {
    if (!process || !process.identifier) {
      return;
    }

    try {
      const data = await requests.getProcessUpdates(process.identifier);

      const allUpdates = data.flatMap((item) => item.alerts);
      setUpdates(allUpdates);
    } catch (error) {
      console.error('Error fetching updates:', error);
    }
  };

  useEffect(() => {
    getUpdates();
  }, []);

  return (
    <>
      {process && (
        <>
          <Tooltip label={process.qttRecentUpdates > 0 ? 'Visualizar atualizações' : 'Sem atualizações'}>
            <Flex
              alignItems="center"
              gap="5px"
              cursor="pointer"
              opacity={updates?.alerts === 0 ? '.1' : '1'}
              onClick={flagClick.bind(this)}
              ref={btnRef}>
              <Image src={notification} />
              <Text textStyle="span" color="#8A8D93">
                {process.qttRecentUpdates}
              </Text>
            </Flex>
          </Tooltip>

          <Drawer isOpen={isOpen} onClose={onClose} placement="right" finalFocusRef={btnRef}>
            <DrawerOverlay />

            <DrawerContent maxW={'100% !important'} w={{ base: "initial", md:"50% !important" }} px="30px" py="30px">
              <DrawerCloseButton />

              <Flex justify="space-between" align="center" mt="10px">
                <Flex direction="column" gap="5px">
                  <Flex align="center" textStyle="tableTitle" gap="5px">
                    <Text textColor="primary" fontWeight="bold">
                      Atualizações:
                    </Text>
                    <Text textColor="linkColor" fontWeight="medium">
                      {process.qttRecentUpdates}
                    </Text>
                  </Flex>

                  <Flex dire align="center" textStyle="subtitle" textColor="#BEBEBE" gap="5px">
                    <Text>Processo:</Text>
                    <Text>{process.code}</Text>
                  </Flex>
                </Flex>

                <Button variant="secundary" onClick={() => toRoute(`/comex/process/detail/${process.identifier}`)}>
                  Ver processo
                </Button>
              </Flex>

              <DrawerBody p="0" mt="20px">
                <Flex direction="column" gap="15px">
                  <Table className="table-default" w="full" p="0">
                    <Tbody>
                      {updates.map((entity, key) => (
                        <Tr key={key}>
                          <Td w="200px">{entity.createdAt}</Td>
                          <Td>{formatDescription(entity.description)}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Flex>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      )}
    </>
  );
};

export default ProcessUpdatesDrawer;
