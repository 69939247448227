import React from 'react';

import { MdOutlineOpenInNew } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { AccordionButton, AccordionIcon, AccordionPanel, Box, Flex, IconButton } from '@chakra-ui/react';

import { clamp } from '../../../../utils/math';

import ProcessInfo from './ProcessInfo';
import ProcessTimelineStep from './ProcessTimelineStep';
const ProcessListItem = ({ process, itemCount = 0, forceLoadTrigger, setForceLoadTrigger }) => {
  let navigate = useNavigate();
  const windowWidth = window.innerWidth;

  const getSteps = () => {
    return [
      {
        title: 'Pré embarque',
        progress: clamp(process.progressBar / 20.0 - 0, 0, 1),
        block: false,
        infos: [process?.startedAtFormatted && { value: process?.startedAtFormatted }],
      },
      {
        title: 'Trânsito',
        progress: clamp(process.progressBar / 20.0 - 1, 0, 1),
        block: false,
        infos: [
          process?.boardedAtFormatted && { label: 'Trânsito', value: process?.boardedAtFormatted },
          process?.vessel && { label: 'Navio', value: process.vessel?.name },
          process?.containerList && {
            label: 'Containers',
            value: process?.containerList?.containers?.length,
            container: (
              <IconButton
                px="5px"
                py="3px"
                minW="none"
                h="none"
                bg="#D0B0FD36"
                _hover={{ color: 'primary', transition: '0.2s' }}
                icon={<MdOutlineOpenInNew color="#6C48C2" size={15} />}
              />
            ),
          },
        ],
      },
      {
        title: 'Desembaraço',
        progress: clamp(process.progressBar / 20.0 - 2, 0, 1),
        block: false,
        infos: [
          process?.arrivedAtFormatted
            ? { label: 'Chegada', value: process.arrivedAtFormatted }
            : process?.arrivalExpectedAtFormatted && {
                label: process?.clearenceDiAtFormatted ? 'Previsão' : 'Prev. chegada',
                value: process.arrivalExpectedAtFormatted,
              },
          process?.diNumber && { label: 'DI', value: process.diNumber },
          process?.clearenceDiAtFormatted && { label: 'Registro', value: process.clearenceDiAtFormatted },
          process?.clearenceCiAtFormatted && { label: 'CI', value: process.clearenceCiAtFormatted },
        ],
      },
      {
        title: !process?.billedAtFormatted ? 'Em faturamento' : 'Faturado',
        progress: !process?.billedAtFormatted && process.progressBar > 60 ? 0 : clamp(process.progressBar / 20.0 - 3, 0, 1),
        block: false,
        infos: [
          process?.billedAtFormatted && { label: 'Emissão', value: process.billedAtFormatted },
          process?.idNota && { label: 'NF', value: process.idNota },
        ],
      },
      {
        title: 'Transporte',
        progress: clamp(process.progressBar / 20.0 - 4, 0, 1),
        block: false,
        infos: [
          process?.storedAtFormatted && { label: 'Carregamento', value: process.storedAtFormatted },
          process?.deliveredAtFormatted && { label: 'Entrega', value: process.deliveredAtFormatted },
        ],
      },
    ];
  };

  const toRoute = (to) => {
    navigate(to);
  };

  const flagClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Box cursor="pointer" borderRadius="5px" onClick={() => toRoute(`/comex/process/detail/${process.identifier}`)}>
      <Flex direction="column" p="10px" transition=".3s" borderTop="#70707036 solid 1px" _hover={{ bg: '#e4e6e761' }}>
        {windowWidth > 768 ? (
          <>
            <ProcessInfo process={process} forceLoadTrigger={forceLoadTrigger} setForceLoadTrigger={setForceLoadTrigger} />
            <Flex
              w="100%"
              justify="space-between"
              flexDirection={{ base: 'column', md: 'row' }}
              alignItems="baseline"
              p={{ base: '25px 0 10px', md: 'inherit' }}>
              {getSteps().map((step, i) => (
                <ProcessTimelineStep
                  key={i}
                  stepCount={i}
                  delay={i + itemCount}
                  progress={step.progress}
                  title={step.title}
                  infos={step.infos}
                  process={process}
                  isBlock={step.block}
                />
              ))}
            </Flex>
          </>
        ) : (
          <>
            <AccordionButton onClick={flagClick.bind(this)} _hover={{ bg: 'transparent' }} p={'10px 4px'} alignItems={'baseline'}>
              <ProcessInfo process={process} />
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel>
              <Flex
                w="100%"
                justify="space-between"
                flexDirection={{ base: 'column', md: 'row' }}
                alignItems="baseline"
                p={{ base: '25px 0 10px', md: 'inherit' }}>
                {getSteps().map((step, i) => (
                  <ProcessTimelineStep
                    key={i}
                    stepCount={i}
                    delay={i + itemCount}
                    progress={step.progress}
                    title={step.title}
                    infos={step.infos}
                    process={process}
                    isBlock={step.block}
                  />
                ))}
              </Flex>
            </AccordionPanel>
          </>
        )}
      </Flex>
    </Box>
  );
};

export default ProcessListItem;
