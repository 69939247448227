import React, { useCallback, useEffect, useState } from 'react';

import { format } from "date-fns";
import { MdDownload } from 'react-icons/md';

import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';

import ModalUpload from '../../../../components/Modal/Upload/ModalUpload';
import requests from '../../../../services/requests';

import ModalDeleteDocument from './ModalDeleteDocument';
import ModalViewDocument from './ModalViewDocument';

const ModalListDocuments = ({ quote = {} }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [documents, setDocuments] = useState([]);
  const [openModalUploadFile, setOpenModalUploadFile] = useState(false);
  const [isDownloadingAllFiles, setIsDownloadingAllFiles] = useState(false);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const actionModalUploadFile = () => {
    setOpenModalUploadFile(!openModalUploadFile);
  };

  const getDocuments = () => {
    setIsLoadingDocuments(true);
    requests.getFilesListByQuote(quote.identifier, metadata?.current_page)
      .then(response => {
        setDocuments(response.data);
        setMetadata(response.meta);
        setIsLoadingDocuments(false);
      })
    ;
  };

  const downloadFile = useCallback((identifier, filename, ext) => {
    let link = document.createElement("a");

    link.href = (requests.getApiUrl() + `/open/quote/file/${quote.identifier}/${identifier}/download`).replace(
        "//open",
        "/open"
    );
    link.target = "_blank";
    link.download = filename + "." + ext;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });

  if (!quote?.identifier) {
    return;
  }

  const typeOptions = [
    { value: "PACKLIST", label: "PACKLIST" },
    { value: "DIVERSOS", label: "DIVERSOS" },
  ];

  useEffect(() => {
    if (quote?.identifier) {
      getDocuments();
    }
  }, [quote]);

  const sendUpload = async (docs, config) => {
    return new Promise((resolve, reject) => {
      requests
        .saveQuoteDocsUploads(quote.identifier, docs, config)
        .then(() => {
          getDocuments();
          onOpen();
          resolve();
        })
        .catch(() => {
          onOpen();
          reject();
        });
    });
  };

  const downloadAllFiles = useCallback(() => {
    setIsDownloadingAllFiles(true);

    let link = document.createElement("a");

    link.href = (requests.getApiUrl() + `/open/quote/file/${quote.identifier}/all/download`).replace("//open", "/open");
    link.target = "_blank";
    link.download = quote.identifier + ".zip";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setIsDownloadingAllFiles(false);
});

  return (
    <>
      <ModalUpload
        title="Importar novo arquivo"
        subtitle="Upload de documentos da cotação"
        accept=".xml, .pdf, .png, .jpeg"
        messageConfirm=" salvo com sucesso para envio!"
        isOpen={openModalUploadFile}
        onOpen={onOpen}
        onClose={() => { actionModalUploadFile(), onOpen() }}
        multiple={true}
        requestAction={sendUpload}
        nameForm="quote_file_upload"
        additional={[
          {
            label: 'Tipo',
            group: 'stage',
            name: 'stage',
            required: true,
            options: typeOptions,
          },
        ]}
      />
      <Box justify="space-between" justifyContent="end">
        <Button
          variant="primary"
          onClick={onOpen}
        >
          <Text fontSize="14px" textColor="#FFFFFF">
            Documentos
          </Text>
        </Button>
      </Box>
      <Drawer
        isOpen={isOpen}
        placement='right'
        size="xl"
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent maxW={'100% !important'} w={{ base: "initial", md:"50% !important" }} px="30px" py="30px">
          <DrawerHeader pt="20">
            <DrawerCloseButton />
            <Text textStyle="tableTitle" fontWeight="800" color="primary">
              Documentos
            </Text>
            <Flex justify="space-between" justifyContent="end">
              <Button
                loadingText="Baixando..."
                isLoading={isDownloadingAllFiles}
                variant="primary"
                onClick={() => downloadAllFiles()}
              >
                <Text fontSize="14px" textColor="#FFFFFF">
                  Baixar todos
                </Text>
              </Button>
              <Button
                ml="10px"
                variant="primary"
                onClick={() => {
                  actionModalUploadFile(),
                  onClose()
                }}
              >
                <Text fontSize="14px" textColor="#FFFFFF">
                  Anexar novo
                </Text>
              </Button>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <Table className="table-default">
              <Thead border="1px" borderBottom="1px" h={50}>
                <Tr>
                  <Th>
                    <Text>Nome</Text>
                  </Th>
                  <Th>
                    <Text>Extensão</Text>
                  </Th>
                  <Th>
                    <Text>Data</Text>
                  </Th>
                  <Th>
                    <Text>Hora</Text>
                  </Th>
                  <Th>
                    <Text>Tipo</Text>
                  </Th>
                  <Th>
                    <Text>Ações</Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody border="1px">
                {documents.length > 0 ? (
                  documents.map((document, key) => {
                    let newCreatedAt = new Date(document?.createdAt);
                    let arrFileName = document?.uploadName?.split(".");

                    return (
                      <Tr key={key} border="none" cursor="pointer" style={{ '--delay': `${key / 10}s` }}>
                        <Td>{arrFileName[0] ?? '-'}</Td>
                        <Td>{arrFileName[1] ?? '-'}</Td>
                        <Td>{newCreatedAt ? format(newCreatedAt, "dd/MM/yyyy") : ""}</Td>
                        <Td>{newCreatedAt ? format(newCreatedAt, "HH:mm") : ""}</Td>
                        <Td>{document?.type ?? '-'}</Td>
                        <Td>
                          <Flex gap="10px">
                            <button
                              onClick={() => downloadFile(document.identifier, arrFileName[0], arrFileName.pop())}
                              title="Baixar documento"
                              target="_blank"
                            >
                              <MdDownload size={20} />
                            </button>
                            <ModalViewDocument
                              quote={quote}
                              document={document}
                            />
                            <ModalDeleteDocument
                              quote={quote}
                              document={document}
                              handleSuccess={getDocuments}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  })
                ) : (
                  <Tr>
                    <Td colSpan={6}>
                      <Flex justify="center" my="25px">
                        Nenhum dado encontrado para os parâmetros filtrados.
                      </Flex>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
};

export default ModalListDocuments;
