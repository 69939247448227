import { useEffect, useState } from 'react';

import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import { toast } from 'react-toastify';

import {
  Button,
  Card,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

import FloatActionButton from '../../../components/Generic/FloatActionButton';
import ModalRegisterAdditionalFields from '../../../components/Modal/AditionalFields/components/ModalRegisterAdditionalFields';
import ModalAditionalFields from '../../../components/Modal/AditionalFields/ModalAditionalFields';
import Page from '../../../components/Page';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { aditionalfields } from '../../../utils/exports';

const AdditionalFieldsManagement = () => {
  const hasPermission = permissions.vendemmiaAdditionalFieldsManagement;
  const [isMobile, setIsMobile] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [list, setList] = useState([]);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [isOpenFloatButton, setIsOpenFloatButton] = useState(false);

  /* const { isOpen, onOpen, onClose } = useDisclosure(); */

  const [loading, setLoading] = useState(false);
  const [entity, setEntity] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [action, setAction] = useState(1);
  const [meta, setMeta] = useState({
    current_page: 1,
  });

  const actionModal = () => {
    setOpenModal(!openModal);
  };

  const closeModal = () => {
    setOpenModal(false);
    closeFloatActionButton();
  };

  const closeFloatActionButton = () => {
    const floatButton = document.querySelector('.float-action-button button');
    floatButton.click();
  };

  const handleEditAdditionalFields = (data) => {
    setEntity(data);
    setOpenModal(!openModal);
  };

  const load = (filters, key) => {};

  const loadMore = (filters, key) => {
    setIsLoading(true);
    setIsLoadingMore(true);
    requests.listProcess(filters, metadata.current_page + 1).then((data) => {
      if (request.current && request.current > key) {
        return;
      }

      request.current = key;

      setList((prevList) => [...prevList, ...data.data]);
      setMetadata(data.meta);
      setIsLoading(false);
      setIsLoadingMore(false);
    });
  };

  const handleDeleteAdditionalFields = (data) => {
    setLoading(true);

    requests
      .deleteAditionalField(data.identifier)
      .then(() => {
        toast.success('Campo "' + data.label + '" removido com sucesso!');
        loadList();

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const loadList = async () => {
    requests.listAditionalFields({}, meta.current_page > 0 ? meta.current_page : 1).then((list) => {
      setList(list.data);
      setMeta(list.meta);
    });
  };

  useEffect(() => {
    loadList();
  }, [action]);

  const FloatActionButtonOptions = [
    {
      isLoading: '',
      rightIcon: <MdAdd color="#FFFFFF" size={25} />,
      handler: actionModal,
      title: 'Adicionar novo',
      showField: true,
    },
  ];

  return (
    <Page
      screen="additional-fields-management"
      title="Campos adicionais"
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      textFilterPlaceholder="Nome do default"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      loadMore={loadMore}
      isContentLoadingMore={isLoadingMore}
      /* filterOptions={filterOptions} */
      isRefreshLoading={isLoading || isLoadingMore}
      showPeriodFilter={false}
      showRefreshData={false}
      showFilters={false}
      allowFiltersOverride={false}>
      <ModalRegisterAdditionalFields
        entity={entity}
        actionModal={closeModal}
        openModal={openModal}
        handleSuccess={() => {
          closeModal();
          loadList();
        }}
      />
      <Card m="10px" border={'1px solid #70707036'}>
        <Heading
          p={'10px 15px'}
          fontSize={'20px'}
          color={'#422C76'}
          lineHeight={'30px'}
          fontWeight={'600'}
          fontFamily={'Hanken Grotesk'}
          borderBottom={'#70707036 solid 1px'}>
          Campos Adicionais
        </Heading>
        <Flex
          m={'15px'}
          flexDirection={'column'}
          alignItems={'end'}
          gap={'10px'}
          borderRadius={'18px'}>
          <Table className="table-default" w="100%">
            <Thead>
              <Tr>
                <Td>Título do campo</Td>
                <Td>Data do cadastro</Td>
                <Td>Data da ultima alteração</Td>
                <Td>Responsável pelo cadastro</Td>
                <Td>Última pessoa que alterou</Td>
                <Td>Ação</Td>
              </Tr>
            </Thead>

            <Tbody>
              {list.map((data, key) => (
                <Tr key={key}>
                  <Td>{data.label}</Td>
                  <Td>{data.createdAtFormatted}</Td>
                  <Td> {data.updatedAtFormatted}</Td>
                  <Td>{data.createdBy.name}</Td>
                  <Td>{data.lastUpdatedBy.name}</Td>
                  <Td>
                    <Flex gap="20px">
                      <Tooltip label="Editar">
                        <Button onClick={() => handleEditAdditionalFields(data)}>
                          <MdEdit color="#422C7694" />
                        </Button>
                      </Tooltip>

                      <Tooltip label="Excluir">
                        <IconButton
                          icon={<MdDelete color="#E74C3C" />}
                          isLoading={loading}
                          onClick={() => handleDeleteAdditionalFields(data)}
                        />
                      </Tooltip>
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Flex>
        <FloatActionButton
          display="flex"
          flexDirection="column"
          options={[
            FloatActionButtonOptions.map((button, key) => {
              return (
                button.showField && (
                  <Button
                    key={key}
                    h="46px"
                    px="24px"
                    py="14px"
                    bgColor="green"
                    color="#FFFFFF"
                    borderRadius="27px"
                    _hover={{ bgColor: '#70D499' }}
                    isLoading={button.isLoading}
                    rightIcon={button.rightIcon}
                    onClick={button.handler}>
                    <Text>{button.title}</Text>
                  </Button>
                )
              );
            }),
          ]}
          isOpen={isOpenFloatButton}
          setIsOpen={setIsOpenFloatButton}
        />
      </Card>
    </Page>
  );
};

export default AdditionalFieldsManagement;
