import React from 'react';

import { MdCircle, MdEditNote } from 'react-icons/md';

import { AccordionButton, AccordionItem, AccordionPanel, Flex, IconButton, Text, Tooltip } from '@chakra-ui/react';

const StepRegister = ({ children, title, onNext, error, step, stepIndex, entity = true }) => {
  return (
    <AccordionItem border="none">
      <Flex bgColor="#FFFFFF" border="1px" borderColor="#422C761A" borderRadius="5px" h="auto" minW="600px" px="45px" py="10px">
        <Flex direction="column" w="full" gap="10px">
          <Flex align="center" justify="space-between" w="full">
            <AccordionButton _hover={{ bgColor: 'none' }} p="0" alignItems="flex-start">
              <>
                <Text textStyle="tableTitle" fontSize="24px" textColor="primary" fontWeight="medium" cursor="default">
                  {title}
                </Text>

                {error && <MdCircle size={7} color="red" />}
              </>
            </AccordionButton>

            <Tooltip label="Editar">
              <IconButton
                bgColor="transparent"
                isDisabled={step > stepIndex ? null : true}
                onClick={onNext}
                icon={<MdEditNote size={24} color="#422C7694" />}
              />
            </Tooltip>
          </Flex>

          <AccordionPanel p="0px">{children}</AccordionPanel>
        </Flex>
      </Flex>
    </AccordionItem>
  );
};

export default StepRegister;
