import React, { useLayoutEffect } from 'react';

import { Flex } from '@chakra-ui/layout';

import DetailsStap from './components/DetailStep';

const ProgressBar = ({ tab = 'progress', timeline, setLoad }) => {
  useLayoutEffect(() => {
    if (tab === 'cte') {
      setLoad(false);
    }
  }, [timeline]);

  return (
    <Flex 
      direction={{ base: "column", md:"row" }} 
      overflow={{ base: "none", md:"auto" }}
      py={{ base: "25px", md: "0" }}
      px={{ base: "35px", md: "0" }}
    >
      {timeline?.elements?.map((step, key) => {
        {
          let isLast = timeline.elements.length !== key + 1;
          let type = key < timeline.now ? '' : key > timeline.now ? 'grey' : 'green';
          return (
            <DetailsStap
              key={key}
              title={step.title}
              desc={step.desc}
              hasProgress={isLast}
              type={type}
              delay={`0.${key}s`}
            />
          );
        }
      })}
    </Flex>
  );
};

export default ProgressBar;
