import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { Box, Button, Collapse, Flex, Image, ModalBody, ModalFooter, Text, VStack } from '@chakra-ui/react';

import armazemTransporte from '../../../../assets/svgs/armazem-e-transporte.svg';
import desembaraco from '../../../../assets/svgs/desembaraco.svg';
import entrega from '../../../../assets/svgs/entrega.svg';
import faturamento from '../../../../assets/svgs/faturamento.svg';
import posEmbarque from '../../../../assets/svgs/pos-embarque.svg';
import preEmbarque from '../../../../assets/svgs/pre-embarque.svg';
import telasSistema from '../../../../assets/svgs/telas-do-sistema.svg';
import requests from '../../../../services/requests';
import FilterOption from '../../../../utils/filters/FilterOption';
import { capitalize } from '../../../../utils/strings';

const PermissionData = ({ getUser, loadList, entity, handleClose, callhandleClose }) => {
  const [data, setData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const [openDownIndex, setOpenDownIndex] = useState(null);
  const [openSide, setOpenSide] = useState(false);

  function getIcon(modality = '') {
    if (modality === 'Documentos de pré-embarque') {
      return preEmbarque;
    } else if (modality === 'Documentos de pós-embarque (transito)') {
      return posEmbarque;
    } else if (modality === 'Documentos de entrega') {
      return entrega;
    } else if (modality === 'Documentos de desembaraço') {
      return desembaraco;
    } else if (modality === 'Documentos de armazém e transporte') {
      return armazemTransporte;
    } else if (modality === 'Documentos de faturamento') {
      return faturamento;
    } else if (modality === 'Telas do sistema') {
      return telasSistema;
    } else {
      return;
    }
  }

  const handleToggleDown = (index) => {
    if (openDownIndex === index) {
      setOpenDownIndex(null);
    } else {
      setOpenDownIndex(index);
    }
  };

  const handleToggleSide = () => {
    setOpenSide(!openSide);
  };

  const loadData = () => {
    setIsLoadingData(true);

    requests.showUserPermissions(entity.identifier).then((response) => {
      let aux = [];
      response?.length > 0 &&
        response.forEach((a) => {
          if (a.permission.description === 'DOCUMENTOS DE PROCESSOS') {
            a.children?.length > 0 &&
              a.children.forEach((b) => {
                let children = [];

                b.children?.length > 0 &&
                  b.children.forEach((c) => {
                    children.push(new FilterOption(capitalize(c.permission.description), c.permission.id, [], c.isChecked));
                  });

                aux.push(new FilterOption(capitalize(b.permission.description), b.permission.id, children, b.isChecked));
              });
          } else {
            let children = [];
            a.children?.length > 0 &&
              a.children.forEach((b) => {
                children.push(new FilterOption(capitalize(b.permission.description), b.permission.id, [], b.isChecked));
              });

            aux.push(new FilterOption(capitalize(a.permission.description), a.permission.id, children, a.isChecked));
          }
        });

      setData(aux);
      setIsLoadingData(false);
    });
  };

  const saveData = () => {
    setIsLoadingSaveData(true);

    let aux = [];
    data.forEach((permission) => {
      if (permission.selected) {
        aux.push(permission.value);
      }

      permission.children.forEach((child) => {
        if (child.selected) {
          aux.push(child.value);
        }
      });
    });

    requests
      .saveProfilePermissions(entity.identifier, {
        permissions: aux,
      })
      .then(() => {
        toast.success('Permissões alteradas com sucesso!');
        getUser();
        loadList();
        setIsLoadingSaveData(false);
      });
  };

  useEffect(() => {
    if (!entity?.identifier) {
      return;
    }

    loadData();
  }, []);

  return (
    <>
      <ModalBody mt="10px" overflowY="auto" h="500px" w="full" className="up-anim">
        <Flex w="full" direction="column" gap="20px">
          <Flex direction="column" gap="10px">
            <Text textStyle="cardTitle" fontWeight="bold" color="primary" textAlign="center">
              Detalhes de processo
            </Text>

            <Flex gap="20px" direction="row" flexWrap="wrap" justify="center">
              {data &&
                data.map((item, key) => {
                  if (item instanceof FilterOption && item.label.includes('Documentos')) {
                    return (
                      <React.Fragment key={key}>
                        <Box
                          borderWidth="1px"
                          backgroundColor="#f5f5f5"
                          h="fit-content"
                          p={4}
                          rounded="md"
                          style={{ width: '30%' }}
                          cursor="pointer">
                          <Flex align="center" gap={2} onClick={() => handleToggleDown(key)}>
                            <Image src={getIcon(item.label)} />

                            <VStack align="start" spacing={1}>
                              <Text fontWeight="bold">{item.label}</Text>
                              <Text style={{ fontSize: '0.8rem' }}>Selecione os campos desejados</Text>
                            </VStack>
                          </Flex>

                          <Collapse in={openDownIndex === key}>
                            <Box borderWidth="1px" p={4} rounded="md" style={{ width: '100%', marginTop: '16px' }}>
                              <ul key={key}>
                                <li style={{ listStyle: 'none', fontSize: '18px', fontWeight: 'bold' }}>{item.renderTree()}</li>
                              </ul>
                            </Box>
                          </Collapse>
                        </Box>
                      </React.Fragment>
                    );
                  }
                })}
            </Flex>
          </Flex>

          <Flex direction="column" gap="10px">
            <Text textStyle="cardTitle" fontWeight="bold" color="primary" textAlign="center">
              Telas do sistema
            </Text>

            <Flex gap="20px" direction="row" flexWrap="wrap" justify="center">
              <Box
                borderWidth="1px"
                backgroundColor="#f5f5f5"
                p={4}
                rounded="md"
                style={{ width: '30%', maxWidth: '30%', maxHeight: '78px' }}
                cursor="pointer">
                <Flex align="center" gap={2} onClick={() => handleToggleSide()}>
                  <Image src={getIcon('Telas do sistema')} />

                  <VStack align="start" spacing={1}>
                    <Text fontWeight="bold">Telas do sistema</Text>
                    <Text style={{ fontSize: '0.8rem' }}>Selecione os campos desejados</Text>
                  </VStack>
                </Flex>
              </Box>

              <Collapse in={openSide}>
                <Box borderWidth="1px" backgroundColor="#f5f5f5" p={4} rounded="md" style={{ width: '100%' }}>
                  {data &&
                    data.map((item, key) => {
                      if (item instanceof FilterOption && !item.label.includes('Documentos')) {
                        return (
                          <React.Fragment key={key}>
                            <ul key={key}>
                              <li style={{ listStyle: 'none', fontSize: '18px', fontWeight: 'bold' }}>{item.renderTree()}</li>
                            </ul>
                          </React.Fragment>
                        );
                      }
                    })}
                </Box>
              </Collapse>
            </Flex>
          </Flex>
        </Flex>
      </ModalBody>

      <ModalFooter borderTop="1px" borderColor="#7070702E" p="0">
        <Button
          border="1px"
          w="fit-content"
          borderRadius="7px"
          m="15px"
          p="9px 23px"
          color="#E73668"
          borderColor="#E52359"
          bgColor="transparent"
          type="button"
          onClick={handleClose}>
          Cancelar e fechar
        </Button>

        <Button
          variant="primary"
          w="fit-content"
          borderRadius="7px"
          m="15px"
          p="9px 23px"
          type="submit"
          isLoading={isLoadingSaveData}
          onClick={saveData}
          loadingText="Salvando">
          Salvar
        </Button>
      </ModalFooter>
    </>
  );
};
export default PermissionData;
