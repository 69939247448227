import React from 'react';

import { Input } from '@chakra-ui/react';

const InputDefault = ({ as, hidden = false, name, placeholder, setFieldValue, type = 'text', value, ...props }) => {
  return (
    <Input
      autoComplete="off"
      bg="#F2F2F2"
      variant="primary"
      className="input-register"
      as={as}
      id={name}
      name={name}
      type={type}
      value={value}
      hidden={hidden}
      placeholder={placeholder}
      onChange={(event) => setFieldValue(name, event.target.value)}
      {...props}
    />
  );
};

export default InputDefault;
