import React, { useEffect, useState } from 'react';

import { MdClose, MdSearch } from 'react-icons/md';

import { Input, InputGroup, InputLeftElement, InputRightElement, Switch, Tooltip } from '@chakra-ui/react';

import { useDebounce } from '../../../hooks/useDebounce';
import { addFilter, getFilter } from '../../../utils/storageFilter';

const Filter = ({ screen, placeholder, onChange, allowFiltersOverride, setShouldIgnoreFilters, shouldIgnoreFilters, isInsideExtraFilter }) => {
  const [searchString, setSearchString] = useState(getFilter(screen, 'search') ?? '');

  const clear = () => {
    setSearchString('');
  };

  const handleCheck = () => {
    setShouldIgnoreFilters(!shouldIgnoreFilters);
  };

  useEffect(() => {
    debouncedSearch();
  }, [searchString]);

  const handleSearch = () => {
    let valueSearch = searchString.trim();

    addFilter(screen, 'search', valueSearch);
    onChange?.(valueSearch);
  };

  const debouncedSearch = useDebounce(handleSearch, 800);  // 800ms de delay

  return (
    <InputGroup w={isInsideExtraFilter ? '250px' : '300px'}>
      <Tooltip label="Insira no mínimo 3 caracteres">
        <Input
          px="10px"
          h="35px"
          pr="40px"
          pl={allowFiltersOverride ? '50px' : ''}
          bgColor="#E8EAED"
          borderRadius="10px"
          borderColor="#70707026"
          value={searchString}
          placeholder={placeholder}
          onChange={event => setSearchString(event.target.value)}
        />
      </Tooltip>

      {allowFiltersOverride && (
        <Tooltip label="Quando este campo estiver ativado, somente o filtro por texto será usado, ignorando todos os demais.">
          <InputLeftElement ml="5px">
            <Switch size="md" bottom="2px" colorScheme="purple" onChange={handleCheck} />
          </InputLeftElement>
        </Tooltip>
      )}

      {searchString.length === 0 && (
        <InputRightElement h="auto" my="auto" top="10px" alignItems="center" pointerEvents="none">
          <MdSearch color="#000000" size={15} />
        </InputRightElement>
      )}
      {searchString.length > 0 && (
        <InputRightElement h="auto" my="auto" top="10px" alignItems="center">
          <MdClose color="#000000" size={15} cursor="pointer" onClick={() => clear()} />
        </InputRightElement>
      )}
    </InputGroup>
  );
};

export default Filter;
