import React from 'react';

import { Bar, CartesianGrid, Cell, ComposedChart, Tooltip, XAxis, YAxis } from 'recharts';

import { Flex, Text } from '@chakra-ui/react';

const BarChartComponent = ({ entity }) => {
  const windowWidth = window.innerWidth
  let chartWidth = windowWidth < 768 ? 280 : 350;

  const tickFormatter = (tickItem) => {
    return tickItem.toLocaleString('pt-BR');
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <Flex direction="column" bgColor="#FFFFFF" border="1px" borderColor="#7070703D" px="15px" py="20px" gap="10px">
          {payload.map((entity, key) => (
            <React.Fragment key={key}>
              <Flex direction="row" align="center" gap="5px">
                <Text>{entity.value.toLocaleString()}</Text>
              </Flex>
            </React.Fragment>
          ))}
        </Flex>
      );
    }

    return null;
  };
  return (
    <ComposedChart width={chartWidth} height={250} data={entity} margin={{ top: 25 }}>
      <CartesianGrid vertical={false} />
      <XAxis tick={false} />
      <YAxis tickFormatter={tickFormatter} />
      <Tooltip content={CustomTooltip} />

      <Bar dataKey="qtt" barSize={110}>
        {entity.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Bar>
    </ComposedChart>
  );
};

export default BarChartComponent;
