import React, { useState } from 'react';

import { MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Box, Button, Flex, Image, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';

import plane from '../../../../assets/images/aviao.svg';
import vessel from '../../../../assets/images/navio.svg';
import assume from '../../../../assets/svgs/assume.svg';
import Tag from '../../../../components/Generic/Tag';
import DefaultModal from '../../../../components/Modal/Template/DefaultModal';
import requests from '../../../../services/requests';

const QuoteTable = ({ list, loadList }) => {
  const [load, setLoad] = useState(false);

  function getStatusBackgroundFormatted(status) {
    let color = 'gray';
    if (status === 'ON_REVIEW_LEGAL') {
      color = 'yellow';
    } else if (status === 'ON_REVIEW_CLIENT' || status === 'ON_REVIEW_OPERATIONAL') {
      color = 'yellow'; // TODO: Verificar qual cor vai ser a oficial
    } else if (status === 'APPROVED') {
      color = 'green';
    } else {
      color = 'red';
    }

    return color;
  }

  const handleAssume = (entity) => {
    setLoad(true);

    requests
      .postQuoteAssume(entity.identifier)
      .then((response) => {
        toast.success(`${response.message}`);
        loadList();

        setLoad(false);
      })
      .catch(() => {
        setLoad(false);
      });
  };

  return (
    <Table className="table-default" w="full">
      <Thead border="1px" borderBottom="1px" h={50}>
        <Tr>
          <Th></Th>
          <Th>Status</Th>
          <Th>Referência</Th>
          <Th>Modal</Th>
          <Th>Processo</Th>
          <Th>
            <Text>Data</Text>
            <Text fontSize="12px">(carga disponível)</Text>
          </Th>
          <Th>Origem</Th>
          <Th>Destino</Th>
          <Th>Valor invoice</Th>
          <Th>Solicitante</Th>
          <Th>
            <Text>Responsável</Text>
            <Text fontSize="12px">(logística)</Text>
          </Th>
        </Tr>
      </Thead>

      <Tbody border="1px">
        {list.length > 0 ? (
          <>
            {list.map((entity, key) => {
              return (
                <Tr key={key}>
                  <Td>
                    <Tooltip label="Visualizar">
                      <Box align="center">
                        <Link to={`/vendemmia/quote/detail/${entity.identifier}`}>
                          <MdOpenInNew size={20} cursor="pointer" color="#422C76" />
                        </Link>
                      </Box>
                    </Tooltip>
                  </Td>

                  <Td>
                    <Tag textAlign="center" width={'120px'} fontSize="17px" type={getStatusBackgroundFormatted(entity.status)}>
                      {entity.statusReadable}
                    </Tag>
                  </Td>

                  <Td style={{ color: '#6C48C2' }}>
                    <Link to={`/comex/process/detail/${entity.processIdentifier}`}>
                      {entity.reference.length <= 15 ? (
                        <>
                          {entity.reference}
                          <br />
                        </>
                      ) : (
                        <Flex gap="5px">
                          <Tooltip label={entity.reference}>
                            <Box>{entity.reference.substring(0, 10)}...</Box>
                          </Tooltip>
                        </Flex>
                      )}
                      <small>{`(${entity.name})`}</small>
                    </Link>
                  </Td>

                  <Td textAlign="center">
                    {entity.transportModalitySlug == 'air' && (
                      <Tooltip label="Aéreo">
                        <Image src={plane} alt="Cotação de frete aéreo" />
                      </Tooltip>
                    )}

                    {entity.transportModalitySlug == 'sea' && (
                      <Tooltip label="Maritima">
                        <Image src={vessel} alt="Cotação de frete marítima" />
                      </Tooltip>
                    )}
                  </Td>

                  <Td>{entity.processCode ? entity.processCode : 'Sem processo'}</Td>

                  <Td>{entity?.availableDateFormatted ?? '-'}</Td>

                  <Td>{entity?.originPortName ? entity?.originPortName + ' (' + entity?.originPortCountry + ')' : '-'}</Td>

                  <Td>{entity?.destinyPortName ? entity?.destinyPortName + ' (' + entity?.destinyPortCountry + ')' : '-'}</Td>

                  <Td>{entity?.invoiceValueFormatted ?? '-'}</Td>

                  <Td>{entity.requiredByName}</Td>

                  <Td>
                    {entity?.responsibleLogisticUserName ? (
                      entity?.responsibleLogisticUserName
                    ) : (
                      <DefaultModal
                        ctaButton={
                          <Button
                            textColor="#6C48C2"
                            h="30px"
                            opacity="1"
                            borderRadius="9px"
                            bgColor="#D0B0FD36"
                            gap="10px"
                            p="8px 24px ">
                            Assumir <Image src={assume} />
                          </Button>
                        }
                        title={'Assumir cotação'}
                        loading={load}
                        subtitle={
                          <>
                            Você tem certeza que deseja assumir essa cotação?
                            <br />
                            Ao assumir você se torna o responsável pela análise logística desse documento.
                          </>
                        }
                        label={null}
                        txtButton="Confirmar"
                        loadingText="Cancelar"
                        callback={async (decision) => {
                          if (decision) {
                            handleAssume(entity);
                          }
                        }}
                      />
                    )}
                  </Td>
                </Tr>
              );
            })}
          </>
        ) : (
          <Tr>
            <Td colSpan={11}>
              <Flex justify="center" my="25px">
                Nenhum dado encontrado para os parâmetros filtrados.
              </Flex>
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};

export default QuoteTable;
