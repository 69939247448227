import React, { useEffect, useState } from 'react';

import highlight from '../../../assets/images/highlight.png';
import LineTopics from '../LineTopics/LineTopics';
import './styles.css';

const BackSlider = ({ children, slides, className }) => {
  const [remainingTime, setRemainingTime] = useState(slides[0]?.timeSecs);
  const [slideId, setSlideId] = useState({ current: 0, next: 1 });
  const [trigger, setTrigger] = useState(false);
  const [clicked, setClicked] = useState(-1);
  const nextSlide = (id = null) => {
    id = id ?? slideId.next;
    let aux = (id + 1) % slides.length;
    setSlideId({ current: id, next: aux });
    setRemainingTime(slides[slideId.current].timeSecs);
  };

  useEffect(() => {
    if (remainingTime > 0) {
      const interval = setInterval(() => {
        setRemainingTime(remainingTime - 1);
        setTrigger(remainingTime == 2);
      }, 1000);
      return () => clearTimeout(interval);
    } else {
      nextSlide();
      setClicked(-1);
    }
  }, [remainingTime]);

  return (
    <main
      className={`back-slider ${trigger ? 'trigger' : ''} ${
        slides[slideId.current].filter > 0 ? 'thanos' : ''
      } ${className} `}
      style={{
        '--bg1': `url("${slides[slideId.current].urlBg}")`,
        '--bg2': `url("${slides[slideId.next].urlBg}")`,
        '--highlight': `url("${highlight}")`,
        '--light': slides[slideId.current].filter,
        marginTop: '-123px',
      }}>
      <LineTopics topicId={slides[slideId.current].topicId} />
      {children}
      <footer>
        <span className="current">{slides[slideId.current].text}</span>
        <span className="next">{slides[slideId.next].text}</span>
        <nav>
          {slides.map((ball, index) => (
            <span
              key={index}
              onClick={() => {
                setSlideId({ current: slideId.current, next: index });
                setRemainingTime(2);
                setClicked(index);
              }}
              className={`${slideId.current === index && clicked === -1 ? 'this' : ''} ${
                clicked === index ? 'boom' : ''
              }`}
            />
          ))}
        </nav>
      </footer>
    </main>
  );
};

export default BackSlider;
