import React, { useEffect, useRef, useState } from 'react';

import { Grid } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsComexCashflow } from '../../../utils/filters/filterPresets';

import FinanceFlowTable from './components/FinanceFlowTable';
import IncomeAndExpenseChart from './components/IncomeAndExpenseChart';
import CashFlowStyles from './styles/CashFlowStyles';

const CashFlowPage = () => {
  const hasPermission = permissions.vendemmiaComexCashFlow;

  const [isMobile, setIsMobile] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCategoryColumn, setIsLoadingCategoryColumn] = useState(false);

  const [forceSetSearchPeriod, setForceSetSearchPeriod] = useState(/* { startsAt: getFormattedDate() } */);
  const [filterOptions, setFilterOptions] = useState([]);

  const [fluxCashTableDataValue, setFluxCashTableDataValue] = useState([]);
  const [inOutGraphDataValue, setInOutGraphDataValue] = useState([]);

  const [foreignExchangeAdvance, setForeignExchangeAdvance] = useState([]);
  const [exchangeBalance, setExchangeBalance] = useState([]);
  const [paymentOfNationalizationTaxes, setPaymentOfNationalizationTaxes] = useState([]);
  const [nationalizationExpenses, setNationalizationExpenses] = useState([]);
  const [projectedRevenue, setProjectedRevenue] = useState([]);
  const [invoicedBills, setInvoicedBills] = useState([]);

  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [period, setPeriod] = useState({
    current: '',
    previous: '',
    next: '',
  });

  const load = async (filters, key) => {
    loadCashFlowTable(filters);
    loadInOutGraph(filters, forceSetSearchPeriod);
  };

  //APIs
  const loadCashFlowTable = async (getFilters) => {
    setIsLoading(true);

    try {
      const res = await requests.getCashFlowTable({
        search: getFilters?.search,
        filters: getFilters?.filters,
        period: getFilters?.period,
      });

      setPeriod({
        current: res.startsAt,
        previous: res.previous,
        next: res.next,
      });

      setFluxCashTableDataValue(res);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }

    setIsLoading(false);
  };

  const loadDetailsByStatus = async (slug, getFilters, startSearchPeriod) => {
    setIsLoadingCategoryColumn(true);

    try {
      const res = await requests.fetchDetailsByStatus(slug, {
        search: getFilters?.search,
        filters: getFilters?.filters,
        period: startSearchPeriod ? startSearchPeriod : '',
      });

      switch (slug) {
        case 'adiantamento-de-cambio':
          setForeignExchangeAdvance(res);
          break;
        case 'saldo-do-cambio':
          setExchangeBalance(res);
          break;
        case 'pagamento-dos-impostos-de-nacionalizacao':
          setPaymentOfNationalizationTaxes(res);
          break;
        case 'despesas-de-nacionalizacao':
          setNationalizationExpenses(res);
          break;
        case 'faturamento-previsto':
          setProjectedRevenue(res);
          break;
        case 'duplicatas-faturadas':
          setInvoicedBills(res);
          break;

        default:
          break;
      }
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }

    setIsLoadingCategoryColumn(false);
  };

  const loadInOutGraph = async (getFilters, startSearchPeriod) => {
    try {
      const res = await requests.getInOutGraph({
        search: getFilters?.search,
        filters: getFilters?.filters,
        period: startSearchPeriod ? startSearchPeriod : '',
      });

      setInOutGraphDataValue(res);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }
  };
  //APIs

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsComexCashflow());
  };

  const closeAllDetailsByStatus = () => {
    const fetchToggleButton = document.querySelectorAll('a[data-toggle-button="true"]');
    fetchToggleButton.forEach((button) => {
      button.click();
    });
  };

  const getFormattedDate = () => {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };

  const handleNext = () => {
    setForceSetSearchPeriod({
      startsAt: period.next,
    });
  };

  const handlePrevious = () => {
    setForceSetSearchPeriod({
      startsAt: period.previous,
    });
  };

  useEffect(() => {
    load();
    getFilterOptions();
  }, []);

  useEffect(() => {
    closeAllDetailsByStatus();
  }, [forceSetSearchPeriod]);

  return (
    <Page
      screen="comex-cashflow"
      title="Fluxo de caixa"
      breadcrumbs={[{ link: '#', title: 'Importação' }]}
      textFilterPlaceholder="Processo, empresa ou ref"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading}
      showPeriodFilter={false}
      forceSetSearchPeriod={forceSetSearchPeriod}>
      <Grid margin={'10px 10px 20px'} gap={'15px'}>
        <CashFlowStyles />
        <ScreenLoader isLoading={isLoading}>
          <Card p={'30px'} gap={'5px'}>
            <FinanceFlowTable
              fluxCashTableDataValue={fluxCashTableDataValue}
              loadDetailsByStatus={loadDetailsByStatus}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              forceSetSearchPeriod={forceSetSearchPeriod}
              isLoadingCategoryColumn={isLoadingCategoryColumn}
              detailsByStatus={{
                foreignExchangeAdvance,
                exchangeBalance,
                paymentOfNationalizationTaxes,
                nationalizationExpenses,
                projectedRevenue,
                invoicedBills,
              }}
            />
          </Card>
        </ScreenLoader>
        <ScreenLoader isLoading={isLoading}>
          <Card>
            <IncomeAndExpenseChart inOutGraphDataValue={inOutGraphDataValue} />
          </Card>
        </ScreenLoader>
      </Grid>
    </Page>
  );
};

export default CashFlowPage;
