import React from 'react';

import { Cell, Pie, PieChart, Tooltip } from 'recharts';

import { Box, Flex, Text } from '@chakra-ui/react';

const PieChartComponent = ({ entity, total, formatValue }) => {
  const tickFormatter = (tickItem) => {
    return tickItem.toLocaleString('pt-BR');
  };

  return (
    <>
      <Flex align="center" justify="center">
        <Box zIndex="2">
          <PieChart width={250} height={250}>
            <Tooltip formatter={tickFormatter} />
            <Pie data={entity} dataKey="qtt" innerRadius={100} outerRadius={117} cx="50%" cy="50%">
              {entity.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </Box>

        <Flex position="absolute" direction="column" align="center">
          <Text textStyle="secondaryTitle" textColor="#B3B8BD">
            Total
          </Text>

          <Text textStyle="primaryTitle" textColor="#333333" fontWeight="bold">
            {formatValue(total)}
          </Text>
        </Flex>
      </Flex>
    </>
  );
};

export default PieChartComponent;
