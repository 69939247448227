import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import getProfile from '../../../services/profile';
import requests from '../../../services/requests';
import '../../../styles/globals.css';
import { editarPerfil } from '../../../utils/exports';
import EditImage from '../../DropdownProfile/Edit/EditImage';
import EditInfos from '../../DropdownProfile/Edit/EditInfos';
import EditPass from '../../DropdownProfile/Edit/EditPass';

const EditProfileModal = () => {
  const [infos, setInfos] = useState({});
  const [newPicture, setNewPicture] = useState(null);
  const [imageBlob, setImageBlob] = useState(null);
  const [newPwd, setNewPwd] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const updateProfileData = async () => {
    const newInfos = await getProfile.update();
    setInfos(newInfos);
  };

  const saveAll = async () => {
    let allOK = true;

    if (newPicture) {
      allOK = allOK && (await saveNewImage());
    }

    allOK = allOK && (await saveInfos());

    if (newPwd.length > 6) {
      allOK = allOK && (await savePass());
    }

    await getProfile.update();

    if (allOK) {
      toast.error('oi');
    } else {
      toast.error('Algo deu errado, por favor, revise os dados inseridos.');
    }
  };

  const saveNewImage = async () => {
    if (!newPicture) {
      return;
    }

    const data = new FormData();
    data.append('image_upload[file]', newPicture);

    try {
      await requests.uploadUserPicture(getProfile.identifier, data);
      window.localStorage.setItem('profile-picture', imageBlob);
      setNewPicture(null);
    } catch (error) {
      toast.error('Falha ao alterar a imagem, tente novamente.');
    }
  };

  const saveInfos = async () => {
    try {
      await requests.editProfile({
        name: infos.name,
        email: infos.email,
        cellphone: infos.cellphone,
        phone: infos.phone,
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  const savePass = async () => {
    try {
      await requests.editPassword({
        password: newPwd,
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    updateProfileData();
  }, []);

  return (
    <>
      <Flex gap="20px" align="center" cursor="pointer" onClick={onOpen}>
        <Image src={editarPerfil} w="44px" h="33px" />
        <Text textStyle="tableTitle" fontWeight="bold" color="primary" _hover={{ textDecoration: 'underline' }}>
          Editar perfil
        </Text>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />

        <ModalContent maxW="768px" minH={{ base: '80vh', md: '60vh' }} borderRadius="5px">
          <ModalBody p="25px" position="relative">
            <Text textStyle="secondaryTitle" fontWeight="medium" textColor="#422C76" mb="20px" fontSize={{ base: '22px', md: '30px' }}>
              Editar informações de perfil
            </Text>

            <Tabs>
              <TabList>
                <Tab _selected={{ borderColor: '#422C76' }}>
                  <Text textStyle="tableTitle" fontWeight="medium" textColor="#422C76" fontSize={{ base: '16px', md: '20px' }}>
                    Imagem de Perfil
                  </Text>
                </Tab>

                <Tab _selected={{ borderColor: '#422C76' }}>
                  <Text textStyle="tableTitle" fontWeight="medium" textColor="#422C76" fontSize={{ base: '16px', md: '20px' }}>
                    Edição de Perfil
                  </Text>
                </Tab>

                <Tab _selected={{ borderColor: '#422C76' }}>
                  <Text textStyle="tableTitle" fontWeight="medium" textColor="#422C76" fontSize={{ base: '16px', md: '20px' }}>
                    Trocar Senha
                  </Text>
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <EditImage newPicture={newPicture} setNewPicture={setNewPicture} imageBlob={imageBlob} setImageBlob={setImageBlob} />
                </TabPanel>

                <TabPanel>
                  <EditInfos setInfos={setInfos} />
                </TabPanel>

                <TabPanel>
                  <EditPass setNewPwd={setNewPwd} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter justifyContent="flex-end">
            <Button variant="secundary" borderRadius="7px" onClick={onClose}>
              Cancelar e Fechar
            </Button>

            <Button variant="primary" borderRadius="7px" marginLeft="15px" onClick={saveAll}>
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditProfileModal;
