import React, { useEffect, useState } from 'react';

import { Flex, Grid, Text } from '@chakra-ui/layout';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import ParameterizationChart from '../../../components/Charts/Comex/ParameterizationChart';
import ProcessPerStepChart from '../../../components/Charts/Comex/ProcessPerStepChart';

const DashboardCharts = ({ searchPeriod, filterInfo, searchString, shouldRefresh, setIsRefreshing, searchFilterData }) => {
  
  const [sizeWidth, setSizeWidth] = useState()

  useEffect(() => {
    const windowWidth = window.innerWidth
    windowWidth > 760 ? (setSizeWidth(500)) : (setSizeWidth(300));
  }, [])

  const [isRefreshingParameterizationChart, setIsRefreshingParameterizationChart] = useState(false);
  const [isRefreshingProcessPerStepChart, setIsRefreshingProcessPerStepChart] = useState(false);

  useEffect(() => {
    setIsRefreshing(isRefreshingParameterizationChart || isRefreshingProcessPerStepChart);
  }, [isRefreshingParameterizationChart, isRefreshingProcessPerStepChart]);

  const chartView = [
    {
      title: 'Parametrização - Canal',
      content: <ParameterizationChart
      titleStyle="cardTitle"
      searchPeriod={searchPeriod}
      filterInfo={filterInfo}
      searchString={searchString}
      shouldRefresh={shouldRefresh}
      setIsRefreshing={setIsRefreshingParameterizationChart} 
      sizeWidth={sizeWidth + 'px'}
      searchFilterData={searchFilterData}
      />
    },
    {
      title: 'Processos por etapa',
      content: <ProcessPerStepChart
      titleStyle="cardTitle"
      searchPeriod={searchPeriod}
      filterInfo={filterInfo}
      searchString={searchString}
      shouldRefresh={shouldRefresh}
      setIsRefreshing={setIsRefreshingProcessPerStepChart}
      sizeWidth={sizeWidth + 'px'}
      searchFilterData={searchFilterData}
      />
    }
  ]

  return (
    <>
      <Flex justify="space-between" align="center" minH="300px" display={{ base: 'none', md: 'flex' }}>
        {chartView.map((item, index) => {
          return (
              <Card key={index} h="450px" title={item.title} w="calc(50% - 5px)">
                {item.content}
              </Card>
            )
          })
        }
      </Flex>
      <Flex 
        justify="center" 
        align="center" 
        display={{ base: 'flex', md: 'none' }}
        borderRadius={8}
        overflow="hidden"
        borderColor="#70707033"
        borderWidth= "0px 1px 0px 1px"
        bg="white"
        boxShadow='base'
      >
          <Grid w="100%">
            <Accordion defaultIndex={[0]} allowToggle>
                {chartView.map((item, index) => {
                  return (
                    <AccordionItem key={index}>
                      <h2>
                        <AccordionButton 
                          p="20px"
                          _hover={{
                            background: "white",
                          }}
                        >
                          <Text 
                            textStyle="title" 
                            textColor="primary" 
                            flex='1' 
                            fontWeight="bold"
                            fontSize="18px"
                          >
                            {item.title}
                          </Text>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel 
                        pb={4}
                        bg="white"
                      >
                        {item.content}
                      </AccordionPanel>
                    </AccordionItem>
                    )
                  })
                }
            </Accordion>
          </Grid>
      </Flex>
    </>
  );
};

export default DashboardCharts;
