import React, { useEffect, useState } from 'react';

import { Box, Flex, TabPanel } from '@chakra-ui/react';

import requests from '../../../../../services/requests';
import TravelTimelineStep from '../Timeline/TimelineStep';

const TrackingTab = ({ entity, setCenter, setZoom }) => {
  const [steps, setSteps] = useState([]);

  const getSteps = () => {
    requests
      .listTripRoute(entity?.identifier)
      .then((response) => {
        setSteps(response.routes);
      })
    ;
  };

  useEffect(() => {
    if (entity?.identifier !== null && entity?.identifier !== undefined) {
      getSteps();
    }
  }, [entity]);

  return (
    <TabPanel>
      {entity?.status == 'TRAVELLING' && (
        <Flex align="center">
          {entity?.additionalInformation?.travelledDistance > 0 && (
            <Box
              width="133px"
              height="67px"
              backgroundColor="#3498DB"
              color="white"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              borderRadius="5px"
              textAlign="center">
              <span color="#FFFFFF91" style={{ fontSize: '12px', fontWeight: 'normal' }}>
                Distância
              </span>
              <span style={{ fontSize: '22px', fontWeight: 'normal' }}>{entity?.additionalInformation?.travelledDistance} km</span>
            </Box>
          )}

          {entity?.additionalInformation?.speedAverage > 0 && (
            <Box
              width="133px"
              height="67px"
              ml="4"
              backgroundColor="green"
              color="white"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              borderRadius="5px"
              textAlign="center">
              <span color="#FFFFFF91" style={{ fontSize: '12px', fontWeight: 'normal' }}>
                Tempo médio
              </span>
              <span style={{ fontSize: '22px', fontWeight: 'normal' }}>{entity?.additionalInformation?.speedAverage} horas</span>
            </Box>
          )}
        </Flex>
      )}
      <Flex 
        w="100%" 
        direction='row' 
        p={"16px 24px"}
        flexWrap={"wrap"}
      >
        {steps.map((step, i) => (
          <TravelTimelineStep
            key={i}
            stepCount={i}
            delay={i + 1}
            progress={step.progress}
            title={step.title}
            infos={step}
            isBlock={step.block}
            setCenter={setCenter}
            setZoom={setZoom}
          />
        ))}
      </Flex>
    </TabPanel>
  );
};

export default TrackingTab;
