import React, { useState } from 'react';

import { Box, Flex, Grid, Text } from '@chakra-ui/react';

import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';

import ChartAbc from './ChartAbc';

const TypeOperation = ({ data, isLoading, sizeWidth }) => {
  const [activeItemName, setActiveItemName] = useState(null);

  const onPieEnter = (item) => {
    setActiveItemName(item.name);
  };

  const onPieLeave = () => {
    setActiveItemName(null);
  };

  return (
    <Flex w="100%" h="full" minH={{ base: 'initial', md: '320px' }} direction="column" textColor="primary" py="10px">
      <ScreenLoader isLoading={isLoading}>
        <Flex
          flexFlow={{ base: 'column', xx: 'row' }}
          alignItems="center"
          w="100%"
          h="full"
          gap={{ base: '25px', xx: '0px' }}
          justify={{ base: 'flex-start', xx: 'center' }}>
          <ChartAbc
            entity={data}
            text="Total de operações"
            activeItemName={activeItemName}
            sizeWidth={{ base: sizeWidth, md: '500px' }}
          />

          <Grid
            templateColumns={{ base: '1fr 1fr', md: 'repeat(4, 1fr)', xx: '200px 200px' }}
            justifyItems="flex-start"
            gap={{ base: '10px 25px', md: '20px' }}>
            {data?.length > 0 && data.map((item, key) => (
              <Flex gap="8px" align="center" key={key} onMouseEnter={() => onPieEnter(item)} onMouseLeave={onPieLeave}>
                <Box minW="28px" minH="18px" bgColor={item.color} borderRadius="3px" />

                <Text textStyle="subtitle" textColor="#0F0A1D" fontSize={{ base: '12px', lg: '16px', xl: '18px' }}>
                  {item.name}
                </Text>
              </Flex>
            ))}
          </Grid>
        </Flex>
      </ScreenLoader>
    </Flex>
  );
};

export default TypeOperation;
