import React, { useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Grid, Text } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import CurveStockChart from '../../../components/Charts/Warehouse/CurveStockChart';
import ProductStatusChart from '../../../components/Charts/Warehouse/ProductStatusChart';
import TotalProducts from '../../../components/Charts/Warehouse/TotalProducts';
import ExpandContainer from '../../../components/ExpandContainer/ExpandContainer';
import Page from '../../../components/Page';
import Paginate from '../../../components/Paginate/Paginate';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsWarehouse } from '../../../utils/filters/filterPresets';

import StockTable from './components/StockTable';

const StockPage = () => {
  const hasPermission = permissions.warehouseStock;

  const [isMobile, setIsMobile] = useState(false);

  const request = useRef(0);

  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);
  const [showUploadPalletPosition, setShowUploadPalletPosition] = useState(true);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [isLoadingChart, setIsLoadingChart] = useState(true);
  const [ownState, setOwnState] = useState(false);
  const [useOwnState, setUseOwnState] = useState(false);
  const requestChart = useRef(0);
  const [chartData, setChartData] = useState({});

  const [sorting, setSorting] = useState(null);
  const handleSort = (column, direction) => {
    setSorting({
      column: column,
      direction: direction,
    });
  };

  const load = (filters, key, page) => {
    setIsLoading(true);

    requests
      .listEstoque(filters, page)
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }
        request.current = key;

        setList(data.data);
        setMetadata(data.meta);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);
  };

  const loadCharts = (filters, key) => {
    setIsLoadingChart(true);

    delete filters.sort;
    delete filters.page;

    requests
      .dashboardEstoque(filters)
      .then((data) => {
        if (requestChart.current && requestChart.current > key) {
          return;
        }
        requestChart.current = key;

        setChartData(data.data);
      })
      .finally(() => {
        setIsLoadingChart(false);
      });
  };

  const exportListFABAction = (filters, key) => {
    const toastId = toast.info('Aguarde enquanto preparamos a exportação...', {
      autoClose: false, // Mantém o toast na tela até que seja fechado manualmente
      closeOnClick: false, // Impede que o usuário feche o toast ao clicar nele
      draggable: false, // Impede que o usuário arraste o toast
      closeButton: false, // Não exibe o botão de fechar o toast
    });

    requests
      .exportListEstoque(filters)
      .then((response) => {
        const date = new Date();
        const fileName = 'Estoque' + '-' + date.getFullYear() + date.getMonth() + date.getDate() + '.xlsx';

        const link = document.createElement('a');

        link.setAttribute('download', fileName);
        link.href = window.URL.createObjectURL(
          new Blob([response.data], {
            fileType: response.headers['content-type'],
          })
        );

        document.body.appendChild(link);

        link.click();
        link.remove();

        toast.dismiss(toastId); // Remove o toast exibido anteriormente
        toast.success('Exportação efetuado com sucesso!', {
          autoClose: 1500, // Tempo em milissegundos que o toast ficará na tela
        });
      })
      .catch(() => {
        toast.dismiss(toastId); // Remove o toast exibido anteriormente
        toast.error('Problemas ao realizar exportação, tente novamente ou entre em contato com o Administrador');
      });
  };

  const handlerUploadPalletPosition = (docs, config) => {
    setUseOwnState(true);

    const toastId = toast.info('Aguarde enquanto enviamos os arquivos para o servidor...', {
      autoClose: false, // Mantém o toast na tela até que seja fechado manualmente
      closeOnClick: false, // Impede que o usuário feche o toast ao clicar nele
      draggable: false, // Impede que o usuário arraste o toast
      closeButton: false, // Não exibe o botão de fechar o toast
    });
    setOwnState(true);

    console.log(docs, config);

    requests
      .uploadPalletPosition(docs, config)
      .then(() => {
        toast.dismiss(toastId); // Remove o toast exibido anteriormente
        toast.success('Upload efetuado com sucesso!', {
          autoClose: 1500, // Tempo em milissegundos que o toast ficará na tela
        });
        setOwnState(false);
      })
      .catch(() => {
        toast.dismiss(toastId); // Remove o toast exibido anteriormente
        toast.error('Problemas ao realizar o upload, tente novamente ou entre em contato com o Administrador');
        setOwnState(false);
      });
  };

  const [filterOptions, setFilterOptions] = useState([]);
  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsWarehouse());
  };
  useEffect(() => {
    getFilterOptions();
  }, []);

  const chartsView = [
    {
      title: 'Curva ABC',
      content: <CurveStockChart data={chartData} modality="ESTOQUE" />,
    },
    {
      title: 'Status de Produtos',
      content: <ProductStatusChart data={chartData} />,
    },
    {
      title: 'Total de Produtos',
      content: <TotalProducts data={chartData} />,
    },
  ];

  return (
    <Page
      screen="warehouse"
      title="Estoque"
      breadcrumbs={[{ link: '#', title: 'Armazém' }]}
      textFilterPlaceholder="Código, lote ou descrição"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      loadCharts={loadCharts}
      load={load}
      isContentLoading={isLoading}
      sorting={sorting}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading || isLoadingChart}
      showPeriodFilter={false}
      allowEmptySearchPeriod={true}
      useOwnState={useOwnState}
      ownState={ownState}
      setOwnState={setOwnState}
      disableAdditionalFields={true}
      disableMultiUpload={true}
      nameForm={'file_upload'}
      FAB={[
        showUploadPalletPosition && {
          title: 'Upload de posição pallet',
          text: 'Importar arquivo CSV com dados de estoque',
          action: handlerUploadPalletPosition,
          modality: 'upload-files',
        },
        {
          title: 'Exportar estoque',
          text: 'Exportar arquivo CSV com dados de estoque',
          action: exportListFABAction,
          modality: 'export-csv',
        },
      ]}>
      <Flex direction="column" gap="20px" m="10px">
        {!isMobile ? (
          /* desktop */
          <Grid w="full" gap="10px" templateColumns="repeat(3, 1fr)">
            {chartsView.map((item, index) => {
              return (
                <Card key={index} pb="20px" title={item.title}>
                  <ScreenLoader isLoading={isLoadingChart}>{item.content}</ScreenLoader>
                </Card>
              );
            })}
          </Grid>
        ) : (
          /* mobile */
          <Accordion w="full" gap="10px" border={'0'} borderRadius={'8px'}>
            {chartsView.map((item, index) => {
              return (
                <AccordionItem
                  key={index}
                  border={'0'}
                  bg={'white'}
                  borderX={'1px solid#70707036'}
                  borderRadius={'8px'}
                  overflow={'hidden'}
                  boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)"
                  mb={'10px'}>
                  <AccordionButton
                    display={'flex'}
                    justifyContent={'space-between'}
                    _hover={{ bg: 'white' }}
                    borderTop={'1px solid #70707036'}
                    p={'16px 21px'}>
                    <Text textStyle="cardTitle" fontSize="16px">
                      {item.title}
                    </Text>
                    <AccordionIcon />
                  </AccordionButton>
                  <ScreenLoader isLoading={isLoadingChart}>
                    <AccordionPanel>{item.content}</AccordionPanel>
                  </ScreenLoader>
                </AccordionItem>
              );
            })}
          </Accordion>
        )}

        <Card id="tableId" className="up-anim">
          <ExpandContainer>
            <Box w="full">
              <ScreenLoader isLoading={isLoading}>
                <Box overflow="auto" h="calc(100vh - 220px)">
                  <StockTable list={list} handleSort={handleSort} sorting={sorting} />
                </Box>
              </ScreenLoader>

              {metadata.total_pages > 1 && <Paginate metadata={metadata} setMetadata={setMetadata} scroll="tableId" />}
            </Box>
          </ExpandContainer>

          <Box overflowX="auto" m="1.2rem">
            <ScreenLoader isLoading={isLoading}>
              <StockTable list={list} handleSort={handleSort} sorting={sorting} />
            </ScreenLoader>
          </Box>

          {metadata.total_pages > 1 && <Paginate metadata={metadata} setMetadata={setMetadata} scroll="tableId" />}
        </Card>
      </Flex>
    </Page>
  );
};

export default StockPage;
