import React from 'react';

import moment from 'moment';

import { Table, Tbody, Td, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';

import { limitChars } from '../../../../utils/strings';

const MapTable = ({ data, appliedFields }) => {
  const returnComments = (entity) => {
    let comments = '';
    const total = entity?.length - 1;

    entity.map((element, key) => {
      comments += element?.description;

      if (key < total) comments += ' <br /> ';
    });

    return comments;
  };

  const renderPrimaryTitles = () => {
    var i = 0;

    return appliedFields.map((field, index) => {
      if (!field.active) {
        return '';
      }
      i++;

      var qttSubItens = 0;
      field.subFields.forEach((field) => {
        if (field.active) {
          qttSubItens++;
        }
      });
      return (
        <Th key={index} colSpan={qttSubItens} py="10px !important" fontWeight="600" fontSize="18px" textAlign="left">
          {field.name}
        </Th>
      );
    });
  };

  const renderSubTitles = () => {
    var i = 0;
    return appliedFields.map((field, index) => {
      if (!field.active) {
        return '';
      }
      i++;

      return field.subFields.map((subField, subIndex) => {
        if (!subField.active) {
          return '';
        }

        return (
          <Th key={`${index}-${subIndex}`} py="10px !important" whiteSpace="nowrap" fontWeight="600" color="#0F0A1D !important">
            {subField.name}
          </Th>
        );
      });
    });
  };

  const getContent = (entity, id) => {
    var content = '-';
    switch (id) {
      case 'ref':
        content = entity?.process?.clientInternalCode ? entity?.process?.clientInternalCode : '-';
        break;
      case 'cliente':
        content = entity?.process?.clientOrderer ? entity?.process?.clientOrderer : '-';
        break;
      case 'etapa':
        content = entity?.process?.currentStageModality ? entity?.process?.currentStageModality : '-';
        break;
      case 'processo':
        content = entity?.process?.code ? entity?.process?.code : '-';
        break;
      case 'tipoOperacao':
        content = entity?.tipoOperacao ? entity?.tipoOperacao : '-';
        break;
      case 'frete':
        content = entity?.freight ? entity?.freight : '-';
        break;
      case 'valorFrete':
        content = entity?.freightInternational ? entity?.freightInternational : '-';
        break;
      case 'valorFreteUsd':
        content = entity?.freightUsd ? entity?.freightUsd : '-';
        break;
      case 'refExportador':
        content = entity?.refExportador ? entity?.refExportador : '-';
        break;
      case 'filial':
        content = entity?.filial ? entity?.filial : '-';
        break;
      case 'incoterm':
        content = entity?.incoterm ? entity?.incoterm : '-';
        break;
      case 'exportador':
        content = entity?.exporter ? entity?.exporter : '-';
        break;
      case 'portoOrigem':
        content = entity?.portoOrigem ? entity?.portoOrigem : '-';
        break;
      case 'portoDestino':
        content = entity?.portoDestino ? entity?.portoDestino : '-';
        break;
      case 'weight':
        content = entity?.weight ? entity?.weight : '-';
        break;
      case 'produtoRefMaterial':
        content = entity?.refMaterial ? entity?.refMaterial : '-';
        break;
      case 'descricaoProduto':
        content = entity?.productDescription ? entity?.productDescription : '-';
        break;
      case 'codAcondicEmbalagem':
        content = entity?.package ? entity?.package : '-';
        break;
      case 'codProduto':
        content = entity?.productCode ? entity?.productCode : '-';
        break;
      case 'invoice':
        content = entity?.invoice ? entity?.invoice : '-';
        break;
      case 'cnt':
        content = entity?.cnt ? entity?.cnt : '-';
        break;
      case 'qtde':
        content = entity?.qtt ? entity?.qtt : '-';
        break;
      case 'valorUnit':
        content = entity?.unitValue ? entity?.unitValue : '-';
        break;
      case 'valorTotalUSD':
        content = entity?.usdValue ? entity?.usdValue : '-';
        break;
      case 'moeda':
        content = entity?.currencyType ? entity?.currencyType : '-';
        break;
      case 'valorTotalFaturamento':
        content = entity?.totalValue ? entity?.totalValue : '-';
        break;
      case 'li':
        content = entity?.li ? entity?.li : '-';
        break;
      case 'ncm':
        content = entity?.ncm ? entity?.ncm : '-';
        break;
      case 'imo':
        content = entity?.cargaImo ? entity?.cargaImo : '-';
        break;
      case 'vencimentoCambio':
        content = entity?.exchangeMaturity ? entity?.exchangeMaturity : '-';
        break;
      case 'originais':
        content = entity?.originais ? moment(entity?.originais).format('DD/MM/Y') : '-';
        break;
      case 'prevEntrega':
        content = entity?.deliveryPrevision ? moment(entity?.deliveryPrevision).format('DD/MM/Y') : '-';
        break;
      case 'dataEntrega':
        content = entity?.process?.deliveredAtFormatted ? entity?.process?.deliveredAtFormatted : '-';
        break;
      case 'blAwbCrt':
        content = entity?.blAwbCrt ? entity?.blAwbCrt : '-';
        break;
      case 'crt20':
        content = entity?.ctnr20 ? entity?.ctnr20 : '-';
        break;
      case 'crt40':
        content = entity?.ctnr40 ? entity?.ctnr40 : '-';
        break;
      case 'tipocntr':
        content = entity?.tipoCNTR ? entity?.tipoCNTR : '-';
        break;
      case 'navio':
        content = entity?.vessel ? entity?.vessel : '-';
        break;
      case 'etd':
        content = entity?.etd ? moment(entity?.etd).format('DD/MM/Y') : '-';
        break;
      case 'envioCartaProtesto':
        content = entity?.protesto ? moment(entity?.protesto).format('DD/MM/Y') : '-';
        break;
      case 'mapaStatus':
        content = entity?.statusLiberacao ? entity?.statusLiberacao : '-';
        break;
      case 'eta':
        content = entity?.eta ? moment(entity?.eta).format('DD/MM/Y') : '-';
        break;
      case 'presencaCarga':
        content = entity?.presencaCarga ? moment(entity?.presencaCarga).format('DD/MM/Y') : '-';
        break;
      case 'dataRemocao':
        content = entity?.removalDate ? moment(entity?.removalDate).format('DD/MM/Y') : '-';
        break;
      case 'previsaoEntrega':
        content = entity?.deliveryPrevision ? moment(entity?.deliveryPrevision).format('DD/MM/Y') : '-';
        break;
      case 'di':
        content = entity?.diFormatted ? entity?.diFormatted : '-';
        break;
      case 'dataRegistro':
        content = entity?.process?.clearenceDiAtFormatted ? entity?.process?.clearenceDiAtFormatted : '-';
        break;
      case 'canal':
        content = entity?.process?.channel ? entity?.process?.channel : '-';
        break;
      case 'dataDesembaraco':
        content = entity?.process?.clearenceCiAtFormatted ? entity?.process?.clearenceCiAtFormatted : '-';
        break;
      case 'localDesembaraco':
        content = entity?.clearancePlace ? entity?.clearancePlace : '-';
        break;
      case 'freeTime':
        content = entity?.freeTime ? entity?.freeTime : '-';
        break;
      case 'dataVencFreeTime':
        content = entity?.dtVencFreeTime ? moment(entity?.dtVencFreeTime).format('DD/MM/Y') : '-';
        break;
      case 'contratoCambio':
        content = entity?.cambio ? entity?.cambio : '-';
        break;
      case 'modalidadePagamento':
        content = entity?.modalidade ? entity?.modalidade : '-';
        break;
      case 'aPagarVendemmia':
        content = entity?.toPayVendemmia ? entity?.toPayVendemmia : '-';
        break;
      case 'dataPgto':
        content = entity?.paymentDate ? moment(entity?.paymentDate).format('DD/MM/Y') : '-';
        break;
      case 'previsaoPagamento':
        content = entity?.billingPrevision ? moment(entity?.billingPrevision).format('DD/MM/Y') : '-';
        break;
      case 'acompDetalhado':
        //content = entity?.detailedTracking ? entity?.detailedTracking : "-";
        content = entity?.process?.comments && entity?.process?.comments?.length > 0 ? returnComments(entity?.process?.comments) : '-';
        break;
      case 'coleta':
        content = entity?.coleta ? moment(entity?.coleta).format('DD/MM/Y') : '-';
        break;
      case 'valorFatNf':
        content = entity?.revenueValue ? entity?.revenueValue : '-';
        break;
      case 'dataFaturamento':
        content = entity?.revenueDate ? moment(entity?.revenueDate).format('DD/MM/Y') : '-';
        break;
      case 'camposAdicionais':
        content = entity?.additionalField ? entity?.additionalField : '-';
        break;
    }

    return content;
  };

  const renderContents = (entity, entityIndex) => {
    var i = 0;
    return appliedFields.map((field, fieldIndex) => {
      if (!field.active) {
        return '';
      }
      i++;

      var j = 0;
      return field.subFields.map((subField, subFieldIndex) => {
        if (!subField.active) {
          return '';
        }
        j++;

        return (
          <Td minW="200px" key={`${entityIndex}-${fieldIndex}-${subFieldIndex}`}>
            {getContent(entity, subField.id).length > 30 ? (
              <Tooltip label={<div dangerouslySetInnerHTML={{__html: getContent(entity, subField.id)}} />}>
                {limitChars(getContent(entity, subField.id), 30)}
              </Tooltip>
            ) : (
              getContent(entity, subField.id)
            )}
          </Td>
        );
      });
    });
  };

  return (
    <Table className="table-default" w="full">
      <Thead bg="#fefefe" py="10px">
        <Tr>{renderPrimaryTitles()}</Tr>
        <Tr>{renderSubTitles()}</Tr>
      </Thead>
      <Tbody>
        {data.map((entity, entityIndex) => (
          <Tr key={entityIndex}>{renderContents(entity, entityIndex)}</Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default MapTable;
