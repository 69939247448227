import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { ToastContainer } from 'react-toastify';
import * as yup from 'yup';

import { Button, Flex, Image, Input, Link, Text } from '@chakra-ui/react';

import apple from '../../assets/images/apple.png';
import google from '../../assets/images/google.png';
import bg1 from '../../assets/images/login/bgs/bg-1.jpg';
import bgAcesse from '../../assets/images/login/bgs/bg-acesse.png';
import bgEvoluiu from '../../assets/images/login/bgs/bg-evoluiu.png';
import Loading from '../../components/Loading/Loading';
import BackSlider from '../../components/Login/BackSlider/BackSlider';
import CardRound from '../../components/Login/CardRound/CardRound';
import PrivacyPolicy from '../../components/Modal/PrivacyPolicy/PrivacyPolicy';
import api from '../../services/api';
import requests from '../../services/requests';

const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [hiddenToast, setHiddenToast] = useState(false);

  const validationSchema = yup.object({
    email: yup.string().email('E-mail inválido').required('E-mail obrigatório'),
    password: yup.string().min(8, 'A senha deve ter pelo menos 8 caracteres').required('Senha obrigatória'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(validationSchema),
  });

  const bgs = [
    {
      urlBg: bgEvoluiu,
      text: 'Lançamento do novo painel',
      timeSecs: 5,
      topicId: null,
      filter: 0, // 0 - Desativado, qualquer outro valor é o brilho do filtro. (Atualmente desativado)
    },
    {
      urlBg: bgAcesse,
      text: 'Vendemmia Logística Integrada',
      timeSecs: 5,
      topicId: null,
      filter: 0, // 0 - Desativado, qualquer outro valor é o brilho do filtro. (Atualmente desativado)
    },
    {
      urlBg: bg1,
      text: 'Comércio internacional',
      timeSecs: 5,
      topicId: 1, // 1 - Navio, 2 - Caminhão, 3 - Armazém, null - Ocultar
      filter: 0, // 0 - Desativado, qualquer outro valor é o brilho do filtro. (Atualmente desativado)
    },
    {
      urlBg: bg1,
      text: 'Transportes e logística',
      timeSecs: 5,
      topicId: 2, // 1 - Navio, 2 - Caminhão, 3 - Armazém, null - Ocultar
      filter: 0, // 0 - Desativado, qualquer outro valor é o brilho do filtro. (Atualmente desativado)
    },
    {
      urlBg: bg1,
      text: 'Armazém e tecnologia',
      timeSecs: 5,
      topicId: 3, // 1 - Navio, 2 - Caminhão, 3 - Armazém, null - Ocultar
      filter: 0, // 0 - Desativado, qualquer outro valor é o brilho do filtro. (Atualmente desativado)
    },
  ];

  const onSubmit = (data) => {
    setLoading(true);
    api
      .post('/login/check', {
        username: data.email,
        password: data.password,
      })
      .then((resLogin) => {
        var token = resLogin.data.token;
        localStorage.setItem('session-token', token);

        requests.getProfile().then((profile) => {
          localStorage.setItem('profile-role', profile.role);
          localStorage.setItem('profile-identifier', profile.identifier);
          localStorage.setItem('profile-name', profile.name);
          localStorage.setItem('profile-email', profile.email);
          localStorage.setItem('profile-cellphone', profile.cellphone);
          localStorage.setItem('profile-phone', profile.phone);
          localStorage.setItem('profile-role-human-readable', profile.roleHumanReadable);
          localStorage.setItem('profile-picture', profile.picture);
          localStorage.setItem('profile-permissions', JSON.stringify(profile.permissions));

          let redirectUserTo = window.localStorage.getItem('redirectTo') ?? '/dashboard';
          let redirectUserExpiresAt = window.localStorage.getItem('redirectToExpiresAt');

          if (!redirectUserExpiresAt || new Date() > redirectUserExpiresAt) {
            redirectUserTo = '/dashboard';
          }

          window.localStorage.removeItem('redirectTo');
          window.localStorage.removeItem('redirectToExpiresAt');
          window.location.href = redirectUserTo;

          return;
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(true);
        setHiddenToast(true);

        localStorage.setItem('profile-email', data.email);

        const message =
          'Este usuário está expirado e deve solicitar reativação de seu acesso: entre em contato com o time da Vendemmia.';

        if (error.response.data.message === message) {
          window.location.href = '/reactivation';
        }
      });
  };

  const handleRecover = (e) => {
    e.preventDefault();
    window.localStorage.setItem('profile-email', getValues().email);
    window.location.href = '/recover';
  };

  const handleRegister = (e) => {
    e.preventDefault();
    window.location.href = '/register';
  };

  return (
    <BackSlider slides={bgs}>
      {!hiddenToast && <ToastContainer />}
      <CardRound>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex direction="column" gap="30px">
            <Flex direction="column" gap="5px">
              <Input pl="20px" {...register('email')} placeholder="E-mail: " variant="flushed" />
              {errors.email && <Text textStyle="error">{errors.email.message}</Text>}
            </Flex>

            <Flex direction="column" gap="5px">
              <Input pl="20px" {...register('password')} placeholder="Senha: " type="password" variant="flushed" />
              {errors.password && <Text textStyle="error">{errors.password.message}</Text>}
            </Flex>

            <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" align="center" gap={{ base: '10px', md: '0' }}>
              <Button
                variant="secundary"
                onClick={handleRecover}
                w={{ base: '100%', md: 'inherit' }}
                order={{ base: '2', md: 'initial' }}>
                <Text textStyle="paragraph">Esqueci minha senha</Text>
              </Button>

              <Button variant="primary" w={{ base: '100%', md: 'inherit' }} order={{ base: '1', md: 'initial' }} type="submit">
                <Text textStyle="paragraph">Entrar</Text>
              </Button>
            </Flex>
          </Flex>
        </form>

        {false && (
          <Flex flexDirection="column" gap="10px">
            <Text textStyle="paragraph" color="textColor3">
              Também disponível para:
            </Text>

            <Flex direction="row" justify="space-between" align="center">
              <Link href="https://play.google.com/store/apps/details?id=br.com.topnode.vendemmia">
                <Image
                  src={google}
                  alt="google"
                  width={{ sm: '95px', md: '120px' }}
                  height={{ sm: '28px', md: '35px' }}
                  transition="transform 0.25s ease"
                  _hover={{
                    transform: 'scale(1.05)',
                  }}
                />
              </Link>

              <Link href="https://apps.apple.com/br/app/vendemmia/id1559775559">
                <Image
                  src={apple}
                  alt="apple"
                  width={{ sm: '95px', md: '120px' }}
                  height={{ sm: '28px', md: '35px' }}
                  transition="transform 0.25s ease"
                  _hover={{
                    transform: 'scale(1.05)',
                  }}
                />
              </Link>
            </Flex>
          </Flex>
        )}

        <Flex align="center" justify="center" mt="35px">
          <PrivacyPolicy selectedContent={1} customButton="Política de Privacidade" color="#707070" size="14px" />
        </Flex>
      </CardRound>

      <Loading status={loading} />
    </BackSlider>
  );
};

export default LoginPage;
