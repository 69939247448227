import React, { useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';

import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsManagementUser } from '../../../utils/filters/filterPresets';

import ModalManagementUser from './components/ModalManagementUser';
import UserManagementListCard from './components/UserManagementListCard';
import UserManagementListTable from './components/UserManagementListTable';
import roleOptions from './Helpers/roleOptions';

const UserManagementPage = () => {
  const request = useRef(0);

  const [action, setAction] = useState(1);

  const hasPermission = permissions.vendemmiaManagementUserManagement;
  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);

  const [isMobile, setIsMobile] = useState(false);

  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [fields, setFields] = useState({
    role: {
      slug: '',
      value: '',
      label: '',
    },
    status: {
      label: '',
      value: '',
    },
  });

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsManagementUser());
  };

  const handleClose = () => {
    onClose();
    triggerRefresh();
  };

  const handleOpen = () => {
    onOpen();
  };

  const triggerRefresh = () => {
    let aux = forceLoadTrigger;
    setForceLoadTrigger(aux + 1);
  };

  const load = (filters, key, page) => {
    setIsLoading(true);

    requests.listUsers(filters, page).then((data) => {
      setList(data.data);
      setMetadata(data.meta);
      setIsLoading(false);
    });
  };

  const exportListAction = (filters, key) => {
    const toastId = toast.info('Aguarde enquanto preparamos a exportação...', {
      autoClose: false, // Mantém o toast na tela até que seja fechado manualmente
      closeOnClick: false, // Impede que o usuário feche o toast ao clicar nele
      draggable: false, // Impede que o usuário arraste o toast
      closeButton: false, // Não exibe o botão de fechar o toast
    });

    requests
      .exportCsvUser(filters)
      .then((response) => {
        const fileType = response.headers['content-type'];
        const blob = new Blob([response.data], { fileType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'export.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
        toast.dismiss(toastId); // Remove o toast exibido anteriormente
        toast.success('Exportação efetuado com sucesso!', {
          autoClose: 1500, // Tempo em milissegundos que o toast ficará na tela
        });
      })
      .catch(() => {
        toast.dismiss(toastId); // Remove o toast exibido anteriormente
        toast.error('Problemas ao realizar exportação, tente novamente ou entre em contato com o Administrador');
      });
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  return (
    <Page
      screen="user"
      title="Gestão de usuários"
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      textFilterPlaceholder="Nome ou email"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading}
      showFilters={true}
      showPeriodFilter={false}
      allowEmptySearchPeriod={true}
      forceLoadTrigger={forceLoadTrigger}
      FAB={[
        {
          title: 'Cadastrar veículo',
          text: 'Cadastro de veículo',
          action: (
            <ModalManagementUser
              loadList={triggerRefresh}
              setFields={setFields}
              fields={fields}
              roleOptions={roleOptions}
              handleClose={handleClose}
              handleOpen={handleOpen}
              isOpen={isOpen}
              key={2}
            />
          ),
          modality: 'custom',
        },
        {
          title: 'Exportar usuários',
          text: 'Exportar arquivo CSV com dados de usuários',
          action: exportListAction,
          modality: 'export-csv',
        },
      ]}>
      <Card
        m="10px"
        header={
          <Flex gap="2px" direction="column">
            <Text textStyle="cardTitle" fontWeight="bold" color="primary">
              Listagem de usuários
            </Text>
            <Text textStyle="subtitle" color="#BEBEBE">
              Existe um total de {metadata?.total_count} usuários
            </Text>
          </Flex>
        }>
        {!isMobile ? (
          <Box p="15px">
            <ScreenLoader isLoading={isLoading}>
              <UserManagementListTable
                setFields={setFields}
                fields={fields}
                roleOptions={roleOptions}
                list={list}
                loadList={triggerRefresh}
                action={action}
                setAction={setAction}
                metadata={metadata}
                setMetadata={setMetadata}
                handleClose={handleClose}
                handleOpen={handleOpen}
                isOpen={isOpen}
              />
            </ScreenLoader>
          </Box>
        ) : (
          <Box p="15px">
            <ScreenLoader isLoading={isLoading}>
              <UserManagementListCard
                setFields={setFields}
                fields={fields}
                roleOptions={roleOptions}
                list={list}
                loadList={triggerRefresh}
                action={action}
                setAction={setAction}
                metadata={metadata}
                setMetadata={setMetadata}
                handleClose={handleClose}
                handleOpen={handleOpen}
                isOpen={isOpen}
              />
            </ScreenLoader>
          </Box>
        )}
      </Card>
    </Page>
  );
};

export default UserManagementPage;
