 import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import { Box, Flex, Grid, GridItem, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

import Card from '../../../../components/Card/Card';
import Tag from '../../../../components/Generic/Tag';
import Layout from '../../../../components/Layout';
import Menubar from '../../../../components/MenuBar/Menubar';
import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../../services/permissions';
import requests from '../../../../services/requests';
import { limitChars } from '../../../../utils/strings';

import ModalCancelTerm from './components/ModalCancelTerm';
import ModalEditTerm from './components/ModalEditTerm';
import ModalNewPolicy from './components/ModalNewTerm';
import ModalPublishTerm from './components/ModalPublishTerm';
import ModalViewTerm from './components/ModalViewTerm';

const TermUsePage = () => {
  let navigate = useNavigate();

  const resolution = window.innerWidth

  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);
  const [searchString, setSearchString] = useState('');

  const status = (value) => {
    switch (value) {
      case 'PUBLISHED':
        return 'green';
      case 'DEPRECATED':
        return 'gray';
      case 'CANCELLED':
        return 'red';
      default:
        return 'yellow';
    }
  };

  const textStatus = (value) => {
    switch (value) {
      case 'PUBLISHED':
        return 'Publicado';
      case 'DEPRECATED':
        return 'Obsoleto';
      case 'CANCELLED':
        return 'Cancelado';
      default:
        return 'Rascunho';
    }
  };

  const loadList = async () => {
    setIsLoading(true);
    try {
      const res = await requests.listTermUse({ search: searchString });
      setList(res.data);
    } catch (error) {
      console.error(error);

      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const addPolicyToList = () => {
    loadList();
  };

  const onChangeTextFilter = (value) => {
    if (value.length >= 3) {
      setSearchString(value);
    } else {
      setSearchString('');
    }
  };

  useEffect(() => {
    loadList();
  }, [searchString]);

  useEffect(() => {
    !permissions.vendemmiaManagementPolicy && navigate('/');
  }, []);

  return (
    <Layout>
      <Menubar
        screen="policy-terms"
        title="Termos de uso"
        showPeriodFilter={false}
        showFilters={false}
        onChangeTextFilter={onChangeTextFilter}
      />

      <Card
        m="10px"
        header={
          <Text textStyle="subtitle" fontWeight="bold" textColor="primary">
            Existe um total de {list.length} tópicos no momento
          </Text>
        }>
        <Box p="15px">
          <ScreenLoader isLoading={isLoading}>

            {resolution > 768 ? (
              <Table className="table-default min">
                <Thead border="1px" borderBottom="1px">
                  <Tr>
                    <Th>Conteúdo</Th>
                    <Th>Data de criação</Th>
                    <Th>Ultima atualização</Th>
                    <Th>Status</Th>
                    <Th>Ações</Th>
                  </Tr>
                </Thead>

                <Tbody border="1px">
                  {list?.map((entity) => (
                    <React.Fragment key={entity?.identifier}>
                      <Tr>
                        <Td w="60%">{limitChars(entity?.description, 100)}</Td>
                        <Td>{moment(entity?.createdAt).format('DD/MM/YYYY')}</Td>
                        <Td>{moment(entity?.updatedAt).format('DD/MM/YYYY')}</Td>
                        <Td>
                          <Tag type={status(entity?.status)}>{textStatus(entity?.status)}</Tag>
                        </Td>

                        <Td>
                          <Flex gap="10px" align="center" h="30px">
                            <ModalEditTerm entity={entity} onSave={loadList} />
                            <ModalPublishTerm entity={entity} onSave={loadList} />
                            <ModalCancelTerm entity={entity} onSave={loadList} />

                            {(entity?.status === 'PUBLISHED' || entity?.status === 'DEPRECATED') && <ModalViewTerm entity={entity} />}
                          </Flex>
                        </Td>
                      </Tr>
                    </React.Fragment>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <Flex
                flexDirection={'column'}
              >
                <Grid
                  p={'10px'}
                  fontWeight={'bold'}
                >
                  <GridItem>Conteúdo</GridItem>
                </Grid>
                <Accordion>
                  {list?.map((entity, key) => (
                    <AccordionItem 
                      key={key}
                      _odd={{ bg: "#eee" }}
                      borderBottom={'1px solid #E2E8F0'}
                      borderTop={0}
                      mb={'10px'}
                    >
                      <AccordionButton
                        display="flex"
                        flexDirection={"column"}
                        gap={'15px'}
                        p={'10px 10px 15px'}
                        _hover={{bg: 'transparent'}}
                      >
                        <Flex 
                          gap={'10px'}
                          textAlign={'left'}
                          color={'#707070'}
                        >
                          {limitChars(entity?.description, 100)}
                          <AccordionIcon />
                        </Flex>
                        
                        <Flex 
                          gap="10px" 
                          w={'full'}
                          align="center"
                        >
                          <Tag type={status(entity?.status)}>{textStatus(entity?.status)}</Tag>
                          <Flex 
                            gap={'10px'}
                          >
                            <ModalEditTerm entity={entity} onSave={loadList} />
                            <ModalPublishTerm entity={entity} onSave={loadList} />
                            <ModalCancelTerm entity={entity} onSave={loadList} />

                            {(entity?.status === 'PUBLISHED' || entity?.status === 'DEPRECATED') && <ModalViewTerm entity={entity} />}
                          </Flex>
                        </Flex>
                      </AccordionButton>

                      <AccordionPanel
                        display={'grid'}
                        gridTemplateColumns={'1fr 1fr'}
                        bg={'white'}
                        p={'8px 16px 15px'}
                      >
                        <Text fontSize={'14px'}><strong>Data de criação: </strong>{moment(entity?.createdAt).format('DD/MM/YYYY')}</Text>
                        <Text fontSize={'14px'}><strong>Ultima atualização: </strong>{moment(entity?.updatedAt).format('DD/MM/YYYY')}</Text>
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </Flex>
            )}
            
          </ScreenLoader>
        </Box>

        {isLoading ? (
          <Flex justify="center" my="25px">
            <span>Carregando...</span>
          </Flex>
        ) : (
          <>
            {list.length === 0 && (
              <Flex justify="center" my="25px">
                Nenhum dado encontrado para os parâmetros filtrados.
              </Flex>
            )}
          </>
        )}

        <Flex className="float-button-modal">
          <ModalNewPolicy addPolicyToList={addPolicyToList} />
        </Flex>
      </Card>
    </Layout>
  );
};

export default TermUsePage;
