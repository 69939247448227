import React, { useState } from 'react';

import { ImLocation } from 'react-icons/im';
import { MdArticle, MdHelp } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Button, Flex, Icon, Image, Td, Text, Tooltip, Tr } from '@chakra-ui/react';

import iconDocument from '../../../../assets/images/document.svg';
import { colors } from '../../../../styles/colors';
import { limitChars } from '../../../../utils/strings';
import { convertColorTrip, descriptionStatusTrip, modalityIcon, modalityTitle } from '../../../../utils/translate';

import DocumentModalView from './Documents/DocumentModalView';

export default function ListCollapse({ entity, entityMarker = null, zoomIntoTrip, setCurrentTripData = null }) {
  const [currentIdentifier, setCurrentIdentifier] = useState(null);
  const [isOpenModalViewFile, setIsOpenModalViewFile] = useState(false);
  const [currentCode, setCurrentCode] = useState(null);

  const getColor = (modality) => {
    if (modality == 'FRETE_NAO_RASTREAVEL') {
      return '#f1c40f';
    } else if (modality == 'FRETE_RASTREAVEL') {
      return '#bababa';
    }

    return '#a885f9';
  };

  const handleOpen = (identifier, code) => {
    setCurrentIdentifier(identifier);
    setCurrentCode(code);
    setIsOpenModalViewFile(!isOpenModalViewFile);
  };

  const highlightLine = (identifier) => {
    const lineList = document.querySelectorAll('tr');

    //limpar dataset
    lineList.forEach((line) => {
      line.removeAttribute('data-mark');

      const id = line.getAttribute('id');
      id === identifier && (line.dataset.mark = true);
    });
  };

  const scrollToMap = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Faz a animação de scroll suave
    });
  };

  const tooltipStart = (
    <>
      <Text>Início Previsto: {entity?.startsAtFormatted}</Text>
      {entity?.advanceTripStart != null && <Text>Adiantou: {entity?.advanceTripStart} </Text>}
      {entity?.delayTripStart != null && <Text>Atrasou: {entity?.delayTripStart} </Text>}
    </>
  );

  const tooltipEnd = (
    <>
      <Text>Conclusão Prevista: {entity?.endsAtFormatted}</Text>
      {entity?.advanceTripEnd != null && <Text>Adiantou: {entity?.advanceTripEnd} </Text>}
      {entity?.delayTripEnd != null && <Text>Atrasou: {entity?.delayTripEnd} </Text>}
    </>
  );

  return (
    <>
      <Tr id={entity.identifier} minH="50px" textColor="#707070">
        <Td w="80px" border="none">
          <Tooltip label={modalityTitle(entity?.modality)} aria-label={modalityTitle(entity?.modality)}>
            <Box cursor="pointer" align="center">
              <Icon as={modalityIcon(entity?.modality)} color={getColor(entity?.modality)} w="1.1rem" h="1.1rem" />
            </Box>
          </Tooltip>
        </Td>
        <Td>
          <Link to={`/logistic/trip/detail/${entity.identifier}`} target="_blank" title="Visualizar viagem">
            <Flex
              align="center"
              gap="10px"
              py="5px"
              px="20px"
              borderRadius="15px"
              bgColor={convertColorTrip(entity.timeline.currentStatus)}
              color="white"
              h="29px"
              cursor="pointer">
              {descriptionStatusTrip(entity.timeline.currentStatus)}
            </Flex>
          </Link>
        </Td>
        <Td textAlign="center" w="100px" cursor="pointer" style={{ color: '#6C48C2' }} fontWeight="500">
          <Link
            to={`/logistic/trip/detail/${entity.identifier}`}
            target="_blank"
            title="Visualizar viagem"
            style={{
              textDecoration: 'none',
              color: '#6C48C2',
            }}
            onMouseOver={(e) => (e.target.style.textDecoration = 'underline')}
            onMouseOut={(e) => (e.target.style.textDecoration = 'none')}>
            #{entity?.code}
          </Link>
        </Td>
        <Td cursor="pointer" border="none" textStyle="subtitle" textColor="#0F0A1DB3">
          <Box>
            <Flex>
              {entity?.startsEtdFormatted != null ? (
                <>
                  {entity?.startsEtdFormatted}
                  <Tooltip label={tooltipStart}>
                    <Box ml="10px">
                      <MdHelp color="#422C76" />
                    </Box>
                  </Tooltip>
                </>
              ) : (
                entity?.startsAtFormatted
              )}
            </Flex>
          </Box>
        </Td>
        <Td cursor="pointer" border="none" textStyle="subtitle" textColor="#0F0A1DB3">
          <Box>
            <Flex>
              {entity?.endsEtdFormatted != null ? (
                <>
                  {entity?.endsEtdFormatted}
                  <Tooltip label={tooltipEnd}>
                    <Box ml="10px">
                      <MdHelp color="#422C76" />
                    </Box>
                  </Tooltip>
                </>
              ) : (
                entity?.endsAtFormatted
              )}
            </Flex>
          </Box>
        </Td>
        <Td maxW="150px">{entity?.cteNumber}</Td>
        <Td>
          <Tooltip label={entity?.userDriverName ?? 'Não identificado'}>
            {entity?.userDriverName ? limitChars(entity?.userDriverName, 20) : 'Não identificado'}
          </Tooltip>
        </Td>
        <Td>{entity?.platesTrackable ? entity?.platesTrackable : '-'}</Td>
        <Td>
          <Tooltip label={entity?.clientsFormatted?.join(',')}>{limitChars(entity?.clientsFormatted?.join(','), 40)}</Tooltip>
        </Td>
        <Td cursor={entity.files?.length > 0 ? 'pointer' : 'text'} border="none" textStyle="subtitle" bgColor={'transparent'}>
          <Box>
            {entity.qttFiles > 0 ? (
              <Tooltip label="Ver arquivos">
                <Button
                  variant="secundary"
                  color="#8067DC"
                  h="32px"
                  fontSize="14px"
                  onClick={() => handleOpen(entity?.identifier, entity?.code)}
                  leftIcon={<MdArticle />}>
                  <Text>Ver arquivos</Text>
                </Button>
              </Tooltip>
            ) : (
              <small>Sem arquivos</small>
            )}
          </Box>
        </Td>
        <Td w="100px">
          <Flex direction="row" gap="20px" alignItems="flex-end">
            <Link
              to={`/logistic/trip/detail/${entity.identifier}`}
              target="_blank"
              style={{ minWidth: '21px' }}
              title="Visualizar viagem">
              <Image
                w="21px"
                src={iconDocument}
                alt="Detalhes"
                filter="saturate(1)"
                cursor="pointer"
                transition="0.2s"
                _hover={{
                  filter: 'saturate(0.5)',
                }}
              />
            </Link>
            <ImLocation
              title="Última localização"
              color={entityMarker && entityMarker.color ? entityMarker.color : '#ea4335'}
              size={21}
              cursor="pointer"
              onClick={() => {
                if (typeof setCurrentTripData === 'function') {
                  setCurrentTripData(entity);
                  highlightLine(entity.identifier);
                  scrollToMap();
                }

                zoomIntoTrip(entity);
              }}
            />
          </Flex>
        </Td>
      </Tr>
      <style>
        {`
          [data-mark="true"]{
            background-color: ${colors['purple']}
          }
        `}
      </style>
      <DocumentModalView isOpen={isOpenModalViewFile} identifier={currentIdentifier} code={currentCode} onClose={handleOpen} />
    </>
  );
}
