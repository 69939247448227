import React from 'react';

import ReactInputMask from 'react-input-mask';

const InputDefaultMask = ({ as, hidden = false, mask = null, name, placeholder, setFieldValue, type = 'text', value, isDisabled,...props }) => {
  return (
    <ReactInputMask
      autoComplete="off"
      bg="#F2F2F2"
      variant="primary"
      className="chakra-input input-register custom-input-mask"
      as={as}
      id={name}
      name={name}
      type={type ? type : 'text'}
      mask={mask}
      value={value}
      hidden={hidden}
      placeholder={placeholder}
      disabled={isDisabled}
      onChange={(event) => setFieldValue(name, event.target.value)}
      {...props}
    />
  );
};

export default InputDefaultMask;
