import React, { useState } from 'react';

import { MdCheck, MdDelete, MdEdit } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Button, Flex, Table, Tbody, Td, Thead, Tr } from '@chakra-ui/react';

import requests from '../../../../services/requests';

const ListSavedFilters = ({
  selectionList,
  setFilterApplied,
  refreshApliedFields,
  onClose,
  loadSelectionList,
  setIsEdit,
  setTitleSelection,
  setSelectedUser,
  setFilterFields,
  setSelectionList,
  setFilterEditing,
  selectTabIndex,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const applySelectionList = (data) => {
    setIsLoading(true);

    let auxF = JSON.parse(data.filters);

    setFilterApplied(auxF);
    refreshApliedFields(auxF.fields);
    setIsLoading(false);

    onClose();
    toast.success('Seleção adicionada com sucesso!');
  };

  const deleteSelectionList = (data) => {
    setIsLoading(true);

    requests.deleteSelection(data?.identifier).then(() => {
      toast.success('Seleção removida com sucesso!');

      loadSelectionList();
      setIsLoading(false);
    });
  };

  const editSelectionList = (data) => {
    setTitleSelection(data?.name);
    setSelectedUser({
      label: data?.user.name,
      value: data?.user.identifier,
    });

    let auxF = JSON.parse(data.filters);
    setFilterFields(auxF.fields);
    delete auxF.fields;

    setFilterEditing(data.identifier);
    setIsEdit(true);
    // setSelectionList(auxF);
    selectTabIndex(0);
  };

  return (
    <Table className="table-default" w="100%">
      <Thead>
        <Tr>
          <Td>Nome</Td>
          <Td>Alterado em</Td>
          <Td>Aplicar</Td>
          <Td>Editar</Td>
          <Td>Excluir</Td>
        </Tr>
      </Thead>

      <Tbody>
        {selectionList?.length > 0 ? (
          selectionList?.map((data, key) => (
            <Tr key={key}>
              <Td>{data?.name}</Td>

              <Td>{data?.createdAtFormatted}</Td>

              <Td>
                <Button
                  onClick={() => {
                    applySelectionList(data);
                  }}>
                  <MdCheck color="#2ECC71" />
                </Button>
              </Td>

              <Td>
                <Button
                  onClick={() => {
                    editSelectionList(data);
                  }}>
                  <MdEdit color="#422C7694" />
                </Button>
              </Td>

              <Td>
                <Button
                  isLoading={isLoading}
                  onClick={() => {
                    deleteSelectionList(data);
                  }}>
                  <MdDelete color="#E52359" />
                </Button>
              </Td>
            </Tr>
          ))
        ) : (
          <Tr>
            <Td colSpan={5}>
              <Flex justify="center" my="25px">
                Nenhum filtro salvo
              </Flex>
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};

export default ListSavedFilters;
