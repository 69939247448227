import React from 'react';

import { Flex } from '@chakra-ui/react';

import OrderProgressBar from '../../../pages/metrics/MetricsWarehouse/components/OrderProgressBar';

const OrderProgressChart = ({ data }) => {
  const aguardandoLiberacao = parseInt(data.aguardandoLiberacao, 10);
  const aguardandoFaturamento = parseInt(data.aguardandoFaturamento, 10);
  const aguardandoColeta = parseInt(data.aguardandoColeta, 10);
  const emSeparacao = parseInt(data.emSeparacao, 10);
  const emConferencia = parseInt(data.emConferencia, 10);
  const cancelado = parseInt(data.cancelado, 10);

  const totalValue = cancelado + aguardandoLiberacao + aguardandoFaturamento + aguardandoColeta + emSeparacao + emConferencia;

  return (
    <Flex direction="column" px="10px" h="full" justify="center">
      <Flex direction="column" w="100%" gap="5px">
        <OrderProgressBar label="Aguardando a liberação" value={data.aguardandoLiberacao} max={totalValue} color="blue" />
        <OrderProgressBar label="Aguardando faturamento" value={data.aguardandoFaturamento} max={totalValue} color="yellow" />
        <OrderProgressBar label="Aguardando coleta" value={data.aguardandoColeta} max={totalValue} color="lightRed" />
        <OrderProgressBar label="Em separação" value={data.emSeparacao} max={totalValue} color="green" />
        <OrderProgressBar label="Em conferência" value={data.emConferencia} max={totalValue} color="lightGreen" />
        <OrderProgressBar label="Cancelado" value={data.cancelado} max={totalValue} color="red" />
      </Flex>
    </Flex>
  );
};

export default OrderProgressChart;
