import React, { useEffect, useState } from 'react';

import { ErrorMessage } from 'formik';
import { MdAdd, MdDelete } from 'react-icons/md';

import { Button, Flex, FormControl, FormLabel, Grid, TabPanel } from '@chakra-ui/react';

import InputDefault from '../../../../components/Form/Input/InputDefault';
import InputDefaultMask from '../../../../components/Form/Input/InputDefaultMask';
import { LabelDefault } from '../../../../components/Form/Label/LabelDefault';
import { handleAddClick, handleInputChange, handleRemoveChange } from '../Helpers/inputMultiple';

const ContactDataForm = ({ values, setFieldValue, isPreviwer }) => {
  const [inputCommercial, setInputCommercial] = useState([{ name: '', email: '', phone: '' }]);
  const [inputOperational, setInputOperational] = useState([{ name: '', email: '', phone: '' }]);
  const [inputFinancial, setInputFinancial] = useState([{ name: '', email: '', phone: '' }]);
  const [inputLegalRepresentative, setInputLegalRepresentative] = useState([{ name: '', email: '', phone: '' }]);

  useEffect(() => {
    if (values.contact.commercial.length > 0) {
      setInputCommercial(values.contact.commercial);
    }
    if (values.contact.operation.length > 0) {
      setInputOperational(values.contact.operation);
    }
    if (values.contact.financial.length > 0) {
      setInputFinancial(values.contact.financial);
    }
    if (values.contact.legal.length > 0) {
      setInputLegalRepresentative(values.contact.legal);
    }
  }, []);

  return (
    <TabPanel p="30px">
      <Grid templateColumns="repeat(2, 1fr)" gap={10}>
        <Flex direction="column" gap="20px">
          <Flex direction="column" gap="10px">
            <FormLabel>Contatos comerciais</FormLabel>

            {inputCommercial.map((_, index) => (
              <Flex gap="10px" align="flex-end" key={index}>
                <FormControl gap="10px" display="flex">
                  <FormControl>
                    <LabelDefault text="Nome" />
                    <InputDefault
                      isDisabled={isPreviwer}
                      placeholder="Nome"
                      name={`contact.commercial[${index}].commercialName`}
                      id={`commercialName${index}`}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          setFieldValue,
                          values.contact,
                          e.target.value,
                          'commercial',
                          'commercialName',
                          'contact'
                        )
                      }
                      value={values.contact.commercial[index]?.commercialName}
                    />
                  </FormControl>

                  <FormControl>
                    <LabelDefault text="Email" />
                    <InputDefault
                      isDisabled={isPreviwer}
                      placeholder="email@gmail.com"
                      name={`contact.commercial[${index}].commercialEmail`}
                      id={`commercialEmail${index}`}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          setFieldValue,
                          values.contact,
                          e.target.value,
                          'commercial',
                          'commercialEmail',
                          'contact'
                        )
                      }
                      value={values.contact.commercial[index]?.commercialEmail}
                    />
                  </FormControl>

                  <FormControl>
                    <LabelDefault text="Telefone" />
                    <InputDefaultMask
                      isDisabled={isPreviwer}
                      placeholder="(99) 99999-9999"
                      mask="(99) 99999-9999"
                      name={`contact.commercial[${index}].commercialPhone`}
                      id={`commercialPhone${index}`}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          setFieldValue,
                          values.contact,
                          e.target.value,
                          'commercial',
                          'commercialPhone',
                          'contact'
                        )
                      }
                      value={values.contact.commercial[index]?.commercialPhone}
                    />
                  </FormControl>
                </FormControl>

                {inputCommercial.length > 1 && (
                  <Button
                    onClick={() =>
                      handleRemoveChange(
                        setFieldValue,
                        index,
                        values.contact.commercial,
                        'commercial',
                        inputCommercial,
                        setInputCommercial,
                        'contact'
                      )
                    }>
                    <MdDelete color="#E52359" />
                  </Button>
                )}
              </Flex>
            ))}
            <ErrorMessage component="p" className="error-message-error" name={`contact.commercial`} />

            <Button
              w="auto"
              isDisabled={isPreviwer}
              h="40px"
              fontSize="16px"
              borderRadius="5px"
              textColor="#2ECC71"
              bgColor="#2ECC7152"
              border="1px dashed #2ECC71"
              _hover={{ bgColor: 'transparent', shadow: 'lg' }}
              leftIcon={<MdAdd color="#2ECC71" size={15} />}
              onClick={() => handleAddClick(inputCommercial, setInputCommercial)}>
              Adicionar contatos comerciais
            </Button>
          </Flex>

          <Flex direction="column" gap="10px">
            <FormLabel>Contatos operacionais</FormLabel>
            {inputOperational.map((_, index) => (
              <Flex gap="10px" align="flex-end" key={index}>
                <FormControl gap="10px" display="flex">
                  <FormControl>
                    <LabelDefault text="Nome" />
                    <InputDefault
                      isDisabled={isPreviwer}
                      placeholder="Nome"
                      name={`contact.operation[${index}].operationName`}
                      id={`operationName${index}`}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          setFieldValue,
                          values.contact,
                          e.target.value,
                          'operation',
                          'operationName',
                          'contact'
                        )
                      }
                      value={values.contact.operation[index]?.operationName}
                    />
                  </FormControl>

                  <FormControl>
                    <LabelDefault text="Email" />
                    <InputDefault
                      isDisabled={isPreviwer}
                      placeholder="email@gmail.com"
                      name={`contact.operation[${index}].operationEmail`}
                      id={`operationEmail${index}`}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          setFieldValue,
                          values.contact,
                          e.target.value,
                          'operation',
                          'operationEmail',
                          'contact'
                        )
                      }
                      value={values.contact.operation[index]?.operationEmail}
                    />
                  </FormControl>

                  <FormControl>
                    <LabelDefault text="Telefone" />
                    <InputDefaultMask
                      isDisabled={isPreviwer}
                      placeholder="(99) 99999-9999"
                      mask="(99) 99999-9999"
                      name={`contact.operation[${index}].operationPhone`}
                      id={`operationPhone${index}`}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          setFieldValue,
                          values.contact,
                          e.target.value,
                          'operation',
                          'operationPhone',
                          'contact'
                        )
                      }
                      value={values.contact.operation[index]?.operationPhone}
                    />
                  </FormControl>
                </FormControl>

                {inputOperational.length > 1 && (
                  <Button
                    onClick={() =>
                      handleRemoveChange(
                        setFieldValue,
                        index,
                        values.contact.operation,
                        'operation',
                        inputOperational,
                        setInputOperational,
                        'contact'
                      )
                    }>
                    <MdDelete color="#E52359" />
                  </Button>
                )}
              </Flex>
            ))}

            <ErrorMessage component="p" className="error-message-error" name={`contact.operation`} />

            <Button
              w="auto"
              isDisabled={isPreviwer}
              h="40px"
              fontSize="16px"
              borderRadius="5px"
              textColor="#2ECC71"
              bgColor="#2ECC7152"
              border="1px dashed #2ECC71"
              _hover={{ bgColor: 'transparent', shadow: 'lg' }}
              leftIcon={<MdAdd color="#2ECC71" size={15} />}
              onClick={() => handleAddClick(inputOperational, setInputOperational)}>
              Adicionar contatos operacionais
            </Button>
          </Flex>
        </Flex>

        <Flex direction="column" gap="20px">
          <Flex direction="column" gap="10px">
            <FormLabel>Contatos financeiros</FormLabel>

            {inputFinancial.map((_, index) => (
              <Flex gap="10px" align="flex-end" key={index}>
                <FormControl gap="10px" display="flex">
                  <FormControl>
                    <LabelDefault text="Nome" />
                    <InputDefault
                      isDisabled={isPreviwer}
                      placeholder="Nome"
                      name={`contact.financial[${index}].financialName`}
                      id={`financialName${index}`}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          setFieldValue,
                          values.contact,
                          e.target.value,
                          'financial',
                          'financialName',
                          'contact'
                        )
                      }
                      value={values.contact.financial[index]?.financialName}
                    />
                  </FormControl>

                  <FormControl>
                    <LabelDefault text="Email" />
                    <InputDefault
                      isDisabled={isPreviwer}
                      placeholder="email@gmail.com"
                      name={`contact.financial[${index}].financialEmail`}
                      id={`financialEmail${index}`}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          setFieldValue,
                          values.contact,
                          e.target.value,
                          'financial',
                          'financialEmail',
                          'contact'
                        )
                      }
                      value={values.contact.financial[index]?.financialEmail}
                    />
                  </FormControl>

                  <FormControl>
                    <LabelDefault text="Telefone" />
                    <InputDefaultMask
                      isDisabled={isPreviwer}
                      placeholder="(99) 99999-9999"
                      mask="(99) 99999-9999"
                      name={`contact.financial[${index}].financialPhone`}
                      id={`financialPhone${index}`}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          setFieldValue,
                          values.contact,
                          e.target.value,
                          'financial',
                          'financialPhone',
                          'contact'
                        )
                      }
                      value={values.contact.financial[index]?.financialPhone}
                    />
                  </FormControl>
                </FormControl>

                {inputFinancial.length > 1 && (
                  <Button
                    onClick={() =>
                      handleRemoveChange(
                        setFieldValue,
                        index,
                        values.contact.financial,
                        'financial',
                        inputFinancial,
                        setInputFinancial,
                        'contact'
                      )
                    }>
                    <MdDelete color="#E52359" />
                  </Button>
                )}
              </Flex>
            ))}
            <ErrorMessage component="p" className="error-message-error" name={`contact.financial`} />

            <Button
              w="auto"
              isDisabled={isPreviwer}
              h="40px"
              fontSize="16px"
              borderRadius="5px"
              textColor="#2ECC71"
              bgColor="#2ECC7152"
              border="1px dashed #2ECC71"
              _hover={{ bgColor: 'transparent', shadow: 'lg' }}
              leftIcon={<MdAdd color="#2ECC71" size={15} />}
              onClick={() => handleAddClick(inputFinancial, setInputFinancial)}>
              Adicionar contatos financeiros
            </Button>
          </Flex>

          <Flex direction="column" gap="10px">
            <FormLabel>Representantes legais</FormLabel>

            {inputLegalRepresentative.map((_, index) => (
              <Flex gap="10px" align="flex-end" key={index}>
                <FormControl gap="10px" display="flex">
                  <FormControl>
                    <LabelDefault text="Nome" />
                    <InputDefault
                      isDisabled={isPreviwer}
                      placeholder="Nome"
                      name={`contact.legal[${index}].legalName`}
                      id={`legalName${index}`}
                      onChange={(e) =>
                        handleInputChange(index, setFieldValue, values.contact, e.target.value, 'legal', 'legalName', 'contact')
                      }
                      value={values.contact.legal[index]?.legalName}
                    />
                  </FormControl>

                  <FormControl>
                    <LabelDefault text="Email" />
                    <InputDefault
                      isDisabled={isPreviwer}
                      placeholder="email@gmail.com"
                      name={`contact.legal[${index}].legalEmail`}
                      id={`legalEmail${index}`}
                      onChange={(e) =>
                        handleInputChange(index, setFieldValue, values.contact, e.target.value, 'legal', 'legalEmail', 'contact')
                      }
                      value={values.contact.legal[index]?.legalEmail}
                    />
                  </FormControl>

                  <FormControl>
                    <LabelDefault text="Telefone" />
                    <InputDefaultMask
                      isDisabled={isPreviwer}
                      placeholder="(99) 99999-9999"
                      mask="(99) 99999-9999"
                      name={`contact.legal[${index}].legalPhone`}
                      id={`legalPhone${index}`}
                      onChange={(e) =>
                        handleInputChange(index, setFieldValue, values.contact, e.target.value, 'legal', 'legalPhone', 'contact')
                      }
                      value={values.contact.legal[index]?.legalPhone}
                    />
                  </FormControl>
                </FormControl>

                {inputLegalRepresentative.length > 1 && (
                  <Button
                    onClick={() =>
                      handleRemoveChange(
                        setFieldValue,
                        index,
                        values.contact.legal,
                        'legal',
                        inputLegalRepresentative,
                        setInputLegalRepresentative,
                        'contact'
                      )
                    }>
                    <MdDelete color="#E52359" />
                  </Button>
                )}
              </Flex>
            ))}

            <ErrorMessage component="p" className="error-message-error" name={`contact.legal`} />

            <Button
              w="auto"
              isDisabled={isPreviwer}
              h="40px"
              fontSize="16px"
              borderRadius="5px"
              textColor="#2ECC71"
              bgColor="#2ECC7152"
              border="1px dashed #2ECC71"
              _hover={{ bgColor: 'transparent', shadow: 'lg' }}
              leftIcon={<MdAdd color="#2ECC71" size={15} />}
              onClick={() => handleAddClick(inputLegalRepresentative, setInputLegalRepresentative)}>
              Adicionar representantes legais
            </Button>
          </Flex>
        </Flex>
      </Grid>
    </TabPanel>
  );
};

export default ContactDataForm;
