import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import {
  Accordion,
  AccordionItem,
} from '@chakra-ui/react'
import { Flex, Text } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import HelpTooltip from '../../../components/HelpTooltip/HelpTooltip';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../services/requests';
import ProcessListItem from '../../comex/process/components/ProcessListItem';

const PriorityProcess = ( { shouldRefresh, setIsRefreshing } ) => {
  let [isLoading, setIsLoading] = useState(true);

  const resolution = window.innerWidth
  const [list, setList] = useState([]);

  const load = () => {
    loadList();
  };

  const loadList = async () => {
    setIsLoading(true);
    await requests.listProcessPriority().then((data) => {
      window.localStorage.setItem(
        'profile-process-priority',
        JSON.stringify(data.map((process) => process.identifier))
      );
      setList(data);
    });
    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (shouldRefresh) {
      load();
    }
  }, [shouldRefresh]);

  useEffect(() => {
    setIsRefreshing(isLoading);
  }, [isLoading]);

  return (
    <Card
      header={
        <>
          <Text textStyle="tableTitle" fontSize={{ base: '18px', md: '20px'}} color="primary" fontWeight="bold" display={{ base: 'none', md: 'block'}}>
            Processos marcados como favoritos
            <HelpTooltip tooltip="Os filtros do topo não afetam essa seção" />
          </Text>
          <Text textStyle="tableTitle" fontSize={{ base: '18px', md: '20px'}} color="primary" fontWeight="bold" display={{ base: 'block', md: 'none'}}>
            Processos marcados como favoritos
          </Text>
          <Link to="/comex/process">
            <Text color="primary">Ver todos os processos {'>'}</Text>
          </Link>
        </>
      }>
      <ScreenLoader isLoading={isLoading}>
        {resolution > 768 ? (
          list.map((process, index) => (
            <ProcessListItem key={process.identifier} itemCount={index} process={process} />
          ))
        ) : (
          <Accordion>
            {list.map((process, index) => (
              <AccordionItem key={process.identifier}>
                <ProcessListItem itemCount={index} process={process} />
              </AccordionItem>
            ))}
          </Accordion>
        )}
        {list.length === 0 && (
          <Flex direction="column" align="center" my="20px">
            Nenhum processo prioritário encontrado.
            <Text textStyle="span">Marque algum processo como favorito para vê-lo aqui.</Text>
          </Flex>
        )}
      </ScreenLoader>
    </Card>
  );
};

export default PriorityProcess;
