import React from 'react';

import { MdOutlineHistory } from 'react-icons/md';

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

import Paginate from '../../../../components/Paginate/Paginate';

const LogHistoryDrawer = ({ entity, meta, setMeta, action, setAction }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const floatActionButton = document.querySelector('.float-action-button button');

  const closeFloatActionButton = () => {
    floatActionButton && floatActionButton.click();
  };

  const flagClick = (e) => {
    e.stopPropagation(); // isso impede a propagação do evento que vai para a página de detalhes do processo
    onOpen();
    closeFloatActionButton();
  };

  return (
    <>
      <Button
        rightIcon={<MdOutlineHistory color="#FFFFFF" size={20} />}
        h="46px"
        px="24px"
        w="fit-content"
        py="14px"
        bgColor="green"
        color="#FFFFFF"
        borderRadius="27px"
        _hover={{ bgColor: '#70D499' }}
        onClick={flagClick.bind(this)}>
        <Text>Ver histórico da cotação</Text>
      </Button>

      <Drawer isOpen={isOpen} onClose={onClose} placement="left">
        <DrawerOverlay />

        <DrawerContent maxW={'100% !important'} w={{ base: 'initial', md: '50% !important' }} pt="30px">
          <DrawerCloseButton />

          <Text px="30px" textStyle="secondaryTitle" textColor="primary" fontWeight="bold" my="10px">
            Histórico
          </Text>

          <DrawerBody px="30px">
            <Table className="table-default" w="full" p="0">
              <Thead>
                <Tr>
                  <Td>Ação</Td>
                  <Td>Data e hora</Td>
                  <Td>Usuário</Td>
                </Tr>
              </Thead>

              <Tbody>
                {entity.length > 0 ? (
                  <>
                    {entity.map((value, key) => (
                      <Tr key={key}>
                        <Td>{value.description}</Td>
                        <Td w="180px">{value.createdAtFormatted}</Td>
                        <Td w="150px">{value.userName}</Td>
                      </Tr>
                    ))}
                  </>
                ) : (
                  <Tr>
                    <Td colSpan={3}>
                      <Flex justify="center" my="25px">
                        Nenhum dado alterado.
                      </Flex>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </DrawerBody>

          <Flex borderTop="1px" borderColor="#7070702E" p="0" w="full">
            {meta.total_pages > 1 && (
              <Paginate w="full" metadata={meta} setMetadata={setMeta} action={action} setAction={setAction} showDetails={true} />
            )}
          </Flex>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default LogHistoryDrawer;
