import React, { useEffect, useRef, useState } from 'react';

import { Flex, useDisclosure } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import Map from '../../../components/Map/Map';
import Page from '../../../components/Page';
import Paginate from '../../../components/Paginate/Paginate';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { timeline } from '../../../utils/exports';
import { filterOptionsCurrentTrips } from '../../../utils/filters/filterPresets';
import { checkVisitedPoints, generateTrajectoryChunks, reduceCheckpointListByDistance } from '../../../utils/geolocation';
import generateIconEvent from '../../../utils/maps/generateIconEvent';
import { tripColors, tripLabelsString } from '../../../utils/translate';

import TableTimeLine from './components/TableTimeLine';

const CurrentTripsPage = () => {
  const hasPermission = permissions.logisticRealtime;

  const [isMobile, setIsMobile] = useState(false);

  const request = useRef(0);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMapData, setIsLoadingMapData] = useState(false);

  const [list, setList] = useState([]);
  const [tripList, setTripList] = useState([]);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const load = (filters, key, page) => {
    setIsLoading(true);

    requests
      .listRealtime(filters, page)
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }
        request.current = key;

        setList(data.data);
        setMetadata(data.meta);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [filterOptions, setFilterOptions] = useState([]);
  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsCurrentTrips());
  };
  useEffect(() => {
    getFilterOptions();
  }, []);

  const [selectedTrip, setSelectedTrip] = useState(null); // for upload
  const [currentTripData, setCurrentTripData] = useState(null); // for map handling
  const [currentTripIsTrackable, setCurrentTripTrackable] = useState(false);
  useEffect(() => {
    if (currentTripData) {
      if (typeof currentTripData.isTrackable === 'undefined' && tripList) {
        for (let i = 0; i < tripList.length; i++) {
          if (tripList[i].identifier === currentTripData.identifier) {
            setCurrentTripTrackable(tripList[i].isTrackable);
            break;
          }
        }
      } else {
        setCurrentTripTrackable(currentTripData.isTrackable);
      }
    } else {
      setCurrentTripTrackable(false);
    }
  }, [currentTripData]);

  const defaultZoom = 2;
  const defaultCenter = {
    lat: 23.73339640721276,
    lng: 2.9533781737686615,
  };
  const [mapBounds, setMapBounds] = useState(null);
  const [currentZoom, setCurrentZoom] = useState(defaultZoom);
  const [center, setCenter] = useState({
    lat: defaultCenter.lat,
    lng: defaultCenter.lng,
  });

  const zoomIntoTrip = (entity) => {
    if (entity && typeof entity.checkpoints === 'undefined') {
      for (let i = 0; i < tripList.length; i++) {
        if (tripList[i].identifier === entity.identifier) {
          entity = tripList[i];
          break;
        }
      }
    }
    setCurrentTripData(entity);

    if (!entity || !entity.lastTrajectoryByDistance) {
      return;
    }

    setCenter({
      lat: entity.lastTrajectoryByDistance.latitude,
      lng: entity.lastTrajectoryByDistance.longitude,
    });
    setCurrentZoom(18);
  };

  const loadDirections = async (origin, destination, waypoints = []) => {
    if (typeof google === 'undefined' || !google) {
      return null;
    }

    if (!origin || !destination) {
      return;
    }

    var trajectory = null;

    try {
      await new google.maps.DirectionsService().route(
        {
          origin: new google.maps.LatLng(origin.latitude, origin.longitude),
          destination: new google.maps.LatLng(destination.latitude, destination.longitude),
          waypoints: waypoints?.slice(0, Math.min(waypoints.length, 21))?.map((point) => ({
            location: new google.maps.LatLng(point.latitude, point.longitude),
            stopover: true,
          })),
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            trajectory = result;
          }
        }
      );
    } catch (e) {
    }

    return trajectory;
  };
  const generateTrajectoryPath = async (checkedTrajectories, checkpoints) => {
    let chunks = generateTrajectoryChunks(checkedTrajectories.visited, checkpoints);

    let result = [];
    for (let i = 0; i < chunks.length; i++) {
      result.push({
        directions: await loadDirections(chunks[i].origin, chunks[i].destination, chunks[i].waypoints),
        status: 'COMPLETED',
      });
    }

    if (checkedTrajectories.notVisited.length > 0) {
      let origin = checkedTrajectories.visited[checkedTrajectories.visited.length - 1];
      if (checkpoints.length > 0) {
        origin = checkpoints[checkpoints.length - 1];
      }
      let destination = checkedTrajectories.notVisited.pop(); // Pop, para remover do array
      result.push({
        directions: await loadDirections(origin, destination, checkedTrajectories.notVisited),
        status: 'PENDING',
      });
    }

    return result;
  };
  const loadPoints = async () => {
    setIsLoadingMapData(true);

    let count = 0;

    let trips = [];
    for (let i in list) {
      let trip = list[i];
      let checkpoints = [];
      let checkpointsMarkers = [];
      let trajectoryMarkers = [];
      let trajectoryPath = [];
      let isTrackable = false;
      let status = trip.status ?? trip.timeline.currentStatus;

      if (trip.modality === 'APP_MOTORISTA' || trip.modality === 'FRETE_RASTREAVEL') {
        checkpoints = await requests.showCheckpointsDriverFreight(trip.identifier);
        isTrackable = true;
      }

      let checkpointsReduced = [];
      let visitedTrajectory = {
        visited: trip.trajectories,
        notVisited: [],
      };
      if (checkpoints.length > 0) {
        for (let k in checkpoints) {
          let item = checkpoints[k];

          checkpointsMarkers.push({
            tripIdentifier: trip.identifier,
            isTrackable: isTrackable,
            lat: item.latitude,
            lng: item.longitude,
            color: tripColors[count],
            content: {
              code: trip.code,
              cte: trip?.cteNumber,
              plate: trip?.platesTrackable,
              date: item.dateFormatted,
              driver: item.userDriverName,
            },
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              scale: 5,
              fillColor: tripColors[count],
              fillOpacity: 0.8,
              strokeWeight: 0.2,
            },
          });
        }

        checkpointsReduced = reduceCheckpointListByDistance(checkpoints);
        if (status !== 'COMPLETED') {
          // Apenas calcula se passou pelos pontos, se a viagem ainda não foi finalizada
          visitedTrajectory = checkVisitedPoints(trip.trajectories, checkpoints, 1.3);
        }
      }

      trajectoryPath = await generateTrajectoryPath(visitedTrajectory, checkpointsReduced);

      if (trip.trajectories.length > 0) {
        let countLabel = -1;
        let previous = null;
        let current = null;

        for (let k in trip.trajectories) {
          let item = trip.trajectories[k];

          if (
            item.latitude == '0' ||
            item.longitude == '0' ||
            item.latitude == '' ||
            item.longitude == '' ||
            item.latitude === null ||
            item.longitude === null
          ) {
            continue;
          }

          current = {
            lat: item.latitude,
            lng: item.longitude,
          };

          if (previous && current.lat === previous.lat && current.lng === previous.lng) {
            continue;
          }
          countLabel++;

          trajectoryMarkers.push({
            tripIdentifier: trip.identifier,
            isTrackable: isTrackable,
            lat: item.latitude,
            lng: item.longitude,
            color: tripColors[count],
            label: tripLabelsString[countLabel % tripLabelsString.length],
            header: {
              title: item?.type,
              icon: generateIconEvent(item?.type, '#4B0082'),
              color: '#4B0082',
            },
            content: {
              code: trip.code,
              cte: trip?.cteNumber,
              plate: trip?.platesTrackable,
              address: item.address,
            },
            icon: {
              url:
                'data:image/svg+xml;charset=UTF-8,' +
                encodeURIComponent(`
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="639.445" height="818" viewBox="0 0 639.445 818">
                    <defs>
                      <filter id="local_icone" x="0" y="0" width="639.445" height="818" filterUnits="userSpaceOnUse">
                        <feOffset dy="3" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="3" result="blur"/>
                        <feFlood flood-color="#00acff" flood-opacity="0.349"/>
                        <feComposite operator="in" in2="blur"/>
                        <feComposite in="SourceGraphic"/>
                      </filter>
                    </defs>
                    <g id="Grupo_4778" data-name="Grupo 4778" transform="translate(-4871.807 23808)">
                      <g transform="matrix(1, 0, 0, 1, 4871.81, -23808)" filter="url(#local_icone)">
                        <path
                          id="local_icone-2"
                          data-name="local_icone"
                        d="M621.435,310.753a314.6,314.6,0,0,1-61.971,186.131l-.782,1.043c-4.65,5.823-182.089,248.363-204.818,279.305A56.07,56.07,0,0,1,308.8,800h-.261A56.14,56.14,0,0,1,263.427,776.8C214.407,709,67.388,504.359,61.651,495.885A306.7,306.7,0,0,1,.028,306.755C3.635,140.7,136.051,6.243,302.018.158a310.758,310.758,0,0,1,319.417,310.6Z"
                          transform="translate(9.01 6)"
                          fill="${tripColors[count]}"
                          fill-opacity="0.9"
                        />
                      </g>
                    </g>
                  </svg>
                `),
              scaledSize: { width: 40, height: 40 },
            },
          });

          previous = current;
        }
      }

      trips.push({
        identifier: trip.identifier,
        code: trip.code,
        color: tripColors[count],
        trajectories: trip.trajectories,
        trajectoryPath: trajectoryPath,
        trajectoryMarkers: trajectoryMarkers,
        modality: trip.modality,
        status: status,
        isTrackable: isTrackable,
        checkpoints: checkpoints,
        lastCheckpoint: (checkpointsReduced.length > 0 ? checkpointsReduced[checkpointsReduced.length - 1] : null),
        lastCheckpointMarker: (checkpointsMarkers.length > 0 ? checkpointsMarkers[checkpointsMarkers.length - 1] : null),
        checkpointsMarkers: checkpointsMarkers,
        checkpointsReduced: checkpointsReduced,
      });

      ++count;
    }
    setTripList(trips);

    setIsLoadingMapData(false);
  };
  const calculateBounds = () => {
    if (!tripList || tripList.length === 0) {
      return;
    }
    let bounds = {
      x1: null,
      x2: null,
      y1: null,
      y2: null,
    };

    let coordinates = [];
    for (let i in tripList) {
      if (currentTripData && currentTripData.identifier !== tripList[i].identifier) {
        continue;
      }

      for (let k in tripList[i].checkpointsReduced) {
        let latitude = tripList[i].checkpointsReduced[k].latitude;
        let longitude = tripList[i].checkpointsReduced[k].longitude;

        coordinates.push({ latitude, longitude });

        if (!latitude || !longitude) {
          continue;
        }

        if (!bounds.x1 || latitude < bounds.x1) {
          bounds.x1 = latitude;
        }

        if (!bounds.x2 || latitude > bounds.x2) {
          bounds.x2 = latitude;
        }

        if (!bounds.y1 || longitude < bounds.y1) {
          bounds.y1 = longitude;
        }

        if (!bounds.y2 || longitude > bounds.y2) {
          bounds.y2 = longitude;
        }
      }

      for (let k in tripList[i].trajectories) {
        let latitude = tripList[i].trajectories[k].latitude;
        let longitude = tripList[i].trajectories[k].longitude;

        coordinates.push({ latitude, longitude });

        if (!latitude || !longitude) {
          continue;
        }

        if (!bounds.x1 || latitude < bounds.x1) {
          bounds.x1 = latitude;
        }

        if (!bounds.x2 || latitude > bounds.x2) {
          bounds.x2 = latitude;
        }

        if (!bounds.y1 || longitude < bounds.y1) {
          bounds.y1 = latitude;
        }

        if (!bounds.y2 || longitude > bounds.y2) {
          bounds.y2 = latitude;
        }
      }
    }

    setMapBounds(bounds);
  };
  useEffect(() => {
    loadPoints();
  }, [list]);
  useEffect(() => {
    calculateBounds();
  }, [tripList]);

  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  return (
    <Page
      screen="logistic"
      title="Tempo real"
      breadcrumbs={[{ link: '#', title: 'Transporte' }]}
      textFilterPlaceholder="Viagem, Motorista, CT-e ou Placa"
      icon={timeline}
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading}
      showPeriodFilter={false}
      allowEmptySearchPeriod={true}>
      <Card m="10px">
        <Flex direction="column" m="20px" gap="20px">
          <Map
            list={list}
            tripList={tripList}
            currentZoom={currentZoom}
            setCurrentZoom={setCurrentZoom}
            center={center}
            mapBounds={mapBounds}
            setCenter={setCenter}
            isLoading={isLoading || isLoadingMapData}
            isMobile={isMobile}
            currentTripData={currentTripData}
            setCurrentTripData={setCurrentTripData}
            currentTripIsTrackable={currentTripIsTrackable}
          />

          <Flex border="1px" borderColor="#7070703D" bgColor="#FFFFFF" w={'full'}>
            <ScreenLoader isLoading={isLoading}>
              {list.length === 0 ? (
                <Flex justify="center" my="25px">
                  Nenhum dado encontrado para os parâmetros filtrados.
                </Flex>
              ) : (
                <TableTimeLine
                  load={isLoading}
                  list={list}
                  tripList={tripList}
                  metadata={metadata}
                  setMetadata={setMetadata}
                  zoomIntoTrip={zoomIntoTrip}
                  paginate={metadata.total_pages > 1 && <Paginate metadata={metadata} setMetadata={setMetadata} />}
                  currentTripData={currentTripData}
                  setCurrentTripData={setCurrentTripData}
                  currentTripIsTrackable={currentTripIsTrackable}
                />
              )}
            </ScreenLoader>
          </Flex>
        </Flex>
      </Card>
    </Page>
  );
};

export default CurrentTripsPage;
