import React, { useEffect, useState } from 'react';

import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import requests from '../../../../services/requests';

const ProductList = ({ process, setIsLoadingData }) => {
  const [data, setData] = useState([]);

  const getData = () => {
    if (!process || !process.identifier) {
      return;
    }

    setIsLoadingData(true);
    requests
      .getProcessItem(process.identifier)
      .then((data) => {
        setData(data.data);
        setIsLoadingData(false);
      })
      .catch(() => {
        setIsLoadingData(false);
      });
  };

  useEffect(() => {
    getData();
  }, [process]);

  return (
    <>
      <Box overflowX="auto">
        <Table className="table-default">
          <Thead border="1px" borderBottom="1px">
            <Tr>
              <Th>TEC (NCM)</Th>
              <Th>Descrição</Th>
              <Th>Quantidade</Th>
              <Th>Peso</Th>
              <Th>Moeda</Th>
              <Th>Unidade</Th>
              <Th>Total</Th>
            </Tr>
          </Thead>
          <Tbody border="1px">
            {data.length > 0 ? (
              data.map((entity, key) => (
                <Tr key={key} border="none" cursor="pointer" style={{ '--delay': `${key / 10}s` }}>
                  <Td>{entity?.ncm ?? '-'}</Td>
                  <Td>{entity?.description ?? '-'}</Td>
                  <Td>{entity?.quantity ?? '-'}</Td>
                  <Td>{entity?.weightGrossFormatted ?? '-'}</Td>
                  <Td>{entity?.currency ?? '-'}</Td>
                  <Td>{entity?.valueUnitFormatted ?? '-'}</Td>
                  <Td>{entity?.valueTotalFormatted ?? '-'}</Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={7}>
                  <Flex justify="center" my="25px">
                    Nenhum dado encontrado para os parâmetros filtrados.
                  </Flex>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

export default ProductList;
