import React, { useEffect, useState } from 'react';

import { MdDelete, MdOutlineFileDownload, MdOutlineOpenInNew } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Button, Flex, IconButton, TabPanel, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';

import DefaultModal from '../../../../../components/Modal/Template/DefaultModal';
import ModalUpload from '../../../../../components/Modal/Upload/ModalUpload';
import requests from '../../../../../services/requests';
import { limitChars } from '../../../../../utils/strings';
import { getStatusTransition } from '../../../../../utils/translate';
import ModalChangeStatus from '../Modals/ModalChangeStatus';

const DocumentTab = ({ entity = {}, getEntity = () => {} }) => {
  const [files, setFiles] = useState([]);
  const [field, setField] = useState({});
  const [isLoadingListFiles, setIsLoadingListFiles] = useState(false);
  const [openModalUploadFile, setOpenModalUploadFile] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDownload = (fileIdentifier, uploadName) => {
    const link = document.createElement('a');
    link.href = (
      process.env.REACT_APP_API_URL + `/open/freight/${entity.identifier}/file/${fileIdentifier}/download?${new Date().getTime()}`
    ).replace('//open', '/open');
    link.target = '_blank';
    link.download = entity.identifier;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const formartType = (type, cteNumber) => {
    if (type === 'COLETA') {
      return `Coleta`;
    }

    if (type === 'ENTREGA') {
      if (cteNumber) {
        return `CT-e ${cteNumber}`;
      }

      return `Entrega`;
    }

    return `Não definido`;
  };

  const actionModalUploadFile = () => {
    setOpenModalUploadFile(!openModalUploadFile);
  };

  const sendUpload = async (docs, config) => {
    return new Promise((resolve, reject) => {
      requests
        .uploadDriverFreightFile(entity?.identifier, docs, config)
        .then(() => {
          resolve();
          getEntity();
          onClose();
        })
        .catch((e) => {
          reject();
        })
        .finally(() => {
          getEntity();
        });
    });
  };

  const isShowButtonDelete = (identifier, modality) => {
    if (modality === 'LOG') {
      return;
    }

    return (
      <Tooltip label="Excluir arquivo">
        <IconButton
          bg="#EF8484"
          p="0"
          icon={<MdDelete size={20} color="#FFFFFF" />}
          transition="0.2s"
          _hover={{ bgColor: '#f6a4a4' }}
          onClick={(decision) => {
            if (decision) {
              handleDelete(identifier);
            }
          }}
        />
      </Tooltip>
    );
  };

  const handleClickChangeStatus = (fileIdentifier) => {
    let transition = getStatusTransition(field?.status?.old, field?.status?.new);
    if (!transition) {
      toast.error('Não foi possivel alterar o status da viagem, verifique o fluxograma para alteração de status');
      return;
    }

    requests
      .changeStatusDriverFreightFile(entity.identifier, fileIdentifier, transition)
      .then((response) => {
        toast.success(`Status do ${response.name} alterado para ${field?.status?.new}`);
        // loadList();
      })
      .catch(() => {
        toast.error('Problemas ao alterar status do CTe, tente novamente ou entre em contato com o Administrador');
      });
  };

  const handleView = (fileIdentifier) => {
    const linkDocument = (process.env.REACT_APP_API_URL + `/open/freight/${entity.identifier}/file/${fileIdentifier}/view`).replace(
      '//open',
      '/open'
    );
    return <iframe width="100%" height="700em" src={linkDocument} allowFullScreen={true}></iframe>;
  };

  const loadDriverFreightFiles = () => {
    setIsLoadingListFiles(true);

    requests
      .showDriverFreightFiles(entity.identifier)
      .then((response) => {
        setFiles(response.data);
        setIsLoadingListFiles(false);
      })
      .catch(() => {
        setIsLoadingListFiles(false);
      });
  };

  useEffect(() => {
    if (entity?.identifier !== undefined) {
      loadDriverFreightFiles();
    }
  }, [entity]);

  return (
    <>
      <ModalUpload
        title="Documentos da viagem"
        subtitle="Upload de documentos da viagem"
        accept=".xml, .pdf, .png, .jpeg"
        messageConfirm=" salvo com sucesso para envio!"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        multiple={true}
        requestAction={sendUpload}
        nameForm="driver_freight_file_upload"
        additional={[
          {
            label: 'Modalidade',
            group: 'modality',
            name: 'mod',
            required: true,
            options: [
              { value: 'ENTREGA', label: 'Comprovante de entrega' },
            ],
          },
        ]}
      />
      <TabPanel>
        <Table className="table-default" w="100%">
          <Thead>
            <Tr>
              <Th>Nome</Th>
              <Th>Modalidade</Th>
              {entity?.modality == 'MANUAL' && <Th>Status</Th>}
              <Th>Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!files ||
              (files?.length === 0 && (
                <Flex justify="center" my="25px">
                  Nenhum documento encontrado
                </Flex>
              ))}
            {files &&
              files.map((file, key) => (
                <>
                  <Tr key={key}>
                    <Td color="#854FFF" fontSize={14}>
                      {file?.name ? limitChars(file.name, 10) : '-'}
                    </Td>
                    <Td>{file?.modalityReadable ?? '-'}</Td>
                    {entity?.modality == 'MANUAL' && (
                      <Td w="30%">
                        <ModalChangeStatus
                          options={file?.statusAvailable}
                          driverFreight={entity}
                          file={file}
                          field={field}
                          setField={setField}
                          callback={(decision) => {
                            if (decision) {
                              handleClickChangeStatus(file.identifier);
                            }
                          }}
                        />
                      </Td>
                    )}
                    <Td>
                      <Flex>
                        <DefaultModal
                          label="Visualizar arquivo"
                          width="70vw"
                          ctaButton={
                            <IconButton
                              bg="#6b6b6b"
                              p="0"
                              mr="2"
                              icon={<MdOutlineOpenInNew size={20} color="#FFFFFF" />}
                              transition="0.2s"
                              _hover={{ bgColor: '#b8b8b8' }}
                            />
                          }
                          title={file.modalityReadable}
                          showButtons={false}>
                          {handleView(file.identifier)}
                        </DefaultModal>
                        <Tooltip label="Baixar arquivo">
                          <IconButton
                            bg="#633dbe"
                            p="0"
                            icon={<MdOutlineFileDownload size={20} color="#FFFFFF" />}
                            mr="2"
                            transition="0.2s"
                            _hover={{ bgColor: '#ab88ff' }}
                            onClick={(decision) => {
                              if (decision) {
                                handleDownload(file.identifier, file.uploadName);
                              }
                            }}
                          />
                        </Tooltip>
                      </Flex>
                    </Td>
                  </Tr>
                </>
              ))}
          </Tbody>
        </Table>
        <Flex gap="35px" justify="flex-end" mt="10">
          <Button variant="primary" mb="5" onClick={() => onOpen()}>
            <Text fontSize="14px" textColor="#FFFFFF">
              Anexar documento
            </Text>
          </Button>
        </Flex>
      </TabPanel>
    </>
  );
};

export default DocumentTab;
