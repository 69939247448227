import React, { useState } from 'react';

import { format, parse } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Bar, BarChart, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Box, Flex, Select, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

import Card from '../../../../components/Card/Card';

const RevenueVendemmia = ({ data }) => {
  const [chartType, setChartType] = useState('bar');

  const tickFormatter = (tickItem) => {
    return tickItem.toLocaleString('pt-BR');
  };

  const dateConvert = data?.map((item) => ({
    date: parse(item.date, 'yyyy-MM-dd', new Date()),
    qtt: item.qtt,
    qtt2: item.qtt2,
  }));

  dateConvert?.sort((a, b) => a.date.getTime() - b.date.getTime());

  const formattedDataTable = dateConvert?.map((item) => {
    const isFutureDate = item.date > new Date();
    const qttValue = isFutureDate && item.qtt === 0 ? '-' : item.qtt;
    const qtt2Value = isFutureDate && item.qtt2 === 0 ? '-' : item.qtt2;

    return {
      date: format(item.date, 'MMM/yyy', { locale: ptBR }),
      qtt: qttValue,
      qtt2: qtt2Value,
    };
  });

  const formattedData = dateConvert?.map((item) => {
    const isFutureDate = item.date > new Date();
    const qttValue = isFutureDate && item.qtt === 0 ? '-' : item.qtt;
    const qtt2Value = isFutureDate && item.qtt2 === 0 ? '-' : item.qtt2;

    return {
      date: format(item.date, 'MMM', { locale: ptBR }),
      qtt: qttValue,
      qtt2: qtt2Value,
    };
  });

  const moneyFormat = (valor) => {
    return valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload?.length) {
      return (
        <Flex direction="column" bgColor="#FFFFFF" border="1px" borderColor="#7070703D" px="15px" py="20px" gap="10px">
          {payload?.map((entity, key) => (
            <React.Fragment key={key}>
              <Flex direction="row" align="center" gap="5px">
                <Text>{entity.dataKey == 'qtt' ? 'Vendemmia' : 'Gestão/Próprio'} :</Text>

                <Text>{moneyFormat(entity.value)}</Text>
              </Flex>
            </React.Fragment>
          ))}
        </Flex>
      );
    }

    return null;
  };

  const handleChartTypeChange = (event) => {
    setChartType(event.target.value);
  };

  let chartComponent;

  switch (chartType) {
    case 'bar':
      chartComponent = (
        <Flex direction="column" alignItems="center" w="full">
          <ResponsiveContainer height={650}>
            <BarChart
              data={formattedData}
              margin={{
                top: 20,
                right: 30,
                bottom: 20,
                left: 50,
              }}>
              <CartesianGrid />
              <Tooltip content={<CustomTooltip />} />
              <XAxis dataKey="date" tickLine={false} />
              <YAxis yAxisId="left" orientation="left" stroke="#422C76" tickFormatter={tickFormatter} />
              <YAxis yAxisId="right" orientation="right" stroke="#C767DC" tickFormatter={tickFormatter} />

              <Bar dataKey="qtt" yAxisId="left" barSize={25} fill="#422C76" />
              <Bar dataKey="qtt2" yAxisId="right" barSize={25} fill="#C767DC" />
            </BarChart>
          </ResponsiveContainer>
          <Flex gap="20px">
            <Flex align="center" gap="8px">
              <Box w="28px" h="18px" bgColor="primary" borderRadius="3px" />
              <Text>Vendemmia</Text>
            </Flex>

            <Flex align="center" gap="8px">
              <Box w="28px" h="18px" bgColor="secundary" borderRadius="3px" />
              <Text>Gestão/Próprio</Text>
            </Flex>
          </Flex>
        </Flex>
      );
      break;
    case 'line':
      chartComponent = (
        <Flex direction="column" alignItems="center" w="full">
          <ResponsiveContainer height={650}>
            <LineChart
              data={formattedData}
              margin={{
                top: 20,
                right: 30,
                bottom: 20,
                left: 50,
              }}>
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip content={<CustomTooltip />} />
              <XAxis dataKey="date" />
              <YAxis yAxisId="left" orientation="left" stroke="#422C76" tickFormatter={tickFormatter} />
              <YAxis yAxisId="right" orientation="right" stroke="#C767DC" tickFormatter={tickFormatter} />
              <Line type="monotone" dataKey="qtt" yAxisId="left" stroke="#422C76" strokeWidth={2} />
              <Line type="monotone" dataKey="qtt2" yAxisId="right" stroke="#C767DC" strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>
          <Flex gap="20px">
            <Flex align="center" gap="8px">
              <Box w="28px" h="18px" bgColor="primary" borderRadius="3px" />
              <Text>Vendemmia</Text>
            </Flex>

            <Flex align="center" gap="8px">
              <Box w="28px" h="18px" bgColor="secundary" borderRadius="3px" />
              <Text>Gestão/Próprio</Text>
            </Flex>
          </Flex>
        </Flex>
      );
      break;
    case 'table':
      chartComponent = (
        <Table className="table-default">
          <Thead border="1px" borderBottom="1px">
            <Tr>
              <Th>Mês</Th>
              <Th>Vendemmia</Th>
              <Th>Gestão/Próprio</Th>
            </Tr>
          </Thead>
          <Tbody border="1px">
            {formattedDataTable.map(function (entity, key) {
              return (
                <Tr key={key} style={{ '--delay': `0.${key}s` }}>
                  <Td>{entity.date}</Td>
                  <Td>{moneyFormat(entity.qtt)}</Td>
                  <Td>{moneyFormat(entity.qtt2)}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      );
      break;
    default:
      chartComponent = null;
      break;
  }

  return (
    <Card
      justify="space-between"
      h="100%"
      header={
        <Flex justify="space-between" gap="40px" py="10px">
          <Select w="260px" variant="primary" onChange={handleChartTypeChange}>
            <option value="bar">Gráfico em barras</option>
            <option value="line">Gráfico em linhas</option>
            <option value="table">Tabela de dados</option>
          </Select>
        </Flex>
      }>
      <Flex
        direction="column"
        alignItems="center"
        justify="center"
        w="calc(100% - 50px)"
        h="full"
        mx="20px"
        my="20px"
        overflowY="auto">
        {chartComponent}
      </Flex>
    </Card>
  );
};

export default RevenueVendemmia;
