import React, { useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Text } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import CollectionStatusChart from '../../../components/Charts/Warehouse/CollectionStatusChart';
import OrderProgressChart from '../../../components/Charts/Warehouse/OrderProgressChart';
import ExpandContainer from '../../../components/ExpandContainer/ExpandContainer';
import Page from '../../../components/Page';
import Paginate from '../../../components/Paginate/Paginate';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsWarehousePicking } from '../../../utils/filters/filterPresets';

import PickingPackingTable from './components/PickingPackingTable';
import PickingPackingTotalProducts from './components/PickingPackingTotalProducts';

const PickingPackingPage = () => {
  const hasPermission = permissions.warehousePickingAndPacking;
  const request = useRef(0);
  const requestChart = useRef(0);

  const [isMobile, setIsMobile] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);

  const [list, setList] = useState([]);
  const [chartData, setChartData] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingChart, setIsLoadingChart] = useState(true);

  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [sorting, setSorting] = useState(null);

  const handleSort = (column, direction) => {
    setSorting({
      column: column,
      direction: direction,
    });
  };

  const load = (filters, key, page) => {
    setIsLoading(true);

    requests
      .listPickingPack(filters, page)
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }
        request.current = key;

        setList(data.data);
        setMetadata(data.meta);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);
  };

  const loadCharts = (filters, key) => {
    setIsLoadingChart(true);

    delete filters.sort;
    delete filters.page;

    filters.type_storage = 'picking';

    requests
      .dashboardPickingPack(filters, 'picking')
      .then((data) => {
        if (requestChart.current && requestChart.current > key) {
          return;
        }
        requestChart.current = key;

        setChartData(data.data);
      })
      .finally(() => {
        setIsLoadingChart(false);
      });
  };

  const exportListFABActionPickingPacking = (filters, key) => {
    return exportListFABAction(filters, key, 'picking-packing');
  };

  const exportListFABActionPickingPackingExpedicao = (filters, key) => {
    return exportListFABAction(filters, key, 'picking-packing-expedition');
  };

  const exportListFABAction = (filters, key, modality) => {
    const toastId = toast.info('Aguarde enquanto preparamos a exportação...', {
      autoClose: false, // Mantém o toast na tela até que seja fechado manualmente
      closeOnClick: false, // Impede que o usuário feche o toast ao clicar nele
      draggable: false, // Impede que o usuário arraste o toast
      closeButton: false, // Não exibe o botão de fechar o toast
    });

    const date = new Date();

    let fileName = '-' + date.getFullYear() + date.getMonth() + date.getDate();
    if (modality === 'picking-packing') {
      fileName = 'PickingPacking' + fileName + '.xlsx';
    } else if (modality === 'picking-packing-expedition') {
      fileName = 'PickingPacking-e-Expedicao' + fileName + '.xlsx';
    } else {
      return;
    }

    requests
      .exportListPickingExpedicao(filters, modality)
      .then((response) => {
        const link = document.createElement('a');

        link.setAttribute('download', fileName);
        link.href = window.URL.createObjectURL(
          new Blob([response.data], {
            fileType: response.headers['content-type'],
          })
        );

        document.body.appendChild(link);

        link.click();
        link.remove();

        toast.dismiss(toastId); // Remove o toast exibido anteriormente
        toast.success('Exportação efetuado com sucesso!', {
          autoClose: 1500, // Tempo em milissegundos que o toast ficará na tela
        });
      })
      .catch(() => {
        toast.dismiss(toastId); // Remove o toast exibido anteriormente
        toast.error('Problemas ao realizar exportação, tente novamente ou entre em contato com o Administrador');
      });
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsWarehousePicking());
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  const chartsView = [
    {
      title: 'Status dos pedidos',
      content: <CollectionStatusChart data={chartData} />,
    },
    {
      title: 'Status de coleta',
      content: <OrderProgressChart data={chartData} />,
    },
    {
      title: 'Total de Produtos',
      content: <PickingPackingTotalProducts data={chartData} />,
    },
  ];

  return (
    <Page
      screen="warehouse"
      title="Picking and Packing"
      breadcrumbs={[{ link: '#', title: 'Armazém' }]}
      textFilterPlaceholder="Buscar por NF ou destinatário"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      loadCharts={loadCharts}
      load={load}
      isContentLoading={isLoading}
      sorting={sorting}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading || isLoadingChart}
      FAB={[
        {
          title: 'Exportar Picking & Packing',
          text: 'Exportar arquivo CSV com dados de Picking & Packing',
          action: exportListFABActionPickingPacking,
          modality: 'export-csv',
        },
        {
          title: 'Exportar Picking & Packing + Expedição',
          text: 'Exportar arquivo CSV com dados de Picking & Packing com dados de Expedição',
          action: exportListFABActionPickingPackingExpedicao,
          modality: 'export-csv',
        },
      ]}>
      <Flex direction="column" gap="10px" m="10px">
        <Flex w="full" gap="10px" h={{ base: 'initial', md: '345px' }}>
          {!isMobile ? (
            chartsView.map((item, index) => {
              return (
                <Card w="full" title={item.title} key={index}>
                  <ScreenLoader isLoading={isLoadingChart}>{item.content}</ScreenLoader>
                </Card>
              );
            })
          ) : (
            <Accordion w="full" gap="10px" display="grid">
              {chartsView.map((item, index) => {
                return (
                  <AccordionItem key={index}>
                    <Card w="full">
                      <AccordionButton display="flex" justifyContent="space-between">
                        <Text textStyle="cardTitle" fontSize="16px" p="8px 5px">
                          {item.title}
                        </Text>
                        <AccordionIcon />
                      </AccordionButton>
                      <ScreenLoader isLoading={isLoadingChart}>
                        <AccordionPanel>{item.content}</AccordionPanel>
                      </ScreenLoader>
                    </Card>
                  </AccordionItem>
                );
              })}
            </Accordion>
          )}
        </Flex>

        <Card id="tableId" className="up-anim">
          <ExpandContainer>
            <Box w="full">
              <Box overflow="auto" h="calc(100vh - 220px)">
                <ScreenLoader isLoading={isLoading}>
                  <PickingPackingTable list={list} handleSort={handleSort} sorting={sorting} />
                </ScreenLoader>
              </Box>

              {metadata.total_pages > 1 && <Paginate metadata={metadata} setMetadata={setMetadata} scroll="tableId" />}
            </Box>
          </ExpandContainer>
          <ScreenLoader isLoading={isLoading}>
            <Box overflowX="auto" m="1rem">
              <PickingPackingTable list={list} handleSort={handleSort} sorting={sorting} />
            </Box>
          </ScreenLoader>

          {metadata.total_pages > 1 && <Paginate metadata={metadata} setMetadata={setMetadata} scroll="tableId" />}
        </Card>
      </Flex>
    </Page>
  );
};

export default PickingPackingPage;
