const initialValues = (data) => {
  return {
    documents: [],
    status: data?.status || 'RASCUNHO',
    version: data?.version || '1.0.1',
    responsibleUsers: {
      comercial: data?.responsibleUsers?.comercial
        ? {
            label: data.responsibleUsers.comercial.name,
            value: data.responsibleUsers.comercial.identifier,
            type: data.responsibleUsers.comercial.type,
          }
        : '',
      additionalComercial: data?.responsibleUsers?.additionalComercial
        ? {
            label: data.responsibleUsers.additionalComercial.name,
            value: data.responsibleUsers.additionalComercial.identifier,
            type: data.responsibleUsers.additionalComercial.type,
          }
        : '',
      tax: data?.responsibleUsers?.tax
        ? { label: data.responsibleUsers.tax.name, value: data.responsibleUsers.tax.identifier, type: data.responsibleUsers.tax.type }
        : '',
      operator: data?.responsibleUsers?.operator
        ? {
            label: data.responsibleUsers.operator.name,
            value: data.responsibleUsers.operator.identifier,
            type: data.responsibleUsers.operator.type,
          }
        : '',
      financial: data?.responsibleUsers?.financial
        ? {
            label: data.responsibleUsers.financial.name,
            value: data.responsibleUsers.financial.identifier,
            type: data.responsibleUsers.financial.type,
          }
        : '',
      director: data?.responsibleUsers?.director
        ? {
            label: data.responsibleUsers.director.name,
            value: data.responsibleUsers.director.identifier,
            type: data.responsibleUsers.director.type,
          }
        : '',
      logistic: data?.responsibleUsers?.logistic
        ? {
            label: data.responsibleUsers.logistic.name,
            value: data.responsibleUsers.logistic.identifier,
            type: data.responsibleUsers.logistic.type,
          }
        : '',
    },
    registration: {
      vendemmiaOperationUnit: data?.registration?.vendemmiaOperationUnit || [],
      operationType: data?.registration?.operationType || '',
      companyName: data?.registration?.companyName || '',
      companyFantasyName: data?.registration?.companyFantasyName || '',
      companyOperationDocumentNumber: data?.registration?.companyOperationDocumentNumber
        ? {
            label: data.registration.companyOperationDocumentNumber,
            value: data.registration.companyOperationDocumentNumber,
            subscriptionState: data.registration.companyOperationSubscriptionState,
          }
        : '',
      companyOperationSubscriptionState: data?.registration?.companyOperationSubscriptionState || '',
      companyBranches: data?.registration?.companyBranches || [
        {
          documentNumber: '',
          subscriptionState: 'ISENTO',
        },
      ],
    },
    contact: {
      commercial: data?.contact?.commercial || [],
      operation: data?.contact?.operation || [],
      financial: data?.contact?.financial || [],
      legal: data?.contact?.legal || [],
    },
    commercial: {
      generalRestrictionsInformation: data?.commercial?.generalRestrictionsInformation || '',
      profitabilityInformation: data?.commercial?.profitabilityInformation || '',
      isIndefiniteContract: data?.commercial?.isIndefiniteContract || false,
      contractValidUntil: data?.commercial?.contractValidUntil || '',
      isProject: data?.commercial?.isProject || '',
      annualBilling: data?.commercial?.annualBilling || '',
      monthlyBilling: data?.commercial?.monthlyBilling || '',
      additionalInformation: data?.commercial?.additionalInformation || '',
    },
    tributary: {
      isIcmsTaxpayer: data?.tributary?.isIcmsTaxpayer || '',
      operationPurpose: data?.tributary?.operationPurpose || '',
      fiscalBenefit: data?.tributary?.fiscalBenefit || '',
      taxRegime: data?.tributary?.taxRegime || '',
      additionalInformation: data?.tributary?.additionalInformation || '',
    },
    operational: {
      forwardingAgent: data?.operational?.forwardingAgent || '',
      forwardingAgentAdditionalInformation: data?.operational?.forwardingAgentAdditionalInformation || '',
      dispatchValue: data?.operational?.dispatchValue || '',
      productTypeDescription: data?.operational?.productTypeDescription || '',
      isAntidumping: data?.operational?.isAntidumping || '',
      useSystemBravos: data?.operational?.useSystemBravos || '',
      importingLicenseConsentingEntity: data?.operational?.importingLicenseConsentingEntity || [],
      importingLicenseValue: data?.operational?.importingLicenseValue || '',
      importingLicenseAdditionalInformation: data?.operational?.importingLicenseAdditionalInformation || '',
      operationNature: data?.operational?.operationNature || '',
      incoterm: data?.operational?.incoterm || '',
      incotermAdditionalInformation: data?.operational?.incotermAdditionalInformation || '',
      hasInternationalTransportQuote: data?.operational?.hasInternationalTransportQuote || '',
      transportModality: data?.operational?.transportModality || [],
      goodsEntranceFederativeUnit: data?.operational?.goodsEntranceFederativeUnit || '',
      goodsEntranceFederativeAdditionalInformation: data?.operational?.goodsEntranceFederativeAdditionalInformation || '',
      customsClearanceLocation: data?.operational?.customsClearanceLocation || '',
      customsClearanceLocationAdditionalInformation: data?.operational?.customsClearanceLocationAdditionalInformation || '',
      insuranceCalculationBasisVendemmia: data?.operational?.insuranceCalculationBasisVendemmia || '',
      insuranceCalculationBasisClient: data?.operational?.insuranceCalculationBasisClient || '',
      insuranceAdditionalInformation: data?.operational?.insuranceAdditionalInformation || '',
      storageAfterBilling: data?.operational?.storageAfterBilling || '',
      transportType: data?.operational?.transportType || '',
      goodsEntranceFederativeUnitModality: data?.operational?.goodsEntranceFederativeUnitModality || '',
      storageAfterBillingAdditionalInformation: data?.operational?.storageAfterBillingAdditionalInformation || '',
      nationalFreightModality: data?.operational?.nationalFreightModality || '',
      nationalFreightBilling: data?.operational?.nationalFreightBilling || '',
      nationalFreightAdditionalInformation: data?.operational?.nationalFreightAdditionalInformation || '',
      ncmRelation: data?.operational?.ncmRelation || [],
      nationalizationFactor: data?.operational?.nationalizationFactor || '',
      nationalizationDays: data?.operational?.nationalizationDays || '',
      expensesFactor: data?.operational?.expensesFactor || '',
      expensesDays: data?.operational?.expensesDays || '',
      billingFactor: data?.operational?.billingFactor || '',
      billingDays: data?.operational?.billingDays || '',
      additionalInformation: data?.operational?.additionalInformation || '',
      hasCashFlow: data?.operational?.hasCashFlow || false,
      exchanges: data?.operational?.exchanges || [
        {
          days: '',
          percent: '',
        },
      ],
      ncmRelations:
        data?.operational?.ncmRelations ||
        [
          // {
          //     description: "",
          //     id: "",
          //     tariff: "",
          // },
        ],
    },
    financial: {
      upFrontPaymentWarranty: data?.financial?.upFrontPaymentWarranty || false,
      upFrontPaymentWarrantyAdditionalInformation: data?.financial?.upFrontPaymentWarrantyAdditionalInformation || '',
      commercialDiscount: data?.financial?.commercialDiscount || false,
      commercialDiscountAdditionalInformation: data?.financial?.commercialDiscountAdditionalInformation || '',
      icmsRates: data?.financial?.icmsRates || [
        {
          value: '',
          discountValue: '',
        },
      ],
      serviceInvoice: data?.financial?.serviceInvoice || false,
      administrateTax: data?.financial?.administrateTax || '',
      serviceInvoiceMinValue: data?.financial?.serviceInvoiceMinValue || '',
      serviceInvoiceModality: data?.financial?.serviceInvoiceModality || '',
      serviceInvoiceAdditionalInformation: data?.financial?.serviceInvoiceAdditionalInformation || '',
      exporterPayment: data?.financial?.exporterPayment || [],
      exporterPaymentAdditionalInformation: data?.financial?.exporterPaymentAdditionalInformation || '',
      financialCharges: data?.financial?.financialCharges || false,
      financialChargesModality: data?.financial?.financialChargesModality || '',
      financialChargesAddition: data?.financial?.financialChargesAddition || '',
      financialChargesAdditionalInformation: data?.financial?.financialChargesAdditionalInformation || '',
      paymentTermsCommodity: data?.financial?.paymentTermsCommodity || '',
      paymentTermsCommodityDays: data?.financial?.paymentTermsCommodityDays || '',
      paymentTermsTax: data?.financial?.paymentTermsTax || '',
      paymentTermsTaxDays: data?.financial?.paymentTermsTaxDays || '',
      paymentTermsExpenses: data?.financial?.paymentTermsExpenses || '',
      paymentTermsExpensesDays: data?.financial?.paymentTermsExpensesDays || '',
      paymentTermsServiceInvoice: data?.financial?.paymentTermsServiceInvoice || '',
      paymentTermsServiceInvoiceDays: data?.financial?.paymentTermsServiceInvoiceDays || '',
      paymentTermsAdditionalInformation: data?.financial?.paymentTermsAdditionalInformation || '',
      extraCommercialDiscount: data?.financial?.extraCommercialDiscount || '',
      hasAdministrativeFee: data?.financial?.hasAdministrativeFee || false,
      administrativeFee: data?.financial?.administrativeFee || '',
      minAdministrativeFee: data?.financial?.minAdministrativeFee || '',
      administrativeFeeAdditionalInformation: data?.financial?.administrativeFeeAdditionalInformation || '',
    },
  };
};

export default initialValues;
