const yup = require('yup');

export const validationSchema = (requiredList = {}) => {
  return yup.object().shape({
    responsibleLogisticUser: yup.mixed().nullable(),
    availableDateFormatted: yup.string(),
    reference: yup.string().required('Campo obrigatório'),
    client: yup.mixed().nullable(),
    currency: yup.string(),
    valueOfTheMerchandise: yup.mixed().nullable(),
    process: yup.mixed().nullable(),
    modality: yup.mixed().required('Campo obrigatório'),
    isPacklistReceived: yup.mixed().required('Campo obrigatório'),
    originPort: yup.mixed().required('Campo obrigatório'),
    destinationPort: yup.mixed().required('Campo obrigatório'),
    incoterm: yup.mixed().nullable(),
    inlandZipCode: requiredList?.incoterm !== 'EXW' ? yup.string() : yup.string().required('Campo obrigatório'),
    dangerousClass: yup.mixed().required('Campo obrigatório'),
    quoteType: requiredList?.modality?.includes('air') ? yup.mixed() : yup.mixed().required('Campo obrigatório'),
    quantity: requiredList?.modality?.includes('air') ? yup.string().required('Campo obrigatório') : yup.string(),
    height: requiredList?.modality?.includes('air') ? yup.string().required('Campo obrigatório') : yup.string(),
    width: requiredList?.modality?.includes('air') ? yup.string().required('Campo obrigatório') : yup.string(),
    length: requiredList?.modality?.includes('air') ? yup.string().required('Campo obrigatório') : yup.string(),
    cbmCubicMeters: requiredList?.modality?.includes('air') ? yup.string().required('Campo obrigatório') : yup.string(),
    ncms: yup
      .array(
        yup.object({
          code: yup.string(),
          description: yup.string(),
          tariff: yup.string(),
        })
      )
      .test({
        message: 'Você deve selecionar um ou mais NCM',
        test: (arr) => {
          return arr.find(({ description }) => {
            return description;
          });
        },
      }),
    weigth: requiredList?.modality?.includes('air') ? yup.string().required('Campo obrigatório') : yup.string(),
    ctrs: requiredList?.modality?.includes('air')
      ? yup.string()
      : yup.array().of(
          yup.object().shape({
            qtt: yup.string().required('Campo obrigatório'),
            size: yup.mixed().required('Campo obrigatório'),
            type: yup.mixed().required('Campo obrigatório'),
          })
        ),
    description: yup.string(),
    invoiceValue: yup.string(),
    copyPeople3Email: yup.string().required('Campo obrigatório').email('Deve ser um e-mail válido').trim(),
  });
};
