import React, { useState } from 'react';

import { Cell, Pie, PieChart, ResponsiveContainer, Sector, Tooltip } from 'recharts';

import { Box, Flex, Text } from '@chakra-ui/react';

const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

const ChartAbc = ({ entity, text, activeItemName, sizeWidth }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const filteredData = entity?.filter((item) => item.value !== 0);

  const total = () => {
    let totalValue = 0;

    for (let index = 0; index < entity?.length; index++) {
      totalValue += entity[index].value;
    }
    return totalValue;
  };

  const findActiveItemIndex = () => {
    for (let index = 0; index < filteredData?.length; index++) {
      if (filteredData[index].name === activeItemName) {
        return index;
      }
    }
    return null;
  };

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  return (
    <Flex pos="relative" justify="center" w={sizeWidth} h={{ base: '160px', md: "200px"}}>
      <Box zIndex="2" w={sizeWidth} h={{ base: '160px', md: "200px"}}>
        <ResponsiveContainer>
          <PieChart margin={{ top: -180 }}>
            <Pie
              dataKey="value"
              activeShape={renderActiveShape}
              onMouseEnter={onPieEnter}
              activeIndex={activeItemName ? findActiveItemIndex() : null}
              data={filteredData}
              startAngle={180}
              endAngle={0}
              innerRadius={120}
              cy="100%"
              outerRadius="100%"
              stroke="none"
              paddingAngle={1}>
              {filteredData?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </Box>
      <Flex direction="column" pos="absolute" bottom="0" align="center" zIndex="1">
        <Text textStyle="primaryTitle" textColor="#0F0A1D" fontWeight="bold">
          {total().toLocaleString()}
        </Text>
        <Text textStyle="tableTitle" textColor="#0F0A1D93">
          {text}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ChartAbc;
