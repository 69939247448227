import { useRef } from "react";

/*
params
fn: (função que deve ser executada no onchange)
delay: (tempo que o debounce usará para executar e capturar o ultimo valor do onChange)

O debounce servirá para caso voce precise fazer requisições durante digitações em um input,
o debounce irá pegar apenas a ultima referencia da palavra digitada e posteriormente fará a requisição,
dessa maneira, evitamos o cenario de fazer uma requisição para cada letra digitada no input, ganhando em desempenho e economizando requisições. 
*/

export function useDebounce(fn, delay) {
    const timeoutRef = useRef(0);

    function debouncedFn(...args) {
        window.clearTimeout(timeoutRef.current);

        timeoutRef.current = window.setTimeout(() => {
            fn(...args);
        }, delay)
    }

    return debouncedFn;
}

/* 
Exemplo de uso:
const debounceHandleInputChange = useDebounce(handleInputChange, 500)
<Input onchange={debounceHandleInputChange} /> 
*/