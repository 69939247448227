import React, { useEffect, useState } from 'react';

import { ErrorMessage, Formik } from 'formik';
import { MdAdd, MdDelete, MdDeleteOutline, MdHelp, MdOutlineFileCopy, MdOutlineSave } from 'react-icons/md';
import { NumericFormat } from 'react-number-format';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { Box, Button, Flex, FormControl, Grid, GridItem, IconButton, Input, Text, Textarea, Tooltip } from '@chakra-ui/react';

import 'react-toastify/dist/ReactToastify.css';
import Card from '../../../../components/Card/Card';
import InputDefault from '../../../../components/Form/Input/InputDefault';
import { LabelDefault } from '../../../../components/Form/Label/LabelDefault';
import FloatActionButton from '../../../../components/Generic/FloatActionButton';
import Layout from '../../../../components/Layout';
import Menubar from '../../../../components/MenuBar/Menubar';
import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';
import api from '../../../../services/api';
import requests from '../../../../services/requests';
import { handleRemoveChange } from '../../operation-specification/Helpers/inputMultiple';
import dangerousClassOptions from '../Helpers/dangerousClassOptions';
import incotermOptions from '../Helpers/incotermOptions';
import { initialValues } from '../Helpers/initialValues';
import quoteTypeOptions from '../Helpers/quoteTypeOptions';
import sizeCtrOptions from '../Helpers/sizeCtrOptions';
import typeCtrOptions from '../Helpers/typeCtrOptions';
import { validationSchema } from '../Helpers/validationSchema';
import yesOrNoOptions from '../Helpers/yesOrNoOptions';

import LogHistoryDrawer from './LogHistoryDrawer';
import ModalListDocuments from './ModalListDocuments';

const QuoteDetailPage = () => {
  const { identifier } = useParams();
  const [isOpenFloatButton, setIsOpenFloatButton] = useState(false);

  const [clientsOptions, setClientsOptions] = useState([]);
  const [noClientOptionsMessage, setNoClientOptionsMessage] = useState('Nenhum resultado encontrado');
  const [noOptionsMessage, setNoOptionsMessage] = useState('Nenhum resultado encontrado');
  const [processFilter, setProcessFilter] = useState({});
  const [listUser, setListUser] = useState(false);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [isLoadingProcess, setIsLoadingProcess] = useState(false);
  const [processOptions, setProcessOptions] = useState([]);
  const [load, setLoad] = useState(false);
  const [process, setProcess] = useState([]);
  const [aereoValue, setAereoValue] = useState('');
  const [modalityOptions, setModalityOptions] = useState([]);
  const [maritimoValue, setMaritimoValue] = useState('');
  const [terrestreValue, setTerrestreValue] = useState('');
  const [portsOptions, setPortsOptions] = useState([]);
  const [requiredList, setRequiredList] = useState({});
  const [ctrsMandatory, setCtrsMandatory] = useState(true);
  const [userOptions, setUserOptions] = useState([]);
  const [entity, setEntity] = useState({});
  const [currency, setCurrency] = useState([]);
  const [logHistory, setLogHistory] = useState([]);
  const [action, setAction] = useState(1);
  const [logHistoryMeta, setLogHistoryMeta] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [listUsersLogistic, setListUsersLogistic] = useState([]);
  const [isLoadingEntity, setIsLoadingEntity] = useState(false);

  const [modality, setModality] = useState();
  const [quantity, setQuantity] = useState();
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [length, setLength] = useState();
  const [cubageFactor, setCubageFactor] = useState();
  const [modalityFull, setModalityFull] = useState();

  const [inputNcmsRelations, setInputNcmsRelations] = useState([]);
  const [ncmOptions, setNcmOptions] = useState([]);
  const [isLoadingNcmOptions, setIsLoadingNcmOptions] = useState(false);
  const [noNcmOptionsMessage, setNoNcmOptionsMessage] = useState('Nenhum resultado encontrado');

  const getQuote = () => {
    setIsLoadingEntity(true);

    requests.getQuoteShow(identifier).then((data) => {
      if (data?.transportModalitySlug === 'sea') {
        setRequiredList({ modality: 'Marítimo (sea)' });
      } else if (data?.transportModalitySlug === 'air') {
        setRequiredList({ modality: 'Aéreo (air)' });
      }

      setEntity(data);
      setCubageFactor(data?.cbmCubicMeters);
      setQuantity(data?.quantity);
      setHeight(data?.height);
      setWidth(data?.width);
      setLength(data?.length);

      if (data?.transportModality && data?.transportModalitySlug) {
        setModalityFull(data?.transportModality + ' (' + data?.transportModalitySlug + ')');
      }

      if (data && data.ncms && data.ncms.length > 0) {
        let aux = [];
        data.ncms.forEach((ncm) => {
          aux.push({
            code: ncm.code,
            description: ncm.code + ' ' + ncm.description,
            tariff: ncm.tariff,
          });
        });

        setInputNcmsRelations(aux);
      }

      setIsLoadingEntity(false);
    });
  };

  const loadClientsList = (search = '') => {
    setNoClientOptionsMessage('Carregando');
    setIsLoadingClients(true);

    if (search?.length <= 2) {
      setNoClientOptionsMessage('Insira ao menos 2 caracteres para fazer a busca');
      setIsLoadingClients(false);
    } else {
      api
        .get(`quote/client/list?page_size=1000${search && `&search=${encodeURIComponent(search)}`}`, {
          headers: {
            Authorization: 'Bearer ' + window.localStorage.getItem('session-token'),
          },
        })
        .then((response) => {
          var clients = [];

          response.data.data.forEach((item) => {
            clients.push({
              value: item?.identifier,
              label: `${item?.name} - ${item?.documentNumberFormatted}`,
            });
          });

          setClientsOptions(clients);
          setNoClientOptionsMessage('Nenhum resultado encontrado');
        })
        .finally(() => {
          setLoad(false);
          setIsLoadingClients(false);
        });
    }
  };

  const loadProcessList = (client = '', search = '') => {
    setNoOptionsMessage('Carregando');
    setIsLoadingProcess(true);

    if (search.length < 2 && client.length === 0) {
      setNoOptionsMessage('Insira ao menos 2 caracteres ou selecione um cliente para fazer a busca');
      setIsLoadingProcess(false);
    } else {
      api
        .get('process/list/all?page_size=10000' + (client ? `&clients[]=${client}` : '') + (search ? `&search=${search}` : ''), {
          headers: {
            Authorization: 'Bearer ' + window.localStorage.getItem('session-token'),
          },
        })
        .then((response) => {
          var processList = [];

          response.data.forEach((item) => {
            processList.push({
              value: item?.identifier,
              label: item?.code,
            });
          });

          setProcess(response.data);
          setProcessOptions(processList);
          setNoOptionsMessage('Nenhum resultado encontrado');
        })
        .finally(() => {
          setLoad(false);
          setIsLoadingProcess(false);
        });
    }
  };

  const loadUsers = (client = undefined) => {
    if (client?.length > 0) {
      let aux = [];
      client.map((data) => {
        aux.push(data.value);
      });

      api
        .get(`/quote/client/users`, {
          headers: {
            Authorization: 'Bearer ' + window.localStorage.getItem('session-token'),
          },
          params: { clients: aux },
        })
        .then((res) => {
          let options = res?.data.map((data) => {
            return {
              value: data.identifier,
              label: data.name,
            };
          });

          setUserOptions(options);
        });
    }
  };

  const loadModality = () => {
    api
      .get('transport-modality/list', {
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('session-token'),
        },
      })
      .then((response) => {
        var modalities = [];
        response.data.data.forEach((item) => {
          if (item?.slug !== 'ground') {
            modalities.push({
              value: item?.identifier,
              label: item?.description + ' (' + item?.slug + ')',
            });
          }
        });
        setLoad(false);
        setModalityOptions(modalities);
      });
  };

  const loadCurrency = () => {
    requests
      .getCurrency()
      .then((response) => {
        setCurrency(response.data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const loadLogHistory = () => {
    requests
      .getLogHistory(identifier, logHistoryMeta.current_page > 0 ? logHistoryMeta.current_page : 1, 10)
      .then((res) => {
        setLogHistory(res.data);
        setLogHistoryMeta(res.meta);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const selectedModal = (modal) => {
    setModality(modal);
    calculateCubage(modality);
  };

  const calculateCubage = (modality) => {
    let factor = 0;

    switch (modality) {
      case 'rodoviario':
        factor = 300;
        break;
      case 'Marítimo (sea)':
        factor = 1000;
        break;
      case 'Aéreo (air)':
        factor = 166.667;
        break;
      default:
        factor = cubageFactor;
    }

    const cubage = quantity * (height / 100) * (width / 100) * (length / 100) * factor;

    setCubageFactor(cubage.toFixed(2));
  };

  const loadPorts = () => {
    api
      .get('port/list?page_size=99999999', {
        headers: {
          Authorization: 'Bearer ' + window.localStorage.getItem('session-token'),
        },
      })
      .then((response) => {
        var ports = [];

        response.data.data.forEach((item) => {
          ports.push({
            value: item?.id,
            label: item?.name + (item?.country && ' (' + item?.country.name + ')'),
          });
        });

        setPortsOptions(ports);
      });
  };

  const handleAddCtrs = (values = undefined, setFieldValue = undefined) => {
    let aux = values.ctrs;
    aux.push({
      qtt: '',
      size: '',
      type: '',
    });

    setFieldValue('ctrs', aux);
  };

  const handleRemoveCtr = (values = undefined, index = undefined, setFieldValue = undefined) => {
    let aux = values.ctrs;
    aux.splice(index, 1);

    setFieldValue('ctrs', aux);
  };

  const handleDuplicateQuote = (entity) => {
    requests
      .postQuoteDuplicate(entity?.identifier)
      .then((response) => {
        toast.success(`${response.message}`);

        let entityOld = `${response.newEntityDuplicated?.name} (Copia do ${response.entity?.name})`;
        localStorage.setItem('entity-old', entityOld);

        setTimeout(() => {
          window.location.href = `/vendemmia/quote/detail/${response.newEntityDuplicated.identifier}`;
        }, 3000);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const listOperationNcmsAction = (code = '') => {
    if (code?.length < 3) {
      setNoNcmOptionsMessage('Insira ao menos 4 digítos para fazer a busca');
      return;
    }

    if (code?.length > 3) {
      setNoNcmOptionsMessage('Carregando');
      setIsLoadingNcmOptions(true);

      requests
        .listNcmsAction(code)
        .then((response) => {
          let result = [];
          response?.data?.length &&
            response.data.forEach((ncm) => {
              result.push({
                value: ncm.code,
                label: ncm.code + ' ' + ncm.description,
              });
            });

          setNcmOptions(result);
          setIsLoadingNcmOptions(false);
          setNoNcmOptionsMessage('Nenhum resultado encontrado');
        })
        .catch((error) => {
          toast.error('Ocorreu um erro ao trazer os ncms');
        });
    }
  };

  const loadUsersLogistic = () => {
    requests.getUsersLogistic().then((res) => {
      setListUsersLogistic(res.data);
    });
  };

  useEffect(() => {
    loadPorts();
    loadModality();
    loadCurrency();
    loadUsersLogistic();

    if (identifier !== undefined) {
      getQuote(identifier);
    }
  }, []);

  useEffect(() => {
    if (identifier !== undefined) {
      loadLogHistory(identifier);
    }
  }, [action]);

  useEffect(() => {
    calculateCubage(modalityFull && modality === undefined ? modalityFull : modality);
  }, [modality, quantity, height, width, length]);

  useEffect(() => {
    loadUsers(clientsOptions);
  }, [listUser]);

  const selectCustomStyles = () => ({
    control: (provided) => ({
      ...provided,
      backgroundColor: '#F2F2F2',
      width: '100%',
    }),
  });

  return (
    <>
      <Layout>
        <Menubar
          linkTree={[
            { link: '#', title: 'Vendemmia' },
            { link: '/vendemmia/quote', title: 'Cotação' },
          ]}
          title={
            entity.identifier
              ? localStorage.getItem('entity-old')
                ? localStorage.getItem('entity-old')
                : 'Detalhes da cotação'
              : 'Nova cotação'
          }
          hideAllFilters={true}
        />
        {isLoadingEntity ? (
          <ScreenLoader isLoading={isLoadingEntity} />
        ) : (
          <Box>
            <Formik
              initialValues={initialValues(entity)}
              validationSchema={validationSchema(requiredList, ctrsMandatory)}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);

                let aux = {
                  availableDate: values?.availableDate,
                  responsibleLogisticUser: values?.responsibleLogisticUser?.value,
                  reference: values?.reference,
                  currency: values?.currency?.value,
                  valueOfTheMerchandise: values?.valueOfTheMerchandise,
                  client: values?.client?.value,
                  process: values?.process?.value,
                  modality: values?.modality?.value,
                  originPort: values?.originPort?.value,
                  destinationPort: values?.destinationPort?.value,
                  incoterm: values?.incoterm?.value,
                  inlandZipCode: values?.inlandZipCode,
                  quoteType: values?.quoteType?.value,
                  dangerousClass: values?.dangerousClass?.value,
                  quantity: values?.quantity,
                  height: values?.height,
                  width: values?.width,
                  length: values?.length,
                  cbmCubicMeters: values?.cbmCubicMeters,
                  weigth: values?.weigth,
                  description: values?.description,
                  invoiceValue: values?.invoiceValue,
                  isPacklistReceived: values?.isPacklistReceived?.value === true ? 1 : 0,
                  copyPeople2: values?.copyPeople2?.value,
                  copyPeople3Email: values?.copyPeople3Email,
                  ctrs: [],
                  ncms: [],
                };

                if (values?.ctrs?.length > 0 && values?.modality?.label?.includes('Marítimo')) {
                  values?.ctrs.map((ctr) => {
                    aux.ctrs.push({
                      qtt: ctr?.qtt,
                      size: ctr?.size?.value,
                      type: ctr?.type?.value,
                    });
                  });
                }

                if (values?.ncms?.length > 0) {
                  values?.ncms.map((ncm) => {
                    aux.ncms.push(ncm.code);
                  });
                }

                if (entity.identifier) {
                  requests
                    .postQuoteEdit(entity?.identifier, aux)
                    .then((response) => {
                      toast.success(`Cotação de referência ${response?.reference} editada com sucesso`);

                      setTimeout(() => {
                        window.location.href = `/vendemmia/quote`;
                      }, 2000);

                      setSubmitting(false);
                    })
                    .catch((error) => {
                      setSubmitting(false);

                      console.log('error', error);
                    });
                } else {
                  requests
                    .postQuoteNew(aux)
                    .then((response) => {
                      toast.success(`Cotação de referência ${response?.reference} cadastrada com sucesso`);

                      setTimeout(() => {
                        window.location.href = '/vendemmia/quote';
                      }, 2000);

                      setSubmitting(false);
                    })
                    .catch((error) => {
                      setSubmitting(false);

                      console.log('error', error);
                    });
                }
              }}>
              {({ values, setFieldValue, handleChange, handleSubmit, isSubmitting, errors }) => (
                <form onSubmit={handleSubmit}>
                  <Card
                    m="10px"
                    header={
                      <>
                        <Flex gap="2px" direction="column">
                          <Text textStyle="cardTitle" fontWeight="bold" color="primary">
                            Cotação
                          </Text>
                          {entity.identifier && (
                            <>
                              <Flex gap="2px">
                                <Text textStyle="subtitle" color="#BEBEBE" pt="3px">
                                  Código da cotação:
                                </Text>
                                <Text textStyle="cardTitle" fontWeight="bold" color="primary" ml="5px">
                                  {entity?.name}
                                </Text>
                              </Flex>
                            </>
                          )}
                        </Flex>
                        <ModalListDocuments quote={entity} />
                      </>
                    }>
                    <Box className="down-anim" justify="space-between" pl="60px" pr="60px" pt="30px" pb="30px">
                      <Grid templateColumns="repeat(5, 1fr)" gap="40px">
                        <Flex direction="column">
                          <LabelDefault name="responsibleLogisticUser" text="Responsável logístico" />

                          <Select
                            styles={selectCustomStyles()}
                            id="responsibleLogisticUser"
                            name="responsibleLogisticUser"
                            value={values.responsibleLogisticUser}
                            options={[
                              { value: null, label: 'Nenhum' },
                              ...listUsersLogistic.map((item) => ({ value: item.identifier, label: item.name })),
                            ]}
                            onChange={(option) => setFieldValue('responsibleLogisticUser', option)}
                            className="input-register"
                            placeholder="Selecione..."
                          />
                          <ErrorMessage component="p" className="error-message-error" name="responsibleLogisticUser" />
                        </Flex>

                        <Flex direction="column">
                          <LabelDefault name="availableDate" text="Carga liberada" />

                          <Input
                            type="date"
                            name="availableDate"
                            cursor="pointer"
                            bg="#F2F2F2"
                            borderColor="#70707033"
                            value={values.availableDate}
                            onChange={handleChange}
                          />
                          <ErrorMessage component="p" className="error-message-error" name="availableDate" />
                        </Flex>

                        <Flex direction="column">
                          <LabelDefault name="reference" text="REF" />
                          <InputDefault
                            setFieldValue={setFieldValue}
                            value={values.reference}
                            name="reference"
                            placeholder="Preencha uma referência"
                          />
                          <ErrorMessage component="p" className="error-message-error" name="reference" />
                        </Flex>

                        <Flex direction="column">
                          <LabelDefault name="client" text="Cliente" />
                          <Select
                            options={clientsOptions}
                            styles={selectCustomStyles()}
                            id="client"
                            name="client"
                            value={values.client}
                            onChange={(option) => {
                              setFieldValue('client', option);
                              setListUser(!listUser);
                              loadProcessList(option?.value);
                              setProcessFilter({});
                            }}
                            noOptionsMessage={() => noClientOptionsMessage}
                            onInputChange={(inputValue) => {
                              loadClientsList(inputValue);
                            }}
                            isLoading={isLoadingClients}
                            className="input-register"
                            placeholder="Selecione um cliente"
                          />
                          <ErrorMessage component="p" className="error-message-error" name="client" />
                        </Flex>

                        <Flex direction="column">
                          <LabelDefault name="process" text="Processo" />
                          <Select
                            styles={selectCustomStyles()}
                            options={processOptions}
                            id="process"
                            name="process"
                            onInputChange={(inputValue) => {
                              loadProcessList(values?.client?.value, inputValue);
                            }}
                            noOptionsMessage={() => noOptionsMessage}
                            value={values.process}
                            onChange={(option) => {
                              setFieldValue('process', option);
                            }}
                            isLoading={isLoadingProcess}
                            className="input-register"
                            placeholder="Selecione um processo"
                          />
                          <ErrorMessage component="p" className="error-message-error" name="process" />
                        </Flex>

                        <Flex direction="column">
                          <LabelDefault name="modality" text="Modal" />
                          <Select
                            styles={selectCustomStyles()}
                            options={modalityOptions}
                            id="modality"
                            name="modality"
                            value={values.modality}
                            onChange={(option) => {
                              setFieldValue('modality', option);
                              setRequiredList({ modality: option?.label });

                              selectedModal(option?.label);

                              if (option?.label?.includes('Marítimo')) {
                                handleAddCtrs(values, setFieldValue);
                              }
                            }}
                            className="input-register"
                            placeholder="Selecione uma modalidade"
                          />
                          <ErrorMessage component="p" className="error-message-error" name="modality" />
                        </Flex>

                        {values.modality.label?.includes('Marítimo') && (
                          <Flex direction="column">
                            <LabelDefault name="quoteType" text="Tipo" />
                            <Select
                              options={quoteTypeOptions}
                              styles={selectCustomStyles()}
                              value={values.quoteType}
                              onChange={(option) => {
                                setFieldValue('quoteType', option);
                                if (option?.value === 'LCL') {
                                  setCtrsMandatory(false);
                                } else {
                                  setCtrsMandatory(true);
                                }
                              }}
                              className="input-register"
                              placeholder="Selecione o tipo"
                            />
                            <ErrorMessage component="p" className="error-message-error" name="quoteType" />
                          </Flex>
                        )}

                        <Flex direction="column">
                          <LabelDefault name="originPort" text="Origem" />
                          <Select
                            options={portsOptions}
                            styles={selectCustomStyles()}
                            id="originPort"
                            name="originPort"
                            value={values.originPort}
                            onChange={(option) => setFieldValue('originPort', option)}
                            className="input-register"
                            placeholder="Selecione a origem"
                          />
                          <ErrorMessage component="p" className="error-message-error" name="originPort" />
                        </Flex>

                        <Flex direction="column">
                          <LabelDefault name="originPort" text="Destino" />
                          <Select
                            options={portsOptions}
                            styles={selectCustomStyles()}
                            id="destinationPort"
                            name="destinationPort"
                            value={values.destinationPort}
                            onChange={(option) => setFieldValue('destinationPort', option)}
                            className="input-register"
                            placeholder="Selecione o destino"
                          />
                          <ErrorMessage component="p" className="error-message-error" name="destinationPort" />
                        </Flex>

                        <Flex direction="column">
                          <LabelDefault name="dangerousClass" text="Classe" />
                          <Select
                            styles={selectCustomStyles()}
                            id="dangerousClass"
                            name="dangerousClass"
                            options={dangerousClassOptions}
                            value={values.dangerousClass}
                            onChange={(option) => setFieldValue('dangerousClass', option)}
                            className="input-register"
                            placeholder="Selecione a classe"
                          />
                          <ErrorMessage component="p" className="error-message-error" name="dangerousClass" />
                        </Flex>

                        <Flex direction="column">
                          <LabelDefault name="incoterm" text="Incoterm" />
                          <Select
                            options={incotermOptions}
                            styles={selectCustomStyles()}
                            value={values.incoterm}
                            onChange={(option) => {
                              setFieldValue('incoterm', option);
                              setRequiredList({
                                ...requiredList,
                                incoterm: option?.value,
                              });
                            }}
                            className="input-register"
                            placeholder="Selecione o incoterm"
                          />
                          <ErrorMessage component="p" className="error-message-error" name="incoterm" />
                        </Flex>

                        <Flex direction="column">
                          <LabelDefault name="inlandZipCode" text="Inland zip code" />
                          <InputDefault
                            setFieldValue={setFieldValue}
                            value={values.inlandZipCode}
                            name="inlandZipCode"
                            placeholder="Preencha o inland zip code"
                          />
                          <ErrorMessage component="p" className="error-message-error" name="inlandZipCode" />
                        </Flex>

                        <Flex direction="column">
                          <LabelDefault name="currency" text="Moeda" />

                          <Select
                            styles={selectCustomStyles()}
                            id="currency"
                            name="currency"
                            placeholder="Selecione uma moeda"
                            options={currency.map((item) => ({ value: item.code, label: item.name }))}
                            value={values.currency}
                            onChange={(option) => setFieldValue('currency', option)}
                          />
                          <ErrorMessage component="p" className="error-message-error" name="inlandZipCode" />
                        </Flex>

                        <Flex direction="column">
                          <LabelDefault name="valueOfTheMerchandise" text="Valor mercadoria" />

                          <NumericFormat
                            placeholder="R$0,00"
                            className="custom-input-mask"
                            type="text"
                            displayType="input"
                            thousandSeparator="."
                            decimalSeparator=","
                            thousandsGroupStyle="thousand"
                            id="valueOfTheMerchandise"
                            name="valueOfTheMerchandise"
                            value={values.valueOfTheMerchandise}
                            decimalScale={2}
                            allowNegative={true}
                            fixedDecimalScale={true}
                            onValueChange={(values) => {
                              const { floatValue } = values;
                              setFieldValue('valueOfTheMerchandise', floatValue);
                            }}
                          />
                          <ErrorMessage component="p" className="error-message-error" name="valueOfTheMerchandise" />
                        </Flex>

                        <Flex direction="column">
                          <LabelDefault name="weigth" text="Peso bruto (kg)" />

                          <NumericFormat
                            placeholder="Preencha o peso bruto"
                            className="custom-input-mask"
                            type="text"
                            displayType="input"
                            thousandSeparator="."
                            decimalSeparator=","
                            thousandsGroupStyle="thousand"
                            id="weigth"
                            name="weigth"
                            value={values.weigth}
                            decimalScale={2}
                            allowNegative={true}
                            fixedDecimalScale={true}
                            onValueChange={(values) => {
                              const { floatValue } = values;
                              setFieldValue('weigth', floatValue);
                            }}
                          />

                          <ErrorMessage component="p" className="error-message-error" name="weigth" />
                        </Flex>

                        <Flex direction="column">
                          <LabelDefault name="invoiceValue" text="Valor invoice" />
                          <NumericFormat
                            placeholder="Preencha o valor da invoice"
                            className="custom-input-mask"
                            type="text"
                            thousandsGroupStyle="thousand"
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="input"
                            name="invoiceValue"
                            id="invoiceValue"
                            decimalScale={2}
                            allowNegative={true}
                            fixedDecimalScale={true}
                            value={values.invoiceValue}
                            onValueChange={(values) => {
                              const { floatValue } = values;
                              setFieldValue('invoiceValue', floatValue);
                            }}
                          />

                          <ErrorMessage component="p" className="error-message-error" name="invoiceValue" />
                        </Flex>

                        <Flex direction="column">
                          <LabelDefault name="isPacklistReceived" text="Packlist liberado" />
                          <Select
                            styles={selectCustomStyles()}
                            options={yesOrNoOptions}
                            name="isPacklistReceived"
                            value={values.isPacklistReceived}
                            onChange={(option) => setFieldValue('isPacklistReceived', option)}
                            className="input-register"
                            placeholder="Selecione se é Packlist liberado"
                          />
                          <ErrorMessage component="p" className="error-message-error" name="isPacklistReceived" />
                        </Flex>
                      </Grid>

                      <Grid templateColumns="repeat(5, 1fr)" gap="40px" mt="40px">
                        <Flex direction="column">
                          <LabelDefault name="quantity" text="Quantidade" />

                          <NumericFormat
                            className="custom-input-mask"
                            name="quantity"
                            id="quantity"
                            thousandsGroupStyle="thousand"
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="input"
                            type="text"
                            allowNegative={true}
                            decimalScale={false}
                            fixedDecimalScale={true}
                            value={values.quantity}
                            onValueChange={(values) => {
                              const { floatValue, value } = values;
                              setFieldValue('quantity', floatValue);
                              setQuantity(value);
                            }}
                          />

                          <ErrorMessage component="p" className="error-message-error" name="quantity" />
                        </Flex>

                        <Flex direction="column">
                          <LabelDefault name="height" text="Altura (cm)" />

                          <NumericFormat
                            className="custom-input-mask"
                            type="text"
                            thousandsGroupStyle="thousand"
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="input"
                            name="height"
                            id="height"
                            decimalScale={2}
                            allowNegative={true}
                            fixedDecimalScale={true}
                            value={values.height}
                            onValueChange={(values) => {
                              const { floatValue, value } = values;
                              setFieldValue('height', floatValue);
                              setHeight(value);
                            }}
                          />

                          <ErrorMessage component="p" className="error-message-error" name="height" />
                        </Flex>

                        <Flex direction="column">
                          <LabelDefault name="width" text="Largura (cm)" />

                          <NumericFormat
                            className="custom-input-mask"
                            type="text"
                            thousandsGroupStyle="thousand"
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="input"
                            name="width"
                            id="width"
                            decimalScale={2}
                            allowNegative={true}
                            fixedDecimalScale={true}
                            value={values.width}
                            onValueChange={(values) => {
                              const { floatValue, value } = values;
                              setFieldValue('width', floatValue);
                              setWidth(value);
                            }}
                          />

                          <ErrorMessage component="p" className="error-message-error" name="width" />
                        </Flex>

                        <Flex direction="column">
                          <LabelDefault name="length" text="Comprimento (cm)" />

                          <NumericFormat
                            className="custom-input-mask"
                            type="text"
                            thousandsGroupStyle="thousand"
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="input"
                            name="length"
                            id="length"
                            decimalScale={2}
                            allowNegative={true}
                            fixedDecimalScale={true}
                            value={values.length}
                            onValueChange={(values) => {
                              const { floatValue, value } = values;
                              setFieldValue('length', floatValue);
                              setLength(value);
                            }}
                          />

                          <ErrorMessage component="p" className="error-message-error" name="length" />
                        </Flex>

                        <Flex direction="column">
                          <Flex gap="5px">
                            <LabelDefault name="cbmCubicMeters" text="Cubagem (m&#179;)" />

                            <Tooltip label="Quantidade * (altura * largura * comprimento) * fator de cubagem. Valores em metros. Fator cubagem (1 metro cúbico é igual a 1.000 kg)">
                              <Box>
                                <MdHelp color="#422C76" />
                              </Box>
                            </Tooltip>
                          </Flex>

                          <NumericFormat
                            className="custom-input-mask"
                            type="text"
                            thousandsGroupStyle="thousand"
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="input"
                            name="cbmCubicMeters"
                            id="cbmCubicMeters"
                            readOnly="readOnly"
                            decimalScale={2}
                            allowNegative={true}
                            fixedDecimalScale={true}
                            data-initial={true}
                            value={cubageFactor}
                            onValueChange={(values) => {
                              const { floatValue } = values;
                              setFieldValue('cbmCubicMeters', floatValue);
                            }}
                          />

                          <ErrorMessage component="p" className="error-message-error" name="cbmCubicMeters" />
                        </Flex>
                      </Grid>

                      <Flex direction="column" mt="40px">
                        <FormControl>
                          <LabelDefault
                            text="Relação dos NCM's"
                            style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end', gap: '10px' }}>
                            <Tooltip label="Tributação será aplicada conforme Legislação" placement="auto-start">
                              <Box>
                                <MdHelp color="#422C76" />
                              </Box>
                            </Tooltip>
                          </LabelDefault>

                          <Select
                            id="ncms"
                            name="ncms"
                            className="outline-none transform-none text-black"
                            placeholder="Digite os quatro primeiros digitos do código NCM. ex: 0101"
                            styles={selectCustomStyles()}
                            value=""
                            options={ncmOptions}
                            onChange={(option) => {
                              setFieldValue('ncms', [
                                ...inputNcmsRelations,
                                {
                                  code: option.value,
                                  description: option.label,
                                  tariff: '',
                                },
                              ]);
                              setInputNcmsRelations([
                                ...inputNcmsRelations,
                                {
                                  code: option.value,
                                  description: option.label,
                                  tariff: '',
                                },
                              ]);
                              setNcmOptions([]);
                            }}
                            onInputChange={(inputValue) => {
                              listOperationNcmsAction(inputValue);
                            }}
                            noOptionsMessage={() => noNcmOptionsMessage}
                            isLoading={isLoadingNcmOptions}
                          />
                          <ErrorMessage component="p" className="error-message-error" name="ncms" />
                        </FormControl>

                        <Flex mt="5px">
                          <i>
                            {"NCM's"} selecionados ({inputNcmsRelations?.length})
                          </i>
                        </Flex>

                        {inputNcmsRelations?.length > 0 &&
                          inputNcmsRelations.map((ncm, index) => (
                            <Grid
                              key={index}
                              templateColumns="repeat(6, 1fr)"
                              alignItems="center"
                              gap="20px"
                              border="1px"
                              bgColor="#70707033"
                              borderColor="#70707033"
                              p="10px"
                              mt="10px">
                              <GridItem colSpan={3}>
                                {ncm?.description?.length > 250 ? (
                                  <Text>
                                    {ncm.description.substring(0, 250)}
                                    <Tooltip label={ncm.description}>[...]</Tooltip>
                                  </Text>
                                ) : (
                                  <p className="mt-5">{ncm.description}</p>
                                )}
                              </GridItem>

                              <GridItem colSpan={2}>
                                <InputDefault
                                  name={`ncms[${index}].tariff`}
                                  id={`ncms[${index}].tariff`}
                                  onChange={(e) => setFieldValue(`ncms[${index}].tariff`, e.target.value)}
                                  value={values.ncms[index]?.tariff}
                                  placeholder="Preencha o EX tarifário ou deixe em branco"
                                />
                              </GridItem>

                              <Button
                                textAlign="end"
                                w="min-content"
                                onClick={() =>
                                  handleRemoveChange(
                                    setFieldValue,
                                    index,
                                    values.ncms,
                                    'ncms',
                                    inputNcmsRelations,
                                    setInputNcmsRelations,
                                    'operational'
                                  )
                                }>
                                <MdDelete color="#E52359" />
                              </Button>
                            </Grid>
                          ))}
                      </Flex>

                      <Grid templateColumns="repeat(4, 1fr)" gap="40px" mt="40px">
                        <GridItem colSpan={2}>
                          <Flex direction="column">
                            <LabelDefault name="description" text="Observação (opcional)" />

                            <Textarea
                              name="description"
                              height="180px"
                              resize="none"
                              bg="#F2F2F2"
                              value={values.description}
                              onChange={(event) => setFieldValue('description', event.target.value)}
                            />

                            <ErrorMessage component="p" className="error-message-error" name="description" />
                          </Flex>
                        </GridItem>

                        <GridItem colSpan={2}>
                          <Flex direction="column">
                            <Grid templateColumns="repeat(2, 1fr)" gap="40px">
                              <Flex direction="column">
                                <LabelDefault name="copyPeople3Email" text="Contato cópia 1 (e-mail)" />

                                <InputDefault
                                  setFieldValue={setFieldValue}
                                  value={values.copyPeople3Email}
                                  type="email"
                                  name="copyPeople3Email"
                                  placeholder="Preencha o contato cópia 1 (e-mail)"
                                />

                                <ErrorMessage component="p" className="error-message-error" name="copyPeople3Email" />
                              </Flex>

                              <Flex direction="column">
                                <LabelDefault name="copyPeople2" text="Contato cópia 2 (e-mail)" />

                                <Select
                                  styles={selectCustomStyles()}
                                  name="copyPeople2"
                                  value={values.copyPeople2}
                                  className="input-register"
                                  placeholder="Selecone o contato cópia 2 (e-mail)"
                                  onChange={(option) => {
                                    setFieldValue('copyPeople2', option);
                                  }}
                                  options={userOptions}
                                />

                                <ErrorMessage component="p" className="error-message-error" name="copyPeople2" />
                              </Flex>
                            </Grid>
                          </Flex>
                        </GridItem>
                      </Grid>
                    </Box>
                  </Card>

                  {values.modality.label?.includes('Marítimo') && (
                    <Card
                      m="10px"
                      header={
                        <>
                          {values?.ctrs?.length > 0 && (
                            <Flex gap="2px" direction="column">
                              <Text textStyle="cardTitle" fontWeight="bold" color="primary">
                                Containers
                              </Text>
                              <Flex gap="2px">
                                <Text textStyle="subtitle" color="#BEBEBE" pt="3px">
                                  Quantidade:
                                </Text>
                                <Text textStyle="cardTitle" fontWeight="bold" color="primary" ml="5px">
                                  {values.ctrs.length}
                                </Text>
                              </Flex>
                            </Flex>
                          )}
                        </>
                      }>
                      <Box className="down-anim" justify="space-between" pl="60px" pr="60px" pt="30px" pb="30px">
                        {values?.ctrs?.length > 0 &&
                          values.ctrs.map((ctr, index) => (
                            <>
                              <Grid templateColumns="repeat(12, 1fr)" gap="40px" key={index}>
                                <GridItem colSpan={2} mt={index > 0 ? `30px` : ''}>
                                  <Flex direction="column">
                                    <LabelDefault name="qtt" text={`Quantidade CTR ${index + 1}`} />
                                    <InputDefault
                                      setFieldValue={setFieldValue}
                                      value={values.ctrs[index]?.qtt}
                                      name={`ctrs[${index}].qtt`}
                                      type="number"
                                      placeholder="Preencha a quantidade"
                                    />
                                    <ErrorMessage component="p" className="error-message-error" name={`ctrs[${index}].qtt`} />
                                  </Flex>
                                </GridItem>
                                <GridItem colSpan={2} mt={index > 0 ? `30px` : ''}>
                                  <Flex direction="column">
                                    <LabelDefault name="size" text={`Tamanho ${index + 1}`} />
                                    <Select
                                      styles={selectCustomStyles()}
                                      options={sizeCtrOptions}
                                      name={`ctrs[${index}].size`}
                                      value={values.ctrs[index]?.size}
                                      onChange={(option) => setFieldValue(`ctrs[${index}].size`, option)}
                                      className="input-register"
                                      placeholder="Selecione o tamanho"
                                    />
                                    <ErrorMessage component="p" className="error-message-error" name={`ctrs[${index}].size`} />
                                  </Flex>
                                </GridItem>
                                <GridItem colSpan={2} mt={index > 0 ? `30px` : ''}>
                                  <Flex direction="column">
                                    <LabelDefault name="type" text={`Tipo ${index + 1}`} />
                                    <Select
                                      styles={selectCustomStyles()}
                                      options={typeCtrOptions}
                                      name={`ctrs[${index}].type`}
                                      value={values.ctrs[index]?.type}
                                      onChange={(option) => setFieldValue(`ctrs[${index}].type`, option)}
                                      className="input-register"
                                      placeholder="Selecione o tipo"
                                    />
                                    <ErrorMessage component="p" className="error-message-error" name={`ctrs[${index}].type`} />
                                  </Flex>
                                </GridItem>
                                {index > 0 && (
                                  <Flex alignItems="center" mt="50px">
                                    <Tooltip label="Excluir">
                                      <IconButton
                                        icon={<MdDeleteOutline size={30} />}
                                        color="#AFAEB4"
                                        bgColor="transparent"
                                        cursor="pointer"
                                        _hover={{ color: '#E74C3C' }}
                                        onClick={() => handleRemoveCtr(values, ctr.id, setFieldValue)}
                                      />
                                    </Tooltip>
                                  </Flex>
                                )}
                              </Grid>
                            </>
                          ))}
                        {values?.ctrs?.length > 0 && (
                          <>
                            <Button
                              mt="30px"
                              w="168px"
                              h="47px;"
                              mb="30px"
                              bgColor="#2ECC7152"
                              border="1px dashed #2ECC71"
                              borderColor="#2ECC7152"
                              borderRadius="5px"
                              textColor="#2ECC71"
                              fontSize="16px"
                              py="6px"
                              _hover={{ bgColor: 'transparent', shadow: 'lg' }}
                              leftIcon={<MdAdd color="#2ECC71" size={15} />}
                              onClick={() => handleAddCtrs(values, setFieldValue)}>
                              Adicionar
                            </Button>
                            <Grid templateColumns="repeat(12, 1fr)" gap="40px" mt="20px">
                              <GridItem colSpan={6}>
                                <Flex direction="column">
                                  <LabelDefault name="description" text="Observação (opcional)" />
                                  <Textarea height="180px" resize="none" bg="#F2F2F2" values={values.quoteObservations} />
                                </Flex>
                              </GridItem>
                            </Grid>
                          </>
                        )}
                      </Box>
                    </Card>
                  )}

                  {entity.identifier ? (
                    <FloatActionButton
                      options={[
                        <>
                          <LogHistoryDrawer
                            entity={logHistory}
                            meta={logHistoryMeta}
                            setMeta={setLogHistoryMeta}
                            action={action}
                            setAction={setAction}
                          />

                          <Button
                            rightIcon={<MdOutlineFileCopy color="#FFFFFF" size={20} />}
                            h="46px"
                            px="24px"
                            py="14px"
                            w="fit-content"
                            bgColor="green"
                            color="#FFFFFF"
                            borderRadius="27px"
                            _hover={{ bgColor: '#70D499' }}
                            onClick={() => {
                              handleDuplicateQuote(entity);
                            }}>
                            <Text>Duplicar cotação</Text>
                          </Button>

                          <Button
                            rightIcon={<MdOutlineSave color="#FFFFFF" size={20} />}
                            type="submit"
                            w="fit-content"
                            h="46px"
                            px="24px"
                            py="14px"
                            bgColor="primary"
                            color="#FFFFFF"
                            borderRadius="27px"
                            _hover={{ bgColor: '#8067DC' }}
                            isLoading={isSubmitting}
                            loadingText="Editando...">
                            <Text>Salvar</Text>
                          </Button>
                        </>,
                      ]}
                      isOpen={isOpenFloatButton}
                      setIsOpen={setIsOpenFloatButton}
                    />
                  ) : (
                    <FloatActionButton
                      options={[
                        <>
                          <Button
                            rightIcon={<MdOutlineSave color="#FFFFFF" size={20} />}
                            type="submit"
                            h="46px"
                            px="24px"
                            py="14px"
                            bgColor="primary"
                            color="#FFFFFF"
                            borderRadius="27px"
                            _hover={{ bgColor: '#8067DC' }}
                            isLoading={isSubmitting}
                            loadingText="Salvando">
                            <Text>Salvar</Text>
                          </Button>
                        </>,
                      ]}
                      isOpen={isOpenFloatButton}
                      setIsOpen={setIsOpenFloatButton}
                    />
                  )}
                </form>
              )}
            </Formik>
          </Box>
        )}
      </Layout>
    </>
  );
};

export default QuoteDetailPage;
