import React from 'react';

import { InfoWindow } from '@react-google-maps/api';
import { BsSpeedometer } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import { Box, Flex, Image, Text } from '@chakra-ui/react';

import document from '../../assets/images/document.svg';

const MapPopup = ({ point, onCloseClick }) => {
  const infoWindowOffset = { x: 0, y: -5 };

  function generateLabel(label) {
    switch (label) {
      case 'code':
        return 'Viagem';
      case 'address':
        return 'Local';
      case 'lat':
        return 'Latitude';
      case 'lng':
        return 'Longitude';
      case 'date':
        return 'Data';
      case 'driver':
        return 'Motorista';
      case 'vehicle':
        return 'Veículo';
      case 'description':
        return 'Descrição';
      case 'speed':
        return 'Velocidade';
      case 'cte':
        return 'CT-e';
      case 'plate':
        return 'Placa';
      default:
        return 'Não definido';
    }
  }

  const svgCode = `
    ${process.env.PUBLIC_URL}${point.header.icon}
  `;

  const temSVG = /<svg\b/i.test(point?.header?.icon);

  return (
    <InfoWindow
      position={point}
      onCloseClick={onCloseClick}
      options={{
        pixelOffset: new window.google.maps.Size(infoWindowOffset.x, infoWindowOffset.y),
      }}
      style={{ padding: '0px' }}>
      <Flex>
        <style>
          {`
            [role="dialog"] svg {
              height: 50px;
            }
          `}
        </style>

        {temSVG ? <Flex dangerouslySetInnerHTML={{ __html: svgCode }} w="12" mr="4" /> : <Image src={svgCode} />}

        <Flex className="info-window-content" direction="column" align="flex-start" w="full">
          <Flex w="full" align="flex-end" mb="3">
            <Text className="up-anim" fontSize="1rem" fontWeight="bold">
              {point?.header?.title}
            </Text>
          </Flex>

          {point?.content != null &&
            Object.entries(point.content)?.map(([key, item]) => {
              if (item == '') return;
              return (
                <Flex key={key} gap="5px" align="center" mb="1">
                  <Text fontWeight="bold">{generateLabel(key)}:</Text>
                  <Text>{item ?? '-'}</Text>
                </Flex>
              );
            })}
          {point?.processes != null &&
            Object.entries(point.processes).map(([key, item]) => {
              return (
                <Flex key={key} justifyContent="space-between" mb="1" w="130px">
                  <Text>{item.code}</Text>
                  <Flex direction="row" justify="flex-end">
                    <Link to={`/comex/process/detail/${item.identifier}`} target="_blank" title="Visualizar processo">
                      <Image
                        w="18px"
                        src={document}
                        alt="Detalhes"
                        filter="saturate(0)"
                        cursor="pointer"
                        transition="0.2s"
                        _hover={{
                          filter: 'saturate(1)',
                        }}
                      />
                    </Link>
                  </Flex>
                </Flex>
              );
            })}
          {point?.speed != null && point?.speed > 0 && (
            <Box border="1px" title="Velocidade" borderRadius="5px" borderColor="#cccccc" bgColor="#f5f5f5" mt="6" px="4" py="2">
              <Flex gap=".5rem">
                <BsSpeedometer />
                <Text>{point?.speed} km/h</Text>
              </Flex>
            </Box>
          )}
        </Flex>
      </Flex>
    </InfoWindow>
  );
};

export default MapPopup;
