import React from 'react';

import { MdOutlineExpandLess, MdOutlineExpandMore, MdOutlineUnfoldMore } from 'react-icons/md';

import { Flex, Text, Th } from '@chakra-ui/react';

const ArrowIcon = ({ isActive, direction }) => {
  if (!isActive) {
    return <MdOutlineUnfoldMore size={20} />;
  }
  return direction === 'ASC' ? <MdOutlineExpandMore size={20} /> : <MdOutlineExpandLess size={20} />;
};

const SortableTableHeader = ({ column, label, sorting, handleSort }) => {
  const isActive = sorting?.column === column;

  return (
    <Th>
      <Flex
        direction="row"
        align="center"
        onClick={() => handleSort(column, isActive && sorting?.direction === 'ASC' ? 'DESC' : 'ASC')}
        cursor="pointer">
        <Text>{label}</Text>

        <ArrowIcon isActive={isActive} direction={sorting?.direction} />
      </Flex>
    </Th>
  );
};

export default SortableTableHeader;
