import React from 'react';

import { MdCircle, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Flex, Icon, Image, Td, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';

import document from '../../../../../../../../assets/images/document.svg';
import {
  convertColorTrip,
  descriptionStatusTrip,
  modalityIcon,
  modalityTitle,
} from '../../../../../../../../utils/translate';
import DocumentModalView from '../../../../../../CurrentTrips/components/Documents/DocumentModalView';

import CteDetails from './CteDetails';

const TavelListCollapse = ({ entity }) => {
  const { isOpen, onToggle } = useDisclosure();
  const getIcon = isOpen ? <MdKeyboardArrowUp color="#6C48C2" /> : <MdKeyboardArrowDown color="#6C48C2" />;

  const getColor = (modality) => {
    if (modality == 'FRETE_NAO_RASTREAVEL') {
      return '#f1c40f';
    } else if (modality == 'FRETE_RASTREAVEL') {
      return '#bababa';
    }

    return '#a885f9';
  };

  return (
    <>
      <Tr h="64px" className="border">
        <Td cursor="pointer" border="none" onClick={onToggle}>
          <Box>{getIcon}</Box>
        </Td>
        <Td cursor="pointer" border="none" title={modalityTitle(entity.modality)} onClick={onToggle}>
          <Box>
            <Box h="1.1rem">
              <Icon as={modalityIcon(entity?.modality)} color={getColor(entity?.modality)} w="1.1rem" h="1.1rem" />
            </Box>
          </Box>
        </Td>
        <Td cursor="pointer" border="none" textColor="linkColor" textStyle="subtitle" onClick={onToggle}>
          <Box>#{entity?.code}</Box>
        </Td>
        <Td cursor="pointer" border="none" textStyle="subtitle" textColor="#0F0A1DB3" onClick={onToggle}>
          <Box>{entity?.startsAtFormatted}</Box>
        </Td>
        <Td cursor="pointer" border="none" textStyle="subtitle" textColor="#0F0A1DB3" onClick={onToggle}>
          <Box>{entity?.endsAtFormatted}</Box>
        </Td>
        <Td cursor="pointer" border="none" textStyle="subtitle" bgColor={'transparent'}>
          <Box>
            {entity.qttFiles > 0 ? (
              <DocumentModalView identifier={entity.identifier} code={entity.code} />
            ) : (
              <small>Sem arquivos</small>
            )}
          </Box>
        </Td>
        <Td border="none" textStyle="subtitle">
          <Box>
            <Flex
              align="center"
              gap="10px"
              py="5px"
              px="20px"
              borderRadius="15px"
              bgColor={convertColorTrip(entity.status)}
              color="white"
              h="29px">
              <MdCircle size={7} />
              {descriptionStatusTrip(entity.status)}
            </Flex>
          </Box>
        </Td>
        <Td border="none" /*  w="50%"*/>
          <Flex direction="row" gap="20px" align="center" justify="flex-end">
            <Tooltip label="Detalhe da viagem">
              <Link to={`/logistic/trip/detail/${entity.identifier}`} state={entity}>
                <Image
                  w="18px"
                  src={document}
                  alt="Detalhes"
                  filter="saturate(0)"
                  cursor="pointer"
                  transition="0.2s"
                  _hover={{
                    filter: 'saturate(1)',
                  }}
                />
              </Link>
            </Tooltip>
          </Flex>
        </Td>
      </Tr>

      <Tr>
        <Td p="0" colSpan="8" border="none">
          <Box overflowX="auto">
            <CteDetails isOpen={isOpen} trip={entity} />
          </Box>
        </Td>
      </Tr>
    </>
  );
};

export default TavelListCollapse;
