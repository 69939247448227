import { Box, Card, Collapse, Flex, Grid, GridItem, Heading, List, ListItem, Text } from '@chakra-ui/react';

import CommonList from './CommonList';

const CommonListCollapsedTable = ({ ...props }) => {
  const { subList, subcategory } = props;
  console.log(subList);
  return <CommonList hiddenCategories={['createdAt']} hasCollapsed={true} list={subList} isSubcategories={true}></CommonList>;
};

export default CommonListCollapsedTable;
