import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Select,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';

import requests from '../../../../services/requests';

import ProcessChatMessage from './ProcessChatMessage';

const ProcessCommentsDrawer = ({ process, comment }) => {
  const [selectedOption, setSelectedOption] = useState(process?.currentStageModalitySequential);
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const [textareaValue, setTextareaValue] = useState('');
  const [comments, setComments] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  let navigate = useNavigate();

  const flagClick = (e) => {
    e.stopPropagation(); // isso impede a propagação do evento que vai para a página de detalhes do processo
    process.qttComment > 1 ? onOpen() : null;
  };

  const toRoute = (to) => {
    navigate(to);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm();

  const getComments = async () => {
    if (!process || !process.identifier) {
      return;
    }

    await requests.getProcessComments(process.identifier).then((data) => {
      setComments(data.data);
    });
  };

  const sendComment = async () => {
    setIsLoadingSaveData(true);
    if (textareaValue === '') {
      return setIsLoadingSaveData(false);
    }

    await requests
      .addProcessStageComment(process.identifier, selectedOption, {
        description: textareaValue,
        hasBeenRead: 0,
      })
      .then(async () => {
        setTextareaValue('');
        clearErrors();
        getComments();
        toast.success('Enviado com sucesso!');
      })
      .catch(() => {
        toast.error('Erro ao enviar o comentário, tente novamente.a');
      })
    ;
    setIsLoadingSaveData(false);
  };

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    getComments();
  }, [process]);

  return (
    <>
      {process && (
        <>
          <Tooltip label={process.qttComment > 0 ? 'Visualizar comentários' : 'Sem comentário'}>
            <Flex
              alignItems="center"
              gap="5px"
              cursor="pointer"
              opacity={process.qttComment === 0 ? '.1' : '1'}
              onClick={flagClick.bind(this)}
              ref={btnRef}>
              <Image src={comment} />
              <Text textStyle="span" color="#8A8D93">
                {process.qttComment}
              </Text>
            </Flex>
          </Tooltip>

          <Drawer isOpen={isOpen} onClose={onClose} placement="right" finalFocusRef={btnRef}>
            <DrawerOverlay />

            <DrawerContent maxW={'100% !important'} w={{ base: "initial", md:"50% !important" }} px="30px" py="30px">
              <DrawerCloseButton />

              <Flex justify="space-between" align="center" mt="10px">
                <Flex direction="column" gap="5px">
                  <Flex align="center" textStyle="tableTitle" gap="5px">
                    <Text textColor="primary" fontWeight="bold">
                      Comentários:
                    </Text>
                    <Text textColor="linkColor" fontWeight="medium">
                      {process.qttComment}
                    </Text>
                  </Flex>

                  <Flex dire align="center" textStyle="subtitle" textColor="#BEBEBE" gap="5px">
                    <Text>Processo:</Text>
                    <Text>{process.code}</Text>
                  </Flex>
                </Flex>

                <Button variant="secundary" onClick={() => toRoute(`/comex/process/detail/${process.identifier}`)}>
                  Ver processo
                </Button>
              </Flex>

              <Box mt="15px">
                <form onSubmit={handleSubmit(sendComment)}>
                  <Flex w="full" align="center" justify="flex-end">
                    <Flex w="85%">
                      <Textarea
                        w="100%"
                        h="125px"
                        bg="#F2F2F2"
                        resize="none"
                        border="1px solid #7070703B"
                        placeholder="Escreva sua mensagem..."
                        {...register('text', { required: true })}
                        name="text"
                        value={textareaValue}
                        onChange={handleTextareaChange}
                      />
                    </Flex>
                    <Button variant="primary" isLoading={isLoadingSaveData} loadingText="Enviando" type="submit" ml="20px">
                      Enviar
                    </Button>
                  </Flex>
                  <Flex w="89%" justify="flex-end">
                    {errors['text'] && <Text textStyle="error">Campo obrigatório.</Text>}
                  </Flex>
                  <Flex w="89%" mt="7px" justify="flex-end">
                    {process.currentStageModalitySequential && (
                      <Flex direction="column">
                        <Select
                          variant="primary"
                          placeholder="Selecione a etapa"
                          w="fit-content"
                          {...register('stage', { required: true })}
                          name="stage"
                          defaultValue={process.currentStageModalitySequential}
                          onChange={handleSelectChange}>
                          <option value="1">Pré embarque</option>
                          <option value="2">Trânsito</option>
                          <option value="3">Desembaraço</option>
                          <option value="4">Faturamento</option>
                          <option value="5">Transporte</option>
                          <option value="6">Entrega</option>
                        </Select>
                        {errors['stage'] && <Text textStyle="error" textAlign="right">Campo obrigatório.</Text>}
                      </Flex>
                    )}
                  </Flex>
                </form>
              </Box>

              <DrawerBody p="0" mt="20px">
                <Flex direction="column" gap="15px">
                  {comments.map((comment) => (
                    <ProcessChatMessage entity={comment} key={comment.identifier} />
                  ))}
                </Flex>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      )}
    </>
  );
};

export default ProcessCommentsDrawer;
