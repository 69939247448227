import React from 'react';

import { Link } from 'react-router-dom';

import { Flex, Image, Text, Tooltip } from '@chakra-ui/react';

import gestao from '../../assets/images/gestao-ball.png';
import manual from '../../assets/images/proprio-ball.png';
import api from '../../assets/images/vendemmia-ball.png';
import UpdateInfoTag from '../../pages/dashboard/components/UpdateInfoTag';
import { limitChars } from '../../utils/strings';

const tagVendemmiaBorder = {
    borderColor: 'primary'
}

const tagManagerBorder = {
    borderColor: 'myoga'
}

const CalendarMonthItem = ({ entity }) => {

  const type = entity?.originDescription

  const getIcon = () => {
    switch (entity.originDescription) {
      case 'api':
        return api;
      case 'manual':
        return manual;
      case 'gestao':
        return gestao;
      default:
        return api;
    }
  };

  const variantBg = type !== 'gestao' ? "primary" : "myoga"
  const variantBorder = type === 'gestao' ? tagManagerBorder : tagVendemmiaBorder

  return (
    <Link
      to={`/comex/process/detail/${entity.processIdentifier}`}
      style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
      className="up-anim">
      <Flex
        direction="column"
        p="7px 14px"
        bg="#F5F5F5"
        w="100%"
        borderRadius="8px"
        transition={'.3s'}
        borderLeftWidth={"5px"}
        borderStyle={"solid"}
        _hover={{ transform: 'scale(1.05)', boxShadow: '0px 2px 20px #00000044' }}
        {...variantBorder}
        >
        
        <Flex mb="6px" align="center" gap="10px">
          <Image src={getIcon()} borderRadius="100%" w="21px" h="21px" />

          <Text 
            textStyle="subtitle" 
            textColor="#0F0A1D"
            fontSize={"12px"}
          >
            {entity.stageDescription}
          </Text>
        </Flex>
        <Flex gap="4px" w="full" fontSize={"10px"}>
          <UpdateInfoTag 
            important={true} 
            isVendemmia={entity.originDescription !== 'api'} 
            fontSize="10px"
            getColor={variantBg}
          >
            {entity.processCode}
          </UpdateInfoTag>

          {entity.clientInternalCode && (
            <UpdateInfoTag
              fontSize="10px"
              getColor="#6794DC"
            >
              <Tooltip label={entity.clientInternalCode} aria-label={entity.clientInternalCode}>
                {limitChars(entity.clientInternalCode, 8)}
              </Tooltip>
            </UpdateInfoTag>
          )}
        </Flex>
      </Flex>
    </Link>
  );
};

export default CalendarMonthItem;