import React from 'react';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { MdMenu } from 'react-icons/md';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Text,
} from '@chakra-ui/react';

const DragAndDropConfigFilter = ({ setFilterFields, filterFields }) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const fieldsAux = reorder(filterFields, result.source.index, result.destination.index);
    setFilterFields(fieldsAux);
  };

  const onDragEndChildrens = (result, fatherIndex) => {
    if (!result.destination) {
      return;
    }

    const fieldsAux = reorder(filterFields[fatherIndex].subFields, result.source.index, result.destination.index);
    const aux = [...filterFields];
    aux[fatherIndex].subFields = fieldsAux;
    setFilterFields(aux);
  };

  const toggleCheckbox = (fatherIndex, subIndex) => {
    const aux = [...filterFields];
    if (subIndex === undefined) {
      aux[fatherIndex].active = !aux[fatherIndex].active;
      aux[fatherIndex].subFields = aux[fatherIndex].subFields.map((subField) => {
        subField.active = !aux[fatherIndex].active;
        return subField;
      });
    } else {
      aux[fatherIndex].subFields[subIndex].active = !aux[fatherIndex].subFields[subIndex].active;
    }
    setFilterFields(aux);
  };

  return (
    <FormControl direction="column">
      <FormLabel textStyle="tableTitle" textColor="primary" fontWeight="bold">
        Campos/colunas da tabela
      </FormLabel>

      <Accordion allowToggle>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="characters">
            {(provided) => (
              <ul
                className="characters"
                style={{
                  border: '1px solid #A885F99C',
                  padding: '20px',
                  overflowY: 'auto',
                  height: '332px',
                  width: '60%',
                }}
                {...provided.droppableProps}
                ref={provided.innerRef}>
                {filterFields.map((field, index) => {
                  return (
                    <Draggable id={field.id} key={field.id} draggableId={field.id} index={index}>
                      {(provided) => (
                        <AccordionItem border="none">
                          <li ref={provided.innerRef} {...provided.draggableProps}>
                            <Flex bgColor="#EEEEEE" p="12px" align="center">
                              <Flex w="min-content" {...provided.dragHandleProps}>
                                <MdMenu size={22} />
                              </Flex>

                              <Flex w="full" ml="20px" gap="10px">
                                <Checkbox
                                  iconColor="primary"
                                  borderColor="primary"
                                  onChange={() => toggleCheckbox(index)}
                                  isChecked={field.active}
                                />

                                <Text textStyle="subtitle" textColor="primary">
                                  {field.name}
                                </Text>
                              </Flex>

                              <Flex justify="flex-end">
                                <AccordionButton _hover={{ bgColor: 'none' }} p="0">
                                  <AccordionIcon color="#422C76" />
                                </AccordionButton>
                              </Flex>
                            </Flex>

                            <DragDropContext onDragEnd={(result) => onDragEndChildrens(result, index)}>
                              <Droppable droppableId="childrens">
                                {(provided) => (
                                  <ul
                                    className="childrens"
                                    style={{
                                      width: '100%',
                                      display: 'flex',
                                      alignItems: 'flex-end',
                                      flexDirection: 'column',
                                      border: 'none',
                                      paddingBottom: '10px',
                                    }}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}>
                                    {field.subFields &&
                                      field.subFields.map((subField, subIndex) => {
                                        return (
                                          <Draggable id={subField.id} key={subField.id} draggableId={subField.id} index={subIndex}>
                                            {(provided) => (
                                              <AccordionPanel p="0" w="full" minW={{ base: '250px', xx: '400px' }} mt="10px">
                                                <li ref={provided.innerRef} {...provided.draggableProps}>
                                                  <Flex bgColor="#EEEEEE" p="12px" align="center">
                                                    <Flex w="min-content" {...provided.dragHandleProps}>
                                                      <MdMenu size={22} />
                                                    </Flex>

                                                    <Flex w="full" ml="20px" gap="10px">
                                                      <Checkbox
                                                        iconColor="primary"
                                                        borderColor="primary"
                                                        onChange={() => toggleCheckbox(index, subIndex)}
                                                        isChecked={subField.active}
                                                      />

                                                      <Text textStyle="subtitle" textColor="primary">
                                                        {subField.name}
                                                      </Text>
                                                    </Flex>
                                                  </Flex>
                                                </li>
                                              </AccordionPanel>
                                            )}
                                          </Draggable>
                                        );
                                      })}
                                    {provided.placeholder}
                                  </ul>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </li>
                        </AccordionItem>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </Accordion>
    </FormControl>
  );
};

export default DragAndDropConfigFilter;
