import React, { useState } from 'react';

import { toast } from 'react-toastify';

import { Box, Button, Flex, Input, Text } from '@chakra-ui/react';

import 'react-toastify/dist/ReactToastify.css';
import bg1 from '../../assets/images/login/bgs/bg-1.jpg';
import Loading from '../../components/Loading/Loading';
import BackSlider from '../../components/Login/BackSlider/BackSlider';
import CardRound from '../../components/Login/CardRound/CardRound';
import api from '../../services/api';

const RecoverPage = () => {
  const [email, setEmail] = useState(window.localStorage.getItem('profile-email'));
  const [step, setStep] = useState(1);
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [loading, setLoading] = useState(false);

  const renderButtonText = () => {
    switch (step) {
      case 1:
        return 'Enviar';
      case 2:
        return 'Salvar';
      case 3:
        return 'Fazer login';
      default:
        return 'Enviar';
    }
  };

  const bgs = [
    {
      urlBg: bg1,
      text: 'Vendemmia Logística Integrada',
      timeSecs: 10,
      topicId: 1,
      filter: 0,
    },
    {
      urlBg: bg1,
      text: 'Vendemmia Logística Integrada',
      timeSecs: 10,
      topicId: 2,
      filter: 0,
    },
    {
      urlBg: bg1,
      text: 'Vendemmia Logística Integrada',
      timeSecs: 10,
      topicId: 3,
      filter: 0,
    },
  ];

  return (
    <BackSlider slides={bgs}>
      <CardRound>
        <Flex flexDir="column" gap="20px">
          {step === 1 && (
            <Text textStyle="paragraph">
              Informe o e-mail cadastrado para receber instruções para a recuperação da sua conta
            </Text>
          )}

          {step === 2 && (
            <Text textStyle="paragraph">
              Se o seu e-mail estiver cadastrado, você irá receber um código para alterar sua senha
            </Text>
          )}

          {step === 1 && (
            <Box>
              <Input
                variant="flushed"
                placeholder="E-mail: "
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
              />
            </Box>
          )}

          {step === 2 && (
            <Flex flexDirection="column" gap="20px">
              <Box>
                <Input
                  id="code"
                  type="text"
                  variant="flushed"
                  placeholder="Insira o código recebido por email"
                  value={code}
                  autoComplete="off"
                  onChange={(e) => setCode(e.target.value.trim())}
                />
              </Box>
              <Box>
                <Input
                  id="newPassword"
                  type="password"
                  variant="flushed"
                  placeholder="Insira sua nova senha"
                  value={password}
                  onChange={(e) => setPassword(e.target.value.trim())}
                />
              </Box>
              <Box>
                <Input
                  id="confirmPassword"
                  className="focus:outline-none border-2 border-#EBEBEB py-1 px-2"
                  type="password"
                  variant="flushed"
                  placeholder="Confirme a senha"
                  onChange={(e) => setPasswordValidation(e.target.value.trim())}
                />
              </Box>
            </Flex>
          )}

          {step === 3 && <p className="text-C6 text-sm text-center px-10">Sua senha foi alterada com sucesso!</p>}

          <Flex flexDirection="row" justify="space-between" align="center">
            <Button
              variant="secundary"
              onClick={() => {
                if (step === 1) {
                  return (window.location.href = '/');
                } else {
                  setStep(step - 1);
                }
              }}>
              <Text textStyle="paragraph">Voltar</Text>
            </Button>

            <Button
              variant="primary"
              onClick={() => {
                if (step === 1) {
                  setLoading(true);
                  api
                    .post('recover/request', {
                      username: email,
                    })
                    .then((response) => {
                      toast.success('Código enviado para email informado!');
                      setStep(2);
                      setLoading(false);
                    })
                    .catch((error) => {
                      setLoading(false);
                    });
                } else if (step === 2) {
                  if (password !== passwordValidation) {
                    toast.error('As senhas não são iguais!');
                  } else {
                    setLoading(false);
                    api
                      .post('recover/send', {
                        code: code,
                        password: password,
                      })
                      .then((response) => {
                        toast.success('Senha alterada com sucesso!');
                        setStep(3);
                        setLoading(false);
                      })
                      .catch((error) => {
                        setLoading(false);
                      });
                  }
                } else if (step === 3) {
                  window.location.href = '/';
                }
              }}>
              <Text textStyle="paragraph">{renderButtonText()}</Text>
            </Button>
          </Flex>
        </Flex>
      </CardRound>
      <Loading status={loading} />
    </BackSlider>
  );
};

export default RecoverPage;
