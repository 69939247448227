import React, { useState } from 'react';

import { MdClose, MdHelpOutline } from 'react-icons/md';

import { Box, Button, Divider, Flex, IconButton, Modal, ModalContent, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react';

import '../../../styles/globals.css';

import HelpAnalyticsPanel from './components/HelpAnalyticsPanel';
import HelpPrivacyPolicy from './components/HelpPrivacyPolicy';
import HelpTermUse from './components/HelpTermUse';

const FAQ = ({ selectedContent, customButton }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedSection, setSelectedSection] = useState(selectedContent);

  const buttons = [
    {
      name: 'Ajuda',
      title: 'Ajuda',
      content: <HelpAnalyticsPanel />,
    },
    {
      name: 'Termos de Uso',
      title: 'Termos de Uso',
      content: <HelpTermUse />,
    },
    {
      name: 'Políticas de privacidade',
      title: 'Políticas de privacidade',
      content: <HelpPrivacyPolicy />,
    },
  ];

  const handleSectionClick = (title) => {
    setSelectedSection(title);
  };

  return (
    <>
      <Button variant="link" onClick={onOpen}>
        {customButton ? (
          customButton
        ) : (
          <Text textColor="primary" onClick={() => handleSectionClick(selectedContent)}>
            {buttons[selectedContent]?.title}
          </Text>
        )}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          h={{ base: 'initial', md: '80vh' }}
          maxW="none"
          w="1188px"
          display="grid"
          gridTemplateColumns={{ base: '1fr', md: '1fr max(70%)' }}
          flexDirection={{ base: 'column', md: 'row' }}>
          <Box backgroundColor="primary">
            <Flex gap="13px" align="center" ml="30px" h="100px">
              <MdHelpOutline size={20} color="white" />
              <Text textStyle="tableTitle" color="white">
                Ajuda
              </Text>
            </Flex>

            <Divider />

            <Flex direction="column" mt="20px">
              {buttons.map((button, index) => (
                <Button
                  key={index}
                  variant="ghost"
                  color="white"
                  fontSize="20px"
                  h="67px"
                  borderRadius="0"
                  pl="30px"
                  justifyContent="flex-start"
                  _hover={{ bgColor: '#6C48C2' }}
                  bgColor={index === selectedSection ? '#6C48C2' : ''}
                  onClick={() => handleSectionClick(index)}>
                  {button.name}
                </Button>
              ))}
            </Flex>
          </Box>

          <Box backgroundColor="background">
            <Flex justify="space-between" align="center" px="30px" h="100px">
              <Text textStyle="secondaryTitle" color="primary" fontWeight="bold" fontSize={{ base: '22px', md: '26px' }}>
                {buttons[selectedSection]?.title}
              </Text>

              <IconButton bgColor="background" icon={<MdClose size={20} color="#00000057" />} onClick={onClose} />
            </Flex>

            <Divider />

            {buttons[selectedSection]?.content}
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FAQ;
