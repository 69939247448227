import React, { useState } from 'react';

import { MdArticle, MdHelp, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Button, Flex, Icon, Image, Td, Text, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';

import document from '../../../../../assets/images/document.svg';
import iconEfetiva from '../../../../../assets/svgs/origem-efetiva.svg';
import iconEsperada from '../../../../../assets/svgs/origem-esperada.svg';
import { convertColorTrip, descriptionStatusTrip, modalityIcon, modalityTitle } from '../../../../../utils/translate';
import DocumentModalView from '../../../CurrentTrips/components/Documents/DocumentModalView';

import CteDetails from './CteDetails';
const TavelListCollapse = ({ entity, loadData }) => {
  const [currentIdentifier, setCurrentIdentifier] = useState(null);
  const [isOpenModalViewFile, setIsOpenModalViewFile] = useState(false);
  const [currentCode, setCurrentCode] = useState(null);

  const handleOpen = (identifier, code) => {
    setCurrentIdentifier(identifier);
    setCurrentCode(code);
    setIsOpenModalViewFile(!isOpenModalViewFile);
  };

  const { isOpen, onToggle } = useDisclosure();
  const getIcon = isOpen ? <MdKeyboardArrowUp color="#6C48C2" /> : <MdKeyboardArrowDown color="#6C48C2" />;

  const resolution = window.innerWidth;

  const getColor = (modality) => {
    if (modality == 'FRETE_NAO_RASTREAVEL') {
      return '#f1c40f';
    } else if (modality == 'FRETE_RASTREAVEL') {
      return '#bababa';
    }

    return '#a885f9';
  };

  const tooltipStart = (
    <>
      <Text>Início Previsto: {entity?.startsAtFormatted}</Text>
      {entity?.advanceTripStart != null && <Text>Adiantou: {entity?.advanceTripStart} </Text>}
      {entity?.delayTripStart != null && <Text>Atrasou: {entity?.delayTripStart} </Text>}
    </>
  );

  const tooltipEnd = (
    <>
      <Text>Conclusão Prevista: {entity?.endsAtFormatted}</Text>
      {entity?.advanceTripEnd != null && <Text>Adiantou: {entity?.advanceTripEnd} </Text>}
      {entity?.delayTripEnd != null && <Text>Atrasou: {entity?.delayTripEnd} </Text>}
    </>
  );

  return (
    <>
      <Tr h="64px" className="border">
        <Td className="border" border="none" cursor="pointer" onClick={onToggle}>
          <Box>{getIcon}</Box>
        </Td>
        <Td border="none" title={modalityTitle(entity.modality)} onClick={onToggle}>
          <Box>
            <Box h="1.1rem">
              <Icon as={modalityIcon(entity?.modality)} color={getColor(entity?.modality)} w="1.1rem" h="1.1rem" />
            </Box>
          </Box>
        </Td>
        <Td cursor="pointer" border="none" textColor="linkColor" textStyle="subtitle" onClick={onToggle}>
          <Box>
            <Link to={`/logistic/trip/detail/${entity.identifier}`} state={entity}>
              #{entity?.code}
            </Link>
          </Box>
        </Td>
        <Td cursor="pointer" border="none" textStyle="subtitle" textColor="#0F0A1DB3" onClick={onToggle}>
          <Box>{entity?.cteNumber}</Box>
        </Td>
        <Td cursor="pointer" border="none" textStyle="subtitle" textColor="#0F0A1DB3" onClick={onToggle}>
          <Box>
            {entity?.clientsFormatted?.length === 0
              ? 'Nenhuma empresa vinculada'
              : entity?.clientsFormatted?.length + ` vinculada${entity?.clientsFormatted?.length > 1 ? 's' : ''}`}
            {entity?.clientsFormatted?.length > 0 && (
              <Tooltip label={entity?.clientsFormatted?.join(', ')}>
                <Box ml="10px">
                  <MdHelp color="#422C76" />
                </Box>
              </Tooltip>
            )}
          </Box>
        </Td>
        <Td cursor="pointer" border="none" textStyle="subtitle" textColor="#0F0A1DB3" onClick={onToggle}>
          <Box>{entity?.userDriverName}</Box>
        </Td>
        <Td cursor="pointer" border="none" textStyle="subtitle" textColor="#0F0A1DB3" onClick={onToggle}>
          <Box>
            {entity?.startsEtdFormatted != null ? (
              <>
                <Image src={iconEfetiva} w="1rem" h="1rem" ml="1" mr="1" />
                {entity?.startsEtdFormatted}
                <Tooltip label={tooltipStart}>
                  <Box ml="10px">
                    <MdHelp color="#422C76" />
                  </Box>
                </Tooltip>
              </>
            ) : (
              <>
                {entity?.startsAtFormatted != null && <Image src={iconEsperada} w="1rem" h="1rem" ml="1" mr="1" />}
                {entity?.startsAtFormatted}
              </>
            )}{' '}
          </Box>
        </Td>
        <Td cursor="pointer" border="none" textStyle="subtitle" textColor="#0F0A1DB3" onClick={onToggle}>
          <Box>
            {entity?.endsEtdFormatted != null ? (
              <>
                <Image src={iconEfetiva} w="1rem" h="1rem" ml="1" mr="1" />
                {entity?.endsEtdFormatted}
                <Tooltip label={tooltipEnd}>
                  <Box ml="10px">
                    <MdHelp color="#422C76" />
                  </Box>
                </Tooltip>
              </>
            ) : (
              <>
                {entity?.endsAtFormatted != null && <Image src={iconEsperada} w="1rem" h="1rem" ml="1" mr="1" />}
                {entity?.endsAtFormatted}
              </>
            )}{' '}
          </Box>
        </Td>
        <Td cursor="pointer" border="none" textStyle="subtitle" bgColor={'transparent'}>
          <Box>
            {entity.qttFiles > 0 ? (
              <Tooltip label="Ver arquivos">
                <Button
                  variant="secundary"
                  color="#8067DC"
                  h="32px"
                  fontSize="14px"
                  onClick={() => handleOpen(entity?.identifier, entity?.code)}
                  leftIcon={<MdArticle />}>
                  <Text>Ver arquivos</Text>
                </Button>
              </Tooltip>
            ) : (
              <small>Sem arquivos</small>
            )}
          </Box>
        </Td>
        <Td border="none" textStyle="subtitle">
          <Box>
            {resolution > 768 && resolution < 1200 ? (
              <>
                <Tooltip label={entity.status}>
                  <Flex
                    align="center"
                    gap="10px"
                    borderRadius="15px"
                    bgColor={convertColorTrip(entity.status)}
                    color="white"
                    h="20px"
                    w="20px"></Flex>
                </Tooltip>
              </>
            ) : (
              <>
                <Flex
                  align="center"
                  gap="10px"
                  py="5px"
                  px="20px"
                  borderRadius="15px"
                  bgColor={convertColorTrip(entity.status)}
                  color="white"
                  h="29px">
                  {descriptionStatusTrip(entity.status)}
                </Flex>
              </>
            )}
          </Box>
        </Td>
        <Td border="none" /*  w="50%"*/>
          <Flex direction="row" gap="20px" align="center" justify="flex-end">
            <Tooltip label="Detalhe da viagem">
              <Link to={`/logistic/trip/detail/${entity.identifier}`} state={entity}>
                <Image
                  w="18px"
                  src={document}
                  alt="Detalhes"
                  filter="saturate(0)"
                  cursor="pointer"
                  transition="0.2s"
                  _hover={{
                    filter: 'saturate(1)',
                  }}
                />
              </Link>
            </Tooltip>
          </Flex>
        </Td>
      </Tr>
      <Tr>
        <Td p="0" colSpan="10" border="none">
          <Box overflowX="auto">
            <CteDetails isOpenCollapse={isOpen} trip={entity} loadData={loadData} />
          </Box>
        </Td>
      </Tr>
      <DocumentModalView isOpen={isOpenModalViewFile} identifier={currentIdentifier} code={currentCode} onClose={handleOpen} />
    </>
  );
};

export default TavelListCollapse;
