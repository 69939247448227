import React from 'react';

import { MdOutlineClose, MdOutlineVisibility } from 'react-icons/md';

import {
  Box,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';

import requests from '../../../../services/requests';

const ModalViewDocument = ({ quote = undefined, document = undefined, isDefaultTitle = true }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const linkDocument = (requests.getApiUrl() + `/open/quote/file/${quote?.identifier}/${document?.identifier}/view`).replace(
    '//open',
    '/open'
  );

  if (document?.ext == 'docx' || document?.ext == 'xlsx' || document?.ext == 'pptx') {
    linkDocument = 'https://view.officeapps.live.com/op/embed.aspx?src=' + linkDocument;
  }

  return (
    <>
      <Tooltip label="Visualizar">
        <Box>
          <MdOutlineVisibility size={20} onClick={onOpen} cursor="pointer" color="#422C76" />
        </Box>
      </Tooltip>

      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="9px" maxW="none" h="80%" w="60%" p="40px" overflowY="auto">
          <ModalHeader>
            <Flex justify="space-between" align="flex-start">
              <Box>
                <Text textStyle="secondaryTitle" color="primary">
                  Documento
                </Text>
                <Text textStyle="subtitle" color="placeholder">
                  {document.uploadedName}
                </Text>
              </Box>
              <IconButton icon={<MdOutlineClose size={30} />} onClick={onClose} bgColor="transparent" />
            </Flex>
          </ModalHeader>
          <ModalBody>
            <iframe width="100%" height="700rem" src={linkDocument} allowFullScreen={true}></iframe>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalViewDocument;
