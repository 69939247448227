import React, { useEffect, useState } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

import PieChartComponent from '../../../components/Charts/PieChartComponent';

const ReceiptDeadlinesChart = ({ data }) => {
  const [entity, setEntity] = useState([]);
  const [total, setTotal] = useState(0);

  const formatValue = (value) => {
    if (value === undefined) {
      return '';
    }
    return value.toLocaleString('pt-BR');
  };

  const calculatePercentage = (value) => {
    if (value === undefined || total === 0) {
      return '0%';
    }
    const percentage = (value / total) * 100;
    return percentage.toFixed(2) + '%';
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { prazo = 0, atraso = 0 } = data;

        const chartData = [
          {
            name: 'No prazo',
            qtt: prazo,
            color: '#2ECC71',
          },
          {
            name: 'Atrasado',
            qtt: atraso,
            color: '#E74C3C',
          },
        ];

        const sum = prazo + atraso;
        setTotal(sum);

        setEntity(chartData);
      } catch (error) {
        console.error('Erro ao obter os dados da API:', error);
      }
    };

    fetchData();
  }, [data]);

  return (
    <Flex alignItems="center" justify="space-evenly" w="full" h="full" flexDirection={{ base: 'column', md: 'column' }}>
      <PieChartComponent entity={entity} total={total} formatValue={formatValue} />

      <Flex direction="column" gap="18px" align="center" flexDirection={{ base: 'row', md: 'row' }}>
        {entity.map((entry, index) => (
          <Flex key={`item-${index}`} direction="column" align="flex-start">
            <Flex align="center" direction="column">
              <Flex align="center">
                <Box w="17px" h="17px" bgColor={entry.color} borderRadius="3px" />
                <Text fontSize={'0.875rem'} ml="1">
                  {entry.name}
                </Text>
              </Flex>
              <Flex align="center" gap="5px">
                <Text textStyle="tableTitle" textColor="#0F0A1D" fontWeight="bold">
                  {formatValue(entry.qtt)}
                </Text>

                <Text textStyle="paragraph" textColor="#0F0A1D" opacity="0.8">
                  {calculatePercentage(entry.qtt)}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default ReceiptDeadlinesChart;
