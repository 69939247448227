import React, { useEffect, useState } from 'react';

import { Card } from '@chakra-ui/card';
import { Box, Flex, Text } from '@chakra-ui/react';

import MapDashboard from '../../../components/Map/MapDashboard';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import useScreenPage from '../../../hooks/useScreenPage';
import requests from '../../../services/requests';

const DashboardMap = props => {
  let [isLoading, setIsLoading] = useState(true);

  //Detecção de chamadas as APIs
  const screenPage = useScreenPage()

  const { 
    w, h, 
    searchPeriod, 
    filterInfo, 
    searchString, 
    shouldRefresh, 
    setIsRefreshing
  } = props;

  const [points, setPoints] = useState([]);
  const [lines, setLines] = useState([]);

  const load = () => {
    loadList();
  };

  const loadList = () => {
    if (window.localStorage.getItem('process-filter')) {
      setSearchString(window.localStorage.getItem('process-filter'));
    }

    setIsLoading(true);
    setIsRefreshing(true);

    requests
      .mapPositions(null, {
        filters: {
          clients: filterInfo.client,
        },
        period: searchPeriod,
        search: searchString,
        screenPage
      })
      .then((data) => {
        setPoints(data.points);
        setLines(data.lines);
      }).finally(() => {
        setIsLoading(false);
        setIsRefreshing(false);
      })
    ;
  };

  useEffect(() => {
    if (searchPeriod?.startsAt != null || searchPeriod?.endsAt != null) {
      load();
    }
  }, [searchPeriod, filterInfo, searchString]);

  useEffect(() => {
    if (shouldRefresh) {
      load();
    }
  }, [shouldRefresh]);

  useEffect(() => {
    setIsRefreshing(isLoading);
  }, [isLoading]);

  return (
    <Card w={w} maxH={{ base: '350px', md: '500px' }} overflow="hidden">
      <ScreenLoader isLoading={isLoading}>
        <Box w="100%" h={h} position="relative">
          <Flex
            position="absolute"
            top="10px"
            left="10px"
            zIndex="10"
            bg="white"
            p="10px 15px"
            boxShadow="0px 6px 25px #0000001A"
            pointerEvents="none"
            alignItems="center"
            gap="10px">
            <Text color="primary" fontWeight="700" fontSize="20px">
              Mapa de importações
            </Text>
          </Flex>

          <MapDashboard w="100%" h={h} points={points} lines={lines} />
        </Box>
      </ScreenLoader>
    </Card>
  );
};

export default DashboardMap;
