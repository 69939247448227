import React from 'react';

const MapMarineTraffic = ({ url }) => {
  return (
    <iframe title="Marine Traffic" name="marinetraffic" id="marinetraffic" width="100%" height="500px" src={url}>
      O navegador não suporta esse tipo de visualização, acesse diretamente: mar{' '}
      <a href={url} target="_blank" rel="noreferrer">
        aqui
      </a>
    </iframe>
  );
};

export default MapMarineTraffic;
