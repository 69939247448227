import { Box, Text } from '@chakra-ui/react';

const HelpPrivacyPolicy = () => {

  return (
    <Box p="35px" overflowY="scroll" h="calc(80vh - 120px)">
      <Text textStyle="tableTitle" color="primary" fontSize={{ base: "16px", md: "20px"}}>
        A presente versão desta Política de Privacidade foi atualizada pela última vez em 07 de julho de 2022.
        <br /><br />
        Esta Política (“Política de Privacidade”) contém as condições gerais aplicáveis ao tratamento dos dados pessoais coletados pelo Grupo Vendemmia, a seguir qualificado pelos seguintes CNPJs: Vendemmia Comércio Internacional Ltda., inscrita no CNPJ/MF nº 13.631.538/0001-46, com sede na Avenida Nossa Senhora da Penha, nº 1495 – Sala BT 11080 – Torre BT, Vitória/ES, CEP: 29.056-905 e Vendemmia Armazém e Logística Integrada Ltda., inscrita no CNPJ/MF nº 25.211.082/0001-44, com sede na rodovia BR-470, nº. 6.800, bairro Volta Grande, Navegantes/SC, CEP: 88371-890, doravante denominadas simplesmente “Vendemmia”, por meio do acesso ao site: www.vendemmia.com.br.
        <br /><br />
        ATENÇÃO: Recomenda-se pela leitura desta Política de Privacidade antes do fornecimento de quaisquer dados pessoais à Vendemmia.
        <br /><br />
        A Vendemmia, comprometida em proteger a privacidade dos dados de clientes e de demais pessoas que acessem nossas plataformas digitais (doravante denominados “usuário/usuários”), assim como prezando pela proteção dos dados pessoais de quem quer que seja, apresenta a presente Política de Privacidade.
        <br /><br />
        A presente Política de Privacidade tem como objetivo fornecer informações claras e precisas sobre o tratamento de dados pessoais dos usuários que acessam o nosso site, esclarecendo aos interessados acerca dos tipos de dados que são coletados, dos motivos e finalidade da coleta e da forma como o usuário poderá atualizar, gerenciar ou solicitar a exclusão dessas informações, e, dada a sua publicidade, não é passível de alegação de desconhecimento de seu conteúdo por parte de nenhum usuário.
        <br /><br />
        Esta Política de Privacidade encontra respaldo e foi elaborada em conformidade com as Leis de Proteção de Dados, quais sejam: a Lei Federal n. 12.965 de 23 de abril de 2014 (Marco Civil da Internet), a Lei Federal n. 13.709, de 14 de agosto de 2018 (Lei de Proteção de Dados Pessoais) e o Regulamento UE n. 2016/679 de 27 de abril de 2016 (Regulamento Geral Europeu de Proteção de Dados Pessoais – RGDP), e poderá ser atualizada em decorrência de eventual atualização normativa, razão pela qual se convida ao usuário a consultar periodicamente esta seção.
        <br /><br />
        Dessa forma, qualquer usuário que utilize nosso site declara, por sua vez, que consente com os Termos e Condições previstos nesta Política e com os princípios que a regem. A aceitação da presente Política de Privacidade é necessária para que o usuário usufrua das ferramentas do site em sua integralidade.
        <br /><br />
        1. DEFINIÇÕES
        Para os fins desta Política de Privacidade:
        <br /><br />
        “Dados Pessoais” significa informações que permitam a identificação, direta ou indiretamente de pessoas naturais;
        <br /><br />
        “Dados Pessoais Sensíveis” significa informações sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou à organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural;
        <br /><br />
        “Tratamento de Dados Pessoais” significa qualquer operação realizada com Dados Pessoais, tais como: coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração; e
        <br /><br />
        “Leis de Proteção de Dados” significa toda a legislação aplicável ao Tratamento de Dados Pessoais, que inclui, sem limitação à Lei Federal n. 13.709/2018 (“LGPD-Lei Geral de Proteção de Dados”).
        <br /><br />
        2. CONTROLADOR DO TRATAMENTO DE DADOS PESSOAIS
        O controlador, responsável pelo tratamento dos dados pessoais do usuário, é a Vendemmia, através de seus CNPJs: Vendemmia Comércio Internacional Ltda., inscrita no CNPJ/MF nº 13.631.538/0001-46, com sede na Avenida Nossa Senhora da Penha, nº 1495 – Sala BT 11080- Torre BT, Vitória/ES, CEP: 29.056-905 e Vendemmia Armazém e Logística Integrada Ltda., inscrita no CNPJ/MF nº 25.211.082/0001-44, com sede na rodovia BR-470, nº. 6.800, bairro Volta Grande, Navegantes/SC, CEP: 88371-890.
        <br /><br />
        A Vendemmia irá tratar os dados pessoais fornecidos através do site: www.vendemmia.com.br
        <br /><br />
        3. DO ENCARREGADO PELO TRATAMENTO DOS DADOS
        (DPO – Data Protection Officer)
        O Encarregado de Proteção de Dados é o profissional que atua como canal de comunicação entre o Controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD), sendo encarregado de receber comunicações e adotar providências, bem como, informar, aconselhar e controlar o responsável pelo tratamento dos dados e os colaboradores que tratem os dados, a respeito das obrigações do site nos termos do RGDP, da Lei de Proteção de Dados Pessoais e de outras disposições de proteção de dados presentes na legislação nacional e internacional, em cooperação com a autoridade de controle competente.
        <br /><br />
        Para efeitos desta Política, o Encarregado de Proteção de Dados é o Sr. André Renato Riva e poderá ser contatado pelo seguinte e-mail: dpo@vendemmia.com.br
        <br /><br />
        4. DOS DIREITOS DO USUÁRIO/TITULAR DE DADOS
        O usuário deste site possui os seguintes direitos, conferidos pela LGPD-Lei Geral de Proteção de Dados Pessoais e pelo RGPD:
        <br /><br />
        Direito de acesso: obter acesso de quais de seus dados pessoais encontram-se armazenados na base de dados correspondente ao site do Controlador Vendemmia; Direito de retificação/atualização: retificação e/ou atualização dos dados pessoais que lhe digam respeito; Direito à eliminação dos dados ou direito ao esquecimento: é o direito do usuário de ter seus dados apagados do site; Direito à limitação do tratamento dos dados: é o direito do usuário de limitar o tratamento de seus dados pessoais, podendo obtê-la quando contestar a exatidão dos dados, quando o tratamento for ilícito, quando o site não precisar mais dos dados para as finalidades propostas e quando tiver manifestado oposição ao tratamento de seus dados e em caso de tratamento de dados desnecessários; Direito de oposição: é o direito do usuário de, a qualquer momento, se opor por motivos relacionados com a sua situação particular ao tratamento dos dados pessoais que lhe digam respeito, podendo se opor ainda ao uso de seus dados pessoais para definição de perfil de marketing; Direito de portabilidade dos dados: é o direito do usuário de receber os dados pessoais que lhe digam respeito e que tenha fornecido ao site; Direito de não ser submetido à decisões automatizadas: é o direito do usuário de não ficar sujeito a nenhuma decisão tomada exclusivamente com base no tratamento automatizado.
        <br /><br />
        5. DA FORMA DOS USUÁRIOS EXERCEREM SEUS DIREITOS
        O usuário poderá exercer os seus direitos por meio de comunicação escrita para a Vendemmia especificando:
        <br /><br />
        Nome completo ou Razão social;
        Número do CPF (Cadastro de Pessoas Físicas, da Receita Federal do Brasil) ou CNPJ
        (Cadastro Nacional de Pessoa Jurídica, da Receita Federal do Brasil);
        Endereço de e-mail do usuário e, se for o caso, do seu representante;
        Direito que deseja exercer, bem como documento que demostre ou justifique o exercício do direito;
        Data do pedido e assinatura do usuário.
        O usuário tem o direito de retirar seu consentimento a qualquer momento, não comprometendo a licitude do tratamento de seus dados pessoais antes da retirada. A retirada do consentimento poderá ser feita pelo e-mail: dpo@vendemmia.com.br ou por meio do correio enviado ao seguinte endereço: Rua Funchal, 129, 13º andar, Vila Olímpia, São Paulo/SP, CEP: 04551-060.
        <br /><br />
        O usuário será informado em caso de retificação ou eliminação dos seus dados.
        <br /><br />
        6. INFORMAÇÕES COLETADAS
        A coleta de dados dos usuários pela Vendemmia se dará em conformidade com o disposto nesta Política de Privacidade e dependerá do consentimento do usuário, sendo este dispensável somente nas hipóteses previstas no art. 11, inciso II, da Lei de Proteção de Dados Pessoais.
        <br /><br />
        6.1 Tipos de dados coletados
        6.1.a. Dados informados no formulário de contato
        A utilização, pelo usuário, de determinadas funcionalidades do site dependerá de preenchimento de formulário, sendo que, nestes casos, os seguintes dados do usuário serão coletados e armazenados:
        <br /><br />
        – Nome completo, endereço de e-mail, número de telefone, número de CPF, Nome completo da Empresa, cargo em que o usuário atua, número de CNPJ, endereço postal.
        <br /><br />
        Os dados eventualmente informados pelo usuário que utilizar o formulário de contato disponibilizado no site, incluindo o teor da mensagem enviada, serão coletados e armazenados.
        <br /><br />
        6.1.b. Registros de acesso
        Em atendimento às disposições do art. 15, caput e parágrafos, da Lei Federal n. 12.965/2014 (Marco Civil da Internet), os registros de acesso do usuário serão coletados e armazenados por, pelo menos, seis meses.
        <br /><br />
        6.1.c. Newsletter/Clipping de Notícias
        O endereço de e-mail cadastrado pelo usuário que optar por se inscrever em nossa Newsletter/Clipping de notícias será coletado e armazenado até que o usuário solicite seu descadastro, caso seja de sua vontade.
        <br /><br />
        6.1.d. Dados sensíveis
        Não serão coletados dados sensíveis dos usuários, assim entendidos aqueles definidos nos artigos 9º e 10º do RGPD e nos artigos 11 e seguintes da Lei Geral de Proteção de Dados Pessoais.
        <br /><br />
        6.1.e. Coleta de dados não previstos expressamente
        Eventualmente, outros tipos de dados não previstos expressamente nesta Política de Privacidade poderão ser coletados, desde que sejam fornecidos com o consentimento do usuário, ou, ainda, que a coleta seja permitida ou imposta por lei.
        <br /><br />
        7. FUNDAMENTO JURÍDICO PARA O TRATAMENTO DOS DADOS PESSOAIS
        Ao utilizar os serviços do site, o usuário está consentindo com a presente Política de Privacidade.
        <br /><br />
        O usuário tem o direito de retirar o seu consentimento a qualquer momento, não comprometendo a licitude do tratamento de seus dados pessoais antes da retirada. A retirada do consentimento poderá ser feita pelo e-mail: dpo@vendemmia.com.br, ou por correio enviado ao seguinte endereço: Rua Funchal, nº 129 13º Andar, conjuntos 13A e 13B, São Paulo, SP, CEP: 04551-060.
        <br /><br />
        O consentimento dos usuários relativamente ou absolutamente incapazes, especialmente de crianças menores de 16 (dezesseis) anos, apenas poderá ser feito, respectivamente, se devidamente assistidos ou representados.
        <br /><br />
        Poderão ainda ser coletados dados pessoais necessários para a execução e cumprimento dos serviços contratados pelo usuário no site.
        <br /><br />
        O tratamento de dados pessoais sem o consentimento do usuário apenas será realizado em razão de interesse legítimo ou para as hipóteses previstas em lei, ou seja, as previstas no inciso II do artigo 11º da Lei 13.709 de 2018.
        <br /><br />
        8. FINALIDADES DO TRATAMENTO DOS DADOS PESSOAIS
        Os dados pessoais do usuário coletados pelo site têm por finalidade facilitar, agilizar e cumprir os compromissos estabelecidos com o usuário, bem como fazer cumprir as solicitações realizadas por meio do preenchimento de formulários.
        <br /><br />
        Os dados pessoais poderão ser utilizados também com uma finalidade comercial, para personalizar o conteúdo oferecido ao usuário, bem como para dar subsídio ao site para a melhoria da qualidade e funcionamento de seus serviços.
        <br /><br />
        A coleta de dados relacionados ou necessários à execução de um contrato de compra e venda ou de prestação de serviços, eventualmente firmado entre a Vendemmia e um usuário, terá a finalidade de conferir às partes segurança jurídica, além de facilitar e viabilizar a conclusão do negócio.
        <br /><br />
        O tratamento de dados pessoais para finalidades não previstas nesta Política de Privacidade somente ocorrerá mediante comunicação prévia ao usuário, sendo que, em qualquer caso, os direitos e obrigações aqui previstos permanecerão aplicáveis.
        <br /><br />
        9. PRAZO DE CONSERVAÇÃO DOS DADOS PESSOAIS
        Os dados pessoais do usuário tratados para as finalidades determinadas acima serão mantidos em conformidade com os princípios de finalidade, adequação e necessidade, até os objetivos do tratamento serem alcançados.
        <br /><br />
        Fica ressalvada a hipótese dos Dados Pessoais serem mantidos por prazos superiores aos inicialmente previstos, em atenção às obrigações regulatórias ou para defesa em processos judiciais, administrativos ou arbitrais.
        <br /><br />
        10. DESTINATÁRIOS E TRANSFERÊNCIA DOS DADOS PESSOAIS
        Não haverá compartilhamento de dados com outros sites.
        <br /><br />
        Os dados Pessoais coletados podem ser acessados por colaboradores, prestadores e fornecedores de serviços da Vendemmia, sendo essas pessoas diretamente autorizadas pelo Controlador para o respectivo tratamento e estando cientes das diretrizes de proteção e privacidade de dados pessoais implementadas no ambiente corporativo da empresa, sob pena de medidas Legais cabíveis em caso de eventual descumprimento e/ou violação, quando necessárias.
        <br /><br />
        A transferência de dados apenas poderá ser feita para outro país caso o país ou território em questão, ou ainda a organização internacional em causa, assegurem um nível de proteção adequado dos dados do usuário.
        <br /><br />
        Caso não haja nível de proteção adequado, o site se compromete a garantir a proteção dos seus dados de acordo com as regras mais rigorosas, por meio de cláusulas contratuais específicas para determinada transferência, cláusulas-padrão contratuais, normas corporativas globais ou selos, certificados e códigos de conduta regularmente emitidos.
        <br /><br />
        11. SEGURANÇA NO TRATAMENTO DOS DADOS PESSOAIS DO USUÁRIO
        Para o Tratamento dos Dados Pessoais coletados diretamente do usuário ou por outros meios supracitados adotamos medidas físicas e administrativas aptas a resguardarem a segurança das informações coletadas e tratadas, ou seja, soluções que levem em consideração: as técnicas adequadas; os custos de aplicação; a natureza, o âmbito, o contexto e as finalidades do tratamento; e os riscos para os direitos e liberdades do usuário.
        <br /><br />
        Todas as informações serão mantidas em nosso Banco de Dados pelo tempo necessário ou relevante à finalidade social da Vendemmia, nos termos da legislação vigente aplicável, bem como para solucionar disputas e cumprimento de obrigações legais.
        <br /><br />
        No entanto, o site se exime de responsabilidade por culpa exclusiva de terceiros, como em caso de ataque de hackers ou crackers, ou culpa exclusiva do usuário, como no caso em que ele mesmo transfere seus dados a terceiro. O site se compromete, ainda, a notificar o usuário em prazo adequado caso ocorra algum tipo de violação da segurança de seus dados pessoais que possa lhe causar um alto risco para seus direitos e liberdades pessoais, bem como à Autoridade Nacional de Proteção de Dados (ANPD) e qualquer outra autoridade que se aplique sobre a violação e as medidas que adotarmos para solucioná-la.
        <br /><br />
        Considera-se por violação de dados pessoais uma violação de segurança que provoque, de modo acidental ou ilícito, a destruição, a perda, a alteração, a divulgação ou o acesso não autorizado a dados pessoais transmitidos, conservados ou sujeitos a qualquer outro tipo de tratamento.
        <br /><br />
        Embora a Vendemmia adote os melhores esforços no sentido de preservar a privacidade e proteger os Dados Pessoais de seus usuários, nenhuma transmissão de informações é totalmente inviolável, de modo que não podemos garantir integralmente que todas as informações enviadas e recebidas não sejam alvo de acessos não autorizados perpetrados por meio de métodos desenvolvidos para obter informações de forma indevida e ilícita. Por esse motivo, incentivamos aos usuários a também tomarem as medidas apropriadas para se protegerem como, por exemplo, manterem confidenciais todos os nomes de Titular e login/senhas de acesso, sendo certo que tais informações são pessoais, intransferíveis e de exclusiva responsabilidade do usuário.
        <br /><br />
        Ademais, todos aqueles que tratarem os Dados Pessoais coletados, com base nas instruções da Vendemmia, estão sujeitos a um dever de confidencialidade e são obrigados a comprovar estarem em conformidade com a LGPD e com outras leis de proteção de dados.
        <br /><br />
        Por fim, o site se compromete a aplicar as medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais dados, bem como, se compromete a tratar os dados pessoais de seus usuários com confidencialidade, dentro dos limites legais.
        <br /><br />
        12. DADOS DE NAVEGAÇÃO (COOKIES)
        Cookies são pequenos arquivos de texto enviados pelo site ao computador do usuário e que nele ficam armazenados, com informações relacionadas à navegação do site.
        <br /><br />
        Por meio dos cookies, pequenas quantidades de informação são armazenadas pelo navegador do usuário para que nosso servidor possa lê-las posteriormente. Podem ser armazenados, por exemplo, dados sobre o dispositivo utilizado pelo usuário, bem como seu local e horário de acesso ao site.
        <br /><br />
        Os cookies não permitem que qualquer arquivo ou informação sejam extraídos do disco rígido do usuário, não sendo possível, ainda, que, por meio deles, se tenha acesso a informações pessoais que não tenham partido do usuário ou da forma como utiliza os recursos do site.
        <br /><br />
        É importante ressaltar que nem todo cookie contém informações que permitem a identificação do usuário, sendo que determinados tipos de cookies podem ser empregados simplesmente para que o site seja carregado corretamente ou para que suas funcionalidades funcionem do modo esperado.
        <br /><br />
        As informações eventualmente armazenadas em cookies que permitam identificar um usuário são consideradas dados pessoais. Dessa forma, todas as regras previstas nesta Política de Privacidade também lhes são aplicáveis.
        <br /><br />
        13. COOKIES DO SITE
        Os cookies do site são aqueles enviados ao computador ou dispositivo do usuário e administrador exclusivamente pelo site.
        <br /><br />
        As informações coletadas por meio destes cookies são utilizadas para melhorar e personalizar a experiência do usuário, sendo que alguns cookies podem, por exemplo, ser utilizados para lembrar as preferências e escolhas do usuário, bem como para o oferecimento de conteúdo personalizado.
        <br /><br />
        Estes dados de navegação poderão, ainda, ser compartilhados com eventuais parceiros do site, buscando o aprimoramento dos produtos e serviços ofertados ao usuário.
        <br /><br />
        14. COOKIES DE REDES SOCIAIS
        O site utiliza plugins de redes sociais, que permitem acessá-las a partir do site. Assim, ao fazê-lo, os cookies utilizados por elas poderão ser armazenados no navegador do usuário.
        <br /><br />
        Cada rede social possui sua própria política de privacidade e de proteção de dados pessoais, sendo as pessoas físicas ou jurídicas que as mantêm responsáveis pelos dados coletados e pelas práticas de privacidade adotadas.
        <br /><br />
        O usuário pode pesquisar, junto às redes sociais, informações sobre como seus dados pessoais são tratados. A título informativo, disponibilizamos os seguintes links, a partir dos quais poderão ser consultadas as políticas de privacidade e de cookies adotadas por algumas das principais redes sociais:
        <br /><br />
        Instagram: help.instagram.com/1896641480634370?ref=ig
        Youtube: policies.google.com/privacy?hl=pt-BR&gl=pt
        Google+: policies.google.com/technologies/cookies?hl=pt
        LinkedIn: www.linkedin.com/legal/cookie-policy?trk=hp-cookies
        <br /><br />
        15. GESTÃO DOS COOKIES E CONFIGURAÇÕES DO NAVEGADOR
        O usuário poderá se opor ao registro de cookies pelo site, bastando que desative esta opção no seu próprio navegador ou aparelho.
        <br /><br />
        A desativação dos cookies, no entanto, pode afetar a disponibilidade de algumas ferramentas e funcionalidades do site, comprometendo seu correto e esperado funcionamento. Outra consequência possível é remoção das preferências do usuário que eventualmente tiverem sido salvas, prejudicando sua experiência.
        <br /><br />
        16. DAS ALTERAÇÕES
        A presente versão desta Política de Privacidade foi atualizada pela última vez em 07 de Julho de 2022.
        <br /><br />
        A Vendemmia se reserva o direito de modificar, a qualquer momento as presentes normas, especialmente para adaptá-las às evoluções do site (www.vendemmia.com.br), seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.
        <br /><br />
        O usuário será explicitamente notificado em caso de alteração desta Política.
        <br /><br />
        Ao utilizar o serviço após eventuais modificações, o usuário demonstra sua concordância com as novas normas. Caso discorde de alguma das modificações, deverá, imediatamente, apresentar a sua ressalva ao serviço de atendimento ou o cancelamento de seu cadastro, se assim o desejar, nos termos já citados anteriormente.
        <br /><br />
        17. RECLAMAÇÃO A UMA AUTORIDADE DE CONTROLE
        Todos os titulares de dados podem apresentar reclamação perante a Autoridade Nacional de Proteção de Dados Pessoais – ANDP, tão logo constituída pelas autoridades competentes
        <br /><br />
        18. DO DIREITO APLICÁVEL E DO FORO
        A presente Política de Privacidade será regida, interpretada e executada com as Leis da República Federativa do Brasil, especialmente com a Lei Federal n. 12.965 de 23 de abril de 2014 (Marco Civil da Internet), com a Lei Federal n. 13.709, de 14 de agosto de 2018 (Lei de Proteção de Dados Pessoais) e com o Regulamento UE n. 2016/679 de 27 de abril de 2016 (Regulamento Geral Europeu de Proteção de Dados Pessoais – RGDP)
        <br /><br />
        Todas as disputas, controvérsias e divergências entre as partes decorrentes ou relacionadas com esta política de privacidade terão seu foro competente o foro de domicílio do Controlador para dirimir qualquer dúvida decorrente deste documento.
      </Text>
    </Box>
  );
};

export default HelpPrivacyPolicy;
