import { Button, Flex, Text } from '@chakra-ui/react';
import 'react-toastify/dist/ReactToastify.css';

const CreditAnalysisBox = ({ isLoading, text, modality, handleClick }) => {
  return (
    <Flex
      mt="4"
      justifyContent="center"
      alignItems="center"
      w="100%"
      border="1px"
      borderColor="#7070703D"
      direction="column"
      pl="30px"
      pr="30px"
      pt="20px"
      pb="20px">
      <Text>{text}</Text>
      <Button
        bgColor="primary"
        color="white"
        borderRadius="10px"
        shadow="xl"
        mt="4"
        h="50px"
        py="15px"
        px="40px"
        isLoading={isLoading}
        loadingText="Salvando..."
        onClick={(decision) => {
          if (decision) {
            handleClick(modality);
          }
        }}
        _hover={{ opacity: '0.5', transition: '0.9s' }}>
        Consultar
      </Button>
    </Flex>
  );
};

export default CreditAnalysisBox;
