import React from 'react';

import { MdOutlineClose, MdOutlineVisibility } from 'react-icons/md';

import {
  Box,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';

const ModalViewFile = ({ entity }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Tooltip label="Visualizar">
        <Box>
          <MdOutlineVisibility size={20} onClick={onOpen} cursor="pointer" color="#422C76" />
        </Box>
      </Tooltip>

      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="9px" maxW="none" h="80%" w="40%" p="40px" overflowY="auto">
          <ModalHeader>
            <Flex justify="space-between" align="flex-start">
              <Box>
                <Text textStyle="secondaryTitle" color="primary">
                  Documento
                </Text>
                <Text textStyle="subtitle" color="placeholder">
                  {entity.uploadName}
                </Text>
              </Box>
              <IconButton icon={<MdOutlineClose size={30} />} onClick={onClose} bgColor="transparent" />
            </Flex>
          </ModalHeader>
          <ModalBody>
            {entity.process.identifier && entity.identifier && (
              <iframe
                src={process.env.REACT_APP_API_URL + `/open/process/file/${entity.process.identifier}/${entity.identifier}/view`}
                title="PDF Viewer"
                allowFullScreen={true}
                style={{ width: '100%', height: '600px' }}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalViewFile;
