import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  GridItem,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import Layout from '../../../components/Layout';
import Menubar from '../../../components/MenuBar/Menubar';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { limitChars } from '../../../utils/strings';

import ModalDeleteFaq from './components/ModalDeleteFaq';
import ModalEditFaq from './components/ModalEditFaq';
import ModalNewFaq from './components/ModalNewFaq';

const FaqPage = () => {
  let navigate = useNavigate();

  const resolution = window.innerWidth
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);

  const [searchString, setSearchString] = useState('');

  const loadList = async () => {
    setIsLoading(true);

    try {
      const res = await requests.listFaqs({ search: searchString });

      setList(res);
    } catch (error) {
      console.error('Erro ao obter os dados da API:', error);
    }
    setIsLoading(false);
  };

  const addTopicToList = () => {
    loadList();
  };

  const onChangeTextFilter = (value) => {
    if (value.length >= 3) {
      setSearchString(value);
    } else {
      setSearchString('');
    }
  };

  useEffect(() => {
    loadList();
  }, [searchString]);

  useEffect(() => {
    !permissions.vendemmiaManagementFaqManagement && navigate('/');
  }, []);

  return (
    <Layout>
      <Menubar
        screen="faq"
        title="Perguntas frequentes"
        onChangeTextFilter={onChangeTextFilter}
        showFilters={false}
        showPeriodFilter={false}
      />
      <Card
        m="10px"
        header={
          <Text textStyle="subtitle" fontWeight="bold" textColor="primary">
            Existe um total de {list.length} tópicos no momento
          </Text>
        }>

        {resolution > 768 ? (
          <Box p="15px">
            <ScreenLoader isLoading={isLoading}>
              <Table className="table-default min">
                <Thead border="1px" borderBottom="1px">
                  <Tr>
                    <Th>Título</Th>
                    <Th>Conteúdo</Th>
                    <Th>Data de Criação</Th>
                    <Th>Tipo</Th>
                    <Th>Modalidade</Th>
                    <Th>Ações</Th>
                  </Tr>
                </Thead>

                <Tbody border="1px">
                  {list?.map((entity) => (
                    <React.Fragment key={entity?.identifier}>
                      <Tr>
                        <Td>{entity?.title}</Td>
                        <Td w="auto">{limitChars(entity?.description, 120)}</Td>
                        <Td>{moment(entity?.createdAt).format('DD/MM/YYYY')}</Td>
                        <Td>{entity?.typeSystem === 'SYSTEM_ANALYTICS' ? 'Painel Analytics' : 'App do Motorista'}</Td>
                        <Td>{entity?.modality === 'AUTHENTICATED' ? 'Autenticado' : 'Público'}</Td>

                        <Td>
                          <Flex gap="10px">
                            <ModalEditFaq entity={entity} onSave={loadList} />
                            <ModalDeleteFaq entity={entity} onSave={loadList} />
                          </Flex>
                        </Td>
                      </Tr>
                    </React.Fragment>
                  ))}
                </Tbody>
              </Table>
            </ScreenLoader>
          </Box>
        ) : (
          <>
            <Grid templateColumns="1fr 100px" p={'8px 16px 8px 12px'} textIndent={'4px'}>
              <GridItem fontWeight={'bold'}>Título</GridItem>
              <GridItem fontWeight={'bold'}>Ações</GridItem>
            </Grid>
            <Accordion>
              {list.map((entity) => {
                return (
                  <>
                    <AccordionItem>
                      <h2>
                        <AccordionButton p={4}>
                          <Grid
                            templateColumns="1fr 100px"
                            justifyContent={'space-between'}
                            w={'100%'}
                            alignItems={'center'}
                            textAlign={'left'}
                            gap={2}>
                            <GridItem>{entity.title}</GridItem>
                            <GridItem>
                              <Flex gap="10px" justifyContent={'space-between'}>
                                <ModalEditFaq entity={entity} onSave={loadList} />
                                <ModalDeleteFaq entity={entity} onSave={loadList} />
                                <Flex align={'center'} justifyContent={'center'}>
                                  <AccordionIcon />
                                </Flex>
                              </Flex>
                            </GridItem>
                          </Grid>
                        </AccordionButton>
                      </h2>
                      <AccordionPanel p={4}>
                        <Text mb={2}>{limitChars(entity.description, 120)}</Text>
                        <Text>
                          <strong>Data de Criação:</strong> {moment(entity.createdAt).format('DD/MM/YYYY')}
                        </Text>
                        <Text>
                          <strong>Tipo:</strong> {entity.typeSystem === 'SYSTEM_ANALYTICS' ? 'Painel Analytics' : 'App do Motorista'}
                        </Text>
                        <Text>
                          <strong>Modalidade:</strong> {entity.modality === 'AUTHENTICATED' ? 'Autenticado' : 'Público'}
                        </Text>
                      </AccordionPanel>
                    </AccordionItem>
                  </>
                );
              })}
            </Accordion>
          </>
        )}
        {/* desktop */}

        <Flex className="float-button-modal">
          <ModalNewFaq addTopicToList={addTopicToList} />
        </Flex>
      </Card>
    </Layout>
  );
};

export default FaqPage;
