import React, { useEffect } from 'react';

import { ErrorMessage } from 'formik';
import Select from 'react-select';

import { Flex, FormControl, FormLabel, Input } from '@chakra-ui/react';

import FilterOption from '../../../../utils/filters/FilterOption';
import { getFilter } from '../../../../utils/storageFilter';

const FormConfigFilter = ({
  values,
  handleChange,
  screen,
  filters,
  filterOptions,
  handleSelectChange,
  setSelectedUser,
  selectedUser,
  userOptions,
  titleSelection,
  setTitleSelection,
}) => {
  const handleUserChange = (entity) => {
    setSelectedUser({
      label: entity?.label,
      value: entity?.value,
    });
  };

  useEffect(() => {
    if (typeof userOptions.user === 'undefined') {
      setSelectedUser({
        label: window.localStorage.getItem('profile-name'),
        value: window.localStorage.getItem('profile-identifier'),
      });
    }
  }, []);

  return (
    <Flex gap="20px" direction="column">
      <Flex gap="20px">
        <FormControl>
          <FormLabel textColor="primary">Nome da seleção</FormLabel>
          <Input
            name="name"
            h="38px"
            placeholder="Digite o nome"
            className="input-register"
            _placeholder={{ color: '#0F0A1D66' }}
            onChange={handleChange}
            value={values.name}
          />

          <ErrorMessage name="name" component="p" className="error-message-error" />
        </FormControl>

        <FormControl>
          <FormLabel textColor="primary">Duplicar relatório ao usuário</FormLabel>
          <Select placeholder="Selecione" options={userOptions} value={selectedUser} onChange={(entity) => handleUserChange(entity)} />
        </FormControl>
      </Flex>

      <FormControl>
        {filterOptions &&
          filterOptions.map((item, key) => {
            if (!(item instanceof FilterOption)) {
              return (
                <React.Fragment key={key}>
                  <FormLabel textColor="primary">{item.label}</FormLabel>

                  <Select
                    placeholder="Selecione"
                    isMulti
                    name={key}
                    key={getFilter(screen, item.name)}
                    options={item.options}
                    value={item?.options?.filter((option) => getFilter(screen, item.name)?.indexOf(option.value) > -1)}
                    onChange={(option) => handleSelectChange(item.name, option, item.isMultiple)}
                    // value={filter.filter((i) => i.checked)}
                    // options={filter.filter((i) => i !== false)}
                  />
                </React.Fragment>
              );
            }
          })}
      </FormControl>
    </Flex>
  );
};

export default FormConfigFilter;
