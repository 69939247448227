import React, { useEffect, useState } from 'react';

import { MdCircle, MdTune } from 'react-icons/md';
import Select from 'react-select';

import { Box, Button, Flex, Menu, MenuButton, MenuList, Text, Tooltip, useDisclosure } from '@chakra-ui/react';

import FilterOption from '../../../utils/filters/FilterOption';
import { addFilter, appendFilter, getFilter } from '../../../utils/storageFilter';

const Filter = ({ filterOptions = [], onChange, screen, calendarFilter, searchFilter }) => {
  const [filters, setFilters] = useState(() => {
    let aux = getFilter(screen) ?? {};

    delete aux.period;
    delete aux.search;

    return aux;
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const isFilterPopulated = () => {
    let filter = getFilter(screen);

    delete filter.period;
    delete filter.search;

    return Object.keys(filter).length > 0;
  };

  const handleSelectChange = (field, value, isMultiple) => {
    let data = [];

    if (!Array.isArray(value)) {
      value = [value];
    }

    for (let key in value) {
      let row = value[key];
      if (typeof row === 'string') {
        data.push(row);
      } else if ('value' in row && row.value !== null) {
        data.push(row.value);
      } else if ('identifier' in row) {
        data.push(row.identifier);
      } else if ('id' in row) {
        data.push(row.id);
      }
    }

    if (!isMultiple) {
      if (data.length > 0) {
        data = data.shift();
      } else {
        data = null;
      }
    }

    addFilter(screen, field, data);
    setFilters(getFilter(screen));
  };

  const applyFilters = () => {
    const selectedData = {};

    filterOptions.forEach((item) => {
      if (!(item instanceof FilterOption)) {
        return;
      }

      let selectedChildren = item.getFullSelectedChildrenValue();

      if (selectedChildren.length > 0) {
        selectedData[item.value] = selectedChildren;
      } else {
        selectedData[item.value] = null;
      }
    });

    appendFilter(screen, selectedData);
    setFilters(getFilter(screen));
  };

  const cleanFilters = () => {
    const selectedData = {};

    filterOptions.forEach((item) => {
      if (item.value !== 'period') {
        if (item instanceof FilterOption) {
          item.resetSelected();
          selectedData[item.value] = null;
        } else {
          handleSelectChange(item.name, [], false);
          selectedData[item.name] = null;
        }
      }
    });

    appendFilter(screen, selectedData);
    setFilters(getFilter(screen));
  };

  useEffect(() => {
    delete filters.period;
    delete filters.search;

    onChange?.(filters);
  }, [filters]);

  return (
    <>
      <Menu isOpen={isOpen} onClose={onClose}>
        <Tooltip label={!isOpen ? 'Escolha filtros' : ''}>
          <MenuButton bgColor="primary" onClick={onOpen} borderRadius="10px" w="44px" h="35px" _hover={{ bg: 'linkColor' }}>
            <Flex justify="center">
              <MdTune size={24} color="#FFFFFFB3" />
              {isFilterPopulated() && (
                <Box>
                  <MdCircle size={7} color="red" />
                </Box>
              )}
            </Flex>
          </MenuButton>
        </Tooltip>

        <style>
          {`
            [data-field="clients"] [class*="-control"] {
              max-height: 220px;
              overflow: auto;
            }
          `}
        </style>
        <MenuList w="270px" shadow="lg" borderRadius="5px" p="0">
          <Flex maxH="60vh">
            <Flex gap="30px" w="100%" direction="column" py="10px" px="10px">
              {calendarFilter && (
                <Flex direction="column" gap="8px">
                  {calendarFilter}
                </Flex>
              )}

              {searchFilter && (
                <Flex direction="column" gap="8px">
                  <Text textStyle="paragraph" opacity="0.5">
                    Pesquisa por texto
                  </Text>
                  {searchFilter}
                </Flex>
              )}

              <Flex direction="column" gap="8px">
                {filterOptions &&
                  filterOptions?.length > 0 &&
                  filterOptions.map((item, key) => {
                    if (!(item instanceof FilterOption)) {
                      return (
                        <Flex direction={'column'} key={key} data-field={item.name}>
                          <Text textStyle="paragraph" opacity="0.5">
                            {item.label}
                          </Text>
                          <Select
                            key={getFilter(screen, item.name)}
                            placeholder="Selecione"
                            options={item.options}
                            isMulti={item.isMultiple}
                            value={item?.options?.filter((option) =>
                              item.isMultiple
                                ? getFilter(screen, item.name)?.indexOf(option.value) > -1
                                : option.value === getFilter(screen, item.name)
                            )}
                            onChange={(option) => handleSelectChange(item.name, option, item.isMultiple)}
                          />
                        </Flex>
                      );
                    }
                  })}
              </Flex>

              <Flex direction="column" overflowY="auto">
                <ul>
                  {filterOptions &&
                    filterOptions?.length > 0 &&
                    filterOptions.map((item, key) => {
                      if (item instanceof FilterOption) {
                        return (
                          <li key={key} style={{ listStyle: 'none', fontSize: '18px', fontWeight: 'bold' }}>
                            {item.renderTree(getFilter(screen, item.value))}
                          </li>
                        );
                      }
                    })}
                </ul>
              </Flex>
            </Flex>
          </Flex>

          <Flex align="center" m="0" p="0">
            {isFilterPopulated() && (
              <Button
                variant="solid"
                borderRadius="0"
                w="full"
                bgColor="#6C48C2B3"
                color="#FFFFFF"
                _hover={{ bg: 'linkColor' }}
                onClick={() => {
                  cleanFilters();
                  onClose();
                }}>
                Limpar
              </Button>
            )}

            <Button
              variant="solid"
              borderRadius="0"
              w="full"
              bgColor="primary"
              color="#FFFFFF"
              _hover={{ bg: 'linkColor' }}
              onClick={() => {
                applyFilters();
                onClose();
              }}>
              Aplicar
            </Button>
          </Flex>
        </MenuList>
      </Menu>
    </>
  );
};

export default Filter;
