import { useState } from 'react';

import { Button, Card, Collapse, Flex, Grid, GridItem, Heading, List, ListItem, Text, useDisclosure } from '@chakra-ui/react';

import CommonListCollapsedTable from './CommonListCollapsedTable';

const CommonList = ({ children, ...props }) => {
  const { list, categories } = props;

  const title = props?.title;
  const hasCollapsed = props?.hasCollapsed;
  const collapsedContentType = props?.collapsedContentType;

  const subcategory = props?.subcategory;
  const subList = props?.subList;

  let categoryLength = categories?.length;
  let hiddenCategoriesLength = props?.hiddenCategories.length;
  let listLength = Object.keys(list[0]).length - hiddenCategoriesLength;

  children || hasCollapsed ? listLength++ : listLength;
  /* console.log(categoryLength, listLength); */
  listLength < categoryLength ? (listLength = categoryLength) : (categoryLength = listLength);

  const gridTemplateLength = categoryLength === listLength ? categoryLength : categoryLength + 1;

  return (
    <Card m="10px" border={'1px solid #70707036'}>
      {title && (
        <Heading
          p={'10px 15px'}
          fontSize={'20px'}
          color={'#422C76'}
          lineHeight={'30px'}
          fontWeight={'600'}
          fontFamily={'Hanken Grotesk'}
          borderBottom={'#70707036 solid 1px'}>
          {props?.title}
        </Heading>
      )}
      <Flex m={'15px'} flexDirection={'column'} gap={'10px'}>
        <Grid
          gridTemplateColumns={`repeat(${gridTemplateLength}, 1fr)`}
          border={'1px solid #6C48C259'}
          borderRadius={'18px'}
          p={'10px 25px'}>
          {props.categories &&
            props.categories.map((category, key) => {
              return (
                <GridItem as={'strong'} key={key} display={'flex'}>
                  {category}
                </GridItem>
              );
            })}
        </Grid>
        <List display={'flex'} flexDirection={'column'} gap={'10px'}>
          {props.list &&
            props.list.map((item, key) => {
              const [isOpen, setIsOpen] = useState(false);

              return (
                <ListItem
                  key={key}
                  display={'grid'}
                  gridTemplateColumns={`repeat(${gridTemplateLength}, 1fr)`}
                  alignItems={'center'}
                  border={'1px solid #6C48C259'}
                  borderRadius={'18px'}
                  p={'10px 25px'}>
                  {Object.entries(item).map(([property, value]) =>
                    !props.hiddenCategories || !props.hiddenCategories.includes(property) ? (
                      <Text as={'span'} key={property}>
                        {value}
                      </Text>
                    ) : null
                  )}
                  <Text as={'span'}>
                    {children}
                    {hasCollapsed && (
                      <Button
                        onClick={() => {
                          setIsOpen(!isOpen);
                        }}>
                        {isOpen ? 'Fechar' : 'Abrir'}
                      </Button>
                    )}
                  </Text>
                  {hasCollapsed && (
                    <Collapse in={isOpen} animateOpacity>
                      {collapsedContentType === 'table' && <CommonListCollapsedTable subList={subList} subCategory={subcategory} />}
                    </Collapse>
                  )}
                </ListItem>
              );
            })}
        </List>
      </Flex>
    </Card>
  );
};

export default CommonList;
