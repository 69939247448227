import React from 'react';

import { Flex, Text } from '@chakra-ui/react';

import ProcessContainersDrawer from '../../pages/comex/process/components/ProcessContainersDrawer';

const LabelWithTextH = ({ title, content, process, icon }) => {
  return (
    <Flex align="center">
      {title && (
        <Text textStyle="paragraph" mr="3px" fontWeight="bold">
          {title}:
        </Text>
      )}
      <Flex align="center" gap="5px">
        <Text textStyle="paragraph">{content}</Text>

        <ProcessContainersDrawer process={process} icon={icon} />
      </Flex>
    </Flex>
  );
};

export default LabelWithTextH;
