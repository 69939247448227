import React, { useEffect, useState } from 'react';

import { ErrorMessage } from 'formik';
import { MdAdd, MdDelete } from 'react-icons/md';
import Select from 'react-select';

import { Button, Flex, FormControl, Grid, TabPanel } from '@chakra-ui/react';

import InputDefault from '../../../../components/Form/Input/InputDefault';
import InputDefaultMask from '../../../../components/Form/Input/InputDefaultMask';
import { LabelDefault } from '../../../../components/Form/Label/LabelDefault';
import { handleAddClick, handleInputChange, handleRemoveChange } from '../Helpers/inputMultiple';

const RegistrationDataForm = ({ values, setFieldValue, isPreviwer }) => {
  const [inputCompanyBranches, setInputCompanyBranches] = useState([]);

  const vendemmiaOperationUnitValues = [
    {
      label: 'ESPÍRITO SANTO - VITÓRIA',
      value: 13631538000146,
    },
    {
      label: 'PERNAMBUCO - RECIFE',
      value: 13631538000499,
    },
    {
      label: 'MINAS GERAIS - POUSO ALEGRE',
      value: 13631538000650,
    },
    {
      label: 'SANTA CATARINA - ITAJAÍ',
      value: 13631538000308,
    },
    {
      label: 'SÃO PAULO - SÃO PAULO',
      value: 13631538000227,
    },
  ];

  const operationTypeValues = [
    {
      label: 'Conta e Ordem',
      value: 'CONTA_E_ORDEM',
    },
    {
      label: 'Encomenda',
      value: 'ENCOMENDA',
    },
    {
      label: 'Gestão',
      value: 'GESTAO',
    },
  ];

  const companyOperationSelectTransformer = (values) => {
    let result = [];
    values.map((value) => {
      result.push({
        label: value.documentNumber,
        value: value.documentNumber,
        subscriptionState: value.subscriptionState,
      });
    });

    return result;
  };

  const selectCustomStyles = () => ({
    control: (provided) => ({
      ...provided,
      backgroundColor: '#F2F2F2',
    }),
  });

  useEffect(() => {
    if (values && values.registration && values.registration.companyBranches && values.registration.companyBranches.length > 0) {
      setInputCompanyBranches(values.registration.companyBranches);
    }
  }, [values]);

  return (
    <TabPanel p="30px" direction="column">
      <Flex direction="column" gap="20px" w="full">
        <Grid templateColumns="repeat(2, 1fr)" gap="20px">
          <FormControl>
            <LabelDefault text="Filial Vendemmia" />
            <Select
              placeholder="Selecione a filial Vendemmia"
              name="registration.vendemmiaOperationUnit"
              isMulti
              styles={selectCustomStyles()}
              isDisabled={isPreviwer}
              options={vendemmiaOperationUnitValues}
              value={values.registration.vendemmiaOperationUnit}
              onChange={(option) => {
                setFieldValue('registration.vendemmiaOperationUnit', option);
              }}
            />

            <ErrorMessage component="p" className="error-message-error" name="registration.vendemmiaOperationUnit" />
          </FormControl>

          <FormControl>
            <LabelDefault text="Tipo de Operação" />
            <Select
              placeholder="Selecione o tipo de Operação"
              name="registration.operationType"
              styles={selectCustomStyles()}
              isDisabled={isPreviwer}
              options={operationTypeValues}
              value={operationTypeValues.find((selected) => selected.label === values.registration.operationType.label)}
              onChange={(option) => {
                setFieldValue('registration.operationType', option);
              }}
            />

            <ErrorMessage component="p" className="error-message-error" name="registration.operationType" />
          </FormControl>

          <FormControl>
            <LabelDefault text="Razão Social do Cliente" />
            <InputDefault
              placeholder="Preencha a razão social"
              name="registration.companyName"
              id="companyName"
              isDisabled={isPreviwer}
              setFieldValue={setFieldValue}
              value={values.registration.companyName}
            />

            <ErrorMessage component="p" className="error-message-error" name="registration.companyName" />
          </FormControl>

          <FormControl>
            <LabelDefault text="Nome Fantasia do Cliente" />
            <InputDefault
              placeholder="Preencha o nome fantasia"
              name="registration.companyFantasyName"
              isDisabled={isPreviwer}
              setFieldValue={setFieldValue}
              value={values.registration.companyFantasyName}
            />

            <ErrorMessage component="p" className="error-message-error" name="registration.companyFantasyName" />
          </FormControl>
        </Grid>

        <Flex direction="column" gap="20px" w="45vw">
          {inputCompanyBranches.map((_, index) => (
            <React.Fragment key={index}>
              <Flex gap="20px" align="flex-end" key={index}>
                <FormControl>
                  <LabelDefault text="CNPJ do Cliente" />
                  <InputDefaultMask
                    placeholder="99.999.999/9999-99"
                    mask="99.999.999/9999-99"
                    name={`companyBranches[${index}].documentNumber`}
                    isDisabled={isPreviwer}
                    id={`companyBranches${index}`}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        setFieldValue,
                        values.registration,
                        e.target.value,
                        'companyBranches',
                        'documentNumber',
                        'registration'
                      )
                    }
                    value={values.registration.documentNumber}
                    defaultValue={values.registration.companyBranches[index]?.documentNumber}
                  />
                </FormControl>

                <FormControl>
                  <LabelDefault text="Inscrição Estadual" />
                  <InputDefault
                    placeholder="Preencha a inscrição estadual"
                    name={`companyBranches[${index}].subscriptionState`}
                    id={`companyBranches[${index}].subscriptionState`}
                    isDisabled={isPreviwer}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        setFieldValue,
                        values.registration,
                        e.target.value,
                        'companyBranches',
                        'subscriptionState',
                        'registration'
                      )
                    }
                    value={values.registration.companyBranches[index]?.subscriptionState}
                  />
                </FormControl>

                {inputCompanyBranches.length > 1 && (
                  <Button
                    px="0px"
                    onClick={() =>
                      handleRemoveChange(
                        setFieldValue,
                        index,
                        values.registration.companyBranches,
                        'companyBranches',
                        inputCompanyBranches,
                        setInputCompanyBranches,
                        'registration'
                      )
                    }
                    className="flex items-center justify-center bg-vermelho_1 text-vermelho_3 border rounded-md border-vermelho_2 h-9 w-10 focus:outline-none hover:bg-vermelho_2 hover:transition duration-200"
                    type="button">
                    <MdDelete color="#E52359" />
                  </Button>
                )}
              </Flex>
              <ErrorMessage component="p" className="error-message-error" name={`registration.companyBranches`} />
            </React.Fragment>
          ))}

          <Button
            w="auto"
            h="40px"
            fontSize="16px"
            borderRadius="5px"
            textColor="#2ECC71"
            bgColor="#2ECC7152"
            border="1px dashed #2ECC71"
            isDisabled={isPreviwer}
            _hover={{ bgColor: 'transparent', shadow: 'lg' }}
            leftIcon={<MdAdd color="#2ECC71" size={15} />}
            onClick={() => handleAddClick(inputCompanyBranches, setInputCompanyBranches, values)}>
            Adicionar CNPJ
          </Button>

          <Grid templateColumns="repeat(2, 1fr)" gap={10} alignItems="end">
            <FormControl>
              <LabelDefault text="Selecione o CNPJ principal do Cliente" />
              <Select
                styles={selectCustomStyles()}
                isDisabled={isPreviwer}
                name="registration.companyOperationDocumentNumber"
                value={values.registration.companyOperationDocumentNumber}
                options={companyOperationSelectTransformer(values.registration.companyBranches)}
                onChange={(option) => {
                  setFieldValue('registration.companyOperationDocumentNumber', option);
                  setFieldValue('registration.companyOperationSubscriptionState', option.subscriptionState);
                }}
              />

              <ErrorMessage component="p" className="error-message-error" name="registration.companyOperationDocumentNumber" />
            </FormControl>

            <FormControl>
              <LabelDefault text="Inscrição Estadual" />
              <InputDefault
                placeholder="Inscrição Estadual"
                isDisabled={true}
                value={values.registration.companyOperationSubscriptionState}
              />

              <ErrorMessage component="p" className="error-message-error" name="registration.companyOperationDocumentNumber" />
            </FormControl>
          </Grid>
        </Flex>
      </Flex>
    </TabPanel>
  );
};

export default RegistrationDataForm;
