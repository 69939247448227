import React from 'react';

import { MdArrowDropDown } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Button, Flex, Modal, ModalBody, ModalContent, Select, Text, useDisclosure } from '@chakra-ui/react';

const ModalChangeRole = ({
  roleOptions = [],
  entity = {},
  fields,
  setFields,
  callback
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleClick = (userDecision) => {
    callback(userDecision);
    onClose();
  };

  return (
    <>
      <Flex alignItems="center" cursor="pointer">
        <Select
          key={entity.identifier}
          icon={<MdArrowDropDown />}
          variant="primary"
          h={{ base: '30px', md: '48px'}}
          className="input-register"
          color="#0F0A1D66"
          defaultValue={entity.role}
          onChange={(event) => {
            if (!event.target.value) {
              toast.error('Você deve selecionar um perfil');
              return;
            }

            setFields({ ...fields, role: {
              label: event.target.value,
              value: roleOptions.find(e => e.value === event.target.value)?.value,
              slug: roleOptions.find(e => e.value === event.target.value)?.slug
            }});
            onOpen();
          }}
        >
          {roleOptions.map((role, key) => (
            <option
              key={role.value + key}
              value={role.value}
            >
              {role.label}
            </option>
          ))}
        </Select>
      </Flex>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="lg">
        <ModalContent p="10px 35px" borderRadius="50px">
          <ModalBody p="35px" position="relative">
            <Flex direction="column" alignItems="center" justifyContent="center">
              <Text
                textStyle="secondaryTitle"
                fontWeight="medium"
                textColor="#0F0A1D"
                textAlign="center"
                mb="20px"
                className="up-anim"
              >
                Você tem certeza que deseja alterar o perfil <strong>{entity.name}</strong> de {""}
                <strong>{entity.roleHumanReadable}</strong> para {""}
                <strong>{roleOptions.find(e => e.value === fields.role.value)?.label}</strong>?
              </Text>
              <Flex>
                <Button
                  variant="secundary"
                  w="fit-content"
                  borderRadius="7px"
                  m="15px"
                  p="25px 35px"
                  onClick={() => handleClick(false)}>
                  Cancelar
                </Button>
                <Button
                  variant="primary"
                  w="fit-content"
                  borderRadius="7px"
                  m="15px"
                  p="25px 35px"
                  onClick={() => handleClick(true)}>
                  Continuar
                </Button>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalChangeRole;
