import React, { useEffect, useState } from 'react';

import {
  AccordionButton,
  AccordionPanel,
  AccordionItem,
  Tag,
} from '@chakra-ui/react'
import { Flex, Image, Text } from '@chakra-ui/react';

import KanbanItem from './KanbanItem';
const KanbanColumn = ({ stage, list, delay, isFirstColumn, isLastColumn }) => {
  const windowWidth = window.innerWidth
  const [showMore, setShowMore] = useState(false);
  const [sizeWidth, setSizeWidth] = useState('')

  // const cardLimit = 20;

  const getStageName = () => {
    switch (stage) {
      case 1:
        return 'Pré-embarque';
      case 2:
        return 'Trânsito';
      case 3:
        return 'Desembaraço';
      case 4:
        return 'Faturamento';
      case 5:
        return 'Transporte';
      case 6:
        return 'Entrega';
      default:
        return 'Aguardando';
    }
  };

  return (
    <>
      {windowWidth > 768 ? (
        /* desktop */
        <Flex
          direction="column"
          w="100%"
          alignItems="center"
          minH="calc(100vh - 260px)"
          style={{ '--delay': `${delay / 10}s`, transformOrigin: 'left' }}
          className="up-anim"
          borderLeft={isFirstColumn ? 'none' : '#70707036 solid 1px'}
          borderRight={isLastColumn ? 'none' : '#70707036 solid 1px'}>
          <Flex bg="white" p="15px" w="100%" alignItems="center" justify="space-between">
            <Image src={require(`../../../../assets/images/kanban-${stage}.svg`)} />

            <Text fontSize="16px" color="#422C76" fontWeight="medium">
              {getStageName()}
            </Text>

            <Text fontSize="14px" color="#422C76">
              {list?.length}
            </Text>
          </Flex>

          <Flex direction="column" bg="white" h="100%" w="100%" gap="15px" p="15px" alignItems="center">
            {list?.length > 0 &&
              list.map((process, key) => (
                // (key < cardLimit || showMore) &&
                <KanbanItem entity={process} key={key} delay={key + delay} />
              ))}
            {/* {list?.length > cardLimit && (
              <Text color="#6794DC" cursor="pointer" onClick={() => setShowMore(!showMore)}>
                {showMore ? 'Ver menos' : `Ver mais (${list.length - cardLimit})`}
              </Text>
            )} */}
          </Flex>
        </Flex>
      ) : (
        /* mobile */
        <AccordionItem className='accordion-w-full'>
          <Flex
            direction="column"
            w="100%"
            alignItems="center"
            style={{ '--delay': `${delay / 10}s`, transformOrigin: 'left' }}
            className="up-anim"
            borderLeft='#70707036 solid 1px'
            borderRight='#70707036 solid 1px'>
            <AccordionButton _expanded={{ bg: 'linkColor', color: 'white' }}>
              <Flex bg="white" p="15px" w="100%" alignItems="center" justify="space-between">
              
                <Image src={require(`../../../../assets/images/kanban-${stage}.svg`)} />

                <Text fontSize="16px" color="#422C76" fontWeight="medium">
                  {getStageName()}
                </Text>

                <Tag fontSize="14px" color="#422C76">
                  {list?.length}
                </Tag>
              </Flex>
            </AccordionButton>

            <AccordionPanel w="100%">
              <Flex direction="column" bg="white" h="100%" w="100%" gap="15px" p="10px 0 0 0" alignItems="center">
                {list?.length > 0 ? (
                  list.map((process, key) => (
                    // (key < cardLimit || showMore) &&
                    <KanbanItem entity={process} key={key} delay={key + delay} />
                  ))
                ) : (
                  
                  <p>Não há registros disponíveis.</p>
                )}
                {/* {list?.length > cardLimit && (
                  <Text color="#6794DC" cursor="pointer" onClick={() => setShowMore(!showMore)}>
                    {showMore ? 'Ver menos' : `Ver mais (${list.length - cardLimit})`}
                  </Text>
                )} */}
              </Flex>
            </AccordionPanel>
          </Flex>
        </AccordionItem>
        ) 
      }
    </>
  );
};

export default KanbanColumn;
