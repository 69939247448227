import React from 'react';

import { Image } from '@chakra-ui/image';
import { Box, Flex, Text } from '@chakra-ui/layout';

import progressBallGreen from '../../../assets/images/progressBallGreen.svg';
import progressBallGrey from '../../../assets/images/progressBallGrey.svg';
import progressBallPurple from '../../../assets/images/progressBallPurple.svg';

const DetailsStap = ({ title, desc, delay, type, hasProgress = true }) => {
  const resolution = window.innerWidth

  const getBall = () => {
    switch (type) {
      case 'green':
        return progressBallGreen;
      case 'grey':
        return progressBallGrey;
      default:
        return progressBallPurple;
    }
  };
  const getColor = () => {
    switch (type) {
      case 'green':
      case 'grey':
        return 'linear-gradient(90deg, #e6646500 50%, #0F0A1D1C 51% )';
      default:
        return '#6C48C2';
    }
  };

  return (
    <Flex
      direction={{ base: "row", md: "column" }}
      gap="18px"
      mb={{ base: "0", md: "35px" }}
      mt={{ base: "0", md: "35px" }}
      className="timeline-step"
      style={{ '--delay': delay }}
      w="100%">
      <Flex 
        align="center" 
        minW="100px" 
        w="100%"
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <Image src={getBall()} />
        {hasProgress && 
          <Box 
            h={{ base: '70px', md: "4px" }} 
            bg={getColor()} 
            bgSize="16%" 
            w={{ base: '4px', md: "full" }} 
            border={resolution > 768 ? (
              '0'
            ) : (
              (type != '') ? "2px dashed #e0dfe1" : '0'
            ) }
          />
        }
      </Flex>

      <Flex 
        gap="2px" 
        direction="column" 
        maxW="90px" 
        minWidth="120px"
        pt={{ base: '5px', md: "0" }}
      >
        <Text
          textStyle="paragraph"
          textColor={type === 'grey' ? '#0F0A1D54' : '#0F0A1D'}
          fontWeight="medium"
          whiteSpace="pre-wrap">
          {title}
        </Text>
        <Text textStyle="paragraph" textColor={type === 'grey' ? '#0F0A1D44' : '#0f0a1db3'} whiteSpace="pre-wrap">
          {desc}
        </Text>
      </Flex>
    </Flex>
  );
};

export default DetailsStap;
