import React from 'react';

import { Link } from 'react-router-dom';

import { Flex, Image, Text, Tooltip } from '@chakra-ui/react';

import gestao from '../../../../assets/images/gestao-ball.png';
import manual from '../../../../assets/images/proprio-ball.png';
import api from '../../../../assets/images/vendemmia-ball.png';
import { limitChars } from '../../../../utils/strings';
import UpdateInfoTag from '../../../dashboard/components/UpdateInfoTag';

const CalendarEventItem = ({ entity }) => {
  const getIcon = () => {
    switch (entity.originDescription) {
      case 'api':
        return api;
      case 'manual':
        return manual;
      case 'gestao':
        return gestao;
      default:
        return api;
    }
  };

  return (
    <Link
      to={`/comex/process/detail/${entity.processIdentifier}`}
      style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
      className="up-anim">
      <Flex
        direction="column"
        p="10px"
        bg="#F5F5F5"
        w="100%"
        borderRadius="8px"
        transition={'.3s'}
        _hover={{ transform: 'scale(1.05)', boxShadow: '0px 2px 20px #00000044' }}>
        <Flex gap="4px" w="full">
          <UpdateInfoTag important={true} isVendemmia={entity.originDescription !== 'api'}>
            {entity.processCode}
          </UpdateInfoTag>

          {entity.clientInternalCode && (
            <UpdateInfoTag>
              <Tooltip label={entity.clientInternalCode} aria-label={entity.clientInternalCode}>
                {limitChars(entity.clientInternalCode, 8)}
              </Tooltip>
            </UpdateInfoTag>
          )}
        </Flex>

        <Flex mt="10px" align="center" gap="10px">
          <Image src={getIcon()} borderRadius="100%" w="33px" h="33px" />

          <Text textStyle="subtitle" textColor="#0F0A1D">
            {entity.stageDescription}
          </Text>
        </Flex>
      </Flex>
    </Link>
  );
};

export default CalendarEventItem;
