import React from 'react';

import { MdInsertDriveFile, MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

import { Box, Collapse, Flex, Table, Tbody, Td, Th, Thead, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';

import { limitChars } from '../../../../utils/strings';

import ModalViewFile from './ModalViewFile';

const ExpeditionList = ({ entity, count }) => {
  const { isOpen, onToggle } = useDisclosure();

  const Icon = isOpen ? <MdKeyboardArrowUp color="#707070" /> : <MdKeyboardArrowDown color="#707070" />;

  const handleColor = (text) => {
    switch (text) {
      case 'FATURADO':
        return 'status-100';
      case 'COLETA INICIADA':
        return 'status-200';
      case 'A':
      case 'PROCESSADO':
        return 'status-300';
      case 'B':
      case 'EXPEDIDO':
        return 'status-900';
      case 'C':
      case 'CANCELADO':
        return 'red';
      default:
        return '';
    }
  };

  return (
    <>
      <Tr onClick={onToggle} className={`border`} cursor="pointer">
        <Td>
          <Box>{Icon}</Box>
        </Td>
        <Td>
          <Box>
            <Flex
              align="center"
              borderRadius="15px"
              justifyContent="center"
              bgColor={handleColor(entity?.statusNf)}
              color="white"
              w={'9rem'}
              h="2rem">
              {entity?.statusNf?.length > 13 ? (
                <Tooltip label={entity.statusNf}>{limitChars(entity.statusNf, 13) ?? '-'}</Tooltip>
              ) : (
                entity?.statusNf ?? '-'
              )}
            </Flex>
          </Box>
        </Td>
        <Td>
          <Box>{entity?.usuarioConferencia ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.stateCodeFormatted ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.nuNota ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.serieNf ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.idPedido ?? '-'}</Box>
        </Td>
        <Td>
          <Box minW="300px">
            {entity?.nomeDestinatario?.length > 50 ? (
              <Tooltip label={entity.nomeDestinatario}>{limitChars(entity.nomeDestinatario, 50) ?? '-'}</Tooltip>
            ) : (
              entity?.nomeDestinatario ?? '-'
            )}
          </Box>
        </Td>
        <Td>
          <Box>{entity?.ufDestinatario ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.recipientCity ?? '-'}</Box>
        </Td>
        <Td>
          <Box minW="300px">
            {entity?.nomeDepositante?.length > 50 ? (
              <Tooltip label={entity.nomeDepositante}>{limitChars(entity.nomeDepositante, 50) ?? '-'}</Tooltip>
            ) : (
              entity?.nomeDepositante ?? '-'
            )}
          </Box>
        </Td>
        <Td>
          <Box minW="300px">
            {entity?.nomeTransportadora?.length > 50 ? (
              <Tooltip label={entity.nomeTransportadora}>{limitChars(entity.nomeTransportadora, 50) ?? '-'}</Tooltip>
            ) : (
              entity?.nomeTransportadora ?? '-'
            )}
          </Box>
        </Td>
        <Td>
          <Box>{entity?.dtFaturamentoFormatted ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.dtFimColetaFormatted ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.embLiberado ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.carga ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.qtVolumesFormatted ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.valorNf ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.cubagemM3Formatted ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.weightFormatted ?? '-'}</Box>
        </Td>
        <Td>
          <Box>
            {entity?.observacao?.length > 50 ? (
              <Tooltip label={entity?.observacao}>
                <span>{limitChars(entity?.observacao, 50)}</span>
              </Tooltip>
            ) : (
              entity?.observacao ?? '-'
            )}
          </Box>
        </Td>
        <Td>
          <Flex gap="10px">
            <ModalViewFile
              type="romaneio"
              label="Ver arquivo romaneio de coleta"
              expedition={entity}
              ctaButton={<MdInsertDriveFile color="#422C76" size={25} />}
            />

            <ModalViewFile
              type="canhoto"
              label="Ver arquivo de canhoto"
              expedition={entity}
              ctaButton={<MdInsertDriveFile color="#C767DC" size={25} />}
            />
          </Flex>
        </Td>
      </Tr>
      <Tr w="full">
        <Td
          p="0"
          colSpan={15}
          border="none"
          style={{
            padding: 0,
          }}>
          <Collapse in={isOpen} animateOpacity>
            <Box bgColor="#F9F9F9" m="1.2rem">
              <Table>
                <Thead border="0" borderBottom="1px" h={50}>
                  <Tr>
                    <Th>Código</Th>
                    <Th>Descrição</Th>
                    <Th>Quantidade</Th>
                    <Th>Valor</Th>
                    <Th>Lote Indústria</Th>
                    <Th>Número de série</Th>
                    <Th>Curva ABC</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {entity?.itens && entity?.itens?.length > 0 ? (
                    entity?.itens?.map((item, key) => {
                      return (
                        <React.Fragment key={key}>
                          <Tr>
                            <Td>{item?.codProduto ?? '-'}</Td>
                            <Td>{item?.descricaoProduto ?? '-'}</Td>
                            <Td>{item?.qtdeExpedidaFormatted ?? '-'}</Td>
                            <Td>{item?.totalValueFormatted ?? '-'}</Td>
                            <Td>{item?.loteIndustrial ?? '-'}</Td>
                            <Td>{item?.infoEspecifica ?? '-'}</Td>
                            <Td>
                              <Box>
                                <Flex
                                  align="center"
                                  justifyContent="center"
                                  borderRadius="15px"
                                  bgColor={handleColor(item?.inAbc)}
                                  color="white"
                                  w="3rem"
                                  h="2rem">
                                  {item?.inAbc ?? '-'}
                                </Flex>
                              </Box>
                            </Td>
                          </Tr>
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <Tr>
                      <Td colSpan={6}>
                        <Flex justify="center" my="25px">
                          Nenhum dado encontrado para os parâmetros filtrados.
                        </Flex>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Box>
          </Collapse>
        </Td>
      </Tr>
    </>
  );
};

export default ExpeditionList;
