const taxList = {
  data: [
    {
      identifier: '1234568',
      branch: 'Santa Catarina',
      client: 'Empresa de tecnologia',
      modality: 'Conta e ordem',
      benefits: 'TTD-SC',
      request: '19/09/2023',
      validade: '19/12/2023',
      status: 'Em validação',
      createdAt: '2023-09-14',
    },
    {
      identifier: '9876543',
      branch: 'São Paulo',
      client: 'Companhia de software',
      modality: 'Conta corrente',
      benefits: 'CC-SP',
      request: '20/09/2023',
      validade: '20/12/2023',
      status: 'Aprovado',
      createdAt: '2023-09-14',
    },
    {
      identifier: '5678901',
      branch: 'Rio de Janeiro',
      client: 'Empresa de logística',
      modality: 'Conta de investimento',
      benefits: 'CI-RJ',
      request: '21/09/2023',
      validade: '21/12/2023',
      status: 'Em análise',
      createdAt: '2023-09-14',
    },
    {
      identifier: '3456789',
      branch: 'Minas Gerais',
      client: 'Indústria de manufatura',
      modality: 'Conta poupança',
      benefits: 'CP-MG',
      request: '22/09/2023',
      validade: '22/12/2023',
      status: 'Reprovado',
      createdAt: '2023-09-14',
    },
    {
      identifier: '1122334',
      branch: 'Bahia',
      client: 'Comércio varejista',
      modality: 'Conta digital',
      benefits: 'CD-BA',
      request: '23/09/2023',
      validade: '23/12/2023',
      status: 'Aprovado',
      createdAt: '2023-09-14',
    },
    {
      identifier: '9988776',
      branch: 'Paraná',
      client: 'Serviços financeiros',
      modality: 'Conta conjunta',
      benefits: 'CCJ-PR',
      request: '24/09/2023',
      validade: '24/12/2023',
      status: 'Em validação',
      createdAt: '2023-09-14',
    },
    {
      identifier: '6655443',
      branch: 'Ceará',
      client: 'Telecomunicações',
      modality: 'Conta salário',
      benefits: 'CS-CE',
      request: '25/09/2023',
      validade: '25/12/2023',
      status: 'Em análise',
      createdAt: '2023-09-14',
    },
    {
      identifier: '2233445',
      branch: 'Goiás',
      client: 'Educação',
      modality: 'Conta de estudante',
      benefits: 'CE-GO',
      request: '26/09/2023',
      validade: '26/12/2023',
      status: 'Reprovado',
      createdAt: '2023-09-14',
    },
    {
      identifier: '7890123',
      branch: 'Amazonas',
      client: 'Saúde',
      modality: 'Conta de saúde',
      benefits: 'CS-AM',
      request: '27/09/2023',
      validade: '27/12/2023',
      status: 'Aprovado',
      createdAt: '2023-09-14',
    },
    {
      identifier: '4455667',
      branch: 'Pernambuco',
      client: 'Alimentos e bebidas',
      modality: 'Conta corporativa',
      benefits: 'CCO-PE',
      request: '28/09/2023',
      validade: '28/12/2023',
      status: 'Em validação',
      createdAt: '2023-09-14',
    },
    {
      identifier: '1122334',
      branch: 'Sergipe',
      client: 'Construção civil',
      modality: 'Conta empresarial',
      benefits: 'CEM-SE',
      request: '29/09/2023',
      validade: '29/12/2023',
      status: 'Aprovado',
      createdAt: '2023-09-14',
    },
  ],
};

export default taxList;