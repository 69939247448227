import React, { useEffect, useRef, useState } from 'react';

import { MdAdd } from 'react-icons/md';

import { Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react';

import credit from '../../../assets/images/credit-analysis.svg';
import Card from '../../../components/Card/Card';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsCreditAnalysis } from '../../../utils/filters/filterPresets';

import CreditCard from './components/CreditCard';
import CreditDrawerNew from './components/CreditDrawerNew';
import CreditTable from './components/CreditTable';

const CreditAnalysisPage = () => {
  const hasPermission = permissions.vendemmiaCreditAnalysis;
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterOptions, setFilterOptions] = useState([]);
  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);
  const [list, setList] = useState([]);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });
  const request = useRef(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const load = (filters, key) => {
    setIsLoading(true);

    requests
      .listCreditAnalysis(filters, metadata.current_page > 0 ? metadata.current_page : 1)
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }

        request.current = key;
        setList(data.data);
        setMetadata(data.meta);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);
  };

  const getFilterOptions = async () => {
    setFilterOptions(filterOptionsCreditAnalysis);
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  return (
    <Page
      screen="credit"
      title="Análise de Crédito"
      breadcrumbIcon={credit}
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      textFilterPlaceholder="CNPJ, Nome Fantasia, Razão Social"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading}
      allowEmptySearchPeriod={true}
      showPeriodFilter={false}
      forceLoadTrigger={forceLoadTrigger}>
      <Card
        m="10px"
        header={
          <Flex gap="2px" direction="column">
            <Text textStyle="cardTitle" fontWeight="bold" color="primary">
              Análise de Crédito
            </Text>
            <Text textStyle="subtitle" color="#BEBEBE">
              Existe um total de {metadata?.item_count} análises
            </Text>
          </Flex>
        }>
        <Box p="15px">
          <ScreenLoader isLoading={isLoading}>
            {!isMobile ? (
              <CreditTable
                list={list}
                action={forceLoadTrigger}
                setAction={setForceLoadTrigger}
                metadata={metadata}
                setMetadata={setMetadata}
              />
            ) : (
              <CreditCard
                list={list}
                action={forceLoadTrigger}
                setAction={setForceLoadTrigger}
                metadata={metadata}
                setMetadata={setMetadata}
              />
            )}
          </ScreenLoader>
        </Box>
        <Flex className="float-button-modal">
          <Button
            bgColor="green"
            color="white"
            borderRadius="50px"
            shadow="xl"
            h="50px"
            py="15px"
            px="20px"
            onClick={() => {
              onOpen();
            }}
            _hover={{ opacity: '0.5', transition: '0.9s' }}>
            <MdAdd fontSize="1.5rem" />
          </Button>

          <CreditDrawerNew isOpen={isOpen} onClose={onClose} action={forceLoadTrigger} setAction={setForceLoadTrigger} />
        </Flex>
      </Card>
    </Page>
  );
};

export default CreditAnalysisPage;
