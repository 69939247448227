import React, { useEffect, useState } from 'react';

import moment from 'moment/moment';
import { MdOutlineInsertDriveFile } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Accordion, Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react';

import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../../services/requests';
import { filterOptionsAddTravel } from '../../../../../utils/filters/filterPresets';

import CardFreight from './CardFreight';
import CardGeneralInformation from './CardGeneralInformation';
import CardMainData from './CardMainData';
import CardTrip from './CardTrip';
import ModalFreightConfirm from './ModalFreightConfirm';
import StepRegister from './StepRegister';

const RegisterFreight = ({
  title,
  subTitle,
  entity,
  onCancelled,
  action,
  setAction,
  setOrigin,
  setDestination,
  tab,
  setGeolocationList,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadSecond, setLoadSecond] = useState(false);
  const [step, setStep] = useState(0);

  const [ctesSelected, setCtesSelected] = useState([]);

  const [filterOptions, setFilterOptions] = useState([]);

  const [trajectories, setTrajectories] = useState(entity?.trajectories ?? []);
  const [addressList, setAddressList] = useState({});

  const [cardMainDataHasError, setCardMainDataHasError] = useState(false);
  const [cardTripHasError, setCardTripHasError] = useState(false);
  const [cardFreightHasError, setCardFreightHasError] = useState(false);
  const [cardDatatHasError, setCardDatatHasError] = useState(false);

  const [formMainData, setFormMainData] = useState({});
  const [formCardTrip, setFormCardTrip] = useState({});
  const [formCardFreight, setFormCardFreight] = useState({});
  const [formCardData, setFormCardData] = useState({});

  const [formData, setFormData] = useState(null);
  const [formConfig, setFormConfig] = useState(null);
  const [freightValues, setFreightValues] = useState({});

  const [formErrorMainData, setFormErrorMainData] = useState(false);
  const [formErrorTrip, setFormErrorTrip] = useState(false);
  const [formErrorFreight, setFormErrorFreight] = useState(false);
  const [formErrorData, setFormErrorData] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsAddTravel());
  };

  const handleSubmit = (status) => {
    setIsLoading(true);
    setLoadSecond(true);

    const toastId = toast.info(`Aguarde enquanto ${entity?.identifier?.length > 0 ? 'editamos' : 'geramos'} a viagem...`, {
      autoClose: false, // Mantém o toast na tela até que seja fechado manualmente
      closeOnClick: false, // Impede que o usuário feche o toast ao clicar nele
      draggable: false, // Impede que o usuário arraste o toast
      closeButton: false, // Não exibe o botão de fechar o toast
    });

    if (formCardTrip && formCardFreight && formCardData && formMainData && Object.keys(formMainData).length > 0) {
      if (entity?.identifier?.length > 0) {
        requests
          .editDriverFreight(
            entity?.identifier,
            {
              externalCode: formMainData.externalCode,
              shippingCompanyName: formMainData?.shippingCompanyName,
              clients: formMainData.clients,
              modality: formMainData.modality,
              driver: formCardData.driver,
              vehicle: formCardData.vehicle,
              startsAt: formCardData.startsAt ? moment(formCardData.startsAt).format('DD/MM/yyyy HH:mm:ss') : null,
              endsAt: formCardData.endsAt ? moment(formCardData.endsAt).format('DD/MM/yyyy HH:mm:ss') : null,
              freightValue: freightValues?.freightValue,
              trajectories: trajectories,
            },
            status
          )
          .then((response) => {
            setFormCardData({
              ...formCardData,
              code: response.code,
            });

            if (formData !== null) {
              requests.uploadCTE(response.identifier, formData, formConfig);
            }

            toast.dismiss(toastId); // Remove o toast exibido anteriormente
            toast.success(`Viagem #${entity.code} editada com sucesso.`);

            if (status === 'draft') {
              onCancelled();
              onClose();

              if (tab === 'kanban') {
                setAction(!action);
              } else {
                setAction(action + 1);
              }
            } else {
              onClose();
              onCancelled();

              if (tab === 'kanban') {
                setAction(!action);
              } else {
                setAction(action + 1);
              }
            }
          })
          .catch(() => {
            toast.dismiss(toastId); // Remove o toast exibido anteriormente
            toast.error(`Problemas ao editar viagem, tenve noamente ou entre em contato com o Administrador.`);
            setIsLoading(false);
            setLoadSecond(false);
            onClose();
          });
      } else {
        let fileValues = [];

        trajectories.forEach((trajectory, index) => {
          if (trajectory?.driverFreightFiles?.length > 0) {
            trajectory?.driverFreightFiles.forEach((file) => {
              fileValues.push(file?.value);
            });
          }
        });

        const auxTrajectories = trajectories;
        const updatedTrajectories = auxTrajectories.map((trajectory) => {
          return {
            ...trajectory,
            driverFreightFiles: fileValues,
          };
        });

        requests
          .addDriverFreight(
            {
              clients: formMainData.clients,
              externalCode: formMainData.externalCode,
              shippingCompanyName: formMainData?.shippingCompanyName,
              modality: formMainData.modality,
              trajectories: updatedTrajectories,
              freightValue: freightValues?.freightValue,
              merchandiseValue: freightValues?.merchandiseValue,
              merchandiseDamagedValue: freightValues?.merchandiseDamagedValue,
              cubage: freightValues?.cubage,
              driver: formCardData.driver,
              vehicle: formCardData.vehicle,
              startsAt: formCardData.startsAt ? moment(formCardData.startsAt).format('DD/MM/yyyy HH:mm:ss') : null,
              endsAt: formCardData.endsAt ? moment(formCardData.endsAt).format('DD/MM/yyyy HH:mm:ss') : null,
            },
            status
          )

          .then((response) => {
            setFormCardData({
              ...formCardData,
              code: response.code,
            });

            if (formData != null) {
              requests.uploadCTE(response.identifier, formData, formConfig);
            }

            toast.dismiss(toastId);
            toast.success(`Viagem #${response.code} cadastrada com sucesso`);

            if (status == 'draft') {
              onCancelled();
              onClose();

              if (tab === 'kanban') {
                setAction(!action);
              } else {
                setAction(action + 1);
              }
            } else {
              onClose();
              onCancelled();

              if (tab === 'kanban') {
                setAction(!action);
              } else {
                setAction(action + 1);
              }
            }
          })
          .catch(() => {
            toast.dismiss(toastId); // Remove o toast exibido anteriormente
            toast.error(`Problemas ao cadastrar viagem, tenve noamente ou entre em contato com o Administrador.`);
            setIsLoading(false);
            setLoadSecond(false);
            onClose();
          });
      }
    } else {
      setFormErrorMainData(true);
      setFormErrorTrip(true);
      setFormErrorFreight(true);
      setFormErrorData(true);

      setIsLoading(false);
      setLoadSecond(false);
      onClose();

      toast.dismiss(toastId); // Remove o toast exibido anteriormente
      toast.error('Algo deu errado, por favor, revise os dados inseridos e clique em salvar.');

      return false;
    }
  };

  const getGeolocations = async () => {
    let list = { ...addressList };

    for (let key in list) {
      let position = await requests.getGeolocationByAddress(list[key]).then((data) => {
        return data.position;
      });

      list[key] = {
        address: list[key],

        position: position,
      };
    }

    setGeolocationList(list);
  };

  useEffect(() => {
    if (trajectories?.length > 0 && Object.keys(addressList).length == 0) {
      let aux = {};
      trajectories?.map(async (item, key) => {
        let position = {
          lat: item.latitude,
          lng: item.longitude,
        };

        if (position.latitude == null && position.longitude == null) {
          position = await requests.getGeolocationByAddress(item.address).then((data) => {
            return data.position;
          });
        }

        aux[key] = {
          address: item.address,
          position: position,
        };
      });

      setGeolocationList(aux);
    }
  }, [trajectories]);

  useEffect(() => {
    getFilterOptions();
  }, []);

  useEffect(() => {
    if (addressList) {
      getGeolocations();

      return () => {};
    }
  }, [addressList]);

  return (
    <Flex direction="column" py="15px" px="10px">
      <Flex direction="column">
        <Text textStyle="secondaryTitle" textColor="#0F0A1D" fontWeight="medium" className="up-anim">
          {title}
        </Text>

        <Text textStyle="subtitle" textColor="#0F0A1D80" className="up-anim">
          {subTitle}
        </Text>
      </Flex>

      <Flex direction="column" h="600px" overflowY="auto" mt="10px">
        <Accordion defaultIndex={[0]} index={step}>
          <ScreenLoader isLoading={isLoading}>
            <Flex gap="10px" direction="column">
              <StepRegister
                title="Dados principais"
                step={step}
                error={cardMainDataHasError}
                stepIndex={0}
                onNext={() => {
                  setStep(0);
                }}>
                <CardMainData
                  entity={entity}
                  filterOptions={filterOptions}
                  setStep={setStep}
                  formErrorMainData={formErrorMainData}
                  setFormErrorMainData={setFormErrorMainData}
                  setFormMainData={setFormMainData}
                  setHasError={setCardMainDataHasError}
                  ctesSelected={ctesSelected}
                  setCtesSelected={setCtesSelected}
                />
              </StepRegister>

              <StepRegister
                title="Trechos da viagem"
                error={cardTripHasError}
                stepIndex={1}
                step={step}
                onNext={() => {
                  setStep(1);
                }}>
                <CardTrip
                  setFormErrorTrip={setFormErrorTrip}
                  formErrorTrip={formErrorTrip}
                  setHasError={setCardTripHasError}
                  setStep={setStep}
                  setFormCardTrip={setFormCardTrip}
                  setTrajectories={setTrajectories}
                  setOrigin={setOrigin}
                  setDestination={setDestination}
                  trajectories={entity?.trajectoriesKanban}
                  formData={formData}
                  setFormData={setFormData}
                  setFormConfig={setFormConfig}
                  addressList={addressList}
                  setAddressList={setAddressList}
                  ctesSelected={ctesSelected}
                  setCtesSelected={setCtesSelected}
                />
              </StepRegister>

              <StepRegister
                title="Pagamento do frete"
                error={cardFreightHasError}
                stepIndex={2}
                step={step}
                onNext={() => {
                  setStep(2);
                }}>
                <CardFreight
                  entity={entity}
                  setHasError={setCardFreightHasError}
                  formErrorFreight={formErrorFreight}
                  setFormErrorFreight={setFormErrorFreight}
                  setStep={setStep}
                  setFormCardFreight={setFormCardFreight}
                  freightValues={freightValues}
                  setFreightValues={setFreightValues}
                />
              </StepRegister>

              <StepRegister
                title="Dados gerais"
                error={cardDatatHasError}
                stepIndex={3}
                step={step}
                onNext={() => {
                  setStep(3);
                }}>
                <CardGeneralInformation
                  entity={entity}
                  setHasError={setCardDatatHasError}
                  formErrorData={formErrorData}
                  setFormErrorData={setFormErrorData}
                  setStep={setStep}
                  setFormCardData={setFormCardData}
                />
              </StepRegister>
            </Flex>
          </ScreenLoader>
        </Accordion>
      </Flex>

      <Flex flexDirection={{ base: 'column', md: 'row' }} justify="space-between" gap={{ base: '2', md: '2' }} mt="10px">
        {!entity?.identifier && (
          <Button
            leftIcon={<MdOutlineInsertDriveFile />}
            border="1px"
            px="20px"
            py="9px"
            color="#5289C9"
            borderColor="#5289C9"
            bgColor="transparent"
            loadingText="Salvando..."
            isDisabled={isLoading}
            isLoading={isLoading}
            onClick={() => {
              handleSubmit('draft');
            }}>
            Salvar como rascunho
          </Button>
        )}

        <Box
          display={{ base: 'grid', md: 'flex' }}
          gridTemplateColumns={{ base: '1fr 1fr', md: 'none' }}
          gap={{ base: '10px', md: '20px' }}>
          <Button variant="secundary" px="30px" py="9px" onClick={onCancelled} isDisabled={isLoading}>
            Cancelar
          </Button>

          <ModalFreightConfirm
            handleSubmit={handleSubmit}
            formCardData={formCardData}
            formMainData={formMainData}
            trajectories={trajectories}
            freightValues={freightValues}
            isOpen={isOpen}
            onOpen={onOpen}
            load={isLoading}
            onClose={onClose}
            loadSecond={loadSecond}
            filterOptions={filterOptions}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default RegisterFreight;
