import React from 'react';

import { ErrorMessage, Formik } from 'formik';
import { toast } from 'react-toastify';

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import requests from '../../../../services/requests';

const yup = require('yup');

const initialValues = (data) => {
  return {
    label: data?.label || '',
  };
};

const ModalRegisterAdditionalFields = ({ entity, openModal, actionModal, handleSuccess }) => {
  return (
    <Modal isOpen={openModal} onClose={actionModal} isCentered>
      <ModalOverlay />

      <ModalContent maxW="none" w="50vw">
        <ModalHeader>
          <Flex align="center" justify="space-between">
            <Text textStyle="tableTitle" textColor="primary" fontWeight="bold">
              Cadastro de campo adicional
            </Text>

            <ModalCloseButton />
          </Flex>
        </ModalHeader>

        <ModalBody direction="column" justify="center" h="full" gap="10px">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues(entity)}
            validationSchema={yup.object().shape({
              label: yup.string().required('Campo obrigatório'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);

              if (entity) {
                requests.editAditionalField(entity.identifier, values).then(() => {
                  handleSuccess();
                  toast.success('Campo alterado com sucesso!');

                  setSubmitting(false);
                });
              } else {
                requests
                  .addAditionalField(values)
                  .then((response) => {
                    handleSuccess();
                    toast.success('Campo "' + response.label + '" cadastrado com sucesso!');

                    setSubmitting(false);
                  })
                  .catch(() => {
                    setSubmitting(false);
                  });
              }
            }}>
            {({ handleChange, handleSubmit, isSubmitting, values }) => (
              <form onSubmit={handleSubmit}>
                <FormControl>
                  <FormLabel textColor="primary">Título do campo</FormLabel>
                  <Input
                    bg="#F2F2F2"
                    variant="primary"
                    className="input-register"
                    name="label"
                    onChange={handleChange}
                    value={values.label}
                  />

                  <ErrorMessage name="label" component="p" className="error-message-error" />
                </FormControl>

                <Flex mt="20px" justify="flex-end">
                  <Button variant="primary" type="submit" isDisabled={isSubmitting} isLoading={isSubmitting} loadingText="Salvando...">
                    Salvar
                  </Button>
                </Flex>
              </form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalRegisterAdditionalFields;
