import React from 'react';

import { Flex, Image, TabPanel, Table, Tbody, Td, Tr } from '@chakra-ui/react';

import iconEfetiva from '../../../../../assets/svgs/origem-efetiva.svg';
import iconEsperada from '../../../../../assets/svgs/origem-esperada.svg';

const InformationTab = ({ entity, setCenter, setZoom }) => {
  return (
    <TabPanel>
      <Table className="table-striped">
        <Tbody>
          <Tr>
            <Td color="#854FFF" fontSize={14}>
              Início
            </Td>
            <Td color="#0F0A1DB3" fontSize={14}>
              <Flex alignItems="flex-start" direction="column">
                <Flex title="Data esperada">
                  {entity?.startsAtFormatted}
                  <Image src={iconEsperada} w="1rem" h="1rem" ml="1" mt="2px" />
                </Flex>
                {entity?.startsEtdFormatted != null && (
                  <Flex title="Data efetiva">
                    {entity?.startsEtdFormatted}
                    {''}
                    <Image src={iconEfetiva} w="1rem" h="1rem" ml="1" mt="2px" />
                  </Flex>
                )}
                {entity?.diffTimeOrigin != null && (
                  <Flex title="Atraso entre a data esperada e a efetiva">Atraso: {entity?.diffTimeOrigin}</Flex>
                )}
              </Flex>
            </Td>
          </Tr>

          <Tr>
            <Td color="#854FFF" fontSize={14}>
              Final
            </Td>
            <Td color="#0F0A1DB3" fontSize={14}>
              <Flex alignItems="flex-start" direction="column">
                {!entity?.endsAtFormatted && !entity?.endsEtdFormatted && (
                  <>
                    <Flex title="Data esperada">Pendente</Flex>
                  </>
                )}
                {entity?.endsAtFormatted != null && (
                  <Flex title="Data esperada">
                    {entity?.endsAtFormatted ? entity?.endsAtFormatted : 'Pendente'}
                    <Image src={iconEsperada} w="1rem" h="1rem" ml="1" mt="2px" />
                  </Flex>
                )}
                {entity?.endsEtdFormatted != null && (
                  <Flex title="Data efetiva">
                    {entity?.endsEtdFormatted ? entity?.endsEtdFormatted : 'Pendente'}
                    <Image src={iconEfetiva} w="1rem" h="1rem" ml="1" mt="2px" />
                  </Flex>
                )}
                {entity?.diffTimeDestination != null && (
                  <Flex title="Atraso entre a data esperada e a efetiva">Atraso: {entity?.diffTimeDestination}</Flex>
                )}
              </Flex>
            </Td>
          </Tr>
          <Tr>
            <Td color="#854FFF" fontSize={14}>
              Frete
            </Td>
            <Td color="#0F0A1DB3" fontSize={14}>
              <Flex alignItems="flex-start" direction="column">
                {entity?.freightValueFormatted ? entity.freightValueFormatted : '-'}
              </Flex>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TabPanel>
  );
};

export default InformationTab;
