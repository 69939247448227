import React from 'react';

import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';

const OrderProgressBar = ({
  tooltip,
  label,
  value = 0,
  max = 10.0,
  maxIsMoney = false,
  color = '#6576FF',
  originalValue = value
}) => {
  function moneyFormat(value) {
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  function getValue(val) {
    if (!val) {
      return 0;
    }

    if (maxIsMoney) {
      return moneyFormat(val);
    }

    return val;
  };

  function getPercent() {
    if (originalValue === 0) {
      return 0;
    }

    return ((originalValue / max) * 100.0).toFixed(2);
  };
  return (
    <Tooltip label={tooltip ? tooltip : `${getValue(value)} de ${getValue(max)} (${getPercent()}%)`}>
      <Flex direction="column" width="100%">
        <Flex justify="space-between" mb="7px">
          <Text className="up-anim" opacity=".78">
            {label}
          </Text>
          <Text className="up-anim">{value}</Text>
        </Flex>
        <Box bg="#EFEFEF" borderRadius="8px" h="10px">
          <Box
            className="progress-anim"
            transformOrigin="left"
            bg={color}
            transition=".5s"
            w={`${getPercent()}%`}
            h="100%"
            borderRadius="8px"
          />
        </Box>
      </Flex>
    </Tooltip>
  );
};

export default OrderProgressBar;
