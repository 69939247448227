import React, { useEffect, useState } from 'react';

import { MdOutlineReceiptLong } from 'react-icons/md';
import { RiFileListFill, RiLayoutGridFill } from 'react-icons/ri';
import { Link as RouterLink } from 'react-router-dom';

import { Button, Divider, Flex, Link, Text } from '@chakra-ui/react';

import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';
import useScreenPage from '../../../../../hooks/useScreenPage';
import requests from '../../../../../services/requests';

import TableCte from './TableCte';

const TravelCte = ({ tab, filterSelected, setTab }) => {
  //Detecção de chamadas as APIs
  const screenPage = useScreenPage();

  const [load, setLoad] = useState(true);
  const [loadSecond, setLoadSecond] = useState(true);
  const [list, setList] = useState([]);
  const [action, setAction] = useState(1);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });
  let profileRole = localStorage.getItem('profile-role');

  const listCtes = () => {
    if (
      (filterSelected?.filters?.startsAt != null && filterSelected?.filters?.endsAt != null) ||
      (filterSelected?.filters?.startsAt != undefined && filterSelected?.filters?.endsAt != undefined)
    ) {
      setLoad(true);
      setLoadSecond(true);

      requests.listCtes(filterSelected, metadata.current_page > 0 ? metadata.current_page : 1).then((data) => {
        setList(data?.data);

        if (data?.data?.length == 0) setLoad(false);
        setMetadata(data?.meta);
      });

      setLoadSecond(false);
    }
  };

  useEffect(() => {
    listCtes();
  }, [filterSelected, action]);

  return (
    <Flex direction="column" w="100%">
      <Flex
        h="70px"
        align="center"
        flexDirection={{ base: 'column', md: 'row' }}
        justify="space-between"
        w="100%"
        gap={{ base: '5', md: '0' }}
        p="10px 15px">
        <Flex gap="2px" direction="column">
          <Text textStyle="cardTitle" fontWeight="bold" color="primary">
            {"CT-e's"} em trânsito
          </Text>

          <Text textStyle="subtitle" color="#BEBEBE" fontSize={{ base: '14px', md: '16px' }}>
            Existe um total de {metadata?.item_count} {"CT-e's"} cadastrados
          </Text>
        </Flex>

        <Flex gap="15px" align="center">
          <Link as={RouterLink} to="/logistic/trip">
            <Button
              variant={tab === 'progress' ? 'primary' : 'secundary'}
              borderRadius="0px"
              borderColor="#6C48C2"
              w={{ base: '100%', md: 'initial' }}
              title="Viagens em lista"
              onClick={() => setTab('progress')}
              rightIcon={<RiFileListFill />}>
              Lista de Viagens
            </Button>
          </Link>

          {profileRole === 'ROLE_SYSTEM_ADMIN' && (
            <Link as={RouterLink} to="/logistic/trip#kanban">
              <Button
                variant={tab === 'kanban' ? 'primary' : 'secundary'}
                borderRadius="0px"
                borderColor="#6C48C2"
                title="Viagens em kanban"
                rightIcon={<RiLayoutGridFill />}
                onClick={() => setTab('kanban')}>
                Quadro de Viagens
              </Button>
            </Link>
          )}

          <Link as={RouterLink} to="/logistic/trip#cte">
            <Button
              variant={tab === 'cte' ? 'primary' : 'secundary'}
              borderRadius="0px"
              borderColor="#6C48C2"
              w={{ base: '100%', md: 'initial' }}
              title={"CT-e's"}
              rightIcon={<MdOutlineReceiptLong />}
              onClick={() => setTab('cte')}>
              {"CT-e's"}
            </Button>
          </Link>
        </Flex>
      </Flex>

      <Divider />

      <ScreenLoader isLoading={load}>
        <TableCte
          tab={tab}
          list={list}
          metadata={metadata}
          load={load}
          setLoad={setLoad}
          loadSecond={loadSecond}
          setMetadata={setMetadata}
          action={action}
          setAction={setAction}
          showDetails={true}
          loadData={listCtes}
        />
      </ScreenLoader>
    </Flex>
  );
};

export default TravelCte;
