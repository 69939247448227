import React, { useEffect, useState } from 'react';

import { Box, Flex, Grid, Text } from '@chakra-ui/react';

import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import useScreenPage from '../../../hooks/useScreenPage';
import ChartAbc from '../../../pages/metrics/MetricsAnalytics/components/ChartAbc';
import requests from '../../../services/requests';

const ParameterizationChart = ({
  data = [],
  isLoading,
  sizeWidth,

  searchPeriod, // Remover após inserir Page na Dashboard
  searchString, // Remover após inserir Page na Dashboard
  shouldRefresh = false, // Remover após inserir Page na Dashboard
  setIsRefreshing, // Remover após inserir Page na Dashboard
  searchFilterData, // Remover após inserir Page na Dashboard
}) => {
  const screenPage = useScreenPage();

  const [isLoadingEntity, setIsLoadingEntity] = useState(false);
  const [entity, setEntity] = useState([]);

  const load = () => {
    getData();
  };

  const [activeItemName, setActiveItemName] = useState(null);

  const getData = async () => {
    if (!searchPeriod || !(searchPeriod?.startsAt != null || searchPeriod?.endsAt != null)) {
      return;
    }

    setIsLoadingEntity(true);

    if (searchFilterData !== null) {
      delete searchFilterData?.search;
    }

    try {
      let res = await requests.dashboardProcessByChannel({
        search: searchString,
        period: searchPeriod,
        filters: searchFilterData,
        screenPage
      });

      setEntity(convertJSON(res));
      setIsLoadingEntity(false);

    } catch (error) {
      setIsLoadingEntity(false);
      console.log(error)
    }
  };

  function convertJSON(jsonData) {
    const colors = ['#70D499', '#F3D783', '#EC5D66', '#696969', '#D6D6D6'];
    const convertedData = jsonData.map((item, index) => {
      return {
        name: item.channel,
        value: parseInt(item.qtt, 10),
        color: colors[index] || '#D6D6D6',
      };
    });

    return convertedData;
  }

  useEffect(() => {
    if (data === 'undefined' || data?.length === 0) {
      load();
    }
  }, [searchPeriod, searchString, searchFilterData]);

  useEffect(() => {
    if ((data === 'undefined' || data?.length === 0) && shouldRefresh) {
      load();
    }
  }, [shouldRefresh]);

  useEffect(() => {
    if (typeof setIsRefreshing === 'function') {
      setIsRefreshing(isLoading);
    }
  }, [isLoading]);

  const onPieEnter = (item) => {
    setActiveItemName(item.name);
  };

  const onPieLeave = () => {
    setActiveItemName(null);
  };

  const [auxData, setAuxData] = useState([]);
  const [auxIsLoading, setAuxIsLoading] = useState(false);

  useEffect(() => {
    if (data?.length > 0) {
      setAuxData(data);
      setAuxIsLoading(isLoading);
    } else {
      setAuxData(entity);
      setAuxIsLoading(isLoadingEntity);
    }
  }, [isLoading, isLoadingEntity]);

  return (
    <Flex w="100%" align="center" h="100%" minH={{ base: 'initial', md:'320px'}}  direction="column" textColor="primary" py="10px">
      <ScreenLoader isLoading={auxIsLoading}>
        <Flex
          flexFlow={{ base: 'column', xx: 'row' }}
          alignItems="center"
          w="100%"
          h="100%"
          gap={{ base: '25px', xx: '0px' }}
          justify={{ base: 'flex-start', xx: 'center' }}>
          <ChartAbc 
            entity={auxData}
            text="Total de canais" 
            activeItemName={activeItemName} 
            sizeWidth={{ base: sizeWidth, md: '500px' }}
          />
          <Grid 
            templateColumns={{ base: '1fr 1fr', md:'repeat(4, 1fr)', xx: '200px 200px' }} 
            justifyItems="flex-start" 
            gap={{ base: '10px 25px', md:'20px'}}
          >
            {auxData?.length > 0 && auxData.map((item, key) => (
              <Flex gap="8px" align="center" key={key} onMouseEnter={() => onPieEnter(item)} onMouseLeave={onPieLeave}>
                <Box minW="28px" minH="18px" bgColor={item.color} borderRadius="3px" />

                <Text 
                  textStyle="subtitle" 
                  textColor="titleColor"
                  fontSize={{base: '12px', lg: '16px', xl: '18px'}}
                >
                  {item.name}
                </Text>
              </Flex>
            ))}
          </Grid>
        </Flex>
      </ScreenLoader>
    </Flex>
  );
};

export default ParameterizationChart;
