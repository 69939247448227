import React from 'react';

import { Accordion, AccordionItem } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';

import CalendarColumn from './CalendarColumn';

const CalendarSection = ({ list, modality, isMobile }) => {
  return (
    <>
      {!isMobile ? (
        <Flex w="100%">
          {list?.days &&
            list.days.map((day, dayKey) => {
              return <CalendarColumn isMobile={isMobile} modality={modality} day={day} key={dayKey + list.formatted} delay={dayKey} />;
            })}
        </Flex>
      ) : (
        <Accordion display={{ base: 'flex', md: 'none' }} flexDirection="column" w="100%" allowToggle>
          {list?.days &&
            list.days.map((day, dayKey) => {
              return (
                <AccordionItem key={dayKey + list.formatted}>
                  <CalendarColumn day={day} delay={dayKey} />
                </AccordionItem>
              );
            })}
        </Accordion>
      )}
    </>
  );
};

export default CalendarSection;
