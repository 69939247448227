import React from 'react';

import { TbFileDownload } from 'react-icons/tb';

import { Box, Button, Collapse, Flex, Text } from '@chakra-ui/react';

import ProgressBar from '../../../../../../../../components/ProgressBar/ProgressBar';
import requests from '../../../../../../../../services/requests';

const CteDetails = ({ isOpen, trip }) => {
  const handleDownloadXML = (identifier) => {
    let link = document.createElement('a');

    link.href = (requests.getApiUrl() + `/open/freight/${identifier}/download-cte`).replace('//open', '/open');
    link.target = '_blank';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Collapse in={isOpen} animateOpacity>
      <Box py="20px" px="75px" bgColor="#F9F9F9">
        {trip?.client != null && (
          <>
            <Flex align="center">
              <Text mr="50px" textStyle="tableTitle" fontWeight="medium" color="#422C76">
                {trip?.client?.name}
              </Text>

              <Button
                variant="secundary"
                rightIcon={<TbFileDownload size={20} />}
                mr="13px"
                onClick={(decision) => {
                  if (decision) {
                    handleDownloadXML(trip.identifier);
                  }
                }}>
                CT-e
              </Button>
              <Button variant="secundary">N°{trip.cteNumber}</Button>
            </Flex>
          </>
        )}

        {trip?.timeline != null && trip?.timeline != undefined && <ProgressBar timeline={trip?.timeline} />}
      </Box>
      <hr />
      <Box py="20px" px="75px" bgColor="#F9F9F9">
        {trip?.cteNumber != null && trip?.client != null && (
          <>
            <Flex align="center">
              <Text mr="50px" textStyle="tableTitle" fontWeight="medium" color="#422C76">
                {trip?.client?.name}
              </Text>

              <Button variant="secundary" rightIcon={<TbFileDownload size={20} />} mr="13px">
                CT-e
              </Button>
              <Button variant="secundary">N°{trip.cteNumber}</Button>
            </Flex>
          </>
        )}

        <ProgressBar />
      </Box>
    </Collapse>
  );
};

export default CteDetails;
