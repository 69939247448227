import React from 'react';

import { Button, Flex, Input, Link, Select } from '@chakra-ui/react';

import bg1 from '../../assets/images/login/bgs/bg-1.jpg';
import bg2 from '../../assets/images/login/bgs/bg-2.jpg';
import BackSlider from '../../components/Login/BackSlider/BackSlider';
import CardRound from '../../components/Login/CardRound/CardRound';

const SignaturePage = () => {
  const bgs = [
    {
      urlBg: bg1,
      text: 'Vendemmia Logística Integrada',
      timeSecs: 10,
      topicId: 1,
      filter: 0,
    },
    {
      urlBg: bg1,
      text: 'Vendemmia Logística Integrada',
      timeSecs: 10,
      topicId: 2,
      filter: 0,
    },
    {
      urlBg: bg1,
      text: 'Vendemmia Logística Integrada',
      timeSecs: 10,
      topicId: 3,
      filter: 0,
    },
  ];

  return (
    <BackSlider slides={bgs}>
      <CardRound>
        <Flex direction="column" gap="10px">
          <Input variant="flushed" placeholder="Nome:" />
          <Input variant="flushed" placeholder="Cargo:" />
          <Input variant="flushed" placeholder="E-mal:" />
          <Input variant="flushed" placeholder="Telefone (opcional): " />
          <Select variant="flushed" placeholder="Selecione um endereço">
            <option value="Rua Funchal, 129 – 13° Andar São Paulo, SP, Brasil, CEP: 04551-060">
              São Paulo (Vila Olímpia)
            </option>
            <option value="Av Marcos Conder, 805 – 8° Andar -  Centro – Itajaí, SC, Brasil, CEP: 88301-123">
              Santa Catarina (Itajaí)
            </option>
            <option value="Rodovia BR 470 – KM 7, 6800 - Volta Grande, Navegantes/SC CEP: 88371-890">
              Armazém SC CD1 (BR 470 – KM 7, 6800)
            </option>
            <option value="Rodovia BR 470 – 4669 - Volta Grande, Navegantes/SC CEP: 88371-890">
              Armazém SC CD2 (BR 470 – 4669)
            </option>
            <option value="Avenida Portugal, 325 - Bairro Itaqui, Itapevi">Armazém SP (Itapevi)</option>
          </Select>
        </Flex>

        <Flex direction="row" justify="space-between">
          <Link href="/" _hover={{ textDecoration: 'none' }}>
            <Button
              variant="secundary"
              fontSize={{ sm: '12px', md: '16px' }}
              width={{ sm: '66px', md: 'auto' }}
              height={{ sm: '24px', md: '30px' }}>
              Voltar
            </Button>
          </Link>
          <Button
            variant="primary"
            fontSize={{ sm: '12px', md: '16px' }}
            width={{ sm: '66px', md: 'auto' }}
            height={{ sm: '24px', md: '30px' }}>
            Criar assinatura
          </Button>
        </Flex>
      </CardRound>
    </BackSlider>
  );
};

export default SignaturePage;
