import requests from '../../services/requests';

import FilterOption from './FilterOption';

const filterOptionsProcess = [];

const filterOptionsTrip = [
  new FilterOption('Modalidade', 'modalities', [
    new FilterOption('App do motorista', 'APP_MOTORISTA', []),
    new FilterOption('Rastreável', 'FRETE_RASTREAVEL', []),
    new FilterOption('Não rastreável', 'FRETE_NAO_RASTREAVEL', []),
    new FilterOption('Gestão manual', 'MANUAL', []),
  ]),
  new FilterOption('Transportadora', 'shippingCompanyName', [
    new FilterOption('Vendemmia', 'VENDEMMIA', []),
    new FilterOption('Outro', 'OUTRO', []),
  ]),
  new FilterOption('Status', 'status', [
    new FilterOption('Preparação', null, [
      new FilterOption('Recusada', 'REFUSED_BY_DRIVER', []),
      new FilterOption('Em rascunho', 'DRAFT', []),
      new FilterOption('Cadastrada', 'CREATED', []),
      new FilterOption('Pendente', 'SENT_TO_DRIVER', []),
      new FilterOption('Agendada', 'ACCEPTED_BY_DRIVER', []),
      new FilterOption('Cancelada', 'CANCELLED', []),
    ]),
    new FilterOption('Execução', null, [
      new FilterOption('Direcionando para coleta', 'GOING_TO_COLLECT'),
      new FilterOption('Chegou para coleta', 'READ_TO_COLLECT'),
      new FilterOption('Coleta iniciada', 'COLLECTING'),
      new FilterOption('Coleta terminada', 'READ_TO_TRAVEL'),
      new FilterOption('Em viagem', 'TRAVELLING'),
      new FilterOption('Impedida', 'HALTED', []),
    ]),
    new FilterOption('Entrega', null, [
      new FilterOption('Chegada no destino', 'ARRIVED_AT_DESTINATION'),
      new FilterOption('Descarregando', 'UNLOADING'),
      new FilterOption('Cancelada pelo motorista', 'DELIVER_REFUSED'),
      new FilterOption('Recebimento assinado', 'DELIVER_SIGNATURE_COLLECTED'),
      new FilterOption('Recebimento recusada', 'DELIVER_SIGNATURE_REFUSED'),
      new FilterOption('No destino', 'COMPLETED'),
    ]),
    new FilterOption('Faturamento', null, [
      new FilterOption('Pagamento liberado', 'PAYMENT_ALLOWED'),
      new FilterOption('Pagamento efetuado', 'PAID'),
      new FilterOption('Pagamento cancelado', 'PAID_CANCELLED'),
    ]),
  ]),
];

const filterOptionsTripKanban = [
  new FilterOption('Modalidade', 'modalities', [
    new FilterOption('App do motorista', 'APP_MOTORISTA', []),
    new FilterOption('Rastreável', 'FRETE_RASTREAVEL', []),
    new FilterOption('Não rastreável', 'FRETE_NAO_RASTREAVEL', []),
    new FilterOption('Gestão manual', 'MANUAL', []),
  ]),
  new FilterOption('Transportadora', 'shippingCompanyName', [
    new FilterOption('Vendemmia', 'VENDEMMIA', []),
    new FilterOption('Outro', 'OUTRO', []),
  ]),
];

const filterOptionsDriver = [
  new FilterOption('Status', 'status', [
    new FilterOption('Ativo', 'ATIVO', []),
    new FilterOption('Dados pendentes', 'PENDENTE'),
    new FilterOption('Aguardando análise', 'EM_ANALISE'),
    new FilterOption('Suspenso', 'INATIVO'),
    new FilterOption('Expirado', 'EXPIRADO'),
    new FilterOption('Não aceito', 'REPROVADO'),
    new FilterOption('Anonimizado', 'ANONIMIZADO'),
  ]),
  new FilterOption('Tipo', 'type', [
    new FilterOption('Colaborador Vendemmia', 'INTERNAL', []),
    new FilterOption('Parceiro externo', 'EXTERNAL', []),
  ]),
];

const filterOptionsVehicle = [
  new FilterOption('Modalidade de veículo', 'ownership', [
    new FilterOption('Veículo próprio', 'PROPRIO'),
    new FilterOption('Veículo Vendemmia', 'VENDEMMIA'),
    new FilterOption('Veículo de Terceiros', 'TERCEIRO'),
  ]),
];

const filterOptionsCurrentTrips = async () => {
  const data = await requests.getFilters();

  return [
    {
      label: 'Empresa',
      name: 'clients',
      isMultiple: true,
      options: data.clients.map((item) => ({
        ...item,
        label: `${item.name} - ${item.documentNumber} ${item.documentNumber.length === 8 ? ' (todas)' : '(' + item.type + ')'}`,
        value: item.identifier,
        qttChildren: item.qttChildren,
      })),
    },
    new FilterOption('Modalidade', 'modalities', [
      new FilterOption('App do motorista', 'APP_MOTORISTA', []),
      new FilterOption('Rastreável', 'FRETE_RASTREAVEL', []),
      new FilterOption('Não rastreável', 'FRETE_NAO_RASTREAVEL', []),
      new FilterOption('Gestão manual', 'MANUAL', []),
    ]),
    new FilterOption('Transportadora', 'shippingCompanyName', [
      new FilterOption('Vendemmia', 'VENDEMMIA', []),
      new FilterOption('Outro', 'OUTRO', []),
    ]),
  ];
};

const filterOptionsComex = async () => {
  const data = await requests.getFilters();

  return [
    {
      label: 'Empresa',
      name: 'clients',
      isMultiple: true,
      options: data.clients.map((item) => ({
        ...item,
        label: `${item.name} - ${item.documentNumber} ${item.documentNumber.length === 8 ? ' (todas)' : '(' + item.type + ')'}`,
        value: item.identifier,
        qttChildren: item.qttChildren,
      })),
    },
    new FilterOption(
      'Etapa atual',
      'stages',
      data.stages.map((item) => {
        const { description, identifier } = item;
        return new FilterOption(description, identifier);
      })
    ),
    new FilterOption(
      'Canal',
      'channels',
      data.channels.map((item) => {
        const { description, identifier } = item;
        return new FilterOption(description, identifier);
      })
    ),
    new FilterOption(
      'Modalidade',
      'transportModalities',
      data.transportModalities.map((item) => {
        const { description, identifier } = item;
        return new FilterOption(description, identifier);
      })
    ),
    new FilterOption(
      'Tipo de processo',
      'processModalities',
      data.processModalities.map((item) => {
        return new FilterOption(item, item);
      })
    ),
  ];
};

const filterOptionsComexCashflow = async () => {
  const data = await requests.getFilters();

  return [
    {
      label: 'Empresa',
      name: 'clients',
      isMultiple: true,
      options: data.clients.map((item) => ({
        ...item,
        label: `${item.name} - ${item.documentNumber} ${item.documentNumber.length === 8 ? ' (todas)' : '(' + item.type + ')'}`,
        value: item.identifier,
        qttChildren: item.qttChildren,
      })),
    },
  ];
};

const filterOptionsWarehouse = async () => {
  const data = await requests.getFilters();

  return [
    {
      label: 'Empresa',
      name: 'clients',
      isMultiple: true,
      options: data.clients.map((item) => ({
        ...item,
        label: `${item.name} - ${item.documentNumber} ${item.documentNumber.length === 8 ? ' (todas)' : '(' + item.type + ')'}`,
        value: item.identifier,
        qttChildren: item.qttChildren,
      })),
    },
    {
      label: 'Localidade',
      name: 'idArmazem',
      isMultiple: false,
      options: [
        { label: 'TODOS', value: null },
        { label: 'SC - matriz', value: 5 },
        { label: 'SC - filial 1', value: 7 },
        { label: 'SP - filial 1', value: 6 },
      ],
    },
  ];
};

const filterOptionsWarehousePicking = async () => {
  let data = await filterOptionsWarehouse();

  return data?.concat(
    new FilterOption('Status', 'status', [
      new FilterOption('Ag. romaneio/onda', 'AG. FORMAÇÃO DE ROMANEIO/ONDA', []),
      new FilterOption('Ag. separação', 'AG. SEPARAÇÃO', []),
      new FilterOption('Cancelado', 'CANCELADO', []),
      new FilterOption('Coleta iniciada', 'COLETA INICIADA', []),
      new FilterOption('Conferência concluída', 'CONFERÊNCIA CONCLUÍDA', []),
      new FilterOption('Conferência iniciada', 'CONFERÊNCIA INICIADA', []),
      new FilterOption('Enviado para faturamento', 'ENVIADO PARA FATURAMENTO', []),
      new FilterOption('Faturado', 'FATURADO', []),
      new FilterOption('Gerada com corte', 'GERADA COM CORTE', []),
      new FilterOption('Importado', 'IMPORTADO', []),
      new FilterOption('Processado', 'PROCESSADO', []),
      new FilterOption('Quarentena', 'QUARENTENA', []),
      new FilterOption('Separação concluída', 'SEPARAÇÃO CONCLUÍDA', []),
      new FilterOption('Separação iniciada', 'SEPARAÇÃO INICIADA', []),
    ])
  );
};

const filterOptionsWarehouseReceipt = async () => {
  let data = await filterOptionsWarehouse();

  return data?.concat(
    new FilterOption('Status', 'status', [
      new FilterOption('Digitada', 'DIGITADA', []),
      new FilterOption('Cancelada', 'CANCELADA', []),
      new FilterOption('Bloqueada', 'BLOQUEADA', []),
      new FilterOption('Processada', 'PROCESSADA', []),
      new FilterOption('Em carga', 'EM_CARGA', []),
      new FilterOption('Importada', 'IMPORTADA', []),
      new FilterOption('Aguardando nf', 'AGUARDANDO_NF', []),
      new FilterOption('Ag alocação', 'AG_ALOCACAO', []),
    ])
  );
};

const filterQuote = async () => {
  const data = await requests.getFilters();

  return [
    {
      label: 'Empresa',
      name: 'clients',
      isMultiple: true,
      options: data.clients.map((item) => ({
        ...item,
        label: `${item.name} - ${item.documentNumber} ${item.documentNumber.length === 8 ? ' (todas)' : '(' + item.type + ')'}`,
        value: item.identifier,
        qttChildren: item.qttChildren,
      })),
    },
    {
      label: 'Modalidade',
      name: 'modality',
      isMultiple: true,
      options: data?.transportModalities.map((item) => ({
        ...item,
        label: `${item.description} (${item.slug})`,
        value: item.identifier,
      })),
    },
  ];
};

const filterOptionsQuote = async () => {
  let data = await filterQuote();

  return data?.concat(
    new FilterOption('Status', 'status', [
      new FilterOption('Cadastrada', 'CREATED', []),
      new FilterOption('Análise logística', 'ON_REVIEW_LEGAL', []),
      new FilterOption('Análise operacional', 'ON_REVIEW_OPERATIONAL', []),
      new FilterOption('Análise cliente', 'ON_REVIEW_CLIENT', []),
      new FilterOption('Aprovado', 'APPROVED', []),
      new FilterOption('Rejeitado', 'REJECTED', []),
      new FilterOption('Cancelado', 'CANCELLED', []),
    ])
  );
};

const filterOptionsManagementUser = async () => {
  const data = await requests.getFilters();

  return [
    {
      label: 'Empresa',
      name: 'clients',
      isMultiple: true,
      options: data.clients.map((item) => ({
        ...item,
        label: `${item.name} - ${item.documentNumber} ${item.documentNumber.length === 8 ? ' (todas)' : '(' + item.type + ')'}`,
        value: item.identifier,
        qttChildren: item.qttChildren,
      })),
    },
    {
      label: 'Tipo de perfil',
      name: 'role',
      defaultValue: null,
      isMultiple: true,
      options:
        data?.roles.length > 0 &&
        data.roles.map((item, index) => ({
          label: `${item.roleHumanReadable}`,
          value: item.role,
        })),
    },
    {
      label: 'Status',
      name: 'status',
      isMultiple: false,
      options: [
        { label: 'TODOS', value: null },
        { label: 'Em analise', value: 'EM_ANALISE' },
        { label: 'Aprovado', value: 'APROVADO' },
        { label: 'Reprovado', value: 'REPROVADO' },
        { label: 'Ativo', value: 'ATIVO' },
        { label: 'Inativo', value: 'INATIVO' },
        { label: 'Anonimizado', value: 'ANONIMIZADO' },
        { label: 'Expirado', value: 'EXPIRADO' },
      ],
    },
    {
      label: 'Tipo de vinculo com cliente',
      name: 'auto',
      isMultiple: false,
      options: [
        { label: 'TODOS', value: null },
        { label: 'Com todos os clientes vinculados', value: 'ATIVO' },
        { label: 'Clientes vinculados manualmente', value: 'INATIVO' },
      ],
    },
  ];
};

const filterOptionsDashboard = async () => {
  const data = await requests.getFilters();

  return [
    {
      label: 'Empresa',
      name: 'clients',
      isMultiple: true,
      options: data?.clients.map((item) => ({
        ...item,
        label: `${item.name} - ${item.documentNumber} ${item.documentNumber.length === 8 ? ' (todas)' : '(' + item.type + ')'}`,
        value: item.identifier,
        qttChildren: item.qttChildren,
      })),
    },
    {
      label: 'Localidade (armazém)',
      name: 'idArmazem',
      isMultiple: false,
      options: [
        { label: 'TODOS', value: null },
        { label: 'SC - matriz', value: 5 },
        { label: 'SC - filial 1', value: 7 },
        { label: 'SP - filial 1', value: 6 },
      ],
    },
    new FilterOption(
      'Etapa atual',
      'stages',
      data.stages.map((item) => {
        const { description, identifier } = item;
        return new FilterOption(description, identifier);
      })
    ),
    new FilterOption(
      'Canal',
      'channels',
      data.channels.map((item) => {
        const { description, identifier } = item;
        return new FilterOption(description, identifier);
      })
    ),
    new FilterOption(
      'Modalidade',
      'transportModalities',
      data.transportModalities.map((item) => {
        const { description, identifier } = item;
        return new FilterOption(description, identifier);
      })
    ),
    new FilterOption(
      'Tipo de processo',
      'processModalities',
      data.processModalities.map((item) => {
        return new FilterOption(item, item);
      })
    ),
  ];
};

const filterOptionsMapping = async () => {
  const data = await requests.getFilters();

  return [
    {
      label: 'Empresa',
      name: 'clients',
      isMultiple: true,
      options: data?.clients.map((item) => ({
        ...item,
        label: `${item.name} - ${item.documentNumber} ${item.documentNumber.length === 8 ? ' (todas)' : '(' + item.type + ')'}`,
        value: item.identifier,
        qttChildren: item.qttChildren,
      })),
    },

    new FilterOption(
      'Etapa atual',
      'stages',
      data.stages.map((item) => {
        const { description, identifier } = item;
        return new FilterOption(description, identifier);
      })
    ),
    new FilterOption(
      'Canal',
      'channels',
      data.channels.map((item) => {
        const { description, identifier } = item;
        return new FilterOption(description, identifier);
      })
    ),
    new FilterOption(
      'Modalidade',
      'transportModalities',
      data.transportModalities.map((item) => {
        const { description, identifier } = item;
        return new FilterOption(description, identifier);
      })
    ),
    new FilterOption(
      'Tipo de processo',
      'processModalities',
      data.processModalities.map((item) => {
        return new FilterOption(item, item);
      })
    ),
  ];
};

const filterOptionsCountry = async () => {
  const data = await requests.getFilters();

  return [
    {
      label: 'Estado',
      name: 'codeState',
      isMultiple: true,
      options:
        data?.states?.length > 0 &&
        data.states.map((item) => ({
          ...item,
          label: item.code,
          value: item.code,
        })),
    },
    {
      label: 'Vínculos',
      name: 'isBound',
      isMultiple: false,
      options: [
        { label: 'TODOS', value: null },
        { label: 'Vínculadas', value: true },
        { label: 'Não vínculadas', value: false },
      ],
    },
  ];
};

const filterOptionsAddTravel = async () => {
  const data = await requests.getFilters();

  return [
    {
      label: 'Empresa',
      name: 'clients',
      isMultiple: true,
      options: data?.clients.map((item) => ({
        ...item,
        label: `${item.name} - ${item.documentNumber} ${item.documentNumber.length === 8 ? ' (todas)' : '(' + item.type + ')'}`,
        value: item.identifier,
        qttChildren: item.qttChildren,
      })),
    },
  ];
};

const filterOptionsCreditAnalysis = [
  new FilterOption('Status', 'status', [
    new FilterOption('Consultando', 'PENDING'),
    new FilterOption('Atenção', 'CLEARED'),
    new FilterOption('Liberado', 'ATTENTION'),
    new FilterOption('Restrição', 'REJECTED'),
  ]),
];

export {
  filterOptionsAddTravel,
  filterOptionsComex,
  filterOptionsComexCashflow,
  filterOptionsCountry,
  filterOptionsCreditAnalysis,
  filterOptionsCurrentTrips,
  filterOptionsDashboard,
  filterOptionsDriver,
  filterOptionsManagementUser,
  filterOptionsMapping,
  filterOptionsProcess,
  filterOptionsQuote,
  filterOptionsTrip,
  filterOptionsTripKanban,
  filterOptionsVehicle,
  filterOptionsWarehouse,
  filterOptionsWarehousePicking,
  filterOptionsWarehouseReceipt
};

