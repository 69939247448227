import React from 'react';

import { Accordion } from '@chakra-ui/react';

import { clamp } from '../../../../utils/math';

import ProcessDetailStep from './ProcessDetailStep';

const ProcessDetails = ({ process, updates, loadUpdates }) => {
  const getSteps = () => {
    if (!process || !updates) return [];

    let infos = {
      preembarque: [],
      transito: [],
      desembaraco: [],
      faturamento: [],
      transporte: [],
      entrega: [],
    };

    if (process?.startedAtFormatted) {
      infos.preembarque.push({
        label: 'Efetiva', value: process?.startedAtFormatted
      });
    }

    if (process?.boardedAtFormatted) {
      infos.transito.push({
        label: 'Trânsito', value: process?.boardedAtFormatted
      });
    }
    if (process?.vessel) {
      infos.transito.push({
        label: 'Navio', value: process.vessel?.name
      });
    }

    if (process?.arrivedAtFormatted) {
      infos.desembaraco.push({ label: 'Chegada', value: process.arrivedAtFormatted });
    }
    if (process?.arrivalExpectedAtFormatted) {
      infos.desembaraco.push({ label: process?.clearenceDiAtFormatted ? 'Previsão' : 'Prev. chegada', value: process.arrivalExpectedAtFormatted, });
    }
    if (process?.diNumber) {
      infos.desembaraco.push({ label: 'DI', value: process.diNumber });
    }
    if (process?.clearenceDiAtFormatted) {
      infos.desembaraco.push({ label: 'Registro', value: process.clearenceDiAtFormatted });
    }
    if (process?.clearenceCiAtFormatted) {
      infos.desembaraco.push({ label: 'CI', value: process.clearenceCiAtFormatted });
    }

    if (process?.billedAtFormatted) {
      infos.faturamento.push({ label: 'Emissão', value: process.billedAtFormatted });
    }
    if (process?.idNota) {
      infos.faturamento.push({ label: 'NF', value: process.idNota });
    }

    if (process?.storedAtFormatted) {
      infos.transporte.push({ label: 'Carregamento', value: process.storedAtFormatted });
    }
    if (process?.deliveredAtFormatted) {
      infos.transporte.push({ label: 'Entrega', value: process.deliveredAtFormatted });
    }

    if (process?.deliveredAtFormatted) {
      infos.entrega.push({ label: 'Entrega', value: process.deliveredAtFormatted });
    }

    return [
      {
        title: 'Pré embarque',
        progress: clamp(process.progressBar / 20.0 - 0, 0, 1),
        block: false,
        infos: infos.preembarque,
        updates: updates[0],
      },
      {
        title: 'Trânsito',
        progress: clamp(process.progressBar / 20.0 - 1, 0, 1),
        block: false,
        infos: infos.transito,
        updates: updates[1],
      },
      {
        title: 'Desembaraço',
        progress: clamp(process.progressBar / 20.0 - 2, 0, 1),
        block: false,
        infos: infos.desembaraco,
        updates: updates[2],
      },
      {
        title: !process?.billedAtFormatted ? 'Em faturamento' : 'Faturado',
        progress:
          !process?.billedAtFormatted && process.progressBar > 60 ? 0 : clamp(process.progressBar / 20.0 - 3, 0, 1),
        block: false,
        infos: infos.faturamento,
        updates: updates[3],
      },
      {
        title: 'Transporte',
        progress: clamp(process.progressBar / 20.0 - 4, 0, 1),
        block: false,
        infos: infos.transporte,
        updates: updates[4],
      },
      {
        title: 'Entrega',
        progress: process.progressBar === 100 ? 1 : 0,
        block: false,
        infos: infos.entrega,
        updates: updates[5],
      },
    ];
  };

  if (!process || !process.currentStageModalitySequential) {
    return null;
  }

  return (
    <Accordion allowMultiple w="100%" direction="column" defaultIndex={[process.currentStageModalitySequential - 1]}>
      {!loadUpdates &&
        getSteps().map((step, i) => (
          <ProcessDetailStep
            key={i}
            stepCount={i}
            delay={i}
            progress={step.progress}
            process={process}
            title={step.title}
            infos={step.infos}
            updates={updates[i]}
            isLast={i == getSteps().length - 1}
            // isBlock={step.block}
          />
        ))}
    </Accordion>
  );
};

export default ProcessDetails;
