import React, { useEffect } from 'react';

import CurrencyInput from 'react-currency-input-field';
import { useForm } from 'react-hook-form';

import { Button, FormControl, FormLabel, Grid, InputGroup, InputLeftAddon, Text } from '@chakra-ui/react';

const CardFreight = ({
  entity,
  formErrorFreight,
  setHasError,
  setFormErrorFreight,
  setStep,
  setFormCardFreight,
  freightValues,
  setFreightValues,
}) => {
  const { register } = useForm();

  function handleInputValues(name, value) {
    setFreightValues({
      ...freightValues,
      [name]: value
    });
  }

  const onSubmit = () => {
    if (!freightValues) {
      setFormErrorFreight(true);

      return;
    }

    setFreightValues(freightValues);
    setFormCardFreight(true);
    setStep(3);
  };

  useEffect(() => {
    setHasError(!freightValues);
  }, [freightValues]);

  return (
    <>
      <Grid templateColumns="repeat(2, 1fr)" gap="10px" mx="1px">
        <FormControl w="220px">
          <FormLabel mb="9px">Valor da mercadoria</FormLabel>
          <InputGroup>
            <InputLeftAddon color="#0F0A1DCF" fontSize="13px" fontWeight="bold" bgColor="#0F0A1D21">
              R$
            </InputLeftAddon>
            <CurrencyInput
              defaultValue={freightValues.merchandiseValue}
              id="merchandiseValue"
              name="merchandiseValue"
              allowDecimals={true}
              decimalSeparator=","
              groupSeparator="."
              decimalsLimit={2}
              decimalScale={2}
              fixedDecimalLength={true}
              placeholder="Mercadoria"
              onValueChange={(value) => handleInputValues('merchandiseValue', value)}
              {...register('merchandiseValue', { required: true })}
              style={{
                border: '1px solid #70707033',
                paddingLeft: '8px',
                borderBottomRightRadius: '6px',
                borderTopRightRadius: '6px',
                '::placeholder': {
                  color: '#0F0A1D66',
                  fontSize: '14px',
                },
              }}
            />
          </InputGroup>
          {formErrorFreight && !freightValues.merchandiseValue && <Text textStyle="error">Campo obrigatório.</Text>}
        </FormControl>
        <FormControl w="220px">
          <FormLabel mb="9px">Valor da mercadoria av.</FormLabel>
          <InputGroup>
            <InputLeftAddon color="#0F0A1DCF" fontSize="13px" fontWeight="bold" bgColor="#0F0A1D21">
              R$
            </InputLeftAddon>
            <CurrencyInput
              defaultValue={freightValues.merchandiseDamagedValue}
              id="merchandiseDamagedValue"
              name="merchandiseDamagedValue"
              allowDecimals={true}
              decimalSeparator=","
              groupSeparator="."
              decimalsLimit={2}
              decimalScale={2}
              fixedDecimalLength={true}
              placeholder="Mercadoria avariada"
              onValueChange={(value) => handleInputValues('merchandiseDamagedValue', value)}
              {...register('merchandiseDamagedValue', { required: true })}
              style={{
                border: '1px solid #70707033',
                paddingLeft: '8px',
                borderBottomRightRadius: '6px',
                borderTopRightRadius: '6px',
                '::placeholder': {
                  color: '#0F0A1D66',
                  fontSize: '14px',
                },
              }}
            />
          </InputGroup>
          {formErrorFreight && !freightValues.merchandiseDamagedValue && <Text textStyle="error">Campo obrigatório.</Text>}
        </FormControl>
        <FormControl w="220px">
          <FormLabel mb="9px">Valor do frete</FormLabel>
          <InputGroup>
            <InputLeftAddon color="#0F0A1DCF" fontSize="13px" fontWeight="bold" bgColor="#0F0A1D21">
              R$
            </InputLeftAddon>
            <CurrencyInput
              defaultValue={freightValues.freightValue}
              id="freightValue"
              name="freightValue"
              allowDecimals={true}
              decimalSeparator=","
              groupSeparator="."
              decimalsLimit={2}
              decimalScale={2}
              fixedDecimalLength={true}
              placeholder="Valor do frete"
              onValueChange={(value) => handleInputValues('freightValue', value)}
              {...register('freightValue', { required: true })}
              style={{
                border: '1px solid #70707033',
                paddingLeft: '8px',
                borderBottomRightRadius: '6px',
                borderTopRightRadius: '6px',
                '::placeholder': {
                  color: '#0F0A1D66',
                  fontSize: '14px',
                },
              }}
            />
          </InputGroup>
          {formErrorFreight && !freightValues.freightValue && <Text textStyle="error">Campo obrigatório.</Text>}
        </FormControl>
        <FormControl w="220px">
          <FormLabel mb="9px">Cubagem da carga</FormLabel>
          <InputGroup>
            <InputLeftAddon color="#0F0A1DCF" fontSize="13px" fontWeight="bold" bgColor="#0F0A1D21">
              cub
            </InputLeftAddon>
            <CurrencyInput
              defaultValue={freightValues.cubage}
              id="cubage"
              name="cubage"
              allowDecimals={true}
              decimalSeparator=","
              groupSeparator="."
              decimalsLimit={2}
              decimalScale={2}
              fixedDecimalLength={true}
              placeholder="Cubagem"
              onValueChange={(value) => handleInputValues('cubage', value)}
              {...register('cubage', { required: true })}
              style={{
                border: '1px solid #70707033',
                paddingLeft: '8px',
                borderBottomRightRadius: '6px',
                borderTopRightRadius: '6px',
                '::placeholder': {
                  color: '#0F0A1D66',
                  fontSize: '14px',
                },
              }}
            />
          </InputGroup>
          {formErrorFreight && !freightValues.cubage && <Text textStyle="error">Campo obrigatório.</Text>}
        </FormControl>
      </Grid>
      <Button mt="20px" variant="primary" onClick={onSubmit}>
        <Text fontStyle="paragraph">Salvar</Text>
      </Button>
    </>
  );
};

export default CardFreight;
