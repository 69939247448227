export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // Radius of the earth in km
  var dLat = (lat2 - lat1) * (Math.PI / 180);// degree 2 rad
  var dLon = (lon2 - lon1) * (Math.PI / 180);// degree 2 rad
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2  * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
};

export const generateTrajectoryChunks = (trajectories, checkpoints, chunkSize = 24) => {
  if (trajectories.length < 2 && checkpoints.length > 0) {
    trajectories.push(checkpoints.pop());// Impede trajeto de menos de dois pontos, pegando o último ponto de checkpoints
  }

  if (trajectories.length < 2) {
    return [];// Se ainda assim for um trajeto inválido, retornar vazio.
  }

  let trajectoriesValid = [];
  for (let i = 0; i < trajectories.length; i++) {
    // Impede latitude e longitude quebrada
    let aux = trajectories[i];
    if (
      aux.latitude == '0' ||
      aux.longitude == '0' ||
      aux.latitude == '' ||
      aux.longitude == '' ||
      aux.latitude === null ||
      aux.longitude === null
    ) {
      continue;
    }

    trajectoriesValid.push(aux);
  }
  trajectories = trajectoriesValid;

  // Garante ter origem e destino
  let origin = trajectories[0];
  let destination = trajectories[trajectories.length - 1];

  // Faz caminho sem rastreio
  if (checkpoints.length === 0) {
    let waypoints = [];

    if (trajectories.length > 2) {
      for (let i = 1; i < trajectories.length; i++) {
        // Loop pulando o primeiro e parando antes do último
        waypoints.push(trajectories[i]);
      }
    }

    return [{
      origin: origin,
      destination: destination,
      waypoints: waypoints,
    }];
  }

  // Caminho com rastreio
  let list = checkpoints.slice();

  list.unshift(origin);
  list.push(destination);

  let directions = [];
  let chunkCount = Math.ceil(list.length / chunkSize);

  for (let i = 0; i < chunkCount; i++) {
    let first = i * chunkSize;
    if (first > 0) {
      first -= 1; // Gerar intersecção dos caminhos
    }
    let last = Math.min((i + 1) * chunkSize - 1, list.length - 1);

    let waypoints = [];

    for (let j = first + 1; j < last; j++) {
      waypoints.push(list[j]);
    }

    directions.push({
      origin: list[first],
      destination: list[last],
      waypoints: waypoints,
    });
  }

  return directions;
};

export const centroid = (coordinates) => {
  let x = 0;
  let y = 0;
  let z = 0;

  for (let i in coordinates) {
    let coordinate = coordinates[i];

    let latitude = coordinate.latitude * Math.PI / 180;
    let longitude = coordinate.latitude * Math.PI / 180;

    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  }

  x = x / coordinates.length;
  y = y / coordinates.length;
  z = z / coordinates.length;

  return {
    latitude: Math.atan2(y, x) * 180 / Math.PI,
    longitude: Math.atan2(z, Math.sqrt(x * x + y * y)) * 180 / Math.PI,
  }
}

export const calculateOptimalZoom = (ne, sw, mapHeight = 500, mapWidth = 1000, worldHeight = 256, worldWidth = 256, zoomMax = 21) => {
  function latRad(lat) {
    let sin = Math.sin(lat * Math.PI / 180);
    let radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  let latFraction = (latRad(ne.latitude) - latRad(sw.latitude)) / Math.PI;

  let lngDiff = ne.longitude - sw.longitude;
  let lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

  let latZoom = Math.floor(Math.log(mapHeight / worldHeight / latFraction) / Math.LN2);
  if (isNaN(latZoom)) {
    latZoom = zoomMax;
  }
  let lngZoom = Math.floor(Math.log(mapWidth / worldWidth / lngFraction) / Math.LN2);
  if (isNaN(lngZoom)) {
    lngZoom = zoomMax;
  }

  return Math.min(latZoom, lngZoom, zoomMax);
}

export const reduceCheckpointListByDistance = (checkpoints, distanceInKm = 3) => {
  if (checkpoints.length <= 2) {
    return checkpoints;
  }

  const checkpointsReduced = [];

  let nextJ = 1;
  for (let j = 0; j < checkpoints.length - 2; j++) { // Não vai ate o último da lista
    let distance = 0;
    let first = checkpoints[j];

    checkpointsReduced.push(checkpoints[j]);
    do {
      let next = checkpoints[nextJ++];
      if (!next) {
        break;
      }
      distance = getDistanceFromLatLonInKm(first.latitude, first.longitude, next.latitude, next.longitude);
    } while (distance <= distanceInKm);
    j = nextJ;
  }

  checkpointsReduced.push(checkpoints[checkpoints.length - 1]); // Sempre envia o último

  return checkpointsReduced;
};

export const checkVisitedPoints = (trajectories, checkpoints, distanceInKm = 2.5) => {
  let result = {
    visited: [],
    notVisited: [],
  }

  for (let i in trajectories) {
    let current = trajectories[i];

    let isVisited = false;
    for (let j in checkpoints) {
      let checkpoint = checkpoints[j];

      let distance = getDistanceFromLatLonInKm(
        current.latitude,
        current.longitude,
        checkpoint.latitude,
        checkpoint.longitude,
      );

      if (distance <= distanceInKm)  {
        isVisited = true;
        break;
      }
    }

    if (isVisited) {
      result.visited.push(current);
    } else {
      result.notVisited.push(current);
    }
  }

  return result;
};
