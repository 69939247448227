import React from 'react';

import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Flex, Text } from '@chakra-ui/react';

const ChartPortShipmentsContainers = ({ entity }) => {
  const tickFormatter = (tickItem) => {
    return tickItem.toLocaleString('pt-BR');
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Flex
          direction="column"
          bgColor="#FFFFFF"
          border="1px"
          borderColor="#7070703D"
          w="full"
          px="15px"
          py="20px"
          gap="10px">
          <p>{label}</p>

          {payload.map((entity, key) => (
            <React.Fragment key={key}>
              <Flex direction="row" align="center" gap="5px">
                <Text>{entity.dataKey == 'processQtt' ? 'Embarques' : 'Containers'}:</Text>

                <Text>{entity.value}</Text>
              </Flex>
            </React.Fragment>
          ))}
        </Flex>
      );
    }

    return null;
  };

  return (
    <>
      {entity?.length > 0 ? (
        <Flex pos="relative" justify="center" w="100%" h="300px">
          <ResponsiveContainer>
            <ComposedChart data={entity} margin={{ bottom: -10, left: -10, top: 5 }}>
              <CartesianGrid vertical={false} />
              <YAxis tickFormatter={tickFormatter} />
              <XAxis dataKey="port" />
              <Tooltip content={<CustomTooltip />} />
              <Legend
                payload={[
                  { value: 'Processos', type: 'square', id: 'processQtt', color: 'rgb(15, 10, 29)' },
                  { value: 'Container', type: 'line', id: 'containerQtt', color: 'rgb(15, 10, 29)' },
                ]}
              />
              <Bar dataKey="processQtt" barSize={40} fill="#AA8BE0" />
              <Line dataKey="containerQtt" stroke="#E52359" dot={{ fill: '#E52359' }} />
            </ComposedChart>
          </ResponsiveContainer>
        </Flex>
      ) : (
        <Flex h="300px" align="center" justify="center">
          Nenhum dado encontrado para os parâmetros filtrados.
        </Flex>
      )}
    </>
  );
};

export default ChartPortShipmentsContainers;
