import FilterCheck from '../../components/MenuBar/components/FilterCheck';

class FilterOption {
  constructor(label, value, children = [], selected = false) {
    this.label = label;
    this.value = value;
    this.children = children;
    this.selected = selected;
    this.state = null;
    this.parent = null;
  }

  resetSelected() {
    this.selected = false;

    for (let i = 0; i < this.children.length; i++) {
      this.children[i].resetSelected();
    }
  }

  set checkState(state) {
    this.state = state;
  }

  renderSelf(values) {
    if (values && values.indexOf(this.value) >= 0) {
      this.selected = true;
    } else if (this.children && this.children.length > 0) {
      let selectedChildren = this.children.filter((item) => item.selected).map((item) => item);
      if (selectedChildren.length === this.children.length) {
        this.selected = true;
      }
    }

    return <FilterCheck filterOption={this} />;
  }

  toggleSelection(newValue = !this.selected) {
    this.selected = newValue;

    if (this.parent != null) {
      const indeterminate = this.parent.isIndeterminate();
      this.parent.state({
        selection: indeterminate ? false : newValue,
        indeterminate: indeterminate,
      });
    }

    this.state({
      selection: newValue,
      indeterminate: this.isIndeterminate(),
    });

    this.children.map((item, key) => item.toggleSelection(newValue));

    return this.selected;
  }

  isIndeterminate() {
    if (this.children.length > 0) {
      let selected = this.children.filter((item) => item.selected).map((item) => item);

      return selected.length !== this.children.length;
    }

    return false;
  }

  renderTree(values) {
    return (
      <>
        {this.renderSelf(values)}
        {this.children.length > 0 && (
          <ul style={{ paddingLeft: '10px', paddingBottom: '10px' }}>
            {this.children.map((item, key) => {
              item.parent = this;
              return (
                <li key={key} style={{ listStyle: 'none', fontSize: '16px', fontWeight: 'normal' }}>
                  {item.renderTree(values)}
                </li>
              );
            })}
          </ul>
        )}
      </>
    );
  }

  getSelectedChildrenValue() {
    return this.children.filter((item) => item.selected).map((item) => item.value);
  }

  getFullSelectedChildrenValue() {
    const getSelectedValuesRecursive = (item) => {
      let values = [];
  
      if (item.value !== null && item.selected) {
        values.push(item.value);
      }
  
      for (let i = 0; i < item.children.length; i++) {
        values = values.concat(getSelectedValuesRecursive(item.children[i]));
      }
  
      return values;
    };
  
    return getSelectedValuesRecursive(this);
  }
}

export default FilterOption;
