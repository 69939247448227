export const buttonStyles = {
  variants: {
    primary: {
      height: '40px',
      bg: 'primary',
      color: 'white',
      border: 'none',
      fontSize: '16px',
      borderRadius: '9px',
      _hover: { bg: 'violet2' },
      _focus: { bg: 'primary', boxShadow: 'none' },
    },

    secundary: {
      height: '40px',
      bg: 'transparent',
      color: 'linkColor',
      border: '1px',
      fontSize: '16px',
      borderColor: 'linkColor',
      borderRadius: '9px',
      _hover: { bg: 'lightViolet', opacity: '1' },
      _focus: { color: 'primary', border: '2px', boxShadow: 'none' },
    },
  },
};
