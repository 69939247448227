import React, { useState } from 'react';

import { AiFillEdit, AiOutlineRise } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Divider, Flex, Image, Text, Tooltip, useDisclosure } from '@chakra-ui/react';

import lineEnd from '../../../../../assets/svgs/line_end.svg';
import lineStart from '../../../../../assets/svgs/line_start.svg';
import { limitChars } from '../../../../../utils/strings';
import ModalFreight from '../TravelList/ModalFreight';

const KanbanCard = ({ entity, color, tab, update, setUpdate, columnId }) => {
  let navigate = useNavigate();
  const [origin, setOrigin] = useState([0, 0]);
  const [destination, setDestination] = useState([0, 0]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDragStart = (event) => {
    const data = {
      identifier: entity.identifier,
      columnId: columnId,
    };
    event.dataTransfer.setData('application/json', JSON.stringify(data));
  };

  return (
    <>
      <Flex
        className="item"
        draggable={tab === 'Preparação' ? true : false}
        onDragStart={handleDragStart}
        cursor={tab === 'Preparação' ? 'grab' : 'default'}>
        <Flex
          direction="column"
          bg="#FFFFFF"
          w="230px"
          minH="200px"
          border="1px solid #E2E8F0"
          borderRadius="8px"
          transition=".3s"
          _hover={{ transform: 'scale(1.05)', boxShadow: '0px 2px 20px #00000044' }}>
          <Box h="4px" bg={color} />

          <Flex direction="column" p="10px" w="full" h="full">
            <Flex justify="space-between" align="center">
              <Flex bg="#422C76" w="57px" h="25px" borderRadius="5px" alignItems="center" justifyContent="center">
                <Text color="#FFFFFF" fontSize="0.7rem" fontWeight="medium">
                  #{entity.code}
                </Text>
              </Flex>

              <Text fontSize="0.7rem" opacity="1" color="#0F0A1D">
                {entity.userDriverName ?? 'Sem motorista'}
              </Text>
            </Flex>

            <Divider borderColor="#E2E8F0" my="5px" />

            <Flex direction="column" h="full" justify="space-between">
              <Flex direction="column" justify="space-between" gap="5px" my="8px">
                <Flex align="center" w="full">
                  <Image src={lineStart} />

                  <Text fontSize="9px" opacity="1" color="#0F0A1D" mx="10px">
                    Inicio:
                  </Text>

                  <Text fontSize="10px" color="#0F0A1D" fontWeight="bold">
                    {entity?.startsAtFormatted != null ? entity?.startsAtFormatted : 'Data não definida'}
                  </Text>
                </Flex>

                <Flex align="center" w="full">
                  <Image src={lineEnd} />

                  <Text fontSize="9px" opacity="1" color="#0F0A1D" mx="10px">
                    Final:
                  </Text>

                  <Text fontSize="10px" color="#0F0A1D" fontWeight="bold">
                    {entity?.endsAtFormatted != null ? entity?.endsAtFormatted : 'Data não definida'}
                  </Text>
                </Flex>
              </Flex>

              <Divider borderColor="#E2E8F0" my="5px" />

              <Flex direction="column" justify="space-between" gap="5px" my="8px">
                <Flex align="center" w="full">
                  <Image src={lineStart} />

                  <Text fontSize="9px" opacity="1" color="#0F0A1D" mx="10px">
                    Origem:
                  </Text>

                  <Tooltip label={entity?.startingFrom}>
                    <Text fontSize="10px" color="#0F0A1D" fontWeight="bold">
                      {limitChars(entity?.startingFrom, 25)}
                    </Text>
                  </Tooltip>
                </Flex>

                <Flex align="center" w="full">
                  <Image src={lineEnd} />

                  <Text fontSize="9px" opacity="1" color="#0F0A1D" mx="10px">
                    Destino:
                  </Text>

                  <Tooltip label={entity?.destinationIn}>
                    <Text fontSize="10px" color="#0F0A1D" fontWeight="bold">
                      {limitChars(entity.destinationIn, 25)}
                    </Text>
                  </Tooltip>
                </Flex>
              </Flex>
            </Flex>
            <Flex align="center" justify="center">
              {(entity?.status == 'DRAFT' || entity?.status == 'CREATED') && (
                <Button
                  bgColor="#6C48C2"
                  color="white"
                  borderRadius="8px"
                  shadow="xl"
                  width="6rem"
                  h="26px"
                  py="15px"
                  px="15px"
                  mr="5px"
                  onClick={(decision) => {
                    if (decision) {
                      onOpen();
                    }
                  }}
                  _hover={{ opacity: '0.5', transition: '0.9s' }}>
                  <AiFillEdit />
                  <Text textStyle="paragraph" fontSize="0.6rem" ml="5px">
                    Editar
                  </Text>
                </Button>
              )}
              <Button
                bgColor="#6C48C2"
                color="white"
                borderRadius="8px"
                shadow="xl"
                width="6rem"
                h="26px"
                py="15px"
                px="15px"
                onClick={(decision) => {
                  if (decision) {
                    navigate(`/logistic/trip/detail/${entity.identifier}`);
                  }
                }}
                _hover={{ opacity: '0.5', transition: '0.9s' }}>
                <AiOutlineRise />
                <Text textStyle="paragraph" fontSize="0.6rem" ml="5px">
                  Detalhes
                </Text>
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex className="float-button-modal">
        <ModalFreight
          isOpen={isOpen}
          onClose={onClose}
          title="Edição de viagem"
          subTitle="Você pode salvar as alterações da sua viagem."
          action={update}
          setAction={setUpdate}
          setOrigin={setOrigin}
          setDestination={setDestination}
          tab={tab}
          entity={entity}
        />
      </Flex>
    </>
  );
};

export default KanbanCard;
