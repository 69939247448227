import React, { useEffect, useState } from 'react';

import { MdClose } from 'react-icons/md';

import {
  Button,
  Flex,
  Grid,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import Layout from '../../components/Layout';
import Menubar from '../../components/MenuBar/Menubar';
import permissions from '../../services/permissions';
import requests from '../../services/requests';
import { filterOptionsDashboard } from '../../utils/filters/filterPresets';

import DashboardCharts from './components/DashboardCharts';
import DashboardMap from './components/DashboardMap';
import DashboardUpdates from './components/DashboardUpdates';
import DashboardWeeklySchedule from './components/DashboardWeeklySchedule';
import PriorityProcess from './components/PriorityProcess';

const DashboardPage = () => {
  const [searchString, setSearchString] = useState('');
  const [searchPeriod, setSearchPeriod] = useState();
  const [searchFilterData, setSearchFilterData] = useState(null);

  const [popup, setPopup] = useState([]);
  const [loadPopup, setLoadPopup] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);

  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const [isRefreshingDashboardMap, setIsRefreshingDashboardMap] = useState(false);
  const [isRefreshingDashboardUpdates, setIsRefreshingDashboardUpdates] = useState(false);
  const [isRefreshingDashboardCharts, setIsRefreshingDashboardCharts] = useState(false);
  const [isRefreshingDashboardWeeklySchedule, setIsRefreshingDashboardWeeklySchedule] = useState(false);
  const [isRefreshingPriorityProcess, setIsRefreshingPriorityProcess] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const refresh = () => {
    setShouldRefresh(true);
  };

  const onChangePeriod = (date) => {
    setSearchPeriod(date);
  };

  const onChangeFilters = (value) => {
    setSearchFilterData(value);
  };

  const onChangeTextFilter = (value) => {
    if (value.length >= 3) {
      setSearchString(value);
    } else {
      setSearchString('');
    }
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsDashboard());
  };

  const getPopup = () => {
    requests
      .getPopup()
      .then((res) => {
        setPopup(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClosePopup = (identifier) => {
    setLoadPopup(true);

    requests
      .postPopupRead(identifier)
      .then(() => {
        onClose();

        setLoadPopup(false);
      })
      .catch((err) => {
        console.log(err);

        setLoadPopup(false);
      });

    setLoadPopup(false);
  };

  useEffect(() => {
    popup.forEach((entity) => {
      if (entity.modality === 'NEW_PANEL' && !isOpen) {
        onOpen();
      }
    });
  }, [popup]);

  useEffect(() => {
    getFilterOptions();
    getPopup();
  }, []);

  useEffect(() => {
    setIsRefreshing(
      isRefreshingDashboardMap ||
        isRefreshingDashboardUpdates ||
        isRefreshingDashboardCharts ||
        isRefreshingDashboardWeeklySchedule ||
        isRefreshingPriorityProcess
    );
  }, [
    isRefreshingDashboardMap,
    isRefreshingDashboardUpdates,
    isRefreshingDashboardCharts,
    isRefreshingDashboardWeeklySchedule,
    isRefreshingPriorityProcess,
  ]);

  useEffect(() => {
    if (!isRefreshing) {
      setShouldRefresh(false);
    }
  }, [isRefreshing]);

  return (
    <Layout>
      <Menubar
        screen="dashboard"
        title="Visão geral"
        placeholder="Pesquisar por texto"
        filterOptions={filterOptions}
        onChangePeriod={onChangePeriod}
        onChangeTextFilter={onChangeTextFilter}
        onChangeFilters={onChangeFilters}
        isRefreshLoading={isRefreshing}
        showRefreshData={true}
        triggerRefreshData={refresh}
      />

      {popup.map((entity) => (
        <Modal
          key={entity.id}
          isCentered
          isOpen={entity.modality === 'NEW_PANEL' && isOpen}
          onClose={onClose}
          motionPreset="slideInBottom">
          <ModalOverlay />

          <ModalContent borderRadius="9px" width="full" maxWidth={'680px'} margin={'25px'} overflowY="auto">
            <ModalHeader p="24px 24px 0 24px" display="flex" alignItems="center" justifyContent="flex-end">
              <IconButton
                bgColor="#ffffff"
                icon={<MdClose size={20} color="#00000057" />}
                onClick={() => onClosePopup(entity.identifier)}
              />
            </ModalHeader>

            <ModalBody px="35px" justifyContent="center" alignItems="center" display="flex" flexDirection="column" gap="20px">
              <Flex direction="column" w="500px">
                <Text textStyle="primaryTitle" fontWeight="medium" textColor="primary" textAlign="center" className="up-anim">
                  {entity.title}
                </Text>

                <Text
                  font="normal normal medium 20px/26px Hanken Grotesk"
                  textColor="#0F0A1D80"
                  textAlign="center"
                  className="up-anim">
                  {entity.subtitle}
                </Text>
              </Flex>

              <Flex gap="20px" align="center" justify="center">
                <Link href={entity.description} target="_blank">
                  <Button variant="primary" w="fit-content" borderRadius="7px" m="15px" p="25px 35px" _hover={{ opacity: 0.8 }}>
                    Acessar manual
                  </Button>
                </Link>

                <Button
                  variant="secundary"
                  w="fit-content"
                  borderRadius="7px"
                  m="15px"
                  p="25px 35px"
                  isLoading={loadPopup}
                  onClick={() => onClosePopup(entity.identifier)}>
                  Acessar sistema
                </Button>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      ))}

      <Flex direction="column" gap="10px" p="10px">
        {(permissions.comexMapping || permissions.comexProcess) && (
          <Grid
            columnGap="10px"
            h={{ base: 'initial', md: '500px' }}
            maxHeight={{ base: 'initial', md: '500px' }}
            gridTemplateColumns={{ base: '1fr', md: '1fr 1fr', lg: '1fr max(35%)' }}>
            <DashboardMap
              filterInfo={filterOptions}
              searchPeriod={searchPeriod}
              searchString={searchString}
              shouldRefresh={shouldRefresh}
              setIsRefreshing={setIsRefreshingDashboardMap}
              maxH={{ base: '350px', md: '500px' }}
            />

            <DashboardUpdates
              filterInfo={filterOptions}
              searchPeriod={searchPeriod}
              searchString={searchString}
              shouldRefresh={shouldRefresh}
              setIsRefreshing={setIsRefreshingDashboardUpdates}
            />
          </Grid>
        )}

        {(permissions.metricsComex || permissions.comexProcess) && (
          <DashboardCharts
            filterInfo={filterOptions}
            searchPeriod={searchPeriod}
            searchString={searchString}
            shouldRefresh={shouldRefresh}
            setIsRefreshing={setIsRefreshingDashboardCharts}
            searchFilterData={searchFilterData}
          />
        )}

        {(permissions.comexAgenda || permissions.comexProcess) && (
          <DashboardWeeklySchedule
            filterInfo={filterOptions}
            searchString={searchString}
            shouldRefresh={shouldRefresh}
            setIsRefreshing={setIsRefreshingDashboardWeeklySchedule}
          />
        )}

        {permissions.comexProcess && (
          <PriorityProcess
            filterInfo={filterOptions}
            searchString={searchString}
            shouldRefresh={shouldRefresh}
            setIsRefreshing={setIsRefreshingPriorityProcess}
          />
        )}
      </Flex>
    </Layout>
  );
};

export default DashboardPage;
