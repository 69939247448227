import React, { useEffect, useRef, useState } from 'react';

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Grid, Select, Text } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import ParameterizationChart from '../../../components/Charts/Comex/ParameterizationChart';
import ProcessPerStepChart from '../../../components/Charts/Comex/ProcessPerStepChart';
import ExpandContainer from '../../../components/ExpandContainer/ExpandContainer';
import HelpTooltip from '../../../components/HelpTooltip/HelpTooltip';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsComex } from '../../../utils/filters/filterPresets';

import Management from './components/Management';
import NationalizationTaxes from './components/NationalizationTaxes';
import PortShipmentsContainers from './components/PortShipmentsContainers';
import RevenueVendemmia from './components/RevenueVendemmia';
import TableSLA from './components/TableSLA';
import TotalRevenue from './components/TotalRevenue';
import TotalVendemmia from './components/TotalVendemmia';
import TypeOperation from './components/TypeOperation';

const MetricsAnalyticsPage = () => {
  const [sizeWidth, setSizeWidth] = useState();

  const [filterOptions, setFilterOptions] = useState([]);

  const [isMobile, setIsMobile] = useState(false);

  const hasPermission = permissions.metricsComex;

  const [isLoadingChart, setIsLoadingChart] = useState(false);

  const [billingChartData, setBillingChartData] = useState([]);
  const [processByStepChartData, setProcessByStepChartData] = useState([]);
  const [parameterizationChartData, setParameterizationChartData] = useState([]);
  const [typeOperationChartData, setTypeOperationChartData] = useState([]);
  const [portShipmentsContainersChartData, setPortShipmentsContainersChartData] = useState([]);
  const [nationalizationTaxesChartData, setNationalizationTaxesChartData] = useState([]);
  const [slaChartData, setSlaChartData] = useState([]);

  const [isLoadingBillingChartData, setIsLoadingBillingChartData] = useState(false);
  const [isLoadingProcessByStepChartData, setIsLoadingProcessByStepChartData] = useState(false);
  const [isLoadingParameterizationChartData, setIsLoadingParameterizationChartData] = useState(false);
  const [isLoadingTypeOperationChartData, setIsLoadingTypeOperationChartData] = useState(false);
  const [isLoadingPortShipmentsContainersChartData, setIsLoadingPortShipmentsContainersChartData] = useState(false);
  const [isLoadingNationalizationTaxesChartData, setIsLoadingNationalizationTaxesChartData] = useState(false);
  const [isLoadingSlaChartData, setIsLoadingSlaChartData] = useState(false);

  const requestList = useRef(0);
  const requestBillingChartData = useRef(0);
  const requestProcessByStepChartData = useRef(0);
  const requestParameterizationChartData = useRef(0);
  const requestTypeOperationChartData = useRef(0);
  const requestPortShipmentsContainersChartData = useRef(0);
  const requestNationalizationTaxesChartData = useRef(0);

  const loadCharts = (filters, key) => {
    loadBillingChart(filters, key);
    loadProcessByStepChart(filters, key);
    loadParameterizationChart(filters, key);
    loadTypeOperationChart(filters, key);
    loadPortShipmentsContainersChart(filters, key);
    loadNationalizationTaxesChart(filters, key);
    loadSlaChart(filters, key);
  };

  const loadBillingChart = async (filters, key) => {
    setIsLoadingChart(true);
    setIsLoadingBillingChartData(true);

    requests
      .dashboardProcessBilling(filters)
      .then((data) => {
        if (requestBillingChartData.current && requestBillingChartData.current > key) {
          return;
        }

        requestBillingChartData.current = key;

        setBillingChartData(data);
      })
      .finally(() => {
        setIsLoadingChart(false);
        setIsLoadingBillingChartData(false);
      });
  };

  const loadProcessByStepChart = async (filters, key) => {
    setIsLoadingChart(true);
    setIsLoadingProcessByStepChartData(true);

    requests
      .dashboardProcessByStage(filters)
      .then((data) => {
        if (requestProcessByStepChartData.current && requestProcessByStepChartData.current > key) {
          return;
        }

        requestProcessByStepChartData.current = key;

        const colors = ['#67B7DC', '#6794DC', '#8067DC', '#A367DC', '#C767DC'];
        const aux = data.map((item, index) => ({
          name: item.stage ? item.stage : item.channel,
          value: parseInt(item.qtt, 10),
          color: colors[index] || '#D6D6D6',
        }));

        setProcessByStepChartData(aux);
      })
      .finally(() => {
        setIsLoadingChart(false);
        setIsLoadingProcessByStepChartData(false);
      });
  };

  const loadParameterizationChart = async (filters, key) => {
    setIsLoadingChart(true);
    setIsLoadingParameterizationChartData(true);

    requests
      .dashboardProcessByChannel(filters)
      .then((data) => {
        if (requestParameterizationChartData.current && requestParameterizationChartData.current > key) {
          return;
        }

        requestParameterizationChartData.current = key;

        const colors = ['#70D499', '#F3D783', '#EC5D66', '#696969', '#D6D6D6'];
        const aux = data.map((item, index) => ({
          name: item.channel,
          value: parseInt(item.qtt, 10),
          color: colors[index] || '#D6D6D6',
        }));

        setParameterizationChartData(aux);
      })
      .finally(() => {
        setIsLoadingChart(false);
        setIsLoadingParameterizationChartData(false);
      });
  };

  const loadTypeOperationChart = async (filters, key) => {
    setIsLoadingChart(true);
    setIsLoadingTypeOperationChartData(true);

    requests
      .dashboardProcessByModality(filters)
      .then((data) => {
        if (requestTypeOperationChartData.current && requestTypeOperationChartData.current > key) {
          return;
        }

        requestTypeOperationChartData.current = key;

        const colors = ['#6794DC', '#67B7DC', '#8067DC', '#D6D6D6', '#D6D6D6', '#A367DC', '#E52359'];
        const aux = data.map((item, index) => ({
          name: item.modality,
          value: parseInt(item.qtt, 10),
          color: colors[index] || '#D6D6D6',
        }));

        setTypeOperationChartData(aux);
      })
      .finally(() => {
        setIsLoadingChart(false);
        setIsLoadingTypeOperationChartData(false);
      });
  };

  const loadPortShipmentsContainersChart = async (filters, key) => {
    setIsLoadingChart(true);
    setIsLoadingPortShipmentsContainersChartData(true);

    requests
      .dashboardBoardings(filters)
      .then((data) => {
        if (requestPortShipmentsContainersChartData.current && requestPortShipmentsContainersChartData.current > key) {
          return;
        }

        requestPortShipmentsContainersChartData.current = key;

        setPortShipmentsContainersChartData(data);
      })
      .finally(() => {
        setIsLoadingChart(false);
        setIsLoadingPortShipmentsContainersChartData(false);
      });
  };

  const loadNationalizationTaxesChart = async (filters, key) => {
    setIsLoadingChart(true);
    setIsLoadingNationalizationTaxesChartData(true);

    requests
      .dashboardClientTax(filters)
      .then((data) => {
        if (requestNationalizationTaxesChartData.current && requestNationalizationTaxesChartData.current > key) {
          return;
        }

        requestNationalizationTaxesChartData.current = key;

        setNationalizationTaxesChartData(data);
      })
      .finally(() => {
        setIsLoadingChart(false);
        setIsLoadingNationalizationTaxesChartData(false);
      });
  };

  const loadSlaChart = (filters, key) => {
    setIsLoadingChart(true);
    setIsLoadingSlaChartData(true);

    requests
      .dashboardProcessSla(filters)
      .then((data) => {
        if (requestList.current && requestList.current > key) {
          return;
        }

        requestList.current = key;

        setSlaChartData(data);
      })
      .finally(() => {
        setIsLoadingChart(false);
        setIsLoadingSlaChartData(false);
      });
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsComex());
  };

  useEffect(() => {
    !isMobile ? setSizeWidth(500) : setSizeWidth(300);
    getFilterOptions();
  }, []);

  const chartView = [
    {
      title: 'Processos por etapa',
      content: (
        <ProcessPerStepChart data={processByStepChartData} isLoading={isLoadingProcessByStepChartData} sizeWidth={sizeWidth + 'px'} />
      ),
    },
    {
      title: 'Parametrização - Canal',
      content: (
        <ParameterizationChart
          data={parameterizationChartData}
          isLoading={isLoadingParameterizationChartData}
          sizeWidth={sizeWidth + 'px'}
        />
      ),
    },
    {
      title: 'Tipos de operação',
      content: (
        <TypeOperation data={typeOperationChartData} isLoading={isLoadingTypeOperationChartData} sizeWidth={sizeWidth + 'px'} />
      ),
    },
    {
      title: 'Porto x Embarques x Containers',
      content: (
        <PortShipmentsContainers
          data={portShipmentsContainersChartData}
          isLoading={isLoadingPortShipmentsContainersChartData}
          sizeWidth={sizeWidth + 'px'}
        />
      ),
    },
  ];

  const graphView = [
    {
      title: 'Total faturamento',
      tooltip: 'Se o filtro de data estiver com um período menor do que 12 meses, será pego o faturamento do ano atual até a data.',
      hasDate: true,
      hasTooltip: true,
      content: <TotalRevenue isLoading={isLoadingBillingChartData} data={billingChartData} />,
    },
    {
      title: 'Total Vendemmia',
      tooltip: 'Se o filtro de data estiver com um período menor do que 12 meses, será pego o faturamento do ano atual até a data.',
      hasDate: true,
      hasTooltip: true,
      content: <TotalVendemmia isLoading={isLoadingBillingChartData} data={billingChartData} />,
    },
    {
      title: 'Gestão/Próprio',
      tooltip: 'Se o filtro de data estiver com um período menor do que 12 meses, será pego o faturamento do ano atual até a data.',
      hasDate: true,
      hasTooltip: true,
      content: <Management isLoading={isLoadingBillingChartData} data={billingChartData} />,
    },
    {
      title: 'Impostos de Nacionalização (Mil)',
      hasDate: false,
      hasTooltip: false,
      content: <NationalizationTaxes isLoading={isLoadingNationalizationTaxesChartData} data={nationalizationTaxesChartData} />,
    },
  ];

  return (
    <Page
      screen="kpi-analytics"
      title="Importação"
      breadcrumbs={[{ link: '#', title: 'Métricas' }]}
      textFilterPlaceholder="Processo, empresa ou ref"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      loadCharts={loadCharts}
      isContentLoading={isLoadingChart}
      filterOptions={filterOptions}
      isRefreshLoading={isLoadingChart}>
      <Flex m="0 10px">
        <Flex flexDirection="column" w="100%" gap="10px">
          {/* desktop */}
          <Grid
            templateColumns="1fr 1fr"
            gap="10px"
            justify="space-between"
            align="center"
            minH="300px"
            display={{ base: 'none', md: 'grid' }}>
            {chartView.map((item, index) => {
              return (
                <Card key={index} h="450px" title={item.title}>
                  {item.content}
                </Card>
              );
            })}
          </Grid>
          {/* mobile */}
          <Flex
            justify="center"
            align="center"
            display={{ base: 'flex', md: 'none' }}
            borderRadius={8}
            overflow="hidden"
            borderColor="#70707033"
            borderWidth="0px 1px 0px 1px"
            bg="white"
            boxShadow="base">
            <Grid w="100%">
              <Accordion defaultIndex={[0]} allowToggle>
                {chartView.map((item, index) => {
                  return (
                    <AccordionItem key={index}>
                      <h2>
                        <AccordionButton
                          p="20px"
                          _hover={{
                            background: 'white',
                          }}>
                          <Text textStyle="title" textColor="primary" flex="1" fontWeight="bold" fontSize="18px">
                            {item.title}
                          </Text>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4} bg="white">
                        {item.content}
                      </AccordionPanel>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </Grid>
          </Flex>

          <Flex justify="space-between" gap="10px" w="100%" flexDirection={{ base: 'column', md: 'row' }}>
            <Flex direction="column" gap="10px">
              {graphView.map((item, index) => {
                return (
                  <Card
                    key={index}
                    header={
                      <Text textStyle="cardTitle" fontSize="14px" ml="10px" textColor="primary" opacity="0.9">
                        {item.title} {item.hasDate && '(' + new Date().getFullYear() + ')'}{' '}
                        {item.hasTooltip && <HelpTooltip tooltip={item.tooltip} />}
                      </Text>
                    }>
                    <ScreenLoader isLoading={isLoadingBillingChartData}>{item.content}</ScreenLoader>
                  </Card>
                );
              })}
            </Flex>

            <ScreenLoader isLoading={isLoadingBillingChartData}>
              {billingChartData ? (
                <RevenueVendemmia data={billingChartData['result']} />
              ) : (
                <Card
                  justify="space-between"
                  h="100%"
                  header={
                    <Flex justify="space-between" gap="40px" py="10px">
                      <Select w="260px" variant="primary">
                        <option value="">Carregando...</option>
                      </Select>
                    </Flex>
                  }
                />
              )}
            </ScreenLoader>
          </Flex>

          <Card title="SLA (Service Level Agreement)" w="100%" pb="20px">
            <ExpandContainer>
              <TableSLA overflowCount={140} data={slaChartData} isLoading={isLoadingSlaChartData} />
            </ExpandContainer>

            <TableSLA overflowCount={300} data={slaChartData} isLoading={isLoadingSlaChartData} />
          </Card>
        </Flex>
      </Flex>
    </Page>
  );
};

export default MetricsAnalyticsPage;
