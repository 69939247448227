import React, { useCallback, useState } from 'react';

import { MdDescription, MdNotifications, MdOutlineChatBubble } from 'react-icons/md';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Image,
  Text,
} from '@chakra-ui/react';

import LabelWithTextH from '../../../../components/Generic/LabelWithTextH';
import requests from '../../../../services/requests';

import ProcessFileCard from './ProcessFileCard';
import ProcessMessageCard from './ProcessMessageCard';
import ProcessUpdatesCard from './ProcessUpdatesCard';

const ProcessDetailStep = ({
  title,
  progress,
  infos,
  stepCount = 0,
  process,
  delay = 0,
  // isBlock = false,
  updates,
  isLast = false,
}) => {
  const [downloadingFile, setDownloadingFile] = useState('');

  const downloadFile = useCallback((identifier, filename) => {
    setDownloadingFile(filename);

    let link = document.createElement('a');

    link.href = (requests.getApiUrl() + `/open/process/file/${process.identifier}/${identifier}/download`).replace(
      '//open',
      '/open'
    );
    link.target = '_blank';
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setDownloadingFile('');
  });

  return (
    <AccordionItem border="none">
      <Flex flexFlow="column">
        <Flex>
          <AccordionButton p="4px 15px">
            <Flex flex="1" alignItems="center">
              <Image
                src={require(`../../../../assets/images/process-step-${stepCount}${
                  process.originDescription ? '-mag' : ''
                }.svg`)}
                filter={progress !== 0 ? 'saturate(1)' : 'saturate(0)'}
                opacity={progress !== 0 ? '1' : '0.5'}
                transition=".4s"
                style={{ animationName: 'scale' }}
              />
              <Text m="0 20px">{title}</Text>
              {updates?.alerts && updates.alerts.length > 0 && (
                <>
                  <MdNotifications color="#E52359" />
                  <Text fontSize="12px" color="#0F0A1D99" ml="3px" mr="10px">
                    {updates.alerts.length}
                  </Text>
                </>
              )}
              {updates?.comments && updates.comments.length > 0 && (
                <>
                  <MdOutlineChatBubble color="#E52359" />
                  <Text fontSize="12px" color="#0F0A1D99" ml="3px" mr="10px">
                    {updates.comments.length}
                  </Text>
                </>
              )}
              {updates?.files && updates.files.length > 0 && (
                <>
                  <MdDescription color="#E52359" />
                  <Text fontSize="12px" color="#0F0A1D99" ml="3px" mr="10px">
                    {updates?.files ? updates.files.length : '0'}
                  </Text>
                </>
              )}
            </Flex>
            <AccordionIcon />
          </AccordionButton>
        </Flex>

        <Flex alignItems="stretch">
          <Box
            className="bar"
            w="5px"
            ml="32px"
            bg="#0F0A1D54"
            opacity={isLast ? 0 : 1}
            style={{ animationName: 'progress' }}>
            <Box
              w="5px"
              bg={process.originDescription ? '#E52359' : 'linkColor'}
              transition=".4s"
              h={`${progress * 100}%`}
            />
          </Box>

          <Box w="calc(100% - 37px)" pb="20px">
            <AccordionPanel>
              <ul
                style={{
                  marginLeft: '23px',
                  marginRight: '20px',
                  display: 'flex',
                  flexFlow: 'wrap',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  animationName: 'progress',
                  gap: '10px',
                }}>
                {infos &&
                  infos.map((info, key) => {
                    return info?.value ? (
                      <li key={key} style={{ listStyle: 'none', display: 'flex', '--delay': `${delay / 10}s` }}>
                        <LabelWithTextH title={info.label} content={info.value} />
                      </li>
                    ) : null;
                  })}
              </ul>
              <Accordion allowMultiple mt="20px">
                <Flex direction="column" gap="10px">
                  {infos?.length === 0
                    && updates?.alerts?.length === 0
                    && updates?.comments?.length === 0
                    && updates?.files?.length == 0
                    && <>
                    <li>
                      <LabelWithTextH content="Sem informações para listar nesta etapa." />
                    </li>
                  </>}

                  {updates?.alerts?.length > 0 && <ProcessUpdatesCard entity={updates} />}

                  {updates?.comments?.length > 0 && <ProcessMessageCard entity={updates} />}

                  {updates?.files?.length > 0 && (
                    <ProcessFileCard entity={updates} downloadFile={downloadFile} downloadingFile={downloadingFile} />
                  )}
                </Flex>
              </Accordion>
            </AccordionPanel>
          </Box>
        </Flex>
      </Flex>
    </AccordionItem>
  );
};

export default ProcessDetailStep;
