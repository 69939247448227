import { useEffect, useState } from 'react';

import { GoogleMap, InfoWindow, Marker, MarkerClusterer, Polyline, useJsApiLoader } from '@react-google-maps/api';

import { Box, Button, Text } from '@chakra-ui/react';

import iconProcess from '../../assets/svgs/maps_process.svg';

import MapPopup from './MapPopup';

function MapDashboard({
  w,
  h,
  points = [],
  lines = [],
  shouldShowCenterMapButton = true,
  defaultMapModality = 'origin-clusters',
  gestureHandling = true,
  fullscreenControl = true,
  mapTypeControl = true,
  maxZoom = 6,
}) {
  const defaultZoom = 2;
  const defaultCenter = {
    lat: 23.73339640721276,
    lng: 2.9533781737686615,
  };
  const maxZoomClustered = 4;

  const [bounds, setBounds] = useState([]);

  const [map, setMap] = useState(null);
  const [mapModality, setMapModality] = useState(defaultMapModality);

  const [currentZoom, setCurrentZoom] = useState(defaultZoom);
  const [isClustered, setIsClustered] = useState(true);

  const [showCenterMapButton, setShowCenterMapButton] = useState(false);
  const [showInfoWindow, setShowInfoWindow] = useState(null);
  const [showInfoWindowCluster, setShowInfoWindowCluster] = useState(false);

  const [processList, setProcessList] = useState([]);
  const [positionList, setPositionList] = useState([]);

  const [arrowPathOffset, setArrowPathOffset] = useState(5);

  const containerStyle = {
    width: '100%',
    height: '500px',
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  });

  const [viewport, setViewport] = useState({
    lat: defaultCenter.lat,
    lng: defaultCenter.lng,
    zoom: defaultZoom,
  });

  const markerClusterShowHandler = (cluster, show) => {
    setShowInfoWindowCluster(show);
    if (show) {
      let markers = cluster.getMarkers();

      let processCodes = '';
      for (let i = 0; i < markers.length; i++) {
        processCodes += markers[i].label + (i == markers.length - 1 ? '' : ', ');
      }

      let offset = ((500 / 6378) * 180) / Math.PI; // Distance in KM / Earth cincunference * Deegrees / PI
      setShowInfoWindowCluster({
        position: {
          lat: cluster.center.lat() + offset,
          lng: cluster.center.lng(),
        },
        content: `Quantidade ${markers.length}: Processos: ${processCodes}`,
      });
    } else {
      setShowInfoWindowCluster(null);
    }
  };

  const markerShowHandler = (index) => {
    setShowInfoWindow(index);
  };

  const iconInfoWindow = encodeURIComponent(`
    <svg xmlns="http://www.w3.org/2000/svg" width="31.641" height="114.708" viewBox="0 0 31.641 114.708">
      <g id="Grupo_4810" data-name="Grupo 4810" transform="translate(-7592.801 -1226.733)">
        <rect id="Retângulo_1826" data-name="Retângulo 1826" width="41.429" height="2" transform="translate(7607.621 1309.8) rotate(-90)" fill="#a885f9"/>
        <g id="Grupo_4808" data-name="Grupo 4808" transform="translate(7592.801 1236.73)">
          <rect id="Retângulo_641" data-name="Retângulo 641" width="31.641" height="31.641" rx="15.821" transform="translate(0 0)" fill="rgba(208,176,253,0.34)"/>
          <rect id="Retângulo_641-2" data-name="Retângulo 641" width="14.304" height="14.304" rx="7" transform="translate(8.668 8.668)" fill="#a885f9"/>
        </g>
        <g id="Grupo_4809" data-name="Grupo 4809" transform="translate(7592.801 1309.8)">
          <rect id="Retângulo_641-3" data-name="Retângulo 641" width="31.641" height="31.641" rx="15.821" transform="translate(0 0)" fill="#d0b0fd" opacity="0.34"/>
          <rect id="Retângulo_641-4" data-name="Retângulo 641" width="14.304" height="14.304" rx="7" transform="translate(8.668 8.668)" fill="#a885f9"/>
        </g>
        <rect id="Retângulo_1827" data-name="Retângulo 1827" width="9.997" height="2" transform="translate(7607.621 1236.73) rotate(-90)" fill="#a885f9"/>
      </g>
    </svg>
  `);
  useEffect(() => {
    let processListAux = [];
    let positionListAux = [];

    let aux = [];
    for (let i = 0; i < points.length; i++) {
      if (isLoaded) aux.push(new google.maps.LatLng(points[i].latitude, points[i].longitude));

      // Pra clusters, deve ter somente os pontos com com processos
      if (points[i].qtt === 0 && mapModality === 'origin-clusters') {
        continue;
      }

      positionListAux.push(points[i]);

      for (let j = 0; j < points[i].process.length; j++) {
        let aux = {
          code: points[i].process[j].code,
          identifier: points[i].process[j].identifier,
          position: {
            lat: points[i].latitude,
            lng: points[i].longitude,
          },
        };
        processListAux.push(aux);
      }
    }

    setBounds(aux);

    setProcessList(processListAux);
    setPositionList(positionListAux);
  }, [points, mapModality]);

  useEffect(() => {
    if (map && isLoaded && bounds && bounds.length > 0) {
      var bound = new google.maps.LatLngBounds();
      for (let key in bounds) {
        bound.extend(bounds[key]);
      }
      map.fitBounds(bound);
    }
  }, [bounds]);

  useEffect(() => {
    let shouldCluster = currentZoom < maxZoomClustered;
    setIsClustered(shouldCluster);

    if (shouldCluster) {
      setShowInfoWindow(null);
    }

    setShowCenterMapButton(currentZoom != defaultZoom);
  }, [currentZoom]);

  let moveArrowTimer;
  const updateArrowPosition = () => {
    moveArrowTimer =
      !moveArrowTimer &&
      setInterval(() => {
        if (arrowPathOffset >= 94) {
          setArrowPathOffset(5);
        } else {
          setArrowPathOffset((arrowPathOffset) => arrowPathOffset + 1);
        }
      }, 100);
  };

  useEffect(() => {
    if (defaultMapModality === 'origin-to-destination') {
      updateArrowPosition();

      return () => clearTimeout(moveArrowTimer);
    }
  }, [arrowPathOffset]);

  return (
    <>
      {shouldShowCenterMapButton && showCenterMapButton && (
        <Button
          position="absolute"
          top="10px"
          right="260px"
          zIndex="15"
          bg="white"
          p="10px 15px"
          boxShadow="0px 1px 4px -1px #0000001A"
          textColor="rgb(26, 32, 44)"
          borderRadius="2px"
          alignItems="center"
          gap="10px"
          fontSize="18px"
          onClick={() => {
            setViewport({
              lat: defaultCenter.lat,
              lng: defaultCenter.lng,
              zoom: defaultZoom,
            });
          }}>
          <Text color="rgb(26, 32, 44)" fontSize="18px" fontFamily="Roboto, Arial, sans-serif">
            Centralizar
          </Text>
        </Button>
      )}
      <Box w={w} h={h} style={{ '--transition': `all .1s linear ` }}>
        {isLoaded && (
          <GoogleMap
            onLoad={(map) => {
              setMap(map);
            }}
            mapContainerStyle={containerStyle}
            center={{
              lat: viewport.lat,
              lng: viewport.lng,
            }}
            zoom={viewport.zoom}
            key={1}
            options={{
              mapId: '1fd5e647f1d25aae',
              minZoom: 1,
              maxZoom: maxZoom,
              zoomControl: true,
              streetViewControl: false,
              keyboardShortcuts: false,
              scaleControl: false,
              fullscreenControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT,
              },
              mapTypeControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT,
              },
              gestureHandling: gestureHandling,
              fullscreenControl: fullscreenControl,
              mapTypeControl: mapTypeControl,
            }}
            onZoomChanged={() => {
              if (map) {
                setCurrentZoom(map.getZoom());
                setViewport({
                  lat: map.center.lat(),
                  lng: map.center.lng(),
                  zoom: map.getZoom(),
                });
              }
            }}>
            {mapModality === 'origin-clusters' && (
              <>
                {isClustered && (
                  <MarkerClusterer
                    gridSize={15}
                    title={'Clique para ver de perto'}
                    maxZoom={maxZoomClustered}
                    minimumClusterSize={1}
                    zoomOnClick={false}
                    onClick={(cluster) => {
                      let zoom = map.getZoom() * 1.5;
                      if (zoom === 0) {
                        zoom = 1;
                      }

                      setViewport({
                        lat: cluster.center.lat(),
                        lng: cluster.center.lng(),
                        zoom: zoom,
                      });
                    }}
                    onMouseOver={(cluster) => {
                      // markerClusterShowHandler(cluster, true)
                    }}
                    onMouseOut={(cluster) => {
                      // markerClusterShowHandler(cluster, false)
                    }}
                    styles={[
                      {
                        url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png',
                        textColor: '#fff',
                        height: 53,
                        width: 53,
                      },
                      {
                        url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png',
                        textColor: '#fff',
                        height: 40,
                        width: 40,
                      },
                      {
                        url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m3.png',
                        textColor: '#fff',
                        height: 66,
                        width: 66,
                      },
                      {
                        url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m4.png',
                        textColor: '#fff',
                        height: 78,
                        width: 78,
                      },
                      {
                        url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m5.png',
                        textColor: '#fff',
                        height: 90,
                        width: 90,
                      },
                    ]}>
                    {(clusterer) => (
                      <div>
                        {processList.map((item, index) => {
                          return (
                            <Marker
                              key={item.identifier}
                              position={item.position}
                              clusterer={clusterer}
                              opacity={0}
                              label={item.code}
                            />
                          );
                        })}
                      </div>
                    )}
                  </MarkerClusterer>
                )}
                {!isClustered &&
                  positionList.map((item, index) => {
                    return (
                      <Marker
                        key={index}
                        position={{
                          lat: item.latitude,
                          lng: item.longitude,
                        }}
                        icon={{
                          path: google.maps.SymbolPath.CIRCLE,
                          scale: 15,
                          strokeColor: '#FF1493',
                          fillColor: '#FF1493',
                          fillOpacity: 1,
                          strokeWeight: 1,
                        }}
                        label={{
                          text: `${item.qtt}`,
                          color: '#fff',
                        }}
                        opacity={1}
                        onClick={() => {
                          markerShowHandler(index);
                          setViewport({
                            lat: item.latitude,
                            lng: item.longitude,
                            zoom: map.getZoom(),
                          });
                        }}>
                        {showInfoWindow === index ? (
                          <MapPopup
                            key={index}
                            point={{
                              header: {
                                title: item.description,
                                icon: iconProcess,
                                color: '#4B0082',
                              },
                              lat: item.latitude,
                              lng: item.longitude,
                              processes: item.process,
                            }}
                            onCloseClick={() => markerShowHandler(null)}
                          />
                        ) : null}
                      </Marker>
                    );
                  })}
              </>
            )}
            {mapModality === 'origin-to-destination' && (
              <>
                {lines.map((item, index) => {
                  return (
                    <Polyline
                      key={index}
                      options={{
                        strokeColor: '#6C48C2',
                        strokeOpacity: 0.4,
                        icons: [
                          {
                            icon: {
                              path: 'M 0,-1 0,1',
                              strokeOpacity: 1,
                              scale: 4,
                            },
                            offset: '0',
                            repeat: '15px',
                          },
                          {
                            icon: {
                              path: google.maps.SymbolPath.CIRCLE,
                              strokeOpacity: 1,
                            },
                            offset: `${arrowPathOffset}%`,
                          },
                          {
                            icon: {
                              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                              strokeOpacity: 1,
                            },
                            offset: '98%',
                          },
                        ],
                      }}
                      path={[
                        {
                          lat: item.origin.latitude,
                          lng: item.origin.longitude,
                        },
                        {
                          lat: item.destination.latitude,
                          lng: item.destination.longitude,
                        },
                      ]}
                    />
                  );
                })}
                {positionList.map((item, index) => {
                  return (
                    <Marker
                      key={index}
                      position={{
                        lat: item.latitude,
                        lng: item.longitude,
                      }}
                      icon={{
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 5,
                        strokeColor: '#FF1493',
                        fillColor: '#FF1493',
                        fillOpacity: 1,
                      }}
                      opacity={1}
                      onMouseOver={() => {
                        markerShowHandler(index);
                        setViewport({
                          lat: map.center.lat(),
                          lng: map.center.lng(),
                          zoom: map.getZoom(),
                        });
                      }}
                      // onMouseOut={() => {
                      //   markerShowHandler(null);
                      //   setViewport({
                      //     lat: map.center.lat(),
                      //     lng: map.center.lng(),
                      //     zoom: map.getZoom(),
                      //   });
                      // }}
                    >
                      {showInfoWindow === index ? (
                        <MapPopup
                          key={index}
                          point={{
                            header: {
                              title: item.description,
                              // icon: iconProcess,
                              color: '#4B0082',
                            },
                            lat: item.latitude,
                            lng: item.longitude,
                          }}
                          onCloseClick={() => markerShowHandler(null)}
                        />
                      ) : null}
                    </Marker>
                  );
                })}
              </>
            )}
            {showInfoWindowCluster && (
              <InfoWindow position={showInfoWindowCluster.position}>
                <>{showInfoWindowCluster.content}</>
              </InfoWindow>
            )}
          </GoogleMap>
        )}
      </Box>
    </>
  );
}

export default MapDashboard;
