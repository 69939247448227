import React from 'react';

import { Link } from 'react-router-dom';

import { Flex, Image, Text } from '@chakra-ui/react';

import gestao from '../../../../assets/images/gestao-ball.png';
import manual from '../../../../assets/images/proprio-ball.png';
import api from '../../../../assets/images/vendemmia-ball.png';

const KanbanItem = ({ entity, delay }) => {
  const getIcon = () => {
    switch (entity.originDescription) {
      case 'api':
        return api;
      case 'manual':
        return manual;
      case 'gestao':
        return gestao;
      default:
        return api;
    }
  };

  return (
    <Link
      to={`/comex/process/detail/${entity.identifier}`}
      style={{ width: '100%', display: 'flex', justifyContent: 'center', '--delay': `${delay / 20}s` }}
      className="up-anim">
      <Flex
        direction="column"
        p="15px"
        bg="#F5F5F5"
        w="100%"
        maxW={{ base: '100%', md: '230px' }}
        borderRadius="8px"
        transition={'.3s'}
        _hover={{ transform: 'scale(1.05)', boxShadow: '0px 2px 20px #00000044' }}>
        <Flex alignItems="center">
          <Image src={getIcon()} borderRadius="100%" w="40px" h="40px" />

          <Flex direction="column" pl="10px">
            <Text color="#0F0A1D" fontSize="14px" fontWeight="medium">
              {entity.code}
            </Text>
            <Text color="#0F0A1D77" fontSize="12px">
              {entity.transportModality}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Link>
  );
};

export default KanbanItem;
