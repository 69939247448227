import React, { useEffect, useState } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

import requests from '../../../../services/requests';

const HelpTermUse = () => {
  const [load, setLoad] = useState(false);
  const [list, setList] = useState([]);

  const loadList = async () => {
    setLoad(true);
    try {
      const response = await requests.currentTermUse();

      setList([response]);
    } catch (error) {
      setLoad(false);

      console.error(error);
    }
    setLoad(false);
  };

  useEffect(() => {
    loadList();
  }, []);

  return (
    <Box p="35px" overflowY="scroll" h="calc(80vh - 120px)">
      {list?.map((entity, key) => {
        return (
          <Text key={key} textStyle="tableTitle" color="primary">
            {entity?.description}
          </Text>
        );
      })}

      {load ? (
        <Flex justify="center" my="25px">
          <span>Carregando...</span>
        </Flex>
      ) : (
        <>
          {list.length === 0 && (
            <Flex justify="center" my="25px">
              Nenhum dado encontrado para os parâmetros filtrados.
            </Flex>
          )}
        </>
      )}
    </Box>
  );
};

export default HelpTermUse;
