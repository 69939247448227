import React from 'react';

import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';

import { Box, Collapse, Flex, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';

import { limitChars } from '../../../../utils/strings';

import DetailsOR from './DetailsOR';

const ReceiptList = ({ entity, count }) => {
  const { isOpen, onToggle } = useDisclosure();

  const Icon = isOpen ? <MdKeyboardArrowDown color="#707070" /> : <MdKeyboardArrowRight color="#707070" />;

  const handleColor = (status) => {
    switch (status) {
      case 'Recebido':
      case 'Liberado':
        return 'green';
      case 'Em Recebimento':
      case 'AGUARDANDO NF':
        return 'yellow-500';
      case 'Interrompido':
      case 'Bloqueado':
      case 'CANCELADA':
      case 'BLOQUEADA':
        return 'red';
      case 'PROCESSADA':
        return 'status-900';
      case 'IMPORTADA':
        return 'status-200';
      case 'AG. ALOCAÇÃO':
        return 'orange-400';
      case 'EM CARGA':
        return 'status-100';
      case 'DIGITADA':
        return 'gray-200';
      default:
        return '';
    }
  };

  return (
    <>
      <Tr onClick={onToggle} className={`border`} cursor="pointer">
        <Td>
          <Box>{Icon}</Box>
        </Td>
        <Td cursor="pointer">
          <Box>
            {entity?.notas && entity?.notas?.length > 0 && (
              <Flex direction="column">
                {entity.notas.slice(0, 1).map((nota, key) => {
                  return <Text key={key}>{nota?.idNota ?? '-'}</Text>;
                })}

                {entity.notas.length > 1 && (
                  <Tooltip
                    label={entity.notas
                      .slice(1)
                      .map((nota) => {
                        return nota?.idNota;
                      })
                      .join(', ')}>
                    <Box>
                      {'+'}
                      {entity.notas.length - 1}
                    </Box>
                  </Tooltip>
                )}
              </Flex>
            )}
          </Box>
        </Td>
        <Td>
          <Box>
            <Flex
              align="center"
              borderRadius="15px"
              justifyContent="center"
              bgColor={handleColor(entity?.status)}
              color="white"
              w="9rem"
              h="2rem">
              {entity.status ?? '-'}
            </Flex>
          </Box>
        </Td>
        <Td>
          <Box>{entity?.idOrdemRecto ?? '-'}</Box>
        </Td>
        <Td>
          <Box minW="300px">
            {entity?.nomeRemetente?.length > 50 ? (
              <Tooltip label={entity.nomeRemetente}>{limitChars(entity.nomeRemetente, 50) ?? '-'}</Tooltip>
            ) : (
              entity?.nomeRemetente ?? '-'
            )}
          </Box>
        </Td>
        <Td>
          <Box minW="300px">
            {entity?.nomeDestinatario?.length > 50 ? (
              <Tooltip label={entity.nomeDestinatario}>{limitChars(entity.nomeDestinatario, 50) ?? '-'}</Tooltip>
            ) : (
              entity?.nomeDestinatario ?? '-'
            )}
          </Box>
        </Td>
        <Td>
          <Box>
            <Text minW="150px">{entity?.diNumberFormatted ?? '-'}</Text>
          </Box>
        </Td>
        <Td>
          <Box>{entity?.origem ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.stateCodeFormatted ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.dtInicioFormatted ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.dtFimFormatted ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.conferenceAtFormatted ?? '-'}</Box>
        </Td>
        <Td>
          <Box>{entity?.qtdVolumeFormatted ?? '-'}</Box>
        </Td>
        <Td>
          <Box>
            {entity?.processCode ? (
              <>
                <a href={`/comex/process/detail/${entity.processIdentifier}`} target="_blank" rel="noopener noreferrer">
                  {entity.processCode}
                </a>
              </>
            ) : (
              '-'
            )}
          </Box>
        </Td>
        <Td>
          <Box>
            <Text textColor={handleColor(entity?.situacao)}>{entity?.situacao ?? '-'} </Text>
          </Box>
        </Td>
      </Tr>
      <Tr w="full">
        <Td
          p="0"
          colSpan={15}
          border="none"
          style={{
            padding: 0,
          }}>
          <Collapse in={isOpen} animateOpacity>
            <Box bgColor="#F9F9F9" m="1.2rem">
              <Table>
                <Thead border="0" borderBottom="1px" h={50}>
                  <Tr>
                    <Th>Estado do produto</Th>
                    <Th>Código</Th>
                    <Th>Quantidade</Th>
                    <Th>Lote</Th>
                    <Th>Descrição</Th>
                    <Th textAlign="end">
                      <DetailsOR entity={entity} />
                    </Th>
                  </Tr>
                </Thead>
                <Tbody borderBottom="1px">
                  {entity?.itens && entity?.itens?.length > 0 ? (
                    entity?.itens?.map((item, key) => {
                      return (
                        <React.Fragment key={key}>
                          <Tr>
                            <Td>
                              <Box>
                                <Flex
                                  align="center"
                                  justifyContent="center"
                                  borderRadius="15px"
                                  bgColor={handleColor(item?.estadoText)}
                                  color="white"
                                  w="9rem"
                                  h="2rem">
                                  {item?.estadoText ?? '-'}
                                </Flex>
                              </Box>
                            </Td>
                            <Td>{item?.codProduto ?? '-'}</Td>
                            <Td>{item?.qtdeEntradaFormatted ?? '-'}</Td>
                            <Td>{item?.loteFabricacao ?? '-'}</Td>
                            <Td>{item?.descricao ?? '-'}</Td>
                            <Td></Td>
                          </Tr>
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <Tr>
                      <Td colSpan={6}>
                        <Flex justify="center" my="10px">
                          Nenhum dado encontrado para os parâmetros filtrados.
                        </Flex>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Box>
          </Collapse>
        </Td>
      </Tr>
    </>
  );
};

export default ReceiptList;
