import React, { useEffect, useState } from 'react';

import { TabPanel } from '@chakra-ui/react';

import TableTravel from './components/Tables/Travel/TableTravel';

const TravelsTab = ({ user }) => {
  const [isLoadingDataload, setIsLoadingData] = useState(false);
  const [data, setData] = useState([]);

  const loadData = () => {
    setIsLoadingData(true);
    setData(user?.trips);
    setIsLoadingData(false);
  };

  useEffect(() => {
    loadData();
  }, [user]);

  return (
    <TabPanel>
      <TableTravel
        list={data}
        load={isLoadingDataload}
        showDetails={true}
        loadData={loadData}
      />
    </TabPanel>
  );
};

export default TravelsTab;
