import React, { useEffect, useState } from 'react';

import { StandaloneSearchBox } from '@react-google-maps/api';
import { useForm } from 'react-hook-form';
import { MdAdd, MdDeleteOutline } from 'react-icons/md';
import Select from 'react-select';

import { Box, Button, Divider, Flex, FormControl, FormLabel, Grid, Icon, Input, Text, Textarea, Tooltip } from '@chakra-ui/react';

import requests from '../../../../../services/requests';

const CardTrip = ({
  setFormErrorTrip,
  formErrorTrip,
  setHasError,
  setStep,
  setFormCardTrip,
  setTrajectories,
  setOrigin,
  setDestination,
  trajectories,
  formData,
  setFormData,
  setFormConfig,
  addressList,
  setAddressList,
  ctesSelected,
  setCtesSelected,
}) => {
  const generateId = generateUniqueId();

  const [searchBox, setSearchBox] = useState();
  const [fields, setFields] = useState(
    trajectories?.length > 0
      ? trajectories
      : [
          {
            id: generateId,
            type: '',
            address: '',
            cte: '',
            observation: '',
            driverFreightFiles: [],
          },
        ]
  );

  const { register, setValue } = useForm();

  function generateUniqueId() {
    return new Date().getTime();
  }

  const handleAddField = () => {
    const newId = generateId;

    const newField = {
      id: newId,
      type: '',
      address: '',
      cte: '',
      observation: '',
      driverFreightFiles: [],
    };

    setFields([...fields, newField]);
  };

  const handleFieldChange = (id, event) => {
    const updatedFields = fields.map((field) => {
      if (field.id === id) {
        return {
          ...field,

          [event.target.name]: event.target.value,
        };
      }

      return field;
    });

    setFields(updatedFields);
  };

  const handleRemoveField = (id) => {
    const indexToRemove = fields.findIndex((field) => field.id === id);

    if (indexToRemove !== -1) {
      const updatedFields = [...fields];
      const removedField = updatedFields.splice(indexToRemove, 1)[0];

      const updatedAddressList = { ...addressList };
      delete updatedAddressList[removedField.id];

      setFields(updatedFields);
      setAddressList(updatedAddressList);
    }
  };

  const onSubmit = () => {
    const hasEmptyFields = fields.some((field) => !field.type || !field.address);

    if (hasEmptyFields) {
      setFormErrorTrip(true);

      return;
    }

    setFormCardTrip(true);

    let options = [];
    fields.forEach((field) => {
      requests.post('/driver/freight/maps/geolocation/search', { address: field.address }).then((response) => {
        if (field.type == 'COLETA') {
          setOrigin([response.longitude, response.latitude]);
        } else {
          setDestination([response.longitude, response.latitude]);
        }
      });

      options.push({
        address: field.address,
        type: field.type,
        observation: field.observation,
        driverFreightFiles: field.driverFreightFiles,
      });
    });

    setTrajectories(options);

    setStep(2);
  };

  useEffect(() => {
    setHasError(!fields[0].type || !fields[0].address);
  }, [fields]);

  return (
    <>
      <Flex direction="column" gap="20px" mx="3px">
        {fields.map((field, index) => (
          <React.Fragment key={index}>
            <Grid templateColumns="repeat(2, 1fr)" gap="10px">
              <FormControl w="220px">
                <FormLabel mb="9px">
                  <Flex align="center" gap="8px">
                    Trecho {index + 1}
                    {fields.length > 1 && (
                      <Tooltip label="Excluir">
                        <Box h="20px">
                          <Icon
                            as={MdDeleteOutline}
                            color="#AFAEB4"
                            w="20px"
                            h="20px"
                            cursor="pointer"
                            _hover={{ color: '#E74C3C' }}
                            onClick={() => handleRemoveField(field.id)}
                          />
                        </Box>
                      </Tooltip>
                    )}
                  </Flex>
                </FormLabel>

                <Select
                  className="input-register"
                  color="#0F0A1D66"
                  placeholder="Tipo de trecho"
                  {...register(`type_${field.id}`, { required: true })}
                  name="type"
                  options={[
                    {
                      label: 'COLETA',
                      value: 'COLETA',
                    },
                    {
                      label: 'ENTREGA',
                      value: 'ENTREGA',
                    },
                  ]}
                  defaultValue={field.type}
                  onChange={(option) => {
                    setValue(`type_${field.id}`, option.value);
                    const updatedFields = [...fields];
                    updatedFields[index].type = option.value;
                    setFields(updatedFields);
                  }}
                />

                {formErrorTrip && !field.type && <Text textStyle="error">Campo obrigatório.</Text>}
              </FormControl>

              <FormControl w="100%">
                <FormLabel mb="9px">{`CT'es do trajeto`}</FormLabel>
                <Flex direction="column" gap="8px">
                  <Select
                    placeholder="Selecione"
                    options={ctesSelected}
                    isMulti={true}
                    name="driverFreightFiles"
                    {...register(`driverFreightFiles`, { required: true })}
                    value={field.driverFreightFiles}
                    onChange={(option) => {
                      setValue(`driverFreightFiles`, option);
                      const updatedFields = [...fields];
                      updatedFields[index].driverFreightFiles = option;
                      setFields(updatedFields);
                    }}
                  />
                </Flex>
              </FormControl>
            </Grid>

            <FormControl w="100%">
              <FormLabel mb="9px">Endereço</FormLabel>
              <StandaloneSearchBox
                onLoad={(ref) => {
                  setSearchBox(ref);
                }}
                onPlacesChanged={() => {
                  const places = searchBox.getPlaces();

                  if (places && places.length > 0) {
                    const place = places[0];

                    setValue(`address_${field.id}`, place.formatted_address);

                    field.address = place.formatted_address;
                  }
                }}
                className="pac-container">
                <Input
                  className="input-register"
                  w="100%"
                  _placeholder={{ color: '#0F0A1D66' }}
                  placeholder="Preencha o endereço"
                  {...register(`address_${field.id}`, { required: true })}
                  name="address"
                  defaultValue={field.address}
                  onChange={(event) => {
                    handleFieldChange(field.id, event);

                    setValue(`address_${field.id}`, event.target.value);
                  }}
                  onBlur={(event) => {
                    let aux = { ...addressList };

                    aux[field.id] = event.target.value;

                    if (event.target.value != '') {
                      setAddressList(aux);
                    }
                  }}
                />
              </StandaloneSearchBox>

              {formErrorTrip && !field.address && <Text textStyle="error">Campo obrigatório.</Text>}
            </FormControl>

            <FormControl w="100%">
              <FormLabel mb="9px">Observações do trecho</FormLabel>
              <Textarea
                height="180px"
                resize="none"
                bg="#F2F2F2"
                name="observation"
                placeholder="Preencha a observação..."
                defaultValue={field.observation}
                onChange={(event) => {
                  handleFieldChange(field.id, event);
                  setValue(`observation`, event.target.value);
                }}
              />
            </FormControl>

            {index !== fields.length - 1 && <Divider borderColor="#70707033 " />}
          </React.Fragment>
        ))}

        <Button
          mb="20px"
          bgColor="transparent"
          border="1px"
          borderColor="red"
          borderRadius="9px"
          textColor="red"
          fontSize="12px"
          py="6px"
          h="auto"
          _hover={{ bgColor: 'transparent', shadow: 'lg' }}
          leftIcon={<MdAdd color="red" size={15} />}
          onClick={handleAddField}>
          Adicionar trecho
        </Button>
      </Flex>

      <Button mt="20px" variant="primary" onClick={onSubmit}>
        <Text fontStyle="paragraph">Salvar</Text>
      </Button>
    </>
  );
};

export default CardTrip;
