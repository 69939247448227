import React from 'react';

import { Button, ButtonGroup, Flex } from '@chakra-ui/react';

const CalendarTab = ({ calendarTypeButtons, calendarModality, setCalendarModality, setList }) => {
  return (
    <Flex justifyContent={'end'} p={'10px'}>
      <ButtonGroup spacing={0} display={'flex'}>
        {calendarTypeButtons.map((button, index) => {
          return (
            <Button
              key={index}
              onClick={() => {
                setCalendarModality(button.type);
                setList(null);
              }}
              className={calendarModality === button.type ? 'is-selected-type' : ''}
              border="1px solid #6c48c2"
              bg={'transparent'}
              color="linkColor"
              cursor="pointer"
              borderRadius={0}
              fontSize="15px"
              maxH="30px"
              minWidth="100px">
              {button.title}
            </Button>
          );
        })}
      </ButtonGroup>
    </Flex>
  );
};

export default CalendarTab;
