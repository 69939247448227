import React, { useEffect, useState } from 'react';

import { MdAdd, MdOutlineReceiptLong } from 'react-icons/md';
import { RiFileListFill, RiLayoutGridFill } from 'react-icons/ri';
import { Link as RouterLink } from 'react-router-dom';

import {
  Button,
  Flex,
  Grid,
  Link,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import ScreenLoader from '../../../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../../../services/requests';
import ModalFreight from '../TravelList/ModalFreight';

import KanbanColumn from './KanbanColumn';

const TravelKanban = ({ action, setAction, setTabTravel, filterSelected }) => {
  const [tab, setTab] = useState('Preparação');
  const [loadPreparation, setLoadPreparation] = useState(false);
  const [loadExecution, setLoadExecution] = useState(false);
  const [loadDelivery, setLoadDelivery] = useState(false);
  const [loadInvoicing, setLoadInvoicing] = useState(false);
  const [isLoadingItems, setIsLoadingItems] = useState(false);
  const [origin, setOrigin] = useState([0, 0]);
  const [destination, setDestination] = useState([0, 0]);

  // Preparação
  const [refusedByDriver, setRefusedByDriver] = useState();
  const [draft, setDraft] = useState();
  const [created, setCreated] = useState();
  const [sentToDriver, setSentToDriver] = useState();
  const [acceptedByDriver, setAcceptedByDriver] = useState();
  const [cancelled, setCancelled] = useState();
  const [metaRefusedByDriver, setMetaRefusedByDriver] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaDraft, setMetaDraft] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaCreated, setMetaCreated] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaSentToDriver, setMetaSentToDriver] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaCancelled, setMetaCancelled] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaAcceptedByDriver, setMetaAcceptedByDriver] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });

  //Execução
  const [goingToCollect, setGoingToCollect] = useState();
  const [readToCollect, setReadToCollect] = useState();
  const [collecting, setCollecting] = useState();
  const [readToTravel, setReadToTravel] = useState();
  const [travelling, setTravelling] = useState();
  const [halted, setHalted] = useState();
  const [metaGoingToCollect, setMetaGoingToCollect] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaReadToCollect, setMetaReadToCollect] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaCollecting, setMetaCollecting] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaReadToTravel, setMetaReadToTravel] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaTravelling, setMetaTravelling] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaHalted, setMetaHalted] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });

  //Entrega
  const [arrivedAtDestination, setArrivedAtDestination] = useState();
  const [unloading, setUnloading] = useState();
  const [delivered, setDelivered] = useState();
  const [deliverRefused, setDeliverRefused] = useState();
  const [deliverSignatureCollected, setDeliverSignatureCollected] = useState();
  const [deliverSignatureRefused, setDeliverSignatureRefused] = useState();
  const [completed, setCompleted] = useState();
  const [metaArrivedAtDestination, setMetaArrivedAtDestination] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaUnloading, setMetaUnloading] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaDelivered, setMetaDelivered] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaDeliverRefused, setMetaDeliverRefused] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaDeliverSignatureCollected, setMetaDeliverSignatureCollected] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaDeliverSignatureRefused, setMetaDeliverSignatureRefused] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaCompleted, setMetaCompleted] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });

  //Faturamento
  const [paymentAllowed, setPaymentAllowed] = useState();
  const [paid, setPaid] = useState();
  const [paidCancelled, setPaidCancelled] = useState();
  const [metaPaymentAllowed, setMetaPaymentAllowed] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaPaid, setMetaPaid] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });
  const [metaPaidCancelled, setMetaPaidCancelled] = useState({
    current_page: 0,

    item_count: 0,

    page_count: 0,

    page_size: 0,

    total_count: 0,

    total_pages: 0,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const resolution = window.innerWidth;

  const tabOpt = ['Preparação', 'Execução', 'Entrega', 'Faturamento'];
  const setCurrentTab = (tab) => {
    setTab(tab);
  };

  // Preparação
  const showMoreItems = async (requestFunction, stateSetter, state, metaSetter, meta) => {
    setIsLoadingItems(true);
    try {
      const response = await requestFunction(meta.current_page > 0 ? meta.current_page + 1 : 1, 10, filterSelected);

      stateSetter([[...state[0], ...response.data]]);
      metaSetter(response.meta);
      setIsLoadingItems(false);
    } catch (error) {
      setIsLoadingItems(false);
      console.error('Erro na solicitação da API: ', error);
    }
  };

  const showMoreRefusedByDriver = () => {
    showMoreItems(requests.requestRefusedByDriver, setRefusedByDriver, refusedByDriver, setMetaRefusedByDriver, metaRefusedByDriver);
  };

  const showMoreDraft = () => {
    showMoreItems(requests.requestDraft, setDraft, draft, setMetaDraft, metaDraft);
  };

  const showMoreCreated = () => {
    showMoreItems(requests.requestCreated, setCreated, created, setMetaCreated, metaCreated);
  };

  const showMoreSentToDriver = () => {
    showMoreItems(requests.requestSentToDriver, setSentToDriver, sentToDriver, setMetaSentToDriver, metaSentToDriver);
  };

  const showMoreAcceptedByDriver = () => {
    showMoreItems(
      requests.requestAcceptedByDriver,
      setAcceptedByDriver,
      acceptedByDriver,
      setMetaAcceptedByDriver,
      metaAcceptedByDriver
    );
  };

  const showMoreCancelled = () => {
    showMoreItems(requests.requestCancelled, setCancelled, cancelled, setMetaCancelled, metaCancelled);
  };

  //Execução
  const showMoreGoingToCollect = () => {
    showMoreItems(requests.requestGoingToCollect, setGoingToCollect, goingToCollect, setMetaGoingToCollect, metaGoingToCollect);
  };

  const showMoreReadToCollect = () => {
    showMoreItems(requests.requestReadToCollect, setReadToCollect, readToCollect, setMetaReadToCollect, metaReadToCollect);
  };

  const showMoreCollecting = () => {
    showMoreItems(requests.requestCollecting, setCollecting, collecting, setMetaCollecting, metaCollecting);
  };

  const showMoreReadToTravel = () => {
    showMoreItems(requests.requestReadToTravel, setReadToTravel, readToTravel, setMetaReadToTravel, metaReadToTravel);
  };

  const showMoreTravelling = () => {
    showMoreItems(requests.requestTravelling, setTravelling, travelling, setMetaTravelling, metaTravelling);
  };

  const showMoreHalted = () => {
    showMoreItems(requests.requestHalted, setHalted, halted, setMetaHalted, metaHalted);
  };

  //Entrega
  const showMoreArrivedAtDestination = () => {
    showMoreItems(
      requests.requestArrivedAtDestination,
      setArrivedAtDestination,
      arrivedAtDestination,
      setMetaArrivedAtDestination,
      metaArrivedAtDestination
    );
  };

  const showMoreUnloading = () => {
    showMoreItems(requests.requestUnloading, setUnloading, unloading, setMetaUnloading, metaUnloading);
  };

  const showMoreDeliverRefused = () => {
    showMoreItems(requests.requestDeliverRefused, setDeliverRefused, deliverRefused, setMetaDeliverRefused, metaDeliverRefused);
  };

  const showMoreDeliverSignatureCollected = () => {
    showMoreItems(
      requests.requestDeliverSignatureCollected,
      setDeliverSignatureCollected,
      deliverSignatureCollected,
      setMetaDeliverSignatureCollected,
      metaDeliverSignatureCollected
    );
  };

  const showMoreDeliverSignatureRefused = () => {
    showMoreItems(
      requests.requestDeliverSignatureRefused,
      setDeliverSignatureRefused,
      deliverSignatureRefused,
      setMetaDeliverSignatureRefused,
      metaDeliverSignatureRefused
    );
  };

  const showMoreCompleted = () => {
    showMoreItems(requests.requestCompleted, setCompleted, completed, setMetaCompleted, metaCompleted);
  };

  //Faturamento
  const showMorePaymentAllowed = () => {
    showMoreItems(requests.requestPaymentAllowed, setPaymentAllowed, paymentAllowed, setMetaPaymentAllowed, metaPaymentAllowed);
  };

  const showMorePaid = () => {
    showMoreItems(requests.requestPaid, setPaid, paid, setMetaPaid, metaPaid);
  };

  const showMorePaidCancelled = () => {
    showMoreItems(requests.requestPaidCancelled, setPaidCancelled, paidCancelled, setMetaPaidCancelled, metaPaidCancelled);
  };

  // Preparação
  useEffect(() => {
    const fetchData = async (requestFunction, setStateFunction, setMetaFunction, currentPage, pageSize) => {
      try {
        const response = await requestFunction(currentPage > 0 ? currentPage : 1, pageSize, filterSelected);

        setStateFunction([response.data]);
        setMetaFunction(response.meta);
      } catch (error) {
        console.error('Erro na solicitação da API: ', error);
      }
    };

    fetchData(requests.requestRefusedByDriver, setRefusedByDriver, setMetaRefusedByDriver, metaRefusedByDriver.current_page, 10);
    fetchData(requests.requestDraft, setDraft, setMetaDraft, metaDraft.current_page, 10);
    fetchData(requests.requestCreated, setCreated, setMetaCreated, metaCreated.current_page, 10);
    fetchData(requests.requestSentToDriver, setSentToDriver, setMetaSentToDriver, metaSentToDriver.current_page, 10);
    fetchData(requests.requestAcceptedByDriver, setAcceptedByDriver, setMetaAcceptedByDriver, metaAcceptedByDriver.current_page, 10);
    fetchData(requests.requestCancelled, setCancelled, setMetaCancelled, metaCancelled.current_page, 10);
  }, [filterSelected]);

  //Execução
  useEffect(() => {
    const fetchData = async (requestFunction, setStateFunction, setMetaFunction, currentPage, pageSize) => {
      try {
        const response = await requestFunction(currentPage > 0 ? currentPage : 1, pageSize, filterSelected);

        setStateFunction([response.data]);
        setMetaFunction(response.meta);
      } catch (error) {
        console.error('Erro na solicitação da API: ', error);
      }
    };

    fetchData(requests.requestGoingToCollect, setGoingToCollect, setMetaGoingToCollect, metaGoingToCollect.current_page, 10);
    fetchData(requests.requestReadToCollect, setReadToCollect, setMetaReadToCollect, metaReadToCollect.current_page, 10);
    fetchData(requests.requestCollecting, setCollecting, setMetaCollecting, metaCollecting.current_page, 10);
    fetchData(requests.requestReadToTravel, setReadToTravel, setMetaReadToTravel, metaReadToTravel.current_page, 10);
    fetchData(requests.requestTravelling, setTravelling, setMetaTravelling, metaTravelling.current_page, 10);
    fetchData(requests.requestHalted, setHalted, setMetaHalted, metaHalted.current_page, 10);
  }, [filterSelected]);

  //Entrega
  useEffect(() => {
    const fetchData = async (requestFunction, setStateFunction, setMetaFunction, currentPage, pageSize) => {
      try {
        const response = await requestFunction(currentPage > 0 ? currentPage : 1, pageSize, filterSelected);

        setStateFunction([response.data]);
        setMetaFunction(response.meta);
      } catch (error) {
        console.error('Erro na solicitação da API: ', error);
      }
    };

    fetchData(
      requests.requestArrivedAtDestination,
      setArrivedAtDestination,
      setMetaArrivedAtDestination,
      metaArrivedAtDestination.current_page,
      10
    );
    fetchData(requests.requestUnloading, setUnloading, setMetaUnloading, metaUnloading.current_page, 10);
    fetchData(requests.requestDeliverRefused, setDeliverRefused, setMetaDeliverRefused, metaDeliverRefused.current_page, 10);
    fetchData(
      requests.requestDeliverSignatureCollected,
      setDeliverSignatureCollected,
      setMetaDeliverSignatureCollected,
      metaDeliverSignatureCollected.current_page,
      10
    );
    fetchData(
      requests.requestDeliverSignatureRefused,
      setDeliverSignatureRefused,
      setMetaDeliverSignatureRefused,
      metaDeliverSignatureRefused.current_page,
      10
    );
    fetchData(requests.requestCompleted, setCompleted, setMetaCompleted, metaCompleted.current_page, 10);
  }, [filterSelected]);

  //Faturamento
  useEffect(() => {
    const fetchData = async (requestFunction, setStateFunction, setMetaFunction, currentPage, pageSize) => {
      try {
        const response = await requestFunction(currentPage > 0 ? currentPage : 1, pageSize, filterSelected);

        setStateFunction(response.data);

        setMetaFunction(response.meta);
      } catch (error) {
        console.error('Erro na solicitação da API: ', error);
      }
    };

    fetchData(requests.requestPaymentAllowed, setPaymentAllowed, setMetaPaymentAllowed, metaPaymentAllowed.current_page, 10);
    fetchData(requests.requestPaid, setPaid, setMetaPaid, metaPaid.current_page, 10);
    fetchData(requests.requestPaidCancelled, setPaidCancelled, setMetaPaidCancelled, metaPaidCancelled.current_page, 10);
  }, [filterSelected]);

  useEffect(() => {
    setOrigin([0, 0]);
    setDestination([0, 0]);
  }, [isOpen]);

  return (
    <>
      <Flex direction="column" w="100%" h="calc(100vh - 180px)">
        <Tabs position="relative" variant="unstyled" w="100%">
          <TabList borderBottom="#70707036 solid 1px" flexDirection={{ base: 'column', md: 'row' }} justifyContent="space-between">
            <Flex gap="15px">
              {resolution > 768 ? (
                tabOpt.map((item, index) => (
                  <Tab key={index} color="#BEBEBE" _selected={{ color: '#6C48C2' }} onClick={() => setCurrentTab(item)}>
                    <Text>{item}</Text>
                  </Tab>
                ))
              ) : (
                <Grid display={{ base: 'grid', md: 'none' }} gridTemplateColumns={'repeat(2, 1fr)'} justifyItems={'center'} order="2">
                  {tabOpt.map((item, index) => (
                    <Tab key={index} color="#BEBEBE" _selected={{ color: '#6C48C2' }} onClick={() => setCurrentTab(item)}>
                      <Text fontSize={'14px'}>{item}</Text>
                    </Tab>
                  ))}
                </Grid>
              )}
            </Flex>

            <Flex
              h="70px"
              p="15px 10px"
              justifyContent={{ base: 'center', md: 'initial' }}
              w={{ base: 'full', md: 'initial' }}
              alignItems="center"
              alignSelf={{ base: 'initial', md: 'flex-end' }}>
              <Flex flexDirection={{ base: 'column', md: 'row' }} gap="15px">
                <Link as={RouterLink} to="/logistic/trip">
                  <Button
                    variant="secundary"
                    borderRadius="0px"
                    borderColor="#6C48C2"
                    w={{ base: '100%', md: 'initial' }}
                    title="Viagens em lista"
                    onClick={() => setTabTravel('progress')}
                    rightIcon={<RiFileListFill />}>
                    Lista de Viagens
                  </Button>
                </Link>

                <Link as={RouterLink} to="/logistic/trip#kanban">
                  <Button
                    variant="primary"
                    borderRadius="0px"
                    borderColor="#6C48C2"
                    title="Viagens em kanban"
                    rightIcon={<RiLayoutGridFill />}
                    onClick={() => setTab('kanban')}>
                    Quadro de Viagens
                  </Button>
                </Link>

                <Link as={RouterLink} to="/logistic/trip#cte">
                  <Button
                    variant={tab === 'cte' ? 'primary' : 'secundary'}
                    borderRadius="0px"
                    borderColor="#6C48C2"
                    w={{ base: '100%', md: 'initial' }}
                    title={"CT-e's"}
                    rightIcon={<MdOutlineReceiptLong />}
                    onClick={() => setTabTravel('cte')}>
                    {"CT-e's"}
                  </Button>
                </Link>
              </Flex>
            </Flex>
          </TabList>

          <TabIndicator mt="-1.5px" height="2px" bg="#6C48C2" borderRadius="1px" />

          <TabPanels h="full">
            {/* Tab Preparação */}
            <TabPanel p="0" overflowX="auto">
              <ScreenLoader display="flex" isLoading={loadPreparation}>
                <KanbanColumn
                  title="Recusado pelo motorista"
                  status="REFUSED_BY_DRIVER"
                  icon="trip-refused.svg"
                  color="#E74C3C"
                  entity={refusedByDriver}
                  meta={metaRefusedByDriver}
                  tab={tab}
                  update={action}
                  setUpdate={setAction}
                  setLoad={setLoadPreparation}
                  showMoreItems={showMoreRefusedByDriver}
                  isLoadingItems={isLoadingItems}
                />

                <KanbanColumn
                  title="Em rascunho"
                  status="DRAFT"
                  icon="trip-draft.svg"
                  color="#6C48C2"
                  entity={draft}
                  meta={metaDraft}
                  tab={tab}
                  update={action}
                  setUpdate={setAction}
                  setLoad={setLoadPreparation}
                  showMoreItems={showMoreDraft}
                  isLoadingItems={isLoadingItems}
                />

                <KanbanColumn
                  title="Cadastrada"
                  status="CREATED"
                  icon="trip-created.svg"
                  color="#6C48C2"
                  entity={created}
                  meta={metaCreated}
                  tab={tab}
                  update={action}
                  setUpdate={setAction}
                  setLoad={setLoadPreparation}
                  setAction={metaCreated}
                  showMoreItems={showMoreCreated}
                  isLoadingItems={isLoadingItems}
                />

                <KanbanColumn
                  title="Enviado ao motorista"
                  status="SENT_TO_DRIVER"
                  icon="trip-sent.svg"
                  color="#6C48C2"
                  entity={sentToDriver}
                  meta={metaSentToDriver}
                  tab={tab}
                  update={action}
                  setUpdate={setAction}
                  setLoad={setLoadPreparation}
                  showMoreItems={showMoreSentToDriver}
                  isLoadingItems={isLoadingItems}
                />

                <KanbanColumn
                  title="Aceito pelo motorista"
                  status="ACCEPTED_BY_DRIVER"
                  icon="trip-accepted.svg"
                  color="#2ECC71"
                  entity={acceptedByDriver}
                  meta={metaAcceptedByDriver}
                  tab={tab}
                  update={action}
                  setUpdate={setAction}
                  setLoad={setLoadPreparation}
                  showMoreItems={showMoreAcceptedByDriver}
                  isLoadingItems={isLoadingItems}
                />

                <KanbanColumn
                  title="Cancelada"
                  status="CANCELLED"
                  icon="trip-cancelled.svg"
                  color="#E74C3C"
                  entity={cancelled}
                  meta={metaCancelled}
                  tab={tab}
                  update={action}
                  setUpdate={setAction}
                  setLoad={setLoadPreparation}
                  showMoreItems={showMoreCancelled}
                  isLoadingItems={isLoadingItems}
                />
              </ScreenLoader>
            </TabPanel>

            {/* Tab Execução */}
            <TabPanel p="0" overflowX="auto">
              <ScreenLoader display="flex" isLoading={loadExecution}>
                <KanbanColumn
                  title="Indo carregar"
                  status="GOING_TO_COLLECT"
                  icon="trip-going.svg"
                  color="#6C48C2"
                  entity={goingToCollect}
                  meta={metaGoingToCollect}
                  tab={tab}
                  update={action}
                  setUpdate={setAction}
                  setLoad={setLoadExecution}
                  showMoreItems={showMoreGoingToCollect}
                  isLoadingItems={isLoadingItems}
                />
                <KanbanColumn
                  title="Carregando"
                  status="READ_TO_COLLECT"
                  icon="trip-collect.svg"
                  color="#6C48C2"
                  entity={readToCollect}
                  meta={metaReadToCollect}
                  tab={tab}
                  update={action}
                  setUpdate={setAction}
                  setLoad={setLoadExecution}
                  showMoreItems={showMoreReadToCollect}
                  isLoadingItems={isLoadingItems}
                />
                <KanbanColumn
                  title="Carga carregada"
                  status="COLLECTING"
                  icon="trip-collecting.svg"
                  color="#6C48C2"
                  entity={collecting}
                  meta={metaCollecting}
                  tab={tab}
                  update={action}
                  setUpdate={setAction}
                  setLoad={setLoadExecution}
                  showMoreItems={showMoreCollecting}
                  isLoadingItems={isLoadingItems}
                />
                <KanbanColumn
                  title="Pronto para iniciar"
                  status="READ_TO_TRAVEL"
                  icon="trip-travel.svg"
                  color="#6C48C2"
                  entity={readToTravel}
                  meta={metaReadToTravel}
                  tab={tab}
                  update={action}
                  setUpdate={setAction}
                  setLoad={setLoadExecution}
                  showMoreItems={showMoreReadToTravel}
                  isLoadingItems={isLoadingItems}
                />
                <KanbanColumn
                  title="Em rota"
                  status="TRAVELLING"
                  icon="trip-travelling.svg"
                  color="#6C48C2"
                  entity={travelling}
                  meta={metaTravelling}
                  tab={tab}
                  update={action}
                  setUpdate={setAction}
                  setLoad={setLoadExecution}
                  showMoreItems={showMoreTravelling}
                  isLoadingItems={isLoadingItems}
                />
                <KanbanColumn
                  title="Parada"
                  status="HALTED"
                  icon="trip-halted.svg"
                  color="#6C48C2"
                  entity={halted}
                  meta={metaHalted}
                  tab={tab}
                  update={action}
                  setUpdate={setAction}
                  setLoad={setLoadExecution}
                  showMoreItems={showMoreHalted}
                  isLoadingItems={isLoadingItems}
                />{' '}
              </ScreenLoader>
            </TabPanel>

            {/* Tab Entrega */}
            <TabPanel p="0" overflowX="auto">
              <ScreenLoader display="flex" isLoading={loadDelivery}>
                <KanbanColumn
                  title="Chegada"
                  status="ARRIVED_AT_DESTINATION"
                  icon="trip-arrived.svg"
                  color="#6C48C2"
                  entity={halted}
                  meta={metaHalted}
                  tab={tab}
                  update={action}
                  setUpdate={setAction}
                  setLoad={setLoadDelivery}
                  showMoreItems={showMoreArrivedAtDestination}
                  isLoadingItems={isLoadingItems}
                />
                <KanbanColumn
                  title="Descarregando"
                  status="UNLOADING"
                  icon="trip-unloading.svg"
                  color="#6C48C2"
                  entity={halted}
                  meta={metaHalted}
                  tab={tab}
                  update={action}
                  setUpdate={setAction}
                  setLoad={setLoadDelivery}
                  showMoreItems={showMoreUnloading}
                  isLoadingItems={isLoadingItems}
                />
                <KanbanColumn
                  title="Entrega recusada"
                  status="DELIVER_REFUSED"
                  icon="trip-deliver.svg"
                  color="#6C48C2"
                  entity={halted}
                  meta={metaHalted}
                  tab={tab}
                  update={action}
                  setUpdate={setAction}
                  setLoad={setLoadDelivery}
                  showMoreItems={showMoreDeliverRefused}
                  isLoadingItems={isLoadingItems}
                />
                <KanbanColumn
                  title="Assintatura feita"
                  status="DELIVER_SIGNATURE_COLLECTED"
                  icon="trip-signature.svg"
                  color="#6C48C2"
                  entity={halted}
                  meta={metaHalted}
                  tab={tab}
                  update={action}
                  setUpdate={setAction}
                  setLoad={setLoadDelivery}
                  showMoreItems={showMoreDeliverSignatureCollected}
                  isLoadingItems={isLoadingItems}
                />
                <KanbanColumn
                  title="Assinatura recusada"
                  status="DELIVER_SIGNATURE_REFUSED"
                  icon="trip-signature-off.svg"
                  color="#6C48C2"
                  entity={halted}
                  meta={metaHalted}
                  tab={tab}
                  update={action}
                  setUpdate={setAction}
                  setLoad={setLoadDelivery}
                  showMoreItems={showMoreDeliverSignatureRefused}
                  isLoadingItems={isLoadingItems}
                />
                <KanbanColumn
                  title="Completa"
                  status="COMPLETED"
                  icon="trip-completed.svg"
                  color="#6C48C2"
                  entity={halted}
                  meta={metaHalted}
                  tab={tab}
                  update={action}
                  setUpdate={setAction}
                  setLoad={setLoadDelivery}
                  showMoreItems={showMoreCompleted}
                  isLoadingItems={isLoadingItems}
                />
              </ScreenLoader>
            </TabPanel>

            {/* Tab Faturamento */}
            <TabPanel p="0" overflowX="auto">
              <ScreenLoader display="flex" isLoading={loadInvoicing}>
                <KanbanColumn
                  title="Pagamento liberado"
                  status="PAYMENT_ALLOWED"
                  icon="trip-payment.svg"
                  color="#6C48C2"
                  entity={halted}
                  meta={metaHalted}
                  tab={tab}
                  update={action}
                  setUpdate={setAction}
                  setLoad={setLoadInvoicing}
                  showMoreItems={showMorePaymentAllowed}
                  isLoadingItems={isLoadingItems}
                />
                <KanbanColumn
                  title="Pagamento aprovado"
                  status="PAID"
                  icon="trip-paid.svg"
                  color="#6C48C2"
                  entity={halted}
                  meta={metaHalted}
                  tab={tab}
                  update={action}
                  setUpdate={setAction}
                  setLoad={setLoadInvoicing}
                  showMoreItems={showMorePaid}
                  isLoadingItems={isLoadingItems}
                />
                <KanbanColumn
                  title="Pagamento cancelado"
                  status="PAID_CANCELLED"
                  icon="trip-payment-off.svg"
                  color="#6C48C2"
                  entity={halted}
                  meta={metaHalted}
                  tab={tab}
                  update={action}
                  setUpdate={setAction}
                  setLoad={setLoadInvoicing}
                  showMoreItems={showMorePaidCancelled}
                  isLoadingItems={isLoadingItems}
                />
              </ScreenLoader>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>

      <Flex className="float-button-modal">
        <Button
          bgColor="green"
          color="white"
          borderRadius="50px"
          shadow="xl"
          h="50px"
          py="15px"
          px="30px"
          onClick={() => {
            onOpen();
          }}
          _hover={{ opacity: '0.5', transition: '0.9s' }}>
          <MdAdd />

          <Text textStyle="paragraph" ml="10px">
            Nova viagem
          </Text>
        </Button>

        <ModalFreight
          isOpen={isOpen}
          onClose={onClose}
          title="Cadastro de viagem"
          subTitle="Você pode adicionar os dados e salvar sua viagem como rascunho."
          action={action}
          setAction={setAction}
          setOrigin={setOrigin}
          setDestination={setDestination}
          tab={tab}
          entity={false}
        />
      </Flex>
    </>
  );
};

export default TravelKanban;
