import React, { useRef, useState } from 'react';

import { Link } from 'react-router-dom';

import { Box, Button, Flex, Image, Text, Toast, useDisclosure } from '@chakra-ui/react';

import add from '../../../assets/images/add.svg';
import Card from '../../../components/Card/Card';
import Page from '../../../components/Page';
import Paginate from '../../../components/Paginate/Paginate';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';

import OperationCard from './components/OperationCard';
import OperationTable from './components/OperationTable';

const OperationSpecification = () => {
  const request = useRef(0);
  const [filterOptions, setFilterOptions] = useState([]);

  const hasPermission = permissions.logisticFleetManagement;
  const [forceLoadTrigger, setForceLoadTrigger] = useState(0);

  const [isMobile, setIsMobile] = useState(false);

  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [action, setAction] = useState(1);
  const [openModalUploadFile, setOpenModalUploadFile] = useState(false);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const handleNewContract = () => {
    localStorage.removeItem('edit');
  };

  const actionModalUploadFile = () => {
    setOpenModalUploadFile(!openModalUploadFile);
  };

  const load = (filters, key, page) => {
    setIsLoading(true);

    requests
      .listOperation(filters, page)
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }

        request.current = key;
        setList(data.data);
        setMetadata(data.meta);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);
  };

  const triggerRefresh = () => {
    let aux = forceLoadTrigger;
    setForceLoadTrigger(aux + 1);
  };

  const sendUpload = async (docs, config) => {
    return new Promise((resolve, reject) => {
      requests
        .importOperationNcmsAction(docs, config)
        .then(() => {
          resolve();
          Toast.success('Arquivo importado com sucesso.');
        })
        .catch(() => {
          reject();
        });
    });
  };

  return (
    <Page
      screen="operation-specificaiton"
      title="Especificação operacional"
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      textFilterPlaceholder="Pesquise pelo conteúdo..."
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading}
      showFilters={false}
      showPeriodFilter={false}
      allowEmptySearchPeriod={true}
      forceLoadTrigger={forceLoadTrigger}
      FAB={[
        {
          title: 'Cadastrar especificação operacional',
          text: 'Cadastro de especificação operacional',
          action: (
            <Link onClick={handleNewContract} to="/vendemmia/operation-specification/new" key={2}>
              <Button variant="primary" pt="23px" pb="23px" borderRadius="50px">
                <Text mr="10px">Nova especificação operacional</Text>
                <Image src={add} />
              </Button>
            </Link>
          ),
          modality: 'custom',
        },
      ]}>
      <Card
        m="10px"
        header={
          <Flex gap="2px" direction="column">
            <Text textStyle="cardTitle" fontWeight="bold" color="primary">
              Especificação operacional
            </Text>
            <Text textStyle="subtitle" color="#BEBEBE">
              Existe um total de {metadata?.total_count} registros
            </Text>
          </Flex>
        }>
        <ScreenLoader isLoading={isLoading}>
          <Box p="15px">{!isMobile ? <OperationTable data={list} loadList={triggerRefresh} /> : <OperationCard data={list} />}</Box>
        </ScreenLoader>

        {metadata.total_pages > 1 && (
          <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
        )}
      </Card>
    </Page>
  );
};

export default OperationSpecification;
