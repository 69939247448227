import React from 'react';

import { MdQuestionMark } from 'react-icons/md';

import { Tooltip } from '@chakra-ui/react';

import './style.css';

const HelpTooltip = ({ tooltip = '' }) => {
  return <Tooltip label={tooltip} aria-label={tooltip}>
      <span className="help"><MdQuestionMark /></span>
  </Tooltip>;
};
export default HelpTooltip;
