import React, { useEffect, useState } from 'react';

import { endOfMonth, endOfWeek, startOfMonth, startOfWeek, subDays, subYears } from 'date-fns';
import { Calendar } from 'react-calendar';
import { VscCalendar } from 'react-icons/vsc';

import { Box, Button, Flex, Menu, MenuButton, MenuList, Text, useDisclosure } from '@chakra-ui/react';

import { dateFormatted, ptDateFormatted } from '../../../../utils/data';
import { addFilter, getFilter } from '../../../../utils/storageFilter';

import './calendar.css';

const now = new Date();
const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
const currentWeekStart = startOfWeek(today);
const currentWeekEnd = endOfWeek(today);
const currentMonthStart = startOfMonth(today);
const currentMonthEnd = endOfMonth(today);
const thirtyDaysAgo = subDays(today, 30);
const ninetyDaysAgo = subDays(today, 90);
const oneYearAgo = subYears(today, 1);
const formatted = [currentMonthStart, currentMonthEnd];

const CalendarFilter = ({ screen, onChange, defaultPeriod, isInsideExtraFilter }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [period, setPeriod] = useState(() => {
    if (defaultPeriod && typeof defaultPeriod === 'object') {
      defaultPeriod = [defaultPeriod.startsAt, defaultPeriod.endsAt];
    }

    return getFilter(screen, 'period') || defaultPeriod || formatted;
  });

  const handleChange = (date) => {
    setPeriod(date);
  };

  const clear = () => {
    setPeriod(formatted);
  };

  useEffect(() => {
    addFilter(screen, 'period', period);

    onChange?.({
      startsAt: dateFormatted(period[0]),
      endsAt: dateFormatted(period[1]),
    });
  }, [period]);

  return (
    <>
      <Menu isOpen={isOpen} onClose={onClose}>
        <MenuButton h="35px" bgColor="primary" borderRadius="10px" _hover={{ bg: 'linkColor' }} onClick={onOpen}>
          <Flex align="center" gap="10px" py="11px" px="10px" minH="43px">
            <Text textStyle="subtitle" color="#FFFFFFB3" fontSize={isInsideExtraFilter ? '15px' : '16px'}>
              {!period
                ? `${ptDateFormatted(today)} até ${ptDateFormatted(today)}`
                : `${ptDateFormatted(period[0])} até ${ptDateFormatted(period[1])}`}
            </Text>
            <VscCalendar size={19} color="#FFFFFFB3" />
          </Flex>
        </MenuButton>

        <MenuList h="auto" p="0px" shadow="lg" zIndex="999">
          <Flex direction="row">
            <Flex direction="column" p="15px" gap="12px">
              <Button
                variant="unstyled"
                height="none"
                _hover={{ textDecoration: 'underline' }}
                onClick={() => handleChange([today, today])}>
                Hoje
              </Button>
              <Button
                variant="unstyled"
                height="none"
                _hover={{ textDecoration: 'underline' }}
                onClick={() => handleChange([currentWeekStart, currentWeekEnd])}>
                Esta semana
              </Button>
              <Button
                variant="unstyled"
                height="none"
                _hover={{ textDecoration: 'underline' }}
                onClick={() => handleChange([currentMonthStart, currentMonthEnd])}>
                Este mês
              </Button>
              <Button
                variant="unstyled"
                height="none"
                _hover={{ textDecoration: 'underline' }}
                onClick={() => handleChange([thirtyDaysAgo, today])}>
                Últimos 30 dias
              </Button>
              <Button
                variant="unstyled"
                height="none"
                _hover={{ textDecoration: 'underline' }}
                onClick={() => handleChange([ninetyDaysAgo, today])}>
                Últimos 90 dias
              </Button>
              <Button
                variant="unstyled"
                height="none"
                _hover={{ textDecoration: 'underline' }}
                onClick={() => handleChange([oneYearAgo, today])}>
                Últimos 12 meses
              </Button>
              <Button
                variant="unstyled"
                height="none"
                _hover={{ textDecoration: 'underline' }}
                onClick={() => handleChange([new Date(today.getFullYear() - 1, 0, 1), new Date(today.getFullYear() - 1, 11, 31)])}>
                Ano passado
              </Button>
              <Button
                variant="unstyled"
                height="none"
                _hover={{ textDecoration: 'underline' }}
                onClick={() => handleChange([new Date(today.getFullYear(), 0, 1), today])}>
                Este ano (jan-hoje)
              </Button>
            </Flex>

            <Box w="0" border="1px" color="#E2E8F0" />

            <Flex direction="column" gap="30px" w="auto" m="25px">
              <Box w="auto" mx="auto">
                <Calendar
                  onChange={handleChange}
                  value={period}
                  calendarType="US"
                  minDetail="month"
                  next2Label={null}
                  prev2Label={null}
                  selectRange
                  className="react-calendar"
                />
              </Box>
            </Flex>
          </Flex>

          <Flex borderTop="2px" borderColor="#E2E8F0" justify="flex-end" py="20px" pr="30px" gap="20px">
            <Button variant="secundary" onClick={clear}>
              Limpar
            </Button>

            <Button variant="primary" onClick={onClose}>
              Fechar
            </Button>
          </Flex>
        </MenuList>
      </Menu>
    </>
  );
};

export default CalendarFilter;
