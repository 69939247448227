import React from 'react';

import { Flex, Grid, GridItem, Text } from '@chakra-ui/react';

import Paginate from '../../../../../components/Paginate/Paginate';

import './styles.css';
import TavelListCollapseCard from './TravelListCollapseCard';

const CardTravel = ({ list, load, setAction, metadata, setMetadata, action }) => {
  return (
    <Flex
      w='full'
      flexDirection={{ base: 'column', md: 'row' }}
    >
      <Grid 
        w="100%" 
        mt="8px"
        gap={'30px'}
      >
        <GridItem 
          display={'grid'}
          justifyContent={'space-between'}
          gridTemplateColumns={'16px 20px 50px 75px 70px  20px'}
          gap={'5px'}
          p={'15px'}
          alignItems={'center'}
          justifyItems={'center'}
          border={'1px solid #70707036'}
          borderRadius={'10px'}
        >
          <Text>{' '}</Text>
          <Text>{' '}</Text>
          <Text textTransform="none" textStyle="subtitle" textColor="#422C76" fontWeight="medium">
            Status
          </Text>
          <Text textTransform="none" textStyle="subtitle" textColor="#422C76" fontWeight="medium">
            Viagem
          </Text>
          <Text textTransform="none" textStyle="subtitle" textColor="#422C76" fontWeight="medium">
            Arquivos
          </Text>
          <Text>{' '}</Text>
        </GridItem>

        <GridItem
          display={'grid'}
          gap={'20px'}
        >
          {!load && (
            <>
              {list?.map((entity, key) => {
                return (
                  <TavelListCollapseCard key={key} entity={entity} />
                )
              })}
            </>
          )}
        </GridItem>
      </Grid>

      {load ? (
        <Flex justify="center" my="25px">
          <span>Carregando...</span>
        </Flex>
      ) : (
        <>
          {list?.length === 0 && (
            <Flex justify="center" my="25px">
              Nenhum dado encontrado
            </Flex>
          )}
        </>
      )}
      {metadata?.total_pages > 1 && (
        <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
      )}
    </Flex>
  );
};

export default CardTravel;
