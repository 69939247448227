import moment from 'moment';

import dangerousClassOptions from './dangerousClassOptions';
import incotermOptions from './incotermOptions';
import quoteTypeOptions from './quoteTypeOptions';
import sizeCtrOptions from './sizeCtrOptions';
import typeCtrOptions from './typeCtrOptions';

export const initialValues = (entity = {}) => {
  let ctrs = [];
  if (entity?.ctrs?.length > 0 && entity?.transportModality !== 'Aéreo') {
    entity?.ctrs.map((ctr, index) => {
      ctrs.push({
        qtt: ctr.qtt,
        size: sizeCtrOptions.find((e) => e.value === ctr.size),
        type: typeCtrOptions.find((e) => e.value === ctr.type),
      });
    });
  }

  return {
    responsibleLogisticUser: entity?.responsibleLogisticUserIdentifier
      ? { value: entity?.responsibleLogisticUserIdentifier, label: entity?.responsibleLogisticUserName }
      : '',
    availableDate: entity?.availableDate ? moment(entity?.availableDate).format('Y-MM-DD') : '',
    reference: entity?.reference || '',
    currency: entity?.currencyCode ? { value: entity?.currencyCode, label: entity?.currencyName } : '',
    valueOfTheMerchandise: entity?.valueOfTheMerchandise || '',
    quantity: entity?.quantity || '',
    height: entity?.height || '',
    width: entity?.width || '',
    length: entity?.length || '',
    cbmCubicMeters: entity?.cbmCubicMeters || '',
    weigth: entity?.weigth || '',
    ncms: entity?.ncms || [],
    description: entity?.description || '',
    quoteObservations: entity?.quoteObservations || '',
    invoiceValue: entity?.invoiceValue || '',
    copyPeople3Email: entity?.secondEmailCopyEmail || '',
    ctrs: ctrs,
    client: entity?.clientIdentifier ? { value: entity?.clientIdentifier, label: entity?.clientName } : '',
    process: entity?.processIdentifier ? { value: entity?.processIdentifier, label: entity?.processCode } : '',
    modality: entity?.transportModalityIdentifier
      ? { value: entity?.transportModalityIdentifier, label: entity?.transportModality + ' (' + entity?.transportModalitySlug + ')' }
      : '',
    originPort: entity?.originPortId
      ? { value: entity?.originPortId, label: entity?.originPortName + ' (' + entity?.originPortCountry + ')' }
      : '',
    destinationPort: entity?.destinyPortId
      ? { value: entity?.destinyPortId, label: entity?.destinyPortName + ' (' + entity?.destinyPortCountry + ')' }
      : '',
    incoterm: entity?.incoterm ? incotermOptions.find((e) => e.value === entity?.incoterm) : '',
    inlandZipCode: entity?.inlandZipCode || '',
    quoteType: entity?.quoteType ? quoteTypeOptions.find((e) => e.label === entity?.quoteType) : '',
    dangerousClass: entity?.dangerousClass ? dangerousClassOptions.find((e) => e.value === entity?.dangerousClass) : '',
    isPacklistReceived: entity?.isPacklistReceived === true ? { value: true, label: 'Sim' } : { value: false, label: 'Não' },
    copyPeople2: entity?.firstUserEmailCopyIdentifier
      ? { value: entity?.firstUserEmailCopyIdentifier, label: entity?.firstUserEmailCopyName }
      : '',
  };
};
