import React, { useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button, Card, Flex } from '@chakra-ui/react';

import Menubar from '../../../components/MenuBar/Menubar';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import useScreenPage from '../../../hooks/useScreenPage';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsComex } from '../../../utils/filters/filterPresets';

import KanbanSection from './components/KanbanSection';

const KanbanPage = () => {
  const hasPermission = permissions.comexKanban;

  const [isMobile, setIsMobile] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const request = useRef(0);

  const [list, setList] = useState([]);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const load = (filters, key) => {
    setIsLoading(true);
    requests
      .listProcess(filters, metadata.current_page, null, 50)
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }

        request.current = key;

        setList(data.data);
        setMetadata(data.meta);
        setIsLoading(false);
      })
    ;
  };

  const loadMore = (filters, key) => {
    setIsLoading(true);
    setIsLoadingMore(true);
    requests
      .listProcess(filters, metadata.current_page + 1)
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }

        request.current = key;

        setList((prevList) => [...prevList, ...data.data]);
        setMetadata(data.meta);
        setIsLoading(false);
        setIsLoadingMore(false);
      })
    ;
  };

  const [filterOptions, setFilterOptions] = useState([]);
  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsComex());
  };
  useEffect(() => {
    getFilterOptions();
  }, []);

  return (
    <Page
      screen="comex"
      title="Kanban"
      breadcrumbs={[{ link: '#', title: 'Importação' }]}

      textFilterPlaceholder="Processo, empresa ou ref"

      setIsMobile={setIsMobile}

      hasPermission={hasPermission}

      list={list}
      metadata={metadata}

      load={load}
      isContentLoading={isLoading}

      loadMore={loadMore}
      isContentLoadingMore={isLoadingMore}

      filterOptions={filterOptions}
      allowFiltersOverride={true}
      isRefreshLoading={isLoading || isLoadingMore}
    >
      <Card m="10px">
        <Flex direction="column" gap="0" p="10px" minH="calc(100vh - 260px)">
          <ScreenLoader isLoading={isLoading}>{list && <KanbanSection list={list ?? []} />}</ScreenLoader>
        </Flex>
        {list.length > 0 && list.length < metadata.total_count && (
          <Button onClick={loadMore} mt="20px" isLoading={isLoading} loadingText="Carregando">
            Carregar mais processos
          </Button>
        )}
      </Card>
    </Page>
  );
};

export default KanbanPage;
