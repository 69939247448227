import React, { useState } from 'react';

import { MdOutlineFileUpload } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Button, Flex, Icon, Image, Td, Text, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';

import cte from '../../../../../assets/svgs/cte.svg';
import ModalUpload from '../../../../../components/Modal/Upload/ModalUpload';
import ProgressBar from '../../../../../components/ProgressBar/ProgressBar';
import requests from '../../../../../services/requests';
import { modalityIcon, modalityTitle } from '../../../../../utils/translate';
import DocumentModalView from '../../../CurrentTrips/components/Documents/DocumentModalView';

const TableListCte = ({ tab, entity, setLoad, loadData }) => {
  let hasChildren = false;
  const [trajectorySelected, setTrajectorySelected] = useState('');
  const [trajectoryCodeSelected, setTrajectoryCodeSelected] = useState('');

  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const getColor = (modality) => {
    if (modality == 'FRETE_NAO_RASTREAVEL') {
      return '#E52359';
    }

    return '#6C48C2';
  };

  const onOpenModal = async (identifier, trajectoryCode) => {
    setTrajectorySelected(identifier);
    setTrajectoryCodeSelected(trajectoryCode);
    onOpen();
  };

  const sendUpload = async (docs, config) => {
    return new Promise((resolve, reject) => {
      requests
        .uploadCteFile(trajectorySelected, docs, config)
        .then(() => {
          resolve();
          loadData();
          onClose();
        })
        .catch((e) => {
          reject();
        })
        .finally(() => {
          loadData();
        });
    });
  };

  return (
    <>
      <ModalUpload
        title={`Viagem #${entity?.driverFreight?.code} ${
          trajectoryCodeSelected && trajectoryCodeSelected.length > 0 ? '- CT-e ' + trajectoryCodeSelected : ''
        }`}
        subtitle="Upload de arquivos"
        accept=".pdf"
        messageConfirm=" salvo com sucesso para envio!"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        requestAction={sendUpload}
        multiple={false}
        nameForm="driver_freight_file_upload"
        additional={[
          {
            label: 'Modalidade',
            group: 'modality',
            name: 'mod',
            required: true,
            options: [
              { value: 'CTE_XML', label: 'XML do CT-e' },
              { value: 'CTE_PDF', label: 'PDF do CT-e' },
              { value: 'NF_XML', label: 'XML da Nota fiscal' },
              { value: 'NF_PDF', label: 'PDF da Nota fiscal' },
            ],
          },
        ]}
      />
      <Tr>
        <Td>
          <Box py="45px" px="75px" bgColor="#F9F9F9">
            <>
              <Flex align="center" gap="2">
                <Image src={cte} />

                <Text ml="4" textStyle="tableTitle" fontWeight="medium" color="#422C76" fontSize="1.3rem">
                  CT-e: {entity?.cteNumber}
                </Text>

                <Tooltip label="Detalhe da viagem">
                  <Link to={`/logistic/trip/detail/${entity?.driverFreight?.identifier}`} state={entity?.driverFreight}>
                    <Button ml="4" h={'32px'} variant="secundary" fontSize="0.7rem">
                      Viagem: #{entity?.driverFreight?.code}
                    </Button>
                  </Link>
                </Tooltip>

                <Button
                  cursor="auto"
                  variant="secundary"
                  fontSize="0.7rem"
                  h={'32px'}
                  color={getColor(entity?.driverFreight?.modality)}
                  borderColor={getColor(entity?.driverFreight?.modality)}>
                  <Flex alignItems="center" justifyItems="center">
                    <Box h="1rem" mr="1">
                      <Icon
                        as={modalityIcon(entity?.driverFreight?.modality)}
                        color={getColor(entity?.driverFreight?.modality)}
                        w="1rem"
                        h="1rem"
                      />
                    </Box>
                    {modalityTitle(entity?.driverFreight?.modality)}
                  </Flex>
                </Button>

                <DocumentModalView
                  identifier={entity?.driverFreight?.identifier}
                  code={entity.driverFreight.code}
                  files={entity.files}
                  isDefaultTitle={false}
                />

                <Tooltip label="Upload de arquivos">
                  <Button
                    variant="secundary"
                    fontSize={'14px'}
                    h={'32px'}
                    leftIcon={<MdOutlineFileUpload color="secundary" size={18} cursor="pointer" />}
                    onClick={() => {
                      onOpenModal(entity.identifier, entity.cteNumber);
                    }}>
                    Upload de arquivos
                  </Button>
                </Tooltip>
              </Flex>
            </>

            {entity?.driverFreight?.modality !== 'APP_MOTORISTA' ? (
              <>
                {entity?.driverFreight?.trajectories?.length > 0 &&
                  entity?.driverFreight?.trajectories.map((trajectory, index) => (
                    <Box key={index}>
                      {trajectory?.type === 'COLETA' &&
                        trajectory?.timeline !== null &&
                        trajectory?.cteNumber == entity?.cteNumber && (
                          <>
                            {entity?.driverFreight?.trajectories?.length > 2 &&
                              trajectory?.cteNumber == entity?.cteNumber &&
                              (hasChildren = true)}
                            <ProgressBar tab={tab} timeline={trajectory.timeline} setLoad={setLoad} />
                            {entity?.driverFreight?.trajectories?.length / 2 !== index && hasChildren && (
                              <hr style={{ marginBottom: '35px' }} />
                            )}
                          </>
                        )}
                    </Box>
                  ))}
              </>
            ) : (
              <>
                {entity?.driverFreight?.timeline != null && entity?.driverFreight?.timeline != undefined && (
                  <ProgressBar mt="-15px" tab={tab} timeline={entity?.driverFreight?.timeline} setLoad={setLoad} />
                )}
              </>
            )}
          </Box>
          <hr />
        </Td>
      </Tr>
    </>
  );
};

export default TableListCte;
