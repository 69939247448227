import { useEffect, useState } from 'react';

import { DirectionsRenderer, GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';

import { Box, Flex, Text } from '@chakra-ui/react';

function MapRegisterFreight({ w, h, points = [] }) {
  const defaultZoom = 2;
  const defaultCenter = {
    lat: 23.73339640721276,
    lng: 2.9533781737686615,
  };

  const [map, setMap] = useState(null);
  const [currentZoom, setCurrentZoom] = useState(defaultZoom);
  const [showInfoWindow, setShowInfoWindow] = useState(null);
  const [arrowPathOffset, setArrowPathOffset] = useState(5);
  const [directions, setDirections] = useState(null);
  const [estimatedTime, setEstimatedTime] = useState('');
  const [estimatedDistance, setEstimatedDistance] = useState('');

  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  // });

  const [viewport, setViewport] = useState({
    lat: defaultCenter.lat,
    lng: defaultCenter.lng,
    zoom: defaultZoom,
  });

  const markerShowHandler = (index) => {
    setShowInfoWindow(index);
  };

  const loadDirections = async (waypoints = []) => {
    if (waypoints.length < 2) {
      return;
    }

    const origin = new google.maps.LatLng(waypoints[0].lat, waypoints[0].lng);
    const destination = new google.maps.LatLng(waypoints[waypoints.length - 1].lat, waypoints[waypoints.length - 1].lng);
    waypoints = waypoints.slice(1, waypoints.length - 1).map((point) => ({
      location: new google.maps.LatLng(point.lat, point.lng),
      stopover: true,
    }));

    const directionsService = new google.maps.DirectionsService();

    await directionsService.route(
      {
        origin: origin,
        destination: destination,
        waypoints: waypoints,
        travelMode: google.maps.TravelMode.DRIVING,
        drivingOptions: {
          departureTime: new Date(Date.now()),
          trafficModel: 'pessimistic',
        },
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);

          const route = result.routes[0];

          if (route) {
            let distance = 0;
            let duration = 0;
            for (let i in route.legs) {
              distance += route.legs[i].distance.value;
              duration += route.legs[i].duration.value;
            }

            distance = Math.ceil(distance / 1000);
            let durationHours = Math.ceil(duration / 60 / 60);
            let durationMinutes = Math.ceil(((duration / 60 / 60) % 1) * 60);

            setEstimatedTime(
              (durationHours > 0 ? durationHours + ' horas' : '') +
                (durationHours > 0 && durationMinutes > 0 ? ' e ' : '') +
                (durationMinutes > 0 ? durationMinutes + ' minutos' : '')
            );
            setEstimatedDistance(distance + ' km');
          }
        }
      }
    );
  };

  useEffect(() => {
    let aux = [];
    Object.entries(points)?.forEach(([key, item]) => {
      aux.push({
        lat: item.position.latitude,
        lng: item.position.longitude,
      });
    });
    if (aux.length > 1) loadDirections(aux);
  }, [points]);

  return (
    <Box w={w} h={h} style={{ '--transition': `all .1s linear ` }} position="relative">
      <Flex position="absolute" top="10px" left="10px" zIndex="10" p="10px 15px" pointerEvents="none" borderRadius="5px" gap="10px">
        {estimatedTime ? (
          <Flex
            className="down-anim"
            bg="primary"
            top="10px"
            left="10px"
            zIndex="10"
            p="10px 15px"
            pointerEvents="none"
            borderRadius="5px"
            direction="column"
            gap="10px">
            <Text color="white" fontSize="12px">
              Tempo estimado:
            </Text>

            <Text color="white" fontSize="20px">
              {estimatedTime}
            </Text>
          </Flex>
        ) : null}

        {estimatedDistance ? (
          <Flex
            className="down-anim"
            bg="primary"
            top="10px"
            left="10px"
            zIndex="10"
            p="10px 15px"
            pointerEvents="none"
            borderRadius="5px"
            direction="column"
            gap="10px">
            <Text color="white" fontSize="12px">
              Distância estimada:
            </Text>

            <Text color="white" fontSize="20px">
              {estimatedDistance}
            </Text>
          </Flex>
        ) : null}
      </Flex>

      <GoogleMap
        onLoad={(map) => {
          setMap(map);
        }}
        mapContainerStyle={{
          width: w,
          height: h,
        }}
        center={{
          lat: viewport.lat,
          lng: viewport.lng,
        }}
        zoom={viewport.zoom}
        key={1}
        options={{
          mapId: '6d2819c49ba264e7',
          minZoom: 1,
          maxZoom: 20,
          zoomControl: true,
          streetViewControl: false,
          keyboardShortcuts: false,
          scaleControl: true,
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT,
          },
          mapTypeControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT,
          },
          fullscreenControl: true,
          gestureHandling: true,
          mapTypeControl: true,
        }}
        onZoomChanged={() => {
          if (map) {
            setCurrentZoom(map.getZoom());
            setViewport({
              lat: map.center.lat(),
              lng: map.center.lng(),
              zoom: map.getZoom(),
            });
          }
        }}>
        {points &&
          Object.keys(points)?.map((key, index) => {
            let item = points[key];

            return (
              <Marker
                key={index}
                position={{
                  lat: item.position.latitude,
                  lng: item.position.longitude,
                }}
                icon={{
                  path: google.maps.SymbolPath.CIRCLE,
                  scale: 15,
                  strokeColor: '#FF1493',
                  fillColor: '#FF1493',
                  fillOpacity: 1,
                  strokeWeight: 1,
                }}
                label={{
                  text: `${index + 1}`,
                  color: '#fff',
                }}
                opacity={1}
                onClick={() => {
                  markerShowHandler(index);
                }}
                // onMouseOut={() => {
                //   markerShowHandler(null);
                // }}
              >
                {showInfoWindow === index ? (
                  <InfoWindow
                    onCloseClick={() => {
                      markerShowHandler(null);
                    }}
                    position={{
                      lat: item.position.latitude,
                      lng: item.position.longitude,
                    }}>
                    <>{item.address}</>
                  </InfoWindow>
                ) : null}
              </Marker>
            );
          })}
        {directions && (
          <DirectionsRenderer
            directions={directions}
            options={{
              suppressMarkers: true,
              strokeOpacity: 1,
              strokeWeight: 2,
              strokeColor: '#868c94',
            }}
          />
        )}
      </GoogleMap>
    </Box>
  );
}

export default MapRegisterFreight;
