import React, { useState } from 'react';

import { MdOutlineFileUpload } from 'react-icons/md';
import { TbFileDownload } from 'react-icons/tb';

import { Box, Button, Collapse, Flex, Grid, Text, useDisclosure } from '@chakra-ui/react';

import ModalUpload from '../../../../../components/Modal/Upload/ModalUpload';
import ProgressBar from '../../../../../components/ProgressBar/ProgressBar';
import requests from '../../../../../services/requests';

const CteDetails = ({ isOpenCollapse, trip, loadData }) => {
  const resolution = window.innerWidth;
  let hasChildren = false;
  const handleDownloadXML = (identifier) => {
    let link = document.createElement('a');

    link.href = (requests.getApiUrl() + `/open/freight/${trip.identifier}/file/${fileIdentifier}/download`).replace('//open', '/open');
    link.target = '_blank';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  const [trajectorySelected, setTrajectorySelected] = useState('');
  const [trajectoryCodeSelected, setTrajectoryCodeSelected] = useState('');

  const onOpenModal = async (identifier, trajectoryCode) => {
    setTrajectorySelected(identifier);
    setTrajectoryCodeSelected(trajectoryCode);
    onOpen();
  };

  const sendUpload = async (docs, config) => {
    return new Promise((resolve, reject) => {
      requests
        .uploadCteFile(trajectorySelected, docs, config)
        .then(() => {
          resolve();
          loadData();
          onClose();
        })
        .catch((e) => {
          reject();
        })
        .finally(() => {
          loadData();
        });
    });
  };

  return resolution > 768 ? (
    /* desktop */
    <Collapse in={isOpenCollapse} animateOpacity>
      <ModalUpload
        title={`Viagem #${trip.code} ${
          trajectoryCodeSelected && trajectoryCodeSelected.length > 0 ? '- CT-e ' + trajectoryCodeSelected : ''
        }`}
        subtitle="Upload de arquivos"
        accept=".pdf"
        messageConfirm=" salvo com sucesso para envio!"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        requestAction={sendUpload}
        multiple={false}
        nameForm="driver_freight_file_upload"
        additional={[
          {
            label: 'Modalidade',
            group: 'modality',
            name: 'mod',
            required: true,
            options: [
              { value: 'CTE_XML', label: 'XML do CT-e' },
              { value: 'CTE_PDF', label: 'PDF do CT-e' },
              { value: 'NF_XML', label: 'XML da Nota fiscal' },
              { value: 'NF_PDF', label: 'PDF da Nota fiscal' }
            ],
          },
        ]}
      />
      <Flex p="20px" bgColor="#F9F9F9" direction="column" w="full">
        {trip.modality !== 'APP_MOTORISTA' ? (
          <>
            {trip?.trajectories?.length > 0 &&
              trip.trajectories.map((trajectory, index) => (
                <Box key={index}>
                  {trajectory.type === 'COLETA' && (
                    <Flex alignItems={'center'} gap={'10px'}>
                      {trip?.trajectories[index + 1]?.files.filter((file) => file.modality === 'CTE_PDF')[0]?.identifier ||
                      trip?.trajectories[index + 1]?.files.filter((file) => file.modality === 'CTE_XML')[0]?.identifier ? (
                        <Button
                          variant="secundary"
                          fontSize={'14px'}
                          h={'32px'}
                          rightIcon={<TbFileDownload size={20} />}
                          onClick={(decision) => {
                            if (decision) {
                              handleDownloadXML(
                                trip.trajectories[index + 1].files.filter((file) => file.modality === 'CTE_PDF')[0]?.identifier ||
                                  trip.trajectories[index + 1].files.filter((file) => file.modality === 'CTE_XML')[0]?.identifier
                              );
                            }
                          }}>
                          CT-e {trajectory.cteNumber}
                        </Button>
                      ) : (
                        <Button variant="secundary" fontSize={'14px'} h={'32px'}>
                          CT-e {trajectory.cteNumber}
                        </Button>
                      )}

                      <Button
                        variant="secundary"
                        fontSize={'14px'}
                        h={'32px'}
                        leftIcon={<MdOutlineFileUpload color="secundary" size={18} cursor="pointer" />}
                        onClick={() => {
                          onOpenModal(trip.trajectories[index + 1].identifier, trajectory.cteNumber);
                        }}>
                        Upload de arquivos
                      </Button>

                      <Text textStyle="tableTitle" fontWeight="medium" color="#422C76">
                        {trajectory?.clientName}
                      </Text>
                    </Flex>
                  )}

                  {trajectory?.type === 'COLETA' && trajectory?.timeline !== null && (
                    <>
                      {trip?.trajectories?.length > 2 && (hasChildren = true)}
                      <ProgressBar timeline={trajectory.timeline} />
                      {trip?.trajectories?.length / 2 !== index && hasChildren && <hr style={{ marginBottom: '35px' }} />}
                    </>
                  )}
                </Box>
              ))}
          </>
        ) : (
          <>{trip?.timeline != null && trip?.timeline != undefined && <ProgressBar mt="-15px" timeline={trip?.timeline} />}</>
        )}
      </Flex>
    </Collapse>
  ) : (
    /* mobile */
    <Flex bgColor="#F9F9F9" w="full">
      {trip?.cteUri != null && trip?.client != null && (
        <>
          <Flex align="center" flexDirection={'column'} gap={'15px'} m={'15px 0'}>
            <Text textStyle="tableTitle" fontWeight="medium" color="#422C76" fontSize={'14px'}>
              {trip?.client?.name}
            </Text>

            <Grid templateColumns={'1fr 1fr'} gap={'5px'} width={'full'}>
              <Button
                variant="secundary"
                rightIcon={<TbFileDownload size={20} />}
                mr="13px"
                w="full"
                height={'30px'}
                onClick={(decision) => {
                  if (decision) {
                    handleDownloadXML(trip.identifier);
                  }
                }}>
                CT-e
              </Button>
              <Button variant="secundary" w="full" height={'30px'}>
                N°{trip.cteNumber}
              </Button>
            </Grid>
          </Flex>
        </>
      )}

      {trip?.timeline != null && trip?.timeline != undefined && <ProgressBar timeline={trip?.timeline} />}
    </Flex>
  );
};

export default CteDetails;
