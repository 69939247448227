import React from 'react';

import { Flex, Td, Text, Tooltip, Tr } from '@chakra-ui/react';

import { limitChars } from '../../../../utils/strings';

const StockList = ({ entity, count }) => {
  const handleColor = (status, type) => {
    if (type === 1) {
      switch (status) {
        case 'A':
          return '#2ECC71';
        case 'B':
          return '#F1C40F';
        case 'C':
          return '#E74C3C';
        default:
          return '';
      }
    } else {
      if (status === '1') {
        return '#2ECC71';
      } else {
        return '#E74C3C';
      }
    }
  };

  const handleColorABC = (status) => {
    switch (status) {
      case 'A':
        return '#2ECC71';
      case 'B':
        return '#F1C40F';
      default:
        return '#E74C3C';
    }
  };

  const handleText = (text) => {
    switch (text) {
      case '1':
        return 'Normal';
      case '2':
        return 'Danificado';
      default:
        return '';
    }
  };
  return (
    <>
      <Tr border="none" style={{ '--delay': `${count / 10}s` }}>
        <Td>{entity.codProduto ?? '-'}</Td>
        <Td>
          {entity?.descProduto?.length > 50 ? (
            <Tooltip label={entity.descProduto}>{limitChars(entity.descProduto, 50)}</Tooltip>
          ) : (
            entity.descProduto ?? '-'
          )}
        </Td>
        <Td>{entity.setor ?? '-'}</Td>
        <Td>
          <Text color={handleColor(entity?.situacao, 2)}>{handleText(entity?.situacao) ?? '-'}</Text>
        </Td>
        <Td>{entity?.situacao === '1' ? entity?.qtdeEstoqueFormatted : entity?.qtdeAvariadaFormatted}</Td>
        <Td>{entity?.totalValueFormatted ?? '-'}</Td>
        <Td>{entity?.idLoteFabricacao ?? '-'}</Td>
        <Td>{entity?.stateCodeFormatted ?? '-'}</Td>
        <Td>{entity?.dtVencimentoFormatted ?? '-'}</Td>
        <Td>
          <Flex
            align="center"
            borderRadius="15px"
            justifyContent="center"
            bgColor={handleColorABC(entity?.inAbc)}
            color="white"
            w="4rem"
            h="2rem">
            {entity?.inAbc ?? '-'}
          </Flex>
        </Td>
        <Td>
          {entity?.clientName?.length > 30 ? (
            <Tooltip label={entity.clientName}>{limitChars(entity.clientName, 30)}</Tooltip>
          ) : (
            entity.clientName ?? '-'
          )}
        </Td>
      </Tr>
    </>
  );
};

export default StockList;
