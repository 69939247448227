import React, { useState } from 'react';

import { toast } from 'react-toastify';

import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box, Flex,
    Grid,
    GridItem,
    Image,
    Text,
    Tooltip,
    useDisclosure,
} from '@chakra-ui/react';

import edit from '../../../../assets/svgs/edit.svg';
import Paginate from '../../../../components/Paginate/Paginate';
import requests from '../../../../services/requests';
import roleOptions from '../Helpers/roleOptions';
import statusOptions from '../Helpers/statusOptions';

import ModalChangeRole from './ModalChangeRole';
import ModalChangeStatus from './ModalChangeStatus';
import ModalManagementUser from './ModalManagementUser';

const UserManagementListCard = ({
  list = [],
  filter,
  loadList,
  action,
  setAction,
  metadata,
  setMetadata,
  setFields,
  fields,
}) => {
  const [identifier, setIdentifier] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClose = () => {
    onClose();
    loadList();
  };

  const handleOpen = (identifier) => {
    setIdentifier(identifier);
    onOpen();
  };

  const handleClickChangeRole = (identifier) => {
    requests
      .editUserRole(identifier, { role: fields?.role?.value })
      .then((response) => {
        toast.success(`Perfil do usuário ${response.name} alterado para ${roleOptions.find(e => e.value === response?.role)?.label}`);
        loadList();
      }).catch(() => {
        toast.error('Problemas ao alterar perfil do usuário, tente novamente ou entre em contato com o Administrador');
      });
    ;
  };

  const handleClickChangeStatus = (identifier) => {
    requests
      .deleteUser(identifier, { statusUser: fields.status })
      .then((response) => {
        toast.success(`Status do usuário ${response.name} alterado para ${statusOptions.find(e => e.value === response?.status)?.label}`);
        loadList();
      }).catch(() => {
        toast.error('Problemas ao alterar status do usuário, tente novamente ou entre em contato com o Administrador');
      });
    ;
  };

  return (
    <>
      <ModalManagementUser
        loadList={loadList}
        filter={filter}
        fields={fields}
        setFields={setFields}
        isEditMode={true}
        handleClose={handleClose}
        handleOpen={handleOpen}
        isOpen={isOpen}
        identifier={identifier}
      />
      <Grid 
        templateColumns={'50px 1fr 20px'}
        gap={'15px'}
        p={'10px'}
      >
        <GridItem><strong>Ações</strong></GridItem>
        <GridItem><strong>Nome</strong></GridItem>
        <GridItem>
          {' '}
        </GridItem>
      </Grid>
      <Flex>
        <Accordion
          w='full'
        >
        {list?.length > 0 ? (
            <>
              {list.map((entity, index) => {
                return (
                <>
                  <AccordionItem
                    /* p={'0 10px 10px'} */
                    mb={'10px'}
                    borderTopWidth={0}
                    borderBottomWidth={1}
                    _odd={{ bg: "#eee" }}
                  >
                    <AccordionButton 
                      display={'flex'}
                      flexDirection={'column'}
                      p={'0'}
                      _hover={{bg:'transparent'}}
                    >
                      <Grid 
                        templateColumns={'50px 1fr 20px'}
                        w='full'
                        alignItems={'center'}
                        p={'10px'}
                        textAlign={'left'}
                        gap={'15px'}
                      >
                        <GridItem>
                          <Text justify="center" variant="primary">
                            <Tooltip label="Editar perfil">
                              <Box onClick={() => {
                                handleOpen(entity.identifier);
                              }}
                              >
                                <Image src={edit} boxSize={6} />
                              </Box>
                            </Tooltip>
                          </Text>
                        </GridItem>
                        <GridItem color={'#707070'}>{entity?.name ?? '-'}</GridItem>
                        <GridItem 
                          display={'flex'}
                          justifyContent={'end'}
                        >
                          <AccordionIcon />
                        </GridItem>
                      </Grid>
                      <Grid
                        templateColumns={'1fr 1fr'}
                        gap='10px'
                        p={'0 10px 15px'}
                      >
                        <Text>
                            <ModalChangeRole
                              roleOptions={roleOptions}
                              entity={entity}
                              setFields={setFields}
                              fields={fields}
                              callback={(decision) => {
                                if (decision) {
                                  handleClickChangeRole(entity.identifier);
                                }
                              }}
                            />
                          </Text>
                          <Text>
                            <ModalChangeStatus
                              statusOptions={statusOptions}
                              entity={entity}
                              setFields={setFields}
                              fields={fields}
                              callback={(decision) => {
                                if (decision) {
                                  handleClickChangeStatus(entity.identifier);
                                }
                              }}
                            />
                          </Text>
                      </Grid>
                    </AccordionButton>
                    <AccordionPanel bg={'white'} pb={4}>
                      <Grid key={index} border="none" cursor="pointer" style={{ '--delay': `${index / 10}s` }}>
                        <Text></Text>
                        <Text fontSize={'14px'}><strong>E-mail: </strong>{entity?.email ?? '-'}</Text>
                        <Text fontSize={'14px'}><strong>Permissões: </strong>{entity?.permissionsQtt ?? '-'}</Text>
                        <Text fontSize={'14px'}><strong>Empresas vinculadas: </strong>{entity?.clientsQtt ?? '-'}</Text>
                      </Grid>
                    </AccordionPanel> 
                  </AccordionItem>
                  </>
                )
              })}
            </>
          ) : (
            <Grid>
              <Text colSpan={7}>
                <Flex justify="center" my="25px">
                  Nenhum dado encontrado para os parâmetros filtrados.
                </Flex>
              </Text>
            </Grid>
          )}
        </Accordion>
      </Flex>

      {metadata.total_pages > 1 && (
        <Paginate
          metadata={metadata}
          setMetadata={setMetadata}
          action={action}
          setAction={setAction}
          showDetails={true}
        />
      )}
    </>
  )
};

export default UserManagementListCard;
