import React from 'react';

import { Flex } from '@chakra-ui/react';

import OrderProgressBar from '../../../pages/metrics/MetricsWarehouse/components/OrderProgressBar';

const OrderProgressByStateChart = ({ data }) => {
  function moneyFormat(value) {
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  function getColor(key) {
    let color = 'red';
    if (key === 0) {
      color = 'blue';
    } else if (key === 1) {
      color = 'yellow';
    } else if (key === 2) {
      color = 'lightRed';
    } else if (key === 3) {
      color = 'green';
    } else if (key === 4) {
      color = 'lightGreen';
    } else if (key === 5) {
      color = 'red';
    }

    return color;
  }

  return (
    <Flex direction="column" px="10px" h="full" justify="center">
      <Flex direction="column" w="100%" gap="5px">
        {data?.byStateCodeValue?.length > 0 &&
          data.byStateCodeValue.map((item, key) => {
            let label = item.stateCode;
            if (label?.includes('Outros')) {
              label = 'Outros';
            }

            return (
              <OrderProgressBar
                tooltip={label === 'Outros' ? item.stateCode.replace('Outros: ', '') : ''}
                key={key}
                label={label}
                originalValue={item.totalValue}
                value={item.totalValue ? moneyFormat(item.totalValue) : 0}
                max={data.totalBilling ? data.totalBilling : 0}
                maxIsMoney={true}
                color={getColor(key)}
              />
            );
          })}
      </Flex>
    </Flex>
  );
};

export default OrderProgressByStateChart;
