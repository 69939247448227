import React from 'react';

import { MdSettings } from 'react-icons/md';

import { Box, Button, Flex, Text } from '@chakra-ui/react';

const FloatActionButton = ({ options = [], isOpen, setIsOpen }) => {
  return (
    <Box className="float-action-button">
      <Button onClick={() => setIsOpen(!isOpen)}>
        <MdSettings size={20} color="white" />
        <Text color="white">Opções</Text>
      </Button>

      <Flex className="options" display={isOpen ? 'flex' : 'none'}>
        <ul style={{ display: 'flex', flexDirection: 'column-reverse', gap: '10px', alignItems: 'flex-end' }}>
          {options.map((option, key) => (
            <li
              key={key}
              style={{ '--delay': `${key / 7}s`, display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'flex-end' }}>
              {option}
            </li>
          ))}
        </ul>
      </Flex>
    </Box>
  );
};

export default FloatActionButton;
