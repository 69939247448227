import React, { useEffect, useState } from 'react';

import { ErrorMessage } from 'formik';
import { MdAdd, MdDelete } from 'react-icons/md';
import Select from 'react-select';

import { Button, Checkbox, Divider, Flex, FormControl, FormLabel, Grid, TabPanel } from '@chakra-ui/react';

import InputCurrency from '../../../../components/Form/Input/InputCurrency';
import InputDefault from '../../../../components/Form/Input/InputDefault';
import { InputPercentage } from '../../../../components/Form/Input/InputPercentage';
import { LabelDefault } from '../../../../components/Form/Label/LabelDefault';
import { handleAddClick, handleRemoveChange } from '../Helpers/inputMultiple';

const FinancialDataForm = ({ values, setFieldValue, handleChange, isPreviwer }) => {
  const [inputIcmsRates, setInputIcmsRates] = useState([]);

  const financialDetailsData = [
    {
      label: 'Não se aplica',
      value: false,
    },
    {
      label: 'Sim',
      value: true,
    },
  ];

  const commercialDiscountData = [
    {
      label: 'Por dentro - Campo custos financeiros',
      value: 'POR_DENTRO',
    },
    {
      label: 'Por fora - Após faturamento (em duplicata)',
      value: 'POR_FORA',
    },
  ];

  const serviceInvoiceModalityData = [
    {
      label: 'Inclusos (ISS + PIS + COFINS)',
      value: 'IMPOSTOS_INCLUSOS',
    },
    {
      label: 'Cobrar Impostos (ISS + PIS + COFINS)',
      value: 'COBRAR_IMPOSTOS',
    },
  ];

  const exporterPaymentData = [
    {
      label: 'Antecipado',
      value: 'ANTECIPADO',
    },
    {
      label: 'A Prazo',
      value: 'A_PRAZO',
    },
    {
      label: 'Vendemmia',
      value: 'VENDEMMIA',
    },
    {
      label: 'Cliente',
      value: 'CLIENTE',
    },
    {
      label: 'Fininp',
      value: 'FININP',
    },
    {
      label: 'Recursos Próprios',
      value: 'RECURSOS_PROPRIOS',
    },
    {
      label: 'Adiantamento',
      value: 'ADIANTAMENTO',
    },
  ];

  const financialChargesModalityData = [
    {
      label: 'CDI (AM) +',
      value: 'CDI',
    },
    {
      label: 'IOF - 0,38%',
      value: 'IOF',
    },
    {
      label: 'Pro-rata',
      value: 'PRO_RATA',
    },
  ];

  const paymentConditionInvoicingData = [
    {
      label: 'Pós / Faturamento',
      value: 'POS_FATURAMENTO',
    },
    {
      label: 'Contra adiantamento / Faturamento',
      value: 'CONTRA_ADIANTAMENTO_FATURAMENTO',
    },
  ];

  const selectCustomStyles = () => ({
    control: (provided) => ({
      ...provided,
      backgroundColor: '#F2F2F2',
    }),
  });

  useEffect(() => {
    if (values && values.financial && values.financial.icmsRates && values.financial.icmsRates.length > 0) {
      setInputIcmsRates(values.financial.icmsRates);
    }
  }, [values]);
  return (
    <TabPanel p="30px" display="flex" flexDirection="column" gap="10px">
      <Flex direction="column">
        <Grid templateColumns="repeat(2, 1fr)" gap={10}>
          <FormControl>
            <LabelDefault text="Adiantamento / Arras / Warranty" />
            <Select
              placeholder="Adiantamento / Arras / Warranty"
              isDisabled={isPreviwer}
              styles={selectCustomStyles()}
              options={financialDetailsData}
              name="financial.upFrontPaymentWarranty"
              className="outline-none transform-none text-black"
              value={financialDetailsData.find(({ value }) => value === values.financial.upFrontPaymentWarranty)}
              onChange={(value) => setFieldValue('financial.upFrontPaymentWarranty', value.value)}
            />
          </FormControl>

          <FormControl colSpan={4}>
            <LabelDefault
              text={
                <>
                  Comentários adicionais <small>(opcional)</small>
                </>
              }
            />
            <InputDefault
              as="textarea"
              minH="120px"
              pt="10px"
              // placeholder="Observações (opcional)"
              isDisabled={isPreviwer}
              styles={selectCustomStyles()}
              name="financial.upFrontPaymentWarrantyAdditionalInformation"
              id="upFrontPaymentWarrantyAdditionalInformation"
              onChange={handleChange}
              value={values.financial.upFrontPaymentWarrantyAdditionalInformation}
            />
          </FormControl>
        </Grid>
      </Flex>

      <Divider borderColor="#70707033" />

      <Flex direction="column" gap="20px">
        <FormLabel>Taxa administrativa</FormLabel>

        <Grid templateColumns="repeat(2, 1fr)" gap={10}>
          <FormControl>
            <LabelDefault text="Aplicar taxa administrativa" />
            <Select
              placeholder="Aplicar taxa administrativa"
              isDisabled={isPreviwer}
              styles={selectCustomStyles()}
              options={financialDetailsData}
              name="financial.hasAdministrativeFee"
              className="outline-none transform-none text-black"
              value={financialDetailsData.find(({ value }) => value === values.financial.hasAdministrativeFee)}
              onChange={(option) => {
                setFieldValue('financial.hasAdministrativeFee', option.value);
              }}
            />
          </FormControl>
        </Grid>

        {values.financial.hasAdministrativeFee === true && (
          <Grid templateColumns="repeat(2, 1fr)" gap={10}>
            <Flex gap={5} direction="column">
              <FormControl>
                <LabelDefault text="Porcentagem da taxa administrativa" />
                <InputPercentage
                  placeholder="Insira a porcentagem da taxa administrativa"
                  isDisabled={isPreviwer}
                  name="financial.administrativeFee"
                  id="administrativeFee"
                  value={values.financial.administrativeFee}
                  setFieldValue={setFieldValue}
                />

                <ErrorMessage component="p" className="error-message-error" name="financial.administrativeFee" />
              </FormControl>

              <FormControl>
                <LabelDefault text="Valor mínimo da taxa administrativa" />
                <InputCurrency
                  placeholder="Insira o valor mínimo da taxa administrativa"
                  isDisabled={isPreviwer}
                  name="financial.minAdministrativeFee"
                  id="minAdministrativeFee"
                  styles={selectCustomStyles()}
                  value={values.financial.minAdministrativeFee}
                  setFieldValue={setFieldValue}
                />

                <ErrorMessage component="p" className="error-message-error" name="financial.minAdministrativeFee" />
              </FormControl>
            </Flex>

            <FormControl colSpan={4}>
              <LabelDefault
                text={
                  <>
                    Comentários adicionais <small>(opcional)</small>
                  </>
                }
              />
              <InputDefault
                as="textarea"
                minH="120px"
                pt="10px"
                // placeholder="Observações (opcional)"
                isDisabled={isPreviwer}
                name="financial.administrativeFeeAdditionalInformation"
                id="administrativeFeeAdditionalInformation"
                styles={selectCustomStyles()}
                onChange={handleChange}
                value={values.financial.administrativeFeeAdditionalInformation}
              />
            </FormControl>
          </Grid>
        )}
      </Flex>

      <Divider borderColor="#70707033" />

      <Flex direction="column" gap="20px">
        <FormLabel>Desconto comercial</FormLabel>

        <Grid templateColumns="repeat(2, 1fr)" gap={10}>
          <FormControl>
            <LabelDefault text="Aplicar desconto comercial?" />
            <Select
              placeholder="Aplicar desconto comercial?"
              isDisabled={isPreviwer}
              styles={selectCustomStyles()}
              options={financialDetailsData}
              name="financial.commercialDiscount"
              className="outline-none transform-none text-black"
              value={financialDetailsData.find(({ value }) => value === values.financial.commercialDiscount)}
              onChange={(value) => setFieldValue('financial.commercialDiscount', value.value)}
            />
          </FormControl>
        </Grid>

        {values.financial.commercialDiscount === true && (
          <Grid templateColumns="repeat(2, 1fr)" gap={10}>
            <Flex direction="column" gap="20px">
              {inputIcmsRates.map((_, index) => (
                <React.Fragment key={index}>
                  <Flex gap="10px" align="flex-end">
                    <FormControl>
                      <LabelDefault text="Alíquota ICMS" />
                      <InputPercentage
                        name={`financial.icmsRates[${index}].value`}
                        id={`icmsRates[${index}].value`}
                        value={values.financial.icmsRates[index]?.value || ''}
                        setFieldValue={setFieldValue}
                        placeholder="Insira a alíquota ICMS"
                        isDisabled={isPreviwer}
                      />
                    </FormControl>

                    <FormControl>
                      <LabelDefault text="Valor do desconto" />
                      <InputPercentage
                        placeholder="Insira a porcentagem de desconto"
                        name={`financial.icmsRates[${index}].discountValue`}
                        id={`icmsRates[${index}].discountValue`}
                        isDisabled={isPreviwer}
                        value={values.financial.icmsRates[index]?.discountValue || ''}
                        setFieldValue={setFieldValue}
                      />
                    </FormControl>

                    {inputIcmsRates.length > 1 && (
                      <Button
                        isDisabled={isPreviwer}
                        onClick={() => {
                          handleRemoveChange(
                            setFieldValue,
                            index,
                            values.financial.icmsRates,
                            'icmsRates',
                            inputIcmsRates,
                            setInputIcmsRates,
                            'financial'
                          );
                        }}>
                        <MdDelete color="#E52359" size={30} />
                      </Button>
                    )}
                  </Flex>

                  <ErrorMessage component="p" className="error-message-error" name="financial.icmsRates" />
                </React.Fragment>
              ))}

              <Button
                w="auto"
                h="40px"
                fontSize="16px"
                borderRadius="5px"
                textColor="#2ECC71"
                bgColor="#2ECC7152"
                isDisabled={isPreviwer}
                border="1px dashed #2ECC71"
                _hover={{ bgColor: 'transparent', shadow: 'lg' }}
                leftIcon={<MdAdd color="#2ECC71" size={15} />}
                onClick={() => handleAddClick(inputIcmsRates, setInputIcmsRates, values)}>
                Adicionar condição de desconto
              </Button>
            </Flex>

            <Flex direction="column" gap="20px">
              <FormControl display="flex" flexDirection="column" gap="10px" bgColor="#F2F2F2" borderRadius="10px" p="20px">
                <LabelDefault text="Informações adicionais do desconto" />
                {commercialDiscountData.map((item, index) => (
                  <React.Fragment key={index}>
                    <Checkbox
                      iconColor="primary"
                      w="fit-content"
                      name="financial.extraCommercialDiscount"
                      isDisabled={isPreviwer}
                      onChange={handleChange}
                      value={item.label}
                      isChecked={values.financial.extraCommercialDiscount.includes(item.label)}>
                      {item.label}
                    </Checkbox>
                  </React.Fragment>
                ))}

                <ErrorMessage component="p" className="error-message-error" name="financial.extraCommercialDiscount" />
              </FormControl>

              <FormControl>
                <LabelDefault
                  text={
                    <>
                      Comentários adicionais <small>(opcional)</small>
                    </>
                  }
                />
                <InputDefault
                  as="textarea"
                  minH="120px"
                  pt="10px"
                  placeholder="Comentários Adicionais (opcional)"
                  isDisabled={isPreviwer}
                  name="financial.commercialDiscountAdditionalInformation"
                  id="commercialDiscountAdditionalInformation"
                  onChange={handleChange}
                  value={values.financial.commercialDiscountAdditionalInformation}
                />
              </FormControl>
            </Flex>
          </Grid>
        )}
      </Flex>

      <Divider borderColor="#70707033" />

      <Flex direction="column" gap="20px">
        <FormLabel>Nota de Serviço</FormLabel>

        <Grid templateColumns="repeat(2, 1fr)" gap={10}>
          <FormControl>
            <LabelDefault text="Nota de Serviço" />
            <Select
              placeholder="Nota de Serviço"
              isDisabled={isPreviwer}
              name="financial.serviceInvoice"
              styles={selectCustomStyles()}
              options={financialDetailsData}
              value={financialDetailsData.find(({ value }) => value === values.financial.serviceInvoice)}
              onChange={(value) => {
                setFieldValue('financial.serviceInvoice', value.value);
                // limpando valores dos campos associados
                if (!value.value) {
                  setFieldValue('financial.administrateTax', '');
                  setFieldValue('financial.serviceInvoiceMinValue', '');
                  setFieldValue('financial.serviceInvoiceModality', '');
                  setFieldValue('financial.serviceInvoiceAdditionalInformation', '');
                  setFieldValue('financial.paymentTermsServiceInvoice', '');
                  setFieldValue('financial.paymentTermsServiceInvoiceDays', '');
                }
              }}
            />
          </FormControl>
        </Grid>

        {values.financial.serviceInvoice === true && (
          <Grid templateColumns="repeat(2, 1fr)" gap={10}>
            <Flex gap={5} direction="column">
              <FormControl>
                <LabelDefault text="Nota de Serviço - Base de cálculo / Taxa administrativa"/>
                <InputPercentage
                  name="financial.administrateTax"
                  id="administrateTax"
                  value={values.financial.administrateTax}
                  setFieldValue={setFieldValue}
                  placeholder="Insira a porcentagem da taxa administrativa"
                  isDisabled={isPreviwer}
                />
                <ErrorMessage component="p" className="error-message-error" name="financial.administrateTax" />
              </FormControl>

              <FormControl>
                <LabelDefault text="Nota de Serviço - Valor mínimo"/>
                <InputCurrency
                  name="financial.serviceInvoiceMinValue"
                  id="serviceInvoiceMinValue"
                  value={values.financial.serviceInvoiceMinValue}
                  setFieldValue={setFieldValue}
                  placeholder="Insira o valor mínimo"
                  isDisabled={isPreviwer}
                />
                <ErrorMessage component="p" className="error-message-error" name="financial.serviceInvoiceMinValue" />
              </FormControl>

              <FormControl>
                <LabelDefault text="Nota de Serviço - Modalidade"/>
                <Select
                  options={serviceInvoiceModalityData}
                  name="financial.serviceInvoiceModality"
                  styles={selectCustomStyles()}
                  value={serviceInvoiceModalityData.find((selected) => selected.label === values.financial.serviceInvoiceModality)}
                  placeholder="Selecione..."
                  isDisabled={isPreviwer}
                  onChange={(option) => setFieldValue('financial.serviceInvoiceModality', option.label)}
                />
                <ErrorMessage component="p" className="error-message-error" name="financial.serviceInvoiceModality" />
              </FormControl>
            </Flex>

            <FormControl colSpan={4}>
              <LabelDefault
                text={
                  <>
                    Comentários adicionais <small>(opcional)</small>
                  </>
                }
              />
              <InputDefault
                as="textarea"
                minH="120px"
                pt="10px"
                // placeholder="Observações (opcional)"
                isDisabled={isPreviwer}
                styles={selectCustomStyles()}
                name="financial.serviceInvoiceAdditionalInformation"
                id="serviceInvoiceAdditionalInformation"
                onChange={handleChange}
                value={values.financial.serviceInvoiceAdditionalInformation}
              />
            </FormControl>
          </Grid>
        )}
      </Flex>

      <Divider borderColor="#70707033" />

      <Flex direction="column">
        <FormLabel>Pagamento ao exportador</FormLabel>

        <Grid templateColumns="repeat(2, 1fr)" gap={10}>
          <FormControl>
            <LabelDefault text="Pagamento ao Exportador" />
            <Select
              styles={selectCustomStyles()}
              options={exporterPaymentData}
              name="financial.exporterPayment"
              isMulti
              value={values.financial.exporterPayment}
              placeholder="Selecione a forma de pagamento ao exportador"
              isDisabled={isPreviwer}
              onChange={(option) => setFieldValue('financial.exporterPayment', option)}
            />

            <ErrorMessage component="p" className="error-message-error" name="financial.exporterPayment" />
          </FormControl>

          <FormControl colSpan={4}>
            <LabelDefault text="Parecer de Crédito" />
            <InputDefault
              as="textarea"
              minH="120px"
              pt="10px"
              // placeholder="Parecer de Crédito"
              isDisabled={isPreviwer}
              name="financial.exporterPaymentAdditionalInformation"
              id="exporterPaymentAdditionalInformation"
              styles={selectCustomStyles()}
              onChange={handleChange}
              value={values.financial.exporterPaymentAdditionalInformation}
            />

            <ErrorMessage component="p" className="error-message-error" name="financial.exporterPaymentAdditionalInformation" />
          </FormControl>
        </Grid>
      </Flex>

      <Divider borderColor="#70707033" />

      <Flex direction="column" gap="20px">
        <FormLabel>Encargos Financeiros</FormLabel>

        <Grid templateColumns="repeat(2, 1fr)" gap={10}>
          <FormControl>
            <LabelDefault text="Encargos Financeiros" />
            <Select
              placeholder="Encargos Financeiros"
              isDisabled={isPreviwer}
              styles={selectCustomStyles()}
              options={financialDetailsData}
              name="financial.financialCharges"
              value={financialDetailsData.find(({ value }) => value === values.financial.financialCharges)}
              onChange={(value) => {
                setFieldValue('financial.financialCharges', value.value);
                if (!value.value) {
                  setFieldValue('financial.financialChargesModality', '');
                  setFieldValue('financial.financialChargesAddition', '');
                }
              }}
            />
          </FormControl>
        </Grid>

        {values.financial.financialCharges === true && (
          <Grid templateColumns="repeat(2, 1fr)" gap={10}>
            <FormControl>
              <LabelDefault text="Encargos Financeiros - Cálculo" />
              <Select
                placeholder="Selecione..."
                isDisabled={isPreviwer}
                name="financial.financialChargesModality"
                className="outline-none transform-none text-black"
                styles={selectCustomStyles()}
                options={financialChargesModalityData}
                value={financialChargesModalityData.find(({ value }) => value === values.financial.financialChargesModality)}
                onChange={(value) => setFieldValue('financial.financialChargesModality', value.value)}
              />

              <ErrorMessage component="p" className="error-message-error" name="financial.financialChargesModality" />
            </FormControl>

            <FormControl colSpan={4}>
              <LabelDefault text="Encargos Financeiros - Valor adicional" />
              <InputPercentage
                placeholder="Insira a porcentagem do valor adicional"
                isDisabled={isPreviwer}
                name="financial.financialChargesAddition"
                id="financialChargesAddition"
                value={values.financial.financialChargesAddition}
                setFieldValue={setFieldValue}
              />

              <ErrorMessage component="p" className="error-message-error" name="financial.financialChargesAddition" />
            </FormControl>
          </Grid>
        )}
        <FormControl colSpan={4}>
          <LabelDefault
            text={
              <>
                Comentários adicionais <small>(opcional)</small>
              </>
            }
          />
          <InputDefault
            as="textarea"
            minH="120px"
            pt="10px"
            // placeholder="Observações (opcional)"
            isDisabled={isPreviwer}
            name="financial.financialChargesAdditionalInformation"
            id="financialChargesAdditionalInformation"
            styles={selectCustomStyles()}
            onChange={handleChange}
            value={values.financial.financialChargesAdditionalInformation}
          />
        </FormControl>
      </Flex>

      <Divider borderColor="#70707033" />

      <Flex direction="column">
        <FormLabel>Condição de pagamento / Faturamento</FormLabel>

        <Grid templateColumns="repeat(2, 1fr)" gap={10}>
          <Grid templateColumns="repeat(2, 1fr)" gap={5} alignItems="flex-end">
            <FormControl>
              <LabelDefault text="Duplicata Mercadoria" />
              <Select
                name="financial.paymentTermsCommodity"
                placeholder="Selecione"
                styles={selectCustomStyles()}
                options={paymentConditionInvoicingData}
                value={paymentConditionInvoicingData.find(
                  (selected) => selected.label === values.financial.paymentTermsCommodity.label
                )}
                isDisabled={isPreviwer}
                onChange={(option) => setFieldValue('financial.paymentTermsCommodity', option)}
              />

              <ErrorMessage component="p" className="error-message-error" name="financial.paymentTermsCommodity" />
            </FormControl>

            <FormControl>
              <LabelDefault text="Dias" />
              <InputDefault
                type="number"
                styles={selectCustomStyles()}
                name="financial.paymentTermsCommodityDays"
                id="financial.paymentTermsCommodityDays"
                onChange={handleChange}
                value={values.financial.paymentTermsCommodityDays}
                placeholder="Preencha a quantidade de dias"
                isDisabled={isPreviwer}
              />

              <ErrorMessage component="p" className="error-message-error" name="financial.paymentTermsCommodityDays" />
            </FormControl>

            <FormControl>
              <LabelDefault text="Duplicata Impostos (II/IPI/Pis/Cofins/ICMS)" />
              <Select
                placeholder="Selecione"
                name="financial.paymentTermsTax"
                className="outline-none transform-none text-black"
                styles={selectCustomStyles()}
                options={paymentConditionInvoicingData}
                value={paymentConditionInvoicingData.find((selected) => selected.label === values.financial.paymentTermsTax.label)}
                isDisabled={isPreviwer}
                onChange={(option) => setFieldValue('financial.paymentTermsTax', option)}
              />

              <ErrorMessage component="p" className="error-message-error" name="financial.paymentTermsTax" />
            </FormControl>

            <FormControl>
              <LabelDefault text="Dias" />
              <InputDefault
                type="number"
                name="financial.paymentTermsTaxDays"
                styles={selectCustomStyles()}
                id="financial.paymentTermsTaxDays"
                isDisabled={isPreviwer}
                onChange={handleChange}
                value={values.financial.paymentTermsTaxDays}
                placeholder="Preencha a quantidade de dias"
              />

              <ErrorMessage component="p" className="error-message-error" name="financial.paymentTermsTaxDays" />
            </FormControl>

            <FormControl>
              <LabelDefault text="Duplicata Despesas + Custos Financeiros" />
              <Select
                placeholder="Selecione "
                isDisabled={isPreviwer}
                styles={selectCustomStyles()}
                name="financial.paymentTermsExpenses"
                className="outline-none transform-none text-black"
                options={paymentConditionInvoicingData}
                value={paymentConditionInvoicingData.find(
                  (selected) => selected.label === values.financial.paymentTermsExpenses.label
                )}
                onChange={(option) => setFieldValue('financial.paymentTermsExpenses', option)}
              />

              <ErrorMessage component="p" className="error-message-error" name="financial.paymentTermsExpenses" />
            </FormControl>

            <FormControl h="fit-content">
              <LabelDefault text="Dias" />
              <InputDefault
                type="number"
                styles={selectCustomStyles()}
                name="financial.paymentTermsExpensesDays"
                id="financial.paymentTermsExpensesDays"
                onChange={handleChange}
                value={values.financial.paymentTermsExpensesDays}
                placeholder="Preencha a quantidade de dias"
                isDisabled={isPreviwer}
              />

              <ErrorMessage component="p" className="error-message-error" name="financial.paymentTermsExpensesDays" />
            </FormControl>
            {values.financial.serviceInvoice === true && (
              <>
                <FormControl>
                  <LabelDefault text="Duplicata Nota de Serviço" />
                  <Select
                    placeholder="Selecione "
                    name="financial.paymentTermsServiceInvoice"
                    className="outline-none transform-none text-black"
                    styles={selectCustomStyles()}
                    isDisabled={isPreviwer}
                    options={paymentConditionInvoicingData}
                    value={paymentConditionInvoicingData.find(
                      (selected) => selected.label === values.financial.paymentTermsServiceInvoice.label
                    )}
                    onChange={(option) => setFieldValue('financial.paymentTermsServiceInvoice', option)}
                  />

                  <ErrorMessage component="p" className="error-message-error" name="financial.paymentTermsServiceInvoice" />
                </FormControl>

                <FormControl h="fit-content">
                  <LabelDefault text="Dias" />
                  <InputDefault
                    type="number"
                    styles={selectCustomStyles()}
                    name="financial.paymentTermsServiceInvoiceDays"
                    id="financial.paymentTermsServiceInvoiceDays"
                    onChange={handleChange}
                    value={values.financial.paymentTermsServiceInvoiceDays}
                    placeholder="Preencha a quantidade de dias"
                    isDisabled={isPreviwer}
                  />

                  <ErrorMessage component="p" className="error-message-error" name="financial.paymentTermsServiceInvoiceDays" />
                </FormControl>
              </>
            )}
          </Grid>

          <FormControl colSpan={4}>
            <LabelDefault
              text={
                <>
                  Comentários adicionais <small>(opcional)</small>
                </>
              }
            />
            <InputDefault
              as="textarea"
              minH="120px"
              pt="10px"
              isDisabled={isPreviwer}
              styles={selectCustomStyles()}
              name="financial.paymentTermsAdditionalInformation"
              id="paymentTermsAdditionalInformation"
              onChange={handleChange}
              value={values.financial.paymentTermsAdditionalInformation}
            />
          </FormControl>
        </Grid>
      </Flex>
    </TabPanel>
  );
};

export default FinancialDataForm;
