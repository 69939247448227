import React from 'react';

import { Badge, Box, Flex, Image } from '@chakra-ui/react';

import logo from '../../../assets/images/logo.png';

const CardRound = ({ children }) => {
  return (
    <Box bgColor="white" borderRadius="27px" px="48px" py="36px" h="auto" width={{ sm: '307px', md: '395px' }}>
      <Flex justify="center" margin="0px" direction="column" gap="20px">
        <Image
          src={logo}
          alt="Logo"
          margin="auto"
          width={{ sm: '125px', md: '161px' }}
          height={{ sm: '26px', md: '33px' }}
        />
        <Badge w="50px" h="4px" bgColor="myoga" m="auto"></Badge>
        {children}
      </Flex>
    </Box>
  );
};
export default CardRound;
