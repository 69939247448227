import React from 'react';

import { Flex } from '@chakra-ui/react';

import ScreenLoader from '../../../../components/ScreenLoader/ScreenLoader';

import ChartPortShipmentsContainers from './ChartPortShipmentsContainers';

const PortShipmentsContainers = ({ data, isLoading }) => {
  return (
    <Flex w="100%" direction="column">
      <Flex align="flex-end" justify="center" py="20px" w="100%">
        <ScreenLoader isLoading={isLoading}>
          <ChartPortShipmentsContainers entity={data} />
        </ScreenLoader>
      </Flex>
    </Flex>
  );
};

export default PortShipmentsContainers;
