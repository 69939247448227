import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';
import { MdTune } from 'react-icons/md';
import { toast } from 'react-toastify';

import {
  Button,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import requests from '../../../../services/requests';
import FilterOption from '../../../../utils/filters/FilterOption';
import { addFilter, appendFilter, getFilter } from '../../../../utils/storageFilter';

import DragAndDropConfigFilter from './DragAndDropConfigFilter';
import FormConfigFilter from './FormConfigFilter';
import ListSavedFilters from './ListSavedFilters';

const yup = require('yup');

const ModalConfigFilter = ({
  setFilterApplied,
  refreshApliedFields,
  setFilterFields,
  filterFields,
  filterOptions,
  screen = 'comex-mapping',
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [indexTab, setIndexTab] = useState(0);
  const [filters, setFilters] = useState(getFilter(screen) ?? null);
  const [titleSelection, setTitleSelection] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [filterEditing, setFilterEditing] = useState('');
  const [selectedUser, setSelectedUser] = useState({});
  const [selectionList, setSelectionList] = useState([]);
  const [userOptions, setUserOptions] = useState([]);

  const [metaSelectionMap, setMetaSelectionMap] = useState({
    current_page: 1,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialValues = () => {
    return {
      name: '',
    };
  };

  const selectTabIndex = (index) => {
    setIndexTab(index);
  };

  const handleSelectChange = (field, value, isMultiple) => {
    let data = [];

    if (!Array.isArray(value)) {
      value = [value];
    }

    for (let key in value) {
      let row = value[key];
      if (typeof row === 'string') {
        data.push(row);
      } else if ('value' in row && row.value !== null) {
        data.push(row.value);
      } else if ('identifier' in row) {
        data.push(row.identifier);
      } else if ('id' in row) {
        data.push(row.id);
      }
    }

    if (!isMultiple) {
      if (data.length > 0) {
        data = data.shift();
      } else {
        data = null;
      }
    }

    addFilter(screen, field, data);
    setFilters(getFilter(screen));
  };

  const onlyApply = () => {
    setIsLoading(true);

    const selectedChecklist = {};

    filterOptions.forEach((item) => {
      if (!(item instanceof FilterOption)) {
        return;
      }

      let selectedChildren = item.getSelectedChildrenValue();

      if (selectedChildren.length > 0) {
        selectedChecklist[item.value] = selectedChildren;
      } else {
        selectedChecklist[item.value] = null;
      }
    });

    setIsLoading(false);
    appendFilter(screen, selectedChecklist);
    setFilterApplied(getFilter(screen), { ...selectedChecklist, ...filters });
    onClose();
  };

  const loadSelectionList = () => {
    requests.listSelection(metaSelectionMap.current_page > 0 ? metaSelectionMap.current_page : 1).then((list) => {
      setSelectionList(list.data);
      setMetaSelectionMap(list.meta);
    });
  };

  const loadUsers = () => {
    requests.listClientsUsers().then((data) => {
      setUserOptions(
        data?.map((data) => {
          return {
            value: data.identifier,
            label: data.name,
          };
        })
      );
    });
  };

  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    loadSelectionList();
  }, []);

  return (
    <>
      <Button
        h="46px"
        px="24px"
        py="14px"
        bgColor="green"
        color="#FFFFFF"
        borderRadius="27px"
        _hover={{ bgColor: '#70D499' }}
        rightIcon={<MdTune color="#FFFFFF" size={20} />}
        onClick={onOpen}
        {...props}>
        <Text>Configurar filtro</Text>
      </Button>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent borderRadius="5px" maxW="none" w="50vw" h="80vh">
          <ModalHeader p="25px 0 0 25px">
            <Text fontSize="25px" textColor="primary">
              Seleção de Filtros
            </Text>

            <ModalCloseButton />
          </ModalHeader>

          <ModalBody py="0px">
            <Formik
              initialValues={initialValues()}
              validationSchema={yup.object().shape({
                name: yup.string().required('Campo obrigatório'),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);

                const selectedChecklist = {};

                filterOptions.forEach((item) => {
                  if (!(item instanceof FilterOption)) {
                    return;
                  }

                  let selectedChildren = item.getSelectedChildrenValue();

                  if (selectedChildren.length > 0) {
                    selectedChecklist[item.value] = selectedChildren;
                  } else {
                    selectedChecklist[item.value] = null;
                  }
                });

                let filter = {
                  ...selectedChecklist,
                  ...filters,
                };

                let data = {
                  name: values.name,
                  user: [selectedUser.value],
                  edit: '',
                  sendEmail: 0,
                  parent: filterEditing,
                };

                if (isEdit) {
                  requests.newSelectionMap(data, filter, filterFields).then((data) => {
                    loadSelectionList();
                    onClose();
                    toast.success('Filtro editado e aplicado!!');
                    requests.deleteSelection(filterEditing);

                    setSubmitting(false);
                  });
                } else {
                  requests.newSelectionMap(data, filter, filterFields).then(() => {
                    loadSelectionList();
                    onClose();
                    toast.success('Filtro criado e aplicado!!');

                    setSubmitting(false);
                  });
                }

                appendFilter(screen, selectedChecklist);
                setFilterApplied(getFilter(screen), { ...selectedChecklist, ...filters });
              }}>
              {({ values, handleSubmit, handleChange, isSubmitting }) => (
                <form
                  onSubmit={handleSubmit}
                  style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <Tabs isFitted index={indexTab}>
                    <TabList borderBottom="1px" borderColor="#7070702E">
                      <Tab _selected={{ borderColor: '#422C76' }} onClick={() => selectTabIndex(0)}>
                        <Text textStyle="tableTitle" fontWeight="medium" textColor="#422C76">
                          Filtro aplicado
                        </Text>
                      </Tab>

                      <Tab _selected={{ borderColor: '#422C76' }} onClick={() => selectTabIndex(1)}>
                        <Text textStyle="tableTitle" fontWeight="medium" textColor="#422C76">
                          Filtros salvos
                        </Text>
                      </Tab>
                    </TabList>

                    <TabPanels>
                      <TabPanel px="3px" py="20px" h="calc(80vh - 180px)" overflowY="auto">
                        {indexTab === 0 && (
                          <Flex gap="20px" direction="column">
                            <FormConfigFilter
                              values={values}
                              handleChange={handleChange}
                              screen={screen}
                              filters={filters}
                              filterOptions={filterOptions}
                              handleSelectChange={handleSelectChange}
                              setSelectedUser={setSelectedUser}
                              selectedUser={selectedUser}
                              userOptions={userOptions}
                              titleSelection={titleSelection}
                              setTitleSelection={setTitleSelection}
                            />

                            <Grid templateColumns={'repeat(4, 1fr)'} w="full">
                              {filterOptions &&
                                filterOptions.map((item, key) => {
                                  if (item instanceof FilterOption) {
                                    return (
                                      <Flex
                                        direction="column"
                                        key={key}
                                        align="flex-start"
                                        style={{ listStyle: 'none', fontSize: '18px', fontWeight: 'bold' }}>
                                        {item.renderTree(getFilter(screen, item.value))}
                                      </Flex>
                                    );
                                  }
                                })}
                            </Grid>

                            <DragAndDropConfigFilter setFilterFields={setFilterFields} filterFields={filterFields} />
                          </Flex>
                        )}
                      </TabPanel>

                      <TabPanel px="3px" py="20px">
                        {indexTab === 1 && (
                          <ListSavedFilters
                            refreshApliedFields={refreshApliedFields}
                            loadSelectionList={loadSelectionList}
                            setFilterApplied={setFilterApplied}
                            selectionList={selectionList}
                            onClose={onClose}
                            setIsEdit={setIsEdit}
                            setFilterEditing={setFilterEditing}
                            setTitleSelection={setTitleSelection}
                            setSelectedUser={setSelectedUser}
                            setFilterFields={setFilterFields}
                            setSelectionList={setSelectionList}
                            selectTabIndex={selectTabIndex}
                          />
                        )}
                      </TabPanel>
                    </TabPanels>
                  </Tabs>

                  <ModalFooter borderTop="1px" borderColor="#7070702E" p="0">
                    <Button
                      variant="secundary"
                      w="fit-content"
                      borderRadius="7px"
                      m="15px"
                      p="9px 23px"
                      type="submit"
                      isLoading={isSubmitting}>
                      Salvar e aplicar
                    </Button>

                    <Button
                      variant="primary"
                      w="fit-content"
                      borderRadius="7px"
                      m="15px"
                      p="9px 23px"
                      isLoading={isSubmitting}
                      onClick={() => {
                        onlyApply();
                      }}>
                      Somente aplicar
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalConfigFilter;
