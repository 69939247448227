import React, { useEffect, useRef, useState } from 'react';

import { MdAdd } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Box, Button, Text } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import ExpandContainer from '../../../components/ExpandContainer/ExpandContainer';
import Page from '../../../components/Page';
import Paginate from '../../../components/Paginate/Paginate';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsQuote } from '../../../utils/filters/filterPresets';

import QuoteCard from './components/QuoteCard';
import QuoteTable from './components/QuoteTable';

const QuotePage = () => {
  const hasPermission = permissions.vendemmiaOperationSpecification;
  const request = useRef(0);

  const [isMobile, setIsMobile] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);

  const [list, setList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [action, setAction] = useState(1);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const load = (filters, key, page) => {
    window.localStorage.removeItem('entity-old');

    setIsLoading(true);

    requests
      .getQuotes(filters, page)
      .then((response) => {
        if (request.current && request.current > key) {
          return;
        }
        request.current = key;

        setList(response.data);
        setMetadata(response.meta);

        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsQuote());
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  return (
    <Page
      screen="quote"
      title="Cotação de frete"
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      textFilterPlaceholder="Buscar por referência ou texto"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading}
      allowEmptySearchPeriod={true}
      showPeriodFilter={false}>
      <Card m="10px">
        <ExpandContainer>
          <Box w="full">
            <ScreenLoader isLoading={isLoading}>
              <Box overflow="auto" h="calc(100vh - 190px)">
                {!isMobile ? <QuoteTable list={list} /> : <QuoteCard list={list} />}
              </Box>
            </ScreenLoader>

            {metadata.total_pages > 1 && (
              <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
            )}
          </Box>
        </ExpandContainer>

        <ScreenLoader isLoading={isLoading}>
          <Box height="calc(100vh - 255px)" overflow="auto">
            {!isMobile ? <QuoteTable list={list} loadList={load} /> : <QuoteCard list={list} />}
          </Box>
        </ScreenLoader>

        {metadata.total_pages > 1 && (
          <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
        )}
      </Card>

      <Link to="/vendemmia/quote/new">
        <Button
          className="float-button-modal"
          bgColor="green"
          color="white"
          borderRadius="50px"
          shadow="xl"
          h="50px"
          py="15px"
          px="30px"
          _hover={{ opacity: '0.5', transition: '0.9s' }}>
          <MdAdd color="#FFFFFF" size={20} />
          <Text textStyle="paragraph" ml="10px">
            Nova cotação
          </Text>
        </Button>
      </Link>
    </Page>
  );
};

export default QuotePage;
