import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Flex, Image, Text } from '@chakra-ui/react';

import comment from '../../../../assets/images/comment.svg';
import gestao from '../../../../assets/images/gestao-ball.png';
import notification from '../../../../assets/images/notification.svg';
import manual from '../../../../assets/images/proprio-ball.png';
import api from '../../../../assets/images/vendemmia-ball.png';
import permissions from '../../../../services/permissions';

import ChannelFlag from './ChannelFlag';
import FavoriteProcess from './FavoriteProcess';
import HideProcess from './HideProcess';
import ProcessCommentsDrawer from './ProcessCommentsDrawer';
import ProcessInfoTag from './ProcessInfoTag';
import ProcessUpdatesDrawer from './ProcessUpdatesDrawer';

const ProcessInfo = ({ process, forceLoadTrigger, setForceLoadTrigger }) => {
  const canHideProcess = permissions.canHideProcess;
  let navigate = useNavigate();
  const getIcon = () => {
    switch (process.originDescription) {
      case 'api':
        return api;
      case 'manual':
        return manual;
      case 'gestao':
        return gestao;
      default:
        return api;
    }
  };

  const toRoute = (to) => {
    navigate(to);
  };

  return (
    <Flex alignItems="center" flexWrap="wrap" gap={{ base: '10px', md: '25px' }} p={{ base: '0', md: '0 5px' }}>
      <Flex alignItems="center" className="up-anim" justifyContent={{ base: 'space-between', md: 'inherit' }} gap="10px">
        <Image src={getIcon()} borderRadius="100%" w="30px" h="30px" />

        <Text textStyle="tableTitle" onClick={() => toRoute(`/comex/process/detail/${process.identifier}`)}>
          {process.code}
        </Text>

        <ChannelFlag color={process.channel} />

        <Flex alignItems="center" opacity={process.qttRecentUpdates == 0 ? '.1' : '1'} gap="10px">
          <ProcessUpdatesDrawer process={process} notification={notification} />
          <ProcessCommentsDrawer process={process} comment={comment} />
          <FavoriteProcess process={process} />
          {canHideProcess && (
            <HideProcess process={process} forceLoadTrigger={forceLoadTrigger} setForceLoadTrigger={setForceLoadTrigger} />
          )}
        </Flex>
      </Flex>

      <Flex gap="10px" flexWrap="wrap">
        {process.clientInternalCode && <ProcessInfoTag key={process.clientInternalCode}>{process.clientInternalCode}</ProcessInfoTag>}
        {process.currentStageModality && (
          <ProcessInfoTag key={process.currentStageModality}>{process.currentStageModality}</ProcessInfoTag>
        )}
        {process.returnOrigin
          ? process.originDescription && (
              <ProcessInfoTag key={process.originDescription}>
                {process.originDescription?.toLowerCase() === 'gestao' ? 'Gestão' : 'Própria'}
              </ProcessInfoTag>
            )
          : process.modality && <ProcessInfoTag key={process.modality}>{process.modality}</ProcessInfoTag>}
      </Flex>
    </Flex>
  );
};

export default ProcessInfo;
