import React, { useState } from 'react';

import { toast } from 'react-toastify';

import { Box, Flex, Image, Table, Tbody, Td, Th, Thead, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';

import edit from '../../../../assets/svgs/edit.svg';
import Paginate from '../../../../components/Paginate/Paginate';
import requests from '../../../../services/requests';
import roleOptions from '../Helpers/roleOptions';
import statusOptions from '../Helpers/statusOptions';

import ModalChangeRole from './ModalChangeRole';
import ModalChangeStatus from './ModalChangeStatus';
import ModalManagementUser from './ModalManagementUser';

const UserManagementListTable = ({ list = [], filter, loadList, action, setAction, metadata, setMetadata, setFields, fields }) => {
  const [identifier, setIdentifier] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClose = () => {
    onClose();
    loadList();
  };

  const handleOpen = (identifier) => {
    setIdentifier(identifier);
    onOpen();
  };

  const handleClickChangeRole = (identifier) => {
    requests
      .editUserRole(identifier, { role: fields?.role?.value })
      .then((response) => {
        toast.success(
          `Perfil do usuário ${response.name} alterado para ${roleOptions.find((e) => e.value === response?.role)?.label}`
        );
        loadList();
      })
      .catch(() => {
        toast.error('Problemas ao alterar perfil do usuário, tente novamente ou entre em contato com o Administrador');
      });
  };

  const handleClickChangeStatus = (identifier) => {
    requests
      .deleteUser(identifier, { statusUser: fields.status })
      .then((response) => {
        toast.success(
          `Status do usuário ${response.name} alterado para ${statusOptions.find((e) => e.value === response?.status)?.label}`
        );
        loadList();
      })
      .catch(() => {
        toast.error('Problemas ao alterar status do usuário, tente novamente ou entre em contato com o Administrador');
      });
  };

  return (
    <>
      <ModalManagementUser
        loadList={loadList}
        filter={filter}
        fields={fields}
        setFields={setFields}
        isEditMode={true}
        handleClose={handleClose}
        handleOpen={handleOpen}
        isOpen={isOpen}
        identifier={identifier}
      />

      <Table className="table-default">
        <Thead border="1px" borderBottom="1px" h={50}>
          <Tr>
            <Th>Nome</Th>
            <Th>E-mail</Th>
            <Th>Permissões</Th>
            <Th>Empresas vinculadas</Th>
            <Th>Perfil do usuário</Th>
            <Th>Status</Th>
            <Th>Ações</Th>
          </Tr>
        </Thead>
        <Tbody>
          {list?.length > 0 ? (
            <>
              {list.map((entity, index) => {
                return (
                  <Tr key={index} border="none" cursor="pointer" style={{ '--delay': `${index / 10}s` }}>
                    <Td>{entity?.name ?? '-'}</Td>
                    <Td>{entity?.email ?? '-'}</Td>
                    <Td>{entity?.permissionsQtt ?? '-'}</Td>
                    <Td>{entity?.clientsQtt ?? '-'}</Td>
                    <Td>
                      <ModalChangeRole
                        roleOptions={roleOptions}
                        entity={entity}
                        setFields={setFields}
                        fields={fields}
                        callback={(decision) => {
                          if (decision) {
                            handleClickChangeRole(entity.identifier);
                          }
                        }}
                      />
                    </Td>
                    <Td>
                      <ModalChangeStatus
                        statusOptions={statusOptions}
                        entity={entity}
                        setFields={setFields}
                        fields={fields}
                        callback={(decision) => {
                          if (decision) {
                            handleClickChangeStatus(entity.identifier);
                          }
                        }}
                      />
                    </Td>
                    <Td justify="center" variant="primary">
                      <Tooltip label="Editar perfil">
                        <Box
                          onClick={() => {
                            handleOpen(entity.identifier);
                          }}>
                          <Image src={edit} />
                        </Box>
                      </Tooltip>
                    </Td>
                  </Tr>
                );
              })}
            </>
          ) : (
            <Tr>
              <Td colSpan={7}>
                <Flex justify="center" my="25px">
                  Nenhum dado encontrado para os parâmetros filtrados.
                </Flex>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {metadata.total_pages > 1 && (
        <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
      )}
    </>
  );
};

export default UserManagementListTable;
