import React, { useEffect, useState } from 'react';

import { Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';

import MapDashboard from '../../../../components/Map/MapDashboard';

import MapMarineTraffic from './MapMarineTraffic';

const SwitchMaps = ({ process, h, points, lines }) => {
  const [marineUrls, setMarineUrls] = useState([]);
  const getMarineUrls = () => {
    let urls = [];
    process?.vesselMmsi?.length &&
      process.vesselMmsi.map((vessel) => {
        return urls.push({
          url: `https://www.marinetraffic.com/en/ais/embed/maptype:0/MMSI:${vessel.mmsi}/showmenu:false/remember:false`,
          name: vessel.name,
        });
      });

    setMarineUrls(urls);
  };

  useEffect(() => {
    if (process.currentStageModalitySequential == 2) {
      getMarineUrls();
    }
  }, [process]);

  return process?.currentStageModalitySequential == 2 && marineUrls.length > 0 ? (
    marineUrls.length > 1 ? (
      <Tabs position="relative" variant="unstyled">
        <TabList justifyContent="flex-start" pl="20px" borderBottom="#70707036 solid 1px">
          {marineUrls.map((item, key) => (
            <Tab key={key} color="#BEBEBE" _selected={{ color: '#6C48C2' }}>
              <Text fontSize="14px">{item.name}</Text>
            </Tab>
          ))}
        </TabList>
        <TabIndicator mt="-1.5px" height="2px" bg="#6C48C2" borderRadius="1px" />
        <TabPanels>
          {marineUrls.map((item, key) => (
            <TabPanel key={key}>
              <MapMarineTraffic url={item.url} />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    ) : (
      <MapMarineTraffic url={marineUrls[0].url} />
    )
  ) : (
    <MapDashboard
      w="100%"
      h={h}
      points={points}
      lines={lines}
      shouldShowCenterMapButton={false}
      defaultMapModality='origin-to-destination'
      gestureHandling='none'
      fullscreenControl={false}
      mapTypeControl={false}
      maxZoom={20}
    />
  );
};

export default SwitchMaps;
