import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { Flex, Tab, TabIndicator, TabList, TabPanels, Tabs, Text } from '@chakra-ui/react';

import Card from '../../../components/Card/Card';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import useScreenPage from '../../../hooks/useScreenPage';
import requests from '../../../services/requests';

import UpdatesNotificationTab from './UpdatesNotificationTab';

const DashboardUpdates = (props) => {
  //Detecção de chamadas as APIs
  const screenPage = useScreenPage();

  const { searchPeriod, filterInfo, searchString, shouldRefresh, setIsRefreshing } = props;

  const [isLoadingUpdates, setIsLoadingUpdates] = useState(false);
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [isLoadingFavourites, setIsLoadingFavourites] = useState(false);

  const [updatesList, setUpdatesList] = useState();
  const [commentsList, setCommentsList] = useState();
  const [favouriteList, setFavouriteList] = useState();

  const [metaUpdate, setMetaUpdate] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [metaComment, setMetaComment] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const [metaFavourite, setMetaFavourite] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const formatMax = (count) => {
    if (count == null) {
      return '0';
    }

    if (count > 999) {
      return '999+';
    }

    return `${count}`;
  };

  const load = () => {
    listUpdates();
    listComments();
    favouriteComments();
  };

  const listUpdates = async () => {
    setIsLoadingUpdates(true);

    try {
      const response = await requests.listUpdates(
        {
          filters: {
            clients: filterInfo.client,
          },
          period: searchPeriod,
          search: searchString,
          screenPage,
        },
        metaUpdate.current_page > 0 ? metaUpdate.current_page : 1,
        10
      );

      setUpdatesList(response.data);
      setMetaUpdate(response.meta);
      setIsLoadingUpdates(false);
    } catch (error) {
      setIsLoadingUpdates(false);

      toast.error(error);
    }
  };

  const loadMoreItemsUpdates = async () => {
    setIsLoadingUpdates(true);

    try {
      const response = await requests.listUpdates(
        {
          filters: {
            clients: filterInfo.client,
          },
          period: searchPeriod,
          search: searchString,
          screenPage,
        },
        metaUpdate.current_page > 0 ? metaUpdate.current_page + 1 : 1,
        10
      );

      setUpdatesList([...updatesList, ...response.data]);
      setMetaUpdate(response.meta);
      setIsLoadingUpdates(false);
    } catch (error) {
      setIsLoadingUpdates(false);

      toast.error(error);
    }
  };

  const listComments = async () => {
    setIsLoadingComments(true);

    try {
      const response = await requests.lastComments(
        {
          filters: {
            clients: filterInfo.client,
          },
          period: searchPeriod,
          search: searchString,
          screenPage,
        },
        metaComment.current_page > 0 ? metaComment.current_page : 1,
        10
      );

      setCommentsList(response.data);
      setMetaComment(response.meta);
      setIsLoadingComments(false);
    } catch (error) {
      setIsLoadingComments(false);

      toast.error(error);
    }
  };

  const loadMoreItemsComments = async () => {
    setIsLoadingComments(true);
    try {
      const response = await requests.lastComments(
        {
          filters: {
            clients: filterInfo.client,
          },
          period: searchPeriod,
          search: searchString,
          screenPage,
        },
        metaComment.current_page > 0 ? metaComment?.current_page + 1 : 1,
        10
      );

      setCommentsList([...commentsList, ...response.data]);
      setMetaComment(response.meta);
      setIsLoadingComments(false);
    } catch (error) {
      setIsLoadingComments(false);
      toast.error(error);
    }
  };

  const favouriteComments = async () => {
    setIsLoadingFavourites(true);

    try {
      const response = await requests.favouriteComments(
        {
          filters: {
            clients: filterInfo.client,
          },
          period: searchPeriod,
          search: searchString,
          screenPage,
        },
        metaFavourite.current_page > 0 ? metaFavourite.current_page : 1,
        10
      );

      setFavouriteList(response.data);
      setMetaFavourite(response.meta);
      setIsLoadingFavourites(false);
    } catch (error) {
      setIsLoadingFavourites(false);

      toast.error(error);
    }
  };

  const loadMoreItemsfavouriteComments = async () => {
    setIsLoadingFavourites(true);
    try {
      const response = await requests.favouriteComments(
        {
          filters: {
            clients: filterInfo.client,
          },
          period: searchPeriod,
          search: searchString,
          screenPage,
        },
        metaFavourite.current_page > 0 ? metaFavourite?.current_page + 1 : 1,
        10
      );

      setFavouriteList([...favouriteList, ...response.data]);
      setMetaFavourite(response.meta);
      setIsLoadingFavourites(false);
    } catch (error) {
      setIsLoadingFavourites(false);
      toast.error(error);
    }
  };

  useEffect(() => {
    if (searchPeriod?.startsAt != null || searchPeriod?.endsAt != null) {
      load();
    }
  }, [searchPeriod, filterInfo, searchString]);

  useEffect(() => {
    setIsRefreshing(isLoadingUpdates || isLoadingComments || isLoadingFavourites);
  }, [isLoadingUpdates, isLoadingComments, isLoadingFavourites]);

  useEffect(() => {
    if (shouldRefresh) {
      load();
    }
  }, [shouldRefresh]);

  return (
    <Tabs
      position="relative"
      maxH={{ base: '380px', md: '500px' }}
      mt={{ base: '10px', md: '0' }}
      overflow="hidden"
      variant="unstyled"
      isFitted>
      <Card
        height="100%"
        header={
          <Flex position="relative" w="full">
            <TabList w="full">
              <Tab px="0px" py="10px">
                <Text fontSize="14px" color="#6C48C2">
                  Atualizações ({formatMax(metaUpdate.total_count)})
                </Text>
              </Tab>
              <Tab px="0px" py="10px">
                <Text fontSize="14px" color="#6C48C2">
                  Comentários ({formatMax(metaComment.total_count)})
                </Text>
              </Tab>
              <Tab px="0px" py="10px">
                <Text fontSize="14px" color="#6C48C2">
                  Favoritos ({formatMax(metaFavourite.total_count)})
                </Text>
              </Tab>
            </TabList>
            <TabIndicator mt="-1.5px" bottom={0} height="2px" bg="#6C48C2" borderRadius="1px" />
          </Flex>
        }>
        <Flex overflowY="auto">
          <ScreenLoader isLoading={isLoadingUpdates} h="440px">
            <TabPanels p="15px" pt="0">
              <UpdatesNotificationTab
                type="update"
                entity={updatesList}
                metaUpdate={metaUpdate}
                loadMoreItemsUpdates={loadMoreItemsUpdates}
                isLoadingUpdates={isLoadingUpdates}
              />
              <UpdatesNotificationTab
                type="comment"
                entity={commentsList}
                metaComment={metaComment}
                loadMoreItemsComments={loadMoreItemsComments}
                isLoadingComments={isLoadingComments}
              />
              <UpdatesNotificationTab
                type="favourite"
                entity={favouriteList}
                metaFavourite={metaFavourite}
                loadMoreItemsfavouriteComments={loadMoreItemsfavouriteComments}
                isLoadingFavourites={isLoadingFavourites}
              />
            </TabPanels>
          </ScreenLoader>
        </Flex>
      </Card>
    </Tabs>
  );
};

export default DashboardUpdates;
