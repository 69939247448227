import React, { useEffect, useState } from 'react';

import { DirectionsRenderer, GoogleMap, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api';
import moment from 'moment';

import { Box, Flex, Switch, Text, Image } from '@chakra-ui/react';

import truck from '../../assets/svgs/realtime-truck.svg';
import ScreenLoader from '../../components/ScreenLoader/ScreenLoader';
import requests from '../../services/requests';
import { getDistanceFromLatLonInKm, generateTrajectoryChunks } from '../../utils/geolocation';
import generateIconEvent from '../../utils/maps/generateIconEvent';
import { descriptionStatusTrip, tripColors, tripLabelsString } from '../../utils/translate';

import MapPopup from './MapPopup';

const Map = ({
  list = [],
  tripList = [],
  currentZoom,
  defaultZoom,
  mapBounds = null,
  setCurrentZoom,
  center,
  setCenter,
  lines,
  setLines,
  defaultCenter,
  shouldShowCenterMapButton = true,
  fullscreenControl = true,
  mapTypeControl = true,
  selectedTrip = null,
  isLoading = false,
  isMobile = false,
  currentTripData = null,
  setCurrentTripData = null,
  currentTripIsTrackable = false,
}) => {
  const [map, setMap] = useState(null);

  const [showAllTrips, setShowAllTrips] = useState(false);
  const [showOnlyCurrent, setShowOnlyCurrent] = useState(true);
  const [showAllTrajectories, setShowAllTrajectories] = useState(false);
  const [showAllCheckpoints, setShowAllCheckpoints] = useState(false);

  const [selectedMarker, setSelectedMarker] = useState(null);
  const handleMarkerClick = (marker, trip) => {
    if (!trip || !currentTripData || (currentTripData.identifier !== trip.identifier)) {
      setShowAllCheckpoints(false);
    }

    if (marker) {
      setSelectedMarker(marker);
      if (trip) {
        setCurrentTripData(trip);
      } else {
        setCurrentTripData(null);
      }
    } else {
      setSelectedMarker(null);
      setCurrentTripData(null);
    }
  };

  const [pointsRealtime, setPointsRealtime] = useState([]);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  });
  const containerStyle = {
    width: '100%',
    height: !isMobile ? '500px' : '250px',
  };


  useEffect(() => {
    if (!mapBounds || !map || (typeof google) === 'undefined' || !google) {
      return;
    }

    let bounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(mapBounds.x1, mapBounds.y1),
        new google.maps.LatLng(mapBounds.x2, mapBounds.y2)
    );

    map.fitBounds(bounds);

    let WORLD_DIM = { height: 256, width: 256 };
    let ZOOM_MAX = 21;

    let ne = bounds.getNorthEast();
    let sw = bounds.getSouthWest();

    if (!ne || !sw) {
      return;
    }

    function latRad(lat) {
      let sin = Math.sin(lat * Math.PI / 180);
      let radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    let latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

    let lngDiff = ne.lng() - sw.lng();
    let lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    let latZoom = Math.floor(Math.log(500 / WORLD_DIM.height / latFraction) / Math.LN2);
    let lngZoom = Math.floor(Math.log(1000 / WORLD_DIM.width / lngFraction) / Math.LN2);

    let zoom = Math.min(latZoom, lngZoom, ZOOM_MAX);

    setCurrentZoom(zoom);
  }, [mapBounds]);

  useEffect(() => {
    if (currentTripData && currentTripData.identifier.length > 0) {
      setShowOnlyCurrent(true);
      setShowAllTrips(false);
      setShowAllTrajectories(false);
    } else {
      setShowOnlyCurrent(false);
    }
  }, [currentTripData]);

  return (
    <Box border="1px" borderColor="#7070703D" bgColor="#FFFFFF" position={'relative'}>
      {currentTripData !== null &&
        <Box w="100%" h={'200px'} position="absolute">
          <Flex
            position="absolute"
            top="10px"
            left="10px"
            zIndex="10"
            p="10px 15px"
            pointerEvents="none"
            borderRadius="5px"
            gap="10px">
            <Flex
              bgColor="linkColor"
              top="10px"
              left="10px"
              zIndex="10"
              p="10px 15px"
              pointerEvents="none"
              borderRadius="5px"
              direction="column"
              gap="10px">
              <Text color="white" fontSize="20px">
                {`Viagem #${currentTripData.code} - ` + descriptionStatusTrip(currentTripData.status ?? currentTripData.timeline.currentStatus)}
              </Text>
            </Flex>
          </Flex>
        </Box>
      }
      <ScreenLoader isLoading={isLoading}>
        {isLoaded ? (
          <>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={currentZoom}
              options={{
                mapId: '6d2819c49ba264e7',
                minZoom: 1,
                zoomControl: true,
                streetViewControl: false,
                keyboardShortcuts: false,
                scaleControl: false,
                gestureHandling: true,
                mapTypeControl: false,
                fullscreenControlOptions: {
                  position: google.maps.ControlPosition.TOP_RIGHT,
                },
                mapTypeControlOptions: {
                  position: google.maps.ControlPosition.TOP_RIGHT,
                },
                fullscreenControl: fullscreenControl,
                mapTypeControl: mapTypeControl,
              }}
              onLoad={(map) => {
                setMap(map);
              }}>
              {/* Markers de viagens */}
              {tripList.map((trip, key) => {
                return (
                  <React.Fragment key={"markers-" + trip.identifier + key}>
                    {trip.trajectoryMarkers.map((marker, markerKey) => {
                      if (!showAllTrips && !trip.isTrackable) {
                        return <React.Fragment key={"markers-" + trip.identifier + key + markerKey} />;
                      }

                      if (showOnlyCurrent && currentTripData && trip.identifier !== currentTripData.identifier) {
                        return (<React.Fragment key={"markers-" + trip.identifier + key + markerKey} />);
                      }

                      return (
                        <Marker
                          key={"markers-" + trip.identifier + key + markerKey}
                          position={marker}
                          icon={marker?.icon}
                          label={marker?.label}
                          title={marker?.title}
                          optimized={false}
                          onClick={() => {
                            handleMarkerClick(marker, trip);
                          }}>
                          {selectedMarker === marker && (
                            <MapPopup key={markerKey} point={selectedMarker} onCloseClick={() => handleMarkerClick(null, null)}
                            />
                          )}
                        </Marker>
                      );
                    })}
                  </React.Fragment>
                );
              })}

              {/* Directions de viagens */}
              {tripList.map((trip, key) => {
                if (!trip.trajectoryPath || trip.trajectoryPath.length === 0) {
                  return <React.Fragment key={"directions-" + trip.identifier + key} />;
                }

                if (!trip.isTrackable && (!showAllTrips || !showAllTrajectories)) {
                  return <React.Fragment key={"directions-" + trip.identifier + key} />;
                }

                if (showOnlyCurrent && currentTripData && trip.identifier !== currentTripData.identifier) {
                  return (<React.Fragment key={"directions-" + trip.identifier + key} />);
                }

                return (<>
                  {trip.trajectoryPath.map((trajectory, trajectoryKey) => {
                    let polylineOptions =  {
                      strokeColor: trip.color,
                      strokeWeight: 4,
                    };
                    if (trajectory.status !== 'COMPLETED') {
                      polylineOptions.strokeOpacity = 0;
                      polylineOptions.icons = [
                        {
                          icon: {
                            path: "M 0,-1 0,1",
                            strokeOpacity: 1,
                            scale: 2,
                          },
                          offset: "0",
                          repeat: "10px",
                        },
                      ];
                    }

                    return <DirectionsRenderer
                      key={"directions-" + trip.identifier + key + trajectoryKey}
                      directions={trajectory.directions}
                      options={{
                        preserveViewport: true,
                        suppressMarkers: true,
                        polylineOptions: polylineOptions,
                      }}
                    />
                  })}
                </>)
              })}

              {/* Viagem atual */}
              {currentTripData && tripList.map((trip, key) => {
                if (trip.identifier !== currentTripData.identifier) {
                  return <React.Fragment key={"current-" + trip.identifier + key} />;
                }

                return (
                  <React.Fragment key={key}>
                    {/* Mostra os pontos de início e fim */}
                    {trip.trajectoryMarkers.map((marker, markerKey) => {
                      return (
                        <Marker
                          key={"current-markers-" + trip.identifier + key + markerKey}
                          position={marker}
                          icon={marker?.icon}
                          label={marker?.label}
                          title={marker?.title}
                          optimized={false}
                          onClick={() => {
                            handleMarkerClick(marker, trip);
                          }}>
                          {selectedMarker === marker && (
                            <MapPopup key={markerKey} point={selectedMarker} onCloseClick={() => handleMarkerClick(null, null)} />
                          )}
                        </Marker>
                      );
                    })}

                    {/* Mostra o trajeto, se houver */}
                    {trip.trajectoryPath.map((trajectory, trajectoryKey) => {
                      let polylineOptions =  {
                        strokeColor: trip.color,
                        strokeWeight: 4,
                      };
                      if (trajectory.status !== 'COMPLETED') {
                        polylineOptions.strokeOpacity = 0;
                        polylineOptions.icons = [
                          {
                            icon: {
                              path: "M 0,-1 0,1",
                              strokeOpacity: 1,
                              scale: 2,
                            },
                            offset: "0",
                            repeat: "10px",
                          },
                        ];
                      }
                      return (
                        <DirectionsRenderer
                          preserveViewport={true}
                          key={"current-diretion-" + trip.identifier + key + trajectoryKey}
                          directions={trajectory.directions}
                          options={{
                            preserveViewport: true,
                            suppressMarkers: true,
                            polylineOptions: polylineOptions,
                          }}
                        />
                      );
                    })}

                    {/* Mostra os checkpoints, se for para mostrar */}
                    {showAllCheckpoints &&
                      trip.checkpointsMarkers.map((marker, markerKey) => {
                        return (
                          <Marker
                            key={"current-marker-" + trip.identifier + key + markerKey}
                            position={marker}
                            icon={marker?.icon}
                            label={marker?.label}
                            title={marker?.title}
                            optimized={false}
                            onClick={() => {
                              handleMarkerClick(marker, trip);
                            }}>
                            {selectedMarker === marker && (
                              <MapPopup
                                key={markerKey}
                                point={selectedMarker}
                                onCloseClick={() => handleMarkerClick(null, null)}
                              />
                            )}
                          </Marker>
                        );
                      })
                    }

                    {/* Mostra a última posição, se houver */}
                    {trip.lastCheckpointMarker && <Marker
                      key={"current-marker-last-position" + trip.identifier}
                      position={trip.lastCheckpointMarker}
                      label={trip.lastCheckpointMarker?.label}
                      title={trip.lastCheckpointMarker?.title}
                      optimized={false}
                      icon={{
                        url: truck,
                        origin: {
                          x: 0,
                          y: -25
                        }
                      }}
                      onClick={() => {
                        handleMarkerClick(trip.lastCheckpointMarker, trip);
                      }}>
                      {selectedMarker === trip.lastCheckpointMarker && (
                        <MapPopup
                          key={'last-position'}
                          point={selectedMarker}
                          onCloseClick={() => handleMarkerClick(null, null)}
                        />
                      )}
                    </Marker>}
                  </React.Fragment>
                );
              })}

              <MarkerClusterer
                key={"cluster"}
                gridSize={15}
                title={'Clique para ver de perto'}
                maxZoom={2}
                minimumClusterSize={1}
                styles={[
                  {
                    url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png',
                    textColor: '#fff',
                    height: 53,
                    width: 53,
                  },
                  {
                    url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png',
                    textColor: '#fff',
                    height: 40,
                    width: 40,
                  },
                  {
                    url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m3.png',
                    textColor: '#fff',
                    height: 66,
                    width: 66,
                  },
                  {
                    url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m4.png',
                    textColor: '#fff',
                    height: 78,
                    width: 78,
                  },
                  {
                    url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m5.png',
                    textColor: '#fff',
                    height: 90,
                    width: 90,
                  },
                ]}
              >
                {(clusterer) => {
                  return (
                    <div>
                      {tripList.map((trip, key) => {
                        return (
                          <React.Fragment key={"cluster-" + trip.identifier + key}>
                            {trip.trajectoryMarkers.map((marker, markerKey) => {
                              return (
                                <Marker
                                  key={"cluster-marker-" + trip.identifier + key + markerKey}
                                  position={marker}
                                  opacity={0}
                                />
                              )
                            })}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  );
                }}
              </MarkerClusterer>
            </GoogleMap>
            <Flex top="10px" left="10px" bg="white" zIndex="99" p="10px 15px" borderRadius="5px" direction="row" gap="10px">
              <Text color="primary" fontSize="12px" display="flex" gap="10px" mr="20px">
                Mostrar as viagens sem rastreio?
                <Switch
                  size="md"
                  colorScheme="teal"
                  isChecked={showAllTrips}
                  onChange={() => {
                    if (showAllTrips) {// Trabalhar com a inversa
                      setShowAllTrajectories(false);
                    }

                    setShowAllTrips(!showAllTrips);
                  }}
                />
              </Text>

              {showAllTrips && <Text color="primary" fontSize="12px" display='flex' gap='10px'>
                Mostrar os trajetos sem rastreio?
                <Switch
                  size="md"
                  colorScheme="teal"
                  isChecked={showAllTrajectories}
                  onChange={() => {
                    if (!showAllTrajectories) {// Trabalhar com a negativa, visto que o booleano está invertendo no set abaixo
                      setShowOnlyCurrent(false);
                    }

                    setShowAllTrajectories(!showAllTrajectories);
                  }}
                />
              </Text>}

              {currentTripData &&
                <Text color="primary" fontSize="12px" display='flex' gap='10px'>
                  Mostrar somente a viagem atual?
                  <Switch
                    size="md"
                    colorScheme="teal"
                    isChecked={showOnlyCurrent}
                    onChange={() => {
                      if (!showOnlyCurrent) {// Trabalhar com a negativa, visto que o booleano está invertendo no set abaixo
                        setShowAllTrajectories(false);
                        setShowAllTrips(false);
                      } else {
                        setCurrentTripData(null)
                      }

                      setShowOnlyCurrent(!showOnlyCurrent);
                    }}
                  />
                </Text>
              }
              {currentTripData && currentTripIsTrackable && currentTripData.checkpoints && currentTripData.checkpoints.length > 0 &&
                <Text color="primary" fontSize="12px" display='flex' gap='10px'>
                  Mostrar todos os pontos rastreados dessa viagem?
                  <Switch
                    size="md"
                    colorScheme="teal"
                    isChecked={showAllCheckpoints}
                    onChange={() => {
                      setShowAllCheckpoints(!showAllCheckpoints);
                    }}
                  />
                </Text>
              }
            </Flex>
          </>
        ) : (
          <></>
        )}
      </ScreenLoader>
    </Box>
  );
};

export default Map;
