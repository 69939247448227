import React from 'react';

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import { Box, Collapse, Flex, Grid, GridItem, List, ListItem, Text, useDisclosure } from '@chakra-ui/react';

import Tag from '../../../../components/Generic/Tag';
import Paginate from '../../../../components/Paginate/Paginate';
import { limitChars } from '../../../../utils/strings';
import { convertColorUser, descriptionStatusUser, descriptionTypeUser } from '../../../../utils/translate';
import ActionButtons from '../../DriverManagement/components/ActionButtons';
import Verify from '../../DriverManagement/components/Verify';

const DriversCard = ({ drivers, load, metadata, setMetadata, action, setAction }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Flex>
      <Grid
        w={'full'}
      >
        <List 
          display={'grid'}
          gridTemplateColumns={'20px 18px 130px 100px'}
          justifyContent={'space-between'}
          border="#70707036 solid 1px" 
          borderBottom="0"
          p={'8px 16px'}
        >
          <ListItem>{' '}</ListItem>
          <ListItem>{' '}</ListItem>
          <ListItem>
            <Text
              fontWeight={'bold'}
            >Nome</Text>
          </ListItem>
          <ListItem>
            <Text
              fontWeight={'bold'}
            >Ações</Text>
          </ListItem>
        </List>
        <Accordion>
          {!load &&
            drivers.length > 0 &&
            drivers.map(function (driver, key) {
              return (
                <AccordionItem key={key} style={{ '--delay': `0.${key}s` }} onClick={onToggle}
                  _odd={{ bg: "#eee" }}
                >
                  <AccordionButton
                    display={'grid'}
                    gridTemplateColumns={'20px 18px 130px 100px'}
                    justifyContent={'space-between'}
                    border={'#70707036 solid 1px'}
                    borderBottom={'0'}
                    borderTop={'0'}
                    py={'12px'}
                  >
                    <AccordionIcon />
                    <Tag 
                      type={convertColorUser(driver.status)}
                      h={'18px'}
                      w={'18px'}
                      borderRadius={'10px'}
                      p={'0'}
                    ></Tag>
                    <Text
                      color= '#707070'
                      textAlign={'left'}
                    >{limitChars(driver.name)}</Text>
                    <ActionButtons driver={driver} action={action} setAction={setAction} />
                  </AccordionButton>
                  <AccordionPanel
                    border={'#70707036 solid 1px'}
                    bg={'white'}
                    p={'16px'}
                  >
                    <Text
                      fontSize={'14px'}
                    ><strong>Tipo: </strong>{descriptionTypeUser(driver.type)}</Text>
                    <Text
                      display={'flex'}
                      gap={'10px'}
                      fontSize={'14px'}
                    ><strong>Verificado: </strong><Verify email={true} tell={true} /></Text>
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
        </Accordion>
      </Grid>
      {metadata.total_pages > 1 && (
        <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
      )}
    </Flex>
  );
};

export default DriversCard;
