import React from 'react';

import { Avatar, Box, Flex, Text } from '@chakra-ui/react';
import '../../../styles/globals.css';

import getProfile from '../../../services/profile';

const EditImage = ({ newPicture, setNewPicture, imageBlob, setImageBlob }) => {
  function handleInputImage(e) {
    const file = e.target.files[0];
    setNewPicture(file);
    setImageBlob(URL.createObjectURL(file));
  }

  return (
    <Flex direction="column">
      <Text textStyle="tableTitle" fontWeight="800">
        Foto de perfil
      </Text>

      <Flex alignItems="center" margin="10px 0 20px 0">
        <label htmlFor="image">
          <Avatar
            src={newPicture ? imageBlob : getProfile.tempPicture || getProfile.picture}
            name={getProfile.name}
            w={{ base: '45px', md: '120px' }}
            h={{ base: '45px', md: '120px' }}
            border="3px solid #422C76"
            cursor="pointer"
          />
        </label>

        <Flex direction="column" marginLeft="20px">
          <label htmlFor="image">
            <Box 
              className="button" 
              borderRadius="7px"
              minW={{ base: '220px', md: 'inherit' }} 
              display="flex"
              alignItems="center"
              justifyContent="center" 
              p={{ base: '10px', md: '16px' }}
            >
              Alterar Foto
            </Box>
          </label>

          <input id="image" name="image" type="file" accept=".png, .jpeg" onChange={handleInputImage} hidden={true} />
        </Flex>
      </Flex>

      <Text textStyle="subtitle" color="#0F0A1D4D">
        PNG ou JPEG com o mínimo de 200x200px de resolução
      </Text>
    </Flex>
  );
};

export default EditImage;
