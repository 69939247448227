import { Flex, Link, List, ListItem, Text, useBoolean } from '@chakra-ui/react';

import moneyFormat from '../../../../utils/currency/moneyFormat';
import { getFilter } from '../../../../utils/storageFilter';

const CategoryColumn = ({ fluxCashTableDataValue, detailsByStatus, loadDetailsByStatus, id, forceSetSearchPeriod }) => {
  const [toggleButton, setToggleButton] = useBoolean(false);

  //hover dos processos
  const handlerOnHoverEffect = (event) => {
    //adiciona o hover no processo selecionado
    const data = currentHoveredElements(event, 'on');

    //adiciona o hover nas colunas correspondentes ao processo
    currentHoveredNodes(`[role="list"] [data-process="${data.process}"]`, 'on', data.scope);
  };

  const handlerOutHoverEffect = (event) => {
    //adiciona o hover no processo selecionado
    const data = currentHoveredElements(event, 'out');

    //adiciona o hover nas colunas correspondentes ao processo
    currentHoveredNodes(`[role="list"] [data-process][data-hover="true"]`, 'out', data.scope);
  };

  const currentHoveredElements = (event, toogle) => {
    const changedElement = event.target;

    //recebe o numero do processo do item que voce passou o mouse e seu pai(lista <li>)
    const process = changedElement.textContent;
    const processList = changedElement.closest('li');
    const scope = changedElement.closest('[role="list"]');

    const data = { process, scope };

    //para cada item da lista de processos adiciona o efeito nos que possui o processo correspondente
    const fetchProcessHover = processList.querySelectorAll('p');
    fetchProcessHover.forEach((item) => {
      item.textContent === process && setHoverEffect(item, toogle);
    });

    return data;
  };

  const currentHoveredNodes = (searchElement, toggle, scope) => {
    const fetchNodeHover = scope.querySelectorAll(searchElement);

    fetchNodeHover.forEach((item) => {
      setHoverEffect(item, toggle);
    });
  };
  //hover dos processos

  const setHoverEffect = (event, toogle) => {
    const el = event?.target ? event.target : event;

    el.dataset.hover = toogle === 'on' ? true : false;

    return el;
  };

  //hover dos valores
  const handlerNodeOnHoverEffect = (event) => {
    const data = getProcessElementHoverEffect(event);

    setHoverEffect(data.process, 'on');
    setHoverEffect(event, 'on');
  };

  const handlerNodeOutHoverEffect = (event) => {
    const data = getProcessElementHoverEffect(event);

    setHoverEffect(data.process, 'out');
    setHoverEffect(event, 'out');
  };

  const getProcessElementHoverEffect = (event) => {
    const changedElement = event.target;

    const position = changedElement.dataset.position;
    const scope = changedElement.closest('[role="list"]');
    const colProcess = scope.querySelector('li');
    const fetchAllProcess = colProcess.querySelectorAll('p');

    const process = fetchAllProcess[position];

    const data = { process };

    return data;
  };

  const handlerOpenDetails = (event) => {
    setToggleButton.toggle();

    const slug = event.target.dataset.slug;
    const filters = getFilter();

    //Faz a busca apenas quando abrir o card
    if (!toggleButton) {
      loadDetailsByStatus(slug, filters, forceSetSearchPeriod);
    }
  };

  let CountblankSpaces = 0;

  return (
    fluxCashTableDataValue.data && (
      <Flex
        flexDirection={'column'}
        bg={toggleButton ? '#f6f6f6' : 'transparent'}
        boxShadow={toggleButton ? '1px 3px 5px #00000029' : '1px 3px 5px transparent'}
        border={toggleButton ? '1px solid #70707036' : '1px solid transparent'}
        borderRadius={'12px'}>
        <List
          display={'grid'}
          gridTemplateColumns={`repeat(8, 1fr)`}
          borderBottom={'1px solid #70707036'}
          alignItems={'center'}
          minH={'75px'}>
          <ListItem minW={'330px'} color={'primary'} p={'5px 0 5px 40px'} fontSize={'20px'} lineHeight={'26px'} fontWeight={'500'}>
            <Link
              onClick={(event) => handlerOpenDetails(event)}
              data-toggle-button={toggleButton}
              data-slug={fluxCashTableDataValue.data[id].slug}>
              {fluxCashTableDataValue.data[id].title}
            </Link>
          </ListItem>

          {fluxCashTableDataValue.data &&
            fluxCashTableDataValue.data[id].list.map((item, key) => (
              <ListItem
                key={key}
                display={'flex'}
                justifyContent={'space-between'}
                p={'5px 22px'}
                w={'full'}
                data-leftLineSwitch={toggleButton}>
                {item && (
                  <>
                    <Text as={'span'} lineHeight={'18px'} fontSize={'14px'} color={'#A885F9'} fontWeight={'500'}>
                      {'R$'}
                    </Text>
                    <Text as={'p'} lineHeight={'22px'} fontSize={'17px'} color={'titleColor'} fontWeight={'500'}>
                      {moneyFormat(item.value).replace('R$', '').trim()}
                    </Text>
                  </>
                )}
              </ListItem>
            ))}
        </List>
        {toggleButton && (
          <List display={'grid'} gridTemplateColumns={`repeat(8, 1fr)`}>
            <ListItem minW={'330px'}>
              {detailsByStatus.data &&
                detailsByStatus.data.map((listItem) => {
                  return listItem.list.length > 0 ? (
                    listItem.list.map((item, index) => (
                      <Text
                        key={index}
                        bg={'#EBEBEB'}
                        boxShadow={'0px 1px 3px #00000029'}
                        border={'1px solid #854FFF'}
                        borderRadius={'5px'}
                        fontSize={'15px'}
                        lineHeight={'25px'}
                        textAlign={'center'}
                        m={'6px 17px'}
                        color={'#854FFF'}
                        onMouseEnter={(event) => {
                          handlerOnHoverEffect(event);
                        }}
                        onMouseLeave={(event) => {
                          handlerOutHoverEffect(event);
                        }}>
                        {item.process}
                      </Text>
                    ))
                  ) : (
                    <Text
                      bg={'#EBEBEB'}
                      boxShadow={'0px 1px 3px #00000029'}
                      border={'1px solid #854FFF'}
                      borderRadius={'5px'}
                      fontSize={'15px'}
                      lineHeight={'25px'}
                      textAlign={'center'}
                      m={'6px 17px'}
                      color={'#854FFF'}>
                      Processo indisponivel
                    </Text>
                  );
                })}
            </ListItem>

            {detailsByStatus.data &&
              detailsByStatus.data.map((listItem, index) => {
                let blankSpaces = [];

                if (CountblankSpaces > 0) {
                  for (let index = 0; index < CountblankSpaces; index++) {
                    blankSpaces.push(
                      <Text m={'6px 17px'} minH={'27px'}>
                        &nbsp;
                      </Text>
                    );
                  }
                  CountblankSpaces = CountblankSpaces + (listItem.list.length ? listItem.list.length : 1);
                } else {
                  CountblankSpaces = CountblankSpaces + (listItem.list.length ? listItem.list.length : 1);
                }

                return (
                  <ListItem key={index}>
                    {blankSpaces}
                    {listItem.list.length > 0 ? (
                      listItem.list.map((item, i) => (
                        <Text
                          key={i}
                          data-process={item.process}
                          display={'flex'}
                          boxShadow={'0px 1px 3px #00000029'}
                          bg={'#EBEBEB'}
                          borderRadius={'5px'}
                          fontSize={'15px'}
                          minH={'27px'}
                          textAlign={'center'}
                          m={'6px 17px'}
                          color={'#854FFF'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          p={'3.5px 11px'}
                          data-position={i + blankSpaces.length}
                          /* onMouseEnter={(event) => {
                            handlerNodeOnHoverEffect(event);
                          }}
                          onMouseLeave={(event) => {
                            handlerNodeOutHoverEffect(event);
                          }} */>
                          <Text as={'span'} lineHeight={'20px'} fontSize={'15px'} color={'linkColor'} fontWeight={'500'}>
                            {'R$'}
                          </Text>
                          <Text as={'span'} lineHeight={'20px'} fontSize={'15px'} color={'linkColor'} fontWeight={'500'}>
                            {moneyFormat(item.value).replace('R$', '').trim()}
                          </Text>
                        </Text>
                      ))
                    ) : (
                      <Text
                        display={'flex'}
                        boxShadow={'0px 1px 3px #00000029'}
                        bg={'#EBEBEB'}
                        borderRadius={'5px'}
                        fontSize={'15px'}
                        minH={'27px'}
                        textAlign={'center'}
                        m={'6px 17px'}
                        color={'#854FFF'}
                        justifyContent={'space-around'}
                        alignItems={'center'}
                        p={'3.5px 11px'}>
                        <Text
                          as={'span'}
                          lineHeight={'20px'}
                          fontSize={'15px'}
                          color={'linkColor'}
                          fontWeight={'500'}
                          textAlign={'center'}>
                          dados indisponíveis
                        </Text>
                      </Text>
                    )}
                  </ListItem>
                );
              })}
          </List>
        )}
      </Flex>
    )
  );
};

export default CategoryColumn;
