import React, { useEffect, useState } from 'react';

import { ErrorMessage } from 'formik';
import { MdAdd, MdDelete, MdHelp } from 'react-icons/md';
import Select from 'react-select';

import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Radio,
  TabPanel,
  Text,
  Tooltip
} from '@chakra-ui/react';

import InputCurrency from '../../../../components/Form/Input/InputCurrency';
import InputDefault from '../../../../components/Form/Input/InputDefault';
import { InputPercentage } from '../../../../components/Form/Input/InputPercentage';
import { LabelDefault } from '../../../../components/Form/Label/LabelDefault';
import requests from '../../../../services/requests';
import { handleAddClick, handleInputChange, handleRemoveChange } from '../Helpers/inputMultiple';

const OperationalDataForm = ({ values, setFieldValue, handleChange, setIsSecondaryZone, isPreviwer }) => {
  const [inputNcmsRelations, setInputNcmsRelations] = useState([]);
  const [ncmOptions, setNcmOptions] = useState([]);
  const [isLoadingNcmOptions, setIsLoadingNcmOptions] = useState(false);
  const [noNcmOptionsMessage, setNoNcmOptionsMessage] = useState('Nenhum resultado encontrado');
  const [inputExchanges, setInputExchanges] = useState([]);
  const [percentExchanges, setPercentExchanges] = useState(0);

  const selectCustomStyles = () => ({
    control: (provided) => ({
      ...provided,
      backgroundColor: '#F2F2F2',
    }),
  });

  const productTypeDescriptionData = [
    {
      label: 'Sim',
      value: true,
    },
    {
      label: 'Não',
      value: false,
    },
    {
      label: 'Ambos',
      value: 'both',
    },
  ];

  const useSystemBravos = [
    {
      label: 'Não',
      value: false,
    },
    {
      label: 'Sim',
      value: true,
    },
  ];

  const fowardingAgentData = [
    {
      label: 'Visão Aduaneira',
      value: 'VISÃO_ADUANEIRA',
    },
    {
      label: 'Nelson Heusi',
      value: 'NELSON_HEUSI',
    },
    {
      label: 'Outros',
      value: 'OUTROS',
    },
  ];

  const importingLicenseConsentingEntityData = [
    {
      label: 'ANCINE',
      value: 'ANCINE',
    },
    {
      label: 'ANEEL',
      value: 'ANEEL',
    },
    {
      label: 'ANP',
      value: 'ANP',
    },
    {
      label: 'CNEN',
      value: 'CNEN',
    },
    {
      label: 'CNPQ',
      value: 'CNPQ',
    },
    {
      label: 'COMEXE',
      value: 'COMEXE',
    },
    {
      label: 'DECEX',
      value: 'DECEX',
    },
    {
      label: 'DNPM',
      value: 'DNPM',
    },
    {
      label: 'DPF',
      value: 'DPF',
    },
    {
      label: 'EBC',
      value: 'EBC',
    },
    {
      label: 'IBAMA',
      value: 'IBAMA',
    },
    {
      label: 'INMETRO',
      value: 'INMETRO',
    },
    {
      label: 'MAPA',
      value: 'MAPA',
    },
    {
      label: 'MCTIC',
      value: 'MCTIC',
    },
    {
      label: 'MD',
      value: 'MD',
    },
    {
      label: 'SUFRAMA',
      value: 'SUFRAMA',
    },
    {
      label: 'NÃO SE APLICA',
      value: 'NÃO_SE_APLICA',
    },
  ];

  const operationNatureData = [
    {
      label: 'Revenda',
      value: 'REVENDA',
    },
    {
      label: 'Consumo',
      value: 'CONSUMO',
    },
    {
      label: 'Revenda e Consumo',
      value: 'REVENDA_E_CONSUMO',
    },
  ];

  const incotermData = [
    {
      label: 'EXW – Ex Works – Na Origem (local de entrega nomeado)',
      value: 'EXW',
    },
    {
      label: 'FCA – Free Carrier – Livre No Transportador (local de entrega nomeado)',
      value: 'FCA',
    },
    {
      label: 'FAS – Free Alongside Ship – Livre Ao Lado Do Navio (porto de embarque nomeado)',
      value: 'FAS',
    },
    {
      label: 'FOB – Free On Board – Livre A Bordo (porto de embarque nomeado)',
      value: 'FOB',
    },
    {
      label: 'CPT – Carriage Paid To – Transporte Pago Até (local de destino nomeado)',
      value: 'CPT',
    },
    {
      label: 'CIP – Carriage And Insurance Paid To – Transporte E Seguro Pagos Até (local de destino nomeado)',
      value: 'CIP',
    },
    {
      label: 'CFR – Cost And Freight – Custo E Frete (porto de destino nomeado)',
      value: 'CFR',
    },
    {
      label: 'CIF – Cost Insurance And Freight – Custo, Seguro E Frete (porto de destino nomeado)',
      value: 'CIF',
    },
    {
      label: 'DAP – Delivered At Place – Entregue No Local (local de destino nomeado)',
      value: 'DAP',
    },
    {
      label: 'DPU – Delivered At Place Unloaded – Entregue No Local Desembarcado (Local de destino nomeado)',
      value: 'DPU',
    },
    {
      label: 'DDP – Delivered Duty Paid – Entregue Com Direitos Pagos (local de destino nomeado)',
      value: 'DDP',
    },
  ];

  const transportModalityData = [
    {
      label: 'Aéreo',
      value: 'AEREO',
    },
    {
      label: 'Marítimo',
      value: 'MARITIMO',
    },
    {
      label: 'Rodoviário',
      value: 'RODOVIARIO',
    },
  ];

  const goodsEntranceFederativeUnitData = [
    {
      label: 'ACRE',
      value: 'AC',
    },
    {
      label: 'ALAGOAS',
      value: 'AL',
    },
    {
      label: 'AMAPÁ',
      value: 'AP',
    },
    {
      label: 'AMAZONAS',
      value: 'AM',
    },
    {
      label: 'BAHIA',
      value: 'BA',
    },
    {
      label: 'CEARÁ',
      value: 'CE',
    },
    {
      label: 'DISTRITO FEDERAL',
      value: 'DF',
    },
    {
      label: 'ESPÍRITO SANTO',
      value: 'ES',
    },
    {
      label: 'GOIÁS',
      value: 'GO',
    },
    {
      label: 'MARANHÃO',
      value: 'MA',
    },
    {
      label: 'MATO GROSSO',
      value: 'MT',
    },
    {
      label: 'MATO GROSSO DO SUL',
      value: 'MS',
    },
    {
      label: 'MINAS GERAIS',
      value: 'MG',
    },
    {
      label: 'PARÁ',
      value: 'PA',
    },
    {
      label: 'PARAÍBA',
      value: 'PB',
    },
    {
      label: 'PARANÁ',
      value: 'PR',
    },
    {
      label: 'PERNAMBUCO',
      value: 'PE',
    },
    {
      label: 'PIAUÍ',
      value: 'PI',
    },
    {
      label: 'RIO DE JANEIRO',
      value: 'RJ',
    },
    {
      label: 'RIO GRANDE DO NORTE',
      value: 'RN',
    },
    {
      label: 'RIO GRANDE DO SUL',
      value: 'RS',
    },
    {
      label: 'RONDÔNIA',
      value: 'RO',
    },
    {
      label: 'RORAIMA',
      value: 'RR',
    },
    {
      label: 'SANTA CATARINA',
      value: 'SC',
    },
    {
      label: 'SÃO PAULO',
      value: 'SP',
    },
    {
      label: 'SERGIPE',
      value: 'SE',
    },
    {
      label: 'TOCANTINS',
      value: 'TO',
    },
  ];

  const goodsEntranceFederativeUnitModalityData = [
    {
      label: 'DTA',
      value: 'DTA',
    },
    {
      label: 'Cabotagem',
      value: 'CABOTAGEM',
    },
    {
      label: 'N/A',
      value: 'N_A',
    },
  ];

  const customsClearanceLocationData = [
    {
      label: 'Porto',
      value: 'PORTO',
    },
    {
      label: 'Aeroporto',
      value: 'AEROPORTO',
    },
    {
      label: 'Fronteira',
      value: 'FRONTEIRA',
    },
    {
      label: 'Zona Secundária',
      value: 'ZONA_SECUNDARIA',
    },
    {
      label: 'Desova',
      value: 'DESOVA',
    },
  ];

  const transportData = [
    {
      label: 'Próprio',
      value: 'PROPRIO',
    },
    {
      label: 'Terceiro',
      value: 'TERCEIRO',
    },
  ];

  const storageAfterBillingData = [
    {
      label: 'Armazém VDM Santa Catarina',
      value: 'ARMAZEM_SANTA_CATARINA',
    },
    {
      label: 'Armazém VDM Itapevi',
      value: 'ARMAZEM_ITAPEVI',
    },
    {
      label: 'Cliente',
      value: 'CLIENTE',
    },
  ];

  const nationalFreightModalityData = [
    {
      label: 'Vendemmia Transporte',
      value: 'VENDEMMIA_TRANSPORTE',
    },
    {
      label: 'Outros',
      value: 'OUTROS',
    },
  ];

  const nationalFreightBillingData = [
    {
      label: 'Vendemmia',
      value: 'VENDEMMIA',
    },
    {
      label: 'Cliente',
      value: 'CLIENTE',
    },
  ];

  const cashFlowData = [
    {
      label: 'Não se aplica',
      value: false,
    },
    {
      label: 'Sim',
      value: true,
    },
  ];

  const listOperationNcmsAction = (code = '') => {
    if (code?.length < 3) {
      setNoNcmOptionsMessage('Insira ao menos 4 digítos para fazer a busca');
      return;
    }

    if (code?.length > 3) {
      setNoNcmOptionsMessage('Carregando');
      setIsLoadingNcmOptions(true);

      requests
        .listNcmsAction(code)
        .then((response) => {
          let result = [];
          response?.data?.length &&
            response.data.forEach((ncm) => {
              result.push({
                value: ncm.code,
                label: ncm.code + ' ' + ncm.description,
              });
            });

          setNcmOptions(result);
          setIsLoadingNcmOptions(false);
          setNoNcmOptionsMessage('Nenhum resultado encontrado');
        })
        .catch((error) => {
          toast.error('Ocorreu um erro ao trazer os ncms');
        });
    }
  };

  const handlingPercentExchange = (value) => {
    let totalPercent = 0;
    value.map((exchange, key) => {
      if (exchange.percent !== undefined) {
        totalPercent += parseFloat(exchange.percent.replace('%', '').replaceAll(',', '.'));
      }
    });

    setPercentExchanges(totalPercent);
  };

  useEffect(() => {
    if (values && values.operational && values.operational.exchanges && values.operational.exchanges.length > 0) {
      setInputExchanges(values.operational.exchanges);
      handlingPercentExchange(values.operational.exchanges);
    }

    if (values && values.operational && values.operational.ncmRelations && values.operational.ncmRelations.length > 0) {
      setInputNcmsRelations(values.operational.ncmRelations);
    }
  }, [values]);

  return (
    <TabPanel p="30px" display="flex" flexDirection="column" gap="10px">
      <Flex direction="column">
        <FormLabel>Despachante</FormLabel>

        <Grid templateRows="auto 1fr" gap={5}>
          <Grid templateColumns="repeat(5, 1fr)" gap={10}>
            <FormControl>
              <LabelDefault text="Despachante" />
              <Select
                placeholder="Selecione o despachante"
                name="operational.forwardingAgent"
                className="outline-none transform-none text-black"
                styles={selectCustomStyles()}
                isDisabled={isPreviwer}
                options={fowardingAgentData}
                value={fowardingAgentData.find((selected) => selected.label === values.operational.forwardingAgent.label)}
                onChange={(option) => setFieldValue('operational.forwardingAgent', option)}
              />

              <ErrorMessage component="p" className="error-message-error" name="operational.forwardingAgent" />
            </FormControl>

            <FormControl>
              <LabelDefault text="Valor do despacho" />
              <InputCurrency
                name="operational.dispatchValue"
                placeholder="R$0,00"
                id="dispatchValue"
                isDisabled={isPreviwer}
                value={values.operational.dispatchValue}
                setFieldValue={setFieldValue}
              />
              <ErrorMessage component="p" className="error-message-error" name="operational.dispatchValue" />
            </FormControl>

            <FormControl>
              <LabelDefault text="Tipo de produto" />
              <InputDefault
                placeholder="Preencha o tipo de produto"
                name="operational.productTypeDescription"
                id="productTypeDescription"
                isDisabled={isPreviwer}
                value={values.operational.productTypeDescription}
                onChange={handleChange}
              />
              <ErrorMessage component="p" className="error-message-error" name="operational.productTypeDescription" />
            </FormControl>

            <FormControl>
              <LabelDefault text="Antidumping" />
              <Select
                placeholder="Selecione uma opção"
                name="operational.isAntidumping"
                styles={selectCustomStyles()}
                isDisabled={isPreviwer}
                options={productTypeDescriptionData}
                value={productTypeDescriptionData.find((selected) => selected.value === values.operational.isAntidumping.value)}
                onChange={(option) => setFieldValue('operational.isAntidumping', option)}
              />

              <ErrorMessage component="p" className="error-message-error" name="operational.isAntidumping" />
            </FormControl>

            <FormControl>
              <LabelDefault text="Usa Bravus?" />
              <Select
                placeholder="Selecione"
                name="operational.useSystemBravos"
                styles={selectCustomStyles()}
                isDisabled={isPreviwer}
                options={useSystemBravos}
                value={useSystemBravos.find((selected) => selected.label === values.operational.useSystemBravos.label)}
                onChange={(option) => setFieldValue('operational.useSystemBravos', option)}
              />

              <ErrorMessage component="p" className="error-message-error" name="operational.useSystemBravos" />
            </FormControl>
          </Grid>

          <FormControl colSpan={4}>
            <LabelDefault text="Comentários adicionais (opcional)" />
            <InputDefault
              as="textarea"
              minH="120px"
              pt="10px"
              name="operational.forwardingAgentAdditionalInformation"
              id="forwardingAgentAdditionalInformation"
              styles={selectCustomStyles()}
              isDisabled={isPreviwer}
              onChange={handleChange}
              value={values.operational.forwardingAgentAdditionalInformation}
            />
          </FormControl>
        </Grid>
      </Flex>

      <Divider borderColor="#70707033" />

      <Flex direction="column">
        <FormLabel>Emissão de LI</FormLabel>

        <Grid templateColumns="repeat(2, 1fr)" gap={10}>
          <Grid templateColumns="repeat(2, 1fr)" gap={10}>
            <FormControl>
              <LabelDefault text="Orgão de anuência" />

              <Select
                placeholder="Selecione o orgão de anuência"
                isDisabled={isPreviwer}
                isMulti
                styles={selectCustomStyles()}
                options={importingLicenseConsentingEntityData}
                value={values.operational.importingLicenseConsentingEntity}
                onChange={(option) => setFieldValue('operational.importingLicenseConsentingEntity', option)}
              />
              <ErrorMessage component="p" className="error-message-error" name="operational.importingLicenseConsentingEntity" />
            </FormControl>

            <FormControl>
              <LabelDefault text="Valor de emissão de LI" />
              <InputCurrency
                placeholder="R$0,00"
                isDisabled={isPreviwer}
                name="operational.importingLicenseValue"
                id="importingLicenseValue"
                value={values.operational.importingLicenseValue}
                setFieldValue={setFieldValue}
              />
              <ErrorMessage component="p" className="error-message-error" name="operational.importingLicenseValue" />
            </FormControl>
          </Grid>

          <FormControl colSpan={4}>
            <LabelDefault
              text={
                <>
                  Comentários adicionais <small>(opcional)</small>
                </>
              }
            />
            <InputDefault
              styles={selectCustomStyles()}
              as="textarea"
              minH="120px"
              pt="10px"
              // placeholder="Comentários adicionais (opcional)"
              isDisabled={isPreviwer}
              name="operational.importingLicenseAdditionalInformation"
              id="importingLicenseAdditionalInformation"
              onChange={handleChange}
              value={values.operational.importingLicenseAdditionalInformation}
            />
          </FormControl>
        </Grid>
      </Flex>

      <Divider borderColor="#70707033" />

      <Flex direction="column">
        <FormLabel>Operação</FormLabel>

        <FormControl>
          <LabelDefault
            text="Relação dos NCM's"
            style={{display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end', gap: '10px'}}>
            <Tooltip label="Tributação será aplicada conforme Legislação" placement="auto-start">
              <Box>
                <MdHelp color="#422C76" />
              </Box>
            </Tooltip>
          </LabelDefault>
          <Select
            id="operational.ncmRelations"
            name="operational.ncmRelations"
            className="outline-none transform-none text-black"
            placeholder="Digite os quatro primeiros digitos do código NCM. ex: 0101"
            styles={selectCustomStyles()}
            value=""
            options={ncmOptions}
            isDisabled={isPreviwer}
            onChange={(option) => {
              setFieldValue('operational.ncmRelations', [
                ...inputNcmsRelations,
                {
                  id: option.value,
                  description: option.label,
                  tariff: '',
                },
              ]);
              setInputNcmsRelations([
                ...inputNcmsRelations,
                {
                  id: option.value,
                  description: option.label,
                  tariff: '',
                },
              ]);
              setNcmOptions([]);
            }}
            onInputChange={(inputValue) => {
              listOperationNcmsAction(inputValue);
            }}
            noOptionsMessage={() => noNcmOptionsMessage}
            isLoading={isLoadingNcmOptions}
          />

          <ErrorMessage component="p" className="error-message-error" name="operational.ncmRelations" />
        </FormControl>

        <Flex mt="5px">
          <i>
            {"NCM's"} selecionados ({inputNcmsRelations?.length})
          </i>
        </Flex>

        {inputNcmsRelations?.length > 0 &&
          inputNcmsRelations.map((ncm, index) => (
            <Grid
              key={index}
              templateColumns="repeat(6, 1fr)"
              alignItems="center"
              gap="20px"
              border="1px"
              bgColor="#70707033"
              borderColor="#70707033"
              p="10px"
              mt="10px">
              <GridItem colSpan={3}>
                {ncm?.description?.length > 250 ? (
                  <Text>
                    {ncm.description.substring(0, 250)}
                    <Tooltip label={ncm.description}>[...]</Tooltip>
                  </Text>
                ) : (
                  <p className="mt-5">{ncm.description}</p>
                )}
              </GridItem>

              <GridItem colSpan={2}>
                <InputDefault
                  name={`ncmRelations[${index}].tariff`}
                  id={`ncmRelations[${index}].tariff`}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      setFieldValue,
                      values.operational,
                      e.target.value,
                      'ncmRelations',
                      'tariff',
                      'operational'
                    )
                  }
                  value={values.operational.ncmRelations[index]?.tariff}
                  placeholder="Preencha o EX tarifário ou deixe em branco"
                  isDisabled={isPreviwer}
                />
              </GridItem>

              <Button
                textAlign="end"
                w="min-content"
                isDisabled={isPreviwer}
                onClick={() =>
                  handleRemoveChange(
                    setFieldValue,
                    index,
                    values.operational.ncmRelations,
                    'ncmRelations',
                    inputNcmsRelations,
                    setInputNcmsRelations,
                    'operational'
                  )
                }>
                <MdDelete color="#E52359" />
              </Button>
            </Grid>
          ))}

        <Grid templateColumns="repeat(3, 1fr)" gap={10} mt="20px">
          <FormControl>
            <LabelDefault text="Natureza de operação" />
            <Select
              styles={selectCustomStyles()}
              name="operational.operationNature"
              className="outline-none transform-none text-black"
              placeholder="Selecione a natureza de operação"
              isMulti
              isDisabled={isPreviwer}
              options={operationNatureData}
              value={values.operational.operationNature}
              onChange={(option) => setFieldValue('operational.operationNature', option)}
            />

            <ErrorMessage component="p" className="error-message-error" name="operational.operationNature" />
          </FormControl>

          <FormControl>
            <LabelDefault text="Incoterm" />
            <Select
              styles={selectCustomStyles()}
              options={incotermData}
              name="operational.incoterm"
              className="outline-none transform-none text-black"
              placeholder="Selecione o Incoterm"
              isMulti
              isDisabled={isPreviwer}
              value={values.operational.incoterm}
              onChange={(option) => setFieldValue('operational.incoterm', option)}
            />

            <ErrorMessage component="p" className="error-message-error" name="operational.incoterm" />
          </FormControl>

          <FormControl>
            <LabelDefault
              text={
                <>
                  Comentários adicionais <small>(opcional)</small>
                </>
              }
            />
            <InputDefault
              as="textarea"
              minH="120px"
              pt="10px"
              isDisabled={isPreviwer}
              styles={selectCustomStyles()}
              name="operational.incotermAdditionalInformation"
              id="incotermAdditionalInformation"
              onChange={handleChange}
              value={values.operational.incotermAdditionalInformation}
            />
          </FormControl>
        </Grid>
      </Flex>

      <Divider borderColor="#70707033" />

      <Flex direction="column">
        <FormLabel>Transporte</FormLabel>

        <Grid templateColumns="repeat(4, 1fr)" gap={10}>
          <FormControl display="flex" flexDirection="column" gap="10px" bgColor="#F2F2F2" borderRadius="10px" p="20px">
            <LabelDefault text="Transporte Internacional (cotação)" />
            <Flex direction="column">
              <Radio
                color="primary !important"
                w="min-content"
                id="hasInternationalTransportQuote"
                name="operational.hasInternationalTransportQuote"
                isDisabled={isPreviwer}
                onChange={handleChange}
                value={true}
                isChecked={
                  values.operational.hasInternationalTransportQuote === 'true' ||
                  values.operational.hasInternationalTransportQuote === true
                    ? true
                    : false
                }>
                Sim
              </Radio>

              <Radio
                color="primary !important"
                w="min-content"
                id="hasInternationalTransportQuote"
                name="operational.hasInternationalTransportQuote"
                isDisabled={isPreviwer}
                onChange={handleChange}
                value={false}
                isChecked={
                  values.operational.hasInternationalTransportQuote === 'false' ||
                  values.operational.hasInternationalTransportQuote === false
                    ? true
                    : false
                }>
                Não
              </Radio>
            </Flex>

            <ErrorMessage component="p" className="error-message-error" name="operational.hasInternationalTransportQuote" />
          </FormControl>

          <FormControl>
            <LabelDefault text="Modal" />
            <Select
              placeholder="Selecione o tipo de transporte"
              isDisabled={isPreviwer}
              name="operational.transportModality"
              isMulti
              styles={selectCustomStyles()}
              options={transportModalityData}
              value={values.operational.transportModality}
              onChange={(option) => setFieldValue('operational.transportModality', option)}
            />

            <ErrorMessage component="p" className="error-message-error" name="operational.transportModality" />
          </FormControl>

          <FormControl>
            <LabelDefault text="UF de Entrada da Mercadoria" />
            <Select
              placeholder="Selecione a UF"
              isDisabled={isPreviwer}
              name="operational.goodsEntranceFederativeUnit"
              isMulti
              styles={selectCustomStyles()}
              options={goodsEntranceFederativeUnitData}
              value={values.operational.goodsEntranceFederativeUnit}
              onChange={(option) => setFieldValue('operational.goodsEntranceFederativeUnit', option)}
            />

            <ErrorMessage component="p" className="error-message-error" name="operational.goodsEntranceFederativeUnit" />
          </FormControl>

          <FormControl display="flex" flexDirection="column" gap="10px" bgColor="#F2F2F2" borderRadius="10px" p="20px">
            <LabelDefault text="Informações adicionais da UF de entrada" />
            <Flex direction="column">
              {goodsEntranceFederativeUnitModalityData.map((item, index) => (
                <React.Fragment key={index}>
                  <Checkbox
                    iconColor="primary"
                    w="fit-content"
                    name="operational.goodsEntranceFederativeUnitModality"
                    isDisabled={isPreviwer}
                    onChange={handleChange}
                    value={item.label}
                    isChecked={values.operational.goodsEntranceFederativeUnitModality.includes(item.label)}>
                    {item.label}
                  </Checkbox>
                </React.Fragment>
              ))}
            </Flex>

            <ErrorMessage component="p" className="error-message-error" name="operational.goodsEntranceFederativeUnitModality" />
          </FormControl>
        </Grid>

        <FormControl colSpan={4} mt="20px">
          <LabelDefault
            text={
              <>
                Observações <small>(opcional)</small>
              </>
            }
          />
          <InputDefault
            as="textarea"
            minH="120px"
            pt="10px"
            name="operational.goodsEntranceFederativeAdditionalInformation"
            id="goodsEntranceFederativeAdditionalInformation"
            styles={selectCustomStyles()}
            isDisabled={isPreviwer}
            onChange={handleChange}
            value={values.operational.goodsEntranceFederativeAdditionalInformation}
          />
        </FormControl>
      </Flex>

      <Divider borderColor="#70707033" />

      <Flex direction="column">
        <FormLabel>Desembaraço</FormLabel>

        <Grid templateColumns="repeat(2, 1fr)" gap={10}>
          <Flex direction="column" gap="20px">
            <FormControl>
              <LabelDefault text="Desembaraço" />
              <Select
                placeholder="Selecione o desembaraço"
                isDisabled={isPreviwer}
                name="operational.customsClearanceLocation"
                styles={selectCustomStyles()}
                options={customsClearanceLocationData}
                value={values.operational.customsClearanceLocation}
                onChange={(option) => {
                  setFieldValue('operational.customsClearanceLocation', option);
                  if (option.value === 'ZONA_SECUNDARIA') {
                    setIsSecondaryZone(true);
                  } else {
                    setIsSecondaryZone(false);
                    setFieldValue('operational.transportType', '');
                  }
                }}
              />

              <ErrorMessage component="p" className="error-message-error" name="operational.customsClearanceLocation" />
            </FormControl>

            {values.operational.customsClearanceLocation.value === 'ZONA_SECUNDARIA' && (
              <FormControl bgColor="#F2F2F2" borderRadius="10px" p="20px">
                <LabelDefault text="Transporte" />

                <Flex direction="column">
                  {transportData.map((item, index) => (
                    <Flex direction="column" key={index}>
                      <Checkbox
                        iconColor="primary"
                        w="fit-content"
                        name="operational.transportType"
                        isDisabled={isPreviwer}
                        onChange={handleChange}
                        value={item.label}
                        isChecked={values.operational.transportType.includes(item.label)}>
                        {item.label}
                      </Checkbox>
                    </Flex>
                  ))}
                </Flex>

                <ErrorMessage component="p" className="error-message-error" name="operational.transportType" />
              </FormControl>
            )}
          </Flex>

          <FormControl colSpan={4}>
            <LabelDefault
              text={
                <>
                  Comentários adicionais <small>(opcional)</small>
                </>
              }
            />
            <InputDefault
              isDisabled={isPreviwer}
              as="textarea"
              minH="120px"
              pt="10px"
              name="operational.customsClearanceLocationAdditionalInformation"
              id="customsClearanceLocationAdditionalInformation"
              styles={selectCustomStyles()}
              onChange={handleChange}
              value={values.operational.customsClearanceLocationAdditionalInformation}
            />
          </FormControl>
        </Grid>
      </Flex>

      <Divider borderColor="#70707033" />

      <Flex direction="column">
        <FormLabel alignItems="flex-start" gap="10px" display="flex">
          Seguro
          <Tooltip
            label="Base de Calculo Seguro: FOB + Frete Internacional  + 10% Lucros esperados + 10% Despesas + Impostos da DI"
            placement="auto-start">
            <Box>
              <MdHelp color="#422C76" />
            </Box>
          </Tooltip>
        </FormLabel>
        <Grid templateColumns="repeat(2, 1fr)" gap={10}>
          <Grid templateColumns="repeat(2, 1fr)" gap={10}>
            <FormControl>
              <LabelDefault text="Base de Cálculo - Vendemmia" />
              <InputPercentage
                placeholder="%"
                isDisabled={isPreviwer}
                name="operational.insuranceCalculationBasisVendemmia"
                id="insuranceCalculationBasisVendemmia"
                value={values.operational.insuranceCalculationBasisVendemmia}
                setFieldValue={setFieldValue}
              />

              <ErrorMessage component="p" className="error-message-error" name={`operational.insuranceCalculationBasisVendemmia`} />
            </FormControl>

            <FormControl>
              <LabelDefault text="Base de Cálculo - Cliente" />
              <InputPercentage
                placeholder="%"
                isDisabled={isPreviwer}
                name="operational.insuranceCalculationBasisClient"
                id="insuranceCalculationBasisClient"
                value={values.operational.insuranceCalculationBasisClient}
                setFieldValue={setFieldValue}
              />

              <ErrorMessage component="p" className="error-message-error" name={`operational.insuranceCalculationBasisClient`} />
            </FormControl>
          </Grid>

          <FormControl colSpan={4}>
            <LabelDefault
              text={
                <>
                  Comentários adicionais <small>(opcional)</small>
                </>
              }
            />
            <InputDefault
              // placeholder="Observações (opcional)"
              isDisabled={isPreviwer}
              as="textarea"
              minH="120px"
              pt="10px"
              name="operational.insuranceAdditionalInformation"
              id="insuranceAdditionalInformation"
              styles={selectCustomStyles()}
              onChange={handleChange}
              value={values.operational.insuranceAdditionalInformation}
            />
          </FormControl>
        </Grid>
      </Flex>

      <Divider borderColor="#70707033" />

      <Flex direction="column">
        <FormLabel>Armazém</FormLabel>

        <Grid templateColumns="repeat(2, 1fr)" gap={10}>
          <FormControl display="flex" flexDirection="column" gap="10px" bgColor="#F2F2F2" borderRadius="10px" p="20px" w="fit-content">
            <LabelDefault text="Armazenagem após faturamento" />
            <Flex direction="column">
              {storageAfterBillingData.map((item, index) => (
                <Flex direction="column" key={index}>
                  <Checkbox
                    iconColor="primary"
                    w="fit-content"
                    name="operational.storageAfterBilling"
                    isDisabled={isPreviwer}
                    onChange={handleChange}
                    value={item.label}
                    isChecked={values.operational.storageAfterBilling.includes(item.label)}>
                    {item.label}
                  </Checkbox>
                </Flex>
              ))}
            </Flex>

            <ErrorMessage component="p" className="error-message-error" name="operational.storageAfterBilling" />
          </FormControl>

          <FormControl colSpan={4}>
            <LabelDefault
              text={
                <>
                  Comentários adicionais <small>(opcional)</small>
                </>
              }
            />
            <InputDefault
              // placeholder="Observações (opcional)"
              isDisabled={isPreviwer}
              as="textarea"
              minH="120px"
              pt="10px"
              name="operational.storageAfterBillingAdditionalInformation"
              id="storageAfterBillingAdditionalInformation"
              styles={selectCustomStyles()}
              onChange={handleChange}
              value={values.operational.storageAfterBillingAdditionalInformation}
            />
          </FormControl>
        </Grid>
      </Flex>

      <Divider borderColor="#70707033" />

      <Flex direction="column">
        <FormLabel>Transporte Nacional</FormLabel>

        <Grid templateColumns="repeat(2, 1fr)" gap={10}>
          <Grid templateColumns="repeat(2, 1fr)" gap={10}>
            <FormControl display="flex" flexDirection="column" gap="10px" bgColor="#F2F2F2" borderRadius="10px" p="20px">
              <LabelDefault text="Transporte Nacional - Tipo" />
              <Flex direction="column">
                {nationalFreightModalityData.map((item, index) => (
                  <Flex direction="column" key={index}>
                    <Checkbox
                      isDisabled={isPreviwer}
                      iconColor="primary"
                      w="fit-content"
                      onChange={handleChange}
                      value={item.label}
                      name="operational.nationalFreightModality"
                      isChecked={values.operational.nationalFreightModality.includes(item.label)}>
                      {item.label}
                    </Checkbox>
                  </Flex>
                ))}
              </Flex>

              <ErrorMessage component="p" className="error-message-error" name="operational.nationalFreightModality" />
            </FormControl>

            <FormControl display="flex" flexDirection="column" gap="10px" bgColor="#F2F2F2" borderRadius="10px" p="20px">
              <LabelDefault text="Transporte Nacional - Faturamento" />
              <Flex direction="column">
                {nationalFreightBillingData.map((item, index) => (
                  <Flex direction="column" key={index}>
                    <Checkbox
                      isDisabled={isPreviwer}
                      iconColor="primary"
                      w="fit-content"
                      name="operational.nationalFreightBilling"
                      onChange={handleChange}
                      value={item.label}
                      isChecked={values.operational.nationalFreightBilling.includes(item.label)}>
                      {item.label}
                    </Checkbox>
                  </Flex>
                ))}
              </Flex>

              <ErrorMessage component="p" className="error-message-error" name="operational.nationalFreightBilling" />
            </FormControl>
          </Grid>

          <FormControl>
            <LabelDefault
              text={
                <>
                  Comentários adicionais <small>(opcional)</small>
                </>
              }
            />
            <InputDefault
              // placeholder="Observações (opcional)"
              isDisabled={isPreviwer}
              as="textarea"
              minH="120px"
              pt="10px"
              name="operational.nationalFreightAdditionalInformation"
              id="nationalFreightAdditionalInformation"
              styles={selectCustomStyles()}
              onChange={handleChange}
              value={values.operational.nationalFreightAdditionalInformation}
            />
          </FormControl>
        </Grid>
      </Flex>

      <Divider borderColor="#70707033" />

      <Flex direction="column">
        <FormLabel>Custos do processo (fluxo de caixa)</FormLabel>

        <Grid templateColumns="repeat(2, 1fr)" gap={5}>
          <FormControl>
            <LabelDefault text="Aplicar fluxo de caixa?" />
            <Select
              placeholder="Aplicar fluxo de caixa?"
              isDisabled={isPreviwer}
              name="operational.hasCashFlow"
              styles={selectCustomStyles()}
              options={cashFlowData}
              value={cashFlowData.find(({ value }) => value === values.operational.hasCashFlow)}
              onChange={(options) => {
                setFieldValue('operational.hasCashFlow', options.value);

                if (options.value === false) {
                  setFieldValue('operational.administrativeFee', '');
                  setFieldValue('operational.nationalizationFactor', '');
                  setFieldValue('operational.nationalizationDays', '');
                  setFieldValue('operational.expensesFactor', '');
                  setFieldValue('operational.expensesDays', '');
                  setFieldValue('operational.billingFactor', '');
                  setFieldValue('operational.billingDays', '');
                  setFieldValue('operational.exchanges.value', '');
                }
              }}
            />
          </FormControl>
          {values.operational.hasCashFlow === true && (
            <Flex direction="column">
              <LabelDefault text="Fator do faturamento sobre o câmbio" />
              <InputDefault
                type="number"
                name="operational.billingFactor"
                id="operational.billingFactor"
                onChange={handleChange}
                value={values.operational.billingFactor}
                placeholder="Preencha o fator"
                isDisabled={isPreviwer}
              />

              <ErrorMessage component="p" className="error-message-error" name="operational.billingFactor" />
            </Flex>
          )}
        </Grid>

        {values.operational.hasCashFlow === true && (
          <>
            <Grid templateColumns="repeat(2, 1fr)" gap={5} mt="20px">
              <Flex direction="column">
                <LabelDefault text="Fator nacionalização sobre o câmbio" />
                <InputDefault
                  type="number"
                  placeholder="Preencha o fator"
                  isDisabled={isPreviwer}
                  name="operational.nationalizationFactor"
                  id="operational.nationalizationFactor"
                  onChange={handleChange}
                  value={values.operational.nationalizationFactor}
                />

                <ErrorMessage component="p" className="error-message-error" name="operational.nationalizationFactor" />
              </Flex>

              <Flex direction="column">
                <LabelDefault text="Dias que a mercadoria será nacionalizada após a chegada" />
                <InputDefault
                  type="number"
                  name="operational.nationalizationDays"
                  id="operational.nationalizationDays"
                  onChange={handleChange}
                  value={values.operational.nationalizationDays}
                  placeholder="Preencha a quantidade de dias"
                  isDisabled={isPreviwer}
                />

                <ErrorMessage component="p" className="error-message-error" name="operational.nationalizationDays" />
              </Flex>

              <Flex direction="column">
                <LabelDefault text="Fator das despesas sobre o câmbio" />
                <InputDefault
                  type="number"
                  name="operational.expensesFactor"
                  id="operational.expensesFactor"
                  onChange={handleChange}
                  value={values.operational.expensesFactor}
                  placeholder="Preencha o fator"
                  isDisabled={isPreviwer}
                />

                <ErrorMessage component="p" className="error-message-error" name="operational.expensesFactor" />
              </Flex>

              <Flex direction="column">
                <LabelDefault text="Dias após a chegada onde será pago as despesas" />
                <InputDefault
                  type="number"
                  name="operational.expensesDays"
                  id="operational.expensesDays"
                  onChange={handleChange}
                  value={values.operational.expensesDays}
                  placeholder="Preencha a quantidade de dias"
                  isDisabled={isPreviwer}
                />

                <ErrorMessage component="p" className="error-message-error" name="operational.expensesDays" />
              </Flex>

              <Flex direction="column">
                <LabelDefault text="Dias que a mercadoria será faturada após a nacionalização" />
                <InputDefault
                  type="number"
                  name="operational.billingDays"
                  id="operational.billingDays"
                  onChange={handleChange}
                  value={values.operational.billingDays}
                  placeholder="Preencha a quantidade de dias"
                  isDisabled={isPreviwer}
                />

                <ErrorMessage component="p" className="error-message-error" name="operational.billingDays" />
              </Flex>

              {inputExchanges.map((_, index) => (
                <>
                  <Flex direction="column">
                    <LabelDefault text="Porcentagem do pagamento do câmbio" />
                    <InputPercentage
                      type="number"
                      placeholder="%"
                      isDisabled={isPreviwer}
                      name={`operational.exchanges[${index}].percent`}
                      id={`exchanges[${index}].percent`}
                      value={values.operational.exchanges[index]?.percent || ''}
                      setFieldValue={setFieldValue}
                    />
                  </Flex>
                  <Flex direction="column">
                    <LabelDefault text="Pagamento do câmbio (dias em relação ao ETD)" />
                    <InputDefault
                      placeholder="Preencha a quantidade de dias"
                      isDisabled={isPreviwer}
                      type="number"
                      name={`exchanges[${index}].days`}
                      id={`exchanges[${index}].days`}
                      onChange={(e) => {
                        handleInputChange(
                          index,
                          setFieldValue,
                          values.operational,
                          e.target.value,
                          'exchanges',
                          'days',
                          'operational'
                        );
                      }}
                      value={values.operational.exchanges[index]?.days || ''}
                    />
                  </Flex>

                  {inputExchanges.length > 1 && (
                    <Button
                      w="fit-content"
                      isDisabled={isPreviwer}
                      onClick={() => {
                        handleRemoveChange(
                          setFieldValue,
                          index,
                          values.operational.exchanges,
                          'exchanges',
                          inputExchanges,
                          setInputExchanges,
                          'operational'
                        );
                        handlingPercentExchange(values.operational.exchanges);
                      }}>
                      <MdDelete color="#E52359" />
                    </Button>
                  )}
                </>
              ))}
            </Grid>

            <Flex direction="column">
              <ErrorMessage component="p" className="error-message-error" name={`operational.exchanges`} />

              {percentExchanges < 100 && (
                <>
                  <Button
                    mt="10px"
                    w="fit-content"
                    h="40px"
                    fontSize="16px"
                    borderRadius="5px"
                    textColor="#2ECC71"
                    bgColor="#2ECC7152"
                    border="1px dashed #2ECC71"
                    isDisabled={isPreviwer}
                    _hover={{ bgColor: 'transparent', shadow: 'lg' }}
                    leftIcon={<MdAdd color="#2ECC71" size={15} />}
                    onClick={() => handleAddClick(inputExchanges, setInputExchanges, values)}>
                    Adicionar condição de pagamento
                  </Button>

                  <Text mt="10px">
                    Ainda falta{' '}
                    <b>
                      {(100 - percentExchanges).toFixed(2)}% para 100% da {'Porcentagem do pagamento do câmbio'}
                    </b>
                    . Adicione uma ou mais condição até totalizar 100%.
                  </Text>
                </>
              )}

              {!isNaN(percentExchanges) && percentExchanges > 100 && (
                <Text mt="10px">
                  Você ultrapassou <b>100% do valor necessário da {'Porcentagem do pagamento do câmbio'}</b>, verifique os valores
                  inseridos. ({100 - percentExchanges})%
                </Text>
              )}
            </Flex>
          </>
        )}
      </Flex>

      <Divider borderColor="#70707033" />

      <Flex direction="column">
        <FormLabel>Considerações Gerais</FormLabel>

        <FormControl>
          <LabelDefault
            text={
              <>
                Comentários adicionais <small>(opcional)</small>
              </>
            }
          />
          <InputDefault
            as="textarea"
            minH="120px"
            pt="10px"
            // placeholder="Observações (opcional)"
            isDisabled={isPreviwer}
            styles={selectCustomStyles()}
            name="operational.additionalInformation"
            id="additionalInformation"
            onChange={handleChange}
            value={values.operational.additionalInformation}
          />
        </FormControl>
      </Flex>
    </TabPanel>
  );
};

export default OperationalDataForm;
