const cashFlowGraph = {
  startsAt: '2023-09-14',
  endsAt: '2023-09-20',
  data: [
    {
      slug: 'adiantamento-cambio',
      name: 'Adiantamento de câmbio',
      dates: [
        {
          inflow: 1200.0,
          outflow: 800.0,
          result: 400.0,
        },
        {
          inflow: 500.0,
          outflow: 500.0,
          result: 0.0,
        },
        {
          inflow: 1800.0,
          outflow: 800.0,
          result: 1000.0,
        },
        {
          inflow: 2200.0,
          outflow: 600.0,
          result: 1800.0,
        },
        {
          inflow: 1000.0,
          outflow: 800.0,
          result: 200.0,
        },
        {
          inflow: 1200.0,
          outflow: 1400.0,
          result: -200.0,
        },
        {
          inflow: 800.0,
          outflow: 1200.0,
          result: -400.0,
        },
      ],
    },
    {
      slug: 'saldo-do-cambio',
      name: 'Saldo do Câmbio',
      dates: [
        {
          inflow: 1800.0,
          outflow: 800.0,
          result: 400.0,
        },
        {
          inflow: 500.0,
          outflow: 500.0,
          result: 0.0,
        },
        {
          inflow: 1800.0,
          outflow: 300.0,
          result: 1000.0,
        },
        {
          inflow: 2200.0,
          outflow: 600.0,
          result: 1800.0,
        },
        {
          inflow: 1000.0,
          outflow: 600.0,
          result: 200.0,
        },
        {
          inflow: 1200.0,
          outflow: 1400.0,
          result: -200.0,
        },
        {
          inflow: 800.0,
          outflow: 7200.0,
          result: -400.0,
        },
      ],
    },
    {
      slug: 'pagamento-dos-impostos-de-nacionalizacao',
      name: 'Pagamento dos impostos de Nacionalização',
      dates: [
        {
          inflow: 1200.0,
          outflow: 800.0,
          result: 400.0,
        },
        {
          inflow: 500.0,
          outflow: 500.0,
          result: 0.0,
        },
        {
          inflow: 800.0,
          outflow: 800.0,
          result: 1000.0,
        },
        {
          inflow: 2200.0,
          outflow: 600.0,
          result: 200.0,
        },
        {
          inflow: 1000.0,
          outflow: 800.0,
          result: 200.0,
        },
        {
          inflow: 1900.0,
          outflow: 400.0,
          result: -200.0,
        },
        {
          inflow: 800.0,
          outflow: 1200.0,
          result: -400.0,
        },
      ],
    },
    {
      slug: 'despesas-de-nacionalizacao',
      name: 'Despesas de Nacionalização',
      dates: [
        {
          inflow: 200.0,
          outflow: 800.0,
          result: 100.0,
        },
        {
          inflow: 500.0,
          outflow: 500.0,
          result: 0.0,
        },
        {
          inflow: 800.0,
          outflow: 800.0,
          result: 1000.0,
        },
        {
          inflow: 200.0,
          outflow: 100.0,
          result: 1800.0,
        },
        {
          inflow: 5000.0,
          outflow: 100.0,
          result: 200.0,
        },
        {
          inflow: 400.0,
          outflow: 200.0,
          result: -200.0,
        },
        {
          inflow: 1800.0,
          outflow: 200.0,
          result: -400.0,
        },
      ],
    },
    {
      slug: 'faturamento-previsto',
      name: 'Faturamento Previsto',
      dates: [
        {
          inflow: 1200.0,
          outflow: 800.0,
          result: 400.0,
        },
        {
          inflow: 500.0,
          outflow: 500.0,
          result: 0.0,
        },
        {
          inflow: 1800.0,
          outflow: 800.0,
          result: 1000.0,
        },
        {
          inflow: 2200.0,
          outflow: 600.0,
          result: 1800.0,
        },
        {
          inflow: 1000.0,
          outflow: 800.0,
          result: 200.0,
        },
        {
          inflow: 1200.0,
          outflow: 1400.0,
          result: -200.0,
        },
        {
          inflow: 800.0,
          outflow: 1200.0,
          result: -400.0,
        },
      ],
    },
    {
      slug: 'duplicatas-faturadas',
      name: 'Duplicatas faturadas',
      dates: [
        {
          inflow: 200.0,
          outflow: 800.0,
          result: 400.0,
        },
        {
          inflow: 1500.0,
          outflow: 500.0,
          result: 0.0,
        },
        {
          inflow: 800.0,
          outflow: 800.0,
          result: 1000.0,
        },
        {
          inflow: 2200.0,
          outflow: 600.0,
          result: 1800.0,
        },
        {
          inflow: 400.0,
          outflow: 200.0,
          result: 200.0,
        },
        {
          inflow: 1200.0,
          outflow: 1400.0,
          result: -200.0,
        },
        {
          inflow: 800.0,
          outflow: 1200.0,
          result: -400.0,
        },
      ],
    },
  ],
};

export default cashFlowGraph;
