const yup = require('yup');

const ValidationsSpecification = (isDraft = false, isSecondaryZone = false) => {
  let validation = yup.object().shape({
    documents: yup.array(),
    registration: yup.object().shape({
      vendemmiaOperationUnit: yup.array().required('Campo obrigatório').nullable(),
      operationType: yup.string().required('Campo obrigatório').nullable(),
      companyName: yup.string().required('Campo obrigatório'),
      companyFantasyName: yup.string().required('Campo obrigatório'),
      companyOperationDocumentNumber: yup.string().required('Campo obrigatório'),
      companyOperationSubscriptionState: yup.string().required('Campo obrigatório'),
      companyBranches: yup
        .array(
          yup.object({
            documentNumber: yup.string(),
            subscriptionState: yup.string(),
          })
        )
        .test({
          message: 'Os campos acima devem ser completamente preenchidos',
          test: (arr) => {
            return arr.find(({ documentNumber, subscriptionState }) => {
              return documentNumber && subscriptionState;
            });
          },
        }),
    }),
    contact: yup.object().shape({
      commercial: isDraft
        ? yup.array()
        : yup
            .array(
              yup.object({
                commercialName: yup.string(),
                commercialPhone: yup.string(),
                commercialEmail: yup.string(),
              })
            )
            .test({
              message: 'Nome, Email e Telefone devem ser completamente preenchidos',
              test: (arr) => {
                return arr.find(({ commercialName, commercialPhone, commercialEmail }) => {
                  return commercialName && commercialPhone && commercialEmail;
                });
              },
            }),
      operation: isDraft
        ? yup.array()
        : yup
            .array(
              yup.object({
                operationName: yup.string(),
                operationPhone: yup.string(),
                operationEmail: yup.string(),
              })
            )
            .test({
              message: 'Nome, Email e Telefone devem ser completamente preenchidos',
              test: (arr) => {
                return arr.find(({ operationName, operationPhone, operationEmail }) => {
                  return operationName && operationPhone && operationEmail;
                });
              },
            }),
      financial: isDraft
        ? yup.array()
        : yup
            .array(
              yup.object({
                financialName: yup.string(),
                financialPhone: yup.string(),
                financialEmail: yup.string(),
              })
            )
            .test({
              message: 'Nome, Email e Telefone devem ser completamente preenchidos',
              test: (arr) => {
                return arr.find(({ financialName, financialPhone, financialEmail }) => {
                  return financialName && financialPhone && financialEmail;
                });
              },
            }),
      legal: isDraft
        ? yup.array()
        : yup
            .array(
              yup.object({
                legalName: yup.string(),
                legalPhone: yup.string(),
                legalEmail: yup.string(),
              })
            )
            .test({
              message: 'Nome, Email e Telefone devem ser completamente preenchidos',
              test: (arr) => {
                return arr.find(({ legalName, legalPhone, legalEmail }) => {
                  return legalName && legalPhone && legalEmail;
                });
              },
            }),
    }),
    commercial: yup.object().shape({
      generalRestrictionsInformation: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      profitabilityInformation: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      isIndefiniteContract: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      contractValidUntil: isDraft
        ? yup.string()
        : yup.string().when('isIndefiniteContract', {
            is: true,
            then: yup.string().required('Campo obrigatório').nullable(),
          }),
      annualBilling: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      monthlyBilling: yup.string(),
      isProject: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      additionalInformation: yup.string(),
    }),
    tributary: yup.object().shape({
      isIcmsTaxpayer: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      operationPurpose: isDraft ? yup.array() : yup.array().required('Campo obrigatório'),
      fiscalBenefit: isDraft ? yup.array() : yup.array().required('Campo obrigatório'),
      taxRegime: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      additionalInformation: yup.string(),
    }),
    operational: yup.object().shape({
      forwardingAgent: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      forwardingAgentAdditionalInformation: yup.string(),
      dispatchValue: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      productTypeDescription: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      isAntidumping: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      useSystemBravos: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      importingLicenseConsentingEntity: isDraft ? yup.array() : yup.array().required('Campo obrigatório'),
      importingLicenseValue: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      importingLicenseAdditionalInformation: yup.string(),
      operationNature: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      incoterm: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      incotermAdditionalInformation: yup.string(),
      hasInternationalTransportQuote: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      transportModality: isDraft ? yup.array() : yup.array().required('Campo obrigatório'),
      goodsEntranceFederativeUnit: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      goodsEntranceFederativeAdditionalInformation: yup.string(),
      customsClearanceLocation: isDraft ? yup.string() : yup.string().required('Campo obrigatório').nullable(),
      customsClearanceLocationAdditionalInformation: yup.string(),
      insuranceCalculationBasisVendemmia: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      insuranceCalculationBasisClient: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      insuranceAdditionalInformation: yup.string(),
      storageAfterBilling: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      transportType: isDraft || !isSecondaryZone ? yup.string() : yup.string().required('Campo obrigatório'),
      goodsEntranceFederativeUnitModality: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      storageAfterBillingAdditionalInformation: yup.string(),
      nationalFreightModality: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      nationalFreightBilling: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      hasCashFlow: isDraft ? yup.boolean() : yup.boolean().required('Campo obrigatório'),
      nationalizationFactor: isDraft
        ? yup.string()
        : yup.string().when('hasCashFlow', {
            is: true,
            then: yup.string().required('Campo obrigatório'),
          }),
      nationalizationDays: isDraft
        ? yup.string()
        : yup.string().when('hasCashFlow', {
            is: true,
            then: yup.string().required('Campo obrigatório'),
          }),
      expensesFactor: isDraft
        ? yup.string()
        : yup.string().when('hasCashFlow', {
            is: true,
            then: yup.string().required('Campo obrigatório'),
          }),
      expensesDays: isDraft
        ? yup.string()
        : yup.string().when('hasCashFlow', {
            is: true,
            then: yup.string().required('Campo obrigatório'),
          }),
      billingFactor: isDraft
        ? yup.string()
        : yup.string().when('hasCashFlow', {
            is: true,
            then: yup.string().required('Campo obrigatório'),
          }),
      billingDays: isDraft
        ? yup.string()
        : yup.string().when('hasCashFlow', {
            is: true,
            then: yup.string().required('Campo obrigatório'),
          }),
      exchanges: isDraft
        ? yup.string()
        : yup.mixed().when('hasCashFlow', {
            is: true,
            then: yup
              .array(
                yup.object({
                  percent: yup.string(),
                  days: yup.string(),
                })
              )
              .test({
                message: 'Os campos acima devem ser completamente preenchidos',
                test: (arr) => {
                  return arr?.find(({ percent, days }) => {
                    return percent && days;
                  });
                },
              }),
          }),
      ncmRelations: isDraft
        ? yup.string()
        : yup
            .array(
              yup.object({
                id: yup.string(),
                description: yup.string(),
                tariff: yup.string(),
              })
            )
            .test({
              message: 'Você deve selecionar um ou mais NCM',
              test: (arr) => {
                return arr.find(({ description }) => {
                  return description;
                });
              },
            }),
    }),
    additionalInformation: yup.string(),
    nationalFreightAdditionalInformation: yup.string(),
    financial: yup.object().shape({
      hasAdministrativeFee: isDraft ? yup.boolean() : yup.boolean().required('Campo obrigatório'),
      administrativeFee: isDraft
        ? yup.string()
        : yup.string().when('hasAdministrativeFee', {
            is: true,
            then: yup.string().required('Campo obrigatório').nullable(),
          }),
      minAdministrativeFee: isDraft
        ? yup.string()
        : yup.string().when('hasAdministrativeFee', {
            is: true,
            then: yup.string().required('Campo obrigatório').nullable(),
          }),
      administrativeFeeAdditionalInformation: yup.string(),
      upFrontPaymentWarranty: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      upFrontPaymentWarrantyAdditionalInformation: yup.string(),
      commercialDiscount: isDraft ? yup.boolean() : yup.boolean().required('Campo obrigatório'),
      icmsRates: isDraft
        ? yup.string()
        : yup.mixed().when('commercialDiscount', {
            is: true,
            then: yup
              .array(
                yup.object({
                  value: yup.string(),
                  discountValue: yup.string(),
                })
              )
              .test({
                message: 'Os campos acima devem ser completamente preenchidos',
                test: (arr) => {
                  return arr.find(({ value, discountValue }) => {
                    return value && discountValue;
                  });
                },
              }),
          }),
      extraCommercialDiscount: isDraft
        ? yup.string()
        : yup.string().when('commercialDiscount', {
            is: true,
            then: yup.string().required('Campo obrigatório'),
          }),
      commercialDiscountAdditionalInformation: yup.string(),
      serviceInvoice: isDraft ? yup.boolean() : yup.boolean().required('Campo obrigatório'),
      administrateTax: isDraft
        ? yup.string()
        : yup.string().when('serviceInvoice', {
            is: true,
            then: yup.string().required('Campo obrigatório').nullable(),
          }),
      serviceInvoiceMinValue: isDraft
        ? yup.string()
        : yup.string().when('serviceInvoice', {
            is: true,
            then: yup.string().required('Campo obrigatório').nullable(),
          }),
      serviceInvoiceModality: isDraft
        ? yup.string()
        : yup.string().when('serviceInvoice', {
            is: true,
            then: yup.string().required('Campo obrigatório').nullable(),
          }),
      serviceInvoiceAdditionalInformation: yup.string(),
      exporterPayment: isDraft ? yup.array() : yup.array().required('Campo obrigatório'),
      exporterPaymentAdditionalInformation: yup.string(),
      financialCharges: isDraft ? yup.boolean() : yup.boolean().required('Campo obrigatório'),
      financialChargesModality: isDraft
        ? yup.string()
        : yup.string().when('financialCharges', {
            is: true,
            then: yup.string().required('Campo obrigatório').nullable(),
          }),
      financialChargesAddition: isDraft
        ? yup.string()
        : yup.string().when('financialCharges', {
            is: true,
            then: yup.string().required('Campo obrigatório').nullable(),
          }),
      financialChargesAdditionalInformation: yup.string(),
      paymentTermsCommodity: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      paymentTermsCommodityDays: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      paymentTermsTax: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      paymentTermsTaxDays: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      paymentTermsExpenses: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      paymentTermsExpensesDays: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      paymentTermsServiceInvoice: isDraft
        ? yup.string()
        : yup.string().when('serviceInvoice', {
            is: true,
            then: yup.string().required('Campo obrigatório'),
          }),
      paymentTermsServiceInvoiceDays: isDraft
        ? yup.string()
        : yup.string().when('serviceInvoice', {
            is: true,
            then: yup.string().required('Campo obrigatório'),
          }),
      paymentTermsAdditionalInformation: yup.string(),
    }),
    responsibleUsers: yup.object().shape({
      comercial: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      additionalComercial: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      tax: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      operator: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      financial: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      director: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
      logistic: isDraft ? yup.string() : yup.string().required('Campo obrigatório'),
    }),
  });

  return validation;
};

export default ValidationsSpecification;
