import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Avatar, Button, Flex, Image, Menu, MenuButton, MenuDivider, MenuGroup, MenuList, Text } from '@chakra-ui/react';

import logoff from '../../assets/images/logoff.svg';
import getProfile from '../../services/profile';
import requests from '../../services/requests';
import ModalAditionalFields from '../Modal/AditionalFields/ModalAditionalFields';
import EditProfileModal from '../Modal/Profile/EditProfile';

const DropdownProfile = () => {
  const [isLoadingLogout, setIsLoadingLogout] = useState(false);

  const [profile, setProfile] = useState({});
  const [actionComponent, setActionComponent] = useState(null);
  let navigate = useNavigate();

  const logout = () => {
    setIsLoadingLogout(true);
    requests
      .logout()
      .then(() => {
        toast.success('Seu acesso foi finalizado com sucesso.');
      })
      .catch(() => {
        toast.success('Seu acesso foi finalizado.');
      })
      .finally(() => {
        // Mesmo se der erro, o usuário faz logout, porém apenas localmente.
        setProfile({});
        window.localStorage.clear();
        navigate('/');
        setIsLoadingLogout(false);
      });
  };

  return (
    <Menu>
      <MenuButton>
        <Avatar src={getProfile.picture} name={getProfile.name} w="38px" h="38px" cursor="pointer" border="1px" />
      </MenuButton>

      <MenuList w="317px" border="1px" borderRadius="7px" borderColor="#A885F982" shadow="lg" p="0">
        <MenuGroup>
          <Flex direction="row" justify="space-between" align="center" p="19px">
            <Avatar src={getProfile.picture} name={getProfile.name} w="43px" h="43px" border="1px" />
            <Flex direction="column" justify="center">
              <Text
                textStyle="tableTitle"
                fontWeight="bold"
                color="primary"
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                maxW="170px">
                {getProfile.name}
              </Text>
              <Text color="#422C76" textStyle="paragraph">
                {getProfile.roleHumanReadable}
              </Text>
            </Flex>

            <Button
              p="0"
              borderColor="primary"
              borderRadius="100%"
              _hover={{ bgColor: 'linkColor' }}
              isLoading={isLoadingLogout}
              aria-label="Sair">
              <Image src={logoff} cursor="pointer" onClick={logout} />
            </Button>
          </Flex>
        </MenuGroup>

        <MenuDivider m="0" bgColor="#A885F982" />

        <MenuGroup>
          <Flex gap="14px" direction="column" p="19px">
            <EditProfileModal />
          </Flex>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};

export default DropdownProfile;
