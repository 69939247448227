import React, { useEffect, useState } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

import PieChartComponent from '../../../components/Charts/PieChartComponent';

const ProductStatusChart = ({ data }) => {
  const [entity, setEntity] = useState([]);
  const [total, setTotal] = useState(0);

  const formatValue = (value) => (isNaN(value) ? 0 : value.toLocaleString('pt-BR'));

  const calculatePercentage = (value) => {
    if (value !== undefined && total !== 0 && !isNaN(total)) {
      const percentage = (value / total) * 100;
      return `${percentage.toFixed(2)}%`;
    }
    return '0%';
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { qttAvailable = 0, qttBlocked = 0, total } = data;

        const chartData = [
          {
            name: 'Liberado',
            qtt: isNaN(qttAvailable) ? 0 : parseInt(qttAvailable, 10),
            color: '#2ECC71',
          },
          {
            name: 'Bloqueado',
            qtt: isNaN(qttBlocked) ? 0 : parseInt(qttBlocked, 10),
            color: '#E74C3C',
          },
        ];

        const sum = parseInt(total, 10);
        setTotal(sum);

        setEntity(chartData);
      } catch (error) {
        console.error('Erro ao obter os dados da API:', error);
      }
    };

    fetchData();
  }, [data]);

  return (
    <Flex alignItems="center" justify="space-evenly" w="full" h="full" gap="10px" flexDirection={{ base: 'column', md: 'row' }}>
      <PieChartComponent entity={entity} total={total} formatValue={formatValue} />

      <Flex direction="column" gap="20px" flexDirection={{ base: 'row', md: 'column' }}>
        {entity.map((entry, index) => (
          <Flex key={`item-${index}`} direction="column" align="center">
            <Flex gap="10px" align="center">
              <Box w="17px" h="17px" bgColor={entry.color} borderRadius="3px" />
              <Text fontSize={'14px'}>{entry.name}</Text>
            </Flex>

            <Flex align="center" gap="9px">
              <Text textStyle="tableTitle" textColor="#0F0A1D" fontWeight="bold">
                {formatValue(entry.qtt)}
              </Text>

              <Text textStyle="paragraph" textColor="#0F0A1D" opacity="0.8">
                {calculatePercentage(entry.qtt)}
              </Text>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default ProductStatusChart;
