import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Button, Divider, Flex, TabPanel, Table, Tbody, Td, Text, Textarea, Th, Thead, Tr } from '@chakra-ui/react';

import requests from '../../../../../services/requests';

const CommentTab = ({ entity = {} }) => {
  const [data, setData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [textareaValue, setTextareaValue] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm();

  const getComments = async () => {
    if (!entity || !entity?.identifier) {
      return;
    }

    await requests.getFreightComments(entity.identifier).then((data) => {
      setData(data);
    });
  };

  const sendComment = async () => {
    setIsLoadingData(true);
    if (textareaValue === '' || !entity?.identifier) {
      return setIsLoadingData(false);
    }

    await requests
      .addFreightComment(entity.identifier, { description: textareaValue })
      .then(async () => {
        toast.success('Comentário enviado com sucesso!');
        setTextareaValue('');
        clearErrors();
        getComments();
      })
      .catch(() => {
        toast.error('Erro ao enviar o comentário, tente novamente.');
      });
    setIsLoadingData(false);
  };

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  useEffect(() => {
    if (entity?.identifier) {
      getComments();
    }
  }, [entity]);

  return (
    <>
      <TabPanel>
        <form onSubmit={handleSubmit(sendComment)}>
          <Flex w="full" align="center" justify="space-between">
            <Flex w="85%">
              <Textarea
                w="100%"
                h="60px"
                bg="#F2F2F2"
                resize="none"
                border="1px solid #7070703B"
                placeholder="Escreva sua mensagem..."
                {...register('text', { required: true })}
                name="text"
                value={textareaValue}
                onChange={handleTextareaChange}
              />
              {errors['text'] && <Text textStyle="error">Campo obrigatório.</Text>}
            </Flex>
            <Flex w="15%" ml="10px">
              <Button variant="primary" isLoading={isLoadingData} loadingText="Enviando" type="submit">
                Enviar
              </Button>
            </Flex>
          </Flex>
        </form>
        <Divider my="15px" borderColor="#7070702E" />
        <Table w="100%">
          <Thead>
            <Tr>
              <Th>Responsável</Th>
              <Th>Comentário</Th>
              <Th>Horário</Th>
            </Tr>
          </Thead>

          <Tbody>
            {data?.length > 0 ? (
              data.map((comment, key) => (
                <Tr key={key}>
                  <Td color="#854FFF">{comment?.user?.name ?? '-'}</Td>

                  <Td fontSize="14px" w="full">
                    {comment?.description ?? '-'}
                  </Td>

                  <Td>
                    <Text fontSize="14px">{moment(comment?.createdAt).format('DD/MM/Y H:mm')}</Text>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={3}>
                  <Flex justify="center" my="25px">
                    Sem comentários
                  </Flex>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TabPanel>
    </>
  );
};

export default CommentTab;
