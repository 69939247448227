import React from 'react';

import { toast } from 'react-toastify';

import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import Tag from '../../../../components/Generic/Tag';
import Paginate from '../../../../components/Paginate/Paginate';
import requests from '../../../../services/requests';
import { limitChars } from '../../../../utils/strings';
import { convertColorUser, descriptionStatusUser, descriptionTypeUser } from '../../../../utils/translate';
import ActionButtons from '../../DriverManagement/components/ActionButtons';
import Verify from '../../DriverManagement/components/Verify';
import ModalChangeToggle from '../Modals/ModalChangeToggle';

const DriversTable = ({ drivers, load, loadList, setIsLoadingData, metadata, setMetadata, action, setAction }) => {
  const handleClick = (identifier, facial) => {
    let status = facial ? 'inactivate' : 'activate';

    requests
      .changeProfileFacialStatus(identifier, status)
      .then(() => {
        toast.success(`Biometria facial ${facial ? 'inativada' : 'ativada'} com sucesso!`);
        loadList();
      })
      .catch(() => {
        toast.error('Problemas ao alterar a biometria facial, tente novamente ou entre em contato com o Administrador');
      });
  };

  return (
    <Box>
      <Table className="table-default">
        <Thead border="1px" borderBottom="1px">
          <Tr>
            <Th>Nome</Th>
            <Th>Tipo</Th>
            <Th>Verificado</Th>
            <Th>Cadastro</Th>
            <Th>Biometria facial</Th>
            <Th>Ações</Th>
          </Tr>
        </Thead>
        <Tbody border="1px">
          {!load &&
            drivers.length > 0 &&
            drivers.map(function (driver, key) {
              return (
                <Tr key={key} style={{ '--delay': `0.${key}s` }}>
                  <Td>{limitChars(driver.name)}</Td>
                  <Td>{descriptionTypeUser(driver.type)}</Td>
                  <Td>
                    <Verify email={true} tell={true} />
                  </Td>
                  <Td>
                    <Tag type={convertColorUser(driver.status)}>{descriptionStatusUser(driver.status)}</Tag>
                  </Td>
                  <Td>
                    <ModalChangeToggle
                      text=" biometria facial"
                      status={driver.facial}
                      callback={(decision) => {
                        if (decision) {
                          handleClick(driver.identifier, driver.facial);
                        }
                      }}
                    />
                  </Td>
                  <Td>
                    <ActionButtons driver={driver} action={action} setAction={setAction} loadList={loadList}/>
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
      {metadata.total_pages > 1 && (
        <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
      )}
    </Box>
  );
};

export default DriversTable;
