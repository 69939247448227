import { Accordion, AccordionItem } from '@chakra-ui/react';
import { Grid, Text } from '@chakra-ui/react';

import CalendarMonthColumn from './CalendarMonthColumn';

const CalendarMonthSection = ({ list, modality = 'event', isMobile = false }) => {
  return (
    <>
      {!isMobile ? (
        <Grid gridTemplateColumns={'repeat(7, 1fr)'} alignItems={'start'} className="borderCalendar">
          {list &&
            list.map((day, dayKey) => {
              return (
                dayKey < 7 && (
                  <Grid key={dayKey}>
                    <Text bg={'#6794DC'} p="15px" fontSize="22px" w="100%" color="white" textAlign="center">
                      {day.weekdayName}
                    </Text>
                  </Grid>
                )
              );
            })}

          {list &&
            list.map((day, dayKey) => {
              return (
                <Grid key={dayKey} bt="0" h="full">
                  <CalendarMonthColumn day={day} key={dayKey} delay={dayKey} modality={modality} isMobile={isMobile} />
                </Grid>
              );
            })}
        </Grid>
      ) : (
        <Accordion display={{ base: 'flex', md: 'none' }} flexDirection="column" w="100%" allowToggle>
          {list &&
            list.map((day, dayKey) => {
              return (
                <AccordionItem
                  key={dayKey}
                  /* border={'0.25px solid #70707033'} */
                  bt="0"
                  h="full">
                  <CalendarMonthColumn day={day} key={dayKey} delay={dayKey} modality={modality} isMobile={isMobile} />
                </AccordionItem>
              );
            })}
        </Accordion>
      )}
    </>
  );
};

export default CalendarMonthSection;
