import { useEffect, useState } from 'react';

import { FaTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { Button, Card, Flex, Grid, GridItem, Heading, IconButton, List, ListItem, Text, useDisclosure } from '@chakra-ui/react';

import ModalUpload from '../../../components/Modal/Upload/ModalUpload';
import Page from '../../../components/Page';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';

const Certificate = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const hasPermission = permissions.vendemmiaCertificate;
  const [isMobile, setIsMobile] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [list, setList] = useState([]);
  const [listClients, setListClients] = useState([]);

  const [clientOptions, setClientOptions] = useState([]);
  const [passwordOptions, setPasswordOptions] = useState([]);

  const [loadInitial, setLoadInitial] = useState(true);

  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  const load = (filters, key) => {
    loadList(filters);

    loadInitial && (loadClientOptions(filters), setLoadInitial(false));
  };

  const loadList = async (filters) => {
    setIsLoading(true);

    try {
      const res = await requests.getCertificateList(filters);
      setList(res.data);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const loadClientOptions = async (filters) => {
    /* setIsLoading(true); */

    try {
      const res = await requests.listClients(filters, 1, 500);
      let data = [];
      res.data?.map((option) => {
        data.push({
          value: option.identifier,
          label: option.name,
        });
      });
      setClientOptions(data);
    } catch (error) {
      console.error(error);
    }
  };

  const loadMore = (filters, key) => {
    setIsLoading(true);
    setIsLoadingMore(true);
    requests.listProcess(filters, metadata.current_page + 1).then((data) => {
      if (request.current && request.current > key) {
        return;
      }

      request.current = key;

      setList((prevList) => [...prevList, ...data.data]);
      setMetadata(data.meta);
      setIsLoading(false);
      setIsLoadingMore(false);
    });
  };

  const handlerDeleteCertificateFile = async (identifier) => {
    try {
      await requests.removeCertificateFile(identifier);
      load();
      toast.success('Certificado deletado com sucesso.');
    } catch (error) {
      toast.success(error);
      console.error(error);
    }
  };

  const sendUpload = async (docs, config) => {
    return new Promise((resolve, reject) => {
      requests
        .uploadClientCertificateFile(docs, config)
        .then(() => {
          resolve();
          load();
          setTimeout(() => {}, 2000);
        })
        .catch(() => {
          reject();
        });
    });
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <Page
      screen="certificate"
      title="Certificado Digital"
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      textFilterPlaceholder="Nome do certificado"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      /* loadMore={loadMore} */
      isContentLoadingMore={isLoadingMore}
      /* filterOptions={filterOptions} */
      isRefreshLoading={isLoading || isLoadingMore}
      showPeriodFilter={false}
      showRefreshData={false}
      showFilters={false}
      allowFiltersOverride={false}>
      {/* <ScreenLoader isLoading={isLoading}>
        <h1>Content</h1>
      </ScreenLoader> */}
      <Card m="10px" border={'1px solid #70707036'}>
        <Heading
          p={'10px 15px'}
          fontSize={'20px'}
          color={'#422C76'}
          lineHeight={'30px'}
          fontWeight={'600'}
          fontFamily={'Hanken Grotesk'}
          borderBottom={'#70707036 solid 1px'}>
          Lista de certificados
        </Heading>
        <Flex
          m={'15px'}
          p={'25px'}
          flexDirection={'column'}
          alignItems={'end'}
          gap={'10px'}
          border={'1px solid #6C48C259'}
          borderRadius={'18px'}>
          <Grid
            templateColumns={'1fr 1fr 150px 150px 150px 120px'}
            p={'15px'}
            w={'full'}
            border={'1px solid #6C48C259'}
            borderRadius={'18px'}
            fontWeight={'bold'}
            fontSize={'15px'}>
            <GridItem>Arquivo</GridItem>
            <GridItem>Cliente</GridItem>
            <GridItem>Enviado por</GridItem>
            <GridItem>Dt Expiração</GridItem>
            <GridItem>Dt Criação</GridItem>
            <GridItem>Ações</GridItem>
          </Grid>
          <List display={'grid'} gap={'10px'} w={'full'}>
            {list.length > 0 ? (
              list.map((item, key) => {
                return (
                  <ListItem
                    key={key}
                    display={'grid'}
                    gridTemplateColumns={'1fr 1fr 150px 150px 150px 120px'}
                    alignItems={'center'}
                    p={'15px'}
                    border={'1px solid #6C48C259'}
                    borderRadius={'18px'}
                    color={'#707070'}
                    _odd={{ bg: '#eee' }}
                    fontSize={'13px'}>
                    <Text>{item.uploadName}</Text>
                    <Text>{item.client.name}</Text>
                    <Text>{item.uploadedByUser.name}</Text>
                    <Text>{item.expiresAtFormatted}</Text>
                    <Text>{item.createdAtFormatted}</Text>
                    <Text>
                      <IconButton
                        bg="none"
                        p="0"
                        icon={<FaTrashAlt size={20} color="#EF8484" />}
                        onClick={() => handlerDeleteCertificateFile(item.identifier)}
                      />
                    </Text>
                  </ListItem>
                );
              })
            ) : (
              <ListItem textAlign={'center'} p={'15px'} border={'1px solid #6C48C259'} borderRadius={'18px'}>
                Não há certificados cadastrados
              </ListItem>
            )}
          </List>
          <Button variant={'primary'} onClick={onOpen}>
            Adicionar novo
          </Button>
        </Flex>
        <ModalUpload
          title="Importar novo arquivo"
          subtitle="Upload de certificado"
          accept=".pfx"
          messageConfirm=" salvo com sucesso para envio!"
          isOpen={isOpen}
          onClose={onClose}
          multiple={true}
          requestAction={sendUpload}
          nameForm="client_certificate_file_upload"
          additional={[
            {
              label: 'Cliente',
              group: 'client',
              name: 'client',
              required: true,
              options: clientOptions,
            },
            {
              label: 'Senha',
              group: 'password',
              name: 'password',
              fieldType: 'text',
              required: true,
            },
          ]}
        />
      </Card>
    </Page>
  );
};

export default Certificate;
