import React from 'react';

import { createRoot } from 'react-dom/client';

import { ChakraProvider } from '@chakra-ui/react';

import App from './App';
import { themeDefault } from './styles/theme';

createRoot(document.getElementById('root')).render(
  <ChakraProvider theme={themeDefault}>
    <App />
  </ChakraProvider>
);
