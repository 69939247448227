import React, { useEffect, useState } from 'react';

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, Text } from '@chakra-ui/react';

import requests from '../../../../services/requests';

const HelpAnalyticsPanel = () => {
  const [load, setLoad] = useState(false);
  const [list, setList] = useState([]);

  const loadList = async () => {
    setLoad(true);
    try {
      const response = await requests.listFaqs();
      const filteredData = response.filter((entity) => entity?.typeSystem === 'SYSTEM_ANALYTICS');

      setList(filteredData);
    } catch (error) {
      setLoad(false);

      console.error(error);
    }
    setLoad(false);
  };

  useEffect(() => {
    loadList();
  }, []);

  return (
    <Flex h="calc(80vh - 121px)" justify="space-between" direction="column" mx="30px" mt="20px" overflowY="auto">
      <Accordion allowToggle>
        <Flex gap="10px" direction="column">
          {list?.map((entity, index) => (
            <AccordionItem borderRadius="15px" border="1px #EAE1FF" key={index}>
              <AccordionButton
                h="78px"
                px="60px"
                bgColor="#EAE1FF"
                borderRadius="15px"
                justifyContent="space-between"
                _hover={{ bgColor: '3EAE1FF' }}>
                <Text textColor="#936FE8" textAlign="left" fontSize={{ base: '18px', md: '24px' }}>
                  {entity?.title}
                </Text>
                <AccordionIcon color="#6C48C2" />
              </AccordionButton>

              <AccordionPanel bgColor="blackAlpha.50" p="0">
                <Text textStyle="tableTitle" textColor="#6C48C2AD" fontSize={{ base: '16px', md: '18px' }} px="70px" py="25px">
                  {entity?.description}
                </Text>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Flex>
      </Accordion>
      {load ? (
        <Flex justify="center" my="25px">
          <span>Carregando...</span>
        </Flex>
      ) : (
        <>
          {list.length === 0 && (
            <Flex justify="center" my="25px">
              Nenhum dado encontrado para os parâmetros filtrados.
            </Flex>
          )}
        </>
      )}

      <Box align="center" my="20px" py="30px" border="1px" borderColor="#0F0A1D30" borderRadius="26px">
        <Text textStyle="secondaryTitle" fontWeight="medium" textColor="#0F0A1D">
          Não encontrou sua dúvida?
        </Text>

        <Text>
          Envie um e-mail para:{' '}
          <Button variant="link" color="#3498DB">
            suporte@vendemmia.com.br
          </Button>
        </Text>
      </Box>
    </Flex>
  );
};

export default HelpAnalyticsPanel;
