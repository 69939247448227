import React, { useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';

import { Box, Button, Flex, Input, Text } from '@chakra-ui/react';

import 'react-toastify/dist/ReactToastify.css';
import bg1 from '../../assets/images/login/bgs/bg-1.jpg';
import Loading from '../../components/Loading/Loading';
import BackSlider from '../../components/Login/BackSlider/BackSlider';
import CardRound from '../../components/Login/CardRound/CardRound';
import api from '../../services/api';

const RegisterPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [data, setData] = useState({
    name: null,
    email: null,
    password: null,
    cellphone: null,
    companyName: null,
    companyDocumentNumber: null,
    companyRole: null,
  });

  const bgs = [
    {
      urlBg: bg1,
      text: 'Vendemmia Logística Integrada',
      timeSecs: 10,
      topicId: 1,
      filter: 0,
    },
    {
      urlBg: bg1,
      text: 'Vendemmia Logística Integrada',
      timeSecs: 10,
      topicId: 2,
      filter: 0,
    },
    {
      urlBg: bg1,
      text: 'Vendemmia Logística Integrada',
      timeSecs: 10,
      topicId: 3,
      filter: 0,
    },
  ];

  return (
    <BackSlider slides={bgs}>
      <ToastContainer />
      <CardRound>
        <Flex flexDir="column" gap="20px">
          <Flex flexDirection="column" gap="20px">
            {step === 1 && (
              <>
                <Text textStyle="paragraph">
                  Informe seus dados
                </Text>
                <Box>
                  <Input
                    variant="flushed"
                    placeholder="Nome: "
                    type="text"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value.trim() })}
                  />
                  {!data.name && (
                    <p
                      style={{
                        fontSize: '12px',
                        color: '#f22c3d',
                        marginTop: '2px',
                        marginLeft: '3px',
                      }}
                    >
                      Campo Obrigatório
                    </p>
                  )}
                </Box>
                <Box>
                  <Input
                    variant="flushed"
                    placeholder="Email: "
                    type="email"
                    value={data.email}
                    onChange={(e) => setData({ ...data, email: e.target.value.trim() })}
                  />
                  {!data.email && (
                    <p
                      style={{
                        fontSize: '12px',
                        color: '#f22c3d',
                        marginTop: '2px',
                        marginLeft: '3px',
                      }}
                    >
                      Campo Obrigatório
                    </p>
                  )}
                </Box>
                <Box>
                  <Input
                    variant="flushed"
                    placeholder="Senha: "
                    type="password"
                    value={data.password}
                    onChange={(e) => setData({ ...data, password: e.target.value.trim() })}
                  />
                  {!data.password && (
                    <p
                      style={{
                        fontSize: '12px',
                        color: '#f22c3d',
                        marginTop: '2px',
                        marginLeft: '3px',
                      }}
                    >
                      Campo Obrigatório
                    </p>
                  )}
                </Box>
                <Box>
                  <Input
                    variant="flushed"
                    placeholder="Celular: "
                    type="text"
                    value={data.cellphone}
                    onChange={(e) => setData({ ...data, cellphone: e.target.value.trim() })}
                  />
                </Box>
                <Box>
                  <Input
                    variant="flushed"
                    placeholder="Nome da companhia: "
                    type="text"
                    value={data.companyName}
                    onChange={(e) => setData({ ...data, companyName: e.target.value.trim() })}
                  />
                  {!data.companyName && (
                    <p
                      style={{
                        fontSize: '12px',
                        color: '#f22c3d',
                        marginTop: '2px',
                        marginLeft: '3px',
                      }}
                    >
                      Campo Obrigatório
                    </p>
                  )}
                </Box>
                <Box>
                  <Input
                    variant="flushed"
                    placeholder="CNPJ da companhia: "
                    type="text"
                    value={data.companyDocumentNumber}
                    onChange={(e) => setData({ ...data, companyDocumentNumber: e.target.value.trim() })}
                  />
                </Box>
                <Box>
                  <Input
                    variant="flushed"
                    placeholder="Cargo: "
                    type="text"
                    value={data.companyRole}
                    onChange={(e) => setData({ ...data, companyRole: e.target.value.trim() })}
                  />
                </Box>
              </>
            )}
          </Flex>

          {step === 2 && (
            <Text textAlign="center" p="10px">Solicitação de cadastro enviada com sucesso. Aguarde a aprovação do seu cadastro!</Text>
          )}

          <Flex flexDirection="row" justify={`${step === 2 ? "center" : "space-between"}`} align="center">
            <Button
              variant="secundary"
              onClick={() => window.location.href = '/'}>
              <Text textStyle="paragraph">Voltar</Text>
            </Button>

            {step === 1 && (
              <Button
                isDisabled={!data.name || !data.email || !data.password || !data.companyName}
                isLoading={isLoading}
                variant="primary"
                loadingText="Enviando..."
                onClick={() => {
                  setIsLoading(true);
                  api
                    .post('register/pre-register', data)
                    .then((response) => {
                      toast.success('Solicitação de cadastro enviada com sucesso. Aguarde a aprovação do seu cadastro');
                      setIsLoading(false);
                      setStep(step + 1);
                    })
                    .catch((error) => {
                      setIsLoading(false);
                    })
                  ;
                }}
              >
                <Text textStyle="paragraph">Enviar</Text>
              </Button>
            )}
          </Flex>
        </Flex>
      </CardRound>
      <Loading status={isLoading} />
    </BackSlider>
  );
};

export default RegisterPage;
