import React, { useEffect, useState } from 'react';

import { MdHelp, MdPermPhoneMsg } from 'react-icons/md';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Select,
  Tab,
  TabIndicator,
  TabList,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from '@chakra-ui/react';

import NoPicture from '../../../assets/images/icons/driver-without-picture.png';
import Card from '../../../components/Card/Card';
import Layout from '../../../components/Layout';
import Menubar from '../../../components/MenuBar/Menubar';
import DefaultModal from '../../../components/Modal/Template/DefaultModal';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import requests from '../../../services/requests';
import { driver } from '../../../utils/exports';
import { limitChars } from '../../../utils/strings';
import { descriptionStatusTrip, getStatusTransition, modalityIcon } from '../../../utils/translate';

import CommentTab from './components/Comment/CommentTab';
import DocumentTab from './components/Document/DocumentTab';
import EventsTab from './components/Events/EventsTab';
import InformationTab from './components/Information/InformationTab';
import MapTrajectory from './components/Map/MapTrajectory';
import TrackingTab from './components/Tracking/TrackingTab';
import TrajectoryTab from './components/Trajectory/TrajectoryTab';

const TravelDetailPage = () => {
  const { identifier } = useParams();
  let { state } = useLocation();
  const [entity, setEntity] = useState(state);
  const [isLoadingEntity, setIsLoadingEntity] = useState(false);
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [isLoadingCheckpoints, setIsLoadingCheckpoints] = useState(false);
  const [events, setEvents] = useState([]);
  const [checkpoints, setCheckpoints] = useState([]);

  const [enabled, setEnabled] = useState(false);

  const getEntity = () => {
    setIsLoadingEntity(true);
    requests
      .showDriverFreight(identifier)
      .then((response) => {
        setEntity(response);
        setIsLoadingEntity(false);
      })
      .catch(() => {
        setIsLoadingEntity(false);
      });
  };

  const getColor = (modality) => {
    if (modality == 'FRETE_NAO_RASTREAVEL') {
      return '#f1c40f';
    } else if (modality == 'FRETE_RASTREAVEL') {
      return '#bababa';
    }

    return '#a885f9';
  };

  let profileRole = localStorage.getItem('profile-role');
  const [center, setCenter] = useState({
    lat: -23.5792551,
    lng: -46.641581,
  });

  const [zoom, setZoom] = useState(5);
  const formartType = (type) => {
    switch (type) {
      case 'COLETA':
        return 'Coleta';
      default:
        return 'Entrega';
    }
  };

  const capitalizeFirstLetter = (str) => {
    if (!str || str?.length === 0) {
      return;
    }

    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  const formatModality = capitalizeFirstLetter(entity?.modality.replaceAll('_', ' ').toLowerCase());

  const getEvents = () => {
    setIsLoadingEvents(true);
    requests
      .showEventsDriverFreight(identifier)
      .then((response) => {
        setEvents(response);
      })
      .finally(() => {
        setIsLoadingEvents(false);
      });
  };

  const getCheckpoints = () => {
    setIsLoadingCheckpoints(true);
    requests
      .showCheckpointsDriverFreight(entity.identifier)
      .then((response) => {
        setCheckpoints(response);
      })
      .finally(() => {
        setIsLoadingCheckpoints(false);
      });
  };

  const handleClickChangeStatus = (event) => {
    setIsLoadingEntity(true);
    let transition = getStatusTransition(entity?.status, event?.target?.value);
    if (!transition) {
      toast.error('Não foi possivel alterar o status da viagem, verifique o fluxograma para alteração de status');
      setIsLoadingEntity(false);
      return;
    }

    requests
      .changeStatusDriverFreight(entity?.identifier, transition)
      .then((response) => {
        toast.success(
          `Status alterado de ${descriptionStatusTrip(entity?.status)} para ${descriptionStatusTrip(event?.target?.value)}`
        );
        window.location.reload();
      })
      .catch(() => {
        toast.error('Problemas ao alterar status da viagem, tente novamente ou entre em contato com o Administrador');
        setIsLoadingEntity(false);
      });
  };

  const handleClickCancelledTrip = () => {
    setIsLoadingEntity(true);
    const targetStatus = 'CANCELLED';
    let transition = getStatusTransition(entity?.status, targetStatus);
    if (!transition) {
      toast.error('Não foi possivel alterar o status da viagem, verifique o fluxograma para alteração de status');
      setIsLoadingEntity(false);
      return;
    }

    requests
      .changeStatusDriverFreight(entity?.identifier, transition)
      .then((response) => {
        toast.success(`Status alterado de ${descriptionStatusTrip(entity?.status)} para ${descriptionStatusTrip(targetStatus)}`);
        getEntity();
      })
      .catch(() => {
        toast.error('Problemas ao alterar status da viagem, tente novamente ou entre em contato com o Administrador');
        setIsLoadingEntity(false);
      });
  };

  useEffect(() => {
    getEntity();
  }, []);

  useEffect(() => {
    if (entity && entity.identifier) {
      getEvents();
      getCheckpoints();
    }
  }, [entity]);

  return (
    <Layout>
      <Menubar
        linkTree={[
          { link: '#', title: 'Transporte' },
          { link: '/logistic/trip', title: 'Viagens' },
        ]}
        placeholder="Pesquisar viagens..."
        title={`${entity && entity.code ? '#' + entity.code : 'Carregando...'}`}
        icon={driver}
        hideAllFilters={true}
      />
      <ScreenLoader isLoading={isLoadingEntity}>
        <Grid mx="20px" gap="10px" templateColumns="1fr 1.5fr">
          <Flex direction="column" gap="10px" h="full">
            <Card p="20px">
              <Flex>
                <Flex direction="column" w="full" justify="space-between">
                  <Flex align="center" gap="20px">
                    {entity?.userDriverName && entity?.userDriverPicture ? (
                      <Image
                        src={`${process.env.REACT_APP_API_URL}${entity?.userDriverPicture?.replace('//profile', '/profile')}`}
                        alt="Foto de perfil do motorista"
                        w="80px"
                        h="80px"
                        objectFit="cover"
                      />
                    ) : (
                      <Image src={NoPicture} alt="Sem foto de motorista" w="80px" h="80px" />
                    )}

                    <Flex direction="column">
                      <Flex align="center" gap={'10px'}>
                        <Text fontSize="12px" color="#422C76">
                          <Flex align="center">
                            <Box w="12px" h="12px" borderRadius="full" bg="#2ecc71" mr="2" />
                            {descriptionStatusTrip(entity?.status)}
                          </Flex>
                        </Text>
                        <Text fontSize="12px" color="#422C76">
                          /
                        </Text>
                        <Text fontSize="12px" color="#422C76">
                          {isLoadingEntity ? (
                            '...'
                          ) : (
                            <Flex align="center">
                              <Icon as={modalityIcon(entity?.modality)} color={getColor(entity?.modality)} w="14px" h="14px" mr="2" />
                              {formatModality}
                              {entity?.lastVersionApp != null && (
                                <>
                                  <Tooltip label={<Text>Versão do APP: {entity?.lastVersionApp}</Text>}>
                                    <Box ml="10px">
                                      <MdHelp color="#422C76" />
                                    </Box>
                                  </Tooltip>
                                </>
                              )}
                            </Flex>
                          )}
                        </Text>
                      </Flex>
                      <Text fontSize="20px" fontWeight="bold" color="#422C76" overflow="ellips">
                        {isLoadingEntity ? (
                          '...'
                        ) : (
                          <>
                            <Tooltip label={entity?.userDriverName}>
                              {entity?.userDriverName ? limitChars(entity?.userDriverName, 20) : 'Motorista não identificado'}
                            </Tooltip>
                          </>
                        )}
                      </Text>
                    </Flex>
                  </Flex>

                  {entity && entity.userDriverName && (
                    <>
                      <Flex justify="space-between" mt="20px" w="full">
                        <Flex direction="column">
                          <Text fontSize="13px">Id Viagem</Text>

                          <Text fontSize="18px" fontWeight="bold">
                            #{entity?.code}
                          </Text>
                        </Flex>

                        <Flex direction="column">
                          <Text fontSize="13px">Veículo</Text>

                          <Text fontSize="18px" fontWeight="bold">
                            {entity?.vehicle?.licensePlate ?? 'Não informado'}
                          </Text>
                        </Flex>
                      </Flex>
                    </>
                  )}
                </Flex>

                {entity && entity.userDriverName && (
                  <Flex direction="column" w="full" align="center" justify="space-between">
                    <Flex gap="4px" align="center">
                      <Text textStyle="paragraph" textColor="primary">
                        Viagens realizadas:
                      </Text>

                      <Text fontWeight="bold" textColor="primary">
                        {entity?.userDriverTrips}
                      </Text>
                    </Flex>

                    <Button
                      bgColor="green"
                      color="white"
                      borderRadius="15px"
                      shadow="xl"
                      h="40px"
                      py="15px"
                      px="15px"
                      onClick={() => {
                        if (entity?.userDriverCellphone != null) {
                          const url = `https://api.whatsapp.com/send?phone=${entity.userDriverCellphone}`;
                          window.open(url, '_blank');
                        } else if (entity?.userDriverPhone != null) {
                          const url = `tel:${entity.userDriverPhone}`;
                          window.open(url, '_blank');
                        }
                      }}
                      _hover={{ opacity: '0.5', transition: '0.9s' }}>
                      <MdPermPhoneMsg />
                      <Text textStyle="paragraph" ml="10px">
                        Entrar em contato
                      </Text>
                    </Button>
                  </Flex>
                )}
              </Flex>
            </Card>
            {entity?.modality == 'MANUAL' && (
              <Card p="20px">
                <Flex align="center" gap="1.8rem">
                  <Flex justify="space-between">
                    <Flex bgColor="linkColor" p="10px 2px" ml={4} borderRadius="2px"></Flex>

                    <Box ml={4}>
                      <Text fontSize={'1.375rem'} fontWeight={'bold'}>
                        Status da viagem
                      </Text>
                      <Text fontSize={'1rem'}>Alterar status manualmente</Text>
                    </Box>
                  </Flex>
                  <Select
                    variant="primary"
                    w="fit-content"
                    name="status"
                    defaultValue={entity?.status}
                    isDisabled={entity?.statusAvailable?.length === 1}
                    onChange={(event) => handleClickChangeStatus(event)}>
                    {entity &&
                      entity?.statusAvailable?.length > 0 &&
                      entity.statusAvailable.map((status, key) => {
                        return (
                          <>
                            <option key={key} value={status.description}>
                              {status.translated}
                            </option>
                          </>
                        );
                      })}
                  </Select>
                </Flex>
              </Card>
            )}

            <Tabs position="relative" variant="unstyled" overflow="hidden" isFitted>
              <Card
                height="100%"
                minH="370px"
                maxH="370px"
                header={
                  <Flex position="relative" w="full">
                    <TabList w="full">
                      <Tab px="0px" py="10px">
                        <Text fontSize="14px" color="#6C48C2">
                          Informações
                        </Text>
                      </Tab>
                      <Tab px="0px" py="10px">
                        <Text fontSize="14px" color="#6C48C2">
                          Trechos
                        </Text>
                      </Tab>
                      <Tab px="0px" py="10px">
                        <Text fontSize="14px" color="#6C48C2">
                          Rastreio
                        </Text>
                      </Tab>
                      {events && events?.length > 0 && (
                        <Tab px="0px" py="10px">
                          <Text fontSize="14px" color="#6C48C2">
                            Eventos
                          </Text>
                        </Tab>
                      )}
                      <Tab px="0px" py="10px">
                        <Text fontSize="14px" color="#6C48C2">
                          Documentos
                        </Text>
                      </Tab>
                      <Tab px="0px" py="10px">
                        <Text fontSize="14px" color="#6C48C2">
                          Comentários
                        </Text>
                      </Tab>
                    </TabList>

                    <TabIndicator mt="-1.5px" bottom={0} height="2px" bg="#6C48C2" borderRadius="1px" />
                  </Flex>
                }>
                <Flex overflowY="auto">
                  <TabPanels p="15px" pt="0">
                    <InformationTab entity={entity} setCenter={setCenter} setZoom={setZoom} />
                    <TrajectoryTab entity={entity} setCenter={setCenter} setZoom={setZoom} getEntity={getEntity} />
                    <TrackingTab entity={entity} setCenter={setCenter} setZoom={setZoom} />
                    {events && events.length > 0 && <EventsTab events={events} />}
                    <DocumentTab files={entity?.files} entity={entity} getEntity={getEntity} />
                    <CommentTab entity={entity} />
                  </TabPanels>
                </Flex>
              </Card>
            </Tabs>
          </Flex>
          <Flex h="min-content">
            {!isLoadingEntity && entity && (
              <MapTrajectory
                w="100%"
                identifier={identifier}
                entity={entity}
                events={events}
                checkpoints={checkpoints}
                center={center}
                setCenter={setCenter}
                zoom={zoom}
                setZoom={setZoom}
              />
            )}
          </Flex>
        </Grid>
      </ScreenLoader>
      <DefaultModal
        label="Reprovar"
        title={'Certeza que deseja reprovar?'}
        enabled={enabled}
        subtitle={
          <>
            Você tem certeza que deseja reprovar esses dados?
            <br /> Ao reprovar o motorista receberá uma notificação com a sua mensagem de observação.
          </>
        }
        buttonColor="red"
        txtButton="Enviar reprovação"
        callback={(decision) => {
          // if (decision) {
          //   changeStatus(identifier, 'reject', { section: section, observation: observation });
          // }
        }}
      />
    </Layout>
  );
};

export default TravelDetailPage;
