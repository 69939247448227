import React from 'react';

import { ErrorMessage, Formik } from 'formik';
import { toast } from 'react-toastify';

import { Button, Flex, Grid, ModalBody, ModalFooter } from '@chakra-ui/react';

import InputDefault from '../../../../components/Form/Input/InputDefault';
import InputDefaultMask from '../../../../components/Form/Input/InputDefaultMask';
import { LabelDefault } from '../../../../components/Form/Label/LabelDefault';
import requests from '../../../../services/requests';
import { userInitialValues, userValidation } from '../Helpers/userSchema';

const RegistrationData = ({ entity, handleClose, onClose, handleCloseRefresh }) => {
  const generateFilters = (values) => {
    let additional = [];

    Object.entries(values?.additional)?.forEach(([key, value]) => {
      if (value != '') {
        additional.push({ modality: key == 'location' ? 'ADDRESS' : key.toUpperCase(), description: value });
      }
    });

    return {
      ...values,
      additional: additional,
    };
  };

  return (
    <Formik
      initialValues={userInitialValues(entity)}
      validationSchema={userValidation(entity)}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        if (entity?.identifier) {
          requests
            .editUser(entity.identifier, values)
            .then((response) => {
              toast.success(`Usuário ${response.name} editado com sucesso!`);
              setSubmitting(false);
            })
            .catch(() => {
              setSubmitting(false);
              toast.error('Problemas ao editar usuário, tente novamente ou entre em contato com o Administrador');
            });
        } else {
          const filters = generateFilters(values);
          requests
            .addUser(values?.role?.slug, filters)
            .then((response) => {
              toast.success(`Usuário ${response.name} cadastrado com sucesso!`);
              setSubmitting(false);
              handleCloseRefresh();
            })
            .catch(() => {
              setSubmitting(false);
              toast.error('Problemas ao cadastrar usuário, tente novamente ou entre em contato com o Administrador');
            });
        }
      }}>
      {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <ModalBody mt="10px" overflowY="auto" h="500px" w="full" className="up-anim">
            <Grid templateColumns="repeat(2, 1fr)" gap="20px">
              <Flex direction="column">
                <LabelDefault name="name" text="Nome completo" />

                <InputDefault setFieldValue={setFieldValue} value={values.name} name="name" placeholder="Preencha o nome completo" />
                <ErrorMessage component="p" className="error-message-error" name="name" />
              </Flex>

              <Flex direction="column">
                <LabelDefault name="documentNumber" text="CPF" />

                <InputDefaultMask
                  setFieldValue={setFieldValue}
                  value={values.documentNumber}
                  name="documentNumber"
                  placeholder="Preencha o CPF"
                  mask="999.999.999-99"
                />
                <ErrorMessage component="p" className="error-message-error" name="documentNumber" />
              </Flex>

              <Flex direction="column">
                <LabelDefault name="email" text="E-mail" />
                <InputDefault setFieldValue={setFieldValue} value={values.email} name="email" placeholder="Preencha o e-mail" />

                <ErrorMessage component="p" className="error-message-error" name="email" />
              </Flex>

              {!entity?.identifier && (
                <Flex direction="column">
                  <LabelDefault name="password" text="Senha de acesso" />

                  <InputDefault
                    setFieldValue={setFieldValue}
                    value={values.password}
                    name="password"
                    type="password"
                    placeholder="Preencha a senha"
                  />
                  <ErrorMessage component="p" className="error-message-error" name="password" />
                </Flex>
              )}

              <Flex direction="column">
                <LabelDefault name="cellphone" text="Celular (opcional)" />

                <InputDefaultMask
                  setFieldValue={setFieldValue}
                  value={values.cellphone}
                  name="cellphone"
                  mask="(99) 99999-9999"
                  placeholder="Preencha o celular (opcional)"
                />
                <ErrorMessage component="p" className="error-message-error" name="cellphone" />
              </Flex>

              <Flex direction="column">
                <LabelDefault name="phone" text="Telefone (opcional)" />

                <InputDefaultMask
                  setFieldValue={setFieldValue}
                  value={values.phone}
                  name="phone"
                  mask="(99) 9999-9999"
                  placeholder="Preencha o telefone (opcional)"
                />
                <ErrorMessage component="p" className="error-message-error" name="phone" />
              </Flex>

              <Flex direction="column">
                <LabelDefault name="additional.cnh" text="CNH" />

                <InputDefault
                  setFieldValue={setFieldValue}
                  value={values.additional.cnh}
                  name="additional.cnh"
                  placeholder="Preencha a CNH"
                />
                <ErrorMessage component="p" className="error-message-error" name="additional.cnh" />
              </Flex>

              <Flex direction="column">
                <LabelDefault name="additional.rg" text="RG" />

                <InputDefault
                  setFieldValue={setFieldValue}
                  value={values.additional.rg}
                  name="additional.rg"
                  placeholder="Preencha o RG (opcional)"
                />
              </Flex>

              <Flex direction="column">
                <LabelDefault name="additional.birthday" text="Data de nascimento" />

                <InputDefaultMask
                  setFieldValue={setFieldValue}
                  value={values.additional.birthday}
                  name="additional.birthday"
                  mask="99/99/9999"
                  placeholder="Preencha a data de nascimento (opcional)"
                />
              </Flex>

              <Flex direction="column">
                <LabelDefault name="additional.zipcode" text="CEP" />

                <InputDefaultMask
                  setFieldValue={setFieldValue}
                  value={values.additional.zipcode}
                  name="additional.zipcode"
                  mask="99999-999"
                  placeholder="Preencha o CEP (opcional)"
                />
              </Flex>

              <Flex direction="column">
                <LabelDefault name="additional.location" text="Endereço" />

                <InputDefault
                  setFieldValue={setFieldValue}
                  value={values.additional.location}
                  name="additional.location"
                  placeholder="Preencha o Endereço (opcional)"
                />
              </Flex>

              <Flex direction="column">
                <LabelDefault name="additional.number" text="Número" />

                <InputDefault
                  setFieldValue={setFieldValue}
                  value={values.additional.number}
                  name="additional.number"
                  placeholder="Preencha o número (opcional)"
                />
              </Flex>

              <Flex direction="column">
                <LabelDefault name="additional.complement" text="Complemento" />

                <InputDefault
                  setFieldValue={setFieldValue}
                  value={values.additional.complement}
                  name="additional.complement"
                  placeholder="Preencha o Complemento (opcional)"
                />
              </Flex>

              <Flex direction="column">
                <LabelDefault name="additional.neighborhood" text="Bairro" />

                <InputDefault
                  setFieldValue={setFieldValue}
                  value={values.additional.neighborhood}
                  name="additional.neighborhood"
                  placeholder="Preencha o Bairro (opcional)"
                />
              </Flex>

              <Flex direction="column">
                <LabelDefault name="additional.city" text="Cidade" />

                <InputDefault
                  setFieldValue={setFieldValue}
                  value={values.additional.city}
                  name="additional.city"
                  placeholder="Preencha a Cidade (opcional)"
                />
              </Flex>

              <Flex direction="column">
                <LabelDefault name="additional.state" text="Estado" />

                <InputDefault
                  setFieldValue={setFieldValue}
                  value={values.additional.state}
                  name="additional.state"
                  placeholder="Preencha o Estado (opcional)"
                />
              </Flex>
            </Grid>
          </ModalBody>

          <ModalFooter borderTop="1px" borderColor="#7070702E" p="0">
            <Button
              border="1px"
              w="fit-content"
              borderRadius="7px"
              m="15px"
              p="9px 23px"
              color="#E73668"
              borderColor="#E52359"
              bgColor="transparent"
              type="button"
              onClick={handleClose}>
              Cancelar e fechar
            </Button>

            <Button
              variant="primary"
              w="fit-content"
              borderRadius="7px"
              m="15px"
              p="9px 23px"
              type="submit"
              isLoading={isSubmitting}
              loadingText="Salvando">
              Salvar
            </Button>
          </ModalFooter>
        </form>
      )}
    </Formik>
  );
};
export default RegistrationData;
