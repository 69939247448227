import { Bar, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Flex, Text } from '@chakra-ui/react';

import CustomTooltip from '../../../../components/Tooltip/customTooltip';
import cashFlowGraph from '../mock/cashFlowGraph';

const IncomeAndExpenseChart = ({ inOutGraphDataValue }) => {

  const flattenData = cashFlowGraph.data.reduce((acc, item) => {
    const totalInflow = item.dates.reduce((sum, date) => sum + date.inflow, 0);
    const totalOutflow = item.dates.reduce((sum, date) => sum + date.outflow, 0);

    return acc.concat({
      name: item.name,
      totalInflow,
      totalOutflow,
    });
  }, []);

  return (
    <Flex p={'50px 100px 45px'} flexDirection={'column'}>
      <Text p={'0 0 65px 60px'} fontSize={'26px'} lineHeight={'34px'} color={'primary'} fontWeight={'600'}>
        Entrada e saída nos próximos dias
      </Text>
      <Flex align="center" justify="center">
        <ResponsiveContainer width="100%" height={500}>
          <ComposedChart data={flattenData}>
            <CartesianGrid strokeDasharray="0" />
            <Tooltip
              content={
                <CustomTooltip
                  payload={flattenData}
                  tooltip={[
                    {
                      name: 'Entrada',
                      dataKey: 'totalInflow',
                      color: '#AA8BE0',
                    },
                    {
                      name: 'Saída',
                      dataKey: 'totalOutflow',
                      color: '#E52359',
                    },
                  ]}
                />
              }
            />
            <XAxis dataKey="name" fontSize={'11px'} />
            <YAxis yAxisId="left" orientation="left" stroke="#AA8BE0" />
            <YAxis yAxisId="right" orientation="right" stroke="#E52359" />
            <Bar barSize={40} dataKey="totalInflow" yAxisId="left" fill="#AA8BE0" />
            <Bar barSize={40} dataKey="totalOutflow" yAxisId="right" fill="#E52359" />
          </ComposedChart>
        </ResponsiveContainer>
      </Flex>
    </Flex>
  );
};

export default IncomeAndExpenseChart;
