import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { MdUploadFile } from 'react-icons/md';
import Select from 'react-select';

import { Button, Flex, FormControl, FormLabel, IconButton, Input, Text, useDisclosure } from '@chakra-ui/react';

import ModalUpload from '../../../../../components/Modal/Upload/ModalUpload';
import requests from '../../../../../services/requests';
import { getFilter } from '../../../../../utils/storageFilter';

const CardMainData = ({
  entity,
  filterOptions = [],
  setStep,
  formErrorMainData,
  setFormErrorMainData,
  setFormMainData,
  setHasError,
  ctesSelected,
  setCtesSelected,
  formData,
  setFormData,
}) => {
  const [cteFilesOptions, setCteFilesOptions] = useState([]);
  const [modality, setModality] = useState(entity?.modality);
  const [clientList, setClientList] = useState(entity?.clients ?? '');
  const [data, setData] = useState({
    modality: entity?.modality ?? '',
    clients: entity?.clients ?? '',
    externalCode: entity?.externalCode ?? '',
    shippingCompanyName: entity?.shippingCompanyName ?? '',
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { register, setValue } = useForm();
  const [showShippingCompanyName, setShowShippingCompanyName] = useState(
    entity?.shippingCompanyName != null && entity.shippingCompanyName != 'VENDEMMIA' ? true : false
  );

  const sendUpload = async (docs, config) => {
    return new Promise((resolve, reject) => {
      requests
        .uploadDriverFreightCteFile(docs, config)
        .then(() => {
          resolve();
          loadCteFiles();
          onClose();
        })
        .catch((e) => {
          reject();
        })
        .finally(() => {
          loadCteFiles();
        });
    });
  };

  const options = [
    { value: 'APP_MOTORISTA', label: 'Aplicativo do motorista' },
    { value: 'MANUAL', label: 'Viagem manual' },
  ];

  function getLabelForValue(value) {
    if (value === 'APP_MOTORISTA') {
      return 'Aplicativo do motorista';
    } else if (value === 'MANUAL') {
      return 'Viagem manual';
    }
  }

  const handleSelectChange = (value) => {
    const selectedValues = Array.isArray(value) ? value : [value];
    const clientIds = selectedValues.flatMap((item) => [item.identifier, ...item.children.map((child) => child.identifier)]);

    setClientList(clientIds);
    setData((prevData) => ({ ...prevData, clients: clientIds }));
  };

  const handleModalityChange = (event) => {
    const newModality = event?.value;
    setModality(newModality);
    setData((prevData) => ({ ...prevData, modality: newModality }));
  };

  const onSubmit = () => {
    if (!modality || clientList.length === 0 || !data.shippingCompanyName) {
      setFormErrorMainData(true);
      return;
    }

    setData({
      modality: modality,
      clients: clientList,
    });

    setFormMainData(data);
    setStep(1);
  };

  const loadCteFiles = () => {
    requests.listDriverFreightCte({}).then((response) => {
      let aux = [];
      response.forEach((data, index) => {
        aux.push({
          label: data.name,
          value: data.fileIdentifier,
        });
      });

      setCteFilesOptions(aux);
    });
  };

  useEffect(() => {
    loadCteFiles();
  }, []);

  useEffect(() => {
    setHasError(!modality || clientList.length === 0);
  }, [modality, clientList]);

  return (
    <>
      <Flex direction="column" gap="20px">
        <Flex flexWrap="wrap" gap="20px" w="500px" mx="2px">
          <FormControl w="full">
            <FormLabel mb="9px">Empresa</FormLabel>
            <Flex direction="column" gap="8px">
              {filterOptions &&
                filterOptions.map((item, key) => {
                  return (
                    <React.Fragment key={key}>
                      <Select
                        placeholder="Selecione"
                        key={getFilter(screen, item.name)}
                        options={item.options}
                        isMulti={item.isMultiple}
                        name="client"
                        {...register('client', { required: true })}
                        value={item?.options?.filter((option) =>
                          item.isMultiple ? clientList?.indexOf(option.value) > -1 : option.value === clientList
                        )}
                        onChange={(option) => handleSelectChange(option)}
                      />
                    </React.Fragment>
                  );
                })}
            </Flex>
            {formErrorMainData && clientList?.length === 0 && <Text textStyle="error">Campo obrigatório.</Text>}
          </FormControl>
          <FormControl w="50%">
            <FormLabel mb="9px">{`CT'es da viagem`}</FormLabel>
            <Flex direction="column" gap="8px">
              <Select
                placeholder="Selecione"
                options={cteFilesOptions}
                isMulti={true}
                name="driverFreightFiles"
                {...register(
                  'driverFreightFiles'
                  // { required: true }
                )}
                value={ctesSelected}
                onChange={(option) => setCtesSelected(option)}
              />
            </Flex>

            {/* {formErrorMainData && ctesSelected?.length === 0 && <Text textStyle="error">Campo obrigatório.</Text>} */}
          </FormControl>
          <FormControl w="45%">
            <FormLabel mb="9px">Upload de arquivos</FormLabel>

            <IconButton
              bgColor="transparent"
              border="1px"
              borderColor={formData != null ? '#6C48C2' : '#70707033'}
              icon={<MdUploadFile size={24} color={formData != null ? '#6C48C2' : '#0F0A1D66'} />}
              onClick={() => {
                onOpen();
              }}
            />
          </FormControl>
          <FormControl w="full">
            <FormLabel mb="9px">Modalidade</FormLabel>
            <Select
              placeholder="Selecione"
              options={options}
              name="modality"
              {...register('modality', { required: true })}
              defaultValue={entity?.modality ? { value: entity.modality, label: getLabelForValue(entity.modality) } : null}
              onChange={(event) => {
                handleModalityChange(event);
                setValue('modality', event.value);
              }}
            />
            {formErrorMainData && !modality && <Text textStyle="error">Campo obrigatório.</Text>}
          </FormControl>
          <FormControl w="100%">
            <FormLabel mb="9px">Código externo (integração para rastreio)</FormLabel>
            <Input
              className="input-register"
              variant="primary"
              type="text"
              name="externalCode"
              placeholder="Preencha o código externo..."
              defaultValue={entity?.externalCode}
              onChange={(event) => {
                setValue('externalCode', event.value);
                setData({ ...data, externalCode: event.target.value });
              }}
            />
          </FormControl>
          <FormControl w="full">
            <FormLabel mb="9px">Transportadora</FormLabel>
            <Select
              placeholder="Selecione"
              options={[
                { value: 'VENDEMMIA', label: 'Vendemmia' },
                { value: 'OUTRO', label: 'Outro' },
              ]}
              name="shippingCompanyName"
              {...register('shippingCompanyName', { required: true })}
              defaultValue={
                entity?.shippingCompanyName
                  ? entity?.shippingCompanyName != 'VENDEMMIA'
                    ? { value: 'OUTRO', label: 'Outro' }
                    : { value: 'VENDEMMIA', label: 'Vendemmia' }
                  : null
              }
              onChange={(event) => {
                setValue('shippingCompanyName', event.value);
                setShowShippingCompanyName(event?.value == 'OUTRO' ? true : false);
                setData({ ...data, shippingCompanyName: event?.value });
              }}
            />
            {formErrorMainData && !data.shippingCompanyName && <Text textStyle="error">Campo obrigatório.</Text>}
          </FormControl>

          {showShippingCompanyName && (
            <FormControl w="100%">
              <FormLabel mb="9px">Descrição</FormLabel>
              <Input
                className="input-register"
                variant="primary"
                type="text"
                name="shippingCompanyNameDescription"
                placeholder="Preencha a transportadora..."
                defaultValue={entity?.shippingCompanyName}
                onChange={(event) => {
                  if (event.target.value != null && event.target.value != undefined) {
                    setValue('shippingCompanyName', event.target.value);
                    setData({ ...data, shippingCompanyName: event.target.value });
                  }
                }}
              />
            </FormControl>
          )}
        </Flex>
      </Flex>

      <Button mt="60px" variant="primary" onClick={onSubmit}>
        <Text fontStyle="paragraph">Salvar</Text>
      </Button>
      <ModalUpload
        title="Gestão de viagens"
        subtitle="Upload de CT-e"
        accept=".xml e .pdf,"
        messageConfirm=" salvo com sucesso para envio!"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        requestAction={sendUpload}
        multiple={false}
        nameForm="driver_freight_file_upload"
        additional={[
          {
            label: 'Modalidade',
            group: 'modality',
            name: 'mod',
            required: true,
            options: [
              { value: 'CTE_XML', label: 'XML do CT-e' },
              { value: 'CTE_PDF', label: 'PDF do CT-e' },
              { value: 'NF_XML', label: 'XML da Nota fiscal' },
              { value: 'NF_PDF', label: 'PDF da Nota fiscal' },
            ],
          },
        ]}
      />
    </>
  );
};

export default CardMainData;
