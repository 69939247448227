import React from 'react';

import { Flex, Table, Tbody, Td, Thead, Tr } from '@chakra-ui/react';

import SortableTableHeader from '../../components/SortableTableHeader';

import StockList from './StockList';

const StockTable = ({ list, handleSort, sorting }) => {
  return (
    <Table className="table-default" w="full">
      <Thead border="1px" borderBottom="1px" h="3rem">
        <Tr>
          <SortableTableHeader column="e.codProduto" label="Cód. Produto" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.descProduto" label="Descrição" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.setor" label="Setor" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.situacao" label="Estado do produto" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.qtdeAvariada" label="Estoque disponível" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.unityValue" label="Valor" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.idLoteFabricacao" label="Lote indústria" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.ufArmazem" label="Armazém" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.dtVencimento" label="Vencimento" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="e.inAbc" label="Curva ABC" sorting={sorting} handleSort={handleSort} />
          <SortableTableHeader column="c.name" label="Depositante" sorting={sorting} handleSort={handleSort} />
        </Tr>
      </Thead>

      <Tbody border="1px">
        {list.length > 0 ? (
          <>
            {list.map((entity, key) => (
              <StockList key={entity.id} count={key} entity={entity} />
            ))}
          </>
        ) : (
          <Tr>
            <Td colSpan={11}>
              <Flex justify="center" my="25px">
                Nenhum dado encontrado para os parâmetros filtrados.
              </Flex>
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};

export default StockTable;
