import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { MdHelp } from 'react-icons/md';

import { Box, Button, Divider, Flex, Modal, ModalBody, ModalContent, ModalOverlay, Text, Tooltip } from '@chakra-ui/react';

const ModalFreightConfirm = ({
  handleSubmit,
  formCardData,
  formMainData,
  filterOptions,
  trajectories,
  freightValues,
  load,
  loadSecond,
  isOpen,
  onOpen,
  onClose,
}) => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    if (filterOptions?.[0]?.name === 'clients') {
      let aux = [];
      formMainData?.clients?.forEach(identifier => {
        filterOptions?.[0]?.options.forEach(option => {
          if (identifier === option.identifier) {
            aux.push(option.name + ' ('+ option.documentNumberFormatted + ')');
          }
        })
      })

      setCompanies(aux);
    }
  }, [formMainData]);

  return (
    <>
      <Button
        onClick={() => {
          onOpen();
        }}
        isDisabled={load}
        variant="primary"
        px="30px"
        py="9px">
        Finalizar
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />

        <ModalContent maxW="none" w="700px" h="auto" px="40px" py="30px">
          <ModalBody p="0">
            <Flex direction="column" gap="5px">
              <Text textStyle="subtitle" textColor="textColor3">
                Verifique se todas as informações foram adicionadas corretamente
              </Text>
            </Flex>

            <Divider my="25px" />

            <Flex direction="column" gap="13px">
              <Flex align="center" gap="50px">
                <Text textStyle="subtitle" fontWeight="medium" textColor="primary" minW="250px">
                  Modalidade de cadastro
                </Text>

                <Text textStyle="paragraph" textColor="primary" opacity="1">
                  {formMainData && formMainData.modality && formMainData.modality.length > 0 && formMainData.modality === 'MANUAL'
                    ? 'Viagem de gestão manual'
                    : 'Viagem do app do motorista'}
                </Text>
              </Flex>

              <Flex align="center" gap="50px">
                <Text textStyle="subtitle" fontWeight="medium" textColor="primary" minW="250px">
                  Empresas vinculadas
                </Text>
                
                <Flex>
                  <Text textStyle="paragraph" textColor="primary" opacity="1">
                    {formMainData?.clients?.length === 0 ? 'Nenhuma empresa vinculada' : formMainData?.clients?.length + ` vinculada${formMainData?.clients?.length > 1 ? 's' : ''}`}
                  </Text>
                  <Box cursor="pointer">
                    {companies?.length > 0 && (
                      <Tooltip label={companies?.join(', ')}>
                        <Box ml="10px">
                          <MdHelp color="#422C76" />
                        </Box>
                      </Tooltip>
                      )}
                  </Box>
                </Flex>
              </Flex>
            </Flex>

            <Divider my="25px" />

            <Flex direction="column" gap="13px">
              <Flex align="center" gap="50px">
                <Text textStyle="subtitle" fontWeight="medium" textColor="primary" minW="250px">
                  Motorista
                </Text>

                <Text textStyle="paragraph" textColor="primary" opacity="1">
                  {formCardData.driverName ? formCardData.driverName : 'Motorista não definido'}
                </Text>
              </Flex>

              <Flex align="center" gap="50px">
                <Text textStyle="subtitle" fontWeight="medium" textColor="primary" minW="250px">
                  Data inicial
                </Text>

                <Text textStyle="paragraph" textColor="primary" opacity="1">
                  {formCardData.startsAt ? moment(formCardData.startsAt).format('DD/MM/YYYY - HH:mm') : 'Data inicial não definida'}
                </Text>
              </Flex>

              <Flex align="center" gap="50px">
                <Text textStyle="subtitle" fontWeight="medium" textColor="primary" minW="250px">
                  Data final
                </Text>

                <Text textStyle="paragraph" textColor="primary" opacity="1">
                  {formCardData.endsAt ? moment(formCardData.endsAt).format('DD/MM/YYYY - HH:mm') : 'Data final não definida'}
                </Text>
              </Flex>

              <Flex align="center" gap="50px">
                <Text textStyle="subtitle" fontWeight="medium" textColor="primary" minW="250px">
                  Origem
                </Text>

                <Text textStyle="paragraph" textColor="primary" opacity="1">
                  {trajectories[0]?.address ? trajectories[0]?.address : 'Origem não definido'}
                </Text>
              </Flex>

              <Flex align="center" gap="50px">
                <Text textStyle="subtitle" fontWeight="medium" textColor="primary" minW="250px">
                  Destino
                </Text>

                <Text textStyle="paragraph" textColor="primary" opacity="1">
                  {trajectories[1]?.address ? trajectories[1]?.address : 'Destino não definido'}
                </Text>
              </Flex>
              <Flex align="center" gap="50px">
                <Text textStyle="subtitle" fontWeight="medium" textColor="primary" minW="250px">
                  Valor da mercadoria
                </Text>

                <Text textStyle="paragraph" textColor="primary" opacity="1">
                  {freightValues?.merchandiseValue ? freightValues.merchandiseValue : '-'}
                </Text>
              </Flex>
              <Flex align="center" gap="50px">
                <Text textStyle="subtitle" fontWeight="medium" textColor="primary" minW="250px">
                  Valor da mercadoria avariada
                </Text>

                <Text textStyle="paragraph" textColor="primary" opacity="1">
                  {freightValues?.merchandiseDamagedValue ? freightValues.merchandiseDamagedValue : '-'}
                </Text>
              </Flex>
              <Flex align="center" gap="50px">
                <Text textStyle="subtitle" fontWeight="medium" textColor="primary" minW="250px">
                  Cubagem do valor da mercadoria
                </Text>

                <Text textStyle="paragraph" textColor="primary" opacity="1">
                  {freightValues?.cubage ? freightValues.cubage : '-'}
                </Text>
              </Flex>
            </Flex>
            <Divider my="25px" />

            <Flex gap="76px" align="center">
              <Text textStyle="secondaryTitle" textColor="linkColor" fontWeight="medium">
                Frete
              </Text>

              <Text textStyle="secondaryTitle" textColor="linkColor" fontWeight="medium">
                R$ {freightValues?.freightValue ? freightValues.freightValue : '-'}
              </Text>
            </Flex>

            <Flex gap="23px" justify="flex-end">
              <Button
                variant="secundary"
                px="30px"
                py="9px"
                borderRadius="9px"
                _focus={{ borderColor: 'linkColor' }}
                isDisabled={loadSecond}
                isLoading={loadSecond}
                onClick={onClose}>
                Editar
              </Button>

              <Button
                variant="primary"
                px="30px"
                py="9px"
                borderRadius="9px"
                loadingText="Salvando..."
                isDisabled={loadSecond}
                isLoading={loadSecond}
                onClick={(decision) => {
                  if (decision) {
                    handleSubmit('created');
                  }
                }}>
                Confirmar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalFreightConfirm;
