import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Card from '../../../components/Card/Card';
import Layout from '../../../components/Layout';
import Menubar from '../../../components/MenuBar/Menubar';
import useScreenPage from '../../../hooks/useScreenPage';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { driver } from '../../../utils/exports';
import { filterOptionsTrip, filterOptionsTripKanban } from '../../../utils/filters/filterPresets';

import TravelCte from './components/CTE/TravelCte';
import TravelKanban from './components/Kanban/TravelKanban';
import TravelList from './components/TravelList/TravelList';

function TravelManagementPage() {
  let navigate = useNavigate();

  //Detecção de chamadas as APIs
  const screenPage = useScreenPage();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [searchPeriod, setSearchPeriod] = useState();
  const [searchFilterData, setSearchFilterData] = useState(null);
  const [filterOptions, setFilterOptions] = useState([]);
  const [numTotal, setNumTotal] = useState(0);
  const [tab, setTab] = useState('progress');
  const [list, setList] = useState([]);
  const [action, setAction] = useState(1);
  const [update, setUpdate] = useState(false);
  const [metadata, setMetadata] = useState({
    current_page: 0,
    item_count: 0,
    page_count: 0,
    page_size: 0,
    total_count: 0,
    total_pages: 0,
  });

  function getFiltersFormatted() {
    let filters = {
      search: searchString,
      filters: {
        ...searchPeriod,
        ...searchFilterData,
      },
      screenPage,
    };

    if (tab === 'kanban') {
      delete filters.filters.status;
      delete filters.filters.startsAt;
      delete filters.filters.endsAt;
    }

    return filters;
  }

  const listFreights = () => {
    if (
      (searchPeriod?.startsAt != null && searchPeriod?.endsAt != null) ||
      (searchPeriod?.startsAt != undefined && searchPeriod?.endsAt != undefined)
    ) {
      setIsLoading(true);

      requests
        .listDriverFreight(
          getFiltersFormatted(),
          metadata.current_page > 0 ? metadata.current_page : 1
        )
        .then((data) => {
          setList(data.data);
          setMetadata(data.meta);
          setNumTotal(data.meta.total_count ? data.meta.total_count : 0);
          setIsLoading(false);
        });
    }
  };

  const onChangePeriod = (date) => {
    setSearchPeriod(date);
  };

  const onChangeFilters = (value) => {
    setSearchFilterData(value);
  };

  const onChangeTextFilter = (value) => {
    if (value.length >= 3) {
      setSearchString(value);
    } else {
      setSearchString('');
    }
  };

  const isRefreshing = () => {
    return isLoading;
  };

  const refresh = () => {
    load();
  };

  const load = () => {
    listFreights();
  };

  const getFilterOptions = () => {
    if (tab === 'kanban') {
      setFilterOptions(filterOptionsTripKanban);
    } else {
      setFilterOptions(filterOptionsTrip);
    }
  };

  useEffect(() => {
    getFilterOptions();
  }, [tab]);

  useEffect(() => {
    getFilterOptions();

    !permissions.logisticTripFullManagement && !permissions.logisticTripView && navigate('/');

    const currentUrl = window.location.href;
    let aux = currentUrl.split('#');
    if (aux.length === 2) {
      if (aux[1].indexOf('kanban') !== -1) {
        setTab('kanban');
      } else {
        setTab(aux[1]);
      }
    }
  }, []);

  useEffect(() => {
    load();
  }, [searchPeriod, searchString, searchFilterData, action]);

  return (
    <Layout>
      <Menubar
        screen="trip"
        title="Gestão de Viagens"
        linkTree={[{ link: '#', title: 'Transporte' }]}
        placeholder="Motorista, Viagem, Transportadora, CT-e ou Placa"
        filterOptions={filterOptions}
        onChangePeriod={onChangePeriod}
        onChangeTextFilter={onChangeTextFilter}
        onChangeFilters={onChangeFilters}
        icon={driver}
        isRefreshLoading={isRefreshing()}
        showRefreshData={true}
        triggerRefreshData={refresh}
        showPeriodFilter={tab !== 'kanban' ? true : false}
      />
      <Card mx="10px">
        {tab === 'progress' && (
          <TravelList
            tab={tab}
            setTab={setTab}
            load={isLoading}
            list={list}
            setAction={setAction}
            metadata={metadata}
            setMetadata={setMetadata}
            action={action}
            loadData={listFreights}
          />
        )}

        {tab === 'kanban' && (
          <TravelKanban
            filterSelected={getFiltersFormatted()}
            action={update}
            setAction={setUpdate}
            setTabTravel={setTab}
          />
        )}

        {tab === 'cte' && (
          <TravelCte
            tab={tab}
            filterSelected={getFiltersFormatted()}
            setTab={setTab}
          />
        )}
      </Card>
    </Layout>
  );
}

export default TravelManagementPage;
